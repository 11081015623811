import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChatShortcut } from '../../../../../models/pro/entite-juridique';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-chat-scenario-message-selector',
  templateUrl: './chat-scenario-message-selector.component.html',
  styleUrls: ['./chat-scenario-message-selector.component.scss']
})
export class ChatScenarioMessageSelectorComponent implements OnInit, OnChanges {
    @Input() chatShortcuts: ChatShortcut[];
    @Input() selectedMessage?: string;
    @Output() selectShortcut = new EventEmitter<ChatShortcut|null>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    datasource = new MatTableDataSource<ChatShortcut>();
    selection = new SelectionModel<ChatShortcut>(true, []);

    constructor(private translateService: TranslateService) {}

    ngOnInit(): void {
        // This processing affects how the category is sorted in the datatable
        this.datasource.data = this.chatShortcuts.map((c: ChatShortcut) => {
            if (!c.category) {
                c.category = this.translateService.instant('SHARED.OTHER');
            }

            c.category = c.category.toLowerCase();
            return c;
        });

        if (this.selectedMessage) {
            const selectedShortcut: ChatShortcut = this.chatShortcuts.find((s: ChatShortcut) => s.shortcut === this.selectedMessage);
            if (selectedShortcut) {
                this.selection.select(selectedShortcut);
            } else {
                this.selection.clear();
            }

            this.filter(this.selectedMessage);
        } else {
            this.selection.clear();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.chatShortcuts?.currentValue) {
            this.datasource.data = changes?.chatShortcuts?.currentValue;
        }
    }

    ngAfterViewInit(): void {
        this.datasource.paginator = this.paginator;
        this.datasource.sort = this.sort;
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filter(filterValue);
    }

    filter(searchString: string): void {
        this.datasource.filter = searchString.trim().toLowerCase();
        this.datasource?.paginator?.firstPage();
    }

    updateSelection(current: ChatShortcut, event: MatCheckboxChange): void {
        this.manageCheckboxes(current, event?.checked);
        this.selectShortcut.emit(this.selection.hasValue() ? current : null);
    }

    private manageCheckboxes(updatedShortcut: ChatShortcut, isSelected: boolean): void {
        if (!isSelected) {
            this.selection.clear();
        } else {
            this.datasource.data.forEach((s: ChatShortcut) => {
                if (s.shortcut === updatedShortcut.shortcut) {
                    this.selection.select(s);
                } else {
                    this.selection.deselect(s);
                }
            });
        }
    }
}

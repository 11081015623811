<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px" class="w-100-p">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                    <span
                        class="title dialog-title">{{ 'FILES.LINKS_ANIMAL' | translate:{animal: data.animal.nom} }}</span>

                    <button mat-icon-button (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <app-list-fichier
                [input]="data.animal"
                [displayStat]="false"
                [displayScrollDay]="false"
                [selectionMode]="FichierSelectionMode.SINGLE_INSTANT"
                (selectedFichiersChanged)="onFichierSelected($event)"></app-list-fichier>
    </div>
</div>

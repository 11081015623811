<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">people</mat-icon>
                <span class="logo-text h1">{{ 'VETO.VETOS' | translate }}</span>
            </div>
            <!-- / APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="search" matInput #filter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="isOwner">
                    <button *ngIf="(utilisateurService?.utilisateurConnected | async)?.isProprietaire" mat-raised-button
                            class="my-12 mt-sm-0"
                            color="primary" (click)="openVeterinaire()">
                        <mat-icon>add</mat-icon>
                        {{ 'VETO.ADD' | translate }}
                    </button>
                </div>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table
                class="veterinaires-table"
                #table
                [dataSource]="dataSource" matSort
                [trackBy]="trackByFn"
                [class.hidden]="(dataSource.loading$ | async) || (dataSource.isEmpty() | async)">
                <ng-container cdkColumnDef="fullName">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                        class="header-veterinaire">{{ 'FORM.FIRST_NAME' | translate }} {{ 'FORM.NAME' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let veterinaire">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                             class="cursor-pointer" (click)="openVeterinaire(veterinaire)">
                            <app-avatar [entity]="veterinaire"></app-avatar>
                            <p class="text-truncate">{{ veterinaire.fullNameLite }}</p>
                            <mat-icon *ngIf="veterinaire.isProprietaire"
                                      [matTooltip]="'SHARED.ADMINISTRATOR' | translate">
                                grade
                            </mat-icon>
                            <mat-icon color='warn' *ngIf="isOwner && !veterinaire.enable"
                                      [matTooltip]="'SHARED.BLOCKED' | translate">
                                block
                            </mat-icon>
                            <app-connector-icon [entity]="veterinaire"></app-connector-icon>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="username">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                            class="header-veterinaire">{{ 'LOGIN.IDENTIFIANT' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let veterinaire">
                        <p class="text-truncate">{{ veterinaire.username }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="entiteGeographique">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                            class="header-veterinaire">{{ 'FORM.CLINIC' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let veterinaire">
                        <p class="text-truncate">
                            {{ veterinaire.entiteGeographique?.nom | emptyField }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="lastConnection">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                            class="header-veterinaires">{{ 'SHARED.LAST_CONNECTION' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let veterinaire">
                        <mat-icon *ngIf="!veterinaire.lastConnection; else lastConnecion">clear</mat-icon>
                        <ng-template #lastConnecion>
                            <p class="text-truncate">{{ veterinaire.lastConnection | dateLocale:'medium' }}</p>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let veterinaire">
                        <p>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="openVeterinaire(veterinaire)">
                                    <mat-icon>info</mat-icon>
                                    <span>{{ 'SHARED.DETAILS' | translate }}</span>
                                </button>
                                <button *ngIf="!veterinaire.lastConnection" mat-menu-item (click)="resendNotificationAccountCreated(veterinaire)">
                                    <mat-icon>sms_failed</mat-icon>
                                    <span>{{ 'VETO.RESEND_NOTIFICATION_ACCOUNT_CREATED.ACTION' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="removeVeterinaire(veterinaire)">
                                  <mat-icon>delete</mat-icon>
                                  <span>Supprimer</span>
                                </button>
                            </mat-menu>
                            <button mat-button color="secondary-text" [matMenuTriggerFor]="actionMenu"
                                    matTooltip="{{ 'SHARED.ACTIONS' | translate }}"
                                    class="more-button">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </p>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.loading$ | async; else loaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <!-- Never car admin toujours mais bon on laisse là -->
            <ng-template #loaded>
                <div *ngIf="dataSource.isEmpty() | async" class="primary-fg">
                    <div class="text-center m-8">
                        <p>{{ 'VETO.ANY' | translate }}</p>
                    </div>
                </div>
            </ng-template>

            <mat-paginator #paginator [length]="dataSource?.totalCount$ | async" [pageIndex]="0" [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">
    <!-- Dummy inputs to prevent Chrome autofill (note: wont work with display: none;) -->
    <input style="width: 1px; height: 1px; opacity: 0;" type="text" name="fakeusernameremembered"/>
    <input style="width: 1px; height: 1px; opacity: 0;" type="email" name="fakemailremembered"/>
    <input style="width: 1px; height: 1px; opacity: 0;" type="password" name="fakepasswordremembered"/>

    <p>{{ 'LOGIN.PWD_MUST_CHANGE' | translate }}</p>

    <div fxLayout="row wrap" fxLayoutAlign="start baseline" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'LOGIN.PWD_NEW' | translate }}</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                  [placeholder]="'LOGIN.PWD_NEW' | translate">
            <mat-icon matSuffix class="hide-password" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('password').hasError('required')">
                {{ 'LOGIN.PWD_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('passwordConfirm')">
                {{ 'LOGIN.PWD_DIFFERENTS' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'LOGIN.PWD_CONFIRM' | translate }}</mat-label>
            <input matInput [type]="hidePasswordConfirm ? 'password' : 'text'" formControlName="passwordConfirm"
                  [placeholder]="'LOGIN.PWD_CONFIRM' | translate">
            <mat-icon matSuffix class="hide-password" (click)="hidePasswordConfirm = !hidePasswordConfirm">{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('passwordConfirm').hasError('required')">
                {{ 'LOGIN.PWD_RETYPE' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('passwordConfirm').hasError('passwordConfirm')">
                {{ 'LOGIN.PWD_DIFFERENTS' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center">
        <button type="submit" 
          mat-raised-button
          color="accent"
          [disabled]="(formInProgress$ | async) || !form.valid">{{ 'LOGIN.PWD_UPDATE_BUTTON' | translate }}</button>
    </div>
</form>

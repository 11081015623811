import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Chat } from '../../../../models/animal/chat';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatScenario, ChatScenarioMessage } from '../../../../models/pro/entite-juridique';
import { DelayedMessageService } from '../../../../services/api/delayed-message.service';
import { forkJoin } from 'rxjs';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';

@Component({
    selector: 'app-chat-scenario-program-dialog',
    templateUrl: './chat-scenario-program-dialog.component.html',
    styleUrls: ['./chat-scenario-program-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChatScenarioProgramDialogComponent {
    scenario: ChatScenario;
    now: Date;
    form: FormArray;
    formInProgress: boolean;

    startDateFG: FormGroup;
    isStartDateApplied: boolean;
    startDateFilter: any;

    constructor(
        public dialogRef: MatDialogRef<ChatScenarioProgramDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ChatScenarioProgramDialogInterface,
        private delayedMessageService: DelayedMessageService, private utilisateurService: UtilisateurService
    ) {
        this.now = new Date();
        this.now.setHours(this.now.getHours() + 1);
        this.now.setMinutes(0);
        this.now.setSeconds(0);

        this.scenario = data.scenario;

        this.form = new FormArray(data.scenario.messages.map(m => {
            const date = new Date(this.now);
            date.setDate(date.getDate() + m.nbDaysAfter);
            return new FormGroup({
                message: new FormControl(data.chat.shortcuts.find(c => c.shortcut?.toLowerCase() === m.message?.toLowerCase())?.content, [Validators.required, Validators.minLength(1)]),
                date: new FormControl(date, [Validators.required]),
                reopenChatIfClosed: new FormControl(!!m.numberDaysToOpenIfClosed),
                numberDaysToOpenIfClosed: new FormControl(m.numberDaysToOpenIfClosed)
            });
        }));

        this.startDateFG = new FormGroup({
            startDate: new FormControl(this.now, [Validators.required])
        });

        this.isStartDateApplied = false;

        this.startDateFilter = (d: Date | null): boolean => {
            const date = d || new Date();
            const now = new Date();
            now.setHours(0);
            now.setMinutes(0);
            now.setSeconds(0);
            now.setMilliseconds(0);
            return date >= now;
        };
    }

    close(): void {
        this.dialogRef.close();
    }

    save(): void {
        this.formInProgress = true;
        const messages: ChatScenarioMessage[] = this.form.value;
        forkJoin(messages.map(m => this.delayedMessageService.add({
            chatId: this.data.chat.id,
            date: m.date,
            message: m.message,
            senderId: this.utilisateurService.utilisateurConnectedValue.id,
            numberDaysToOpenIfClosed: m.numberDaysToOpenIfClosed > 0 ? m.numberDaysToOpenIfClosed : null
        }))).subscribe({
            complete: () => {
                this.data.chat.delayedMessagesCount += messages.length;
                this.close();
                this.formInProgress = false;
            },
            error: _ => {
                this.formInProgress = false;
            }
        });
    }

    applyStartDate(startDateFG: FormGroup): void {
        const startDateToApply = startDateFG.controls.startDate.value;
        const updatedDates = this.data.scenario.messages.map(m => {
            const date = new Date(startDateToApply);
            return date.setDate(date.getDate() + m.nbDaysAfter);
        });

        this.form.controls.forEach((fg: FormGroup, index) => fg.patchValue({ date: new Date(updatedDates[index]) }));

        this.isStartDateApplied = true;
    }
}

export interface ChatScenarioProgramDialogInterface {
    chat: Chat;
    scenario: ChatScenario;
}

export interface ChatScenarioMessageForChat {
    message: string;
    date: Date;
    numberDaysToOpenIfClosed?: number;
}

<div class="forgot-pass-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="logo">
        <img src="/assets/images/logos/logo-vertical.svg" alt="Logo">
    </div>

    <div class="forgot-pass-card mat-elevation-z2">
        <div *ngIf="codeOk == null">
            <app-loading></app-loading>
        </div>

        <div *ngIf="codeOk == true">
            <h1>{{ 'LOGIN.PWD_NEW_MESSAGE' | translate }}</h1>
            <h4>{{ 'LOGIN.PWD_SIZE' | translate }}</h4>

            <div class="content">
                <form [formGroup]="form" (submit)="sendResetPassowrd()" fxLayout="column">

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>{{ 'LOGIN.PWD_NEW' | translate }}</mat-label>
                        <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="pwd" required>
                        <mat-icon matSuffix class="hide-password"
                                  (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <mat-error *ngIf="form.get('pwd').hasError('required')">
                            {{ 'LOGIN.PWD_REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" fxFlex="1 1 auto">
                        <mat-label>{{ 'LOGIN.PWD_CONFIRM' | translate }}</mat-label>
                        <input matInput [type]="hidePasswordConfirm ? 'password' : 'text'" formControlName="pwdConfirm"
                               required>
                        <mat-icon matSuffix class="hide-password"
                                  (click)="hidePasswordConfirm = !hidePasswordConfirm">{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>

                        <mat-error *ngIf="form.get('pwdConfirm').hasError('required')">
                            {{ 'LOGIN.PWD_CONFIRM_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="form.get('pwdConfirm').hasError('passwordsNotMatch')">
                            {{ 'LOGIN.PWD_DIFFERENTS' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" fxFlex="1 1 auto">
                        <button mat-raised-button type="submit"
                                color="primary">{{ 'SHARED.CONTINUE' | translate }}</button>
                        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                                     *ngIf="changeInProgress"></mat-spinner>
                    </div>
                </form>
            </div>
        </div>
        <div *ngIf="codeOk == false">
            <h1>{{ 'SHARED.ERROR' | translate }}</h1>
        </div>
    </div>
    <button mat-button [routerLink]="'/'" color="accent">{{ 'SHARED.BACK_HOME' | translate }}</button>
</div>

export interface ShortcutGroup {
    name: string;
    shortcuts: Shortcut[];
}

export interface Shortcut {
    name: string;
    key: string;
}

export const shortcutGroups: ShortcutGroup[] = [
    {
        name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.ANIMAL.TITLE',
        shortcuts: [
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.ANIMAL.NAME',
                key: 'animal'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.ANIMAL.AGE',
                key: 'ageAnimal'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.ANIMAL.SPECIE',
                key: 'specieAnimal'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.ANIMAL.BREED',
                key: 'breedAnimal'
            }
        ]
    },
    {
        name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.TITLE',
        shortcuts: [
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.FULLNAME',
                key: 'owner'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.CIVILITY',
                key: 'civilityOwner'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.FIRSTNAME',
                key: 'firstNameOner'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.LASTNAME',
                key: 'lastNameOwner'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.MAIL',
                key: 'mailOwner'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.OWNER.TEL',
                key: 'telOwner'
            }
        ]
    },
    {
        name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.CLINIC.TITLE',
        shortcuts: [
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.CLINIC.NAME',
                key: 'clinic'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.CLINIC.TEL',
                key: 'telClinic'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.CLINIC.MAIL',
                key: 'mailClinic'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.CLINIC.ADDRESS',
                key: 'addressClinic'
            }
        ]
    },
    {
        name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.VETERINARIAN.TITLE',
        shortcuts: [
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.VETERINARIAN.FULLNAME',
                key: 'veterinarian'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.VETERINARIAN.CIVILITY',
                key: 'civilityVeterinarian'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.VETERINARIAN.FIRSTNAME',
                key: 'firstNameVeterinarian'
            },
            {
                name: 'SETTINGS.MESSAGES.SHORTCUTS.LIST.VETERINARIAN.LASTNAME',
                key: 'lastNameVeterinarian'
            }
        ]
    }
];

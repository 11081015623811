<form [formGroup]="form" class="w-100-p" fxLayout="column" (ngSubmit)="submit()" autocomplete="off">
    <div *ngIf="entityLinked" class="mb-16 text-bold warn-fg">
        {{ 'CONNECTOR.ENTITY_LINKED' | translate }}
    </div>

    <!-- <h2>{{ 'SHARED.GENERAL' | translate }}</h2> -->

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <app-input-phone
            [control]="form.get('telephonePortable')"
            [required]="(entityData$ | async)?.typeUtilisateur != 'veterinaire'"
            [disabled]="disabled"
            [refresh$]="phoneRefresh$"
            [placeholder]="'FORM.PHONE_MOBILE' | translate">
        </app-input-phone>

        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'FORM.MAIL' | translate }}</mat-label>
            <input matInput formControlName="mail" [placeholder]="'FORM.MAIL' | translate" type="email" autocomplete="nope">
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
        <app-photo-profil-form
            *ngIf="(entityData$ | async)?.typeUtilisateur == 'veterinaire' && enablePhoto && (entityData$ | async)?.id"
            [entityData]="entityData$" [imageEvent]="imageEvent$"
            [enablePhotoForm]="enablePhotoForm"
            [photoInProgress]="photoInProgress$"
            [photoDelInProgress]="photoDelInProgress$"
            (photoSubmited)="submitPhoto($event)">
        </app-photo-profil-form>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'FORM.CIVILITY' | translate }}</mat-label>
            <mat-select [placeholder]="'FORM.CIVILITY' | translate" formControlName="civilite">
                <mat-option *ngFor="let civility of (('CIVILITY' | translate) | values:true)" [value]="civility.value">
                    {{ civility.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'FORM.FIRST_NAME' | translate }}</mat-label>
            <input matInput formControlName="prenom" [placeholder]="'FORM.FIRST_NAME' | translate" autocomplete="nope">
            <mat-error *ngIf="form.get('prenom').hasError('required')">
                {{ 'FORM.FIRST_NAME_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'FORM.LAST_NAME' | translate }}</mat-label>
            <input matInput formControlName="nom" [placeholder]="'FORM.LAST_NAME' | translate" required autocomplete="nope">
            <mat-error *ngIf="form.get('nom').hasError('required')">
                {{ 'FORM.NAME_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div *ngIf="showAllField && form.get('id').value" class="font-size-16 m-4">
        <h2>{{ 'LOGIN.CONNEXION' | translate }}</h2>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="start baseline" fxLayoutGap="20px" *ngIf="showAllField && form.enabled">
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'LOGIN.PWD' | translate }}</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                   [placeholder]="'LOGIN.PWD' | translate" autocomplete="new-password">
            <mat-icon matSuffix class="hide-password" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('password').hasError('required')">
                {{ 'LOGIN.PWD_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('password').hasError('passwordConfirm')">
                {{ 'LOGIN.PWD_DIFFERENTS' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'LOGIN.PWD_CONFIRM' | translate }}</mat-label>
            <input matInput [type]="hidePasswordConfirm ? 'password' : 'text'" formControlName="passwordConfirm"
                   [placeholder]="'LOGIN.PWD_CONFIRM' | translate" autocomplete="new-password">
            <mat-icon matSuffix class="hide-password" (click)="hidePasswordConfirm = !hidePasswordConfirm">{{hidePasswordConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
            <mat-error *ngIf="form.get('passwordConfirm').hasError('required')">
                {{ 'LOGIN.PWD_RETYPE' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('passwordConfirm').hasError('passwordConfirm')">
                {{ 'LOGIN.PWD_DIFFERENTS' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <!-- <h2>{{ 'SHARED.DETAILS' | translate }}</h2> -->

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'FORM.LOCALE' | translate }}</mat-label>
            <mat-select formControlName="locale" [placeholder]="'FORM.LOCALE' | translate">
                <mat-option *ngFor="let language of configService.getLanguagesConfig() | async "
                            [value]="language.iso">{{ language.name }}</mat-option>
            </mat-select>
        </mat-form-field>

        <app-input-timezone fxFlex="1 0 0" [required]="true" [control]="form.get('timezone')"></app-input-timezone>
    </div>

    <app-input-connector
        *ngIf="connecteurService.isConnecteurFeatureEnabled((entityData$ | async)?.typeUtilisateur == 'veterinaire' ? 'VETERINARIAN' : 'CLIENT') | async"
        [control]="form.get('connectorId')"
        [required]="connectorIdRequired"></app-input-connector>

    <!-- <h2>{{ 'FORM.CLINIC' | translate }}</h2> -->

    <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>{{ 'FORM.CLINIC' | translate }}</mat-label>
        <mat-select [placeholder]="'FORM.CLINIC' | translate" formControlName="entiteGeographique" required>
            <mat-option *ngFor="let eg of entitesGeographiques$ | async" [value]="eg.id">
                {{ eg.nom }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('entiteGeographique').hasError('required')">
            {{ 'FORM.CLINIC_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <div [ngSwitch]="(entityData$ | async)?.typeUtilisateur">
        <app-veterinaire-form *ngSwitchCase="'veterinaire'" [utilisateurData]="entityData$" [isMe]="isMe"
                              [form]="form" [disabled]="disabled" [country]="country"></app-veterinaire-form>
        <app-client-form *ngSwitchCase="'client'" [utilisateurData]="entityData$"
                         [form]="form" [disabled]="disabled"></app-client-form>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button *ngIf="!disabled && showAllField && !isMe && form.get('id').value && userNeverConnected"
                type="button" mat-flat-button color="primary"
                [disabled]="formInProgress$ | async" (click)="resendNotificationAccountCreated()">
            <mat-icon>sms_failed</mat-icon>
            {{ ((entityData$ | async)?.typeUtilisateur == 'veterinaire' ? 'VETO' : 'CLIENT') + '.RESEND_NOTIFICATION_ACCOUNT_CREATED.ACTION' | translate }}
        </button>
        <button *ngIf="!disabled && showAllField && canBeDeleted" type="button" mat-flat-button color="warn"
                [disabled]="formInProgress$ | async" (click)="delete()">
            <mat-icon>delete</mat-icon>
            {{ 'SHARED.DELETE' | translate }}
        </button>
        <button *ngIf="form.enabled && (entityData$ | async)?.typeUtilisateur == 'client' && !(entityData$ | async)?.id"
                type="submit" mat-raised-button color="accent" (click)="createAnimal$.next(true)"
                [disabled]="form.disabled || (formInProgress$ | async)">
            <mat-icon>save</mat-icon>
            {{ 'CLIENT.SAVE_ADD_ANIMAL' | translate }}
        </button>
        <button *ngIf="form.enabled" type="submit" mat-raised-button color="accent"
                [disabled]="form.disabled || (formInProgress$ | async)">
            <mat-icon>save</mat-icon>
            {{ 'FORM.SAVE' | translate }}
        </button>
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="formInProgress$ | async"></mat-spinner>
    </div>

</form>

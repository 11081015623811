import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { OnboardingPlansResponse, OnboardingService } from '../../services/api/onboarding.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSliderChange } from '@angular/material/slider';
import { StripeCoupon } from '../../models/stripe/coupon';
import { AuthService } from '../../services/api/auth.service';
import { TokenInfoResponseInterface } from '../../models/interfaces/auth/auth-response.interface';
import { add } from 'date-fns';
import { DateLocalePipe } from 'app/pipes/date-locale.pipe';
import { TranslateService } from '@ngx-translate/core';
import { StripeService } from '../../services/api/stripe.service';
import { UtilisateurService } from 'app/services/api/utilisateur.service';

@Component({
    selector: 'onboarding-pro-plans',
    templateUrl: './onboarding-pro-plans.component.html',
    styleUrls: ['./onboarding-pro-plans.component.scss'],
    animations: fuseAnimations
})
export class OnboardingProPlansComponent implements OnInit, OnDestroy {
    isValidUser: boolean;
    messageToUser: string;

    form: FormGroup;
    formLoading = false;

    linkCGUV = 'https://lnk.vet/cgu';

    couponLoading = false;
    private _stripeCoupon: StripeCoupon;
    private _stripeCouponCovid19: StripeCoupon;
    private defaultCoupon = '';

    private subscriptions: Subscription[] = [];

    private accessToken: string;

    priceMonthly = 79;
    priceAdditionalLicenseMonthly = 9;
    nbLicences = 2;
    private defaultNbLicences = 2;

    cliniqueName = '';

    constructor(
        private fuseConfigService: FuseConfigService,
        private onboardingService: OnboardingService,
        private translateService: TranslateService,
        private authService: AuthService,
        private utilisateurService: UtilisateurService,
        private stripeService: StripeService,
        private router: Router,
        private readonly route: ActivatedRoute) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            monthly: new FormControl(true, [Validators.required]),
            numberOfLicenses: new FormControl(this.nbLicences, [Validators.min(this.nbLicences), Validators.max(50), Validators.required]),
            coupon: new FormControl(null, []),
            options: new FormGroup({
                payment: new FormControl(true)
            }),
            conditionsAccepted: new FormControl(false, [Validators.requiredTrue]),
            specialConditionsAccepted: new FormControl(false, [isSpecialConditionsValid(this)])
        });

        this.formatLicenses = this.formatLicenses.bind(this);

        const couponSubscription = this.form.get('coupon').valueChanges.subscribe(() => {
            this.setCouponForOptions();
        });

        const routeSubscription = this.route.queryParamMap.subscribe(queryParams => {
            if (queryParams.has('access_token')) {
                this.accessToken = queryParams.get('access_token');

                this.authService.getInfoToken(this.accessToken).subscribe({
                    next: (res: TokenInfoResponseInterface) => {
                        if (res.role !== 'VETERINAIRE' || res.entiteJuridiqueEnabled) {
                            void this.router.navigate(['/']);
                        } else {
                            this.isValidUser = true;
                            this.cliniqueName = res.fullName;
                            this.utilisateurService.getMe().subscribe(_ => {
                                // this.getDefaultCoupon();
                            });
                        }
                    },
                    error: () => {
                        void this.router.navigate(['/']);
                    }
                });
            } else if (this.authService.isAuthenticated) {
                this.utilisateurService.getMe().subscribe(user => {
                    if (user.entiteJuridique?.isDisabled) {
                        this.accessToken = this.authService.token;
                        this.isValidUser = true;
                        this.cliniqueName = user.entiteJuridique.nom;
                        // this.getDefaultCoupon();
                    } else {
                        void this.router.navigate(['/']);
                    }
                });
            } else {
                void this.router.navigate(['/']);
            }

            if (queryParams.has('status')) {
                const status = queryParams.get('status');
                void this.router.navigate([], {
                    queryParams: {
                        status: null
                    },
                    queryParamsHandling: 'merge'
                });

                if (status === 'canceled') {
                    this.messageToUser = 'ONBOARDING_PRO_PLANS.CANCELED';
                }
            }
        });

        this.subscriptions.push(couponSubscription, routeSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    public get stripeCoupon(): StripeCoupon {
        return this.stripeCouponRaw ?? this.stripeCouponCovid19;
    }

    public get stripeCouponRaw(): StripeCoupon {
        return this._stripeCoupon;
    }

    public get stripeCouponCovid19(): StripeCoupon {
        return this._stripeCouponCovid19;
    }

    public get pricePerPayment(): number {
        const data: PlansFormValue = this.form.getRawValue();
        return this.priceMonthly +
            (data.numberOfLicenses - this.defaultNbLicences) * this.priceAdditionalLicenseMonthly;
    }

    public get pricePerPaymentAfterCoupon(): number {
        let price = this.pricePerPayment;
        const coupon = this.stripeCoupon;
        if (coupon) {
            price -= (coupon.amountOff ?? 0) / 100;
            price *= 1 - (coupon.percentOff ?? 0);
        }

        if (price < 0) {
            price = 0;
        }

        return price;
    }

    public get priceToPayNowHT(): number {
        const data: PlansFormValue = this.form.getRawValue();
        let price: number = null;

        price = this.priceMonthly +
            (data.numberOfLicenses - this.defaultNbLicences) * this.priceAdditionalLicenseMonthly;

        const coupon = this.stripeCoupon;
        if (coupon) {
            price -= (coupon.amountOff ?? 0) / 100;
            price *= 1 - (coupon.percentOff ?? 0);
        }

        if (price < 0) {
            price = 0;
        }

        return price;
    }

    public get dateCouponEnd(): string {
        if (this.stripeCoupon?.monthsDuration > 0) {
            const date = add(Date.now(), { months: this.stripeCoupon.monthsDuration });
            const datePipe = new DateLocalePipe(this.translateService);
            return datePipe.transform(date);
        }

        return '';
    }

    onBackClicked(): void {
        void this.router.navigate(['/']);
    }

    onMatSliderInput(event: MatSliderChange): void {
        this.form.get('numberOfLicenses').setValue(event.value);
    }

    formatLicenses(value: number): string {
        const tradKey = 'ONBOARDING_PRO_PLANS.LICENSES.NAME';
        let trad: string = this.translateService?.instant('ONBOARDING_PRO_PLANS.LICENSES.NAME');
        if (!trad || trad === tradKey) {
            trad = 'licences';
        }

        return Math.round(value).toString() + '\u00A0' + trad;
    }

    resetCoupon(): void {
        this._stripeCoupon = null;
        const options = Object.keys((this.form.get('options') as FormGroup).controls);
        options.forEach(optionsKey => {
            this.form.get(['options', optionsKey]).enable();
        });
        this.form.get('coupon').disable();
        this.form.get('coupon').setValue(null);
        this.form.get('coupon').enable();
    }

    checkCoupon(): void {
        const coupon = this.form.get('coupon').value;
        if (!coupon || coupon && coupon.trim() === '') {
            return;
        }

        this.form.get('coupon').disable();
        this.couponLoading = true;
        this.onboardingService.checkCoupon(coupon, this.accessToken).subscribe({
            next: (stripeCoupon: StripeCoupon) => {
                if (stripeCoupon) {
                    this._stripeCoupon = stripeCoupon;
                } else {
                    this._stripeCoupon = null;
                    this.form.get('coupon').enable();
                }

                this.couponLoading = false;

                this.setCouponForOptions();
            },
            error: () => {
                this.form.get('coupon').enable();
                this.couponLoading = false;
            }
        });
    }

    getDefaultCoupon(): void {
        const pays = this.utilisateurService.utilisateurConnectedValue?.entiteJuridique?.paysFacturation;
        if (!pays || pays === 'FR') {
            this.onboardingService.checkCoupon(this.defaultCoupon, this.accessToken).subscribe((stripeCoupon: StripeCoupon) => {
                this._stripeCouponCovid19 = stripeCoupon;
                this.setCouponForOptions();
            });
        }
    }

    submit(): void {
        const accessToken = this.accessToken;
        if (!this.form.valid || !accessToken) {
            return;
        }

        const plans = this.form.getRawValue();
        plans.options = Object.entries(plans.options).filter(([_key, value]) => value === true).map(([key, _value]) => key);

        if (!plans.coupon) {
            plans.coupon = this.defaultCoupon;
        }

        this.formLoading = true;
        this.onboardingService.sendPlans(plans, this.accessToken).subscribe({
            next: (res: OnboardingPlansResponse) => {
                const idSession = res?.idSession;
                if (idSession) {
                    this.stripeService.gotoStripe(idSession).then(null, err => {
                        console.error(err);
                    });
                }

                this.formLoading = false;
            },
            error: () => {
                this.formLoading = false;
            }
        });
    }

    private setCouponForOptions() {
        const coupon = this.stripeCoupon;
        if (coupon) {
            if (coupon.metadata?.optionsIncluded) {
                try {
                    const optionsIncluded: string[] = JSON.parse(coupon.metadata.optionsIncluded);
                    if (optionsIncluded && optionsIncluded.length > 0) {
                        optionsIncluded.forEach(optionKey => {
                            const option = this.form.get(['options', optionKey]);
                            option.setValue(true);
                            option.disable();
                        });
                    }
                } catch {
                    // ignored
                }
            }

            if (coupon.metadata?.licences) {
                this.nbLicences = Number.parseInt(coupon.metadata.licences, 10) + this.defaultNbLicences;
            } else {
                this.nbLicences = this.defaultNbLicences;
            }

            this.form.get('numberOfLicenses').setValue(this.nbLicences);
        }
    }
}

export function isSpecialConditionsValid(context: OnboardingProPlansComponent): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const stripeCoupon = context.stripeCoupon;
        if (stripeCoupon && !control.value) {
            return {
                isSpecialConditionsValid: true
            };
        }

        return null;
    };
}

interface PlansFormValue {
    monthly: boolean;
    numberOfLicenses: number;
    coupon: string;
    options: {
        payment: boolean;
    };
    conditionsAccepted: boolean;
}

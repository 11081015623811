import { JsonProperty } from 'json-object-mapper';
import { Entity } from './entity';
import { DateMapper } from '../object-mapper/date-mapper';
import { List } from './list';
import { Utils } from '../utils';

export class Fichier extends Entity {
    @JsonProperty()
    url: string = null;

    @JsonProperty()
    contentType?: string = null;

    @JsonProperty()
    taille?: number = null;

    @JsonProperty()
    nom?: string = null;

    @JsonProperty()
    description?: string = null;

    @JsonProperty()
    type?: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    date?: Date = null;

    public isImage(): boolean {
        return Utils.isImage(this.contentType);
    }

    public isVideo(): boolean {
        return Utils.isVideo(this.contentType);
    }

    public isAudio(): boolean {
        return Utils.isAudio(this.contentType);
    }

    public isOther(): boolean {
        return Utils.isOther(this.contentType);
    }

    public get iconForType(): string {
        return Utils.getIconForType(this.contentType);
    }
}

export class ListFichiers extends List {
    @JsonProperty({ type: Fichier })
    data: Fichier[] = null;

    @JsonProperty()
    stats: any = null;
}

import { Component, OnDestroy } from '@angular/core';
import { VisioService } from '../../../../services/visio.service';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { DateLocalePipe } from '../../../../pipes/date-locale.pipe';
import { TranslateService } from '@ngx-translate/core';
import { WaitingRoomService } from '../../../../services/api/waiting-room.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-visio-timer',
    templateUrl: './visio-timer.component.html',
    styleUrls: ['./visio-timer.component.scss']
})

export class VisioTimerComponent implements OnDestroy {
    time: BehaviorSubject<string> = new BehaviorSubject<string>('00:00');
    batteryLevel: number;
    datePipe: DateLocalePipe;

    subscriptions: Subscription;

    signalStrength: number = null;

    constructor(
        public visioService: VisioService,
        private translateService: TranslateService,
        private waitingRoomService: WaitingRoomService
    ) {
        this.datePipe = new DateLocalePipe(translateService);

        const counter = interval(250).subscribe(() => {
            const delta = this.visioService.calculatedTime;
            this.time.next(this.datePipe.transform(new Date(delta), 'mm:ss'));
        });
        this.subscriptions = counter;

        const rendezVous = this.visioService.currentRendezVousObject;
        this.waitingRoomService.clientWaitings.pipe(
            map(cw => cw?.find(c => c.rendezVous.id === rendezVous.id))
        ).subscribe(cw => {
            if (cw) {
                this.signalStrength = cw?.testResult;
            }
        });

        this.visioService.currentSessionObject.on('signal', event => {
            if ((event.type as string) === 'signal:battery') {
                this.batteryLevel = Number(event.data);
            } else if ((event.type as string) === 'signal:test') {
                this.signalStrength = Number((event as any)?.data) ?? null;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    batteryIcon(): string {
        if (!this.batteryLevel) {
            return 'battery_unknown';
        } else if (this.batteryLevel >= 15) {
            return 'battery_full';
        } /*else if (this.batteryLevel >= 90) {
            return 'battery_90';
        } else if (this.batteryLevel >= 80) {
            return 'battery_80';
        } else if (this.batteryLevel >= 60) {
            return 'battery_60';
        } else if (this.batteryLevel >= 50) {
            return 'battery_50';
        } else if (this.batteryLevel >= 30) {
            return 'battery_30';
        } else if (this.batteryLevel >= 15) {
            return 'battery_20';
        }*/

        return 'battery_alert';
    }

    isOvertime(): boolean {
        return this.visioService.currentRendezVousObject && this.visioService.calculatedTime / 1000 > this.visioService.currentRendezVousObject.dureePrevue * 60;
    }

    getTestResult(testResult: number): string {
        switch (testResult) {
            case 0:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_0') as string;
            case 1:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_1') as string;
            case 2:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_2') as string;
            case 3:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_3') as string;
            default:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_UNKNOWN') as string;
        }
    }

    getTestResultColor(testResult: number): string {
        switch (testResult) {
            case 0:
                return 'red';
            case 1:
                return 'red';
            case 2:
                return 'orange';
            case 3:
                return 'green';
            default:
                return 'inherit';
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from '../../services/api/stripe.service';

@Component({
    selector: 'onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    animations: fuseAnimations
})
export class OnboardingComponent implements OnInit {
    constructor(
        private fuseConfigService: FuseConfigService,
        private stripeService: StripeService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        if (this.route.snapshot.params.sessionId) {
            this.stripeService.gotoStripe(this.route.snapshot.params.sessionId).then(null, err => {
                console.error(err);
                this.redirect();
            });
        }
    }

    redirect(): void {
        void this.router.navigate(['/']);
    }
}

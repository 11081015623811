import { Inject, Injectable } from '@angular/core';
import { MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';

@Injectable()
export class DateAdapterTranslated extends NativeDateAdapter {
    constructor(
        @Inject(MAT_DATE_LOCALE) matDateLocale: string,
        platform: Platform,
        private translateService: TranslateService,
        private utilisateurService: UtilisateurService,
        dateTimeAdapter: DateTimeAdapter<any>
    ) {
        super(matDateLocale, platform);
        this.translateService.onLangChange.subscribe(() => {
            const locale = this.getCurrentLocale();
            this.setLocale(locale);
            dateTimeAdapter.setLocale(locale);
        });
    }

    getFirstDayOfWeek(): number {
        return getLocaleFirstDayOfWeek(this.getCurrentLocale());
    }

    private getCurrentLocale(): string {
        return this.utilisateurService.utilisateurConnectedValue?.localeIso ?? this.translateService.currentLang ?? 'en';
    }
}

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { captureException } from '@sentry/browser';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error | { originalError: Error; message: string }): void {
        console.error(error);

        if (!environment.hmr && error) {
            if (/Loading chunk \d+ failed/.test(error.message)) {
                window.location.reload();
            }

            if (Object.prototype.hasOwnProperty.call(error, 'originalError')) {
                captureException((error as { originalError: Error}).originalError);
            } else {
                captureException(error);
            }

            if (environment.production) {
                const googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
                googleAnalyticsService.exception(error.message);
            }
        }
    }
}

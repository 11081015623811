import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { ChatScenario, ChatScenarioMessage, ChatShortcut } from '../../../../models/pro/entite-juridique';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ScenarioMessageDialogComponent } from './scenario-message-dialog/scenario-message-dialog.component';

@Component({
    selector: 'app-chat-scenario-dialog',
    templateUrl: './chat-scenario-dialog.component.html',
    styleUrls: ['./chat-scenario-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChatScenarioDialogComponent {
    scenario: ChatScenario;
    form: FormGroup;
    messagesToDisplay: ChatScenarioMessage[] = [];
    messagesControls: FormArray;
    shortcuts: ChatShortcut[] = [];

    constructor(
        private cdRef: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ChatScenarioDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ChatScenarioDialogInterface,
        public dialog: MatDialog
    ) {
        this.scenario = data.scenario;
        this.shortcuts = data.shortcuts;
        data.scenario?.messages?.forEach(m => this.messagesToDisplay.push(m));

        this.form = new FormGroup({
            title: new FormControl(data.scenario.title),
            messages: new FormArray(data.scenario.messages?.length > 0 ? data.scenario.messages.map(m => this.addMessageForm(m)) : [this.addMessageForm()], [minLength(1)])
        });

        this.messagesControls = this.form.get('messages') as FormArray;
    }

    private addMessageForm(message: ChatScenarioMessage = null): FormGroup {
        const formGroup = new FormGroup({
            message: new FormControl(message?.message, [Validators.required]),
            nbDaysAfter: new FormControl(message?.nbDaysAfter, [Validators.required, Validators.min(1)]),
            reopenChatIfClosed: new FormControl(!(message?.numberDaysToOpenIfClosed === 0)),
            numberDaysToOpenIfClosed: new FormControl(message?.numberDaysToOpenIfClosed)
        });
        formGroup.setParent(this.messagesControls);
        return formGroup;
    }

    close(): void {
        this.dialogRef.close();
    }

    submit(): void {
        if (this.form.valid) {
            const val = new ChatScenario();
            val.title = this.form.get('title').value.trim();
            val.messages = this.messagesControls.value.map(message => {
                const messageVal = new ChatScenarioMessage();
                messageVal.message = message.message.trim().replace('/', '').toLowerCase();
                messageVal.nbDaysAfter = +message.nbDaysAfter;
                messageVal.numberDaysToOpenIfClosed = +message.numberDaysToOpenIfClosed;
                return messageVal;
            });
            this.dialogRef.close(val);
        }
    }

    addMessage(): void {
        this.dialog.open(ScenarioMessageDialogComponent, {
            data: {
                message: {
                    message: null,
                    nbDaysAfter: null,
                    // défault value suggested when adding a new message to the scenario
                    numberDaysToOpenIfClosed: 7
                },
                shortcuts: this.shortcuts
            },
            panelClass: 'no-padding-dialog',
            width: '800px',
            disableClose: true
        }).afterClosed().subscribe((c: ChatScenarioMessage) => {
            if (c !== null) {
                // and not this.messagesToDisplay.push(c) => 2h wasted !!!
                this.messagesToDisplay = [...this.messagesToDisplay, c];
                this.updateForm(this.messagesToDisplay);
                this.cdRef.detectChanges();
            }
        });
    }

    updateMessage(data: ChatScenarioMessage[]): void {
        this.updateDataTable(data);
        this.updateForm(data);
    }

    deleteMessage(data: ChatScenarioMessage[]): void {
        this.updateDataTable(data);
        this.updateForm(data);
    }

    private updateForm(data: ChatScenarioMessage[]): void {
        this.messagesControls.clear();
        data.forEach(m => {
            this.messagesControls.push(this.addMessageForm(m));
        });
    }

    private updateDataTable(data: ChatScenarioMessage[]): void {
        this.messagesToDisplay = data;
    }
}

export interface ChatScenarioDialogInterface {
    scenario: ChatScenario;
    shortcuts: ChatShortcut[];
}

function minLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
        if (!(control instanceof FormArray)) {
            return;
        }

        return control.length < min ? { minLength: true } : null;
    };
}

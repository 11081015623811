import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

export class TranslateHttpLoaderFallback implements TranslateLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string): Observable<any> {
        return this.http.get('./assets/i18n/' + lang + '.json').pipe(
            catchError(() => this.http.get('./assets/i18n/' + lang.slice(0, 2) + '.json'))
        );
    }
}

import { JsonProperty } from 'json-object-mapper';
import { UtilisateurPostInterface } from '../interfaces/post/utilisateurs/utilisateur-post.interface';
import { Fichier } from '../fichier';
import { Entity } from '../entity';
import { ConnectorIdInterface } from '../interfaces/connector-id.interface';
import { DateMapper } from '../../object-mapper/date-mapper';
import { EntiteGeographique } from '../pro/entite-geographique';

export class Utilisateur extends Entity implements ConnectorIdInterface {
    @JsonProperty()
    civilite: string = null;

    @JsonProperty()
    nom: string = null;

    @JsonProperty()
    prenom: string = null;

    @JsonProperty()
    password: string = null;

    @JsonProperty()
    username: string = null;

    @JsonProperty()
    mail?: string = null;

    @JsonProperty()
    typeUtilisateur?: TypeUtilisateurEnum = null;

    @JsonProperty()
    telephonePortable?: string = null;

    @JsonProperty()
    locale: string = null;

    @JsonProperty()
    timezone: string = null;

    @JsonProperty()
    enable: boolean = null;

    @JsonProperty()
    cguConfirmed: boolean = null;

    @JsonProperty({ type: Fichier })
    photo?: Fichier = null;

    @JsonProperty()
    cguMustConfirm: boolean = null;

    @JsonProperty()
    passMustChange: boolean = null;

    @JsonProperty()
    connectorId?: string = null;

    @JsonProperty()
    canBeDeleted: boolean = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    lastConnection: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateSignupSended: Date = null;

    @JsonProperty()
    stripeClientId?: string = null;

    @JsonProperty()
    stripePublishableKey?: string = null;

    @JsonProperty()
    lastUserAgentUsed?: string = null;

    public get simpleLocale(): string {
        if (this.locale) {
            const splited = this.locale.split('_');
            if (splited.length > 0) {
                return splited[0].toLowerCase();
            }
        }

        return 'en';
    }

    public get localeIso(): string {
        return this.locale?.replace('_', '-') ?? 'en';
    }

    public get fullName(): string {
        return (this.civilite ? this.civilite + ' ' : '') + ' ' + this.fullNameLite;
    }

    public get fullNameLite(): string {
        return (this.prenom ? this.prenom + ' ' : '') + this.nom;
    }

    public get clinique(): string {
        return this.getEntiteGeographique() ? this.getEntiteGeographique().nom : null;
    }

    // tout ce bazar car si on met la prpriété entiteGeographique dans l'Utilisateur, on a des dépendances cyclique
    private getEntiteGeographique(): EntiteGeographique {
        return (this as any).entiteGeographique as EntiteGeographique;
    }

    public hasPost(): UtilisateurPostInterface {
        return {
            id: this.id,
            typeUtilisateur: this.typeUtilisateur,
            civilite: this.civilite,
            nom: this.nom,
            prenom: this.prenom,
            password: this.password,
            username: this.username,
            mail: this.mail,
            telephonePortable: this.telephonePortable,
            locale: this.locale,
            timezone: this.timezone,
            enable: this.enable,
            photo: this.photo ? this.photo.url : null,
            connectorId: this.connectorId,
            entiteGeographique: this.getEntiteGeographique() ? this.getEntiteGeographique().id : null
        };
    }
}

export enum TypeUtilisateurEnum {
    veterinaire = 'veterinaire',
    client = 'client',
}

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text mb-16">
            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">assignment</mat-icon>
                <span class="logo-text h1">{{ 'REPORTS.HISTORY.MAIN_TITLE' | translate }} {{ animal?.nom }} </span>
            </div>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">


            <div class="custom-container mat-elevation-z8">

                <div class="custom-loading-shade" *ngIf="isLoadingResults">
                    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                </div>

                <div class="custom-table-container">

                    <table mat-table [dataSource]="dataSource" matSort class="custom-table">

                        <!-- ID Column -->
                        <ng-container matColumnDef="rdvDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_DATE' | translate }}</th>
                            <td mat-cell *matCellDef="let row"> <a routerLink="/report/{{row.rdvId}}"> {{row.rdvDate?.date + '+0000' | dateLocale:'short' }} </a></td>
                        </ng-container>

                        <!-- Vet Column -->
                        <ng-container matColumnDef="vetLastName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_VET' | translate }}</th>
                            <td mat-cell *matCellDef="let row">{{row.vetFirstName|titlecase }} {{row.vetLastName|uppercase}}</td>
                        </ng-container>

                        <!-- Notes Column -->
                        <ng-container matColumnDef="noteId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_NOTES' | translate }}</th>
                            <td mat-cell *matCellDef="let row"> <mat-icon>{{row.noteId ? 'check' : 'close'}}</mat-icon></td>
                        </ng-container>

                        <!-- Target column -->
                        <ng-container matColumnDef="reportTarget">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_TARGET' | translate }}</th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="row.reportTarget">{{ row.reportTarget == reportTarget.Vet ? 'Vétérinaire' : 'Client'}}</span>
                                <span *ngIf="!row.reportTarget"><mat-icon>close</mat-icon></span>
                            </td>
                        </ng-container>

                        <!-- Update date -->
                        <ng-container matColumnDef="reportUpdatedAt">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_VERSION' | translate }}</th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="row.reportUpdatedAt">{{row.reportUpdatedAt?.date + '+0000' | dateLocale:'short' }}</span>
                                <span *ngIf="!row.reportUpdatedAt"><mat-icon>close</mat-icon></span>
                            </td>
                        </ng-container>

                        <!-- Email sending date -->
                        <ng-container matColumnDef="reportSendingDate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'REPORTS.HISTORY.HEADER_EMAIL_DATE' | translate }}</th>
                            <td mat-cell *matCellDef="let row">
                                <span *ngIf="row.reportSendingDate">{{row.reportSendingDate?.date + '+0000' | dateLocale:'short' }}</span>
                                <span *ngIf="!row.reportSendingDate"><mat-icon>close</mat-icon></span>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                    </table>

                </div>

                <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>

            </div>

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->
</div>

import { Component, Input } from '@angular/core';
import { Utilisateur } from '../../../../models/utilisateurs/utilisateur';
import { Animal } from '../../../../models/animal/animal';
import { UtilisateurPostInterface } from '../../../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { AnimalPostInterface } from '../../../../models/interfaces/post/animal/animal-post.interface';
import { Fichier } from 'app/models/fichier';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
    @Input() entity: Utilisateur | UtilisateurPostInterface | Animal | AnimalPostInterface;
    @Input() url: Fichier;
    @Input() emoji: string;
    @Input() size: AvatarSize = AvatarSize.MEDIUM;
}

export enum AvatarSize {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    XLARGE = 'XLARGE'
}

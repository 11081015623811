<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">location_on</mat-icon>
                <span class="logo-text h1">{{ 'ENTITE_GEO.ENTITE_GEOS' | translate }}</span>
            </div>
            <!-- / APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="search" matInput #filter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="isOwner">
                    <button *ngIf="(utilisateurService?.utilisateurConnected | async)?.isProprietaire" mat-raised-button
                            class="my-12 mt-sm-0"
                            color="primary" (click)="openEntiteGeographique()">
                        <mat-icon>add</mat-icon>
                        {{ 'ENTITE_GEO.ADD' | translate }}
                    </button>
                </div>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table class="entites-geographique-table" #table [dataSource]="dataSource" matSort
                       [class.hidden]="(dataSource?.loading$ | async) || (dataSource?.isEmpty() | async)">
                <ng-container cdkColumnDef="nom">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                        class="header-entite-geographique">{{ 'FORM.NAME' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"
                             class="cursor-pointer" (click)="openEntiteGeographique(entiteGeographique)">
                            <p class="text-truncate">{{ entiteGeographique.nom }}</p>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="mail">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span class="header-entite-geographique">{{ 'FORM.MAIL' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <p class="text-truncate">{{ entiteGeographique.mail }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="telephone">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span class="header-entite-geographique">{{ 'FORM.PHONE' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <p class="text-truncate">{{ entiteGeographique.telephone | phone }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="ville">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span class="header-entite-geographique">{{ 'FORM.CITY' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <p class="text-truncate">{{ entiteGeographique.ville }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="codePostal">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span class="header-entite-geographique">{{ 'FORM.CP' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <p class="text-truncate">{{ entiteGeographique.codePostal }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                    <mat-header-cell *cdkHeaderCellDef>
                      <span class="header-entite-geographique" *ngIf="clientSignupEnabled">
                        {{ 'SETTINGS.ONBOARDING_CLIENT.PLACEHOLDER' | translate }}
                      </span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let entiteGeographique">
                        <div fxLayout="row" fxLayoutGap="8">
                            <button mat-button
                                color="secondary-text"
                                [matTooltip]="'SETTINGS.ONBOARDING_CLIENT.TOOLTIP_LINK' | translate"
                                *ngIf="entiteGeographique.clientSignupCode"
                                (click)="copySignupCode(entiteGeographique)">
                                <mat-icon>content_copy</mat-icon>
                                {{ entiteGeographique.clientSignupCode }}
                            </button>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="openEntiteGeographique(entiteGeographique)">
                                    <mat-icon>info</mat-icon>
                                    <span>{{ 'SHARED.DETAILS' | translate }}</span>
                                </button>
                            </mat-menu>
                            <button mat-button color="secondary-text" [matMenuTriggerFor]="actionMenu" matTooltip="{{ 'SHARED.ACTIONS' | translate }}"
                                class="more-button">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource?.loading$ | async; else loaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="dataSource?.isEmpty() | async" class="primary-fg">
                    <div class="text-center m-8">
                        <p>{{ 'ENTITE_GEO.ANY' | translate }}</p>
                    </div>
                </div>
            </ng-template>

            <mat-paginator #paginator [length]="dataSource?.totalCount$ | async" [pageIndex]="0" [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

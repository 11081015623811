import { JsonProperty } from 'json-object-mapper';
import { HistoriqueVisio } from '../rendez-vous/historique-visio';

export class MakeCallResponse {
    @JsonProperty()
    opentokApiKey: string = null;

    @JsonProperty({ type: HistoriqueVisio })
    visio?: HistoriqueVisio = null;
}

import { Environment } from './environment.interface';
import infos from '../../infos.json';

export const environment: Environment = {
    production: false,
    preproduction: false,
    demo: false,
    dev: true,
    hmr: false,

    version: infos.version,
    buildVersion: infos.lastBuild,
    lastCommit: infos.lastCommit,

    apiUrl: 'https://univet-dev-api.2dia.fr/',
    sendbirdAppId: 'E3FBEC9A-A61C-4C3D-897F-3708DF57431E',

    oauthClientId: 'wkPuncRigwUEASAu4rFfKh3PRcvWeSRfMtl6Dd5rJd3VVPBlsCOuOh4fhZCaHU4o',
    oauthClientSecret: 'Tlt910wACFEcrldShrNsz8zSbNXns8IEBtujHbJ84hGpMtVMPC1VdbXLLBXdc08v',

    oneSignalAppId: 'ec40e299-d28a-47de-9db9-cb862a5b2cff',

    stripeClientId: 'ca_DgkX58RAKwDqrOp4UItPx2eg8fly3BXW',
    stripePublicKey: 'pk_test_1Qz6HjQlufgG5ZwV1RRndgsk',

    googleAnalytics: 'G-QX9SGYLL93',
    googleApiKey: 'AIzaSyAnlIY5oLIp7WeBfSQ9uvoyIyK-OYHQbO4',

    sentryDsn: 'https://4d13191fd6f44585b0f2ad6cb0b76a56@o368540.ingest.sentry.io/6094916',

    signUpClientUrl: 'https://uni.vet/',

    firebase: {
      apiKey: 'AIzaSyCZJ1XlchHQI6-mqkXACM3xI3hBizYhtEk',
      authDomain: 'linkyvet.firebaseapp.com',
      databaseURL: 'https://linkyvet.firebaseio.com',
      projectId: 'linkyvet',
      storageBucket: 'linkyvet.appspot.com',
      messagingSenderId: '155738908358',
      appId: '1:155738908358:web:94088b0502e4802f2b99c7',
      measurementId: 'G-GJYLG9EB1E'
    },

    mediprod: {
        url: 'https://console.visiocaresuite.com/',
        shopUrl: 'https://mediproductions.com/',
        version: 1,
        partnerName: 'linkyvet'
    },

    supportUrl: 'https://www.univet-veterinaire.com/contact/'
};

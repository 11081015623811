import { IdInterface } from './id.interface';
import { DocumentStatus, DocumentType } from '../rendez-vous/text-document';

export enum RecordingOptions{
    ORIGINAL = 'original',
    COPY = 'copy'
}

export interface TemplatePostInterface extends IdInterface {
    isDefault: boolean;
    name: string;
    content: string;
    status?: DocumentStatus;
    type?: DocumentType;
    //dateCreated?: Date;
    //dateLastRecorded: Date;
    recordingOption: RecordingOptions;
}

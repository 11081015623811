import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { News, NewsList } from 'app/models/news/news';
import { NewsDialogComponent, NewsDialogInterface } from 'app/main/shared/form-dialog/news-dialog/news-dialog.component';
import { NewsPostInterface } from 'app/models/interfaces/post/news-post.interface';

@Injectable({
    providedIn: 'root'
})
export class NewsService {
    private _news: BehaviorSubject<News[]> = new BehaviorSubject<News[]>([]);

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public get(id: number): Observable<News> {
        return this.http.get<News>(this.config.baseUrl + 'api/news/' + id.toString()).pipe(
            map(r => ObjectMapper.deserialize(News, r)),
            tap(theNews => {
                const allNews = this._news.getValue();
                const theNewsIndex = allNews.findIndex(eg => eg.id === theNews.id);
                if (theNewsIndex !== -1) {
                    allNews[theNewsIndex] = theNews;
                }

                this._news.next(allNews);
            })
        );
    }

    public getAll(
        keywords: string = null,
        status = 'draft',
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit = null
    ): Observable<NewsList> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        params = params.append('status', status);

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        return this.http.get<NewsList>(this.config.baseUrl + 'api/news', {
            params: params
        }).pipe(
            map(list => ObjectMapper.deserialize(NewsList, list))
        );
    }

    public add(data: NewsPostInterface, image?: File): Observable<News> {
        this.snackbar.open(this.translateService.instant('NEWS.API.ADDING'), null);

        if ((data.publishedDate as any) instanceof Date) {
            data.publishedDate = Math.round((data.publishedDate as any).getTime() / 1000);
        }

        const formData = new FormData();
        formData.append('data', ObjectMapper.serialize(data).toString());
        if (image) {
            formData.append('image', image);
        }

        return this.http.post<News>(this.config.baseUrl + 'api/news', formData).pipe(
            map(r => ObjectMapper.deserialize(News, r)),
            tap(newNews => {
                const allNews = this._news.getValue();
                allNews.push(newNews);
                this._news.next(allNews);
                this.snackbar.open(this.translateService.instant('NEWS.API.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public update(data: NewsPostInterface, image?: File): Observable<News> {
        this.snackbar.open(this.translateService.instant('NEWS.API.UPDATING'), null);

        if ((data.publishedDate as any) instanceof Date) {
            data.publishedDate = Math.round((data.publishedDate as any).getTime() / 1000);
        }

        const formData = new FormData();
        formData.append('data', ObjectMapper.serialize(data).toString());

        if (image) {
            formData.append('image', image);
        }

        return this.http.post<News>(this.config.baseUrl + 'api/news/' + data.id.toString(), formData).pipe(
            map(r => ObjectMapper.deserialize(News, r)),
            tap(newNews => {
                const allNews = this._news.getValue();
                const newsIndex = allNews.findIndex(eg => eg.id === newNews.id);
                if (newsIndex !== -1) {
                    allNews[newsIndex] = newNews;
                }

                this._news.next(allNews);
                this.snackbar.open(this.translateService.instant('NEWS.API.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public archive(id: number): Observable<News> {
        this.snackbar.open(this.translateService.instant('NEWS.API.ARCHIVING'), null);
        return this.http.patch<News>(this.config.baseUrl + 'api/news/' + id.toString() + '/archive', null).pipe(
            map(r => ObjectMapper.deserialize(News, r)),
            tap(newNews => {
                const allNews = this._news.getValue();
                const newsIndex = allNews.findIndex(eg => eg.id === newNews.id);
                if (newsIndex !== -1) {
                    allNews[newsIndex] = newNews;
                }

                this._news.next(allNews);
                this.snackbar.open(this.translateService.instant('NEWS.API.ARCHIVED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public delete(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('NEWS.API.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('NEWS.API.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('NEWS.API.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(confirmed => Boolean(confirmed)),
            switchMap(() => {
                this.snackbar.open(this.translateService.instant('NEWS.API.DELETING'), null);
                return this.http.delete(this.config.baseUrl + 'api/news/' + id.toString())
                    .pipe(tap(() => {
                        this.snackbar.open(this.translateService.instant('NEWS.API.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    }));
            })
        );
    }

    public openAddOrEditDialog(data?: NewsDialogInterface): MatDialogRef<NewsDialogComponent> {
        return this.dialog.open(NewsDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }
}

import { NgModule } from '@angular/core';
import { ReportBugDialogComponent } from 'app/main/shared/report-bug-dialog/report-bug-dialog.component';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { SharedModule } from '../../../shared.module';

@NgModule({
    declarations: [
        ReportBugDialogComponent
    ],
    imports: [
        FuseSharedModule,
        SharedModule,
        ViewSharedModule
    ],
    exports: [
        ReportBugDialogComponent
    ]
})

export class ReportBugDialogModule {}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { RendezVousPostInterface } from '../../../../models/interfaces/post/rendez-vous-post.interface';
import { ConfigService } from '../../../../services/config.service';
import { CategorieInterface } from '../../../../models/interfaces/categorie.interface';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { CurrencySymbolPipe } from '../../../../pipes/currency-symbol.pipe';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyMaskConfig } from 'ngx-currency';
import { ConnecteurService } from 'app/services/api/connecteur.service';

@Component({
    selector: 'app-rendez-vous-form',
    templateUrl: './rendez-vous-form.component.html',
    styleUrls: ['./rendez-vous-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RendezVousFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<RendezVousPostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();
    @Input('formInProgressDelete') formInProgressDelete$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() canBeFullEdited: boolean;
    @Input() canBeRemoved: boolean;
    @Input() canCall: boolean;
    @Input() pricePayed: number;
    @Input() datePayed: Date;
    @Input() mustCapture: boolean;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<RendezVousPostInterface>();
    @Output('formDeleted') formDeleted$ = new EventEmitter<boolean>();

    @Output('entityReceived') entityReceived$ = new EventEmitter<RendezVous>();
    @Output('entityDeleted') entityDeleted$ = new EventEmitter<boolean>();

    @Output('entityCalled') entityCalled$ = new EventEmitter<boolean>();
    @Output('entityCapture') entityCapture$ = new EventEmitter<boolean>();

    form: FormGroup;

    categoriesFilter: FormControl = new FormControl();
    categories: CategorieInterface[] = [];
    filteredCategoriesOptions$: BehaviorSubject<CategorieInterface[]> = new BehaviorSubject<CategorieInterface[]>(null);

    country: string;
    paymentEnabled: boolean;
    entityLinked = false;
    usePriceHt = false;

    private subscriptionEntityData: Subscription;
    private subscriptionCategories: Subscription;

    public minDate: Date;

    constructor(
        public configService: ConfigService,
        public connecteurService: ConnecteurService,
        private rendezVousService: RendezVousService,
        private utilisateurService: UtilisateurService,
        private translateService: TranslateService) {
        this.minDate = new Date();

        this.form = new FormGroup({
            id: new FormControl(),
            nature: new FormControl(null, [Validators.required]),
            categorie: new FormControl(null, [Validators.required]),
            dureePrevue: new FormControl(null, [Validators.required, Validators.min(1)]),
            date: new FormControl(null, [Validators.required]),
            isFree: new FormControl(),
            prix: new FormControl(25, [Validators.required, Validators.min(1)]),
            priceWithoutVat: new FormControl([Validators.min(1)]),
            currency: new FormControl(null),
            commentaire: new FormControl(null),
            animalId: new FormControl(null, [Validators.required]),
            veterinaire: new FormControl(null, [Validators.required]),
            connectorId: new FormControl()
        });

        this.usePriceHt = this.utilisateurService.utilisateurConnectedValue.entiteJuridique.appointmentVatEnabled;

        this.form.get('id').disable();

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.form.get('priceWithoutVat').valueChanges.subscribe(_ => this.computePrice());

        this.categoriesFilter.valueChanges
            .subscribe(() => {
                const filteredCategoriesOptions = this.categoriesFilter.value ? this.categories.filter(espece => espece.value.toLowerCase().includes(this.categoriesFilter.value.toLowerCase())) : this.categories;

                this.filteredCategoriesOptions$.next(filteredCategoriesOptions);
            });
    }

    ngOnInit(): void {
        this.paymentEnabled = this.utilisateurService.utilisateurConnectedValue.entiteJuridique.paymentEnabled;
        this.country = this.utilisateurService.utilisateurConnectedValue.entiteJuridique.paysFacturation;

        this.form.get('isFree').valueChanges.subscribe(() => {
            this.computeStatePaymentControls();
        });

        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((rendezVousData: RendezVousPostInterface) => {
            this.form.patchValue({
                id: rendezVousData.id,
                nature: rendezVousData.nature,
                categorie: rendezVousData.categorie,
                dureePrevue: rendezVousData.dureePrevue,
                date: rendezVousData.date,
                isFree: !(rendezVousData.prix > 0),
                prix: rendezVousData.prix,
                priceWithoutVat: this.usePriceHt ? rendezVousData.prix : null,
                currency: rendezVousData.currency ? rendezVousData.currency : this.utilisateurService.utilisateurConnectedValue.entiteJuridique.currency,
                commentaire: rendezVousData.commentaire,
                animalId: rendezVousData.animalId,
                veterinaire: rendezVousData.veterinaire,
                connectorId: rendezVousData.connectorId
            }, { emitEvent: false });

            if (rendezVousData.id) {
                if (this.canBeFullEdited) {
                    this.form.enable();
                    this.form.get('id').disable();
                    this.form.get('animalId').disable();
                    this.computeStatePaymentControls();
                } else if (this.canBeRemoved) {
                    this.form.disable();
                    this.form.get('commentaire').enable();
                    this.form.get('nature').enable();
                    this.form.get('categorie').enable();
                    this.form.get('dureePrevue').enable();
                    this.form.get('veterinaire').enable();
                } else {
                    this.form.disable();
                }

                this.entityLinked = Boolean(this.form.get('connectorId').value);

                if (this.entityLinked) {
                    this.form.disable();
                }
            } else {
                this.computeStatePaymentControls();
            }

            this.computePrice();
        });

        this.subscriptionCategories = this.configService.getCategories().subscribe(categories => {
            this.categories = categories;
            this.filteredCategoriesOptions$.next(this.categories);
        });
    }

    private computeStatePaymentControls(): void {
        if (this.form.get('isFree').value) {
            this.form.get('prix').disable();
            this.form.get('priceWithoutVat').disable();
            this.form.get('currency').disable();
            this.form.get('prix').setValue(null);
            this.form.get('priceWithoutVat').setValue(null);
        } else {
            if (this.usePriceHt) {
                this.form.get('prix').disable();
                this.form.get('priceWithoutVat').enable();
                if (!this.form.get('priceWithoutVat').value) {
                    this.form.get('priceWithoutVat').setValue(20.8); // Univet
                }
            } else {
                this.form.get('prix').enable();
                this.form.get('priceWithoutVat').disable();
                if (!this.form.get('prix').value) {
                    this.form.get('prix').setValue(25); // Univet
                }
            }

            this.form.get('currency').enable();
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }

        if (this.subscriptionCategories) {
            this.subscriptionCategories.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save(this.form.getRawValue());
            }
        }
    }

    public capture(): void {
        if (this.mustCapture) {
            this.entityCapture$.next(true);
        }
    }

    private save(data: RendezVousPostInterface) {
        this.formInProgress$.next(true);

        if (data.prix === null) {
            data.prix = 0;
        }

        if (data.id) {
            this.rendezVousService.updateRendezVous(data).subscribe({
                next: rdv => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(rdv);
                },
                error: () => this.formInProgress$.next(false)
            });
        } else {
            this.rendezVousService.addRendezVous(data).subscribe({
                next: rdv => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(rdv);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }

    remove(): void {
        if (this.canBeRemoved) {
            this.formDeleted$.next(true);
            if (this.handleForm) {
                this.removeRendezVous();
            }
        }
    }

    call(): void {
        if (this.canCall) {
            this.entityCalled$.next(true);
        }
    }

    private removeRendezVous() {
        this.formInProgressDelete$.next(true);
        this.rendezVousService.deleteRendezVous(this.form.get('id').value).subscribe({
            next: () => {
                this.formInProgressDelete$.next(false);
                this.entityDeleted$.next(true);
            },
            complete: () => this.formInProgressDelete$.next(false)
        });
    }

    getCurrencyInputOptions(): Partial<CurrencyMaskConfig> {
        const currency = this.form.get('currency').value;
        const currencySymbolPipe = new CurrencySymbolPipe(this.translateService);
        const currencySymbol = currencySymbolPipe.transform(currency);
        const currencyMaskConfig: Partial<CurrencyMaskConfig> = {
            allowNegative: false,
            prefix: currencySymbol + ' ',
            nullable: true,
            min: 1,
            inputMode: 1
        };

        if (currency === 'USD') {
            return currencyMaskConfig;
        }

        currencyMaskConfig.prefix = '';
        currencyMaskConfig.suffix = ' ' + currencySymbol;
        currencyMaskConfig.decimal = ',';
        currencyMaskConfig.thousands = ' ';
        return currencyMaskConfig;
    }

    private computePrice(): void {
        if (this.usePriceHt) {
            const priceHt = this.form.get('priceWithoutVat').value;
            this.form.get('prix').setValue(priceHt * (1 + this.utilisateurService.utilisateurConnectedValue.entiteJuridique.appointmentVatPercent / 100));
        }
    }
}

import { NgModule } from '@angular/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { VisioComponent } from './visio.component';
import { PublisherComponent } from './visio-publisher/visio-publisher.component';
import { SubscriberComponent } from './visio-subscriber/visio-subscriber.component';
import { GestionComponent } from './visio-gestion/visio-gestion.component';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { PointerComponent } from './visio-pointer/visio-pointer.component';
import { RouterModule } from '@angular/router';
import { ChatModule } from '../chat/chat.module';
import { ActionComponent } from './visio-action/visio-action.component';
import { VisioCanDeactivate } from './visio.can-deactivate';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedModule } from '../../../shared.module';
import { VisioPaymentChargeDialogComponent } from './visio-payment-charge-dialog/visio-payment-charge-dialog.component';
import { ListFichierModule } from '../../shared/list-fichier/list-fichier.module';
import { VisioTimerComponent } from './visio-timer/visio-timer.component';
import { VisioSettingsDialogComponent } from './visio-settings-dialog/visio-settings-dialog.component';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { PublisherVideoComponent } from './visio-publisher-video/visio-publisher-video.component';

const routes = [
    {
        path: 'visio/:rdvId',
        component: VisioComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard],
        canDeactivate: [VisioCanDeactivate]
    }
];

@NgModule({
    declarations: [
        VisioComponent,
        PublisherComponent,
        PublisherVideoComponent,
        SubscriberComponent,
        GestionComponent,
        PointerComponent,
        ActionComponent,
        VisioTimerComponent,
        VisioPaymentChargeDialogComponent,
        VisioSettingsDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        LazyLoadImageModule,
        ViewSharedModule,
        ChatModule,
        ListFichierModule
    ]
})

export class VisioModule {}

import { Component, Input, OnDestroy, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { InputPhoneComponent } from '../../shared/form/inputs/input-phone/input-phone.component';

@Component({
    selector: 'app-step-clinic',
    templateUrl: './step-clinic.component.html',
    styleUrls: ['./step-clinic.component.scss']
})
export class StepClinicComponent implements OnDestroy {
    searchDone = false;
    @Input() form: FormGroup;
    @ViewChild('inputPhone', { static: true }) inputPhone: InputPhoneComponent;

    searchFilterControl: FormControl = new FormControl();
    entities$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    loading: boolean;

    private subcriptions: Subscription[] = [];

    constructor(
        private translateService: TranslateService,
        public zone: NgZone
    ) {
        this.subcriptions.push(
            this.searchFilterControl.valueChanges.pipe(
                debounceTime(300)
            ).subscribe(value => {
                this.form.get('name').setValue(value);
                this.searchPlaces(value);
            })
        );
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subscription: Subscription) => {
                subscription.unsubscribe();
            });
        }
    }

    onEntitySelected(event: MatAutocompleteSelectedEvent): void {
        if (event?.option?.value) {
            const entities = this.entities$.value;
            const entity = entities.find(x => x.place_id === event.option.value);
            this.searchFilterControl.setValue(entity.name);
            this.getDetailPlace(entity.place_id);
        } else {
            this.searchFilterControl.setValue(this.form.get('name').value);
            this.searchDone = true;
        }
    }

    private searchPlaces(input: string) {
        if (!input) {
            this.entities$.next(null);
            this.loading = false;
            return;
        }

        this.loading = true;
        const service = new google.maps.places.AutocompleteService();

        const request: google.maps.places.AutocompletionRequest = {
            input,
            types: ['establishment']
        };

        void service.getPlacePredictions(request, res => {
            this.zone.run(() => {
                if (res) {
                    let entities = res.map(x => ({
                        place_id: x.place_id,
                        name: x.structured_formatting.main_text,
                        formatted_address: x.structured_formatting.secondary_text
                    }));

                    if (entities.length > 4) {
                        entities = entities.slice(0, 4);
                    }

                    entities.push({
                        place_id: null,
                        name: this.translateService.instant('ONBOARDING_PRO.STEPS.CLINIC.OTHER'),
                        formatted_address: null
                    });
                    this.entities$.next(entities);
                } else {
                    this.entities$.next(
                        [
                            {
                                place_id: null,
                                name: this.translateService.instant('ONBOARDING_PRO.STEPS.CLINIC.OTHER'),
                                formatted_address: null
                            }
                        ]
                    );
                }

                this.loading = false;
            });
        });
    }

    private getDetailPlace(placeId: string) {
        this.loading = true;
        const service = new google.maps.places.PlacesService(document.createElement('div'));

        const request = {
            placeId,
            fields: ['name', 'address_components', 'formatted_phone_number']
        };

        service.getDetails(request, res => {
            this.zone.run(() => {
                if (res) {
                    try {
                        this.form.patchValue({
                            name: res.name,
                            address: (res?.address_components?.find(x => Boolean(x?.types?.includes('street_number')))?.long_name ?? '') + ' ' + (res?.address_components?.find(x => Boolean(x?.types?.includes('route')))?.long_name ?? '').trim(),
                            zipCode: res?.address_components?.find(x => Boolean(x?.types?.includes('postal_code')))?.long_name ?? '',
                            city: res?.address_components?.find(x => Boolean(x?.types?.includes('locality')))?.long_name ?? '',
                            country: res?.address_components?.find(x => Boolean(x?.types?.includes('country')))?.short_name ?? '',
                            phone: res.formatted_phone_number
                        });

                        this.inputPhone.setNewValue(res.formatted_phone_number);
                    } catch (err) {
                        console.error(err);
                    }
                }

                this.loading = false;
                this.searchDone = true;
            });
        });
    }
}

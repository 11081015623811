import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaysInterface } from '../../../../../models/interfaces/pays.interface';
import { ConfigService } from '../../../../../services/config.service';

@Component({
    selector: 'app-input-pays',
    templateUrl: './input-pays.component.html',
    styleUrls: ['./input-pays.component.scss']
})
export class InputPaysComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() required: boolean;
    @Input() disabled: boolean;
    @Input() label: string;
    @Input() isForPhoneCountryCode = false;

    paysFilter = new FormControl();
    paysList: PaysInterface[] = [];
    filteredPaysOptions$: BehaviorSubject<PaysInterface[]> = new BehaviorSubject<PaysInterface[]>(null);

    private subscriptionPays: Subscription;

    constructor(private configService: ConfigService) {}

    ngOnInit(): void {
        this.paysFilter.valueChanges
            .subscribe(() => {
                const filteredPaysOptions = this.paysFilter.value ? this.paysList.filter(pays => (pays.name.toLowerCase() + pays.phoneCode).includes(this.paysFilter.value.toLowerCase())) : this.paysList;

                this.filteredPaysOptions$.next(filteredPaysOptions);
            });

        this.subscriptionPays = this.configService.getPaysList().subscribe(paysList => {
            this.paysList = paysList;
            this.filteredPaysOptions$.next(paysList);
        });
    }

    ngOnDestroy(): void {
        this.subscriptionPays?.unsubscribe();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {
    AuthResponseInterface,
    SwitchAuthResponseInterface,
    TokenInfoResponseInterface
} from '../../models/interfaces/auth/auth-response.interface';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ConfigService } from '../config.service';
import { UtilisateurService } from './utilisateur.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomHttpParamEncoder } from '../../object-mapper/http-param-encoder';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private refreshInProgress: boolean;
    private refreshRequest$ = new Subject<boolean | AuthResponseInterface>();

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private router: Router,
        private cookie: CookieService,
        private config: ConfigService,
        private utilisateurService: UtilisateurService,
        private snackbar: MatSnackBar
    ) {}

    obtainAccessToken(username: string, password: string): Observable<AuthResponseInterface> {
        const params = new HttpParams({
            encoder: new CustomHttpParamEncoder()
        })
            .append('username', username.trim())
            .append('password', password.trim())
            .append('grant_type', 'password')
            .append('client_id', environment.oauthClientId)
            .append('client_secret', environment.oauthClientSecret);

        return this.http.post<AuthResponseInterface>(this.config.baseUrl + 'oauth/v2/token', params.toString(), {
            headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
        }).pipe(
            tap((data: AuthResponseInterface) => this.saveToken(data))
        );
    }

    switchUser(userId: number): Observable<SwitchAuthResponseInterface> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.SWITCHING'), null, { duration: -1 });
        return this.http.get<SwitchAuthResponseInterface>(this.config.baseUrl + 'api/user/' + userId.toString() + '/switch').pipe(
            tap((data: SwitchAuthResponseInterface) => {
                const newToken: AuthResponseInterface = {
                    access_token: data.accessToken,
                    expires_in: data.expiresIn,
                    token_type: data.tokenType,
                    scope: data.scope,
                    refresh_token: data.refreshToken
                };
                this.saveToken(newToken);
                this.snackbar.open(this.translateService.instant('UTILISATEUR.SWITCHED'), null, { duration: 1500 });
                setTimeout(() => {
                    window.location.href = '/';
                });
            })
        );
    }

    switchEntiteGeographique(entiteGeographiqueId: number): Observable<SwitchAuthResponseInterface> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.SWITCHING'), null, { duration: -1 });
        return this.http.get<SwitchAuthResponseInterface>(this.config.baseUrl + 'api/user/entite_geographique/' + entiteGeographiqueId.toString() + '/switch').pipe(
            tap((data: SwitchAuthResponseInterface) => {
                const newToken: AuthResponseInterface = {
                    access_token: data.accessToken,
                    expires_in: data.expiresIn,
                    token_type: data.tokenType,
                    scope: data.scope,
                    refresh_token: data.refreshToken
                };
                this.saveToken(newToken);
                this.snackbar.open(this.translateService.instant('UTILISATEUR.SWITCHED'), null, { duration: 1500 });
                setTimeout(() => {
                    window.location.href = '/';
                });
            })
        );
    }

    refreshAccessToken(): Observable<boolean | AuthResponseInterface> {
        if (this.canRefresh) {
            if (!this.refreshInProgress) {
                this.refreshInProgress = true;

                const params = new HttpParams({
                    encoder: new CustomHttpParamEncoder()
                })
                    .append('grant_type', 'refresh_token')
                    .append('refresh_token', this.cookie.get('refresh_token'))
                    .append('client_id', environment.oauthClientId)
                    .append('client_secret', environment.oauthClientSecret);

                return this.http.post<AuthResponseInterface>(this.config.baseUrl + 'oauth/v2/token', params.toString(), {
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                }).pipe(
                    catchError((err: Error) => {
                        alert(this.translateService.instant('AUTH.REFRESH_FAILED'));
                        this.logout(false);
                        this.refreshInProgress = false;
                        this.refreshRequest$.next(false);
                        return throwError(() => err);
                    }),
                    tap((data: AuthResponseInterface) => {
                        this.saveToken(data);
                        this.refreshInProgress = false;
                        this.refreshRequest$.next(data);
                    })
                );
            }

            return this.refreshRequest$.asObservable();
        }

        this.logout(false);
        return of(false);
    }

    get token(): string {
        if (this.cookie.check('access_token')) {
            return this.cookie.get('access_token');
        }

        return null;
    }

    saveToken(token: AuthResponseInterface): void {
        const expireDate = new Date();
        expireDate.setSeconds(expireDate.getSeconds() + token.expires_in);
        const expireRefreshDate = new Date();
        expireRefreshDate.setDate(expireRefreshDate.getDate() + 10);
        this.cookie.set('access_token', token.access_token, expireDate, '/', null, !environment.hmr);
        if (token.refresh_token) {
            this.cookie.set('refresh_token', token.refresh_token, expireRefreshDate, '/', null, !environment.hmr);
        }
    }

    saveTokenFromRouteIfPossible(route: ActivatedRouteSnapshot): boolean {
        if (route.queryParamMap.has('access_token')) {
            this.saveToken({
                access_token: route.root.queryParamMap.get('access_token'),
                refresh_token: route.root.queryParamMap.get('refresh_token'),
                expires_in: 3600
            } as AuthResponseInterface);
            return true;
        }

        return false;
    }

    get isAuthenticated(): boolean {
        return this.cookie.check('access_token');
    }

    get canRefresh(): boolean {
        return this.cookie.check('refresh_token');
    }

    logout(logoutRequest = true, redirect = true): void {
        if (logoutRequest && this.utilisateurService.utilisateurConnectedValue) {
            this.utilisateurService.disconnect(this.utilisateurService.utilisateurConnectedValue.id).subscribe({
                next: () => {
                    this.cookie.deleteAll('/', null, !environment.hmr);
                    if (redirect) {
                        setTimeout(() => {
                            window.location.href = '/';
                        });
                    }
                },
                error: () => {
                    this.cookie.deleteAll('/', null, !environment.hmr);
                    if (redirect) {
                        setTimeout(() => {
                            window.location.href = '/';
                        });
                    }
                }
            });
        } else {
            this.cookie.deleteAll('/', null, !environment.hmr);
            this.utilisateurService.utilisateurConnectedValue = null;
            if (redirect) {
                setTimeout(() => {
                    window.location.href = '/';
                });
            }
        }
    }

    forgotPasswordForgottenSendMail(identifier: string): Observable<any> {
        return this.http.post(this.config.baseUrl + 'api/password/forgotten', {
            identifier: identifier
        });
    }

    forgotPasswordForgottenCheckCode(identifier: string, code: string): Observable<any> {
        return this.http.post(this.config.baseUrl + 'api/password/check', {
            identifier: identifier,
            code: code
        });
    }

    forgotPasswordForgottenChangePassword(identifier: string, code: string, newCode: string): Observable<any> {
        return this.http.patch(this.config.baseUrl + 'api/password/change', {
            identifier: identifier,
            code: code,
            new: newCode
        });
    }

    getInfoToken(accessToken: string = null): Observable<TokenInfoResponseInterface> {
        return this.http.get<TokenInfoResponseInterface>(this.config.baseUrl + 'oauth/v2/tokeninfo?access_token=' + accessToken).pipe(
            tap(_ => {
                this.saveToken({
                    access_token: accessToken,
                    expires_in: 3600
                } as AuthResponseInterface);
            })
        );
    }
}

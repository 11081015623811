import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';
import { FuseSharedModule } from '../@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    exports: [
        FuseSharedModule,
        PipesModule,
        FuseSharedModule,
        MaterialModule,
        TranslateModule
    ]
})
export class SharedModule {}

import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import {
    ChatShortcut,
    EntiteJuridique,
    EntiteJuridiqueConnectorStats,
    EntiteJuridiqueStatsFiles
} from '../../models/pro/entite-juridique';
import { ObjectMapper } from 'json-object-mapper';
import { filter, map } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntiteJuridiquePostInterface } from '../../models/interfaces/post/pro/entite-juridique-post.interface';
import { EntiteGeographique } from '../../models/pro/entite-geographique';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { TranslateService } from '@ngx-translate/core';
import { UtilisateurService } from './utilisateur.service';
import { EntiteJuridiqueSettingsPostInterface } from '../../models/interfaces/post/pro/entite-juridique-settings-post.interface';
import { Invoice, Subscription } from '../../models/subscription';
import { StripeSession } from 'app/models/stripe/session';
import { EntiteJuridiqueSubscriptionPostInterface } from 'app/models/interfaces/post/pro/entite-juridique-subscription-post.interface';

@Injectable({
    providedIn: 'root'
})
export class EntiteJuridiqueService {
    private _entiteJuridiqueForUtilisateurConnected = new BehaviorSubject<EntiteJuridique>(null);

    constructor(private translateService: TranslateService, private http: HttpClient,
        private config: ConfigService, private snackbar: MatSnackBar,
        private utilisateurService: UtilisateurService) {
        this._entiteJuridiqueForUtilisateurConnected.subscribe(e => {
            this.config.currentUserCountry = e?.paysFacturation?.toLowerCase();
        });
    }

    public get entiteJuridiqueForUtilisateurConnected(): Observable<EntiteJuridique> {
        if (!this._entiteJuridiqueForUtilisateurConnected.value && this.utilisateurService.utilisateurConnectedValue) {
            this._entiteJuridiqueForUtilisateurConnected.next(this.utilisateurService.utilisateurConnectedValue.entiteJuridique);
        }

        return this._entiteJuridiqueForUtilisateurConnected.asObservable().pipe(filter(u => u !== null));
    }

    public get entiteJuridiqueForUtilisateurConnectedValue(): EntiteJuridique {
        if (!this._entiteJuridiqueForUtilisateurConnected.value && this.utilisateurService.utilisateurConnectedValue) {
            return this.utilisateurService.utilisateurConnectedValue.entiteJuridique;
        }

        return this._entiteJuridiqueForUtilisateurConnected.value;
    }

    public getEntiteJuridiqueById(id: number): Observable<EntiteJuridique> {
        return this.http.get<EntiteJuridique>(this.config.baseUrl + 'api/entite_juridique/' + id.toString()).pipe(
            map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
            map(r => this.updateEntiteJuridiqueForUtilisateurConnected(r))
        );
    }

    public updateEntiteJuridique(entiteJuridique: EntiteJuridiquePostInterface): Observable<EntiteJuridique> {
        this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.UPDATING'), null);
        return this.http.put<EntiteJuridiquePostInterface>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridique.id.toString(), entiteJuridique)
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
                map(r => {
                    this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    return this.updateEntiteJuridiqueForUtilisateurConnected(r);
                })
            );
    }

    public updateEntiteJuridiqueSettings(entiteJuridiqueSettings: EntiteJuridiqueSettingsPostInterface): Observable<EntiteJuridique> {
        this.snackbar.open(this.translateService.instant('SETTINGS.UPDATING'), null);
        return this.http.put<EntiteJuridiquePostInterface>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueSettings.id.toString() + '/settings', entiteJuridiqueSettings)
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
                map(r => {
                    this.snackbar.open(this.translateService.instant('SETTINGS.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    return this.updateEntiteJuridiqueForUtilisateurConnected(r);
                })
            );
    }

    public getChatDefaultShortcuts(entiteJuridiqueId: number): Observable<ChatShortcut[]> {
        return this.http.get<ChatShortcut[]>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/settings/default_shortcuts')
            .pipe(
                map(r => ObjectMapper.deserializeArray(ChatShortcut, r))
            );
    }

    public getVeterinairesConnectedById(id: number): Observable<Veterinaire[]> {
        return this.http.get<Veterinaire[]>(this.config.baseUrl + 'api/entite_juridique/' + id.toString() + '/stats/vets_connected')
            .pipe(
                map(r => ObjectMapper.deserializeArray(Veterinaire, r))
            );
    }

    public getStatsFilesById(id: number): Observable<EntiteJuridiqueStatsFiles> {
        return this.http.get<EntiteJuridiqueStatsFiles>(this.config.baseUrl + 'api/entite_juridique/' + id.toString() + '/stats/files')
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridiqueStatsFiles, r))
            );
    }

    public getStatsEntityConnectorLinkedById(id: number): Observable<EntiteJuridiqueConnectorStats> {
        return this.http.get<EntiteJuridiqueConnectorStats>(this.config.baseUrl + 'api/entite_juridique/' + id.toString() + '/stats/entity_connector_linked')
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridiqueConnectorStats, r))
            );
    }

    public updateEntiteJuridiqueForUtilisateurConnected(entiteJuridique: EntiteJuridique): EntiteJuridique {
        for (let e of entiteJuridique.entitesGeographiques) {
            e = ObjectMapper.deserialize(EntiteGeographique, e);
        }

        entiteJuridique.proprietaire = ObjectMapper.deserialize(Veterinaire, entiteJuridique.proprietaire);
        if (this.utilisateurService.updateEntiteJuridiqueForUtilisateurConnected(entiteJuridique)) {
            this._entiteJuridiqueForUtilisateurConnected.next(entiteJuridique);
        }

        return entiteJuridique;
    }

    public getSubscription(entiteJuridique: EntiteJuridique | number): Observable<Subscription> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;

        return this.http.get<Subscription>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/subscription').pipe(
            map(s => ObjectMapper.deserialize(Subscription, s))
        );
    }

    public getInvoices(entiteJuridique: EntiteJuridique | number): Observable<Invoice[]> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;

        return this.http.get<Invoice[]>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/invoices').pipe(
            map(invoices => invoices.map(i => ObjectMapper.deserialize(Invoice, i)))
        );
    }

    public getInvoiceBlob(entiteJuridique: EntiteJuridique | number, invoice: Invoice | string): Observable<File> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;
        const invoiceId = invoice instanceof Invoice ? invoice.id : invoice;

        return this.http.get(
            this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/invoice/' + invoiceId.toString(),
            { responseType: 'blob', observe: 'response' }
        ).pipe(
            map((res: HttpResponse<Blob>) => {
                const disposition = res.headers.get('content-disposition');
                let filename = null;
                if (disposition?.includes('attachment')) {
                    const filenameRegex = /filename[^\n;=]*=((["']).*?\2|[^\n;]*)/;
                    const matches = filenameRegex.exec(disposition);
                    if (matches?.[1]) {
                        filename = matches[1].replace(/["']/g, '');
                    }
                }

                const f = res.body as any;
                f.name = filename ?? 'facture.pdf';
                return f as File;
            })
        );
    }

    public regenerateClientSignupCode(entiteJuridique: EntiteJuridique | number): Observable<EntiteJuridique> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;
        this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.UPDATING_CLINIC_CODE'), null);

        return this.http.patch<EntiteJuridique>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/settings/signup_client_link', null)
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
                map(r => {
                    this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.UPDATED_CLINIC_CODE'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    return this.updateEntiteJuridiqueForUtilisateurConnected(r);
                })
            );
    }

    public deleteClientSignupCode(entiteJuridique: EntiteJuridique | number): Observable<EntiteJuridique> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;
        this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.DELETING_CLINIC_CODE'), null);

        return this.http.delete<EntiteJuridique>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/settings/signup_client_link')
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
                map(r => {
                    this.snackbar.open(this.translateService.instant('ENTITE_JURIDIQUE.DELETED_CLINIC_CODE'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    return this.updateEntiteJuridiqueForUtilisateurConnected(r);
                })
            );
    }

    public updatePaymentMethod(entiteJuridique: EntiteJuridique | number): Observable<StripeSession> {
        let entiteJuridiqueId: number;
        if (entiteJuridique instanceof EntiteJuridique) {
            entiteJuridiqueId = entiteJuridique.id;
        } else {
            entiteJuridique = entiteJuridiqueId;
        }

        return this.http.get<StripeSession>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/update_payment_method`);
    }

    public cancelSubscription(entiteJuridique: EntiteJuridique | number): Observable<void> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;

        return this.http.delete<void>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/subscription`);
    }

    public updateSubscription(entiteJuridique: EntiteJuridique | number, data: EntiteJuridiqueSubscriptionPostInterface): Observable<Subscription> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;

        return this.http.patch<Subscription>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/subscription`, data).pipe(
            map(s => ObjectMapper.deserialize(Subscription, s))
        );
    }

    public uncancelSubscription(entiteJuridique: EntiteJuridique | number): Observable<void> {
        const entiteJuridiqueId = entiteJuridique instanceof EntiteJuridique ? entiteJuridique.id : entiteJuridique;

        return this.http.patch<void>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/subscription/uncancel`, null);
    }
}

import { JsonProperty } from 'json-object-mapper';
import { Entity } from '../entity';
import { NotePostInterface } from '../interfaces/note-post.interface';
import { List } from '../list';
import { RendezVous } from './rendez-vous';

export class Note extends Entity {
    @JsonProperty()
    purpose?: string = null;

    @JsonProperty()
    history?: string = null;

    @JsonProperty()
    exam?: string = null;

    @JsonProperty()
    interpretation?: string = null;

    @JsonProperty()
    advice?: string = null;

    @JsonProperty({ type: RendezVous })
    rendezVous: RendezVous = null;

    @JsonProperty()
    isModified = false;

    public hasPost(): NotePostInterface {
        return {
            id: this.id === 0 ? null : this.id,
            purpose: this.purpose,
            history: this.history,
            exam: this.exam,
            interpretation: this.interpretation,
            advice: this.advice,
            rendezVous: this.rendezVous?.id
        };
    }
}

export class ListNotes extends List {
    @JsonProperty({ type: Note })
    data: Note[] = null;

    @JsonProperty()
    stats: any = null;
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ (isEdit ? 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.TITLE' : 'CHAT.DIALOG_MESSAGE_LATER.TITLE') | translate }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <form [formGroup]="form" (submit)="submit()">
            <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column">
                    <h4 class="subtitle">{{ 'CHAT.DIALOG_MESSAGE_LATER.PREVIEW' | translate }}</h4>
                    <div class="preview">
                        <div class="message-preview">
                            <div class="attachment" *ngIf="attachment">
                                <div [ngSwitch]="attachment.contentType.split('/')[0]">
                                    <div *ngSwitchCase="'image'" fxLayout="column">
                                        <img [src]="attachment.url | filePath" width="300" height="240" />
                                    </div>
                                    <div *ngSwitchCase="'video'">
                                        <video width="300" height="240" controls="controls" class="file-content">
                                            <source [src]="attachment.url | filePath" type="video/mp4" />
                                            {{ 'SHARED.BROWSER_NOT_READ_VIDEO' | translate }}
                                        </video>
                                    </div>
                                    <div *ngSwitchCase="'audio'">
                                        <audio controls="controls" class="file-content">
                                            <source [src]="attachment.url | filePath" type="audio/aac" />
                                            {{ 'SHARED.BROWSER_NOT_READ_AUDIO' | translate }}
                                        </audio>
                                    </div>
                                    <div *ngSwitchDefault fxLayout="column">
                                        <a mat-button color="primary" [href]="attachment.url | filePath" target="_blank" class="p-0 mt-4 text-center">
                                            <mat-icon>get_app</mat-icon>
                                            {{ 'SHARED.DOWNLOAD_FILE' | translate }}
                                            ({{ attachment.taille | fileSize }})
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <span>{{ form.get('message').value.trim() }}</span>
                        </div>
                    </div>
                    <h4 class="subtitle">{{ 'CHAT.DIALOG_MESSAGE_LATER.INFOS' | translate }}</h4>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_PLACEHOLDER' | translate }}
                        </mat-label>
                        <textarea matInput formControlName="message"
                            [placeholder]="'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_PLACEHOLDER' | translate"
                            required>
                        </textarea>
                        <mat-error *ngIf="form.get('message').hasError('required')">
                            {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.DATE_PLACEHOLDER' | translate }}</mat-label>
                        <input matInput
                            [owlDateTimeTrigger]="datePicker"
                            [owlDateTime]="datePicker"
                            [placeholder]="'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.DATE_PLACEHOLDER' | translate"
                            [min]="currentDate"
                            readonly
                            required
                            formControlName="date"
                            (click)="datePicker.open()"
                            (focus)="datePicker.open()">
                        <mat-icon matSuffix [owlDateTimeTrigger]="datePicker">today</mat-icon>
                        <owl-date-time #datePicker [stepMinute]="5" [firstDayOfWeek]="null | firstDayWeek"
                                       [hour12Timer]="null | twelveHour">
                        </owl-date-time>
                        <mat-error *ngIf="form.get('date').hasError('required')">
                            {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.DATE_REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="16px" [style.marginBottom]="'16px'">
                        <button mat-button type="button" (click)="openFichiersModal()">
                            <mat-icon>attach_file</mat-icon>
                            {{ (attachment ? 'CHAT.ACTIONS.ATTACHMENT.EDIT' : 'CHAT.ACTIONS.ATTACHMENT.ACTION') | translate }}
                        </button>
                        <button mat-button type="button" (click)="removeFile()" *ngIf="attachment" color="warn">
                            <mat-icon>clear</mat-icon>
                            {{ 'SHARED.DELETE' | translate }}
                        </button>
                    </div>
                    <h4 class="subtitle">{{ 'CHAT.DIALOG_MESSAGE_LATER.ADVANCED' | translate }}</h4>
                    <mat-checkbox formControlName="reopenChatIfClosed" [style.marginBottom]="'8px'">
                        {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.REOPEN_IF_CLOSED_PLACEHOLDER' | translate }}
                    </mat-checkbox>
                    <mat-form-field appearance="outline" [class.hidden]="!form.get('reopenChatIfClosed').value">
                        <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.NUMBER_DAYS_OPEN_IF_CLOSED_PLACEHOLDER' | translate }}</mat-label>
                        <input matInput type="number" min="1" step="1" formControlName="numberDaysToOpenIfClosed"
                               [style.textAlign]="'right'">
                        <span matSuffix>{{ 'SHARED.DAYS' | translate }}</span>
                    </mat-form-field>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px"
                     fxFlex="1 1 auto">
                    <button mat-button color="warn" (click)="remove()" type="button"
                            *ngIf="form.get('id').value != null"
                            [disabled]="(formInProgress$ | async) || (formInProgressDelete$ | async)">
                        <mat-icon>delete</mat-icon>
                        {{ 'SHARED.DELETE' | translate }}
                    </button>
                    <button type="submit" mat-raised-button color="accent"
                            [disabled]="form.disabled || (formInProgress$ | async)">
                        <mat-icon>save</mat-icon>
                        {{ 'FORM.SAVE' | translate }}
                    </button>
                    <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="(formInProgress$ | async)">
                    </mat-spinner>
                </div>
            </div>
        </form>
    </div>
</div>

import { NgModule } from '@angular/core';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { FichiersAnimalDialogComponent } from './fichiers-animal-dialog.component';
import { ListFichierModule } from '../list-fichier/list-fichier.module';

@NgModule({
    declarations: [FichiersAnimalDialogComponent],
    imports: [
        RouterModule,
        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        ListFichierModule
    ],
    exports: [FichiersAnimalDialogComponent]
})
export class FichiersAnimalDialogModule {}

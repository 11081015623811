import { Component } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
    selector: 'app-apps-pro-card',
    templateUrl: './apps-pro-card.component.html',
    styleUrls: ['./apps-pro-card.component.scss'],
    animations: [fuseAnimations]
})
export class AppsProCardComponent {}

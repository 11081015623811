<form [formGroup]="form" (submit)="submit()" (keydown.enter)="$event.preventDefault()">
    <h3 class="option-title">{{ 'ONBOARDING_PRO_PLANS.LICENSES.TITLE' | translate }}</h3>
    <p class="option-description">
        {{ subscription.monthly ? ('ONBOARDING_PRO_PLANS.LICENSES.DESCRIPTION_MONTHLY' | translate:{ price: priceAdditionalLicenseMonthly }) : ('ONBOARDING_PRO_PLANS.LICENSES.DESCRIPTION_YEARLY' | translate:{ price: priceAdditionalLicenseYearly }) }}
    </p>

    <div fxLayout="column" class="pl-24 pr-24 mb-32 mt-32">
        <mat-slider
            formControlName="numberOfLicenses"
            class="ml-16 mr-16"
            [displayWith]="formatLicenses"
            [min]="nbLicencesBase"
            max="50"
            (input)="onMatSliderInput($event)">
        </mat-slider>
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <div>{{ 'ONBOARDING_PRO_PLANS.LICENSES.MIN' | translate }}</div>
            <div>{{ 'ONBOARDING_PRO_PLANS.LICENSES.MAX' | translate }}</div>
        </div>
    </div>

    <!-- <div formGroupName="options">
        <h3 class="option-title">{{ 'ONBOARDING_PRO_PLANS.OPTIONS.TITLE' | translate }}</h3>
        <div fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox formControlName="payment">{{ 'ONBOARDING_PRO_PLANS.OPTIONS.PAYMENT.TITLE' | translate:{ price: ( subscription.monthly ? priceOptionPaymentMonthly : priceOptionPaymentYearly ) } }}</mat-checkbox>
        </div>
    </div> -->

    <!-- <div class="mt-16" fxLayout="column">
        <mat-form-field class="coupon-container" appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ONBOARDING_PRO_PLANS.COUPON.TITLE' | translate }}</mat-label>
            <input matInput [placeholder]="'ONBOARDING_PRO_PLANS.COUPON.PLACEHOLDER' | translate" formControlName="coupon" type="text" (keyup.enter)="checkCoupon()">

            <button *ngIf="!!stripeCoupon" (click)="resetCoupon()" class="coupon-button-reset" type="button" mat-icon-button>
                <mat-icon>clear</mat-icon>
            </button>

            <button *ngIf="!stripeCoupon" (click)="checkCoupon()" class="coupon-button" type="button" mat-raised-button color="accent" [disabled]="couponLoading || !form.get('coupon').value">
                <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                    <div>
                        {{ 'ONBOARDING_PRO_PLANS.COUPON.USE' | translate }}
                    </div>
                    <mat-icon *ngIf="!couponLoading" matSuffix class="ml-8">confirmation_number</mat-icon>
                    <mat-spinner *ngIf="couponLoading" matSuffix [diameter]="24" class="ml-8"></mat-spinner>
                </div>
            </button>
        </mat-form-field>

        <p class="coupon-desc" *ngIf="stripeCoupon?.metadata?.description">{{ stripeCoupon?.metadata?.description }}</p>
    </div> -->

    <div fxLayout="row" fxLayoutAlign="center center" class="mt-16 mb-32">
        <div fxLayout="row" fxLayoutAlign="center end" class="mr-8" *ngIf="pricePerPaymentBefore != pricePerPayment">
            <span class="price-before" fxLayoutAlign="center end">{{ pricePerPaymentBefore | numberLocale }}&nbsp;€&nbsp;<mat-icon>arrow_right_alt</mat-icon></span>
        </div>
        <div fxLayout="row" fxLayoutAlign="center end">
            <span class="price">{{ (pricePerPayment) | numberLocale }}&nbsp;€</span>
            <!-- <span class="price-detail">{{ (subscription.monthly ? 'ONBOARDING_PRO_PLANS.PER_MONTH' : 'ONBOARDING_PRO_PLANS.PER_YEAR') | translate }}</span> -->
            <span class="price-detail">{{ 'ONBOARDING_PRO_PLANS.PER_MONTH' | translate }}</span>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" class="mt-16">
        <button type="button" mat-flat-button [disabled]="formLoading" (click)="close()">
            {{ 'SHARED.CANCEL' | translate }}
        </button>
        <button type="submit" mat-button color="accent"
                [disabled]="form.disabled || formLoading">
            {{ 'SHARED.UPDATE' | translate }}
        </button>
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="formLoading"></mat-spinner>
    </div>
</form>



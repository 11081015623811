<div class="mb-32" *ngIf="!enabled">
    <button mat-raised-button (click)="returnToList()">
        <mat-icon matPrefix class="mr-4">arrow_back</mat-icon><span>{{ 'CONNECTOR.BACK' | translate }}</span>
    </button>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-32">
    <ng-container *ngIf="connecteur.logo && connecteur.logo.light != ''">
        <img src="{{ connecteur.logo.light }}" alt="Logo {{ connecteur.name }}" [class.logo-light]="connecteur.logo.dark">
        <img *ngIf="connecteur.logo.dark" src="{{ connecteur.logo.dark }}" alt="Logo {{ connecteur.name }}" class="logo-dark">
    </ng-container>

    <div fxLayout="row" fxLayoutGap="8px">
        <button mat-raised-button color="accent" *ngIf="connecteur.link" (click)="openLink()">
            <mat-icon>public</mat-icon>&nbsp;
            <span>{{ 'CONNECTOR.LINK' | translate }}</span>
        </button>

        <button mat-raised-button color="primary" *ngIf="enabled; else disable" (click)="confirmDisableConnector()"
                [disabled]="loading">
            <span>{{ 'CONNECTOR.DEACTIVATE' | translate }}</span>
        </button>

        <ng-template #disable>
            <button mat-raised-button color="accent" (click)="enableConnector()" [disabled]="loading">
                <span>{{ 'CONNECTOR.ACTIVATE' | translate: {name: connecteur.name} }}</span>
            </button>
        </ng-template>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="stretch start" fxLayoutGap="32px" class="mb-16">
    <div class="info">
        <div class="info-title">{{ 'CONNECTOR.INFOS.NAME' | translate }}</div>
        <div class="info-content">{{ connecteur.name }}</div>
    </div>
    <div class="info" *ngIf="connecteur.description">
        <div class="info-title">{{ 'CONNECTOR.INFOS.DESCRIPTION' | translate }}</div>
        <div class="info-content">{{ connecteur.description }}</div>
    </div>
    <div class="info" *ngIf="connecteur.minVersion">
        <div class="info-title">{{ 'CONNECTOR.INFOS.COMPATIBILITY' | translate }}</div>
        <div class="info-content">v{{ connecteur.minVersion }}</div>
    </div>
</div>

<div fxLayout="column" fxLayoutGap="16px" class="features">
    <h3 class="features-title">{{ 'CONNECTOR.FEATURES.TITLE' | translate }}</h3>

    <div class="feature" *ngFor="let feature of features">
        <h4 class="feature-title" fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
            <mat-icon>done</mat-icon>
            <span>{{ 'CONNECTOR.FEATURES.' + (feature.connecteurType | uppercase) + '.TITLE' | translate }}</span>
            <span class="feature-sync" *ngIf="feature.support.length == 2; else oneFeature">
                <mat-icon matTooltip="{{ 'CONNECTOR.FEATURES.PUSH_AND_PULL' | translate }}">sync</mat-icon>
            </span>
            <ng-template #oneFeature>
                <span class="feature-sync" *ngIf="feature.support[0] === 0">
                    <mat-icon matTooltip="{{ 'CONNECTOR.FEATURES.PUSH_ONLY' | translate }}">sync_problem</mat-icon>
                </span>
                <span class="feature-sync" *ngIf="feature.support[0] === 1">
                    <mat-icon matTooltip="{{ 'CONNECTOR.FEATURES.PULL_ONLY' | translate }}">sync_problem</mat-icon>
                </span>
            </ng-template>
        </h4>
        <p class="feature-content">{{ 'CONNECTOR.FEATURES.' + (feature.connecteurType | uppercase) + '.DESCRIPTION' | translate:{connecteur: connecteur.name} }}</p>
    </div>
</div>

<app-connector-form *ngIf="enabled" [connector]="connecteur" [apiKey]="apiKey" (refreshKey)="enableConnector()"></app-connector-form>

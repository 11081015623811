import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BugService } from '../../../services/api/bug.service';

@Component({
    selector: 'app-report-bug-dialog',
    templateUrl: 'report-bug-dialog.component.html',
    styleUrls: ['report-bug-dialog.component.scss']
})
export class ReportBugDialogComponent {
    commentaire = '';

    constructor(
        private bugService: BugService,
        public dialogRef: MatDialogRef<ReportBugDialogComponent>
    ) {}

    send(): void {
        this.bugService.sendBugReport(this.commentaire).subscribe({
            next: () => {
                this.dialogRef.close();
            },
            error: () => {
                this.dialogRef.close();
            }
        });
    }

    close(): void {
        this.dialogRef.close();
    }
}

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { DialogComponent } from '../../view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CurrencySymbolPipe } from '../../../../pipes/currency-symbol.pipe';
import { CurrencyMaskConfig } from 'ngx-currency';

@Component({
    selector: 'app-payment-capture-dialog',
    templateUrl: './payment-capture-dialog.component.html',
    styleUrls: ['./payment-capture-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class PaymentCaptureDialogComponent {
    showFees = false;
    loading = false;
    priceControl: FormControl;

    constructor(
        public dialogRef: MatDialogRef<PaymentCaptureDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PaymentCaptureDialogData,
        private dialog: MatDialog, private translateService: TranslateService
    ) {
        this.priceControl = new FormControl(data.rendezVous.prix, [Validators.max(data.rendezVous.prix), Validators.min(1)]);
    }

    cancel(): void {
        this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('DIALOG_CANCEL_PAYMENT.TITLE'),
                content: this.translateService.instant('DIALOG_CANCEL_PAYMENT.CONTENT'),
                action: true,
                ok: this.translateService.instant('DIALOG_CANCEL_PAYMENT.OK')
            },
            disableClose: true
        }).afterClosed().subscribe(val => {
            if (val) {
                this.loading = true;
                this.priceControl.disable();
                this.data.rendezVousService
                    .cancelChargeRendezVous(this.data.rendezVous.id)
                    .subscribe({
                        next: (rendezVous: RendezVous) => {
                            this.dialogRef.close(rendezVous);
                        },
                        error: () => {
                            this.loading = false;
                            this.priceControl.enable();
                        }
                    });
            }
        });
    }

    capture(): void {
        if (!this.priceControl.valid) {
            return;
        }

        this.loading = true;
        this.priceControl.disable();
        this.data.rendezVousService
            .captureChargeRendezVous(this.data.rendezVous.id, this.priceControl.value)
            .subscribe({
                next: (rendezVous: RendezVous) => {
                    this.dialogRef.close(rendezVous);
                },
                error: () => {
                    this.loading = false;
                    this.priceControl.enable();
                }
            });
    }

    close(): void {
        this.dialogRef.close();
    }

    public getCurrencyInputOptions(): Partial<CurrencyMaskConfig> {
        const currency = this.data.rendezVous.currency;
        const currencySymbolPipe = new CurrencySymbolPipe(this.translateService);
        const currencySymbol = currencySymbolPipe.transform(currency);
        const currencyMaskConfig: Partial<CurrencyMaskConfig> = {
            allowNegative: false,
            prefix: currencySymbol + ' ',
            nullable: false,
            min: 1,
            inputMode: 1
        };

        if (currency === 'USD') {
            return currencyMaskConfig;
        }

        currencyMaskConfig.prefix = '';
        currencyMaskConfig.suffix = ' ' + currencySymbol;
        currencyMaskConfig.decimal = ',';
        currencyMaskConfig.thousands = ' ';
        return currencyMaskConfig;
    }
}

export interface PaymentCaptureDialogData {
    rendezVous: RendezVous;
    rendezVousService: RendezVousService;
}

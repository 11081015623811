import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateService } from '@ngx-translate/core';

export class OwlDatetimePickerIntlTranslated extends OwlDateTimeIntl {
    /** A label for the up second button (used by screen readers).  */
    upSecondLabel: string;

    /** A label for the down second button (used by screen readers).  */
    downSecondLabel: string;

    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel: string;

    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel: string;

    /** A label for the up hour button (used by screen readers).  */
    upHourLabel: string;

    /** A label for the down hour button (used by screen readers).  */
    downHourLabel: string;

    /** A label for the Précedent month button (used by screen readers). */
    prevMonthLabel: string;

    /** A label for the next month button (used by screen readers). */
    nextMonthLabel: string;

    /** A label for the Précedent year button (used by screen readers). */
    prevYearLabel: string;

    /** A label for the next year button (used by screen readers). */
    nextYearLabel: string;

    /** A label for the Précedent multi-year button (used by screen readers). */
    prevMultiYearLabel: string;

    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel: string;

    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel: string;

    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel: string;

    /** A label for the cancel button */
    cancelBtnLabel: string;

    /** A label for the set button */
    setBtnLabel: string;

    /** A label for the range 'from' in picker info */
    rangeFromLabel: string;

    /** A label for the range 'to' in picker info */
    rangeToLabel: string;

    /** A label for the hour12 button (AM) */
    hour12AMLabel: string;

    /** A label for the hour12 button (PM) */
    hour12PMLabel: string;

    constructor(private translateService: TranslateService) {
        super();

        this.refreshTranslation();
        this.translateService.onLangChange.subscribe(() => this.refreshTranslation());
    }

    private refreshTranslation(): void {
        this.upSecondLabel = this.translateService.instant('DATE_TIME_PICKER.UP_SECOND') as string;
        this.downSecondLabel = this.translateService.instant('DATE_TIME_PICKER.DOWN_SECOND') as string;
        this.upMinuteLabel = this.translateService.instant('DATE_TIME_PICKER.UP_MINUTE') as string;
        this.downMinuteLabel = this.translateService.instant('DATE_TIME_PICKER.DOWN_MINUTE') as string;
        this.upHourLabel = this.translateService.instant('DATE_TIME_PICKER.UP_HOUR') as string;
        this.downHourLabel = this.translateService.instant('DATE_TIME_PICKER.DOWN_HOUR') as string;
        this.prevMonthLabel = this.translateService.instant('DATE_TIME_PICKER.PREV_MONTH') as string;
        this.nextMonthLabel = this.translateService.instant('DATE_TIME_PICKER.NEXT_MONTH') as string;
        this.prevYearLabel = this.translateService.instant('DATE_TIME_PICKER.PREV_YEAR') as string;
        this.nextYearLabel = this.translateService.instant('DATE_TIME_PICKER.NEXT_YEAR') as string;
        this.prevMultiYearLabel = this.translateService.instant('DATE_TIME_PICKER.PREV_MULTI_YEAR') as string;
        this.nextMultiYearLabel = this.translateService.instant('DATE_TIME_PICKER.NEXT_MULTI_YEAR') as string;
        this.switchToMonthViewLabel = this.translateService.instant('DATE_TIME_PICKER.SWITCH_TO_MONTH_VIEW') as string;
        this.switchToMultiYearViewLabel = this.translateService.instant('DATE_TIME_PICKER.SWITCH_TO_MULTI_YEAR_VIEW') as string;
        this.cancelBtnLabel = this.translateService.instant('DATE_TIME_PICKER.CANCEL_BTN') as string;
        this.setBtnLabel = this.translateService.instant('DATE_TIME_PICKER.SET_BTN') as string;
        this.rangeFromLabel = this.translateService.instant('DATE_TIME_PICKER.RANGE_FROM') as string;
        this.rangeToLabel = this.translateService.instant('DATE_TIME_PICKER.RANGE_TO') as string;
        this.hour12AMLabel = this.translateService.instant('DATE_TIME_PICKER.HOUR_12AM') as string;
        this.hour12PMLabel = this.translateService.instant('DATE_TIME_PICKER.HOUR_12PM') as string;
    }
}

<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <button mat-stroked-button class="back-button" (click)="onBackHomeClicked()">
            <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK_HOME' | translate }}
        </button>
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg" alt="Univet">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg" alt="Univet">
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="1 1 auto">
        <form [formGroup]="form">
            <mat-card>
                <mat-card-content>
                    <mat-horizontal-stepper #stepper linear>
                        <mat-step [completed]="form.get('clinic').valid">
                            <app-step-clinic #stepClinic [form]="form.get('clinic') | asFormGroup"></app-step-clinic>
                        </mat-step>
                        <mat-step [completed]="form.get('user').valid">
                            <app-step-user #stepUser [form]="form.get('user') | asFormGroup" [emailSuggestion]="emailSuggestion"></app-step-user>
                        </mat-step>
                        <mat-step>
                            <app-step-welcome [mail]="form.get('user.mail').value"></app-step-welcome>
                        </mat-step>
                    </mat-horizontal-stepper>
                </mat-card-content>
                <mat-divider></mat-divider>
                <mat-card-actions fxLayout="row" fxLayoutAlign="space-between center">
                    <app-steps [currentStep]="stepper.selectedIndex" [stepsCount]="3" (stepClick)="goToStep($event)"></app-steps>
                    <div fxLayout="row">
                        <button *ngIf="stepper.selectedIndex < 2 && this.stepClinic.searchDone" mat-icon-button (click)="onBackClicked()" matTooltip="{{ 'ONBOARDING_PRO.BACK' | translate }}"><mat-icon>arrow_backward</mat-icon></button>
                        <button *ngIf="stepper.selectedIndex < 2" mat-raised-button color="accent" (click)="onNextClicked()"
                            [disabled]="formLoading || !buttonContinueEnabled">
                            <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                                <div>
                                    {{ 'ONBOARDING_PRO.CONTINUE' | translate }}
                                </div>
                                <mat-icon *ngIf="!formLoading" matSuffix class="ml-8">arrow_forward</mat-icon>
                                <mat-spinner *ngIf="formLoading" matSuffix [diameter]="24" class="ml-8"></mat-spinner>
                            </div>
                        </button>
                    </div>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
</div>

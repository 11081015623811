import { WebsocketMessage } from './websocket-message';
import { JsonProperty } from 'json-object-mapper';

export class Connection extends WebsocketMessage<null> {
    constructor(token: string) {
        super();
        this.token = token;
    }

    @JsonProperty()
    type = 'connecting';

    @JsonProperty()
    data = null;
}


        <table mat-table
               [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z1">

            <ng-container matColumnDef="nbDaysAfter">
                <th mat-header-cell *matHeaderCellDef>{{ 'SETTINGS.MESSAGES.SCENARIOS.HEADINGS.DELAY' | translate }}</th>
                <td mat-cell *matCellDef="let row"> {{row.chatScenarioMessage.nbDaysAfter}} </td>
            </ng-container>

            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef>{{ 'SETTINGS.MESSAGES.SCENARIOS.HEADINGS.SHORTCUT' | translate }}</th>
                <td mat-cell *matCellDef="let row">
                    <mat-chip-list>
                        <mat-chip class="shortcut" (click)="refreshPreviewSelected(row.chatScenarioMessage.message);expandedChatScenarioMessage = expandedChatScenarioMessage === row.chatScenarioMessage ? null : row.chatScenarioMessage">
                            /{{row.chatScenarioMessage.message}}
                        </mat-chip>
                    </mat-chip-list>
                </td>
            </ng-container>

            <ng-container matColumnDef="numberDaysToOpenIfClosed">
                <th mat-header-cell *matHeaderCellDef>{{ 'SETTINGS.MESSAGES.SCENARIOS.HEADINGS.CHAT_REOPPENING_DURATION' | translate }}</th>
                <td mat-cell *matCellDef="let row"> {{row.chatScenarioMessage.numberDaysToOpenIfClosed ? row.chatScenarioMessage.numberDaysToOpenIfClosed : 'SETTINGS.MESSAGES.SCENARIOS.HEADINGS.CHAT_REOPPENING_NONE' | translate }} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef style="width:15%;">
                    {{ 'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.LABEL' | translate }}
                </th>
                <td mat-cell *matCellDef="let row" style="width:15%;">
                    <button type="button" mat-icon-button (click)="editMessage(row.chatScenarioMessage, row.index)"
                            [matTooltip]="'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.EDIT' | translate">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" mat-icon-button color="warn" (click)="remove(row.index)"
                            [matTooltip]="'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.DELETE' | translate">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplay.length">
                    <div class="example-element-detail"
                         [@detailExpand]="row.chatScenarioMessage == expandedChatScenarioMessage ? 'expanded' : 'collapsed'">

                        <div class="preview">
                            <div *ngIf="previewShortcutSelected" class="message-preview message"
                                 [innerHTML]="(previewShortcutSelected?.content) | beautifyShortcuts">
                            </div>
                        </div>

                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"
                class="example-element-row"
                [class.example-expanded-row]="expandedChatScenarioMessage === row.chatScenarioMessage">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
        </table>

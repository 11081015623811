<div *ngIf="displayLoading && isLoading; else loaded" fxLayout="column" fxLayoutAlign="center center" class="m-auto" style="height: 100%;">
    <app-loading></app-loading>
</div>
<ng-template #loaded>
    <div class="m-8 select-filter-bar" *ngIf="displayStat || selectionMode == 'MULTI' && selectedFiles.length > 0">
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="displayStat">
            <mat-chip-list>
                <mat-chip *ngFor="let type of stats | values" [selectable]="false" [selected]="false"
                    [disableRipple]="true">
                    {{ 'SHARED.' + type.key | translate }}{{ stats[type.key] > 1 ? 's' : '' }}
                    ({{ stats[type.key] }})
                </mat-chip>
            </mat-chip-list>
        </div>
        <div class="select-bar" *ngIf="selectionMode == 'MULTI' && selectedFiles.length > 0">
            <p>{{ selectedFiles.length }}
                {{ 'SHARED.SELECTED.FILE' + (selectedFiles.length > 1 ? 'S' : '')  | translate }}</p>
            <button mat-icon-button (click)="deselectAll()" [matTooltip]="'SHARED.UNSELECT_ALL' | translate">
                <mat-icon>check_circle</mat-icon>
            </button>
            <button mat-icon-button (click)="downloadFiles()" [matTooltip]="'SHARED.DOWNLOAD' | translate">
                <mat-icon>save_alt</mat-icon>
            </button>
            <button mat-icon-button (click)="removeFiles()" [matTooltip]="'SHARED.DELETE' | translate">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>

    <div [photoGalleryGroup]="photoGalleryConfig">
        <div *ngFor="let sortByString of listSortStrings$ | async; let i = index; trackBy: trackByFn">
            <p class="date-day" *ngIf="(groupBy != 'NONE') || (selectionMode == 'MULTI' && (fichiers$ | async)[sortByString]?.length > 0)">
                <button
                    *ngIf="selectionMode == 'MULTI' && (fichiers$ | async)[sortByString]?.length > 0"
                    mat-icon-button
                    class="select-button"
                    (click)="selectAllDay(sortByString)"
                    [matTooltip]="'SHARED.SELECT_ALL' | translate">
                    <mat-icon>check_circle_outline</mat-icon>
                </button>
                <b *ngIf="groupBy != 'NONE'">{{ sortByString }}</b>
            </p>
            <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
                <div
                    *ngIf="input && displayAddFile && i === 0"
                    class="add-button"
                    fxLayout="column wrap"
                    fxLayoutAlign="center center"
                    (click)="openNewFileDialog()"
                    [matTooltip]="'CHAT.SEND_FILE' | translate">
                    <mat-icon *ngIf="!isSending">add</mat-icon>
                    <div *ngIf="!isSending">{{ 'SHARED.ADD_FILE' | translate }}</div>
                    <mat-spinner *ngIf="isSending" mode="indeterminate" [diameter]="40" color="primary"></mat-spinner>
                </div>
                <app-fichier
                    *ngFor="let file of (fichiers$ | async)[sortByString]"
                    [fichierIndex]="file"
                    [displayAction]="displayAction"
                    [selectionMode]="selectionMode"
                    (openFile)="openFile($event)"
                    (editImage)="editImage($event)"
                    (toggleSelect)="toggleSelect($event)"
                    (downloadFile)="downloadFile($event)"
                    (removeFile)="removeFile($event)">
                </app-fichier>
            </div>
        </div>
    </div>

    <div class="date-tag" [style.top]="dateTagPositionY" *ngIf="displayScrollDay && !isLoading">{{ dateTagText }}</div>

    <div class="m-16 w-100-p" fxLayout="column" fxLayoutAlign="center center" *ngIf="canLoadMore">
        <button mat-button *ngIf="!isLoading && !isLoadingMore" (click)="loadMoreFiles()">
            <mat-icon>expand_more</mat-icon>
            <span>{{ 'SHARED.SEE_MORE' | translate }}</span>
        </button>
        <app-loading [diameter]="20" [loadingMessage]="'FILES.LOADING_FILES' | translate" *ngIf="isLoadingMore">
        </app-loading>
    </div>
</ng-template>

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { ListVeterinaires, Veterinaire } from '../models/utilisateurs/veterinaire';
import { Utilisateur } from '../models/utilisateurs/utilisateur';

export class VeterinairesDataSource implements DataSource<Utilisateur> {
    private _veterinaires = new BehaviorSubject<Veterinaire[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(private utilisateurService: UtilisateurService) {}

    loadVeterinaires(filter: string, sortDirection: string, sortField: string, pageIndex: number, pageSize: number): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.utilisateurService.getVeterinaires(filter, sortDirection, sortField, pageIndex * pageSize, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this._loadingSubject.next(false))
        ).subscribe((veterinaires: ListVeterinaires) => {
            if (veterinaires.data && veterinaires.meta) {
                this._veterinaires.next(veterinaires.data);
                this._totalCount.next(veterinaires.meta.totalItems);
            }
        });
    }

    add(veterinaire: Veterinaire): void {
        const data = this._veterinaires.getValue();
        data.push(veterinaire);
        this._veterinaires.next(data);
        this._totalCount.next(this._totalCount.getValue() + 1);
    }

    replace(veterinaire: Veterinaire): void {
        const data = this._veterinaires.getValue();
        const index = data.findIndex(c => c.id === veterinaire.id);
        data[index] = veterinaire;
        this._veterinaires.next(data);
    }

    delete(veterinaire: Veterinaire): void {
        const data = this._veterinaires.getValue();
        data.splice(data.findIndex(c => c.id === veterinaire.id), 2);
        this._veterinaires.next(data);
        this._totalCount.next(this._totalCount.getValue() - 1);
    }

    connect(): Observable<Veterinaire[]> {
        return this._veterinaires.asObservable();
    }

    disconnect(): void {
        this._veterinaires.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }
}

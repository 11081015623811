<div fxLayout="column" [formGroup]="form">
    <div class="step-header">
        <h1 [innerHTML]="'ONBOARDING_PRO.STEPS.CLINIC.TITLE' | translate"></h1>
        <p [innerHTML]="'ONBOARDING_PRO.STEPS.CLINIC.SUBTITLE' | translate"></p>
    </div>

    <div fxLayout="row">
        <img class="icon" src="../../../../assets/images/icons/onboarding-icon-clinic.svg" alt="Clinic Icon" fxFlex="0 0" />

        <div fxFlex="1 1 auto">
            <mat-form-field *ngIf="!searchDone; else nameClinic" appearance="outline" fxFlex="1 1 auto">
                <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.SEARCH_PLACEHOLDER' | translate }}</mat-label>
                <input type="text" matInput placeholder="{{ 'ONBOARDING_PRO.STEPS.CLINIC.SEARCH_PLACEHOLDER' | translate }}" [formControl]="searchFilterControl" [matAutocomplete]="auto">
                <mat-spinner *ngIf="loading" matSuffix [diameter]="24"></mat-spinner>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onEntitySelected($event)">
                    <mat-option *ngFor="let entity of entities$ | async" [value]="entity.place_id" [class.other-entity]="!entity.place_id">
                        <b>{{ entity.name }}</b> {{ entity.formatted_address }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <ng-template #nameClinic>
                <mat-form-field appearance="outline" fxFlex="1 1 auto">
                    <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.NAME_PLACEHOLDER' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'ONBOARDING_PRO.STEPS.CLINIC.NAME_PLACEHOLDER' | translate }}"
                        formControlName="name" type="text" required>
                    <mat-icon matSuffix>check</mat-icon>
                    <mat-error *ngIf="form.get('name').hasError('required')">
                        {{ 'ONBOARDING_PRO.STEPS.CLINIC.NAME_REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>
            </ng-template>
        </div>
    </div>
    <div [style.display]="searchDone ? 'flex' : 'none'" style="flex-direction: column;">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.ADDRESS_PLACEHOLDER' | translate }}</mat-label>
            <input matInput [placeholder]="'ONBOARDING_PRO.STEPS.CLINIC.ADDRESS_PLACEHOLDER' | translate"
                formControlName="address" type="text" required>
            <mat-error *ngIf="form.get('address').hasError('required')">
                {{ 'ONBOARDING_PRO.STEPS.CLINIC.ADDRESS_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="16px">
            <mat-form-field appearance="outline" fxFlex="1 1 33%">
                <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.ZIPCODE_PLACEHOLDER' | translate }}</mat-label>
                <input matInput [placeholder]="'ONBOARDING_PRO.STEPS.CLINIC.ZIPCODE_PLACEHOLDER' | translate"
                    formControlName="zipCode" type="text" required>
                <mat-error *ngIf="form.get('zipCode').hasError('required')">
                    {{ 'ONBOARDING_PRO.STEPS.CLINIC.ZIPCODE_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="1 1 67%">
                <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.CITY_PLACEHOLDER' | translate }}</mat-label>
                <input matInput [placeholder]="'ONBOARDING_PRO.STEPS.CLINIC.CITY_PLACEHOLDER' | translate"
                    formControlName="city" type="text" required>
                <mat-error *ngIf="form.get('city').hasError('required')">
                    {{ 'ONBOARDING_PRO.STEPS.CLINIC.CITY_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <app-input-pays [control]="form.get('country')" [required]="true"></app-input-pays>
        <app-input-phone #inputPhone class="w-100-p" appearance="outline"
            [placeholder]="'ONBOARDING_PRO.STEPS.CLINIC.PHONE_PLACEHOLDER' | translate" [control]="form.get('phone')" [required]="true">
        </app-input-phone>

        <div fxLayout="row" fxLayout.xs="column">
            <mat-form-field class="mr-16" fxFlex="1 0" appearance="outline">
                <mat-label>{{ 'ONBOARDING_PRO.STEPS.CLINIC.GROUPEMENT_PLACEHOLDER' | translate }}</mat-label>
                <input matInput [placeholder]="'ONBOARDING_PRO.STEPS.CLINIC.GROUPEMENT_PLACEHOLDER' | translate"
                    formControlName="groupement" type="text">
            </mat-form-field>

            <app-input-vat fxFlex="1 0" #inputVat [class.hidden]="!inputVat.show" [controlVat]="form.get('vat')" [country]="form.get('country').value" [required]="false" [showValidateButton]="false"></app-input-vat>
        </div>
    </div>
</div>

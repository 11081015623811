import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { OpentokService } from '../../../../services/opentok.service';
import { VisioService, DevicePermission } from '../../../../services/visio.service';
import { Subscription } from 'rxjs';
import { Session, Publisher } from '@opentok/client';
import { MatDialog } from '@angular/material/dialog';
import { VisioSettingsDialogComponent } from '../visio-settings-dialog/visio-settings-dialog.component';

@Component({
    selector: 'app-visio-publisher',
    templateUrl: './visio-publisher.component.html',
    styleUrls: ['./visio-publisher.component.scss']
})

export class PublisherComponent implements OnDestroy, AfterViewInit {
    @ViewChild('publisherDiv', { static: true }) publisherDiv: ElementRef;

    session: Session;
    subscriptions: Subscription;

    publisher: Publisher;
    publishing = false;
    micEnabled = true;
    cameraEnabled = true;

    DevicePermission = DevicePermission;

    constructor(
        private opentokService: OpentokService,
        private dialog: MatDialog,
        public visioService: VisioService
    ) {}

    ngAfterViewInit(): void {
        this.publisher = this.visioService.initPublisher(this.publisherDiv.nativeElement, {
            publishAudio: this.micEnabled,
            publishVideo: this.cameraEnabled,
            insertMode: 'append',
            style: {
                nameDisplayMode: 'off',
                buttonDisplayMode: 'off',
                audioLevelDisplayMode: 'off',
                archiveStatusDisplayMode: 'off',
                backgroundImageURI: 'on'
            }
        } as any);

        this.publisher.on('streamDestroyed', event => {
            event.preventDefault();
        });

        this.subscriptions = this.visioService.currentSession.subscribe((session: Session) => {
            if (this.session) {
                this.session.off('sessionConnected', null);
            }

            this.session = session;
            if (this.session) {
                this.session.on('sessionConnected', () => this.publish(session));
            }
        });
    }

    ngOnDestroy(): void {
        if (this.publisher) {
            this.publisher.off('streamDestroyed', null);
            this.publisher.destroy();
        }

        this.visioService.setCurrentPublisher(null);
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    publish(session: Session): void {
        session.publish(this.publisher, err => {
            if (err) {
                console.error(err.message);
            } else {
                this.publishing = true;
            }
        });
    }

    toggleCamera(): void {
        this.cameraEnabled = !this.cameraEnabled;
        this.publisher.publishVideo(this.cameraEnabled);
    }

    toggleMic(): void {
        this.micEnabled = !this.micEnabled;
        this.publisher.publishAudio(this.micEnabled);
        this.visioService.currentPublisherFileObject?.publishAudio(this.micEnabled);
    }

    openSettings(): void {
        this.dialog.open(VisioSettingsDialogComponent, {
            data: {
                visioService: this.visioService
            },
            panelClass: 'no-padding-dialog',
            width: '420px',
            disableClose: true
        });
    }

    refresh(): void {
        document.location.reload();
    }
}

import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LinkyvetNotification, NotificationsService } from 'app/services/api/notifications.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
    animations: [
        trigger('notifications', [
            transition(':enter', [
                style({ 'transform': 'scale(0.9)', 'opacity': 0, 'z-index': 99_999 }),
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({ 'transform': 'scale(1)', 'opacity': 1, 'z-index': 99_999 }))
            ]),
            transition(':leave', [
                style({ 'transform': 'scale(1)', 'opacity': 1, 'height': '*', 'z-index': 99_999 }),
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({
                        'transform': 'scale(0.9) translateY(-16px)', 'opacity': 0,
                        'height': '0px', 'z-index': 99_999
                    }))
            ])
        ])
    ]
})
export class NotificationsComponent implements OnInit, OnDestroy {
    isVisible = false;
    notifications: LinkyvetNotification[] = [];
    readIds: number[] = [];
    subscriptions: Subscription[] = [];
    supportUrl = environment.supportUrl;
    private newsAvailibityDuration = 2_592_000_000; // 30 days

    constructor(
        private readonly ref: ElementRef,
        private readonly notificationsService: NotificationsService,
        private utilisateurService: UtilisateurService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        const readSubscription = this.notificationsService.read.subscribe(read => {
            this.readIds = read;
        });

        const notificationsSubscription = this.utilisateurService.utilisateurConnected.pipe(
            filter(u => !!u),
            take(1),
            switchMap(() => this.notificationsService.notifications)
        ).subscribe(notifications => {
            this.notifications = notifications.filter(n =>
              +n.date + this.newsAvailibityDuration > +Date.now()
            );

            const featured = this.notifications
                .filter(n => n.featured && !this.utilisateurService.utilisateurConnectedValue.newsRead?.includes(n.id));
            if (featured.length > 0) {
                const notificationFeatured = featured[0];
                this.notificationsService.getNotificationDetails(notificationFeatured.id).subscribe(fullNotification => this.showNotificationModal(fullNotification));
            }
        });

        this.subscriptions.push(notificationsSubscription, readSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        if (!this.ref.nativeElement.contains(event.target)) {
            this.isVisible = false;
        }
    }

    toggleVisibility(): void {
        this.isVisible = !this.isVisible;
    }

    openLink(notification: LinkyvetNotification): void {
        if (notification?.link) {
            this.notificationsService.setNotificationAsRead(notification.id);
            window.open(notification.link, '_blank');
        }
    }

    markAllAsRead(): void {
        const ids = this.notifications.map(notification => notification.id);
        this.notificationsService.setAllNotifificationAsRead(ids);
    }

    trackByFn(index: number, notification: LinkyvetNotification): number {
        return notification.id;
    }

    private showNotificationModal(notification: LinkyvetNotification) {
        const notificationDialog = this.dialog.open(NotificationDialogComponent, {
            data: {
                notification
            },
            panelClass: 'no-padding-dialog',
            disableClose: true
        });

        const notificationDialogSubscription = notificationDialog.afterClosed().subscribe(() => {
            this.notificationsService.setNotificationAsRead(notification.id);
            notificationDialogSubscription.unsubscribe();
        });
    }
}

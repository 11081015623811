<fuse-widget class="widget">
    <div class="fuse-widget-front mat-white-bg">
        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
              <mat-icon *ngIf="value > dangerZone" color="warn">warning</mat-icon>
              <span [style.color]="value > dangerZone ? 'red' : ''"
                    [style.font-weight]="value > dangerZone ? 'bold' : ''">{{ titleLabel }}</span>
            </div>
            <button mat-icon-button color="accent" (click)="actionClicked$.next(null)"
                    *ngIf="action !== undefined && action !== null">
                <mat-icon [matTooltip]="action">{{ icon }}</mat-icon>
            </button>
        </div>

        <div class="pb-16">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="!isLoading; else loading">
                <div class="chart">
                    <ngx-charts-gauge *ngIf="!linear; else linearGauge"
                                      [scheme]="domainScheme"
                                      [results]="statSerie"
                                      [animations]="false"
                                      [legend]="false"
                                      [startAngle]="0"
                                      [angleSpan]="360"
                                      [showAxis]="false"
                                      [max]="max"
                                      [tooltipDisabled]="true"
                                      [units]="units"
                    ></ngx-charts-gauge>

                    <ng-template #linearGauge>
                        <ngx-charts-linear-gauge
                            [scheme]="domainScheme"
                            [results]="statSerie"
                            [value]="value"
                            [animations]="false"
                            [max]="max"
                            [units]="units"
                        ></ngx-charts-linear-gauge>
                    </ng-template>
                </div>

                <div class="{{ color }}-fg font-size-14" *ngIf="maxLabel">
                    {{ maxLabel }}
                </div>

                <div class="{{ color }}-fg font-size-14 text-bold" *ngIf="detail">
                    {{ detail }}
                </div>
            </div>

            <ng-template #loading>
                <div class="{{ color }}-fg font-size-72 line-height-72 text-center">...</div>
            </ng-template>
        </div>
    </div>
</fuse-widget>

import { NgModule } from '@angular/core';
import { BrowserNotSupportedModule } from './main/browser-not-supported/browser-not-supported.module';
import { LoginModule } from './main/login/login.module';
import { ForgotPassModule } from './main/forgot-password/forgot-password.module';
import { ChatModule } from './main/content/chat/chat.module';
import { ClientsModule } from './main/content/clients/clients.module';
import { VeterinairesModule } from './main/content/veterinaires/veterinaires.module';
import { AnimalFichiersModule } from './main/content/animal-fichiers/animal-fichiers.module';
import { DashboardModule } from './main/content/dashboard/dashboard.module';
import { VisioModule } from './main/content/visio/visio.module';
import { CompteModule } from './main/content/compte/compte.module';
import { EntiteJuridiqueModule } from './main/content/entite-juridique/entite-juridique.module';
import { ProchainsRendezVousModule } from './main/content/prochains-rendez-vous/prochains-rendez-vous.module';
import { RouterModule, Routes } from '@angular/router';
import { EspaceDisqueModule } from './main/content/espace-disque/espace-disque.module';
import { CalendrierModule } from 'app/main/content/calendrier/calendrier.module';
import { ResetPassModule } from './main/reset-password/reset-password.module';
import { PaiementsModule } from 'app/main/content/paiements/paiements.module';
import { EntitesGeographiqueModule } from 'app/main/content/entites-geographique/entites-geographique.module';
import { InformationsBancaireModule } from './main/content/informations-bancaires/informations-bancaire.module';
import { SettingsModule } from './main/content/settings/settings.module';
import { ConnectorModule } from './main/content/connector/connector.module';
import { TestModule } from './main/content/test/test.module';
import { TodosModule } from './main/content/todos/todos.module';
import { OnboardingProModule } from './main/onboarding-pro/onboarding-pro.module';
import { OnboardingProPlansModule } from './main/onboarding-pro-plans/onboarding-pro-plans.module';
import { OnboardingProEndModule } from './main/onboarding-pro-end/onboarding-pro-end.module';
import { OnboardingClientModule } from './main/onboarding-client/onboarding-client.module';
import { OnboardingClientEndModule } from './main/onboarding-client-end/onboarding-client-end.module';
import { OnboardingModule } from './main/onboarding/onboarding.module';
import { AbonnementModule } from './main/content/abonnement/abonnement.module';
import { FacturesModule } from './main/content/factures/factures.module';
import { ReportModule } from './main/content/report/report.module';
import { DeactivatedAccountModule } from './main/deactivated-account/deactivated-account.module';
import { LogoutModule } from './main/logout/logout.module';
import { NewsModule } from './main/content/news/news.module';

const appRoutes: Routes = [
    {
        path: 'onboarding',
        redirectTo: 'onboarding-pro'
    },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [],
    imports: [
        RouterModule.forRoot(appRoutes),

        AbonnementModule,
        AnimalFichiersModule,
        BrowserNotSupportedModule,
        CalendrierModule,
        ChatModule,
        ClientsModule,
        CompteModule,
        ConnectorModule,
        DashboardModule,
        DeactivatedAccountModule,
        EntiteJuridiqueModule,
        EntitesGeographiqueModule,
        EspaceDisqueModule,
        FacturesModule,
        ForgotPassModule,
        InformationsBancaireModule,
        LoginModule,
        LogoutModule,
        NewsModule,
        OnboardingClientEndModule,
        OnboardingClientModule,
        OnboardingModule,
        OnboardingProEndModule,
        OnboardingProModule,
        OnboardingProPlansModule,
        PaiementsModule,
        ProchainsRendezVousModule,
        ReportModule,
        ResetPassModule,
        SettingsModule,
        TestModule,
        TodosModule,
        VeterinairesModule,
        VisioModule
    ]
})
export class RoutesModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from '../../../../models/subscription';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';

@Component({
    selector: 'app-plan-dialog',
    templateUrl: './plan-dialog.component.html',
    styleUrls: ['./plan-dialog.component.scss']
})
export class PlanDialogComponent {
    loading$ = new BehaviorSubject<boolean>(false);

    constructor(
        public dialogRef: MatDialogRef<PlanDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PlanDialogInterface
    ) {}

    onFormDone(event: Subscription): void {
        this.dialogRef.close(event);
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface PlanDialogInterface {
    entiteJuridique: EntiteJuridique;
    subscription: Subscription;
}

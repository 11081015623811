import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigService } from '../../../../../services/config.service';
import { RegionCountryInterface, RegionInterface } from '../../../../../models/interfaces/region.interface';

@Component({
    selector: 'app-input-region',
    templateUrl: './input-region.component.html',
    styleUrls: ['./input-region.component.scss']
})
export class InputRegionComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() controlCountry: AbstractControl;

    required: boolean;

    regionsFilter = new FormControl();
    regionsList: RegionInterface[] = [];
    regionsCountryList: RegionCountryInterface[] = [];
    filteredRegionsOptions$: BehaviorSubject<RegionInterface[]> = new BehaviorSubject<RegionInterface[]>([]);

    private subscriptionRegions: Subscription;

    constructor(private configService: ConfigService) {}

    ngOnInit(): void {
        this.regionsFilter.valueChanges.subscribe(value => {
            let filteredRegionsOptions: RegionInterface[] = this.regionsList;

            if (this.regionsList.length > 0 && this.regionsFilter.value) {
                filteredRegionsOptions = this.regionsList.filter(r => r.name.toLowerCase().includes(value.toLowerCase()));
            }

            this.filteredRegionsOptions$.next(filteredRegionsOptions);
        });

        this.controlCountry.valueChanges.subscribe(() => {
            this.control.setValue(null);
            this.getRegionsForcountry();
        });

        this.subscriptionRegions = this.configService.getRegionsList().subscribe(value => {
            this.regionsCountryList = value;
            this.getRegionsForcountry();
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionRegions) {
            this.subscriptionRegions.unsubscribe();
        }
    }

    private getRegionsForcountry() {
        setTimeout(() => {
            this.regionsList = [];
            this.required = false;

            if (this.controlCountry.value && this.regionsCountryList) {
                const countryFound = this.regionsCountryList.filter(r => r.countryShortCode.toLowerCase() === this.controlCountry.value.toLowerCase());
                if (countryFound.length > 0) {
                    this.required = true;
                    this.regionsList = countryFound[0].regions;
                }
            }

            if (this.required) {
                this.control.setValidators(Validators.required);
            } else {
                this.control.clearValidators();
            }

            this.control.updateValueAndValidity({ emitEvent: false });
            this.filteredRegionsOptions$.next(this.regionsList);
        });
    }
}

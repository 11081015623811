import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { isFuture, isPast, isSameDay, isSameMonth, isToday, isWeekend, startOfDay, startOfMonth } from 'date-fns';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay } from 'angular-calendar';
import { fuseAnimations } from '@fuse/animations';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { ListRendezVous, RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { UtilisateurService } from '../../../services/api/utilisateur.service';

@Component({
    selector: 'app-calendrier',
    templateUrl: './calendrier.component.html',
    styleUrls: ['./calendrier.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CalendrierComponent implements OnInit, OnDestroy {
    now: Date = new Date();
    view: 'month' | 'week' | 'day';
    viewDate: Date;

    lastStartDate: Date;

    selectedDay: CalendarMonthViewDay<RendezVous>;
    expandedMonthDay: Date;

    events$: BehaviorSubject<Array<CalendarEvent<RendezVous>>> = new BehaviorSubject<Array<CalendarEvent<RendezVous>>>([]);
    refresh$: Subject<boolean> = new Subject<boolean>();

    loadingInProgress: boolean;
    dialogOpened: boolean;

    locale: string;

    private subscription: Subscription;

    constructor(
        public dialog: MatDialog,
        private readonly rendezVousService: RendezVousService,
        private readonly utilisateurService: UtilisateurService
    ) {
        this.view = 'month';
        this.viewDate = new Date();
        this.setSelectedDayForDate(this.viewDate);
    }

    ngOnInit(): void {
        this.locale = this.utilisateurService.utilisateurConnectedValue.localeIso;
        this.refreshRendezVous();
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    refreshRendezVous(force = false): void {
        this.expandedMonthDay = null;
        const startMonth = startOfMonth(this.viewDate);

        if (force || !this.lastStartDate || this.lastStartDate.getTime() !== startMonth.getTime()) {
            this.lastStartDate = startMonth;
            this.loadingInProgress = true;

            const nextMonth = new Date(startMonth);
            nextMonth.setUTCMonth(nextMonth.getUTCMonth() + 2);
            nextMonth.setUTCDate(5);

            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            this.subscription = this.rendezVousService.getAllBetweenToDate(startMonth, nextMonth, null, null, null, 'all').subscribe({
                next: (rendezVousList: ListRendezVous) => {
                    this.loadingInProgress = false;

                    this.events$.next(rendezVousList.data.map((rendezVous: RendezVous) => {
                        return this.rendezVousService.createEventFromRendezVous(rendezVous);
                    }));
                    this.refresh$.next(true);
                },
                error: () => {
                    this.loadingInProgress = false;
                }
            });
        }
    }

    setSelectedDayForDate(date: Date): void {
        this.selectedDay = {
            inMonth: isSameMonth(date, new Date()),
            events: [],
            badgeTotal: 0,
            date: startOfDay(date),
            day: date.getDay(),
            isPast: isPast(date),
            isFuture: isFuture(date),
            isWeekend: isWeekend(date),
            isToday: isToday(date)
        };
    }

    dayClicked(day: CalendarMonthViewDay<RendezVous>): void {
        this.expandedMonthDay = null;
        const date: Date = day.date;
        const events = day.events as Array<CalendarEvent<RendezVous>>;

        if (isSameMonth(date, this.viewDate) && !isSameDay(this.viewDate, date) && events.length > 0) {
                this.viewDate = date;
            }

        this.selectedDay = day;
        this.viewDate = date;
        this.view = 'day';
    }

    eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent<RendezVous>): void {
        if (!this.dialogOpened) {
            const oldStart = event.start;
            const oldEnd = event.end;

            const rendezVousNewDate: RendezVous = event.meta;
            this.rendezVousService.setDateRendezVousFrom2Dates(rendezVousNewDate, newStart, newEnd);

            this.replaceEvent(this.rendezVousService.createEventFromRendezVous(rendezVousNewDate));
            this.refresh$.next(true);

            this.rendezVousService.updateRendezVous(rendezVousNewDate.hasPost()).subscribe({
                next: rendezVous => {
                    this.replaceEvent(this.rendezVousService.createEventFromRendezVous(rendezVous));
                    this.refresh$.next(true);
                },
                error: () => {
                    this.rendezVousService.setDateRendezVousFrom2Dates(rendezVousNewDate, oldStart, oldEnd);
                    this.replaceEvent(this.rendezVousService.createEventFromRendezVous(rendezVousNewDate));
                    this.refresh$.next(true);
                }
            });
        }
    }

    editRendezVous(event: CalendarEvent<RendezVous> = null): void {
        let isNew: boolean;
        let rendezVous: RendezVous;

        if (!event) {
            isNew = true;
            rendezVous = new RendezVous();
            rendezVous.veterinaire = this.utilisateurService.utilisateurConnectedValue;
        } else if (event.meta) {
            isNew = false;
            rendezVous = event.meta;
        }

        if (rendezVous) {
            this.dialogOpened = true;
            this.rendezVousService.openDialogRendezVous(rendezVous).afterClosed().subscribe(result => {
                if (result) {
                    if (result === 'deleted') {
                        this.deleteEvent(event);
                    } else if (result instanceof RendezVous) {
                        if (isNew) {
                            this.addEvent(this.rendezVousService.createEventFromRendezVous(result));
                        } else {
                            this.replaceEvent(this.rendezVousService.createEventFromRendezVous(result));
                        }
                    }
                }

                this.dialogOpened = false;
            });
        }
    }

    public deleteEvent(event: CalendarEvent<RendezVous>): void {
        const events = this.events$.getValue();
        const eventI = events.findIndex((eventSearch: CalendarEvent) => eventSearch.id === event.id);
        if (eventI !== -1) {
            events.splice(eventI, 1);
            this.events$.next(events);
            this.refresh$.next(true);
        }
    }

    public replaceEvent(event: CalendarEvent<RendezVous>): void {
        const events = this.events$.getValue();
        const eventI = events.findIndex((eventSearch: CalendarEvent<RendezVous>) => eventSearch.meta.id === event.meta.id);
        if (eventI !== -1) {
            events[eventI] = event;
            this.events$.next(events);
            this.refresh$.next(true);
        }
    }

    public addEvent(event: CalendarEvent<RendezVous>): void {
        const events = this.events$.getValue();
        events.push(event);
        this.events$.next(events);
        this.refresh$.next(true);
    }
}

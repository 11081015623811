import { Component, EventEmitter, Input, Output, HostListener, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-color-picker',
    templateUrl: './color-picker.component.html',
    styleUrls: ['./color-picker.component.scss'],
    animations: [
        trigger('selectionBox', [
            transition(':enter', [
                style({ transform: 'scale(0.9)', opacity: 0 }), // initial
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({ transform: 'scale(1)', opacity: 1 })) // final
            ]),
            transition(':leave', [
                style({ transform: 'scale(1)', opacity: 1, height: '*' }),
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({
                        transform: 'scale(0.9) translateY(-16px)', opacity: 0,
                        height: '0px'
                    }))
            ])
        ])
    ]
})
export class ColorPickerComponent implements OnInit, OnDestroy {
    @Input() color: string = null;
    @Input() colors: string[] = [];
    @Input() tooltip: string = null;
    @Output() event: EventEmitter<string> = new EventEmitter<string>();

    public show = false;
    private subscription: Subscription;

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        if (!this.ref.nativeElement.contains(event.target)) {
            this.show = false;
        }
    }

    constructor(
        private ref: ElementRef,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {}

    ngOnInit(): void {
        if (this.colors.length === 0) {
            this.subscription = this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.subscribe(entiteJuridique => {
                this.colors = entiteJuridique.chatListColors;
            });
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    public changeColor(color: string): void {
        this.color = color;
        this.event.emit(this.color);
        this.show = false;
    }

    public removeColor(): void {
        this.color = null;
        this.event.emit(this.color);
        this.show = false;
    }

    public toggleColors(): void {
        this.show = !this.show;
    }
}

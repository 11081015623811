import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { DelayedMessage } from '../../../../models/delayed-message';
import { DelayedMessagePostInterface } from '../../../../models/interfaces/post/delayed-message-post.interface';
import { DelayedMessageService } from '../../../../services/api/delayed-message.service';
import {
    FichiersAnimalDialogComponent,
    FichiersAnimalDialogInterface
} from '../../../shared/fichiers-animal-dialog/fichiers-animal-dialog.component';
import { map } from 'rxjs/operators';
import { Fichier } from '../../../../models/fichier';
import { AnimalService } from '../../../../services/api/animal.service';

@Component({
    selector: 'app-chat-edit-delayed-message-dialog',
    templateUrl: './chat-edit-delayed-message-dialog.component.html',
    styleUrls: ['./chat-edit-delayed-message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChatEditDelayedMessageDialogComponent implements OnDestroy {
    isEdit = false;
    formInProgress$ = new Subject<boolean>();
    formInProgressDelete$ = new Subject<boolean>();

    form: FormGroup;
    attachment: Fichier;
    delayedMessage: DelayedMessage;

    currentDate = new Date();

    private subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ChatEditDelayedMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ChatUpdateDelayedMessageDialogInterface,
        private dialog: MatDialog,
        private delayedMessageService: DelayedMessageService,
        private animalService: AnimalService
    ) {
        this.isEdit = data.edit;
        this.delayedMessage = data.delayedMessage;
        this.attachment = data.delayedMessage.attachment;

        this.form = new FormGroup({
            id: new FormControl(data.delayedMessage.id),
            message: new FormControl(data.delayedMessage.message, [Validators.required]),
            date: new FormControl(data.delayedMessage.date, [Validators.required]),
            chat: new FormControl(data.delayedMessage.chat.id, [Validators.required]),
            sender: new FormControl(data.delayedMessage.sender.id, [Validators.required]),
            reopenChatIfClosed: new FormControl(data.delayedMessage.numberDaysToOpenIfClosed > 0),
            numberDaysToOpenIfClosed: new FormControl(data.delayedMessage.numberDaysToOpenIfClosed),
            attachment: new FormControl(this.attachment?.id)
        });

        const numberDaysToOpenIfClosedForm = this.form.get('numberDaysToOpenIfClosed');
        if (!numberDaysToOpenIfClosedForm.value) {
            numberDaysToOpenIfClosedForm.disable();
        }

        this.form.get('reopenChatIfClosed').valueChanges.subscribe(val => {
            if (val) {
                numberDaysToOpenIfClosedForm.enable();
            } else {
                numberDaysToOpenIfClosedForm.disable();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    hourValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const valid = Number((control.value as string).split(':').shift()) > new Date().getHours();
            return valid ? null : { dateInvalid: { value: control.value as string } };
        };
    }

    submit(): void {
        if (this.form.valid) {
            const data: DelayedMessagePostInterface = {
                id: this.form.get('id').value,
                message: this.form.get('message').value,
                date: this.form.get('date').value,
                chatId: this.form.get('chat').value,
                senderId: this.form.get('sender').value,
                numberDaysToOpenIfClosed: this.form.get('numberDaysToOpenIfClosed').value,
                attachmentId: this.form.get('attachment').value
            };
            this.save(data);
        }
    }

    openFichiersModal(): void {
        const data: FichiersAnimalDialogInterface = {
            reqFichiers: this.animalService.getFichiers(this.delayedMessage.chat.animal.id).pipe(map(f => f.data)),
            animal: this.delayedMessage.chat.animal
        };
        this.dialog.open(FichiersAnimalDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '60vw',
            minHeight: '60vh',
            disableClose: true,
            data
        }).afterClosed().subscribe(result => {
            if (result instanceof Fichier) {
                this.attachment = result;
                this.form.get('attachment').setValue(result.id);
            }
        });
    }

    removeFile(): void {
        this.form.get('attachment').setValue(null);
        this.attachment = null;
    }

    remove(): void {
        this.formInProgressDelete$.next(true);
        this.delayedMessageService.delete(this.delayedMessage.id).subscribe(() => {
            this.delayedMessage.chat.delayedMessagesCount--;

            this.formInProgressDelete$.next(false);
            this.close();
        });
    }

    private save(data: DelayedMessagePostInterface) {
        this.formInProgress$.next(true);

        if (data.id) {
            this.delayedMessageService.update(data).subscribe({
                next: res => {
                    this.formInProgress$.next(false);
                    this.dialogRef.close(res);
                },
                error: () => this.formInProgress$.next(false)
            });
        } else {
            this.delayedMessageService.add(data).subscribe({
                next: res => {
                    this.delayedMessage.chat.delayedMessagesCount++;
                    this.formInProgress$.next(false);
                    this.dialogRef.close(res);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }
}

export interface ChatUpdateDelayedMessageDialogInterface {
    delayedMessage: DelayedMessage;
    edit: boolean;
}

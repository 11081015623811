import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from 'app/shared.module';
import { ViewSharedModule } from '../view-shared.module';
import { ListTodosComponent, TransitionGroupTodosComponent, TransitionGroupTodoDirective } from './list-todos.component';

@NgModule({
    declarations: [
        ListTodosComponent, TransitionGroupTodosComponent, TransitionGroupTodoDirective
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        SharedModule,
        ViewSharedModule
    ],
    exports: [ListTodosComponent]
})
export class ListTodosModule {}

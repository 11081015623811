import { NgModule } from '@angular/core';
import { AnimalDialogComponent } from './animal-dialog/animal-dialog.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { ClientDialogComponent } from './client-dialog/client-dialog.component';
import { VeterinaireDialogComponent } from './veterinaire-dialog/veterinaire-dialog.component';
import { RendezVousDialogComponent } from './rendez-vous-dialog/rendez-vous-dialog.component';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { SharedModule } from '../../../shared.module';
import { EntiteGeographiqueDialogComponent } from './entite-geographique-dialog/entite-geographique-dialog.component';
import { FormSharedModule } from '../form/form-shared.module';
import { PaymentCaptureDialogComponent } from './payment-capture-dialog/payment-capture-dialog.component';
import { UserForceUpdateDialogComponent } from './user-force-update-dialog/user-force-update-dialog.component';
import { ViewUtilsSharedModule } from '../view-utils/view-utils-shared.module';
import { ShortcutDialogComponent } from './shortcut-dialog/shortcut-dialog.component';
import { RendezVousWebDialogComponent } from './rendez-vous-web-dialog/rendez-vous-web-dialog.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { PlanDialogComponent } from './plan-dialog/plan-dialog.component';
import { NewsDialogComponent } from './news-dialog/news-dialog.component';
import { NewsSegmentDialogComponent } from './news-segment-dialog/news-segment-dialog.component';
import { VeterinaryReassignDialogComponent } from './veterinary-reassign-dialog/veterinary-reassign-dialog.component';

@NgModule({
    declarations: [
        AnimalDialogComponent,
        ChatDialogComponent,
        ClientDialogComponent,
        NewsDialogComponent,
        NewsSegmentDialogComponent,
        EntiteGeographiqueDialogComponent,
        PaymentCaptureDialogComponent,
        PlanDialogComponent,
        RendezVousDialogComponent,
        RendezVousWebDialogComponent,
        ShortcutDialogComponent,
        UserForceUpdateDialogComponent,
        VeterinaireDialogComponent,
        VeterinaryReassignDialogComponent
    ],
    imports: [
        FuseSharedModule,
        SharedModule,
        FormSharedModule,
        ViewUtilsSharedModule,
        NgxCurrencyModule
    ]
})
export class FormDialogSharedModule {}

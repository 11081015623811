import { Component } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { VeterinairePostInterface } from '../../../models/interfaces/post/utilisateurs/veterinaire-post.interface';
import { filter, map } from 'rxjs/operators';
import { Veterinaire } from '../../../models/utilisateurs/veterinaire';

@Component({
    selector: 'app-compte',
    templateUrl: './compte.component.html',
    styleUrls: ['./compte.component.scss'],
    animations: fuseAnimations
})
export class CompteComponent {
    userData$: BehaviorSubject<VeterinairePostInterface> = new BehaviorSubject<VeterinairePostInterface>(null);
    user$: Observable<Veterinaire>;

    constructor(utilisateurService: UtilisateurService) {
        this.user$ = utilisateurService.utilisateurConnected.pipe(
            filter(u => Boolean(u))
        );

        utilisateurService.utilisateurConnected.pipe(
            filter(u => Boolean(u)),
            map((u: Veterinaire) => u.hasPost())
        ).subscribe(value => {
            this.userData$.next(value);
        });
    }
}

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">account_balance_wallet</mat-icon>
                <span class="logo-text h1">{{ 'NAV.ABONNEMENT' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-accordion *ngIf="(subscription | async); else loading; let subscription" class="my-16" [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>info</mat-icon>
                            <span>{{ 'SUBSCRIPTION.DETAILS.TITLE' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="16px">
                        <div fxLayout="row wrap" fxLayoutAlign="space-around start" fxLayoutGap="8px">
                            <div class="card-detail">
                                <span class="card-detail-title">{{ 'SUBSCRIPTION.DETAILS.INVOICE' | translate }}</span>
                                <span class="card-detail-content" [innerHTML]="((montly | async) ? 'SUBSCRIPTION.DETAILS.INVOICE_MONTHLY' : 'SUBSCRIPTION.DETAILS.INVOICE_YEARLY') | translate"></span>
                            </div>
                            <div class="card-detail">
                                <span class="card-detail-title">{{ 'SUBSCRIPTION.DETAILS.LICENSE' | translate }}</span>
                                <span *ngIf="(nbLicensesBase | async) && (nbLicensesSup | async)" class="card-detail-content" [innerHTML]="'SUBSCRIPTION.DETAILS.LICENSE_DETAIL' | translate:{ total: (nbLicensesTotal | async) , base: (nbLicensesBase | async), supplement: (nbLicensesSup | async) }"></span>
                                <span *ngIf="!((nbLicensesBase | async) && (nbLicensesSup | async))" class="card-detail-content">{{ nbLicensesTotal | async }}</span>
                            </div>
                            <div class="card-detail">
                                <span class="card-detail-title">{{ 'SUBSCRIPTION.DETAILS.TCHAT' | translate }}</span>
                                <span class="card-detail-content" [innerHTML]="'SUBSCRIPTION.DETAILS.TCHAT_DETAIL' | translate"></span>
                            </div>
                            <div class="card-detail">
                                <span class="card-detail-title">{{ 'SUBSCRIPTION.DETAILS.VISIO' | translate }}</span>
                                <span class="card-detail-content" [innerHTML]="((nbFreeHours | async) > 0 ? 'SUBSCRIPTION.DETAILS.VISIO_DETAIL_FREE_HOURS' : 'SUBSCRIPTION.DETAILS.VISIO_DETAIL') | translate:{ n: (nbFreeHours | async) }"></span>
                            </div>
                            <!-- <div class="card-detail">
                                <span class="card-detail-title">{{ 'SUBSCRIPTION.DETAILS.PAYMENT' | translate }}</span>
                                <span class="card-detail-content" [class.green-fg]="paymentAuthorized | async" [class.red-fg]="!(paymentAuthorized | async)" [innerHTML]="((paymentAuthorized | async) ? 'SUBSCRIPTION.DETAILS.PAYMENT_ACTIVATED' : 'SUBSCRIPTION.DETAILS.PAYMENT_DEACTIVATED') | translate"></span>
                            </div> -->
                        </div>

                        <div *ngIf="subscription?.discount?.coupon; let coupon;" class="coupon" fxLayout="row" fxLayoutGap="16px">
                            <div fxFlex="1 1 100%" fxLayout="column">
                                <div><b>{{ 'SUBSCRIPTION.INVOICES_COUPON' | translate }}</b></div>
                                <div [innerHTML]="'SUBSCRIPTION.INVOICES_COUPON_SPEECH' | translate:{ endDate: (getDateEndCoupon(subscription) | dateLocale:'shortDate'), priceAfter: (((getPriceAfterEndCoupon(subscription) / 100) | number:'1.0-2') + '&nbsp;&euro;'), codeCoupon: getCouponCode(coupon), descriptionCoupon: coupon.metadata.description }"></div>
                            </div>
                            <div *ngIf="!!coupon?.metadata?.isHidden !== true" fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="center center">
                                <div class="code text-uppercase">{{ getCouponCode(coupon) }}</div>
                            </div>
                        </div>

                        <div *ngIf="subscription.currentInvoice; let invoice; else noCurrentInvoice">
                            <div class="mb-8 invoice-detail-title">{{ 'SUBSCRIPTION.DETAILS.TABLE.TITLE' | translate:{ date: (now | dateLocale:'shortDate'), payDate: (invoice.periodEnd | dateLocale:'shortDate') } }}</div>
                            <div class="inner-card">
                                <table class="w-100-p subscription">
                                    <thead>
                                        <tr>
                                            <th>{{ 'SUBSCRIPTION.DETAILS.TABLE.DETAIL' | translate }}</th>
                                            <th class="w-80 text-right">{{ 'SUBSCRIPTION.DETAILS.TABLE.QUANTITY' | translate }}</th>
                                            <th class="w-80 text-right">{{ 'SUBSCRIPTION.DETAILS.TABLE.PRICE' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let group of currentIvoiceGroupedItems">
                                            <tr>
                                                <td class="period" colspan="3">{{ 'SUBSCRIPTION.DETAILS.TABLE.PERIOD' | translate:{ periodStart: (group.periodStart | dateLocale:'shortDate'), periodEnd: (group.periodEnd | dateLocale:'shortDate')} }}</td>
                                            </tr>
                                            <tr *ngFor="let item of group.items">
                                                <td>{{ item.product?.name }}</td>
                                                <td class="text-right">{{ item.quantity === null ? '' : item.quantity }}</td>
                                                <td class="text-right">{{ (item.price / 100) | number:'1.0-2' }}&nbsp;&euro;</td>
                                            </tr>
                                        </ng-container>
                                        <tr>
                                            <td colspan="3">
                                                <mat-divider></mat-divider>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'SUBSCRIPTION.DETAILS.TABLE.TOTAL_HT' | translate }}</td>
                                            <td colspan="2" class="text-right">
                                                {{ (invoice.subtotalPriceHT / 100) | number:'1.0-2' }}&nbsp;&euro;
                                            </td>
                                        </tr>
                                        <tr *ngIf="(subscription?.discount?.endDate == null || subscription?.discount?.endDate > invoice.periodEnd) && subscription?.discount?.coupon; let coupon">
                                            <td class="text-bold" colspan="2">{{ coupon.metadata.description }}</td>
                                            <td *ngIf="coupon.amountOff" class="text-right green-fg">-{{ (coupon.amountOff / 100) | number:'1.0-2' }}&nbsp;&euro;</td>
                                            <td *ngIf="coupon.percentOff" class="text-right green-fg">-{{ coupon.percentOff * 100 }}&nbsp;%</td>
                                        </tr>
                                        <tr *ngIf="invoice.percentTaxes && invoice.percentTaxes > 0">
                                            <td>{{ 'SUBSCRIPTION.DETAILS.TABLE.VAT' | translate }} ({{ invoice.percentTaxes }} %)</td>
                                            <td colspan="2" class="text-right">
                                                {{ (invoice.totalTaxes / 100) | number:'1.0-2' }}&nbsp;&euro;
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{{ 'SUBSCRIPTION.DETAILS.TABLE.TOTAL' | translate }}</td>
                                            <td colspan="2" class="text-right">
                                                {{ (invoice.totalPrice / 100) | number:'1.0-2' }}&nbsp;&euro;
                                            </td>
                                        </tr>
                                        <tr *ngIf="invoice.balance != 0">
                                            <td>{{ 'SUBSCRIPTION.DETAILS.TABLE.BALANCE' | translate }}</td>
                                            <td colspan="2" class="text-right">
                                                {{ (invoice.balance / 100) | number:'1.0-2' }}&nbsp;&euro;
                                            </td>
                                        </tr>
                                        <tr *ngIf="invoice.totalDue != invoice.totalPrice">
                                            <td>{{ 'SUBSCRIPTION.DETAILS.TABLE.TOTAL_DUE' | translate }}</td>
                                            <td colspan="2" class="text-right">
                                                {{ (invoice.totalDue / 100) | number:'1.0-2' }}&nbsp;&euro;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <ng-template #noCurrentInvoice>
                            <div class="no-current-invoice mt-16 mb-16" [innerHTML]="(entiteJuridique | async).cancelSubscriptionDate ? ('SUBSCRIPTION.LAST_INVOICE_INCOMING' | translate:{ date: ((entiteJuridique | async).cancelSubscriptionDate | date:'longDate')}) : ('SUBSCRIPTION.NO_INVOICE_INCOMING' | translate)">
                            </div>
                        </ng-template>

                        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                            <button mat-raised-button color="accent" *ngIf="!isCanceled && (montly | async)"
                                    (click)="updateSubcription()">{{ 'SUBSCRIPTION.DETAILS.UPDATE_SUBSCRIPTION' | translate }}</button>
                            <button mat-button color="warn" *ngIf="!isCanceled" (click)="showCancelSubscriptionDialog()">{{ 'SUBSCRIPTION.DETAILS.CANCEL_SUBSCRIPTION' | translate }}</button>
                            <button mat-button color="warn" *ngIf="isCanceled" (click)="showUncancelSubscriptionDialog()">{{ 'SUBSCRIPTION.DETAILS.UNCANCEL_SUBSCRIPTION' | translate }}</button>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>schedule</mat-icon>
                            <span [innerHTML]="'SUBSCRIPTION.FOLLOWUP.TITLE' | translate:{ date: (currentDate | dateLocale:'longDate') }"></span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <p [innerHTML]="'SUBSCRIPTION.FOLLOWUP.DESCRIPTION' | translate:{ date: (subscription?.currentPeriodEnd | dateLocale:'longDate')}"></p>
                        <div class="stats" fxLayout="row wrap" fxLayoutAlign="space-around center" fxLayoutGap="10px">
                            <div class="stat" fxLayout="column" fxLayoutAlign="center center">
                                <span class="stat-title">{{ 'SUBSCRIPTION.FOLLOWUP.STATS.VISIO' | translate }}</span>
                                <span class="stat-detail">{{ stats.visio | timeDuration:true }}</span>
                            </div>
                            <div class="stat" fxLayout="column" fxLayoutAlign="center center">
                                <span class="stat-title">{{ 'SUBSCRIPTION.FOLLOWUP.STATS.STORAGE' | translate }}</span>
                                <span class="stat-detail">{{ stats.space | fileSize }} / {{ stats?.maxSpace }} {{'SHARED.FILESIZE.GB' | translate}}</span>
                            </div>
                            <div class="stat" fxLayout="column" fxLayoutAlign="center center" *ngIf="stats.payments">
                                <span
                                    class="stat-title">{{ 'SUBSCRIPTION.FOLLOWUP.STATS.PAYMENTSRECEIVED' | translate }}</span>
                                <span
                                    class="stat-detail">{{ stats.payments | currencyLocale:stats.paymentsCurrency }}</span>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>payment</mat-icon>
                            <span>{{ 'SUBSCRIPTION.PAYMENT.TITLE' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div *ngIf="subscription?.card; else nocard" class="payment-card-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between end" fxLayoutGap="24px">
                            <div class="payment-card inner-card" fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex="1 1 auto">
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                    <div class="card-type">
                                        <span class="card-type-{{ subscription.card.brand | lowercase}}"></span>
                                    </div>
                                    <div class="payment-card-detail">
                                        <span class="payment-card-detail-title">{{ 'SUBSCRIPTION.PAYMENT.NUMBER' | translate }}</span>
                                        <span class="payment-card-detail-content">•••• •••• •••• {{ subscription.card.last4Number }}</span>
                                    </div>
                                </div>
                                <div class="payment-card-detail">
                                    <span class="payment-card-detail-title">{{ 'SUBSCRIPTION.PAYMENT.BRAND' | translate }}</span>
                                    <span class="payment-card-detail-content">{{ subscription.card.brand | titlecase }}</span>
                                </div>
                                <div class="payment-card-detail">
                                    <span class="payment-card-detail-title">{{ 'SUBSCRIPTION.PAYMENT.EXPIRATION' | translate }}</span>
                                    <span class="payment-card-detail-content">{{ subscription.card.expMonth < 10 ? '0' + subscription.card.expMonth : subscription.card.expMonth }}/{{ subscription.card.expYear }}</span>
                                </div>
                            </div>
                            <button mat-raised-button color="accent" (click)="updatePaymentMethod()">{{ 'SHARED.UPDATE' | translate }}</button>
                        </div>
                        <ng-template #nocard>
                            <p>{{ 'SUBSCRIPTION.PAYMENT.NOCARD' | translate }}</p>
                            <button mat-raised-button color="accent" (click)="updatePaymentMethod()">{{ 'SHARED.ADD' | translate }}</button>
                        </ng-template>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>receipt</mat-icon>
                            <span>{{ 'SUBSCRIPTION.INVOICES' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <p [innerHTML]="'SUBSCRIPTION.INVOICES_SPEECH' | translate:{ nextInvoiceDate: (subscription.currentPeriodEnd | dateLocale:'longDate' ), periodStart: (subscription.currentPeriodStart | dateLocale:'longDate' ), periodEnd: (subscription.currentPeriodEnd | dateLocale:'longDate' ) }"></p>

                        <div fxLayout="row" fxLayoutAlign="space-between end">
                            <div fxLayout="row" fxLayoutGap="8px">
                                <ng-container
                                    *ngIf="subscription?.currentInvoice"
                                    [ngTemplateOutlet]="invoice"
                                    [ngTemplateOutletContext]="{ invoice: subscription?.currentInvoice }">
                                </ng-container>

                                <ng-container
                                    *ngIf="subscription?.lastInvoice"
                                    [ngTemplateOutlet]="invoice"
                                    [ngTemplateOutletContext]="{ invoice: subscription?.lastInvoice }">
                                </ng-container>

                                <ng-template #invoice let-invoice='invoice'>
                                    <div class="invoice inner-card" fxLayout="column" fxLayoutGap="4px">
                                        <div class="mb-8"><b><span *ngIf="invoice.localNumber">{{ invoice.localNumber }}&nbsp;&nbsp;•&nbsp;&nbsp;</span>{{ invoice.periodEnd | dateLocale:'MMMM yyyy' }}</b></div>
                                        <div>
                                            <span *ngIf="invoice.subtotalPrice && invoice.subtotalPrice !== invoice.totalPrice">
                                                <span style="text-decoration: line-through;">{{ (invoice.subtotalPrice / 100) | number:'1.0-2' }}&nbsp;&euro;</span>&nbsp;&rarr;&nbsp;
                                            </span>
                                            {{ (invoice.totalPrice / 100) | number:'1.0-2' }}&nbsp;&euro;&nbsp;&nbsp;•&nbsp;&nbsp;
                                            <span [ngSwitch]="invoice.status">
                                                <span *ngSwitchCase="'draft'" class="badge orange mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_DRAFT' | translate }}</span>
                                                <span *ngSwitchCase="'open'" class="badge orange mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_OPEN' | translate }}</span>
                                                <span *ngSwitchCase="'paid'" class="badge green mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_PAID' | translate }}</span>
                                                <span *ngSwitchCase="'void'" class="badge red mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_VOID' | translate }}</span>
                                                <span *ngSwitchCase="'uncollectible'" class="badge red mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_UNCOLLECTIBLE' | translate }}</span>
                                            </span>
                                            <span *ngIf="invoice?.discount?.coupon" class="badge">{{ 'SUBSCRIPTION.INVOICES_COUPON' | translate }}</span>
                                        </div>
                                        <a *ngIf="invoice.status == 'paid' || invoice.status == 'open'" (click)="downloadInvoice(invoice)" class="download-invoice">{{ 'SUBSCRIPTION.DOWNLOAD' | translate }}</a>
                                    </div>
                                </ng-template>
                            </div>
                            <a mat-raised-button routerLink="/subscription/invoices" color="accent" class="text-uppercase">{{ 'SUBSCRIPTION.INVOICES_MORE' | translate }}</a>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <ng-template #loading>
                <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                    <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                    <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

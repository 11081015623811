import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { SettingsComponent } from './settings.component';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { ChatScenarioDialogComponent } from './chat-scenario-dialog/chat-scenario-dialog.component';
import { ChatScenarioMessageComponent } from './chat-scenario-dialog/chat-scenario-message/chat-scenario-message.component';
import { SettingsChatComponent } from './settings-chat/settings-chat.component';
import { ScenariDatatableComponent } from './chat-scenario-dialog/scenari-datatable/scenari-datatable.component';
import { ScenarioMessageDialogComponent } from './chat-scenario-dialog/scenario-message-dialog/scenario-message-dialog.component';
import { ChatScenarioMessageSelectorComponent } from './chat-scenario-dialog/chat-scenario-message-selector/chat-scenario-message-selector.component';
import { AuthGuard } from '../../../guards/auth.guard';

const routes = [
    {
        path: 'settings/general',
        component: SettingsComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'settings/chats',
        component: SettingsChatComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        SettingsComponent,
        SettingsChatComponent,
        ChatScenarioDialogComponent,
        ChatScenarioMessageComponent,
        ScenariDatatableComponent,
        ScenarioMessageDialogComponent,
        ChatScenarioMessageSelectorComponent

    ],
    exports: [
        ChatScenarioMessageSelectorComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class SettingsModule {}

import { CacheKey, Deserializer, Serializer } from 'json-object-mapper';
import { StatusPaymentRendezVousEnum } from '../models/rendez-vous/rendez-vous';

@CacheKey('StatusPaymentRendezVousMapper')
export class StatusPaymentRendezVousMapper implements Deserializer, Serializer {
    deserialize = (value: string): StatusPaymentRendezVousEnum => {
        return StatusPaymentRendezVousEnum[value] as StatusPaymentRendezVousEnum;
    };

    serialize = (value: StatusPaymentRendezVousEnum): string => {
        const obj = Object.entries(StatusPaymentRendezVousEnum).find(o => o[1] === value)[0];
        return obj?.length ? obj[0] : null;
    };
}

import { JsonProperty } from 'json-object-mapper';
import { OwnerPostInterface } from '../../interfaces/post/pro/stripe/owner-post.interface';
import { DateMapper } from '../../../object-mapper/date-mapper';

export class Owner {
    @JsonProperty()
    firstName: string = null;

    @JsonProperty()
    lastName: string = null;

    @JsonProperty()
    mail: string = null;

    @JsonProperty()
    phone: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    birthDate: Date = null;

    @JsonProperty()
    address: string = null;

    @JsonProperty()
    zipCode: string = null;

    @JsonProperty()
    city: string = null;

    @JsonProperty()
    region: string = null;

    @JsonProperty()
    country: string = null;

    @JsonProperty()
    status: string = null;

    @JsonProperty()
    detailsCodeFailed: string = null;

    @JsonProperty()
    isVerified: boolean = null;

    public hasPost(): OwnerPostInterface {
        return {
            firstName: this.firstName,
            lastName: this.lastName,
            mail: this.mail,
            phone: this.phone,
            birthDate: this.birthDate,
            address: this.address,
            zipCode: this.zipCode,
            city: this.city,
            region: this.region,
            country: this.country
        };
    }
}


<div fxLayout="column">
    <div class="step-header">
        <img class="icon" src="../../../../assets/images/icons/onboarding-icon-animal.svg" alt="Animal Icon" fxFlex="0 0" />
        <h1 [innerHTML]="'ONBOARDING_CLIENT.STEPS.ANIMALS.TITLE' | translate"></h1>
        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.ANIMALS.SUBTITLE' | translate"></p>
    </div>

    <div fxLayout="column">
        <mat-tab-group #tabGroup>
            <mat-tab *ngFor="let control of controls; let i = index">
                <ng-template mat-tab-label>
                    {{ getAnimalName(i) ? getAnimalName(i) : ('ONBOARDING_CLIENT.STEPS.ANIMALS.ANIMAL_N' | translate:{ number: (i+1) }) }}
                    <mat-icon class="clickable ml-16" matRipple (click)="removeAnimal(i)">clear</mat-icon>
                </ng-template>
                <div class="pt-16">
                    <app-animal-form (formChanged)="onFormChanged($event, i)" [handleForm]="false"
                                     [showSaveButton]="false"
                                     [showDateDeces]="false"></app-animal-form>
                </div>
            </mat-tab>
        </mat-tab-group>

        <div fxLayout="row" fxLayoutAlign="end center" class="mt-32">
            <button mat-stroked-button (click)="addAnimal()" [disabled]="controls.length >= 10">
                <mat-icon matPrefix>add</mat-icon> {{ 'ONBOARDING_CLIENT.ADD_ANIMAL' | translate }}
            </button>
        </div>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'numberLocale',
    pure: false
})
export class NumberLocalePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: number, digitsInfo?: string): any {
        const decimalPipe: DecimalPipe = new DecimalPipe(this.translateService.currentLang);
        return decimalPipe.transform(value, digitsInfo, this.translateService.currentLang);
    }
}

import { Component, ViewEncapsulation } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { FuseNavigationService } from '../../../../@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { NavigationEnd, Router } from '@angular/router';
import { BugService } from '../../../services/api/bug.service';
import { filter, takeUntil } from 'rxjs/operators';
import { FuseConfig, FuseNavigationItem } from '@fuse/types';

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent {
    fuseConfig: FuseConfig;
    navigation: FuseNavigationItem[];

    version: string = environment.version;
    buildVersion: string = environment.buildVersion;
    apiVersion$: Observable<string>;
    folded: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {Router} _router
     * @param bugService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _router: Router,
        private bugService: BugService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._fuseSidebarService.getSidebar('navbar').foldedChanged.subscribe(folded => {
            this.folded = folded;
        });

        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                    if (this._fuseSidebarService.getSidebar('navbar')) {
                        this._fuseSidebarService.getSidebar('navbar').close();
                    }
                }
            );

        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(
                filter(value => value !== null),
                takeUntil(this._unsubscribeAll)
            )
            .subscribe(() => {
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
            });

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(config => {
                this.fuseConfig = config;
            });

        if (environment.hmr || environment.dev || environment.demo || environment.preproduction) {
            this.apiVersion$ = this.bugService.getLastCommit();
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObjectMapper } from 'json-object-mapper';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { DelayedMessagePostInterface } from '../../models/interfaces/post/delayed-message-post.interface';
import { DelayedMessage } from '../../models/delayed-message';
import {
    ChatEditDelayedMessageDialogComponent,
    ChatUpdateDelayedMessageDialogInterface
} from '../../main/content/chat/chat-edit-delayed-message-dialog/chat-edit-delayed-message-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DelayedMessageService {
    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public getAllByChatId(chatId: number): Observable<DelayedMessage[]> {
        return this.http.get<DelayedMessage[]>(this.config.baseUrl + 'api/delayed-messages/chat/' + chatId.toString()).pipe(
            map(listDelayedMessages => ObjectMapper.deserializeArray(DelayedMessage, listDelayedMessages))
        );
    }

    public getById(id: number): Observable<DelayedMessage> {
        return this.http.get<DelayedMessage>(this.config.baseUrl + 'api/delayed-message/' + id.toString()).pipe(
            map(delayedMessage => ObjectMapper.deserialize(DelayedMessage, delayedMessage))
        );
    }

    public add(data: DelayedMessagePostInterface): Observable<DelayedMessage> {
        this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.ADDING'), null, { duration: 1500 });
        data.id = 0;
        data.date = data.date ? Math.round((data.date as Date).getTime() / 1000) : null;
        return this.http.post<DelayedMessage>(this.config.baseUrl + 'api/chat/' + data.chatId.toString() + '/delayed-message', data).pipe(
            map(delayedMessage => ObjectMapper.deserialize(DelayedMessage, delayedMessage)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public update(data: DelayedMessagePostInterface): Observable<DelayedMessage> {
        this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.UPDATING'), null, { duration: 1500 });
        data.date = data.date ? Math.round((data.date as Date).getTime() / 1000) : null;
        return this.http.put<DelayedMessage>(this.config.baseUrl + 'api/delayed-message/' + data.id.toString(), data).pipe(
            map(delayedMessage => ObjectMapper.deserialize(DelayedMessage, delayedMessage)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public delete(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('DELAYED_MESSAGE.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('DELAYED_MESSAGE.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('DELAYED_MESSAGE.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.DELETING'), null, { duration: 1500 });
            return this.http.delete(this.config.baseUrl + 'api/delayed-message/' + id.toString())
                .pipe(tap(() => {
                    this.snackbar.open(this.translateService.instant('DELAYED_MESSAGE.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
        }));
    }

    public openDelayedMessageDialog(edit: boolean, delayedMessage: DelayedMessage): MatDialogRef<ChatEditDelayedMessageDialogComponent> {
        const data: ChatUpdateDelayedMessageDialogInterface = {
            delayedMessage: delayedMessage,
            edit: edit
        };

        return this.dialog.open(ChatEditDelayedMessageDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            disableClose: true,
            minWidth: '550px',
            width: '40vw'
        });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormatWidth, getLocaleTimeFormat } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'twelveHour'
})
export class TwelveHourPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(_notused?: string): boolean {
        return getLocaleTimeFormat(this.translateService.currentLang, FormatWidth.Short).endsWith('a');
    }
}

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text"
             fxLayout="column"
             fxLayoutAlign="center center"
             fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">settings</mat-icon>
                <span class="logo-text h1">{{ 'CONNECT_IMPORT.TITLE' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-vertical-stepper [linear]="true" #stepper (selectionChange)="changeStepper($event)">
                <mat-step [stepControl]="formConfig.get('config')">
                    <ng-template matStepLabel>{{ 'CONNECT_IMPORT.CONFIG.TYPE.TITLE' | translate }}</ng-template>

                    <form [formGroup]="formConfig.get('config') | asFormGroup" (submit)="fileImportInput.click()" fxLayout="column"
                          fxLayoutAlign="center center" fxLayoutGap="8px">
                        <mat-form-field appearance="outline" class="w-100-p">
                            <mat-label>{{ 'CONNECT_IMPORT.CONFIG.TYPE.LABEL' | translate }}</mat-label>
                            <mat-select formControlName="type" required>
                                <mat-option
                                    value="veterinaries">{{ 'CONNECT_IMPORT.CONFIG.TYPE.veterinaries' | translate }}</mat-option>
                                <mat-option
                                    value="clients">{{ 'CONNECT_IMPORT.CONFIG.TYPE.clients' | translate }}</mat-option>
                                <mat-option
                                    value="animalsClients">{{ 'CONNECT_IMPORT.CONFIG.TYPE.animalsClients' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <input type="file" #fileImportInput formControlName="file"
                               style="display: none" (change)="fileChangeListener($event, stepper)" accept=".csv"/>

                        <button mat-button type="submit"
                                [disabled]="formConfig.get('config').get('type').invalid">{{ 'CONNECT_IMPORT.CONFIG.TYPE.GO' | translate }}</button>
                    </form>
                </mat-step>

                <mat-step [stepControl]="formConfig.get('configCsv')">
                    <ng-template matStepLabel>{{ 'CONNECT_IMPORT.CONFIG.CSV.TITLE' | translate }}</ng-template>

                    <form [formGroup]="formConfig.get('configCsv') | asFormGroup" fxLayout="column" fxLayoutAlign="center center"
                          fxLayoutGap="8px">
                        <mat-list class="w-100-p">
                            <mat-list-item class="w-100-p"
                                           *ngFor="let control of (configCsv.controls | keyvalue)">
                                <span fxFlex>{{ ('CONNECT_IMPORT.CONFIG.CSV.' + control.key) | translate }}</span>

                                <mat-icon fxFlex>arrow_forward</mat-icon>

                                <mat-form-field fxFlex appearance="standard" class="w-100-p">
                                    <mat-label>{{ ('CONNECT_IMPORT.CONFIG.CSV.' + control.key) | translate }}</mat-label>
                                    <mat-select [formControl]="control.value | asFormControl"
                                                [required]="isRequiredField(control.value)">
                                        <mat-option *ngFor="let column of csvDatasColumns" [value]="column">
                                            {{ column }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="control.value.hasError('required')">
                                        {{ 'SHARED.REQUIRED' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                        </mat-list>

                        <div fxLayout="row" fxLayoutGap="8px">
                            <button mat-button type="button"
                                    matStepperPrevious>{{ 'SHARED.PREVIOUS' | translate }}</button>
                            <button mat-button type="button" matStepperNext>{{ 'SHARED.NEXT' | translate }}</button>
                        </div>
                    </form>
                </mat-step>

                <mat-step [stepControl]="formConfig.get('configCsv')">
                    <ng-template matStepLabel>{{ 'CONNECT_IMPORT.TABLE.TITLE' | translate  }}</ng-template>

                    <div *ngIf="!loading; else loadingTemplate">
                        <h3>{{ 'CONNECT_IMPORT.CONFIG.TYPE.LABEL' | translate }}
                            : {{ 'CONNECT_IMPORT.CONFIG.TYPE.' + currentTypeChoosen | translate }}</h3>

                        <div>
                            <mat-form-field appearance="outline" class="w-100-p">
                                <mat-label>{{ 'SHARED.SEARCH' | translate }}</mat-label>
                                <input [formControl]="filterControl" matInput
                                       [placeholder]="'SHARED.SEARCH' | translate"/>
                            </mat-form-field>
                        </div>
                        <cdk-virtual-scroll-viewport [minBufferPx]="780" [maxBufferPx]="1560" [itemSize]="130"
                                                     style="height: 830px">
                            <table class="simple">
                                <thead>
                                <tr>
                                    <th *ngFor="let header of csvDatasColumnsTable">{{ ('CONNECT_IMPORT.CONFIG.CSV.' + header) | translate }}</th>
                                    <th>{{ 'CONNECT_IMPORT.TABLE.PERCENT_SURE' | translate }}</th>
                                    <th>{{ 'CONNECT_IMPORT.TABLE.TARGET_ENTITY' | translate }}</th>
                                    <th>{{ 'SHARED.ACTIONS' | translate }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *cdkVirtualFor="let data of csvDatasFiltered$ | async"
                                    [class.blue-fg]="data.percentSureLink == 1"
                                    [class.green-fg]="data.percentSureLink >= 0.75 && data.percentSureLink < 1"
                                    [class.yellow-fg]="data.percentSureLink >= 0.50 && data.percentSureLink < 0.75"
                                    [class.orange-fg]="data.percentSureLink >= 0.25 && data.percentSureLink < 0.5"
                                    [class.red-fg]="data.percentSureLink < 0.25"
                                >
                                    <td *ngFor="let column of csvDatasColumnsTable"
                                        [innerHTML]="data.data[column]"></td>
                                    <td>
                                        <span
                                            *ngIf="data.percentSureLink == 1; else percent">{{ 'CONNECTOR.ENTITY_LINKED' | translate }}</span>
                                        <ng-template #percent>
                                            {{ data.percentSureLink * 100 }}%
                                        </ng-template>
                                    </td>
                                    <td>
                                        <div [ngSwitch]="currentTypeChoosen" *ngIf="data.control">
                                            <app-input-veterinaire *ngSwitchCase="'veterinaries'"
                                                                   [nullValue]="'VETO.ADD' | translate"
                                                                   [control]="data.control"
                                                                   [showMailTel]="true"></app-input-veterinaire>
                                            <app-input-client *ngSwitchCase="'clients'"
                                                              [maxShown]="null"
                                                              [nullValue]="'CLIENT.ADD' | translate"
                                                              [clients]="entities"
                                                              [control]="data.control"
                                                              [showMailTel]="true"></app-input-client>
                                            <app-input-animal *ngSwitchCase="'animalsClients'"
                                                              [maxShown]="null"
                                                              [nullValue]="'PET.ADD' | translate"
                                                              [clients]="entities"
                                                              [control]="data.control"
                                                              [nullValueForAll]="'PET.ADD' | translate"
                                                              [showMailTel]="true"></app-input-animal>
                                        </div>
                                    </td>
                                    <td>
                                        <button mat-button (click)="doAction(data)" *ngIf="data.control">
                                            {{ (data.control.value ? 'SHARED.UPDATE' : 'SHARED.ADD') | translate }}
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div fxLayout="row" fxLayoutGap="8px">
                                <button mat-button matStepperPrevious>{{ 'SHARED.PREVIOUS' | translate }}</button>
                            </div>
                        </cdk-virtual-scroll-viewport>
                    </div>
                </mat-step>
            </mat-vertical-stepper>

            <ng-template #loadingTemplate>
                <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;" class="mt-16">
                    <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                    <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatScenarioMessage, ChatShortcut } from '../../../../../models/pro/entite-juridique';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-scenario-message-dialog',
  templateUrl: './scenario-message-dialog.component.html',
  styleUrls: ['./scenario-message-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScenarioMessageDialogComponent {
    chatScenarioMessage: ChatScenarioMessage;
    chatScenarioMessageForm: FormGroup;
    shortcuts: ChatShortcut[] = [];

    constructor(
        public dialogRef: MatDialogRef<ScenarioMessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: MessageAndShortcuts) {
        this.chatScenarioMessage = data.message;
        this.shortcuts = data.shortcuts;

        this.chatScenarioMessageForm = new FormGroup({
            message: new FormControl(data?.message?.message, [Validators.required]),
            nbDaysAfter: new FormControl(data.message?.nbDaysAfter, [Validators.required, Validators.min(1)]),
            reopenChatIfClosed: new FormControl(!(data.message?.numberDaysToOpenIfClosed === 0 || data.message?.numberDaysToOpenIfClosed === null)),
            numberDaysToOpenIfClosed: new FormControl(data.message?.numberDaysToOpenIfClosed)
        });
    }

    addOrUpdate(form: FormGroup): void {
        const data: ChatScenarioMessage =
        {
           message: form.get('message').value,
           nbDaysAfter: form.get('nbDaysAfter').value,
           numberDaysToOpenIfClosed: form.get('numberDaysToOpenIfClosed').value,
           date: form.get('date')?.value ?? null
        };
        this.dialogRef.close(data);
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface MessageAndShortcuts {
    message?: ChatScenarioMessage;
    shortcuts?: ChatShortcut[];
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { ProchainsRendezVousComponent } from './prochains-rendez-vous.component';
import { RendezVousListCardModule } from '../../shared/rendez-vous-list-card/rendez-vous-list-card.module';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'appointments',
        component: ProchainsRendezVousComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'prochains-rendez-vous',
        redirectTo: '/next-appointments',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        ProchainsRendezVousComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule,

        RendezVousListCardModule
    ],
    exports: []
})

export class ProchainsRendezVousModule {}

<h2 mat-dialog-title>{{ 'REPORTS.DIALOGS.TEMPLATE.MAIN_TITLE' | translate }}</h2>
<div mat-dialog-content>
    <h3>{{ 'REPORTS.DIALOGS.TEMPLATE.NAME_TITLE' | translate }}</h3>
    <mat-form-field class="w-100-p">
        <mat-label>{{ 'REPORTS.DIALOGS.TEMPLATE.NAME_LABEL' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.template.name">
    </mat-form-field>
    <h3>{{ 'REPORTS.DIALOGS.TEMPLATE.RECORDING_OPTION_TITLE' | translate }}</h3>
    <mat-radio-group fxLayout="column" fxLayoutAlign="end start" fxLayoutGap="8px" [(ngModel)]="data.template.recordingOption">
        <mat-radio-button fxFlex value="{{ recordingOption.COPY }}" >{{ 'REPORTS.DIALOGS.TEMPLATE.RECORDING_OPTION_NEW' | translate }}</mat-radio-button>
        <mat-radio-button fxFlex value="{{ recordingOption.ORIGINAL }}">{{ 'REPORTS.DIALOGS.TEMPLATE.RECORDING_OPTION_UPDATE' | translate }}</mat-radio-button>
    </mat-radio-group>
    <div class="message-box warning mt-24" *ngIf="!canBeRecorded()">{{ warningMsg }}</div>
</div>
<div mat-dialog-actions class="mt-24 mb-16" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px">
    <button class="mat-raised-button" (click)="onNoClick()">{{ 'REPORTS.DIALOGS.TEMPLATE.CANCEL_BUTTON' | translate }}</button>
    <button class="mat-raised-button mat-primary" [disabled]="!canBeRecorded()" [mat-dialog-close]="data">{{ 'REPORTS.DIALOGS.TEMPLATE.SUBMIT_BUTTON' | translate }}</button>
</div>

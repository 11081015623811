<div *ngIf="url || emoji; else entityView">
    <img *ngIf="url; else emojiView" [src]="url.url" class="avatar"/>
    <ng-template #emojiView>
        <div class="border-radius-100 accent-500-bg text-center empty-icon"
            [class.xsmall]="size == 'XSMALL'"
            [class.small]="size == 'SMALL'"
            [class.medium]="size == 'MEDIUM'"
            [class.large]="size == 'LARGE'"
            [class.xlarge]="size == 'XLARGE'"
            [innerHTML]="emoji"></div>
    </ng-template>
</div>

<ng-template #entityView>
    <div *ngIf="entity?.photo && $any(entity.photo).url; else emptyImageView" fxLayout="row" fxLayoutAlign="center center">
        <img [src]="$any(entity.photo).url | filePath" class="avatar" [alt]="entity?.nom"
             [class.xsmall]="size == 'XSMALL'"
             [class.small]="size == 'SMALL'"
             [class.medium]="size == 'MEDIUM'"
             [class.large]="size == 'LARGE'"
             [class.xlarge]="size == 'XLARGE'"/>
    </div>

    <ng-template #emptyImageView>
        <div class="border-radius-100 accent-500-bg text-center empty-icon"
             [class.xsmall]="size == 'XSMALL'"
             [class.small]="size == 'SMALL'"
             [class.medium]="size == 'MEDIUM'"
             [class.large]="size == 'LARGE'"
             [class.xlarge]="size == 'XLARGE'"
             [innerHTML]="entity | entityEmoji"></div>
    </ng-template>
</ng-template>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './api/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ErrorInterface, FormErrorInterface } from 'app/models/interfaces/api/error.interface';
import { BugService } from './api/bug.service';
import { environment } from '../../environments/environment';
import { NavigationStart, Router } from '@angular/router';
import { UtilisateurService } from './api/utilisateur.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent, DialogDataInterface } from 'app/main/shared/view-utils/dialog/dialog.component';

export const InterceptorSkipHandler = 'X-Skip-Interceptor-Handler';

@Injectable({
    providedIn: 'root'
})
export class HttpTokenAndErrorInterceptor implements HttpInterceptor {
    private currentRoute: NavigationStart;

    constructor(
        router: Router,
        public authService: AuthService,
        private snackbar: MatSnackBar,
        private translateService: TranslateService,
        private utilisateurService: UtilisateurService,
        private dialog: MatDialog
    ) {
        router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe((e: NavigationStart) => {
                this.currentRoute = e;
            });
    }

    private addLanguageHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('Accept-Language', this.translateService.currentLang ?? 'en')
        });
    }

    private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
        return request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + this.authService.token)
        });
    }

    private addLogHeaders(request: HttpRequest<any>): HttpRequest<any> {
        let headers = request.headers
            .set('X-Session-User', BugService.session)
            .set('X-Version-App', environment.version + ' build ' + environment.buildVersion)
            .set('X-Platform', 'web')
            .set('X-Accept-Version', 'v1');
        if (this.currentRoute?.url) {
            headers = headers.set('X-Context', this.currentRoute.url);
        }

        if (this.utilisateurService.utilisateurConnectedValue?.id) {
            headers = headers.set('X-User-Id', this.utilisateurService.utilisateurConnectedValue.id.toString());
        }

        return request.clone({
            headers
        });
    }

    private addHeaders(request: HttpRequest<any>): HttpRequest<any> {
        let shouldIgnoreAuthHeader = false;
        const ignored: string[] = [
            environment.supportUrl,
            environment.mediprod.url,
            'oauth/v2/token',
            'signup',
            '.json'
        ];

        for (const ignoredRequest of ignored) {
            if (request.urlWithParams.includes(ignoredRequest)) {
                shouldIgnoreAuthHeader = true;
                break;
            }
        }

        const fixSignup: string[] = [
            'signup/pro/plans',
            'signup_client_link'
        ];

        for (const fix of fixSignup) {
            if (request.urlWithParams.includes(fix)) {
                shouldIgnoreAuthHeader = false;
                break;
            }
        }

        if (!shouldIgnoreAuthHeader) {
            request = this.addLanguageHeader(request);
            request = this.addLogHeaders(request);
            request = this.addAuthHeader(request);
        }

        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addHeaders(request);

        if (request.headers.has(InterceptorSkipHandler)) {
            const headers = request.headers.delete(InterceptorSkipHandler);
            return next.handle(request.clone({ headers }));
        }

        let shouldIgnore = false;
        const ignored: string[] = [
            environment.supportUrl,
            environment.mediprod.url,
            'oauth/v2/token',
            'api/user/logout',
            'api/user/find',
            'api/log',
            'assets',
            'api/ping'
        ];

        for (const ignoredRequest of ignored) {
            if (request.urlWithParams.includes(ignoredRequest)) {
                shouldIgnore = true;
                break;
            }
        }

        if (shouldIgnore) {
            return next.handle(request);
        }

        return next.handle(request).pipe(
            catchError((err: Error) => {
                if (err instanceof HttpErrorResponse && err.status !== 404) {
                    // Server Token Error (-> Refresh Token)
                    if (err.status === 401 && !request.url.includes('switch')) {
                        return this.authService.refreshAccessToken().pipe(switchMap(() => next.handle(this.addAuthHeader(request))));
                    }

                    if (!err.error) {
                        return throwError(() => err);
                    }

                    // Server Error
                    const serverError: ErrorInterface = err.error;
                    const okBtnMessage = this.translateService.instant('SHARED.OK');

                    if (serverError && serverError.statusCode >= 400) {
                        if (environment.production) {
                            const errorMessage = this.translateService.instant('SHARED.ERROR_MESSAGE', { message: serverError.message });
                            this.snackbar.open(errorMessage, okBtnMessage, { duration: 5000 });
                        } else {
                            const snackbar = this.snackbar.open(this.translateService.instant('SHARED.ERROR_SERVER', {
                                status: `${err.status} : ${err.statusText}`
                            }), 'Voir l\'erreur');
                            snackbar.onAction().subscribe(() => {
                                this.showException(request.url, serverError);
                            });
                        }

                        return throwError(() => err);
                    }

                    // Server Form Error
                    const serverFormError: FormErrorInterface = err.error;
                    if (serverFormError.errors) {
                        const msg: string = Object.keys(serverFormError.errors)
                        .map(k => k + ' : ' + serverFormError.errors[k].join(', '))
                        .join(', ');
                        const errorMessage = this.translateService.instant('SHARED.ERROR_MESSAGE', { message: msg });
                        this.snackbar.open(errorMessage, okBtnMessage, { duration: 5000 });
                        return throwError(() => err);
                    }

                    this.snackbar.open(this.translateService.instant('SHARED.ERROR'), okBtnMessage, { duration: 5000 });
                    return throwError(() => err);
                }

                return throwError(() => err);
            })
        );
    }

    private showException(
        request: string,
        error: ErrorInterface
    ): void {
        const title = `Erreur ${error.statusCode} sur ${request}`;
        let content = `<p><b>Message</b> : ${error.message}</p>`;

        if (error.innerMessage) {
            content += `<p><b>InnerMessage</b> : ${error.innerMessage}</p>`;
        }

        if (error.stackTrace) {
            content += `<pre>${error.stackTrace}}</pre>`;
        }

        if (content === '') {
            content = 'Erreur inconnue';
        }

        const data: DialogDataInterface = {
            action: false,
            cancel: null,
            ok: null,
            title,
            content
        };

        this.dialog.open(DialogComponent, { data, panelClass: 'server-error-modal' });
    }
}

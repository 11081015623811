/* eslint-disable unicorn/prefer-module */
import './polyfills';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import { hmrBootstrap } from 'hmr';

const bootstrap = async () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
    if ((module as any).hot) {
        hmrBootstrap(module as any, bootstrap);
    } else {
        void bootstrap();
    }
} else {
    enableProdMode();
    void bootstrap();
}

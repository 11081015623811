import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { TypeUtilisateurEnum, Utilisateur } from '../../models/utilisateurs/utilisateur';
import { filter, map, share, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObjectMapper } from 'json-object-mapper';
import { UtilisateurPostInterface } from '../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { ListVeterinaires, Veterinaire } from '../../models/utilisateurs/veterinaire';
import { Client, ListClients } from '../../models/utilisateurs/client';
import {
    ClientDialogComponent,
    ClientDialogInterface
} from '../../main/shared/form-dialog/client-dialog/client-dialog.component';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import {
    VeterinaireDialogComponent,
    VeterinaireDialogInterface
} from 'app/main/shared/form-dialog/veterinaire-dialog/veterinaire-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import { TimezoneService } from '../timezone.service';
import {
    UserForceUpdateDialogComponent,
    UserForceUpdateDialogInterface
} from '../../main/shared/form-dialog/user-force-update-dialog/user-force-update-dialog.component';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { EntiteGeographique } from '../../models/pro/entite-geographique';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { configureScope } from '@sentry/browser';
import { Animal } from '../../models/animal/animal';
import { FuseNavigationItem } from '@fuse/types';
import { J } from '@angular/cdk/keycodes';
import {
    VeterinaryReassignDialogComponent
} from '../../main/shared/form-dialog/veterinary-reassign-dialog/veterinary-reassign-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class UtilisateurService {
    private _utilisateurConnected: BehaviorSubject<Veterinaire> = new BehaviorSubject<Veterinaire>(null);

    public veterinaires$: BehaviorSubject<Veterinaire[]> = new BehaviorSubject<Veterinaire[]>([]);

    constructor(private http: HttpClient, private config: ConfigService,
        private snackbar: MatSnackBar, private dialog: MatDialog,
        private translateService: TranslateService, timezoneService: TimezoneService,
        private navigationService: FuseNavigationService,
        public googleAnalyticsService: GoogleAnalyticsService) {
        this.utilisateurConnected.pipe(filter(u => u !== null)).subscribe(user => {
            timezoneService.use(user.timezone);
            if (user.stripeClientId) {
                environment.stripeClientId = user.stripeClientId;
            }

            if (user.stripePublishableKey) {
                environment.stripePublicKey = user.stripePublishableKey;
            }

            this.navigationService.onNavigationRegistered.subscribe(nav => {
                if (nav) {
                    const currentNav = nav[1] as FuseNavigationItem[];
                    const isAdmin = user.isProprietaire || environment.demo;

                    const subscriptionItem = this.navigationService.getNavigationItem('my-subscription', currentNav);
                    if (subscriptionItem) {
                        subscriptionItem.type = isAdmin && !user.entiteJuridique.isSubscriptionManaged ? 'item' : 'hidden';
                    }

                    const invoicesItem = this.navigationService.getNavigationItem('my-invoices', currentNav);
                    if (invoicesItem) {
                        invoicesItem.type = isAdmin && !user.entiteJuridique.isSubscriptionManaged ? 'item' : 'hidden';
                    }

                    const legalInformation = this.navigationService.getNavigationItem('legal-information', currentNav);
                    if (legalInformation) {
                        legalInformation.type = isAdmin ? 'item' : 'hidden';
                    }

                    const itemBankItem = this.navigationService.getNavigationItem('bank-information', currentNav);
                    if (itemBankItem) {
                        itemBankItem.type = isAdmin ? 'item' : 'hidden';
                    }

                    const connect = this.navigationService.getNavigationItem('connect', currentNav);
                    if (connect) {
                        connect.type = isAdmin ? 'item' : 'hidden';
                    }

                    const news = this.navigationService.getNavigationItem('news', currentNav);
                    if (news) {
                        news.type = user.entiteJuridique.newsAllowed ? 'item' : 'hidden';
                    }
                }
            });
        });
    }

    public get utilisateurConnected(): Observable<Veterinaire> {
        return this._utilisateurConnected.asObservable().pipe(filter(u => u !== null));
    }

    public get isConnected(): boolean {
        return Boolean(this.utilisateurConnectedValue);
    }

    public get utilisateurConnectedValue(): Veterinaire {
        return this._utilisateurConnected.getValue();
    }

    public set utilisateurConnectedValue(veterinaire: Veterinaire) {
        this._utilisateurConnected.next(veterinaire);
    }

    public getMe(): Observable<Veterinaire> {
        if (this._utilisateurConnected.getValue()) {
            return of(this._utilisateurConnected.getValue());
        }

        return this.http.get<Veterinaire>(this.config.baseUrl + 'api/user/me').pipe(
            share(),
            map(user => {
                const userOk = ObjectMapper.deserialize(Veterinaire, user);
                if (user.entiteJuridique === null) {
                    throw new Error('User should have EJ');
                }

                userOk.entiteJuridique = ObjectMapper.deserialize(EntiteJuridique, user.entiteJuridique);
                return userOk;
            }),
            tap(user => {
                this.utilisateurConnectedValue = user;

                if (!environment.hmr) {
                    if (environment.production) {
                        this.googleAnalyticsService.gtag('event', 'login', {
                            user_type: 'pro',
                            user_job: user.fonction
                        });
                        this.googleAnalyticsService.gtag('config', 'GA_MEASUREMENT_ID', {
                            user_id: user.id
                        });
                    }

                    configureScope(scope => {
                        scope.setUser({
                            username: user.username,
                            id: user.id.toString(),
                            email: user.mail,
                            nom: user.fullNameLite,
                            entiteJuridiqueId: user.entiteJuridique.id,
                            entiteJuridique: user.entiteJuridique.nom
                        });
                    });
                }
            })
        );
    }

    public updateEntiteJuridiqueForUtilisateurConnected(entiteJuridique: EntiteJuridique): boolean {
        let shoudlRefresh = false;
        const ej = this.utilisateurConnectedValue.entiteJuridique;
        if (ej !== null && ej.id === entiteJuridique.id) {
            shoudlRefresh = true;
            this.utilisateurConnectedValue.entiteJuridique = entiteJuridique;
        }

        if (shoudlRefresh) {
            entiteJuridique.isFull = true;
            // this.utilisateurConnectedValue = this._utilisateurConnected.value;
            return true;
        }

        return false;
    }

    public updateEntiteGeographiqueForUtilisateurConnected(entiteGeographique: EntiteGeographique): boolean {
        const eg = this.utilisateurConnectedValue.entiteGeographique;
        if (eg !== null && eg.id === entiteGeographique.id) {
            this.utilisateurConnectedValue.entiteGeographique = entiteGeographique;
            // this.utilisateurConnectedValue = this.utilisateurConnectedValue;
            return true;
        }

        return false;
    }

    public getById(id: number): Observable<Utilisateur> {
        return this.http.get<Utilisateur>(this.config.baseUrl + 'api/user/' + id.toString()).pipe(
            map(user => {
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            })
        );
    }

    public resendNotificationAccountCreated(id: number, type: string): Observable<void> {
        if (type === 'veterinaire') {
            type = 'veto';
        }

        type = type.toUpperCase();

        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant(type + '.RESEND_NOTIFICATION_ACCOUNT_CREATED.ACTION'),
                content: this.translateService.instant(type + '.RESEND_NOTIFICATION_ACCOUNT_CREATED.HELP'),
                action: true,
                ok: this.translateService.instant('SHARED.YES')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(value => Boolean(value)),
            switchMap(() => {
                this.snackbar.open(this.translateService.instant(type + '.RESEND_NOTIFICATION_ACCOUNT_CREATED.PROGRESS'), null, { duration: 1500 });
                return this.http.get<void>(this.config.baseUrl + 'api/user/' + id.toString() + '/resend_notification_account_created');
            }),
            tap(() => this.snackbar.open(this.translateService.instant(type + '.RESEND_NOTIFICATION_ACCOUNT_CREATED.OK'), null, { duration: 1500 }))
        );
    }

    public addUtilisateur(utilisateur: UtilisateurPostInterface): Observable<Utilisateur> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.ADDING'), null, { duration: 1500 });
        utilisateur.id = 0;
        return this.http.post<Utilisateur>(this.config.baseUrl + 'api/' + utilisateur.typeUtilisateur, utilisateur).pipe(
            map(user => {
                this.snackbar.open(this.translateService.instant('UTILISATEUR.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            })
        );
    }

    public updateUtilisateur(utilisateur: UtilisateurPostInterface): Observable<Utilisateur> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.UPDATING'), null, { duration: 1500 });
        return this.http.put<Utilisateur>(this.config.baseUrl + 'api/user/' + utilisateur.id.toString(), utilisateur).pipe(
            map(user => {
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            }),
            tap(user => {
                if (user.id === this.utilisateurConnectedValue.id) {
                    this.utilisateurConnectedValue = user as Veterinaire;
                }

                this.snackbar.open(this.translateService.instant('UTILISATEUR.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public setPasswordUtilisateur(newPassword: string): Observable<Utilisateur> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.PASSWORD_UPDATING'), null, { duration: 1500 });
        return this.http.patch<Utilisateur>(this.config.baseUrl + 'api/user/password', { new: newPassword }).pipe(
            map(user => {
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            }),
            tap(user => {
                if (user.id === this.utilisateurConnectedValue.id) {
                    this.utilisateurConnectedValue = user as Veterinaire;
                }

                this.snackbar.open(this.translateService.instant('UTILISATEUR.PASSWORD_UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public setPhoto(id: number, image?: Blob): Observable<Utilisateur> {
        this.snackbar.open(this.translateService.instant('UTILISATEUR.PHOTO_UPDATING'), null, { duration: 1500 });
        const formData: FormData = new FormData();
        if (image instanceof Blob) {
            formData.append('photo', image);
        }

        return this.http.post<Utilisateur>(this.config.baseUrl + 'api/user/' + id.toString() + '/photo', formData).pipe(
            map(user => {
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            }),
            tap(user => {
                if (user.id === this.utilisateurConnectedValue.id) {
                    this.utilisateurConnectedValue = user as Veterinaire;
                }

                this.snackbar.open(this.translateService.instant('UTILISATEUR.PHOTO_UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public deleteUtilisateur(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('UTILISATEUR.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('UTILISATEUR.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('UTILISATEUR.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(confirmed => Boolean(confirmed)),
            switchMap(() => {
                this.snackbar.open(this.translateService.instant('UTILISATEUR.DELETING'), null);
                return this.http.delete(this.config.baseUrl + 'api/user/' + id.toString())
                    .pipe(tap(() => {
                        this.snackbar.open(this.translateService.instant('UTILISATEUR.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    }));
            }));
    }

    public deleteVeterinary(id: number, juridicEntityId: number): Observable<any> {
        const dialog = this.dialog.open(VeterinaryReassignDialogComponent, {
            data: {
                userId: id
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(confirmed => Boolean(confirmed)),
            switchMap(data => {
                this.snackbar.open(this.translateService.instant('UTILISATEUR.DELETING'), null);
                return this.http.delete(this.config.baseUrl + 'api/veterinaire/' + id.toString() + '/juridic_entity/' + juridicEntityId.toString() + '?newUserId=' + data.toString())
                    .pipe(tap(() => {
                        this.snackbar.open(this.translateService.instant('UTILISATEUR.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    }));
            }));
    }

    public acceptCGUV(): Observable<Utilisateur> {
        return this.http.patch<Utilisateur>(this.config.baseUrl + 'api/user/cguv', null).pipe(
            map(user => {
                if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                    return ObjectMapper.deserialize(Client, user);
                }

                return ObjectMapper.deserialize(Veterinaire, user);
            }),
            tap(user => {
                if (user.id === this.utilisateurConnectedValue.id) {
                    this.utilisateurConnectedValue = user as Veterinaire;
                }
            }));
    }

    public openDialogClient(client: Client | any): MatDialogRef<ClientDialogComponent> {
        const data: ClientDialogInterface = {
            client: client instanceof Client || Object.prototype.hasOwnProperty.call(client, 'hasPost') ? client : ObjectMapper.deserialize(Client, client),
            utilisateurService: this
        };
        data.client.typeUtilisateur = TypeUtilisateurEnum.client;
        return this.dialog.open(ClientDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public openDialog(user: Utilisateur | any): MatDialogRef<ClientDialogComponent | VeterinaireDialogComponent> {
        return user instanceof Client ? this.openDialogClient(user) : this.openDialogVeterinaire(user);
    }

    public openDialogVeterinaire(veterinaire: Veterinaire | any, disabled = false): MatDialogRef<VeterinaireDialogComponent> {
        const data: VeterinaireDialogInterface = {
            veterinaire: veterinaire instanceof Veterinaire || Object.prototype.hasOwnProperty.call(veterinaire, 'hasPost') ? veterinaire : ObjectMapper.deserialize(Veterinaire, veterinaire),
            utilisateurService: this,
            disabled: disabled
        };
        data.veterinaire.typeUtilisateur = TypeUtilisateurEnum.veterinaire;
        return this.dialog.open(VeterinaireDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public openDialogForceUpdate(utilisateur: Utilisateur | any): MatDialogRef<UserForceUpdateDialogComponent> {
        const data: UserForceUpdateDialogInterface = {
            utilisateur: utilisateur instanceof Utilisateur || Object.prototype.hasOwnProperty.call(utilisateur, 'hasPost') ? utilisateur : ObjectMapper.deserialize(Utilisateur, utilisateur),
            utilisateurService: this
        };
        return this.dialog.open(UserForceUpdateDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public getClients(
        keywords: string = null,
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit = 20,
        autocomplete: boolean = null,
        ids: number[] = null,
        animalId: number = null
    ): Observable<ListClients> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        if (autocomplete) {
            params = params.append('autocomplete', autocomplete.toString());
        }

        if (ids?.length > 0) {
            params = params.append('ids', ids.join(','));
        }

        if (animalId > 0) {
            params = params.append('animalId', animalId.toString());
        }

        return this.http.get<ListClients>(this.config.baseUrl + 'api/clients', {
            params: params
        }).pipe(
            map(users => ObjectMapper.deserialize(ListClients, users)),
            tap(users => users.data.forEach(u => {
                u.animaux = ObjectMapper.deserializeArray(Animal, u.animaux);
            }))
        );
    }

    public getVeterinaires(
        keywords: string = null,
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit = 20,
        autocomplete: boolean = null,
        ids: number[] = null
    ): Observable<ListVeterinaires> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        if (autocomplete) {
            params = params.append('autocomplete', autocomplete.toString());
        }

        if (ids?.length > 0) {
            params = params.append('ids', ids.join(','));
        }

        return this.http.get<ListVeterinaires>(this.config.baseUrl + 'api/veterinaires', {
            params: params
        }).pipe(
            map(users => ObjectMapper.deserialize(ListVeterinaires, users))
        );
    }

    public disconnect(id: number): Observable<any> {
        this.snackbar.open(this.translateService.instant('LOGIN.DISCONNECTING'), null, { duration: 1500 });
        return this.http.get(this.config.baseUrl + 'api/user/logout/' + id.toString())
            .pipe(tap(() => {
                this.snackbar.open(this.translateService.instant('LOGIN.DISCONNECTED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                this.utilisateurConnectedValue = null;
            }));
    }

    public emoji(user: Utilisateur | UtilisateurPostInterface): string {
        if (user.typeUtilisateur) {
            const mr = this.translateService.instant('CIVILITY.MR').toLowerCase();
            const mrs = this.translateService.instant('CIVILITY.MRS').toLowerCase();
            const miss = this.translateService.instant('CIVILITY.MISS').toLowerCase();

            if (user.typeUtilisateur === TypeUtilisateurEnum.client) {
                if (!user.civilite) {
                    return '🧑';
                }

                switch (user.civilite.toLowerCase()) {
                    case mr:
                        return '👨';
                    case mrs:
                    case miss:
                        return '👩';
                    default:
                        return '🧑';
                }
            } else if (user.typeUtilisateur === TypeUtilisateurEnum.veterinaire) {
                if (!user.civilite) {
                    return '🧑‍⚕️';
                }

                switch (user.civilite.toLowerCase()) {
                    case mr:
                        return '👨‍⚕️';
                    case mrs:
                    case miss:
                        return '👩‍⚕️';
                    default:
                        return '🧑‍⚕️';
                }
            }
        }

        return '🧑';
    }
}

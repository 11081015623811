import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VeterinairePostInterface } from '../../../../../models/interfaces/post/utilisateurs/veterinaire-post.interface';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilisateurPostInterface } from 'app/models/interfaces/post/utilisateurs/utilisateur-post.interface';

@Component({
    selector: 'app-veterinaire-form',
    templateUrl: './veterinaire-form.component.html',
    styleUrls: ['./veterinaire-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VeterinaireFormComponent implements OnInit, OnDestroy {
    @Input('utilisateurData') utilisateurData$: Observable<UtilisateurPostInterface>;
    @Input() form: FormGroup;
    @Input() disabled = false;
    @Input() isMe = false;
    @Input() country;

    private subscriptionUserData: Subscription;

    ngOnInit(): void {
        const numeroOrdreControl = new FormControl();
        numeroOrdreControl.setParent(this.form);
        this.form.addControl('numeroOrdre', numeroOrdreControl);

        const fonctionControl = new FormControl();
        fonctionControl.setParent(this.form);
        this.form.addControl('fonction', fonctionControl);

        const appProAllowedControl = new FormControl(true);
        appProAllowedControl.setParent(this.form);
        this.form.addControl('appProAllowed', appProAllowedControl);

        this.subscriptionUserData = this.utilisateurData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((utilisateurData: VeterinairePostInterface) => {
            this.form.patchValue({
                numeroOrdre: utilisateurData.numeroOrdre,
                fonction: utilisateurData.fonction,
                appProAllowed: utilisateurData.appProAllowed
            }, { emitEvent: false });
        });

        if (this.disabled) {
            this.form.disable();
        }

        if (this.isMe) {
            this.form.get('appProAllowed').disable();
        }
    }

    ngOnDestroy(): void {
        this.subscriptionUserData?.unsubscribe();
    }
}

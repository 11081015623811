import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Animal } from '../../../../models/animal/animal';
import { AnimalService } from '../../../../services/api/animal.service';
import { Client } from '../../../../models/utilisateurs/client';

@Component({
    selector: 'app-rendez-vous-web-dialog',
    templateUrl: './rendez-vous-web-dialog.component.html',
    styleUrls: ['./rendez-vous-web-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RendezVousWebDialogComponent implements OnInit, OnDestroy {
    rendezVous$: BehaviorSubject<RendezVous> = new BehaviorSubject<RendezVous>(null);
    loading$ = new BehaviorSubject<boolean>(false);

    private subscription: Subscription;

    constructor(
        private animalService: AnimalService,
        public dialogRef: MatDialogRef<RendezVousWebDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RendezVousWebDialogInterface
    ) {}

    ngOnInit(): void {
        if (this.data.animal.id &&
            (
                !this.data.animal.proprietaire || !this.data.animal.proprietaire.telephonePortable ||
                (!this.data.client || !this.data.client.telephonePortable)
            )
        ) {
            this.loading$.next(true);
            this.subscription = this.animalService.getById(this.data.animal.id).subscribe({
                next: (animal: Animal) => {
                    this.data.client = animal.proprietaire;
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close()
            });
        } else {
            this.data.client = this.data.animal.proprietaire;
        }
    }

    close(): void {
        this.dialogRef.close(this.rendezVous$.getValue());
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

export interface RendezVousWebDialogInterface {
    animal: Animal;
    client?: Client;
}

<div class="pt-8 pb-16" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="localeFullUnderscore">
    <a target="_blank"
       href="https://apps.apple.com/{{ locale}}/app/univet-pro/id1488741479?mt=8"
       style="display:inline-block;overflow:hidden;background-repeat: no-repeat;width:135px;height:40px;"
       [style.background]="'url(https://linkmaker.itunes.apple.com/' + localeFullDash + '/badge-lrg.svg?releaseDate=2019-11-22&kind=iossoftware&bubble=ios_apps)'"></a>

    <a target="_blank" style="width: 160px"
       href='https://play.google.com/store/apps/details?id=com.univet.pro&hl={{ locale }}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
        [alt]="'APP_STORE.GOOGLE_AVAILABLE' | translate"
        src='https://play.google.com/intl/{{ localeFullUnderscore }}/badges/static/images/badges/{{ locale }}_badge_web_generic.png'/></a>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { switchMap, tap } from 'rxjs/operators';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { StatAnimal, StatSerie, StatSeries, StatTotal } from '../../../models/interfaces/stats.interface';
import { Router } from '@angular/router';
import { EntiteJuridiqueStatsFiles, StatsFiles } from '../../../models/pro/entite-juridique';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LegendPosition } from '@swimlane/ngx-charts';

@Component({
    selector: 'app-espace-disque',
    templateUrl: './espace-disque.component.html',
    styleUrls: ['./espace-disque.component.scss'],
    animations: fuseAnimations
})
export class EspaceDisqueComponent implements OnInit, OnDestroy {
    LegendPosition = LegendPosition;

    isLoading = true;
    customColors: StatSerie[] = [];
    statsTotal: StatTotal;
    statsByType: StatSeries[] = [];
    statsByAnimal: StatAnimal[] = [];

    private utilisateurSubscription: Subscription;

    constructor(
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private router: Router,
        private translateService: TranslateService
    ) {
        this.customColors = [
            {
                name: this.translateService.instant('SHARED.PICTURE'),
                value: '#188dd1'
            },
            {
                name: this.translateService.instant('SHARED.VIDEO'),
                value: '#48b5e3'
            },
            {
                name: this.translateService.instant('SHARED.AUDIO'),
                value: '#bfe5f5'
            },
            {
                name: this.translateService.instant('SHARED.OTHER'),
                value: '#63798e'
            },
            {
                name: this.translateService.instant('STORAGE.FREE'),
                value: '#eeeeee'
            }
        ];
    }

    ngOnInit(): void {
        this.utilisateurSubscription = this.utilisateurService.utilisateurConnected.pipe(
            switchMap(veterinaire => this.entiteJuridiqueService.getStatsFilesById(veterinaire.entiteJuridique.id)),
            tap((statsFiles: EntiteJuridiqueStatsFiles) => {
                this.isLoading = false;
                this.generateStatsTotal(statsFiles);
                this.generateStatsByType(statsFiles);
                this.generateStatsByAnimal(statsFiles);
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        if (this.utilisateurSubscription) {
            this.utilisateurSubscription.unsubscribe();
        }
    }

    goToAnimalUsage(id: number): void {
        void this.router.navigate(['animal', id, 'files']);
    }

    trackByFn(_index: number, item: StatAnimal): number {
        return item.animal?.id;
    }

    private generateStatsTotal(statsFiles: EntiteJuridiqueStatsFiles): void {
        this.statsTotal = {
            total: statsFiles.nbGigaStockage * 1024 * 1024 * 1024,
            totalUsed: statsFiles.stats.map(v => v.taille).reduce((acc, current) => acc + current, 0)
        };
    }

    private generateStatsByType(statsFiles: EntiteJuridiqueStatsFiles): void {
        const statsByType: StatSerie[] = [];
        const fileTypes: FileType[] = [
            {
                name: 'PICTURE',
                type: 'image',
                icon: 'image'
            }, {
                name: 'VIDEO',
                type: 'video',
                icon: 'movie'
            }, {
                name: 'AUDIO',
                type: 'audio',
                icon: 'audiotrack'
            }, {
                name: 'OTHER',
                type: null,
                icon: 'description'
            }
        ];

        fileTypes.forEach(fileType => {
            let stats: StatsFiles[];
            if (fileType.type) {
                stats = statsFiles.stats.filter(files => files.contentType.includes(fileType.type));
            } else {
                stats = statsFiles.stats.filter(files => {
                    return !files.contentType.includes('image') &&
                        !files.contentType.includes('video') &&
                        !files.contentType.includes('audio');
                });
            }

            statsByType.push({
                name: this.translateService.instant(`SHARED.${fileType.name}`),
                value: stats.reduce((acc, stat) => acc + stat.taille, 0),
                extra: {
                    type: fileType.name,
                    nbFiles: stats.length,
                    icon: fileType.icon
                }
            });
        });

        statsByType.push({
            name: this.translateService.instant('STORAGE.FREE'),
            value: this.statsTotal.total - this.statsTotal.totalUsed
        });

        this.statsByType.push({
            name: null,
            series: statsByType
        });
    }

    private generateStatsByAnimal(statsFiles: EntiteJuridiqueStatsFiles): void {
        this.statsByAnimal = statsFiles.stats.reduce((all: StatAnimal[], stat: StatsFiles) => {
            const index = all.findIndex(a => a.animal.id === stat.animal.id);
            const animal: StatAnimal = index === -1 ? { animal: stat.animal, usage: 0 } : all[index];
            animal.usage += stat.taille;
            if (index === -1) {
                all.push(animal);
            } else {
                all[index] = animal;
            }

            return all;
        }, [])
            .sort((a, b) => b.usage - a.usage)
            .slice(0, 5);
    }
}

interface FileType {
    name: string;
    type?: string;
    icon?: string;
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { StepClinicComponent } from './step-clinic/step-clinic.component';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OnboardingService, OnboardingSession } from '../../services/api/onboarding.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { add } from 'date-fns';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { StepUserComponent } from './step-user/step-user.component';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../services/config.service';
import { EmailSuggestion, EmailValidators, Suggestion } from 'ngx-validators';
import { emailOptionsConfig } from 'app/config';

@Component({
    selector: 'onboarding-pro',
    templateUrl: './onboarding-pro.component.html',
    styleUrls: ['./onboarding-pro.component.scss'],
    animations: fuseAnimations
})
export class OnboardingProComponent implements OnInit, OnDestroy {
    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    @ViewChild('stepClinic', { static: true }) stepClinic: StepClinicComponent;
    @ViewChild('stepUser', { static: true }) stepUser: StepUserComponent;

    form: FormGroup;
    formLoading = false;
    emailSuggestion?: Suggestion;

    private emailSuggestionExecutor = new EmailSuggestion();
    private subscriptions: Subscription[] = [];
    private readonly cookieName = 'onboarding_form';

    constructor(
        private fuseConfigService: FuseConfigService,
        private cookie: CookieService,
        private onboardingService: OnboardingService,
        private translateService: TranslateService,
        private router: Router,
        private configService: ConfigService
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.form = new FormGroup({
            clinic: new FormGroup({
                name: new FormControl(null, [Validators.required]),
                address: new FormControl(null, [Validators.required]),
                zipCode: new FormControl(null, [Validators.required]),
                city: new FormControl(null, [Validators.required]),
                country: new FormControl(null, [Validators.required]),
                phone: new FormControl(null, [Validators.required]),
                groupement: new FormControl(null, []),
                vat: new FormControl(null, [])
            }),
            user: new FormGroup({
                civility: new FormControl(null, [Validators.required]),
                firstname: new FormControl(null, [Validators.required]),
                name: new FormControl(null, [Validators.required]),
                mail: new FormControl(null, [Validators.required, EmailValidators.normal]),
                phone: new FormControl(null, []),
                password: new FormControl(null, [Validators.required]),
                timezone: new FormControl(null, [Validators.required])
            })
        });
    }

    ngOnInit(): void {
        this.retrieveFormData();

        const formSubscription = this.form.valueChanges.subscribe(() => {
            this.saveFormData();
        });

        const timeZoneSubscription = this.configService.getTimezoneList().subscribe(timezones => {
            const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (timezones.includes(timezone)) {
                this.form.get('user').get('timezone').setValue(timezone);
            }
        });

        const emailSubscription = this.form.get('user.mail').valueChanges.subscribe(value => {
            this.emailSuggestion = this.emailSuggestionExecutor.suggest(value, emailOptionsConfig)?.suggestion;
        });

        this.subscriptions.push(formSubscription, timeZoneSubscription, emailSubscription);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    onBackHomeClicked(): void {
        void this.router.navigate(['/']);
    }

    onBackClicked(): void {
        if (this.stepper.selectedIndex === 0) {
            if (this.stepClinic.searchDone) {
                this.stepClinic.searchDone = false;
                this.stepClinic.searchFilterControl.setValue('');
                this.form.get('clinic').patchValue({
                    name: null,
                    address: null,
                    zipCode: null,
                    city: null,
                    country: null,
                    phone: null,
                    groupement: null
                });
                this.cookie.delete(this.cookieName);
            } else {
                void this.router.navigate(['/']);
            }
        } else {
            this.stepper.previous();
        }
    }

    onNextClicked(): void {
        if (this.stepper.selectedIndex === 0) {
            const vat = this.form.get('clinic').get('vat').value;
            if (vat) {
                this.checkVatNumber(vat);
            } else {
                this.stepper.next();
            }
        } else if (this.stepper.selectedIndex === 1) {
            this.sendForm();
        } else {
            this.stepper.next();
        }
    }

    goToStep(step: number): void {
        this.stepper.selectedIndex = step;
    }

    private checkVatNumber(vat: string) {
        const country = this.form.get('clinic').get('country').value;
        this.formLoading = true;
        this.onboardingService.checkVatNumber(vat, country).subscribe({
            next: res => {
                if (res) {
                    this.stepper.next();
                } else {
                    this.form.get('clinic').get('vat').setErrors({ invalidVatNumber: true });
                }

                this.formLoading = false;
            },
            error: () => {
                this.formLoading = false;
            }
        });
    }

    private retrieveFormData(): void {
        if (!this.cookie.check(this.cookieName)) {
            return;
        }

        const data = JSON.parse(this.cookie.get(this.cookieName));
        if (!data) {
            return;
        }

        setTimeout(() => {
            this.form.patchValue(data);
            this.stepClinic.searchDone = true;
            if (data.clinic.phone) {
                this.stepClinic.inputPhone.setNewValue(data.clinic.phone);
            }

            if (data.user.phone) {
                this.stepUser.inputPhone.setNewValue(data.user.phone);
            }

            this.stepUser.pwdLevel = null;
        });
    }

    private saveFormData(): void {
        const formData = this.form.getRawValue();
        formData.user.password = null;
        const formDataString = JSON.stringify(formData);
        this.cookie.set(this.cookieName, formDataString, add(Date.now(), { weeks: 1 }), null, null, environment.production, 'Lax');
    }

    private sendForm() {
        if (!this.form.valid) {
            return;
        }

        const formData = this.form.value as OnboardingFormValue;
        if (!formData) {
            return;
        }

        const locale = this.translateService.getBrowserCultureLang().replace('-', '_');

        const session: OnboardingSession = {
            entiteJuridique: {
                nom: formData.clinic.name,
                adresse: formData.clinic.address,
                codePostal: formData.clinic.zipCode,
                ville: formData.clinic.city,
                pays: formData.clinic.country,
                mail: formData.user.mail,
                telephone: formData.clinic.phone,
                groupement: formData.clinic.groupement,
                vat: formData.clinic.vat
            },
            veterinaire: {
                civilite: formData.user.civility,
                prenom: formData.user.firstname,
                nom: formData.user.name,
                mail: formData.user.mail,
                telephone: formData.user.phone,
                password: formData.user.password,
                locale: locale.slice(0, 3).toLowerCase() + locale.slice(3).toUpperCase(),
                timezone: formData.user.timezone
            }
        };
        this.formLoading = true;
        this.onboardingService.registerNewUser(session).subscribe({
            next: () => {
                this.stepper.next();
                this.cookie.delete(this.cookieName);
                this.formLoading = false;
            },
            error: () => {
                this.formLoading = false;
            }
        });
    }

    public get buttonContinueEnabled(): boolean {
        return this.stepper.selected?.completed ?? false;
    }
}

export interface OnboardingFormValue {
    clinic: {
        name: string;
        address: string;
        zipCode: string;
        city: string;
        country: string;
        phone: string;
        groupement: string;
        vat?: string;
    };
    user: {
        civility: string;
        firstname: string;
        name: string;
        mail: string;
        phone: string;
        password: string;
        timezone: string;
    };
}

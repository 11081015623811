<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">
    <mat-accordion class="my-16" [multi]="true">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>group</mat-icon>
                    <span>{{ 'SETTINGS.ACCOUNT_SELECTOR' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutAlign="end end">
                <mat-slide-toggle formControlName="accountSelector" fxFlex="0 0 0">
                    {{ 'SETTINGS.FORM.ACCOUNT_SELECTOR' | translate }}
                </mat-slide-toggle>
                <p [innerHTML]="'SETTINGS.FORM.ACCOUNT_SELECTOR_DESCRIPTION' | translate" fxFlex="1 1 auto"
                   class="description"></p>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
                <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
                    <mat-icon>save</mat-icon>
                    {{ 'FORM.SAVE' | translate }}
                </button>
                <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                             *ngIf="(formInProgress$ | async)"></mat-spinner>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>notifications</mat-icon>
                    <span>{{ 'SETTINGS.NOTIFICATIONS.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="start end">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <mat-icon [class.notifications-enabled]="isNotificationEnabled" [class.notifications-disabled]="!isNotificationEnabled">{{ isNotificationEnabled ? 'notifications_active' : 'notifications_off' }}</mat-icon>
                        <p class="notifications-{{ isNotificationEnabled ? 'enabled' : 'disabled' }}">
                            {{ (isNotificationEnabled ? 'SETTINGS.NOTIFICATIONS.ON' : 'SETTINGS.NOTIFICATIONS.OFF') | translate }}
                        </p>
                        <button type="button" mat-raised-button color="primary" [disabled]="isNotificationEnabled"
                                (click)="enableNotifications()">
                            {{ 'SETTINGS.NOTIFICATIONS.ENABLE_NOTIFICATIONS' | translate }}
                        </button>
                    </div>
                    <p [innerHTML]="'SETTINGS.NOTIFICATIONS.DESCRIPTION' | translate" class="description"></p>
                </div>
                <!--TODO one day recap fin de journée -->
                <!--                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" *ngIf="isDev">-->
                <!--                    <mat-checkbox formControlName="receiveEmailRecap">{{ 'SETTINGS.NOTIFICATIONS.EMAIL' | translate }}</mat-checkbox>-->
                <!--                    <mat-form-field appearance="outline">-->
                <!--                        <mat-label>{{ 'FORM.MAIL' | translate }}</mat-label>-->
                <!--                        <input matInput formControlName="receiveEmailRecapAddress"-->
                <!--                               type="email" required [placeholder]="'FORM.MAIL' | translate">-->
                <!--                        <mat-error *ngIf="form.get('receiveEmailRecapAddress').hasError('required')">-->
                <!--                            {{ 'FORM.MAIL_REQUIRED' | translate }}-->
                <!--                        </mat-error>-->
                <!--                        <mat-error *ngIf="form.get('receiveEmailRecapAddress').hasError('email')">-->
                <!--                            {{ 'FORM.MAIL_WRONG' | translate }}-->
                <!--                        </mat-error>-->
                <!--                    </mat-form-field>-->

                <!--                    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">-->
                <!--                        <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">-->
                <!--                            <mat-icon>save</mat-icon>-->
                <!--                            {{ 'FORM.SAVE' | translate }}-->
                <!--                        </button>-->
                <!--                        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"-->
                <!--                                     *ngIf="(formInProgress$ | async)"></mat-spinner>-->
                <!--                    </div>-->
                <!--                </div>-->
            </div>
        </mat-expansion-panel>

        <!-- TODO: -->
        <!-- <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>event</mat-icon>
                    <span>{{ 'SETTINGS.APPOINTMENT.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutAlign="end end">
                <p [innerHTML]="'SETTINGS.APPOINTMENT.DESCRIPTION' | translate" class="description"></p>
                <label id="appointment-label">{{ 'SETTINGS.APPOINTMENT.MODE' | translate }}</label>
                <mat-radio-group aria-labelledby="appointment-label" formControlName="appointmentType" fxLayout="column" fxLayoutGap="8px" [style.margin]="'1em 0'">
                    <mat-radio-button class="example-radio-button" *ngFor="let type of [0, 1, 2]" [value]="type">{{ ('SETTINGS.APPOINTMENT.TYPES.' + type) | translate }}</mat-radio-button>
                </mat-radio-group>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'SETTINGS.APPOINTMENT.TYPES.2' | translate }}</mat-label>
                    <input matInput formControlName="appointmentTypeValue" required>
                    <mat-error *ngIf="this.form.get('appointmentTypeValue').hasError('required')">{{ 'SETTINGS.APPOINTMENT.ERROR' | translate }}</mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel> -->

        <!--TODO one day prix rdv urgence -->
        <!--<mat-expansion-panel [expanded]="true">-->
        <!--<mat-expansion-panel-header>-->
        <!--<mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>update</mat-icon><span>{{ 'SETTINGS.RDV' | translate }}</span></mat-panel-title>-->
        <!--</mat-expansion-panel-header>-->
        <!--<div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="20px">-->
        <!--<mat-form-field appearance="outline" class="w-50-p">-->
        <!--<mat-label>{{ 'SETTINGS.FORM.EMERGENCY_APPOINTMENT_PRICE' | translate }}</mat-label>-->
        <!--<input [placeholder]="'SETTINGS.FORM.EMERGENCY_APPOINTMENT_PRICE' | translate" matInput-->
        <!--formControlName="emergencyAppointmentPrice" min="0" type="number" step="0.1" style="text-align: right;">-->
        <!--<span matSuffix>{{ currency | currencySymbol }}</span>-->
        <!--<mat-hint *ngIf="!paymentEnabled">{{ 'PAYMENTS.DISABLED_SPEECH' | translate }}</mat-hint>-->
        <!--</mat-form-field>-->
        <!--</div>-->
        <!--</mat-expansion-panel>-->
    </mat-accordion>
</form>

<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'VETO.VETOS' | translate }} {{ loading ? '- ' + ('SHARED.LOADING' | translate) : '' }}</mat-label>
    <mat-select [placeholder]="'VETO.VETO' | translate" [formControl]="control | asFormControl" [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="veterinairesFilter"
                [placeholderLabel]="'VETO.VETO_SEARCH' | translate"
                [noEntriesFoundLabel]="'VETO.VETO_ANY_FOUND' | translate"
                [searching]="loading">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="''" *ngIf="nullValue">{{ nullValue }}</mat-option>
        <mat-optgroup
                *ngFor="let entiteGeographique of filteredVeterinairesOptions$ | async | groupBy:'entiteGeographique.nom'"
                [label]="entiteGeographique.key">
            <mat-option *ngFor="let veterinaire of entiteGeographique.value" [value]="veterinaire.id">
                {{ veterinaire | userFullName }}
                <span *ngIf="showMailTel && veterinaire.mail">&nbsp;{{ veterinaire.mail }}</span>
                <span
                    *ngIf="showMailTel && veterinaire.telephonePortable">&nbsp;{{ veterinaire.telephonePortable | phone }}</span>
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
        {{ 'VETO.VETO_REQUIRED' | translate }}
    </mat-error>
</mat-form-field>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Observable, of } from 'rxjs';
import { ClientPostInterface } from '../../models/interfaces/post/utilisateurs/client-post.interface';
import { EntiteJuridiqueService } from './entite-juridique.service';
import { AnimalPostInterface } from '../../models/interfaces/post/animal/animal-post.interface';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AllydvmInitInterface } from '../../models/interfaces/post/pro/allydvm-init.interface';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { ObjectMapper } from 'json-object-mapper';

@Injectable()
export class AllyDvmService {
    constructor(
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private http: HttpClient,
        private config: ConfigService
    ) {}

    public setInfosForEntiteJuridique(data: AllydvmInitInterface): Observable<EntiteJuridique> {
        this.snackbar.open(this.translateService.instant('SETTINGS.UPDATING'), null);
        return this.http.post<EntiteJuridique>(`${this.config.baseUrl}api/allydvm/${this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id}`, data).pipe(
            map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
            map(r => {
                this.snackbar.open(this.translateService.instant('SETTINGS.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                return this.entiteJuridiqueService.updateEntiteJuridiqueForUtilisateurConnected(r);
            })
        );
    }

    private entiteJuridiqueHasAllyDvm<T>(req: Observable<T>, defaut: T): Observable<T> {
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.hasAllyDvm) {
            return req;
        }

        return of(defaut);
    }

    public searchClients(keywords: string = null,
                         offset: number = null,
                         limit = 20): Observable<ClientPostInterface[]> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        return this.entiteJuridiqueHasAllyDvm(
            this.http.get<ClientPostInterface[]>(`${this.config.baseUrl}api/allydvm/${this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id}/clients`, {
                params: params
            }), []);
    }

    public getAnimauxClient(clientId: string): Observable<AnimalPostInterface[]> {
        return this.entiteJuridiqueHasAllyDvm(
            this.http.get<AnimalPostInterface[]>(`${this.config.baseUrl}api/allydvm/${this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id}/client/${clientId}/animaux`).pipe(
                tap(datas => {
                    datas.forEach(data => {
                        data.dateNaissance = data.dateNaissance ? new Date((data.dateNaissance as number) * 1000) : null;
                        data.dateDeces = data.dateDeces ? new Date((data.dateDeces as number) * 1000) : null;
                    });
                })
            ), []);
    }
}

import { IdInterface } from './interfaces/id.interface';
import { JsonProperty } from 'json-object-mapper';

export class Entity implements IdInterface {
    @JsonProperty()
    id: number = null;

    public hasPost(): IdInterface {
        return {
            id: this.id
        };
    }
}

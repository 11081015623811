import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-actions-menu',
    templateUrl: './actions-menu.component.html',
    styleUrls: ['./actions-menu.component.scss']
})
export class ActionsMenuComponent {
    @Input() id: number;
    @Input() actions: ActionsMenuInterface[];
}

/**
 * Action affichée dans le menu sur le tile de profile
 *
 * @export
 * @interface ActionsMenuInterface
 */
export interface ActionsMenuInterface {
    /**
     * Titre de l'action
     *
     * @type {string}
     * @memberof ActionsMenuInterface
     */
    title: string;

    /**
     * Nom de l'icone de l'action (mat-icon)
     *
     * @type {string}
     * @memberof ActionsMenuInterface
     */
    icon: string;

    /**
     * Event au clique sur l'action
     *
     * @memberof ActionsMenuInterface
     */
    onClick: (event: Event, id?: number) => void;

    /**
     * Classe du bouton
     *
     * @type {string}
     * @memberof ActionsMenuInterface
     */
    class?: string;
}

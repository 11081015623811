<div class="page-layout simple fullwidth p-16">
    <div class="center centered-content">

        <div class="header secondary-text"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">storage</mat-icon>
                <h1 class="logo-text">{{ 'STORAGE.USED' | translate }}</h1>
            </div>
        </div>

        <div class="content-card">
            <mat-accordion class="my-16" *ngIf="!isLoading; else loading" [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>info</mat-icon>
                            <span>{{ 'STORAGE.GENERAL' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <div fxLayout="column" fxLayoutGap="5px">
                                <span class="filesize-big">{{ statsTotal?.totalUsed | fileSize }}</span>
                                <span class="filesize-description">Utilisé</span>
                            </div>
                            <div fxLayout="column" fxLayoutGap="5px">
                                <span class="filesize-big filesize-used">{{ statsTotal?.total | fileSize }}</span>
                                <span class="filesize-description ta-r">{{ 'STORAGE.TOTAL_SPACE' | translate }}</span>
                            </div>
                        </div>
                        <div class="filesize-chart">
                            <ngx-charts-bar-horizontal-stacked
                                [view]="[750, 70]"
                                [results]="statsByType"
                                [customColors]="customColors"
                                [xScaleMax]="statsTotal.total"
                                [legend]="true"
                                [legendPosition]="LegendPosition.Below"
                                [legendTitle]="null">
                                <ng-template #tooltipTemplate let-model="model">
                                    <p class="mb-0">{{ model.name | capitalize }}</p>
                                    <h4 class="mt-0 mb-8">{{ model.value | fileSize }}</h4>
                                </ng-template>
                            </ngx-charts-bar-horizontal-stacked>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>description</mat-icon>
                            <span>{{ 'STORAGE.FILE_TYPES' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div *ngFor="let type of statsByType[0].series" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <div class="filetype-image" *ngIf="type.extra?.icon">
                                <mat-icon>{{ type.extra?.icon }}</mat-icon>
                            </div>
                            <div class="filetype-content" fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="type.extra?.type">
                                <div class="filetype-name" fxLayout="column">
                                    <span class="filetype-type">{{ type.name }}</span>
                                    <span class="filetype-files" *ngIf="type.extra?.nbFiles > 0">{{ type.extra?.nbFiles }} {{ 'STORAGE.FILES' | translate}}</span>
                                </div>
                                <span class="filetype-size">{{ type.value | fileSize }}</span>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <mat-icon>pets</mat-icon>
                            <span>{{ 'STORAGE.ANIMALS' | translate }}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div>
                        <div class="file-animal" *ngFor="let stat of this.statsByAnimal; trackBy: trackByFn">
                            <app-profile-tile
                                class="cursor-pointer"
                                [title]="stat.animal.nom"
                                [matTooltip]="'FILES.SEE' | translate"
                                [entity]="stat.animal"
                                (click)="goToAnimalUsage(stat.animal.id)">
                                <span class="normal">
                                    {{ 'CLIENT.CLIENT' | translate }} : {{ stat.animal.proprietaire | userFullName }} &middot;
                                    {{ 'STORAGE.FILES' | translate | capitalize }} : {{ stat.usage | fileSize }}
                                </span>
                            </app-profile-tile>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <ng-template #loading>
            <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
            </div>
        </ng-template>
    </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { CompteComponent } from './compte.component';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'account',
        component: CompteComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'compte',
        redirectTo: '/account',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        CompteComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class CompteModule {}

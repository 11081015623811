import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { EntiteJuridiqueService } from './entite-juridique.service';
import { environment } from '../../../environments/environment';
import { Connecteur } from '../../models/interfaces/post/pro/connecteur.interface';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { Animal } from '../../models/animal/animal';
import { Client } from '../../models/utilisateurs/client';
import { StorageMap } from '@ngx-pwa/local-storage';
import { DataEntityLinkPossible, DataEntityLinkPossibleStorage } from '../../models/data-entity-link-possible';
import { PhonePipe } from '../../pipes/phone.pipe';
import { UtilisateurService } from './utilisateur.service';
import { Utils } from '../../utils';

@Injectable({
    providedIn: 'root'
})
export class ConnecteurService {
    private phonePipe: PhonePipe;

    private readonly STORAGE_CSV = 'csvDump';

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private storageMap: StorageMap, utilisateurService: UtilisateurService
    ) {
        this.phonePipe = new PhonePipe(utilisateurService);
    }

    public enableConnecteurForEntiteJuridique(entiteJuridiqueId: number, vetSoft: string): Observable<EntiteJuridique> {
        return this.http.post<EntiteJuridique>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/connect', { vetSoft });
    }

    public disableConnecteurForEntiteJuridique(entiteJuridiqueId: number): Observable<EntiteJuridique> {
        return this.http.delete<EntiteJuridique>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/connect');
    }

    public linkEntity(connectorId: string, entity: Animal | Client | Veterinaire): Observable<void> {
        let type: string;

        if (entity instanceof Animal) {
            type = 'animal';
        } else if (entity instanceof Client) {
            type = 'customer';
        } else if (entity instanceof Veterinaire) {
            type = 'veterinary';
        } else {
            throw new TypeError('Entity type not found');
        }

        return this.http.patch<void>(`${this.config.baseUrl}api/connect/${type}`, {
            connectorId: connectorId
        });
    }

    public isConnecteurFeatureEnabled(featureType: ('APPOINTMENT' | 'ANIMAL' | 'CLIENT' | 'VETERINARIAN' | 'FILES'), supporting: ('any' | 'push' | 'pull') = 'any'): Observable<boolean> {
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.pipe(map(e => e.vetSoft)) && environment.demo) {
            return of(true);
        }

        const features: string[] = [];

        switch (supporting) {
            case 'any':
                features.push('SYNC_' + featureType + '_PUSH', 'SYNC_' + featureType + '_PULL');
                break;
            default:
                features.push('SYNC_' + featureType + '_' + supporting.toUpperCase());
                break;
        }

        return this.config.getConnectors().pipe(
            withLatestFrom(this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.pipe(map(e => e.vetSoft))),
            map(([connecteurs, vetSoft]) => connecteurs?.find(c => c.id === vetSoft)),
            map(c => c?.features.some(f => features.includes(f.toString())))
        );
    }

    public get isConnecteurEnabledForUtilisateurConnected(): Observable<boolean> {
        return this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.pipe(map(e => e.vetSoft && e.vetSoft.length > 0));
    }

    public get isConnecteurEnabledForUtilisateurConnectedValue(): boolean {
        return this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.vetSoft &&
            this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.vetSoft.length > 0;
    }

    public get currentConnecteurForUtilisateurConnected(): Observable<Connecteur> {
        return combineLatest([
            this.config.getConnectors(),
            this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected
        ]).pipe(
            map(([connecteurs, entiteJuridique]) => {
                return entiteJuridique?.vetSoft?.length > 0 ? connecteurs?.find(c => c.id === entiteJuridique.vetSoft) : null;
            })
        );
    }

    public saveCsvDatas(datas: DataEntityLinkPossible[], type: string): Observable<DataEntityLinkPossibleStorage> {
        return this.storageMap.set(this.STORAGE_CSV + '_' + type, {
            datas: datas,
            date: new Date()
        } as DataEntityLinkPossibleStorage);
    }

    public searchInCsvDatas(type: string, fullSearch: string): Observable<DataEntityLinkPossibleStorage> {
        const toSearch = fullSearch.split(' ');

        return this.storageMap.get(this.STORAGE_CSV + '_' + type).pipe(
            map((d: DataEntityLinkPossibleStorage) => {
                if (d?.datas?.length > 0) {
                    d.datas = d.datas.map(dd => new DataEntityLinkPossible(dd));
                    d.datas = d.datas.filter(dd => toSearch.every(s => dd.concatenated.includes(s)));

                    return d;
                }

                return null;
            })
        );
    }

    public searchInCsvDatasById(type: string, id: string, idField: string): Observable<DataEntityLinkPossibleStorage> {
        return this.storageMap.get(this.STORAGE_CSV + '_' + type).pipe(
            map((d: DataEntityLinkPossibleStorage) => {
                if (d?.datas?.length > 0) {
                    d.datas = d.datas.map(dd => new DataEntityLinkPossible(dd));
                    d.datas = d.datas.filter(dd => dd[idField] === id);

                    return d;
                }

                return null;
            })
        );
    }

    public compareEntityWithCsvData(data: DataEntityLinkPossible, entity: Animal | Client | Veterinaire): number {
        if (entity.connectorId && data.id && entity.connectorId === data.id) {
            return 1;
        }

        if (entity instanceof Animal) {
            if (data.idClient && entity.proprietaire?.id?.toString() === data.idClient) {
                if (Utils.removeDiacritics(entity.nom.toLowerCase()) === Utils.removeDiacritics(data.nom?.toLowerCase())) {
                    return 0.95;
                }
            } else if (
                entity.proprietaire?.mail && data.mailClient && entity.proprietaire.mail.toLowerCase() === data.mailClient.toLowerCase() ||
                entity.proprietaire?.telephonePortable && data.telephonePortableClient && this.phonePipe.transform(entity.proprietaire.telephonePortable).toLowerCase().includes(data.telephonePortableClient.toLowerCase())
            ) {
                if (Utils.removeDiacritics(entity.nom.toLowerCase()) === Utils.removeDiacritics(data.nom?.toLowerCase())) {
                    return 0.9;
                }
            } else if (Utils.removeDiacritics(entity.proprietaire?.nom?.toLowerCase()) === Utils.removeDiacritics(data.nomClient?.toLowerCase())) {
                if (data.prenomClient && Utils.removeDiacritics(entity.proprietaire?.nom?.toLowerCase()) === Utils.removeDiacritics(data.prenomClient.toLowerCase()) && Utils.removeDiacritics(entity.nom.toLowerCase()) === Utils.removeDiacritics(data.nom?.toLowerCase())) {
                        return 0.75;
                    }

                if (Utils.removeDiacritics(entity.nom.toLowerCase()) === Utils.removeDiacritics(data.nom?.toLowerCase())) {
                    return 0.5;
                }
            }
        } else if (entity instanceof Client || entity instanceof Veterinaire) {
            if (
                data.mail && entity.mail && entity.mail.toLowerCase() === data.mail.toLowerCase() ||
                data.telephonePortable && entity.telephonePortable && this.phonePipe.transform(entity.telephonePortable.toLowerCase()).includes(data.telephonePortable.toLowerCase())
            ) {
                return 0.75;
            }

            if (Utils.removeDiacritics(entity.nom.toLowerCase()) === Utils.removeDiacritics(data.nom?.toLowerCase())) {
                if (data.prenom && Utils.removeDiacritics(entity.prenom?.toLowerCase()) === Utils.removeDiacritics(data.prenom.toLowerCase())) {
                    return 0.6;
                }

                return 0.5;
            }
        }

        return 0;
    }
}

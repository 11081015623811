import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigService } from '../../../../../services/config.service';
import { CurrencyInterface } from '../../../../../models/interfaces/currency.interface';

@Component({
    selector: 'app-input-currency',
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss']
})
export class InputCurrencyComponent implements OnInit, OnChanges, OnDestroy {
    @Input() control: AbstractControl;
    @Input() country: string;
    @Input() required: boolean;

    currencyFilter = new FormControl();
    currencyList: CurrencyInterface[] = [];
    filteredCurrencyOptions$: BehaviorSubject<CurrencyInterface[]> = new BehaviorSubject<CurrencyInterface[]>(null);

    private subscriptionCurrency: Subscription;

    constructor(private configService: ConfigService) {}

    ngOnInit(): void {
        this.currencyFilter.valueChanges
            .subscribe(() => {
                const filteredPaysOptions = this.currencyFilter.value ? this.currencyList.filter(currency => (currency.name + currency.code + currency.symbolNative).toLowerCase().includes(this.currencyFilter.value.toLowerCase())) : this.currencyList;

                console.log(this.currencyFilter.value, filteredPaysOptions, this.currencyList);

                this.filteredCurrencyOptions$.next(filteredPaysOptions);
            });
    }

    ngOnChanges(): void {
        if (this.subscriptionCurrency) {
            this.subscriptionCurrency.unsubscribe();
        }

        this.subscriptionCurrency = this.configService.getCurrencyList().subscribe(currenciesList => {
            this.currencyList = currenciesList;
            this.filteredCurrencyOptions$.next(currenciesList);
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionCurrency) {
            this.subscriptionCurrency.unsubscribe();
        }
    }
}

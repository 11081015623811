import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'app/services/config.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'priceAfterTaxe'
})
export class PriceAfterTaxe implements PipeTransform {
    private regex = /^-?\\d+(?:.\\d{0,2})?/;

    constructor(private configService: ConfigService) {}

    transform(value: number, onlyTax = false): Observable<number> {
        if (Number.isNaN(Number(value))) {
            return of(0);
        }

        return this.configService.getPaymentConfig().pipe(
            map(config => Number(value) * config.percentage + config.commission),
            map(frais => Number(this.regex.exec(frais.toString())[0])),
            map(frais => onlyTax ? frais : Number(value) - frais),
            map(val => val < 0 ? 0 : val)
        );
    }
}

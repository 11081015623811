import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { ListRendezVous, RendezVous } from '../models/rendez-vous/rendez-vous';
import { RendezVousService } from '../services/api/rendez-vous.service';
import { StripeService } from '../services/api/stripe.service';
import { Payout } from '../models/stripe/payout';

export class RendezVousDataSource implements DataSource<RendezVous> {
    private _rendezVous = new BehaviorSubject<RendezVous[]>([]);
    private _rendezVousOriginal = new BehaviorSubject<RendezVous[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(private rendezVousService: RendezVousService, private stripeService: StripeService) {}

    loadRendezVousByMonth(
        filter: string,
        sortDirection: string,
        sortField: string,
        pageIndex: number,
        pageSize: number,
        startDate: Date,
        endDate: Date,
        onlyNext = false,
        who: 'mine' | 'all' = 'all',
        payingOnly = false
    ): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.rendezVousService.getAll(
            filter,
            sortDirection,
            sortField,
            pageIndex,
            pageSize,
            startDate,
            endDate,
            onlyNext,
            who,
            payingOnly
        ).pipe(
            catchError(() => of([])),
            finalize(() => this._loadingSubject.next(false))
        ).subscribe((rendezVous: ListRendezVous) => {
            if (rendezVous.data && rendezVous.meta) {
                this._rendezVous.next(rendezVous.data);
                this._rendezVousOriginal.next(rendezVous.data);
                this._totalCount.next(rendezVous.meta.totalItems);
            }
        });
    }

    loadRendezVousByPayout(filter: string, payout: Payout): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._rendezVous.next(payout.rendezVous.filter(r => r.search(filter)));
        this._rendezVousOriginal.next(payout.rendezVous);
        this._totalCount.next(payout.rendezVous.length);
    }

    connect(): Observable<RendezVous[]> {
        return this._rendezVous.asObservable();
    }

    disconnect(): void {
        this._rendezVous.complete();
        this._rendezVousOriginal.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }

    offlineSort(sortDirection: string, sortField: string, filter: string): void {
        const rendezVous = this._rendezVousOriginal.getValue()
            .filter(r => r.search(filter))
            .sort((a: RendezVous, b: RendezVous) => {
                return this.getSortedBetweenDatas(sortDirection, sortField, a, b);
            });
        this._rendezVous.next(rendezVous);
        this._totalCount.next(rendezVous.length);
    }

    private getSortedBetweenDatas(sortDirection: string, sortField: string, a: RendezVous, b: RendezVous): number {
        let propertyA: number | string | boolean = '';
        let propertyB: number | string | boolean = '';

        switch (sortField) {
            case 'price':
            case 'priceTotal':
                [propertyA, propertyB] = [a.finalPrice, b.finalPrice];
                break;

            case 'capture':
                [propertyA, propertyB] = [a.payed ? a.prix : 0, b.payed ? b.prix : 0];
                break;

            case 'date':
                [propertyA, propertyB] = [a.date.getTime(), b.date.getTime()];
                break;

            case 'payed':
                [propertyA, propertyB] = [a.payed.getTime(), b.payed.getTime()];
                break;

            case 'veterinaire':
                [propertyA, propertyB] = [a.veterinaire.fullName, b.veterinaire.fullName];
                break;

            case 'client':
                [propertyA, propertyB] = [a.client.fullName, b.client.fullName];
                break;

            case 'animal':
                [propertyA, propertyB] = [a.animal.nom, b.animal.nom];
                break;

            case 'nature':
                [propertyA, propertyB] = [`${a.id}${a.nature}`, `${b.id}${b.nature}`];
                break;

            case 'available':
                [propertyA, propertyB] = [
                    a.paymentAvailableOn ? a.paymentAvailableOn.getTime() : 0,
                    b.paymentAvailableOn ? b.paymentAvailableOn.getTime() : 0
                ];
                break;

            default:
                [propertyA, propertyB] = [a[sortField], b[sortField]];
                break;
        }

        const valueA = Number.isNaN(Number(propertyA)) ? propertyA : Number(propertyA);
        const valueB = Number.isNaN(Number(propertyB)) ? propertyB : Number(propertyB);

        return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
    }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { EntiteJuridiqueComponent } from './entite-juridique.component';
import { SharedModule } from '../../../shared.module';
import { AdminGuard } from '../../../guards/admin.guard';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'legal-information',
        component: EntiteJuridiqueComponent,
        canActivate: [AdminGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'infos-juridique',
        redirectTo: '/legal-information',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        EntiteJuridiqueComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class EntiteJuridiqueModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared.module';
import { RouterModule } from '@angular/router';
import { DeactivatedAccountComponent } from './deactivated-account.component';
import { NoAuthGuard } from '../../guards/no-auth.guard';

const routes = [
    {
        path: 'deactivated-account',
        component: DeactivatedAccountComponent,
        canActivate: [NoAuthGuard]
    }
];

@NgModule({
  declarations: [DeactivatedAccountComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule
  ]
})
export class DeactivatedAccountModule {}

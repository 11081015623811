import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Params, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/api/auth.service';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { Observable, of } from 'rxjs';
import { ChatService } from '../services/api/chat.service';
import { EntiteGeographiqueService } from '../services/api/entite-geographique.service';
import { ConfigService } from '../services/config.service';
import { Veterinaire } from 'app/models/utilisateurs/veterinaire';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })

export class AuthGuard implements CanActivate {
    protected withChat: boolean;
    protected isAccountDisabled: boolean;

    constructor(
        protected router: Router,
        protected authService: AuthService,
        protected utilisateurService: UtilisateurService,
        protected chatService: ChatService,
        protected entiteGeographiqueService: EntiteGeographiqueService,
        protected configService: ConfigService,
        protected translateService: TranslateService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        let req: Observable<boolean>;

        this.authService.saveTokenFromRouteIfPossible(state.root);

        if (this.authService.isAuthenticated || this.authService.canRefresh) {
            req = this.utilisateurService.getMe().pipe(
                catchError(() => of(false)),
                map(result => {
                    if (!result) {
                        return false;
                    }

                    const veterinaire = result as Veterinaire;

                    if (!veterinaire.entiteJuridique) {
                        return false;
                    }

                    this.isAccountDisabled = veterinaire.entiteJuridique.isDisabled;

                    if (this.isAccountDisabled) {
                        if (veterinaire.entiteJuridique?.cancelSubscriptionDate ? new Date() > veterinaire.entiteJuridique?.cancelSubscriptionDate : false) {
                            const allowedRoutes = [
                                '/bank-information',
                                '/subscription/invoices',
                                '/payments'
                            ];
                            if (allowedRoutes.some(url => state.url.startsWith(url))) {
                                return true;
                            }

                            setTimeout(() => {
                                void this.router.navigate(['/deactivated-account']);
                            });
                        } else {
                            const token = this.authService.token;
                            this.authService.logout(false, false);

                            setTimeout(() => {
                                void this.router.navigate(['/onboarding-pro-plans'], { queryParams: { access_token: token } });
                            });
                        }

                        return false;
                    }

                    return true;
                })
            );

            if (this.withChat) { // obligation de connexion
                req = req.pipe(
                    switchMap(result => {
                        if (result) {
                            return this.chatService.connectAndGetChannel().pipe(catchError(() => of(true))) as Observable<boolean>;
                        }

                        return of(false);
                    })
                );
            }

            req = req.pipe(
                tap(result => {
                    const user = this.utilisateurService.utilisateurConnectedValue;
                    if (user && (user.cguMustConfirm || user.passMustChange)) {
                        this.openDialogForceUpdate();
                    }

                    if (user && result && !this.withChat && !this.isAccountDisabled) { // connexion en arrière plan
                        this.chatService.connectAndGetChannel().subscribe();
                    }
                })
            );
        } else {
            req = of(false);
        }

        return req.pipe(
            tap(result => {
                if (!result && !this.isAccountDisabled) {
                    if (this.authService.isAuthenticated || this.authService.canRefresh) {
                        this.authService.logout();
                    } else {
                        let queryParams: Params = null;
                        if (!['/', '/dashboard'].includes(state.url)) {
                            queryParams = { returnUrl: state.url };
                        }

                        void this.router.navigate(['/login'], { queryParams });
                    }
                }
            })
        );
    }

    protected openDialogForceUpdate(): void {
        this.utilisateurService
            .openDialogForceUpdate(this.utilisateurService.utilisateurConnectedValue)
            .afterClosed()
            .subscribe();
    }
}

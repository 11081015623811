<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'CLIENT.CLIENTS' | translate }} {{ loading ? '- ' + ('SHARED.LOADING' | translate) : '' }}</mat-label>
    <mat-select [placeholder]="'CLIENT.CLIENT' | translate" [formControl]="control | asFormControl" [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="clientsFilter"
                [placeholderLabel]="'CLIENT.CLIENT_SEARCH' | translate"
                [noEntriesFoundLabel]="'CLIENT.CLIENT_ANY_FOUND' | translate"
                [searching]="loading">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="''" *ngIf="nullValue">{{ nullValue }}</mat-option>
        <mat-optgroup
            *ngFor="let entiteGeographique of filteredClientsOptions$ | async | groupBy:'entiteGeographique.nom'"
            [label]="entiteGeographique.key">
            <mat-option *ngFor="let client of entiteGeographique.value" [value]="client.id">
                {{ client | userFullName }}
                <span *ngIf="showMailTel && client.mail">&nbsp;{{ client.mail }}</span>
                <span
                    *ngIf="showMailTel && client.telephonePortable">&nbsp;{{ client.telephonePortable | phone }}</span>
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
        {{ 'CLIENT.CLIENT_REQUIRED' | translate }}
    </mat-error>
</mat-form-field>

import { WebsocketMessage } from './websocket-message';
import { JsonProperty } from 'json-object-mapper';
import { RendezVous } from '../rendez-vous/rendez-vous';

export class RdvTestVisioData {
    @JsonProperty({ type: RendezVous })
    rendezVous: RendezVous = null;

    @JsonProperty()
    result: 0 | 1 | 2 | 3 = null;
}

export class RdvTestVisio extends WebsocketMessage<RdvTestVisioData> {
    @JsonProperty()
    type = 'rdv.test-visio';

    @JsonProperty({ type: RdvTestVisioData })
    data: RdvTestVisioData;
}

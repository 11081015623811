import { Component, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { Connecteur } from 'app/models/interfaces/post/pro/connecteur.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FichierService } from 'app/services/api/fichier.service';
import { Utils } from '../../../../utils';

@Component({
    selector: 'app-connector-form',
    templateUrl: './connector-form.component.html',
    styleUrls: ['./connector-form.component.scss'],
    animations: fuseAnimations
})
export class ConnectorFormComponent {
    @Input('connector') connector: Connecteur;
    @Input('apiKey') apiKey: string;
    @Output('refreshKey') refreshKeyEvent = new EventEmitter<void>();

    disabled = false;

    constructor(
        private readonly snackbar: MatSnackBar,
        private readonly translateService: TranslateService,
        private readonly fichierService: FichierService
    ) {}

    ngOnInit(): void {
        if (!this.apiKey) {
            this.apiKey = '***************************';
            this.disabled = true;
        }
    }

    copyApiKey(): void {
        navigator.clipboard.writeText(this.apiKey)
            .then(() => {
                this.snackbar.open(this.translateService.instant('CONNECTOR.FORM.API_KEY.COPIED'), null, { duration: 2000 });
            }).catch(error => {
                console.error(error);
                this.snackbar.open(this.translateService.instant('CONNECTOR.FORM.API_KEY.NOT_COPIED'), null, { duration: 2000 });
            });
    }

    regenerateKey(): void {
        this.refreshKeyEvent.emit();
        this.disabled = false;
    }

    goToFile(): void {
        this.fichierService.openFileInModal('.screenshot');
    }

    goToLink(): void {
        window.open(this.connector.installation.link, '_blank');
    }

    isImage(link: string): boolean {
        return Utils.isImage(link);
    }
}

import { Payout } from '../../../../models/stripe/payout';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { StripeService } from '../../../../services/api/stripe.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';

export class PayoutDataSource implements DataSource<Payout> {
    private _payouts = new BehaviorSubject<Payout[]>([]);
    private _payoutsOriginal = new BehaviorSubject<Payout[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(
        private stripeService: StripeService
    ) {}

    loadAll(entiteJuridique: EntiteJuridique): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.stripeService.getPayouts(entiteJuridique.id).pipe(
            catchError(() => of([])),
            finalize(() => this._loadingSubject.next(false))
        ).subscribe((payouts: Payout[]) => {
            if (payouts) {
                this._payouts.next(payouts);
                this._payoutsOriginal.next(payouts);
                this._totalCount.next(payouts.length);
            }
        });
    }

    connect(): Observable<Payout[]> {
        return this._payouts.asObservable();
    }

    disconnect(): void {
        this._payouts.complete();
        this._payoutsOriginal.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }

    offlineSort(sortDirection: string, sortField: string, _filter: string): void {
        const rendezVous = this._payouts.getValue()
            // .filter(r => (r.nature + r.categorie.value + r.animal.nom + r.client.fullNameLite + r.veterinaire.fullNameLite).toLowerCase().indexOf(filter.toLowerCase()) !== -1)
            .sort((a: Payout, b: Payout) => {
                return this.getSortedBetweenDatas(sortDirection, sortField, a, b);
            });
        this._payouts.next(rendezVous);
        this._totalCount.next(rendezVous.length);
    }

    private getSortedBetweenDatas(sortDirection: string, sortField: string, a: Payout, b: Payout): number {
        let propertyA: number | string | boolean = '';
        let propertyB: number | string | boolean = '';

        switch (sortField) {
            case 'ammount':
                [propertyA, propertyB] = [a.amount, b.amount];
                break;

            case 'created':
                [propertyA, propertyB] = [a.created.getTime(), b.created.getTime()];
                break;

            case 'arrival_date':
                [propertyA, propertyB] = [a.arrival_date.getTime(), b.arrival_date.getTime()];
                break;

            default:
                [propertyA, propertyB] = [a[sortField], b[sortField]];
                break;
        }

        const valueA = Number.isNaN(Number(propertyA)) ? propertyA : Number(propertyA);
        const valueB = Number.isNaN(Number(propertyB)) ? propertyB : Number(propertyB);

        return (valueA < valueB ? -1 : 1) * (sortDirection === 'asc' ? 1 : -1);
    }
}

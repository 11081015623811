<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <button mat-stroked-button class="back-button" (click)="redirect()">
            <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK_HOME' | translate }}
        </button>
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg">
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="1 1 auto">
        <mat-card>
            <mat-card-content>
                <div fxLayout="column">
                    <div class="step-header">
                        <img class="icon" src=" ../../../../assets/images/icons/onboarding-icon-clinic.svg" alt="Mail Icon" fxFlex="0 0"/>
                        <h1 [innerHTML]="'ONBOARDING.TITLE' | translate"></h1>
                        <p [innerHTML]="'ONBOARDING.SUBTITLE' | translate"></p>
                    </div>

                    <mat-grid-list cols="2" rowHeight="1:1" gutterSize="32">
                        <mat-grid-tile matRipple [routerLink]="'/onboarding-pro'">
                            <div fxLayout="column" fxLayoutAlign="center center" class="w-100-p h-100-p p-8">
                                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 1 60%">
                                    <img class="characters" src=" ../../../../assets/images/veto.png" alt="Veto"/>
                                </div>
                                <p [innerHTML]="'ONBOARDING.PRO' | translate" class="choices"></p>
                            </div>
                        </mat-grid-tile>
                        <mat-grid-tile matRipple [routerLink]="'/onboarding-client'">
                            <div fxLayout="column" fxLayoutAlign="center center" class="w-100-p h-100-p p-8">
                                <div fxLayout="row" fxLayoutAlign="center center" fxFlex="0 1 60%">
                                    <img class="characters" src=" ../../../../assets/images/client.png" alt="Veto"/>
                                </div>
                                <p [innerHTML]="'ONBOARDING.CLIENT' | translate" class="choices"></p>
                            </div>
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

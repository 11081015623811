<!-- SIDENAV HEADER -->
<div class="sidenav-header">
    <!-- CHATS TOOLBAR -->
    <mat-toolbar>
        <!-- TOOLBAR BOTTOM -->
        <mat-toolbar-row>
            <!-- SEARCH -->
            <div class="search-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                <div class="search-input-wrapper" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <label for="search" class="mr-8">
                        <mat-icon class="secondary-text">search</mat-icon>
                    </label>
                    <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                        <input id="search" matInput #filter [placeholder]="'CHAT.SEARCH' | translate">
                    </mat-form-field>
                </div>

                <button color="primary" mat-icon-button (click)="addChat()" [disabled]="isRefreshing"
                        [matTooltip]="'CHAT.ADD' | translate">
                    <mat-icon>add</mat-icon>
                </button>

                <!-- <button color="primary" mat-icon-button (click)="refresh()" [disabled]="isRefreshing"
                        [matTooltip]="'SHARED.REFRESH' | translate">
                    <mat-icon>refresh</mat-icon>
                </button> -->

                <button color="primary" mat-icon-button [matMenuTriggerFor]="menuFilter"
                    [matTooltip]="'CHAT_FILTER.TITLE' | translate" matBadge="1" matBadgePosition="after" matBadgeColor="accent" [matBadgeHidden]="!hasFilter" class="badged">
                    <mat-icon>filter_alt</mat-icon>
                </button>

                <mat-menu #menuFilter="matMenu">
                    <app-chat-list-menu-filter (filterChanged)="onChatFilterChanged($event)" (hasFilter)="hasFilter = $event"></app-chat-list-menu-filter>
                </mat-menu>
            </div>
            <!-- / SEARCH -->
        </mat-toolbar-row>
        <!-- / TOOLBAR BOTTOM -->
    </mat-toolbar>
    <!--/ CHATS TOOLBAR -->
</div>
<!-- / SIDENAV HEADER -->

<!-- SIDENAV CONTENT -->
<div class="sidenav-content">
    <ng-container [ngTemplateOutlet]="chatList"
                    [ngTemplateOutletContext]="{chats:chatsRefined}">
    </ng-container>

    <div *ngIf="chats.length == 0 || chatsRefined.length == 0" class="chatlist-empty-state" fxLayout="column" fxLayoutAlign="center center">
        <img src="/assets/images/empty-state-chat.svg" class="mb-8"/>
        <p [innerHTML]="(chats.length == 0 ? 'CHAT.NO_CHAT_WITHOUT_FILTER' : 'CHAT.NO_CHAT_WITH_FILTER') | translate" style="text-align:center; margin:0 30px"></p>
    </div>
    <!-- / CHATS CONTENT -->
</div>
<!-- / SIDENAV CONTENT -->

<ng-template #chatList let-chats="chats">
    <div class="chat-list h-100-p" fxLayout="column">
        <cdk-virtual-scroll-viewport [minBufferPx]="750" [maxBufferPx]="1800" [itemSize]="150">
            <div *cdkVirtualFor="let chat of chats; let animalIndex = index">
                <div [ngSwitch]="chat.type">
                    <div *ngSwitchCase="'unread'" class="categorie unread-header" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon>markunread_mailbox</mat-icon>&nbsp;{{ 'CHAT.UNREAD' | translate }}&nbsp;
                        <span *ngIf="chatsRefined">({{ getChatNbrByGroup(chatGroup.UNREAD)}})</span>
                    </div>

                    <div *ngSwitchCase="'pinned'" class="categorie pinned-header" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon>push_pin</mat-icon>&nbsp;{{ 'CHAT.PINNED' | translate }}&nbsp;
                        <span *ngIf="chatsRefined">({{ getChatNbrByGroup(chatGroup.PINNED)}})</span>
                    </div>

                    <div *ngSwitchCase="'category'" class="categorie">
                        {{ chat.title }}&nbsp;<span *ngIf="chatsRefined">({{ getChatNbrByGroup(chatGroup.CATEGORY)}})</span>
                    </div>

                    <div *ngSwitchCase="'futur'" class="categorie secondary-500-bg fuse-white-fg" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon>access_time</mat-icon>&nbsp;{{ 'CHAT.FUTURE' | translate }}&nbsp;
                        <span *ngIf="chatsRefined">({{ getChatNbrByGroup(chatGroup.FUTUR)}})</span>
                    </div>

                    <div *ngSwitchCase="'close'" class="categorie secondary-500-bg fuse-white-fg" fxLayout="row"
                         fxLayoutAlign="start center">
                        <mat-icon>lock</mat-icon>&nbsp;{{ 'CHAT.CLOSED_PLURAL' | translate }}&nbsp;
                        <span *ngIf="chatsRefined">({{ getChatNbrByGroup(chatGroup.CLOSE)}})</span>
                    </div>

                    <div *ngSwitchCase="'chat'" class="contact"
                         [class.unread-tiles]="chat.group == 'unread'"
                         [class.pinned-tiles]="chat.group == 'pinned'">
                        <app-chat-list-tile [chat]="chat.chat" [animal]="chat.chat.animal"
                                            [id]="'chat' + chat.chat.id"
                                            [client]="chat.chat.animal?.proprietaire"
                                            [active]="chatSelected?.id == chat.chat.id"
                                            [animalIndex]="animalIndex"
                                            [isMine]="chat.chat.isMine"
                                            (click)="selectConversation(chat.chat.id)"></app-chat-list-tile>
                    </div>
                </div>
            </div>
        </cdk-virtual-scroll-viewport>
    </div>
</ng-template>

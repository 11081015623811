<div class="navbar-header secondary-500-bg">

    <button mat-icon-button class="toggle-sidebar-folded"
            (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened"
            (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
    </button>

    <div class="logo cursor-pointer" [routerLink]="['/']" [matTooltip]="'NAV.HOME' | translate">
        <img class="logo-icon" src="/assets/images/logos/logo-horizontal.svg" alt="Univet">
    </div>

</div>

<div class="navbar-content mat-white-bg">
    <fuse-navigation></fuse-navigation>
    <div [class.hidden]="folded" class="version" [matTooltip]="apiVersion$ | async">v{{ version }}
        Build&nbsp;{{ buildVersion }}</div>
</div>

<h3>{{ 'CONNECTOR.FORM.INSTALLATION' | translate }}</h3>
<p>{{ 'CONNECTOR.FORM.DESCRIPTION.TITLE' | translate:{connecteur: (connector.name | titlecase)} }}</p>
<p>{{ 'CONNECTOR.FORM.DESCRIPTION.TEXT' | translate }}</p>
<mat-form-field appearance="outline">
    <mat-label>{{ 'CONNECTOR.FORM.API_KEY.TEXT' | translate }}</mat-label>
    <input matInput readonly [value]="apiKey" [disabled]="disabled">
    <button mat-raised-button color="accent" matSuffix (click)="disabled ? regenerateKey() : copyApiKey()" class="copy-button">
        {{ (disabled ? 'CONNECTOR.FORM.API_KEY.REGENERATE' : 'CONNECTOR.FORM.API_KEY.COPY') | translate }}
    </button>
</mat-form-field>
<div *ngIf="connector.installation">
    <p>{{ connector.installation.instruction }}</p>
    <div *ngIf="connector.installation.link">
        <img *ngIf="isImage(connector.installation.link); else link" [src]="connector.installation.link"
             class="screenshot"
             (click)="goToFile()">
        <ng-template #link>
            <button mat-raised-button color="primary"
                    (click)="goToLink()">{{ 'CONNECTOR.FORM.FILE' | translate }}</button>
        </ng-template>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
    selector: 'app-todos',
    templateUrl: './todos.component.html',
    styleUrls: ['./todos.component.scss']
})
export class TodosComponent {
    @Input() selectedIndex = 0;

    constructor(private route: ActivatedRoute, private router: Router) {
        this.route.fragment.subscribe(fragment => {
            if (fragment === 'mine') {
                this.selectedIndex = 0;
            } else if (fragment === 'all') {
                this.selectedIndex = 1;
            } else {
                void this.router.navigate(['/todos'], { fragment: 'mine' });
            }
        });
    }

    onSelectedTabChanged(event: MatTabChangeEvent): void {
        if (event.index === 0) {
            void this.router.navigate(['/todos'], { fragment: 'mine' });
        } else if (event.index === 1) {
            void this.router.navigate(['/todos'], { fragment: 'all' });
        }
    }
}

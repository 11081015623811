<div fxLayout="column" fxLayoutAlign="center center">
    <mat-form-field appearance="outline" fxFlex="1 1 100%" class="w-100-p">
        <mat-label>{{ 'SETTINGS.MESSAGES.SHORTCUTS.CONTENT' | translate }}</mat-label>
        <textarea matInput required [formControl]="control" #shortcutContentInput rows="5"></textarea>
        <mat-error *ngIf="control.hasError('required')">
            {{ 'SETTINGS.MESSAGES.SHORTCUTS.REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <p [innerHTML]="'SETTINGS.MESSAGES.SHORTCUTS.DESCRIPTION' | translate"></p>
    <div fxLayout="row wrap" fxLayoutGap="10px">
        <div *ngFor="let shortcutGroup of shortcutGroups">
            <button type="button" mat-raised-button [matMenuTriggerFor]="menu">{{ shortcutGroup.name | translate }}
                <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button type="button" mat-menu-item *ngFor="let shortcut of shortcutGroup.shortcuts"
                        (click)="insert(shortcut.key)">{{ shortcut.name | translate }}</button>
            </mat-menu>
        </div>
    </div>
</div>

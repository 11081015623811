<div fxFlex="row" fxLayoutAlign="center start" fxLayoutGap="8px" class="m-16">
    <mat-chip-list *ngIf="signalStrength">
        <mat-chip color="accent" selected>
            <mat-icon class="small mr-4">signal_cellular_4_bar</mat-icon>
            {{ getTestResult(signalStrength) }}
        </mat-chip>
    </mat-chip-list>
    <mat-chip-list>
        <mat-chip [style.background-color]="isOvertime() ? 'red' : ''" color="accent" selected class="text-bold">
            <mat-icon *ngIf="isOvertime()" class="small mr-4">warning</mat-icon>
            {{ time | async }}
        </mat-chip>
    </mat-chip-list>
    <mat-chip-list *ngIf="(visioService.currentStreams | async)?.length > 0 && (visioService.currentStreams | async)?.length < 2">
        <mat-chip [style.background-color]="batteryLevel < 15 ? 'red' : ''" color="accent" selected>
            <mat-icon class="small mr-2">{{ batteryIcon() }}</mat-icon>
            {{batteryLevel ? (batteryLevel | numberLocale:'2.0-0') + '%' : ''}}
        </mat-chip>
    </mat-chip-list>
</div>

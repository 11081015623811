import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { TemplateService } from '../../../../../services/api/template.service';
import { Template } from '../../../../../models/rendez-vous/template';
import { DocumentStatus } from '../../../../../models/rendez-vous/text-document';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../../../../services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-template-editor',
    templateUrl: './template-editor.component.html',
    styleUrls: ['./template-editor.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
    @Input()
    templates: Template [];

    @Output()
    deleteTemplate = new EventEmitter<number>();

    @Output()
    saveTemplate = new EventEmitter<Template>();

    selectedTemplate: Template;
    appliedTemplate: Template;

    templateStatus = DocumentStatus;

    isTemplateHasBeenChosen: boolean;

    tinyMCEInit: any;

    private templateListUpdateNotifierSubscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private templateService: TemplateService) {}

    ngOnInit(): void {
        this.selectedTemplate = this.templates[0];

        this.templateListUpdateNotifierSubscription = this.templateService.templateListUpdateNotifier.subscribe(template => {
            this.selectedTemplate = template;
        });

        this.isTemplateHasBeenChosen = false;

        this.tinyMCEInit = {
            height: 700,
            menubar: false,
            branding: false,
            elementpath: false,
            resize: false,
            plugins: [
                'advlist autolink lists link image charmap print',
                'preview anchor searchreplace visualblocks code',
                'fullscreen insertdatetime media table paste'
            ],
            toolbar:
                'myShortCodeSelector| undo redo | copy cut paste | formatselect | bold italic underline | fontselect fontsizeselect forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link table charmap',
            toolbar_mode: 'sliding',
            language: 'fr_FR',
            language_url: '../../../../../assets/i18n/tinyMCE/fr_FR.js',
            content_css: '../../../../../assets/report_styles/default-template-style.css',
            mobile: {
                toolbar_mode: 'sliding'
            },

            // see how to add custom buttons in TinyMCE editor at https://www.tiny.cloud/docs/demo/custom-toolbar-menu-button/
            // TODO: i18n
            setup: editor => {
                editor.ui.registry.addMenuButton('myShortCodeSelector', {
                    text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_FIELDS'),
                    fetch: callback => {
                        const items = [
                            {
                                type: 'nestedmenuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_ANIMAL'),
                                getSubmenuItems: () => {
                                    return [
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_AGE'),
                                            onAction: () => {
                                                editor.insertContent('[[animal.age]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_SPECIES'),
                                            onAction: () => {
                                                editor.insertContent('[[animal.species]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_NAME'),
                                            onAction: () => {
                                                editor.insertContent('[[animal.name]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_BREED'),
                                            onAction: () => {
                                                editor.insertContent('[[animal.breed]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_SEX'),
                                            onAction: () => {
                                                editor.insertContent('[[animal.sex]]');
                                            }
                                        }

                                    ];
                                }
                            },
                            {
                                type: 'nestedmenuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_OWNER'),
                                getSubmenuItems: () => {
                                    return [
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_OWNER_FULLNAME'),
                                            onAction: () => {
                                                editor.insertContent('[[client]]');
                                            }
                                        }
                                    ];
                                }
                            },
                            {
                                type: 'nestedmenuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_SURGERY'),
                                getSubmenuItems: () => {
                                    return [
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_ADDRESS'),
                                            onAction: () => {
                                                editor.insertContent('[[site.address]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_POST_CODE_CITY'),
                                            onAction: () => {
                                                editor.insertContent('[[site.cp.city]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_EMAIL'),
                                            onAction: () => {
                                                editor.insertContent('[[site.mail]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_SURGERY_NAME'),
                                            onAction: () => {
                                                editor.insertContent('[[site.name]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_SIRET'),
                                            onAction: () => {
                                                editor.insertContent('[[site.siret]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_PHONE'),
                                            onAction: () => {
                                                editor.insertContent('[[site.phone]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_VETS'),
                                            onAction: () => {
                                                editor.insertContent('[[vets]]');
                                            }
                                        }
                                    ];
                                }
                            },
                            {
                                type: 'nestedmenuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_CONSULTATION'),
                                getSubmenuItems: () => {
                                    return [
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_ADVICE'),
                                            onAction: () => {
                                                editor.insertContent('[[advice]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_DATE'),
                                            onAction: () => {
                                                editor.insertContent('[[date]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_DURATION'),
                                            onAction: () => {
                                                editor.insertContent('[[duration]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_EXAMINATION'),
                                            onAction: () => {
                                                editor.insertContent('[[exam]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_HISTORY'),
                                            onAction: () => {
                                                editor.insertContent('[[history]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_INTERPRETATION'),
                                            onAction: () => {
                                                editor.insertContent('[[interpretation]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_PURPOSE'),
                                            onAction: () => {
                                                editor.insertContent('[[purpose]]');
                                            }
                                        }
                                    ];
                                }
                            },
                            {
                                type: 'nestedmenuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_VERSION_MANAGEMENT'),
                                getSubmenuItems: () => {
                                    return [
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_TARGET'),
                                            onAction: () => {
                                                editor.insertContent('[[target]]');
                                            }
                                        },
                                        {
                                            type: 'menuitem',
                                            text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_VET_SPAN'),
                                            onAction: () => {
                                                editor.insertContent(this.getProCommentTag());
                                            }
                                        }
                                    ];
                                }
                            },
                            {
                                type: 'menuitem',
                                text: this.translateService.instant('REPORTS.EDITORS.TEMPLATE.TAG_LABEL_VET'),
                                onAction: () => {
                                    editor.insertContent('[[vet]]');
                                }
                            }

                        ];
                        callback(items);
                    }
                });
            }

        };
    }

    ngOnDestroy(): void {
        this.templateListUpdateNotifierSubscription.unsubscribe();
    }

    youSeeWhatIsApplied(): boolean {
        return this.selectedTemplate === this.appliedTemplate && !this.selectedTemplate.hasBeenUpdatedSinceLastTimeApplied && this.isTemplateHasBeenChosen;
    }

    updateSelectedTemplate(templateId: number): void {
        this.selectedTemplate = this.templates.find(template => template.id === templateId);
    }

    templateModified(): void {
        this.selectedTemplate.status = DocumentStatus.Modified;
        this.selectedTemplate.hasBeenUpdatedSinceLastTimeApplied = true;
    }

    applyTemplate(): void {
        this.appliedTemplate = this.templates.find(template => template.id === this.selectedTemplate.id);
        this.templateService.changeAppliedTemplate(this.appliedTemplate);
        this.selectedTemplate.hasBeenUpdatedSinceLastTimeApplied = false;
        this.isTemplateHasBeenChosen = true;
    }

    save(): void {
        this.saveTemplate.emit(this.selectedTemplate);
    }

    delete(): void {
        this.deleteTemplate.emit(this.selectedTemplate.id);
        // TODO: Trouver une manière + élégante de le faire
        this.selectedTemplate = this.templates.find(template => template.id !== this.selectedTemplate.id);
    }

    private getProCommentTag(): string {
        const text: string = this.translateService.instant('REPORTS.EDITORS.TEMPLATE.PRO_COMMENTS_HERE');
        return `[[pro ${text} pro]]`;
    }
}

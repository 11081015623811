import { JsonProperty } from 'json-object-mapper';

export class JoinCallResponse {
    @JsonProperty()
    callerName: string = null;

    @JsonProperty()
    apiKey: string = null;

    @JsonProperty()
    idSession: string = null;

    @JsonProperty()
    token: string = null;
}

<div class="chat" fxFlex fxLayout="column" *ngIf="chat" #container>

    <!-- CHAT TOOLBAR -->
    <mat-toolbar class="chat-toolbar" *ngIf="!visio">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="start center">
                <!-- RESPONSIVE CHATS BUTTON-->
                <button mat-icon-button fxHide.gt-sm class="responsive-chats-button mr-16 secondary"
                        fuseMatSidenavToggler="chat-left-sidenav" [matTooltip]="'CHAT.LIST_CHATS' | translate">
                    <mat-icon>menu</mat-icon>
                </button>
                <!-- / RESPONSIVE CHATS BUTTON-->

                <!-- CHAT CONTACT-->
                <div class="chat-contact" fxLayout="row" fxLayoutAlign="start center" style="overflow: hidden;">
                    <app-avatar [entity]="animal" class="mr-16"
                                [matTooltip]="animal?.nom + ' &middot; ' + (client | userFullName)"></app-avatar>

                    <span style="overflow: hidden; text-overflow: ellipsis;">{{ chat.nom }}</span>
                </div>
                <!-- / CHAT CONTACT-->
            </div>

            <div fxLayout="row" fxLayoutAlign="start center">
                <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

                    <button *ngIf="chat.bookmarked" mat-icon-button color="accent"
                            matTooltip="{{ 'CHAT.BOOKMARK.REMOVE' | translate }}"
                            (click)="removeBookmark()">
                        <mat-icon>star</mat-icon>
                    </button>

                    <button *ngIf="!chat.bookmarked" mat-icon-button color="accent"
                            matTooltip="{{ 'CHAT.BOOKMARK.ADD' | translate }}"
                            (click)="addBookmark()">
                        <mat-icon>star_border</mat-icon>
                    </button>

                    <button color="accent" type="button" mat-button (click)="seeDelayedMessages()"
                            *ngIf="chat.delayedMessagesCount > 0">
                        <mat-icon>access_time</mat-icon>
                        {{ 'CHAT.DELAYED_MESSAGES' | translate }}
                    </button>

                    <mat-slide-toggle [ngModel]="chat.canChat" (change)="changeStatusChat($event.checked)"
                                      [class.warn-fg]="!chat.canChat" [class.green-fg]="chat.canChat">
                        {{ 'CHAT.STATUS' | translate }}
                        : {{ chat.canChat ? ('CHAT.STATUS_OPEN' | translate) : ('CHAT.STATUS_CLOSE' | translate) }}
                    </mat-slide-toggle>

                    <button color="primary" type="button" mat-button (click)="openChat()">
                        <mat-icon>edit</mat-icon>
                        {{ 'CHAT.CHANGE' | translate }}
                    </button>

                    <button mat-icon-button color="accent" class="more-button" [matMenuTriggerFor]="actionMenu"
                            matTooltip="{{ 'SHARED.ACTIONS' | translate }}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </div>

                <mat-spinner mode="indeterminate" [diameter]="20" color="accent" *ngIf="loading"></mat-spinner>
            </div>
        </div>
    </mat-toolbar>
    <!-- / CHAT TOOLBAR -->

    <!-- CHAT CONTENT -->
    <div id="chat-content" fxFlex #chatcontent (scroll)="loadMoreMessage($event)">

        <!-- CHAT MESSAGES -->
        <ngx-file-drop (onFileDrop)="onFileDropped($event)">
            <ng-template ngx-file-drop-content-tmp>
                <div>
                    <!-- MESSAGE -->
                    <div fxLayout="row" *ngFor="let wrappedMessage of wrappedMessages$ | async; trackBy: trackByFn"
                         class="message-row message-{{ wrappedMessage?.id }}"
                         [class.user]="wrappedMessage.isMyMessage">
                        <ng-container [ngTemplateOutlet]="messageTemplate"
                                      [ngTemplateOutletContext]="{message:wrappedMessage, position:wrappedMessage.position}">
                        </ng-container>
                    </div>

                    <div fxLayout="row" *ngIf="chatService.typing | async" class="message-row typing">
                        <ng-container [ngTemplateOutlet]="messageEmptyTemplate"
                                      [ngTemplateOutletContext]="{contact:(chatService.typing | async)}">
                        </ng-container>
                    </div>
                    <!-- / MESSAGE -->
                </div>
            </ng-template>
        </ngx-file-drop>
        <!-- CHAT MESSAGES -->

        <div *ngIf="visio && loading" class="w-100-p m-16" fxLayout="row" fxLayoutAlign="center center">
            <mat-spinner mode="indeterminate" [diameter]="40" color="accent"></mat-spinner>
        </div>
    </div>
    <!-- / CHAT CONTENT -->

    <!-- CHAT FOOTER -->
    <div class="chat-footer" fxLayout="row" fxLayoutAlign="center center" *ngIf="chat.isFutur">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="mr-16">access_time</mat-icon>
            <p [innerHTML]="'CHAT.MESSAGE_CLOSE' | translate:{ client: client | userFullName }"></p>
        </div>
    </div>

    <div class="chat-footer" fxLayout="column" *ngIf="!chat.isChatClosed; else canNotChat">
        <!-- REPLY FORM -->
        <form #replyForm="ngForm" (ngSubmit)="reply()" (keyup.enter)="$event.preventDefault(); reply()" class="reply-form" fxFlex fxLayout="row"
              fxLayoutAlign="start center">

            <button mat-icon-button type="button" [matMenuTriggerFor]="messagesMenu"
                    [matTooltip]="'CHAT.ACTIONS.ADD' | translate" [disabled]="isSending" class="mr-8">
                <mat-icon>add_circle</mat-icon>
            </button>

            <mat-menu #messagesMenu="matMenu" class="chat-add-menu">
                <span class="menu-title">{{ 'CHAT.ACTIONS.ATTACHMENT.TITLE' | translate }}</span>
                <button mat-menu-item (click)="openFichiersModal()" [disabled]="isSending">
                    <mat-icon>attach_file</mat-icon>
                    {{ 'CHAT.SEND_FILE' | translate }}</button>
                <span *ngIf="utilisateurService?.utilisateurConnectedValue?.entiteJuridique?.hasVisioCare">
                    <mat-divider class="my-8"></mat-divider>
                    <span class="menu-title">{{ 'CHAT.ACTIONS.MEDIPROD.TITLE' | translate }}</span>
                    <button mat-menu-item (click)="openMediProdModal()" [disabled]="isSending"><mat-icon
                        svgIcon="visiocare"></mat-icon>
                        {{ 'CHAT.ACTIONS.MEDIPROD.ACTION' | translate }}</button>
                </span>
                <mat-divider class="my-8"></mat-divider>
                <span class="menu-title">{{ 'CHAT.AUTO_MSG' | translate }}</span>

                <button mat-menu-item (click)="openMessageModal()">
                    <mat-icon>message</mat-icon>
                    {{ 'CHAT.SEND_AUTO_MSG' | translate }}
                </button>

                <mat-divider class="my-8"></mat-divider>
                <span class="menu-title">{{ 'CHAT.SCENARIOS' | translate }}</span>
                <button mat-menu-item [matMenuTriggerFor]="scenariosSubMenu" [disabled]="!scenarios?.length">
                    <mat-icon>engineering</mat-icon>
                    {{ 'CHAT.PROGRAM_SCENARIO' | translate }}
                </button>
            </mat-menu>

            <mat-menu #scenariosSubMenu="matMenu">
                <ng-container *ngFor="let scenario of scenarios">
                    <button mat-menu-item (click)="openScenarioModal(scenario)">
                        {{ scenario.title }}
                    </button>
                    <mat-divider class="my-8"></mat-divider>
                </ng-container>
            </mat-menu>

            <mat-autocomplete #autoShortcut="matAutocomplete">
                <mat-option *ngFor="let shortcut of filteredShortcuts" [value]="shortcut.content">
                    <div>
                        <div class="shortcut">/{{ shortcut.shortcut }}</div>
                        {{ shortcut.content }}
                    </div>
                </mat-option>
            </mat-autocomplete>

            <mat-form-field fxFlex floatLabel="never" class="mr-16">
                <textarea
                    matInput
                    #replyInput
                    [placeholder]="'CHAT.ENTER_MESSAGE' | translate"
                    [disabled]="isSending"
                    [matAutocomplete]="autoShortcut"
                    ngModel
                    name="message"
                    autosize
                    minRows="1"
                    [maxRows]="4"
                    (keydown.enter)="$event.preventDefault()">
                </textarea>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="space-around stretch" class="button-send" [class.small]="smallSendButton">
                <button mat-button [disabled]="isSending || replyInput.value.trim().length === 0" type="submit"
                        fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon matPrefix class="button-send-icon">send</mat-icon>
                    <span class="button-send-text">{{ 'CHAT.SEND' | translate }}</span>
                </button>
                <button mat-icon-button [matMenuTriggerFor]="sendButtonMenu" class="button-send-later"
                        [disabled]="isSending || replyInput.value.trim().length === 0" type="button">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #sendButtonMenu="matMenu">
                    <button mat-menu-item [disabled]="isSending || replyInput.value.trim().length === 0"
                            (click)="sendMessageLater()" type="button" fxLayout="row"
                            fxLayoutAlign="center center">
                        <mat-icon matPrefix class="mr-8">access_time</mat-icon>
                        <span>{{ 'CHAT.SEND_LATER' | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </form>
        <!-- / REPLY FORM -->
    </div>

    <ng-template #canNotChat>
        <div class="chat-footer" fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="mr-16">lock</mat-icon>
            <p [innerHTML]="'CHAT.EXPIRED' | translate"></p>
        </div>
    </ng-template>
    <!-- / CHAT FOOTER-->
</div>
<!-- / CHAT -->

<ng-template #messageTemplate let-message="message" let-position="position">
    <div class="inner-message-row" [class.top]="position == 'TOP'" [class.middle]="position == 'MIDDLE'"
         [class.bottom]="position == 'BOTTOM'" [class.solo]="position == 'SOLO'">
        <div *ngIf="message.isAdmin; else notAdmin" class="adminMessage">
            <div class="message">{{ 'CHAT.MESSAGE_SYSTEM' | translate }} : {{ message.payload }}</div>
            <div class="info secondary-text">{{ message.date | dateLocale:'medium' }}</div>
        </div>

        <ng-template #notAdmin>
            <div *ngIf="!message.dateSeparator; else dateSeparator" fxLayout="column">
                <div class="info secondary-text"
                     *ngIf="!message.isMyMessage && (position == 'TOP' || position == 'SOLO')">
                    {{ message.sender | userFullName }}
                </div>

                <div fxLayout="{{ message.isMyMessage ? 'row-reverse' : 'row' }}" fxLayoutAlign="start end">
                    <app-avatar [entity]="message.sender"
                                [style.opacity]="position == 'BOTTOM' || position == 'SOLO' ? '1' : '0'"
                                [size]="AvatarSize.SMALL" class="{{ message.isMyMessage ? 'ml-8' : 'mr-8' }}"></app-avatar>

                    <div class="bubble" [class.angular-corner]="position == 'BOTTOM' || position == 'SOLO'"
                         [class.vet]="!message.isClient"
                         fxLayout="column" fxLayoutAlign="start {{ message.isMyMessage ? 'end' : 'start' }}">
                        <div class="message" *ngIf="!message.isFile; else fileTemplate"
                             [innerHTML]="message.payload | linky:{ className: 'chat-message-link', replaceFn: replaceFnLinky }"></div>
                        <ng-template #fileTemplate>
                            <div fxLayout="column">
                                <div [ngSwitch]="chatService.getFileTypeMessage(message)" [photoGalleryGroup]="photoGalleryConfig">
                                    <div *ngSwitchCase="'image'" fxLayout="column">
                                        <img [photoGallery]="message.payload.url"
                                             [src]="message.payload.thumbnailUrl" width="300" height="240"
                                             [title]="message.date | dateLocale:(((message.date | dateIsCurrentYear) ? 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_YEAR_WITHOUT_SECOND' : 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_SECOND') | translate)"
                                             class="image-{{ message.id }} cursor-pointer"/>
                                        <div class="actions" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                                            <a mat-button [href]="message.payload.url" target="_blank">
                                                <mat-icon>get_app</mat-icon>
                                                ({{ message.payload.size | fileSize }})
                                            </a>
                                            <button mat-button (click)="editImage(message)">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'video'">
                                        <video width="300" height="240" controls="controls" class="file-content">
                                            <source [src]="message.payload.url" type="video/mp4"/>
                                            {{ 'SHARED.BROWSER_NOT_READ_VIDEO' | translate }}
                                        </video>
                                        <a mat-button color="accent" [href]="message.payload.url" target="_blank"
                                           class="p-0 mt-4 text-center">
                                            <mat-icon>get_app</mat-icon>
                                            {{ 'SHARED.DOWNLOAD_VIDEO' | translate }}
                                            ({{ message.payload.size | fileSize }})
                                        </a>
                                    </div>
                                    <div *ngSwitchCase="'audio'">
                                        <audio controls="controls" class="file-content">
                                            <source [src]="message.payload.url" type="audio/aac"/>
                                            {{ 'SHARED.BROWSER_NOT_READ_AUDIO' | translate }}
                                        </audio>
                                        <a mat-button color="accent" [href]="message.payload.url" target="_blank"
                                           class="p-0 mt-4 text-center">
                                            <mat-icon>get_app</mat-icon>
                                            {{ 'SHARED.DOWNLOAD_AUDIO' | translate }}
                                            ({{ message.payload.size | fileSize }})
                                        </a>
                                    </div>
                                    <div *ngSwitchDefault fxLayout="column">
                                        <a mat-button color="accent" [href]="message.payload.url" target="_blank"
                                           class="p-0 mt-4 text-center">
                                            <mat-icon>get_app</mat-icon>
                                            {{ 'SHARED.DOWNLOAD_FILE' | translate }}
                                            ({{ message.payload.size | fileSize }})
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div class="info secondary-text" fxLayout="row"
                     fxLayoutAlign="{{ message.isMyMessage ? 'end' : 'start' }} center"
                     *ngIf="position == 'BOTTOM' || position == 'SOLO'">
                    <span>{{ message.date | dateLocale:(((message.date | dateIsCurrentYear) ? 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_YEAR_WITHOUT_SECOND' : 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_SECOND') | translate) }}</span>
                    <div *ngIf="message.isMyMessage" class="ml-4">
                        <mat-icon *ngIf="message.isRead; else notRead" class="check"
                                  [matTooltip]="'CHAT.READ' | translate">done_all
                        </mat-icon>
                        <ng-template #notRead>
                            <mat-icon *ngIf="message.isSent" class="check"
                                      [matTooltip]="'SHARED.SENDED' | translate">done
                            </mat-icon>
                        </ng-template>
                    </div>
                </div>
            </div>

            <ng-template #dateSeparator>
                <div class="date-separator text-center">
                    {{ message.dateSeparator | dateLocale:'fullDate' }}
                </div>
            </ng-template>
        </ng-template>
    </div>
</ng-template>

<ng-template #messageEmptyTemplate let-contact="contact">
    <app-avatar [entity]="contact"></app-avatar>

    <div class="bubble">
        {{ contact | userFullName:true }} {{ 'CHAT.IS_WRITING' | translate }}
    </div>
</ng-template>

<mat-menu #actionMenu="matMenu">
    <button type="button" mat-menu-item (click)="changeStatusChat(false)"
            *ngIf="chat.canChat; else canNotChat">
        <mat-icon>lock</mat-icon>
        {{ 'CHAT.STATUS_DO_CLOSE' | translate }}
    </button>

    <ng-template #canNotChat>
        <button class="open-button" type="button" mat-menu-item (click)="changeStatusChat(true)">
            <mat-icon>lock_open</mat-icon>
            {{ 'CHAT.STATUS_DO_OPEN' | translate }}
        </button>
    </ng-template>

    <button type="button" mat-menu-item (click)="generatePDF()">
        <mat-icon>save</mat-icon>
        {{ 'CHAT.EXPORT_PDF' | translate }}
    </button>
</mat-menu>

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors } from '@angular/forms';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-input-phone',
    templateUrl: './input-phone.component.html',
    styleUrls: ['./input-phone.component.scss']
})
export class InputPhoneComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() required: boolean;
    @Input() placeholder: string;
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() refresh$: Subject<string>;

    country: string;
    countryControl: FormControl;
    phoneNationalControl: FormControl;
    hasError: boolean;

    constructor(private translateService: TranslateService) {
        this.countryControl = new FormControl();
        this.phoneNationalControl = new FormControl();
    }

    ngOnInit(): void {
        if (!this.placeholder) {
            this.placeholder = this.translateService.instant('FORM.PHONE');
        }

        if (!this.label) {
            this.label = this.translateService.instant('FORM.PHONE');
        }

        if (this.refresh$) {
            this.refresh$.subscribe(val => this.phoneNationalControl.patchValue(val));
        }

        this.setNewValue(this.control.value);

        if (this.disabled) {
            this.phoneNationalControl.disable();
            this.countryControl.disable();
        } else {
            this.countryControl.valueChanges.subscribe(() => this.updateFormValues());
            this.phoneNationalControl.valueChanges.subscribe(() => this.updateFormValues());
        }
    }

    setNewValue(value: string): void {
        this.phoneNationalControl.patchValue(value);
        try {
            this.countryControl.patchValue(parsePhoneNumberWithError(this.phoneNationalControl.value).country);
            this.updateFormValues();
        } catch {
            this.countryControl.patchValue(this.translateService.getBrowserLang().toUpperCase(), { emitEvent: false });
        }
    }

    private updateFormValues(): void {
        try {
            const phoneNumber = parsePhoneNumberWithError(this.phoneNationalControl.value, this.countryControl.value);
            this.countryControl.patchValue(phoneNumber.country, { emitEvent: false });
            this.phoneNationalControl.patchValue(phoneNumber.formatNational(), { emitEvent: false });
            this.control.patchValue(phoneNumber.number);
        } catch {
            this.control.patchValue(null);
        }
    }
}

export function phoneNumberValidator(control: AbstractControl): ValidationErrors | null {
    if (control?.value) {
        try {
            return parsePhoneNumberWithError(control.value).isValid() ? null : { number: true };
        } catch {
            // ignored
        }

        return { number: true };
    }

    return null;
}

import { fuseAnimations } from '../../../../@fuse/animations';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { MatSort } from '@angular/material/sort';
import { RendezVousDataSource } from 'app/datasources/rendez-vous.data-source';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, mergeMap, reduce } from 'rxjs/operators';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { ConfigService } from '../../../services/config.service';
import { PriceAfterTaxe } from '../../../pipes/price-after-taxe.pipe';
import { RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { Animal } from 'app/models/animal/animal';
import { AnimalService } from 'app/services/api/animal.service';
import { TypeUtilisateurEnum, Utilisateur } from '../../../models/utilisateurs/utilisateur';
import { AuthService } from '../../../services/api/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from '../../../services/api/stripe.service';
import { Payout } from '../../../models/stripe/payout';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { FuseConfigService } from '../../../../@fuse/services/config.service';

@Component({
    selector: 'app-paiements',
    templateUrl: './paiements.component.html',
    styleUrls: ['./paiements.component.scss'],
    animations: fuseAnimations
})
export class PaiementsComponent implements OnInit, OnDestroy, AfterViewInit {
    payout: Payout;
    showFees = false;
    displayPayments = true;
    viewDate: Date;
    dataSource?: RendezVousDataSource;
    displayedColumns: string[];

    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatSort) sort: MatSort;

    isLoading: boolean;

    private priceAfterTaxe: PriceAfterTaxe;

    private subscriptions: Subscription[] = [];

    public get deactivatedAccount(): Observable<boolean> {
        return this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.pipe(map(e => !!e?.isDisabled));
    }

    constructor(
        private configService: ConfigService,
        private rendezVousService: RendezVousService,
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private animalService: AnimalService,
        private authService: AuthService,
        private stripeService: StripeService,
        private router: Router,
        private fuseConfigService: FuseConfigService,
        private readonly route: ActivatedRoute) {
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.isDisabled) {
            this.configureFuseLayout();
        }

        this.payout = route.snapshot.data.payout;
        if (this.payout) {
            this.displayedColumns = ['nature', 'veterinaire', 'client', 'animal', 'date', 'price'];
        } else if (this.showFees) {
            this.displayedColumns = ['nature', 'veterinaire', 'client', 'animal', 'date', 'status', 'price', 'priceTotal', 'available', 'receipt'];
        } else {
            this.displayedColumns = ['nature', 'veterinaire', 'client', 'animal', 'date', 'status', 'price', 'available', 'receipt'];
        }

        this.viewDate = new Date();
        this.viewDate.setDate(1);

        this.priceAfterTaxe = new PriceAfterTaxe(this.configService);
    }

    ngOnInit(): void {
        this.dataSource = new RendezVousDataSource(this.rendezVousService, this.stripeService);

        this.subscriptions.push(
            this.route.queryParamMap.subscribe(queryParams => {
                if (!queryParams.has('payout') && queryParams.has('d')) {
                    this.viewDate = new Date(Number(queryParams.get('d')));

                    void this.router.navigate([], {
                        queryParams: {
                            d: null
                        },
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                    });
                }

                if (this.utilisateurService.utilisateurConnectedValue.entiteJuridique.paymentEnabled) {
                    this.loadRendezVous();
                } else {
                    this.displayPayments = false;
                }
            })
        );
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }
    }

    ngAfterViewInit(): void {
        if (this.displayPayments) {
            this.sort.sortChange.subscribe(() => {
                this.dataSource.offlineSort(this.sort.direction, this.sort.active, this.filter.nativeElement.value);
            });

            fromEvent(this.filter.nativeElement, 'keyup').pipe(
                distinctUntilChanged(),
                debounceTime(150)
            ).subscribe(() => {
                this.dataSource.offlineSort(this.sort.direction, this.sort.active, this.filter.nativeElement.value);
            });
        }
    }

    private get startMonth(): Date {
        const startMonth = new Date(this.viewDate);
        startMonth.setUTCFullYear(startMonth.getUTCFullYear(), startMonth.getUTCMonth(), 1);
        startMonth.setUTCHours(0, 0, 0);
        startMonth.setUTCMilliseconds(0);
        return startMonth;
    }

    loadRendezVous(): void {
        const f = this.filter ? this.filter.nativeElement.value : null;

        const payout = this.payout;
        if (payout) {
            this.dataSource.loadRendezVousByPayout(f, payout);
        } else {
            const startDate = this.startMonth;
            const endDate = new Date(startDate);
            endDate.setUTCMonth(endDate.getUTCMonth() + 1);

            this.dataSource.loadRendezVousByMonth(
                f,
                'desc',
                'date',
                0,
                0,
                startDate,
                endDate,
                false,
                'all',
                true
            );
        }
    }

    chosenYearHandler(year: Date): void {
        this.viewDate.setFullYear(year.getFullYear());
    }

    chosenMonthHandler(month: Date): void {
        this.viewDate.setFullYear(month.getFullYear());
        this.viewDate.setMonth(month.getMonth());
        this.loadRendezVous();
    }

    getTotalPrice(): Observable<number> {
        return this.dataSource.connect().pipe(
            map(rendezVous => rendezVous.map(r => r.finalPrice)
                .reduce((acc, value) => acc + value, 0)
            )
        );
    }

    getTotalWithTax(): Observable<number> {
        return this.dataSource?.connect().pipe(
            mergeMap(rdv => rdv),
            mergeMap(rdv => this.priceAfterTaxe.transform(rdv.pricePayed)),
            reduce((acc, val) => acc + val, 0)
        );
    }

    showRdvDetail(rdv: RendezVous): void {
        this.rendezVousService.openDialogRendezVous(rdv);
    }

    showUserDetails(user: Utilisateur, type: string): void {
        if (type === TypeUtilisateurEnum.client) {
            this.utilisateurService.openDialogClient(user);
        } else {
            this.utilisateurService.openDialogVeterinaire(user);
        }
    }

    showAnimalDetail(animal: Animal): void {
        this.animalService.openDialogAnimal(animal);
    }

    public capture(rendezVous: RendezVous): void {
        if (rendezVous.mustCapture) {
            this.rendezVousService.openDialogCaptureRendezVous(rendezVous).afterClosed().subscribe((updatedRendezVous: RendezVous) => {
                if (updatedRendezVous) {
                    this.loadRendezVous();
                }
            });
        }
    }

    public get currencyEntiteJuridique(): Observable<string> {
        return this.utilisateurService.utilisateurConnected.pipe(map(u => u.entiteJuridique.currency));
    }

    public openReceipt(rendezVous: RendezVous): void {
        window.open(this.configService.baseUrl + 'api/rendez_vous/' + rendezVous.id.toString() + '/ticket?access_token=' + this.authService.token, '_blank');
    }

    trackByFn(_index: number, item: RendezVous): number {
        return item.id;
    }

    private configureFuseLayout(): void {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
}

import { Component } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { Router } from '@angular/router';

@Component({
    selector: 'onboarding-pro-end',
    templateUrl: './onboarding-pro-end.component.html',
    styleUrls: ['./onboarding-pro-end.component.scss'],
    animations: fuseAnimations
})
export class OnboardingProEndComponent {
    constructor(
        private fuseConfigService: FuseConfigService,
        private router: Router
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    redirect(): void {
        void this.router.navigate(['/']);
    }
}

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0 w-100-p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <button mat-stroked-button routerLink="/" *ngIf="entiteJuridique.isDisabled">
                    <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK' | translate }}
                </button>
                <mat-icon class="logo-icon">credit_card</mat-icon>
                <div class="h1" fxLayout="row" fxLayoutAlign="space-between center">
                    <span class="logo-text">{{ 'INFO_BANCAIRE.INFO_BANCAIRE' | translate }}</span>
                    <span class="paymentEnabled"
                          [class.enabled]="entiteJuridique?.paymentEnabled === true"
                          [class.pending]="entiteJuridique?.paymentEnabled === false"
                          *ngIf="entiteJuridique">&nbsp;
                        <span *ngIf="entiteJuridique?.paymentEnabled === true">
                            {{ 'SHARED.ENABLED' | translate }}
                        </span>
                        <span *ngIf="entiteJuridique?.paymentEnabled === false">
                            {{ 'OWNER.STATUS.PENDING' | translate }}
                        </span>
                    </span>
                </div>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">
            <div *ngIf="!(loading$ | async); else loading">
                <div fxLayout="column" *ngIf="entiteJuridique?.paymentEnabled !== null; else paymentDisabled">
                    <div *ngIf="accountType === 'custom'; else expressAccount">
                        <p>{{ 'INFO_BANCAIRE.CUSTOM' | translate }}</p>
                    </div>

                    <ng-template #expressAccount>
                        <p [innerHTML]="(entiteJuridique?.paymentEnabled === true ? 'INFO_BANCAIRE.CONSULT' : 'INFO_BANCAIRE.PENDING') | translate"></p>
                        <a mat-flat-button color="primary" href="{{ this.accountLink }}" target="_blank">
                            {{ 'INFO_BANCAIRE.CONSULT_BUTTON' | translate }}
                        </a>
                    </ng-template>
                </div>

                <div class="mt-16">
                    <app-payouts *ngIf="entiteJuridique?.paymentEnabled" [entiteJuridique]="entiteJuridique"></app-payouts>
                </div>

                <ng-template #paymentDisabled>
                    <div fxLayout="column">
                        <p [innerHTML]="'INFO_BANCAIRE.HELP' | translate"></p>
                        <a mat-flat-button color="primary" href="{{ this.accountLink }}" target="_blank">
                            {{ 'INFO_BANCAIRE.CREATE_BUTTON' | translate }}
                        </a>
                    </div>
                </ng-template>
            </div>

            <ng-template #loading>
                <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;" *ngIf="loading$ | async">
                    <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                    <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

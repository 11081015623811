<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg" alt="Logo Univet">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg" alt="Logo Univet">
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="1 1 auto">
        <mat-card>
            <mat-card-content>
                <div fxLayout="column">
                    <div class="step-header">
                        <h1 [innerHTML]="'ONBOARDING_CLIENT.STEPS.END.TITLE' | translate"></h1>
                        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.END.SUBTITLE' | translate"></p>
                    </div>

                    <div fxLayout="column" class="content">
                        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.END.STEP1' | translate"></p>

                        <div *ngIf="platform === 'other'" fxLayout="row" fxLayoutAlign="center center">
                            <img width="128" src="../../../../assets/images/logos/logo-app.png" alt="Univet">
                        </div>

                        <div *ngIf="platform === 'other'" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center"
                            fxLayoutGap="16px" class="links">
                            <a class="app-store" href="https://apps.apple.com/fr/app/univet/id1434308403?mt=8"
                                style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/fr-fr/badge-lrg.svg?releaseDate=2018-09-07&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;">
                            </a>
                            <a class="play-store"
                                href="https://play.google.com/store/apps/details?id=com.univet.app&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img alt="Disponible sur Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png" />
                            </a>
                        </div>

                        <div *ngIf="platform === 'iOS'" fxLayout="column" fxLayoutAlign="center center" class="links">
                            <a href="https://apps.apple.com/fr/app/univet/id1434308403?mt=8">
                                <img width="128" src="../../../../assets/images/logos/logo-app.png" alt="Univet">
                            </a>
                            <a class="app-store" href="https://apps.apple.com/fr/app/univet/id1434308403?mt=8"
                                style="display:inline-block;overflow:hidden;background:url(https://linkmaker.itunes.apple.com/fr-fr/badge-lrg.svg?releaseDate=2018-09-07&kind=iossoftware&bubble=ios_apps) no-repeat;width:135px;height:40px;">
                            </a>
                        </div>
                        <div *ngIf="platform === 'Android'" fxLayout="column" fxLayoutAlign="center center" class="links">
                            <a href="https://play.google.com/store/apps/details?id=com.univet.app&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img width="128" src="../../../../assets/images/logos/logo-app.png" alt="Univet">
                            </a>
                            <a class="play-store"
                                href="https://play.google.com/store/apps/details?id=com.univet.app&hl=fr&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                <img alt="Disponible sur Google Play"
                                    src="https://play.google.com/intl/en_us/badges/static/images/badges/fr_badge_web_generic.png" />
                            </a>
                        </div>

                        <p *ngIf="phone" [innerHTML]="'ONBOARDING_CLIENT.STEPS.END.STEP2' | translate:{ phone: (phone | phone) }"></p>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

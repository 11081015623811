import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { LoginComponent } from './login.component';
import { ViewSharedModule } from '../shared/view-shared.module';
import { SharedModule } from '../../shared.module';
import { NoAuthGuard } from '../../guards/no-auth.guard';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { LinkAppsProModule } from '../shared/link-apps-pro/link-apps-pro.module';

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NoAuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'reinscription',
        data: { resignin: true },
        component: LoginComponent,
        canActivate: [NoAuthGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        LoginComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        LinkAppsProModule
    ]
})
export class LoginModule {}

import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '../../@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '../../@fuse/components';
import { ContentModule } from './components/content/content.module';
import { NavbarModule } from './components/navbar/navbar.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { MaterialModule } from '../material.module';

@NgModule({
    declarations: [
        LayoutComponent
    ],
    imports: [
        RouterModule,

        FuseSharedModule,
        FuseSidebarModule,
        FuseProgressBarModule,

        ContentModule,
        NavbarModule,
        ToolbarModule,
        MaterialModule
    ],
    exports: [
        LayoutComponent
    ]
})
export class LayoutModule {}

import { NgModule } from '@angular/core';
import { LinkAppsProComponent } from './link-apps-pro.component';
import { SharedModule } from '../../../shared.module';

@NgModule({
    declarations: [LinkAppsProComponent],
    imports: [
        SharedModule
    ],
    exports: [LinkAppsProComponent]
})
export class LinkAppsProModule {}

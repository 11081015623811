import { filter, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from './auth.guard';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AdminGuard extends AuthGuard {
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return super.canActivate(next, state).pipe(
            filter(value => value),
            switchMap(() => this.utilisateurService.utilisateurConnected),
            map(user => {
                if (environment.demo) {
                    return true;
                }

                if (!user.isProprietaire) {
                    void this.router.navigate(['/dashboard']);
                }

                return user.isProprietaire;
            })
        );
    }
}

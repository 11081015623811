import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from '../../../../models/subscription';
import { FormControl, Validators } from '@angular/forms';

@Component({
    selector: 'app-veterinary-reassign-dialog',
    templateUrl: './veterinary-reassign-dialog.component.html',
    styleUrls: ['./veterinary-reassign-dialog.component.scss']
})
export class VeterinaryReassignDialogComponent {
    newUserControl = new FormControl(undefined, Validators.required);
    constructor(
        public dialogRef: MatDialogRef<VeterinaryReassignDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VeterinaryReassignDialogInterface
    ) {}

    close(): void {
        this.dialogRef.close();
    }

    closeOk(): void {
        if (this.newUserControl.valid) {
            this.dialogRef.close(this.newUserControl.value);
        }
    }
}

export interface VeterinaryReassignDialogInterface {
    userId: number;
}

import { NgModule } from '@angular/core';

import { AnimalFormComponent } from './animal-form/animal-form.component';
import { ChatFormComponent } from './chat-form/chat-form.component';
import { EntiteGeographiqueFormComponent } from './entite-geographique-form/entite-geographique-form.component';
import { EntiteJuridiqueFormComponent } from './entite-juridique-form/entite-juridique-form.component';
import { PhotoProfilFormComponent } from './photo-profil-form/photo-profil-form.component';
import { RendezVousFormComponent } from './rendez-vous-form/rendez-vous-form.component';
import { UserFormComponent } from './user-form/user-form.component';
import { SharedModule } from '../../../shared.module';
import { ClientFormComponent } from './user-form/client-form/client-form.component';
import { VeterinaireFormComponent } from './user-form/veterinaire-form/veterinaire-form.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { InputAnimalComponent } from './inputs/input-animal/input-animal.component';
import { InputPaysComponent } from './inputs/input-pays/input-pays.component';
import { InputAdresseComponent } from './inputs/input-adresse/input-adresse.component';
import { InputPhoneComponent } from './inputs/input-phone/input-phone.component';
import { InputCurrencyComponent } from './inputs/input-currency/input-currency.component';
import { InputTimezoneComponent } from './inputs/input-timezone/input-timezone.component';
import { InputVeterinaireComponent } from './inputs/input-veterinaire/input-veterinaire.component';
import { UserChangePasswordFormComponent } from './user-change-password-form/user-change-password-form.component';
import { UserAcceptCGUFormComponent } from './user-accept-cgu-form/user-accept-cgu-form.component';
import { ViewUtilsSharedModule } from '../view-utils/view-utils-shared.module';
import { SettingsFormComponent } from './settings-form/settings-form.component';
import { ConnectorFormComponent } from './settings-connector-form/connector-form.component';
import { InputConnectorComponent } from './inputs/input-connector/input-connector.component';
import { InputRegionComponent } from './inputs/input-region/input-region.component';
import { RendezVousWebFormComponent } from './rendez-vous-web-form/rendez-vous-web-form.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { FuseSharedModule } from '../../../../@fuse/shared.module';
import { PlanFormComponent } from './plan-form/plan-form.component';
import { InputVatComponent } from './inputs/input-vat/input-vat.component';
import { ColorChromeModule } from 'ngx-color/chrome';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InputClientComponent } from './inputs/input-client/input-client.component';
import { InputImportComponent } from './inputs/input-import/input-import.component';
import { InputVariableMessageComponent } from './inputs/input-variable-message/input-variable-message.component';
import { SettingsChatFormComponent } from './settings-chat-form/settings-chat-form.component';
import { SettingsShortcutMessageComponent } from './settings-chat-form/settings-shortcut-message/settings-shortcut-message.component';
import { NewsFormComponent } from './news-form/news-form.component';
import { NewsSegmentFormComponent } from './news-segment-form/news-segment-form.component';

@NgModule({
    declarations: [
        AnimalFormComponent,
        ChatFormComponent,
        ClientFormComponent,
        ConnectorFormComponent,
        EntiteGeographiqueFormComponent,
        EntiteJuridiqueFormComponent,
        NewsFormComponent,
        NewsSegmentFormComponent,
        PhotoProfilFormComponent,
        PlanFormComponent,
        RendezVousFormComponent,
        RendezVousWebFormComponent,
        SettingsChatFormComponent,
        SettingsFormComponent,
        UserAcceptCGUFormComponent,
        UserChangePasswordFormComponent,
        UserFormComponent,
        VeterinaireFormComponent,

        InputAdresseComponent,
        InputAnimalComponent,
        InputClientComponent,
        InputConnectorComponent,
        InputCurrencyComponent,
        InputImportComponent,
        InputPaysComponent,
        InputPhoneComponent,
        InputRegionComponent,
        InputTimezoneComponent,
        InputVariableMessageComponent,
        InputVatComponent,
        SettingsShortcutMessageComponent,
        InputVeterinaireComponent
    ],
    imports: [
        FuseSharedModule,
        SharedModule,
        ViewUtilsSharedModule,

        NgxMatSelectSearchModule,
        NgxCurrencyModule,
        ColorChromeModule,
        LazyLoadImageModule
    ],
    exports: [
        AnimalFormComponent,
        ChatFormComponent,
        ConnectorFormComponent,
        EntiteGeographiqueFormComponent,
        EntiteJuridiqueFormComponent,
        NewsFormComponent,
        NewsSegmentFormComponent,
        NgxMatSelectSearchModule,
        PhotoProfilFormComponent,
        PlanFormComponent,
        RendezVousFormComponent,
        RendezVousWebFormComponent,
        SettingsChatFormComponent,
        SettingsFormComponent,
        UserAcceptCGUFormComponent,
        UserChangePasswordFormComponent,
        UserFormComponent,

        InputAnimalComponent,
        InputClientComponent,
        InputConnectorComponent,
        InputCurrencyComponent,
        InputImportComponent,
        InputPaysComponent,
        InputPhoneComponent,
        InputTimezoneComponent,
        InputVariableMessageComponent,
        InputVatComponent,
        InputVeterinaireComponent
    ]
})
export class FormSharedModule {}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { EspaceDisqueComponent } from './espace-disque.component';
import { SharedModule } from '../../../shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'disk-space',
        component: EspaceDisqueComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'espace-disque',
        redirectTo: '/disk-space',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        EspaceDisqueComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule,

        NgxChartsModule
    ]
})

export class EspaceDisqueModule {}

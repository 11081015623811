import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { Subscription } from 'rxjs';
import { VisioService } from '../../../../services/visio.service';
import { Stream, Subscriber } from '@opentok/client';

@Component({
    selector: 'app-visio-subscriber',
    templateUrl: './visio-subscriber.component.html',
    styleUrls: ['./visio-subscriber.component.scss']
})

export class SubscriberComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('subscriberDiv', { static: true }) subscriberDiv: ElementRef;
    @ViewChild('pointer') pointersLayer: ElementRef;
    @Input() stream: Stream;
    subscriber: Subscriber;
    pointerX = 150;
    pointerY = 150;
    pointerDisplay = 'none';

    lastX = null;
    lastY = null;
    lastVideoOrientation = '0';
    videoOrientationTransform = {
        'position': 'absolute',
        'top': '0',
        'left': '0',
        'transform': 'rotate(0deg)',
        'transform-origin': 'top left',
        'height': '100%',
        'width': '100%'
    };

    rendezVous: RendezVous;
    subscriptions: Subscription;

    constructor(private el: ElementRef, private visioService: VisioService) {}

    ngOnInit(): void {
        this.subscriptions = this.visioService.currentRendezVous.subscribe((rendezVous: RendezVous) => {
            this.rendezVous = rendezVous;
        });
    }

    ngAfterViewInit(): void {
        this.visioService.currentSessionObject.on('signal', event => {
            if ((event.type as string) === 'signal:orientation') {
                const videoOrientation = event.data;
                if (this.lastVideoOrientation === videoOrientation) {
                    return;
                }

                this.lastVideoOrientation = videoOrientation;
                let videoTop;
                let videoLeft;
                let videoHeight;
                let videoWidth;

                const rect: { height: number; width: number } = this.el.nativeElement.getBoundingClientRect();
                switch (videoOrientation) {
                    case '270':
                        videoTop = rect.height.toString() + 'px';
                        videoLeft = '0';
                        videoHeight = rect.width.toString() + 'px';
                        videoWidth = rect.height.toString() + 'px';
                        break;
                    case '180':
                        videoTop = rect.height.toString() + 'px';
                        videoLeft = rect.width.toString() + 'px';
                        videoHeight = rect.height.toString() + 'px';
                        videoWidth = rect.width.toString() + 'px';
                        break;
                    case '90':
                        videoTop = '0';
                        videoLeft = rect.width.toString() + 'px';
                        videoHeight = rect.width.toString() + 'px';
                        videoWidth = rect.height.toString() + 'px';
                        break;
                    case '0':
                    default:
                        videoTop = '0';
                        videoLeft = '0';
                        videoHeight = rect.height.toString() + 'px';
                        videoWidth = rect.width.toString() + 'px';
                        break;
                }

                this.pointerDisplay = 'none';
                this.videoOrientationTransform = {
                    'position': 'absolute',
                    'top': videoTop,
                    'left': videoLeft,
                    'transform': 'rotate(' + videoOrientation + 'deg)',
                    'transform-origin': 'top left',
                    'height': videoHeight,
                    'width': videoWidth
                };

                if (Boolean(this.lastX) && Boolean(this.lastY)) {
                    this.drawPointer(this.lastX, this.lastY);
                }
            }
        });

        this.subscriber = this.visioService.currentSessionObject.subscribe(
            this.stream,
            this.subscriberDiv.nativeElement,
            {
                style: {
                    nameDisplayMode: 'off',
                    buttonDisplayMode: 'off',
                    audioLevelDisplayMode: 'off',
                    backgroundImageURI: 'on',
                    videoDisabledDisplayMode: 'on'
                },
                insertMode: 'replace',
                width: '100%',
                height: '100%',
                fitMode: 'contain'
            } as any,
            err => {
                if (err) {
                    console.error(err.message);
                }
            });
        this.visioService.addCurrenSubscribers(this.subscriber);
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }

        if (this.subscriber) {
            this.visioService.removeCurrenSubscribers(this.subscriber);
        }
    }

    sendPointerSignal(x: number, y: number): void {
        this.visioService.currentSessionObject.signal(
            {
                type: 'pointer',
                data: {
                    x: x,
                    y: y
                }
            } as any,
            error => {
                if (error) {
                    console.error('signal error (' + error.name + '): ' + error.message);
                }
            }
        );
    }

    sendClearPointerSignal(ev: Event): void {
        if (ev) {
            ev.stopPropagation();
        }

        this.pointerDisplay = 'none';
        this.visioService.currentSessionObject.signal(
            {
                type: 'clear'
            },
            error => {
                if (error) {
                    console.error('signal error (' + error.name + '): ' + error.message);
                }
            }
        );
    }

    sendTakePhotoSignal(ev: Event): void {
        ev.stopPropagation();
        this.visioService.currentSessionObject.signal(
            {
                type: 'capture',
                data: 'force'
            },
            error => {
                if (error) {
                    console.error('signal error (' + error.name + '): ' + error.message);
                }
            }
        );
    }

    sendSelectPhotoSignal(ev: Event): void {
        ev.stopPropagation();
        this.visioService.currentSessionObject.signal(
            {
                type: 'capture'
            },
            error => {
                if (error) {
                    console.error('signal error (' + error.name + '): ' + error.message);
                }
            }
        );
    }

    sendToggleFlashSignal(ev: Event): void {
        ev.stopPropagation();
        this.visioService.currentSessionObject.signal(
            {
                type: 'flash'
            },
            error => {
                if (error) {
                    console.error('signal error (' + error.name + '): ' + error.message);
                }
            }
        );
    }

    pointerClick(ev: MouseEvent): void {
        const orientation = this.lastVideoOrientation;
        const fluxVideoRect = this.getFluxVideoRect();
        if (fluxVideoRect && ev.clientX >= fluxVideoRect.left && ev.clientX <= fluxVideoRect.right && ev.clientY >= fluxVideoRect.top && ev.clientY <= fluxVideoRect.bottom) {
            let x: number;
            let y: number;
            switch (orientation) {
                case '270':
                    x = 1 - (ev.clientY - fluxVideoRect.top) / fluxVideoRect.height;
                    y = (ev.clientX - fluxVideoRect.left) / fluxVideoRect.width;
                    break;
                case '180':
                    x = 1 - (ev.clientX - fluxVideoRect.left) / fluxVideoRect.width;
                    y = 1 - (ev.clientY - fluxVideoRect.top) / fluxVideoRect.height;
                    break;
                case '90':
                    x = (ev.clientY - fluxVideoRect.top) / fluxVideoRect.height;
                    y = 1 - (ev.clientX - fluxVideoRect.left) / fluxVideoRect.width;
                    break;
                case '0':
                default:
                    x = (ev.clientX - fluxVideoRect.left) / fluxVideoRect.width;
                    y = (ev.clientY - fluxVideoRect.top) / fluxVideoRect.height;
                    break;
            }

            this.drawPointer(x, y);
            this.sendPointerSignal(x, y);
            this.lastX = x;
            this.lastY = y;
        }
    }

    private getFluxVideoRect(): DOMRect {
        const orientation = this.lastVideoOrientation;
        if (!this.subscriber.element) {
            return null;
        }

        const rectVideoElement = this.subscriber.element.getBoundingClientRect();
        const aspectRatioFlux = orientation === '90' || orientation === '270' ? this.subscriber.videoHeight() / this.subscriber.videoWidth() : this.subscriber.videoWidth() / this.subscriber.videoHeight();
        const aspectRatioVideoElement = orientation === '90' || orientation === '270' ? rectVideoElement.height / rectVideoElement.width : rectVideoElement.width / rectVideoElement.height;
        let realWidthFlux: number;
        let realHeightFlux: number;
        let realXFlux: number;
        let realYFlux: number;
        if (aspectRatioFlux > aspectRatioVideoElement) {
            realWidthFlux = rectVideoElement.width;
            realHeightFlux = realWidthFlux / aspectRatioFlux;
            realXFlux = rectVideoElement.x;
            realYFlux = (rectVideoElement.height - realHeightFlux) / 2 + rectVideoElement.y;
        } else {
            realHeightFlux = rectVideoElement.height;
            realWidthFlux = realHeightFlux * aspectRatioFlux;
            realYFlux = rectVideoElement.y;
            realXFlux = (rectVideoElement.width - realWidthFlux) / 2 + rectVideoElement.x;
        }

        return new DOMRect(realXFlux, realYFlux, realWidthFlux, realHeightFlux);
    }

    private drawPointer(x, y) {
        const orientation = this.lastVideoOrientation;
        const rectVideoElement = this.subscriber.element.getBoundingClientRect();
        const fluxVideoRect = this.getFluxVideoRect();
        if (fluxVideoRect) {
            this.pointerDisplay = 'block';

            let rawX: number;
            let rawY: number;
            switch (orientation) {
                case '270':
                    rawX = (rectVideoElement.height - fluxVideoRect.height) / 2 + fluxVideoRect.height * x - 8;
                    rawY = (rectVideoElement.width - fluxVideoRect.width) / 2 + fluxVideoRect.width * y - 8;
                    break;
                case '180':
                    rawX = (rectVideoElement.width - fluxVideoRect.width) / 2 + fluxVideoRect.width * x - 8;
                    rawY = (rectVideoElement.height - fluxVideoRect.height) / 2 + fluxVideoRect.height * y - 8;
                    break;
                case '90':
                    rawX = (rectVideoElement.height - fluxVideoRect.height) / 2 + fluxVideoRect.height * x - 8;
                    rawY = (rectVideoElement.width - fluxVideoRect.width) / 2 + fluxVideoRect.width * y - 8;
                    break;
                case '0':
                default:
                    rawX = (rectVideoElement.width - fluxVideoRect.width) / 2 + fluxVideoRect.width * x - 8;
                    rawY = (rectVideoElement.height - fluxVideoRect.height) / 2 + fluxVideoRect.height * y - 8;
                    break;
            }

            this.pointerX = rawX;
            this.pointerY = rawY;
        }
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListRendezVous, RendezVous } from '../../models/rendez-vous/rendez-vous';
import { ConfigService } from '../config.service';
import { CalendarEvent } from 'angular-calendar';
import { Fichier } from '../../models/fichier';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RendezVousPostInterface } from '../../models/interfaces/post/rendez-vous-post.interface';
import { ObjectMapper } from 'json-object-mapper';
import { MakeCallResponse } from '../../models/visio/make-call-response';
import {
    RendezVousDialogComponent,
    RendezVousDialogInterface
} from '../../main/shared/form-dialog/rendez-vous-dialog/rendez-vous-dialog.component';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import {
    PaymentCaptureDialogComponent,
    PaymentCaptureDialogData
} from '../../main/shared/form-dialog/payment-capture-dialog/payment-capture-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Animal } from '../../models/animal/animal';
import {
    RendezVousWebDialogComponent,
    RendezVousWebDialogInterface
} from '../../main/shared/form-dialog/rendez-vous-web-dialog/rendez-vous-web-dialog.component';
import { Client } from '../../models/utilisateurs/client';
import { RendezVousWebPostInterface } from '../../models/interfaces/post/rendez-vous-web-post.interface';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { JoinCallResponse } from '../../models/visio/join-call-response';
import { ItemHistory } from '../../models/interfaces/item-history';

@Injectable({
    providedIn: 'root'
})
export class RendezVousService {
    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public getAll(keywords: string = null,
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit: number = null,
        startDate: Date = null,
        stopDate: Date = null,
        onlyNext: boolean = null,
        who: 'mine' | 'all' = 'mine',
        onlyPaying: boolean = null
    ): Observable<ListRendezVous> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', `${offset}`);
        }

        if (limit >= 0) {
            params = params.append('limit', `${limit ? limit : 0}`);
        }

        if (startDate) {
            params = params.append('date_start', `${Math.round(startDate.getTime() / 1000)}`);
        }

        if (stopDate) {
            params = params.append('date_stop', `${Math.round(stopDate.getTime() / 1000)}`);
        }

        if (onlyNext) {
            params = params.append('only_next', onlyNext.toString());
        }

        if (who) {
            params = params.append('who', `${who}`);
        }

        if (onlyPaying) {
            params = params.append('only_paying', onlyPaying.toString());
        }

        return this.http.get<ListRendezVous>(this.config.baseUrl + 'api/rendez_vous', {
            params: params
        }).pipe(
            map(listRendezVous => ObjectMapper.deserialize(ListRendezVous, listRendezVous))
        );
    }

    public getAllBetweenToDate(startDate: Date = new Date(), stopDate: Date = null, limit = null, offset: number = null, onlyNext: boolean = null, who: 'mine' | 'all' = 'mine', onlyPaying: boolean = null): Observable<ListRendezVous> {
        return this.getAll(null, null, null, offset, limit, startDate, stopDate, onlyNext, who, onlyPaying);
    }

    public getByAnimal(animalId: number): Observable<ItemHistory[]> {
        const headers = new HttpHeaders();
        headers.set('Content-Type', 'Application/json');

        const animalIdAsString: string = animalId.toString();
        return this.http.get<ItemHistory[]>(`${this.config.baseUrl}api/report/history/${animalIdAsString}`, { headers: headers });
    }

    public getById(id: number): Observable<RendezVous> {
        return this.http.get<RendezVous>(this.config.baseUrl + 'api/rendez_vous/' + id.toString()).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous))
        );
    }

    public addRendezVous(rendezVousPost: Partial<RendezVousPostInterface>): Observable<RendezVous> {
        if ((rendezVousPost.date as any) instanceof Date) {
            rendezVousPost.date = Math.round((rendezVousPost.date as any).getTime() / 1000);
        }

        if ((rendezVousPost.clientConfirme as any) instanceof Date) {
            rendezVousPost.clientConfirme = Math.round((rendezVousPost.clientConfirme as any).getTime() / 1000);
        }

        rendezVousPost.id = 0;
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.ADDING'), null, { duration: 1500 });
        return this.http.post<RendezVous>(this.config.baseUrl + 'api/rendez_vous', rendezVousPost).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public addRendezVousWeb(rendezVousPost: RendezVousWebPostInterface): Observable<RendezVous> {
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.ADDING'), null, { duration: 1500 });
        rendezVousPost.id = 0;
        return this.http.post<RendezVous>(this.config.baseUrl + 'api/rendez_vous/web', rendezVousPost).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.ADDED_SENDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public updateRendezVous(rendezVousPost: RendezVousPostInterface): Observable<RendezVous> {
        if ((rendezVousPost.date as any) instanceof Date) {
            rendezVousPost.date = Math.round((rendezVousPost.date as any).getTime() / 1000);
        }

        if ((rendezVousPost.clientConfirme as any) instanceof Date) {
            rendezVousPost.clientConfirme = Math.round((rendezVousPost.clientConfirme as any).getTime() / 1000);
        }

        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.UPDATING'), null, { duration: 1500 });
        return this.http.put<RendezVous>(this.config.baseUrl + 'api/rendez_vous/' + rendezVousPost.id.toString(), rendezVousPost).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public deleteRendezVous(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('RENDEZ_VOUS.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('RENDEZ_VOUS.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('RENDEZ_VOUS.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.DELETING'), null, { duration: 1500 });
            return this.http.delete(this.config.baseUrl + 'api/rendez_vous/' + id.toString())
                .pipe(tap(() => {
                    this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
        }));
    }

    public cancelChargeRendezVous(id: number): Observable<RendezVous> {
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.CANCEL_CAPTURING'), null, { duration: 1500 });
        return this.http.patch<RendezVous>(this.config.baseUrl + 'api/rendez_vous/' + id.toString() + '/cancel_payment', null).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.CANCEL_CAPTURED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public captureChargeRendezVous(id: number, price: number): Observable<RendezVous> {
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.CAPTURING'), null, { duration: 1500 });
        return this.http.patch<RendezVous>(this.config.baseUrl + 'api/rendez_vous/' + id.toString() + '/pay', { price: price }).pipe(
            map(rendezVous => ObjectMapper.deserialize(RendezVous, rendezVous)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.CAPTURED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public createEventFromRendezVous(rendezVous: RendezVous): CalendarEvent {
        const now = Date.now();

        let cssClass = '';
        if (rendezVous.isFinished) {
            cssClass = 'ended';
        } else if (rendezVous.clientConfirme) {
            cssClass = 'client-confirme';
        }

        const event: CalendarEvent = {
            title: [rendezVous.client.fullName, rendezVous.animal.espece.nom, rendezVous.animal.nom].filter(v => v).join(' / '),
            start: rendezVous.date,
            end: new Date(rendezVous.date.getTime() + rendezVous.duree * 1000 * 60),
            meta: rendezVous,
            draggable: rendezVous.date.getTime() >= now,
            resizable: {
                beforeStart: rendezVous.date.getTime() / 1000 - rendezVous.duree >= now,
                afterEnd: rendezVous.date.getTime() >= now
            },
            cssClass
        };
        return event;
    }

    public setDateRendezVousFrom2Dates(rendezVous: RendezVous|RendezVousPostInterface, newStart: Date, newEnd: Date = null): void {
        if (!newEnd) {
            newEnd = new Date(newStart);
            newEnd.setUTCMinutes(newEnd.getUTCMinutes() + 10);
        }

        rendezVous.date = new Date(newStart.getTime());
        rendezVous.dureePrevue = Math.round((newEnd.getTime() - newStart.getTime()) / 1000 / 60);
    }

    public makeCall(rendezVousId: number): Observable<MakeCallResponse> {
        return this.http.get<MakeCallResponse>(this.config.baseUrl + 'api/rendez_vous/' + rendezVousId.toString() + '/call').pipe(
            map(res => ObjectMapper.deserialize(MakeCallResponse, res))
        );
    }

    public joinCall(rendezVousId: number): Observable<JoinCallResponse> {
        return this.http.get<JoinCallResponse>(this.config.baseUrl + 'api/rendez_vous/' + rendezVousId.toString() + '/session').pipe(
            map(res => ObjectMapper.deserialize(JoinCallResponse, res))
        );
    }

    public getFichiers(id: number): Observable<Fichier[]> {
        return this.http.get<Fichier[]>(this.config.baseUrl + 'api/rendez_vous/' + id.toString() + '/fichiers').pipe(
            map(fichiers => ObjectMapper.deserializeArray(Fichier, fichiers))
        );
    }

    public uploadFile(id: number, file: File): Observable<Fichier> {
        if (!(file instanceof Blob)) {
            return;
        }

        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.FILE_SENDING'), null, { duration: 1500 });
        const formData: FormData = new FormData();
        formData.append('photo', file, file.name);
        return this.http.post(this.config.baseUrl + 'api/rendez_vous/' + id.toString() + '/photo', formData).pipe(
            map(fichier => ObjectMapper.deserialize(Fichier, fichier)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.FILE_SENT'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public generatePDF(id: number, note: string): Observable<Fichier> {
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.RAPPORT_GENERATING'), null, { duration: 1500 });
        return this.http.post<Fichier>(this.config.baseUrl + 'api/rendez_vous/' + id.toString() + '/pdf', {
            note: note
        }).pipe(
            map(fichier => ObjectMapper.deserialize(Fichier, fichier)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.RAPPORT_GENERATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public openDialogRendezVous(rendezVous: RendezVous | any): MatDialogRef<RendezVousDialogComponent> {
        const data: RendezVousDialogInterface = {
            rendezVous: rendezVous instanceof RendezVous || Object.prototype.hasOwnProperty.call(rendezVous, 'hasPost') ? rendezVous : ObjectMapper.deserialize(RendezVous, rendezVous),
            rendezVousService: this
        };
        return this.dialog.open(RendezVousDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public openDialogCaptureRendezVous(rendezVous: RendezVous | any): MatDialogRef<PaymentCaptureDialogComponent> {
        const data: PaymentCaptureDialogData = {
            rendezVous: rendezVous instanceof RendezVous || Object.prototype.hasOwnProperty.call(rendezVous, 'hasPost') ? rendezVous : ObjectMapper.deserialize(RendezVous, rendezVous),
            rendezVousService: this
        };
        return this.dialog.open(PaymentCaptureDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '30vw',
            disableClose: true
        });
    }

    public openDialogRendezVousWeb(animal: Animal, client: Client): MatDialogRef<RendezVousWebDialogComponent> {
        const data: RendezVousWebDialogInterface = {
            animal: animal,
            client: client
        };
        return this.dialog.open(RendezVousWebDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '400px',
            disableClose: true
        });
    }

    public getRendezVousUrgencePost(veterinaire: Veterinaire, animal: Animal, entiteJuridique: EntiteJuridique = null): Partial<RendezVousPostInterface> {
        const date = new Date();
        date.setTime(date.getTime() + 5 * 60 * 1000); // + 5 min
        let prix: number = null;
        let currency: string = null;

        if (entiteJuridique) {
            prix = entiteJuridique.emergencyAppointmentPrice;
            currency = entiteJuridique.currency;
        } else if (veterinaire.entiteJuridique) {
            prix = veterinaire.entiteJuridique.emergencyAppointmentPrice;
            currency = veterinaire.entiteJuridique.currency;
        }

        return {
            nature: this.translateService.instant('SHARED.EMERGENCY'),
            categorie: 24, // urgence
            dureePrevue: 10,
            date: date,
            prix,
            currency,
            animalId: animal.id,
            veterinaire: veterinaire.id,
            clientConfirme: new Date()
        };
    }
}

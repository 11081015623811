<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'SETTINGS.MESSAGES.SCENARIOS.DIALOG.TITLE_' + (scenario.title ? 'UPDATE' : 'NEW') | translate }}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <form [formGroup]="form" (submit)="submit()">
            <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'SETTINGS.MESSAGES.SCENARIOS.DIALOG.TITLE_PLACEHOLDER' | translate }}
                        </mat-label>
                        <input matInput formControlName="title"
                               [placeholder]="'SETTINGS.MESSAGES.SCENARIOS.DIALOG.TITLE_PLACEHOLDER' | translate"
                               required/>
                        <mat-error *ngIf="form.get('title').hasError('required')">
                            {{ 'SHARED.REQUIRED' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <div *ngIf="messagesControls.controls?.length > 0; else empty">
                        <app-scenari-datatable
                            [shortcuts]="shortcuts"
                            [messages]="messagesToDisplay"
                            (removeMessage)="deleteMessage($event)"
                            (updateMessage)="updateMessage($event)"
                        ></app-scenari-datatable>
                    </div>

                    <ng-template #empty>
                        <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                            <img src="/assets/images/empty-state-chat.svg"/>
                            <span
                                class="app-message my-16">{{ 'SETTINGS.MESSAGES.SCENARIOS.DIALOG.EMPTY_STATE_MESSAGE' | translate }}</span>
                        </div>
                    </ng-template>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" fxFlex="1 1 auto">
                    <button type="button" mat-raised-button (click)="addMessage()" color="primary">
                        <mat-icon>add</mat-icon>
                        {{ 'SETTINGS.MESSAGES.SCENARIOS.DIALOG.ADD_MESSAGE' | translate }}
                    </button>
                    <button type="button" mat-raised-button color="primary" (click)="close()">
                        <mat-icon>cancel</mat-icon>
                        {{ 'FORM.CANCEL' | translate }}
                    </button>
                    <button type="submit" mat-raised-button color="accent" [disabled]="form.invalid">
                        <mat-icon>save</mat-icon>
                        {{ 'FORM.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

import { Animal } from './animal';
import { JsonProperty } from 'json-object-mapper';
import { Veterinaire } from '../utilisateurs/veterinaire';
import { DateMapper } from '../../object-mapper/date-mapper';
import { ChatPostInterface } from '../interfaces/post/animal/chat-post.interface';
import { Categorie } from '../categorie';
import { ChatShortcut } from '../pro/entite-juridique';
import { GroupChannel } from 'sendbird';
import { ChatMessage } from './chat-message';
import { Utilisateur } from '../utilisateurs/utilisateur';

export class File {
    @JsonProperty()
    url: string = null;

    @JsonProperty()
    data: string = null;

    @JsonProperty()
    type: string = null;

    @JsonProperty()
    name: string = null;

    @JsonProperty()
    size: number = null;
}

export class Sender {
    @JsonProperty()
    'user_id': string = null;

    @JsonProperty()
    nickname: string = null;

    @JsonProperty()
    'profile_url': string = null;

    @JsonProperty()
    metadata: any = null;
}

export class SendBirdMessageWebHook {
    @JsonProperty()
    'custom_type': string = null;

    @JsonProperty()
    'mentioned_users': any[] = null;

    @JsonProperty()
    'updated_at': number = null;

    @JsonProperty({ type: File })
    file?: File = null;

    @JsonProperty({ type: Sender })
    sender: Sender = null;

    @JsonProperty()
    thumbnails?: any[] = null;

    @JsonProperty()
    type: string = null;

    @JsonProperty()
    'created_at': number = null;

    @JsonProperty()
    'mention_type': string = null;

    @JsonProperty()
    message: string = null;

    @JsonProperty()
    'message_id': number = null;
}

export class Chat {
    @JsonProperty()
    id: number = null;

    @JsonProperty()
    nom: string = null;

    @JsonProperty({ type: Categorie })
    categorie: Categorie = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateDebut: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateFin: Date = null;

    @JsonProperty({ type: Animal })
    animal: Animal = null;

    @JsonProperty()
    sendbirdUrl: string = null;

    @JsonProperty()
    canChat: boolean = null; // Pas dans le passé ni dans le futur

    @JsonProperty()
    isChatClosed: boolean = null; // Dans le passé ?

    @JsonProperty()
    isFutur: boolean = null;

    @JsonProperty({ type: Veterinaire })
    veterinaire: Veterinaire = null;

    @JsonProperty()
    color: string = null;

    @JsonProperty()
    delayedMessagesCount: number = null;

    @JsonProperty({ type: ChatShortcut })
    shortcuts: ChatShortcut[] = null;

    @JsonProperty()
    bookmarked = false;

    @JsonProperty()
    veterinaryHasRead = false;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    lastMessageDate: Date = null;

    @JsonProperty({ type: Utilisateur })
    lastMessageOwner: Utilisateur = null;

    @JsonProperty()
    lastMessageText: string = null;

    @JsonProperty()
    lastMessageType: TypeChatMessageEnum = null;

    channel: GroupChannel;
    lastMessage: ChatMessage;
    unreadMessage = 0;

    public hasPost(): ChatPostInterface {
        return {
            id: this.id,
            nom: this.nom,
            categorie: this.categorie ? this.categorie.id : null,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            animalId: this.animal ? this.animal.id : null,
            veterinaire: this.veterinaire ? this.veterinaire.id : null,
            color: this.color,
            bookmarked: this.bookmarked
        };
    }
}

export enum TypeChatMessageEnum {
  file = 'file',
  text = 'text',
  admin = 'admin',
}

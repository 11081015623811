<div class="dialog-content-wrapper" fxLayout="column">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">
                <img src="/assets/images/visiocare/logo_full.png" width="130" height="48" class="h-48"/>
            </span>
            <div>
                <a mat-raised-button href="{{shopUrl}}" target="_blank"><mat-icon>store</mat-icon>{{ 'VISIOCARE.SHOP' | translate }}</a>
                <button mat-icon-button (click)="cancel()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="row">
        <ng-container *ngTemplateOutlet="(error$ | async) ? error : ((loading$ | async) ? loading : content)"></ng-container>

        <ng-template #error>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 1 100%" [innerHTML]="'VISIOCARE.ERROR_LOADING'| translate"></div>
        </ng-template>

        <ng-template #loading>
            <div fxLayout="row" fxLayoutAlign="center center" fxFlex="1 1 100%">
                <app-loading></app-loading>
            </div>
        </ng-template>

        <ng-template #content>
            <div fxLayout="column" fxFlex="1 1 100%">
                <mat-form-field appearance="outline" fxFlex="0 0 auto" class="no-padding">
                    <mat-chip-list #chipList>
                        <mat-chip *ngFor="let keyword of selectedKeywords | async" [selectable]="true"
                            [removable]="true" (removed)="keywordRemove(keyword)">
                            {{ keyword }}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                        <input [placeholder]="'VISIOCARE.SEARCH_PLACEHOLDER'| translate" #keywordsInput
                            [formControl]="keywordsCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="keywordSelected($event)">
                        <mat-option *ngFor="let keyword of (filteredKeywords | async | slice:0:25)" [value]="keyword">
                            {{ keyword | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon *ngIf="!(loadingMedia$ | async)" matSuffix>search</mat-icon>
                    <mat-spinner *ngIf="loadingMedia$ | async" matSuffix [diameter]="24"></mat-spinner>
                </mat-form-field>

                <p class="mt-8">{{ 'VISIOCARE.N_RESULT'| translate:{ n:(totalCountItems | async) } }}</p>

                <mat-divider></mat-divider>

                <mat-accordion class="visiocare-accordion" #itemsContainer *ngIf="items | async; let items" fxFlex="1 1 100%" [multi]="true">
                    <ng-template *ngFor="let item of items"
                        [ngTemplateOutlet]="itemContent"
                        [ngTemplateOutletContext]="{ item: item, items: items, depth: 0 }"></ng-template>

                    <ng-template #itemContent let-item='item' let-items='items' let-depth='depth'>
                        <mat-expansion-panel #panel [hideToggle]="item.type === 'media'" [disabled]="item.type === 'media'" [expanded]="items?.length === 1" class="mat-elevation-z0">
                            <mat-expansion-panel-header [class.item-media]="item.type === 'media'" class="{{ 'depth-' + depth }}">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p py-16 pr-8">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <div class="h-20">
                                            <mat-icon *ngIf="item.type === 'media'" class="icon mr-8">{{ getIcon(item) }}</mat-icon>
                                        </div>
                                        <div class="name">
                                            {{ item.name | titlecase }}
                                        </div>
                                    </div>
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <div *ngIf="item?.count > 0" class="count">
                                            {{ 'VISIOCARE.N_MEDIAS'| translate:{ n:item.count } }}
                                        </div>
                                        <div *ngIf="item.type === 'media'" fxLayout="row" fxLayoutAlign="center center" class="h-20">
                                            <button mat-icon-button color="primary" [disabled]="!canSee(item)"
                                                (click)="seeItem(item)" [matTooltip]="'VISIOCARE.PREVIEW' | translate">
                                                <mat-icon>visibility</mat-icon>
                                            </button>
                                            <button mat-icon-button color="accent" [disabled]="!canSend()"
                                                (click)="sendItem(item)" [matTooltip]="(data?.for === 'visio' ? 'VISIOCARE.SEND_VISIO' : 'VISIOCARE.SEND_CHAT') | translate">
                                                <mat-icon>{{ canSend() ? 'send' : 'lock' }}</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </mat-expansion-panel-header>
                            <div *ngIf="panel.expanded && item?.data">
                                <ng-template *ngFor="let i of item.data"
                                        [ngTemplateOutlet]="itemContent"
                                        [ngTemplateOutletContext]="{ item: i, items: item.data, depth: (depth + 1) }"></ng-template>
                            </div>
                        </mat-expansion-panel>
                    </ng-template>
                </mat-accordion>
            </div>
        </ng-template>
    </div>
</div>

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text"
             fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">person</mat-icon>
                <span class="logo-text h1">{{ 'LOGIN.MY_ACCOUNT' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <app-user-form [entityData]="userData$" [disabled]="false"
                           [country]="(user$ | async)?.entiteGeographique?.pays"></app-user-form>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketService } from './websocket.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { RendezVous } from '../../models/rendez-vous/rendez-vous';
import { WebsocketMessage } from '../../models/websockets/websocket-message';
import { RdvWaitingRoomList } from 'app/models/websockets/rdv-waiting-room-list';
import { RdvWaitingRoomEnter } from '../../models/websockets/rdv-waiting-room-enter';
import { RdvWaitingRoomExit } from '../../models/websockets/rdv-waiting-room-exit';
import { RdvTestVisio, RdvTestVisioData } from 'app/models/websockets/rdv-test-visio';

@Injectable({
    providedIn: 'root'
})
export class WaitingRoomService implements OnDestroy {
    public clientWaitings: BehaviorSubject<ClientWaiting[]> = new BehaviorSubject<ClientWaiting[]>([]);
    private subscription: Subscription = null;

    constructor(
        private webSocketService: WebSocketService
    ) {
        this.subscription = this.webSocketService.messages.subscribe(msg => this.onMessage(msg));
    }

    private onMessage(message: WebsocketMessage) {
        switch (message.constructor) {
            case RdvWaitingRoomList:
                this.onRoomList(message.data);
                break;
            case RdvWaitingRoomEnter:
                this.onRoomEnter(message.data);
                break;
            case RdvWaitingRoomExit:
                this.onRoomExit(message.data);
                break;
            case RdvTestVisio:
                this.onTestVisio(message.data);
                break;
            default:
                break;
        }
    }

    private onRoomList(data: RendezVous[]) {
        const newList = data.map(rendezVous => {
            const res: ClientWaiting = {
                rendezVous
            };
            return res;
        });

        const oldList = this.clientWaitings.value?.slice(0);
        if (oldList) {
            oldList.forEach(a => {
                const item = newList.find(b => a.rendezVous.id === b.rendezVous.id);
                if (item) {
                    item.testResult = a.testResult;
                }
            });
        }

        this.clientWaitings.next(newList);
    }

    private onRoomEnter(data: RendezVous) {
        const list = this.clientWaitings.value?.slice(0) ?? [];

        const item = list.find(i => i.rendezVous.id === data.id);
        if (item) {
            item.rendezVous = data;
        } else {
            list.push({ rendezVous: data } as ClientWaiting);
        }

        this.clientWaitings.next(list);
    }

    private onRoomExit(data: RendezVous) {
        const list = this.clientWaitings.value?.slice(0) ?? [];
        this.clientWaitings.next(list.filter(i => i.rendezVous.id !== data.id));
    }

    private onTestVisio(data: RdvTestVisioData) {
        const list = this.clientWaitings.value?.slice(0) ?? [];

        const item = list.find(i => i.rendezVous.id === data.rendezVous.id);
        if (item) {
            item.testResult = data.result;
            item.rendezVous = data.rendezVous;
        } else {
            list.push(
                {
                    testResult: data.result,
                    rendezVous: data.rendezVous
                } as ClientWaiting
            );
        }

        this.clientWaitings.next(list);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }
}

export interface ClientWaiting {
    testResult?: 0 | 1 | 2 | 3;
    rendezVous: RendezVous;
}

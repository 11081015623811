<div class="waiting-room-container">
    <mat-card *ngIf="card; else content">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </mat-card>
</div>

<ng-template #content>
    <div class="h2 p-16">{{ 'WAITING_ROOM.TITLE' | translate }}
        <span *ngIf="showCount" class="ml-8">({{ (waitingRoomService.clientWaitings | async)?.length }})</span>
    </div>
    <div fxLayout="column" fxLayoutGap="16px">
        <div *ngIf="!(waitingRoomService.clientWaitings | async)" class="m-16">
            <app-loading></app-loading>
        </div>

        <div *ngIf="(waitingRoomService.clientWaitings | async); let clientWaitings" class="p-16" fxLayout="column"
             fxLayoutGap="16px">
            <div class="rdv" *ngFor="let clientWaiting of clientWaitings" fxLayout="row">
                <div class="rdv-content p-8" fxFlex="grow">
                    <div class="waiting-room-info" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="mr-4">access_time</mat-icon>
                        {{ getElapsedTime(clientWaiting.rendezVous.waitingRoomEnterDate) }}
                        <span class="ml-8 mr-4">&middot;</span>
                        <mat-icon class="mr-4">signal_cellular_4_bar</mat-icon>
                        <b
                            class="mr-4">{{ 'WAITING_ROOM.SIGNAL_STRENGTH_PREFIX' | translate }}</b><span
                        [style.color]="getTestResultColor(clientWaiting)">{{ getTestResult(clientWaiting) }}</span>
                    </div>
                    <div class="rdv-info">
                        <b [matTooltip]="clientWaiting.rendezVous.commentaire">
                            {{ clientWaiting.rendezVous.date | dateLocale:'shortTime' }}
                            &middot; {{ clientWaiting.rendezVous.nature }}
<!--                            &middot; {{ clientWaiting.rendezVous.categorie?.value }}-->
                        </b>
                    </div>

                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center">
                        <div fxLayout="row" fxLayoutGap="8px" fxFlex="1 0 auto">
                            <app-profile-tile [title]="clientWaiting.rendezVous.animal.nom"
                                              [entity]="clientWaiting.rendezVous.animal">
                                {{ clientWaiting.rendezVous.animal.espece.nom + ' ' + (clientWaiting.rendezVous.animal.race != null ? ' &middot; ' +
                                clientWaiting.rendezVous.animal.race.nom : '') }}
                            </app-profile-tile>

                            <app-profile-tile [title]="clientWaiting.rendezVous.client | userFullName:true"
                                              [entity]="clientWaiting.rendezVous.client">
                                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                                    <span>{{ clientWaiting.rendezVous.client.telephonePortable | phone }}</span>
                                </div>
                            </app-profile-tile>
                        </div>

                        <button mat-button color="accent" (click)="loadVisio(clientWaiting.rendezVous)"
                                fxFlex="0 0 auto">
                            <mat-icon>videocam</mat-icon>
                            {{ 'VISIO.JOIN' | translate }}
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="clientWaitings.length <= 0" class="empty-state p-16" fxLayout="column"
                 fxLayoutAlign="center center" fxLayoutGap="10px">
                <img src="/assets/images/empty-state-rdv.svg"/>

                <span class="app-title my-16">{{ 'RENDEZ_VOUS.ANY_WAITING_ROOM' | translate }}</span>

                <span class="app-message">
                        {{ 'RENDEZ_VOUS.ANY_WAITING_ROOM_SPEECH' | translate }}
                    </span>
            </div>
        </div>
    </div>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { ViewSharedModule } from '../../../main/shared/view-shared.module';
import { ReportBugDialogModule } from 'app/main/shared/report-bug-dialog/report-bug-dialog.module';
import { SharedModule } from '../../../shared.module';
import { ConnectionTestDialogModule } from '../../../main/shared/connection-test/connection-test-dialog.module';

@NgModule({
    declarations: [
        ToolbarComponent
    ],
    imports: [
        RouterModule,

        SharedModule,
        ViewSharedModule,

        FuseSharedModule,
        ReportBugDialogModule,
        ConnectionTestDialogModule
    ],
    exports: [
        ToolbarComponent
    ]
})
export class ToolbarModule {}

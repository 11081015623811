import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'relativeTime' })
export class RelativeTimePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(date: Date | number): string {
        try {
            let result: string;
            let i: number;

            const now = Date.now();

            // time since message was sent in seconds
            const delta = date instanceof Date ? (now - date.getTime()) / 1000 : (now - date * 1000) / 1000;

            if (Number.isNaN(delta)) {
                return '';
            }

            // format string
            if (delta < 10) {
                result = this.translateService.instant('SHARED.RIGHT_NOW') as string;
            } else if (delta < 60) { // sent in last minute
                i = Math.floor(delta);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.SECONDS') as string}` : `${i} ${this.translateService.instant('SHARED.SECOND') as string}`;
            } else if (delta < 3600) { // sent in last hour
                i = Math.floor(delta / 60);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.MINUTES') as string}` : `${i} ${this.translateService.instant('SHARED.MINUTE') as string}`;
            } else if (delta < 86_400) { // sent on last day
                i = Math.floor(delta / 3600);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.HOURS') as string}` : `${i} ${this.translateService.instant('SHARED.HOURS') as string}`;
            } else if (delta < 2_592_000) { // sent more than one day ago
                i = Math.floor(delta / 86_400);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.DAYS') as string}` : `${i} ${this.translateService.instant('SHARED.DAY') as string}`;
            } else if (delta < 31_536_000) { // sent more than one month ago
                i = Math.floor(delta / 2_592_000);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.MONTHS') as string}` : `${i} ${this.translateService.instant('SHARED.MONTH') as string}`;
            } else { // sent more than one year ago
                i = Math.floor(delta / 31_536_000);
                result = i > 1 ? `${i} ${this.translateService.instant('SHARED.YEARS') as string}` : `${i} ${this.translateService.instant('SHARED.YEAR') as string}`;
            }

            return result;
        } catch {
            return '';
        }
    }
}

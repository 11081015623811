import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { EntiteGeographiquePostInterface } from '../../../../models/interfaces/post/pro/entite-geographique-post.interface';
import { EntiteGeographiqueService } from '../../../../services/api/entite-geographique.service';
import { EntiteGeographique } from '../../../../models/pro/entite-geographique';
import { phoneNumberValidator } from '../inputs/input-phone/input-phone.component';
import { getLocaleFirstDayOfWeek } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FichierService } from '../../../../services/api/fichier.service';
import { SelectorType } from '../../view-utils/document-selector-dialog/document-selector-dialog.component';
import { Fichier } from '../../../../models/fichier';
import { Utils } from 'app/utils';

@Component({
    selector: 'app-entite-geographique-form',
    templateUrl: './entite-geographique-form.component.html',
    styleUrls: ['./entite-geographique-form.component.scss'],
    animations: fuseAnimations
})
export class EntiteGeographiqueFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new BehaviorSubject<EntiteGeographiquePostInterface>(null);
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() disabled = false;
    @Input() canBeDeleted = false;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<EntiteGeographiquePostInterface>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<EntiteGeographique>();

    form: FormGroup;
    openingTimeDays: FormArray;
    orderOpeningTimeDays: number[] = [0, 1, 2, 3, 4, 5, 6];
    maxLengthChatAwayMessage = 512;

    currentImage?: {
        logo?: Fichier;
        imageCouverture?: Fichier;
    } = {};

    base64Image: {
        logo?: string | ArrayBuffer;
        imageCouverture?: string | ArrayBuffer;
    } = {};

    private subscriptionEntityData: Subscription;
    private subscriptions: Subscription[] = [];

    constructor(private entiteGeographiqueService: EntiteGeographiqueService, private translateService: TranslateService,
                private fichierService: FichierService) {
        this.openingTimeDays = new FormArray([
            this.createTimeOfDayFormGroup(false),
            this.createTimeOfDayFormGroup(true, '08:00', '12:00', '14:00', '19:00'),
            this.createTimeOfDayFormGroup(true, '08:00', '12:00', '14:00', '19:00'),
            this.createTimeOfDayFormGroup(true, '08:00', '12:00', '14:00', '19:00'),
            this.createTimeOfDayFormGroup(true, '08:00', '12:00', '14:00', '19:00'),
            this.createTimeOfDayFormGroup(true, '08:00', '12:00', '14:00', '19:00'),
            this.createTimeOfDayFormGroup(true, '09:00', '13:00')
        ]);

        this.form = new FormGroup({
            id: new FormControl(null),
            nom: new FormControl(null, [Validators.required]),
            mail: new FormControl(null, [Validators.required]),
            telephone: new FormControl(null, [Validators.required, phoneNumberValidator]),
            adresse: new FormControl(null, [Validators.required]),
            codePostal: new FormControl(null, [Validators.required]),
            ville: new FormControl(null, [Validators.required]),
            region: new FormControl(),
            pays: new FormControl(null, [Validators.required]),
            entiteJuridique: new FormControl(null, [Validators.required]),
            openingTime: this.openingTimeDays,
            chatAwayMessage: new FormControl(null, [Validators.maxLength(this.maxLengthChatAwayMessage)]),
            logo: new FormControl(null),
            logoId: new FormControl(null),
            imageCouverture: new FormControl(null),
            imageCouvertureId: new FormControl(null),
            telephoneUrgence: new FormControl(null, [phoneNumberValidator]),
            lienPriseRendezVous: new FormControl(null, [Utils.getUrlValidator()]),
            lienBoutique: new FormControl(null, [Utils.getUrlValidator()]),
            lienSiteWeb: new FormControl(null, [Utils.getUrlValidator()]),
            lienFacebook: new FormControl(null, [Utils.getUrlValidator()]),
            lienTwitter: new FormControl(null, [Utils.getUrlValidator()]),
            lienInstagram: new FormControl(null, [Utils.getUrlValidator()])
        });

        this.form.get('id').disable();
        this.form.get('entiteJuridique').disable();

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        if (getLocaleFirstDayOfWeek(this.translateService.getBrowserCultureLang()) === 0) {
            this.orderOpeningTimeDays = [0, 1, 2, 3, 4, 5, 6];
        } else {
            this.orderOpeningTimeDays = [1, 2, 3, 4, 5, 6, 0];
        }
    }

    ngOnInit(): void {
        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe(entiteGeographiqueData => {
            this.form.patchValue({
                id: entiteGeographiqueData.id,
                nom: entiteGeographiqueData.nom,
                mail: entiteGeographiqueData.mail,
                telephone: entiteGeographiqueData.telephone,
                adresse: entiteGeographiqueData.adresse,
                codePostal: entiteGeographiqueData.codePostal,
                ville: entiteGeographiqueData.ville,
                region: entiteGeographiqueData.region,
                pays: entiteGeographiqueData.pays,
                entiteJuridique: entiteGeographiqueData.entiteJuridique,
                chatAwayMessage: entiteGeographiqueData.chatAwayMessage,
                logoId: entiteGeographiqueData.logoId,
                imageCouvertureId: entiteGeographiqueData.imageCouvertureId,
                telephoneUrgence: entiteGeographiqueData.telephoneUrgence,
                lienPriseRendezVous: entiteGeographiqueData.lienPriseRendezVous,
                lienBoutique: entiteGeographiqueData.lienBoutique,
                lienSiteWeb: entiteGeographiqueData.lienSiteWeb,
                lienFacebook: entiteGeographiqueData.lienFacebook,
                lienTwitter: entiteGeographiqueData.lienTwitter,
                lienInstagram: entiteGeographiqueData.lienInstagram
            }, { emitEvent: false });

            this.currentImage.logo = entiteGeographiqueData.logo;
            this.currentImage.imageCouverture = entiteGeographiqueData.imageCouverture;

            for (let index = 0; index < 7; index++) {
                const formGroup = this.openingTimeDays.controls[index] as FormGroup;
                if (entiteGeographiqueData.openingTime && entiteGeographiqueData.openingTime.length > index) {
                    const openingTime: number[] = entiteGeographiqueData.openingTime[index];
                    if (openingTime.length > 0) {
                        this.updateTimeOfDayFormGroup(formGroup, true, this.minuteToHHMM(openingTime[0]), this.minuteToHHMM(openingTime[1]), this.minuteToHHMM(openingTime[2]), this.minuteToHHMM(openingTime[3]));
                    } else {
                        this.updateTimeOfDayFormGroup(formGroup, false);
                    }
                }
            }
        });

        if (this.disabled) {
            this.form.disable();
        }
    }

    private createTimeOfDayFormGroup(enable: boolean, startHour: string = null, endHour: string = null, startHour2: string = null, endHour2: string = null): FormGroup {
        const group = new FormGroup({
            enabled: new FormControl(),
            start: new FormControl(null, [Validators.required]),
            end: new FormControl(null, [Validators.required, EntiteGeographiqueFormComponent.greaterThan('start')]),
            start2: new FormControl(null, [EntiteGeographiqueFormComponent.requiredIfOtherTimeHasValue('end2'), EntiteGeographiqueFormComponent.greaterThan('end')]),
            end2: new FormControl(null, [EntiteGeographiqueFormComponent.requiredIfOtherTimeHasValue('start2'), EntiteGeographiqueFormComponent.greaterThan('start2')])
        });
        group.get('enabled').valueChanges.subscribe(value => {
            if (value) {
                group.get('start').enable();
                group.get('end').enable();
                group.get('start2').enable();
                group.get('end2').enable();
            } else {
                group.get('start').disable();
                group.get('end').disable();
                group.get('start2').disable();
                group.get('end2').disable();
            }
        });

        this.updateTimeOfDayFormGroup(group, enable, startHour, endHour, startHour2, endHour2);

        this.subscriptions.push(
          group.get('start').valueChanges.subscribe(() => {
            group.get('end').updateValueAndValidity({ emitEvent: false });
            group.get('start2').updateValueAndValidity({ emitEvent: false });
            group.get('end2').updateValueAndValidity({ emitEvent: false });
          }),
          group.get('end').valueChanges.subscribe(() => {
            group.get('start').updateValueAndValidity({ emitEvent: false });
            group.get('start2').updateValueAndValidity({ emitEvent: false });
            group.get('end2').updateValueAndValidity({ emitEvent: false });
          }),
          group.get('start2').valueChanges.subscribe(() => {
            group.get('start').updateValueAndValidity({ emitEvent: false });
            group.get('end').updateValueAndValidity({ emitEvent: false });
            group.get('end2').updateValueAndValidity({ emitEvent: false });
          }),
          group.get('end2').valueChanges.subscribe(() => {
            group.get('start').updateValueAndValidity({ emitEvent: false });
            group.get('start2').updateValueAndValidity({ emitEvent: false });
            group.get('end').updateValueAndValidity({ emitEvent: false });
          })
        );

        group.get('start').updateValueAndValidity({ emitEvent: false });
        group.get('end').updateValueAndValidity({ emitEvent: false });
        group.get('start2').updateValueAndValidity({ emitEvent: false });
        group.get('end2').updateValueAndValidity({ emitEvent: false });

        return group;
    }

    private updateTimeOfDayFormGroup(formGroup: FormGroup, enable: boolean, startHour: string = null, endHour: string = null, startHour2: string = null, endHour2: string = null) {
        formGroup.setValue({
            enabled: enable,
            start: startHour,
            end: endHour,
            start2: startHour2,
            end2: endHour2
        });
    }

    private HHMMToMinute(value: string): number {
      try {
        const splited: string[] = value.split(':');
        return Number(splited[0]) * 60 + Number(splited[1]);
      } catch {
        return Number.NaN;
      }
    }

    private minuteToHHMM(value: number): string {
      try {
        const hours = Math.floor(Number(value) / 60);
        const minutes = Number(value) % 60;
        return (hours < 10 ? '0' : '') + hours.toString() + ':' + (minutes < 10 ? '0' : '') + minutes.toString();
      } catch {
        return null;
      }
    }

    ngOnDestroy(): void {
        this.subscriptionEntityData?.unsubscribe();
        this.subscriptions.forEach((s: Subscription) => {
          s.unsubscribe();
      });
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save(this.form.getRawValue());
            }
        }
    }

    delete(): void {
        if (!this.disabled && this.canBeDeleted && this.form.get('id').value) {
            this.entiteGeographiqueService.deleteEntiteGeographique(this.form.get('id').value).subscribe(() => {
                this.entityReceived$.next(null);
                this.formSubmited$.next(null);
            });
        }
    }

    openImageDialog(name: string): void {
        const fichierSubscription = this.fichierService.openFileDropDialog({
            enableCropper: true,
            forceCropper: true,
            selectorType: SelectorType.IMAGE,
            selectorMultiple: false,
            settings: {
                rounded: false,
                aspectRatio: 1,
                maxHeight: 480,
                maxWidth: 480
            }
        }).subscribe((files: File[]) => {
            if (files && files.length > 0) {
                this.currentImage[name] = null;
                this.base64Image[name] = null;
                const imageInput = this.form.get(name);
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.addEventListener('load', () => {
                    this.base64Image[name] = reader.result;
                });
                reader.addEventListener('error', error => {
                    console.error(error);
                });
                imageInput?.setValue(files[0]);
                imageInput?.updateValueAndValidity();
            }

            fichierSubscription?.unsubscribe();
        });
    }

    deleteImage(event: Event, name: string): void {
        this.base64Image[name] = null;
        this.currentImage[name] = null;
        const imageInput = this.form.get(name);
        imageInput?.setValue(null);
        imageInput?.updateValueAndValidity();
        event.stopPropagation();
    }

    private save(data: EntiteGeographiquePostInterface) {
        this.formInProgress$.next(true);

        data.openingTime = (this.openingTimeDays.getRawValue() as TimeWithDay[]).map(t => t.enabled ? [this.HHMMToMinute(t.start), this.HHMMToMinute(t.end), this.HHMMToMinute(t.start2), this.HHMMToMinute(t.end2)].filter(t => !Number.isNaN(t)) : []);

        if (data.id) {
            this.entiteGeographiqueService.updateEntiteGeographique(data, this.form.get('logo').value, this.form.get('imageCouverture').value).subscribe({
                next: entiteGeographique => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(entiteGeographique);
                },
                error: () => this.formInProgress$.next(false)
            });
        } else {
            this.entiteGeographiqueService.addEntiteGeographique(data, this.form.get('logo').value, this.form.get('imageCouverture').value).subscribe({
                next: entiteGeographique => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(entiteGeographique);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }

    private static greaterThan(otherFormKey: string) {
      return (form: FormControl): { greaterThan?: boolean } => {
        if (form.parent) {
            const otherForm = form.parent.get(otherFormKey);
            let formValue = form.value;
            if (formValue?.toString() === 'NaN:NaN') {
              formValue = null;
            }

            let otherFormValue = otherForm.value;
            if (otherFormValue?.toString() === 'NaN:NaN') {
              otherFormValue = null;
            }

            if (formValue && otherFormValue && formValue <= otherFormValue) {
                return {
                    greaterThan: true
                };
            }
        }

        return {};
      };
    }

    private static requiredIfOtherTimeHasValue(otherFormKey: string) {
      return (form: FormControl): { requiredIfOtherTimeHasValue?: boolean } => {
        if (form.parent) {
            const otherForm = form.parent.get(otherFormKey);

            let formValue = form.value?.toString();
            if (formValue === 'NaN:NaN') {
              formValue = null;
            }

            let otherFormValue = otherForm.value?.toString();
            if (otherFormValue === 'NaN:NaN') {
              otherFormValue = null;
            }

            if (otherFormValue && !formValue) {
                return {
                  requiredIfOtherTimeHasValue: true
                };
            }
        }

        return {};
      };
    }
}

interface TimeWithDay {
    enabled: boolean;
    start?: string;
    end?: string;
    start2?: string;
    end2?: string;
}

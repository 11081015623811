import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ShortcutGroup, shortcutGroups } from '../../../../../models/shortcuts';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-input-variable-message',
    templateUrl: './input-variable-message.component.html',
    styleUrls: ['./input-variable-message.component.scss']
})
export class InputVariableMessageComponent {
    @Input() control: FormControl;
    shortcutGroups: ShortcutGroup[] = shortcutGroups;
    @ViewChild('shortcutContentInput') shortcutContentInput: ElementRef<HTMLTextAreaElement>;

    insert(str: string): void {
        if (this.shortcutContentInput) {
            const input = this.shortcutContentInput.nativeElement;
            const pos = input.selectionStart;
            const currentValue = this.control.value as string;
            const strVariable = '{{ ' + str + ' }}';
            if (pos && currentValue) {
                const strFirstPart = currentValue.slice(0, Math.max(0, pos));
                const strLastPart = currentValue.slice(pos, pos + currentValue.length);
                this.control.setValue(strFirstPart + strVariable + strLastPart);
                input.selectionStart = (strFirstPart + strVariable).length;
                input.selectionEnd = input.selectionStart;
            } else {
                this.control.setValue(strVariable);
            }

            input.focus();
        }
    }
}

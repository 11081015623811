<div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
    <div fxLayout="column" fxLayoutAlign="space-around end" fxLayoutGap="8px">
        <button mat-icon-button color="secondary-text" type="button"
                class="more-button" (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="actionMenu"
                [matTooltip]="'SHARED.ACTIONS' | translate" *ngIf="actions != null || actions?.length > 0">
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</div>
<mat-menu #actionMenu="matMenu">
    <button mat-menu-item type="button" *ngFor="let action of actions" (click)="action.onClick($event, id)">
        <span [ngClass]="action.class">
            <mat-icon [ngClass]="action.class">{{ action.icon }}</mat-icon>
            <span>{{ action.title }}</span>
        </span>
    </button>
</mat-menu>
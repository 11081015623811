import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Animal } from '../../../../models/animal/animal';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AnimalService } from '../../../../services/api/animal.service';
import { filter } from 'rxjs/operators';
import { AnimalPostInterface } from '../../../../models/interfaces/post/animal/animal-post.interface';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { RendezVousService } from 'app/services/api/rendez-vous.service';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { ChatService } from '../../../../services/api/chat.service';
import { Chat } from '../../../../models/animal/chat';

@Component({
    selector: 'app-animal-dialog',
    templateUrl: './animal-dialog.component.html',
    styleUrls: ['./animal-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnimalDialogComponent implements OnInit, OnDestroy {
    animalData$ = new BehaviorSubject<AnimalPostInterface>(null);

    animal$: BehaviorSubject<Animal>;
    animal: Animal;
    loading$ = new BehaviorSubject<boolean>(false);

    hasChanged = false;

    private subscription: Subscription;

    constructor(
        private rendezVousService: RendezVousService,
        private utilisateurService: UtilisateurService,
        private chatService: ChatService,
        public dialogRef: MatDialogRef<AnimalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AnimalDialogInterface
    ) {
        this.animal$ = new BehaviorSubject(data.animal);
        this.animal$.pipe(
            filter(u => Boolean(u))
        ).subscribe(animal => {
            this.animal = animal;
            this.animalData$.next(animal.hasPost());
        });
    }

    ngOnInit(): void {
        if (this.animal?.id) {
            this.loading$.next(true);
            this.subscription = this.data.animalService.getById(this.animal.id).subscribe({
                next: (animal: Animal) => {
                    this.animal$.next(animal);
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close(this.animal)
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.hasChanged ? this.animal$.value : false);
    }

    createRdv(): void {
        const newRdv = new RendezVous();
        newRdv.client = this.animal.proprietaire;
        newRdv.animal = this.animal;
        newRdv.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.rendezVousService.openDialogRendezVous(newRdv).afterClosed().subscribe(result => {
            if (result && result instanceof RendezVous) {
                    this.rendezVousService.createEventFromRendezVous(result);
                }
        });
    }

    createTchat(): void {
        const chat = new Chat();
        chat.dateDebut = new Date();
        chat.animal = this.animal;
        chat.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.chatService.openDialogChat(chat);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

export interface AnimalDialogInterface {
    animal: Animal;
    animalService: AnimalService;
}

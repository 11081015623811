import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: 'dialog.component.html',
    styleUrls: ['dialog.component.scss']
})
export class DialogComponent {
    iframeLoaded = false;

    constructor(public dialogRef: MatDialogRef<DialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogDataInterface) {}

    iframeHasLoaded(): void {
        this.iframeLoaded = true;
    }
}

export interface DialogDataInterface {
    action: boolean;
    cancel: string;
    cancelButton?: boolean;
    content: string;
    hideOk?: boolean;
    ok: string;
    title: string;
    urlIframe?: string;
}

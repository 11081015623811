import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'values'
})
export class ValuesPipe implements PipeTransform {
    transform(value: Record<string, unknown> | any[], filterNull = false): ValuesPipeInterface[] {
        return Object.keys(value).map(key => {
            const result: ValuesPipeInterface = {
                key: key,
                value: value[key]
            };
            return result;
        }).filter(v => filterNull ? !!v.value : true);
    }
}

export interface ValuesPipeInterface {
    key: any;
    value: any;
}

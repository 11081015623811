import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { StatSerie } from '../../../../models/interfaces/stats.interface';

@Component({
    selector: 'app-gauge-card',
    templateUrl: './gauge-card.component.html',
    styleUrls: ['./gauge-card.component.scss'],
    animations: [fuseAnimations]
})
export class GaugeCardComponent implements OnInit, OnChanges {
    @Input() titleLabel: string;
    @Input() maxLabel?: string;
    @Input() max: number;
    @Input() dangerZone: number;
    @Input() units: string;
    @Input() value: number;
    @Input() detail?: string;
    @Input() linear?: boolean;

    @Input('loading') isLoading?: boolean;

    @Input() action?: string;
    @Input() icon = 'open_in_new';
    @Input() colorChart = window.matchMedia('(prefers-color-scheme: dark)') ? '#28a8de' : '#20405d';
    @Input() color = '';
    @Output('onActionClick') actionClicked$ = new EventEmitter<null>();

    statSerie: StatSerie[];

    domainScheme = null;

    ngOnInit(): void {
        this.refreshDomain();
    }

    ngOnChanges(): void {
        this.refreshDomain();
    }

    private refreshDomain(): void {
        this.domainScheme = {
            domain: [this.colorChart]
        };

        if (this.value) {
            this.statSerie = [
                {
                    name: this.titleLabel,
                    value: this.value
                }
            ];
        } else {
            this.statSerie = null;
        }
    }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Note } from '../../models/rendez-vous/note';
import { NotePostInterface } from '../../models/interfaces/note-post.interface';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { map } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
    constructor(
        private http: HttpClient,
        private config: ConfigService
  ) {}

    public getNoteByRendezVousId(rendezVousid: number): Observable<Note> {
        return this.http.get<Note>(this.config.baseUrl + 'api/rendez_vous/' + rendezVousid.toString() + '/note').pipe(
            map(data => {
                if (!data) {
                    return null;
                }

                return ObjectMapper.deserialize(Note, data);
            })
        );
    }

    public saveNote(data: NotePostInterface): Observable<Note> {
        data.id = 0;
        return this.http.post<Note>(this.config.baseUrl + 'api/note', data).pipe(
            map(note => ObjectMapper.deserialize(Note, note))
        );
    }
}

import { JsonProperty } from 'json-object-mapper';
import { DateMapper } from '../object-mapper/date-mapper';

export class ItemMetadata {
    @JsonProperty()
    nbGoStockage: number = null;

    @JsonProperty()
    nbLicences: number = null;
}

export class ProductElement {
    @JsonProperty()
    id: string = null;

    @JsonProperty({ type: ItemMetadata })
    metadata?: ItemMetadata = null;

    @JsonProperty()
    name: string = null;

    @JsonProperty()
    price: number = null;

    @JsonProperty()
    quantity: number = null;
}

export class CurrenInvoiceItem {
    @JsonProperty({ type: ProductElement })
    product: ProductElement = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    periodStart: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    periodEnd: Date = null;

    @JsonProperty()
    price: number = null;

    @JsonProperty()
    quantity: number = null;
}

export class CouponMetadata {
    @JsonProperty()
    visioFreeHours: number = null;

    @JsonProperty()
    description: string = null;

    @JsonProperty()
    monthly: boolean = null;

    @JsonProperty()
    optionsIncluded: string = null;

    @JsonProperty()
    isHidden: boolean = null;
}

export class Coupon {
    @JsonProperty()
    id: string = null;

    @JsonProperty()
    amountOff: number = null;

    @JsonProperty()
    percentOff: number = null;

    @JsonProperty()
    monthsDuration: number = null;

    @JsonProperty({ type: CouponMetadata })
    metadata: CouponMetadata = null;

    public static getCouponCode(coupon: Coupon): string {
        let couponCode = String(coupon.id);

        if (couponCode.endsWith('-mensuel')) {
            couponCode = couponCode.slice(0, '-mensuel'.length - 1);
        } else if (couponCode.endsWith('-annuel')) {
            couponCode = couponCode.slice(0, '-annuel'.length - 1);
        }

        return couponCode;
    }
}

export class Discount {
    @JsonProperty({ type: Coupon })
    coupon: Coupon = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    startDate: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    endDate: Date = null;
}

export class Invoice {
    @JsonProperty()
    id: null | string = null;

    @JsonProperty()
    status: string = null;

    @JsonProperty()
    number: string = null;

    @JsonProperty()
    localNumber: string = null;

    @JsonProperty()
    paid: boolean = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    periodStart: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    periodEnd: Date = null;

    @JsonProperty()
    percentTaxes: number = null;

    @JsonProperty()
    totalTaxes: number = null;

    @JsonProperty()
    totalPriceHT: number = null;

    @JsonProperty()
    totalPrice: number = null;

    @JsonProperty()
    balance: number = null;

    @JsonProperty()
    totalDue: number = null;

    @JsonProperty()
    subtotalPriceHT: number = null;

    @JsonProperty()
    subtotalPrice: number = null;

    @JsonProperty({ type: CurrenInvoiceItem })
    items: CurrenInvoiceItem[] = null;

    @JsonProperty({ type: Discount })
    discount: Discount | null = null;
}

export class Card {
    @JsonProperty()
    brand: string = null;

    @JsonProperty()
    expMonth: number = null;

    @JsonProperty()
    expYear: number = null;

    @JsonProperty()
    last4Number: string = null;
}

export class Subscription {
    @JsonProperty()
    id: string = null;

    @JsonProperty()
    status: string = null;

    @JsonProperty()
    monthly: boolean = null;

    @JsonProperty({ type: Discount })
    discount: Discount = null;

    @JsonProperty({ type: Card })
    card: Card = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    startDate: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    endDate?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    currentPeriodStart: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    currentPeriodEnd: Date = null;

    @JsonProperty({ type: ProductElement })
    items: ProductElement[] = null;

    @JsonProperty({ type: Invoice })
    lastInvoice: Invoice = null;

    @JsonProperty({ type: Invoice })
    currentInvoice?: Invoice = null;
}

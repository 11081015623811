import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Animal } from '../../../../models/animal/animal';
import { Subscription } from 'rxjs';
import { AnimalService } from '../../../../services/api/animal.service';
import { AllyDvmService } from '../../../../services/api/ally-dvm.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';

@Component({
    selector: 'app-allydvm-dialog',
    templateUrl: './allydvm-dialog.component.html',
    styleUrls: ['./allydvm-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AllyDvmDialogComponent implements OnInit, OnDestroy {
    loading = true;
    formInProgress: boolean;
    canDelete: boolean;
    form: FormGroup;

    private subscription: Subscription;

    constructor(
        private entiteJuridiqueService: EntiteJuridiqueService,
        private allyDvmService: AllyDvmService,
        public dialogRef: MatDialogRef<AllyDvmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AnimalDialogInterface
    ) {
        this.form = new FormGroup({
            apiKey: new FormControl(null, [Validators.required]),
            appId: new FormControl(null, [Validators.required]),
            sourceId: new FormControl(null, [Validators.required])
        });
    }

    ngOnInit(): void {
        this.subscription = this.entiteJuridiqueService.getEntiteJuridiqueById(this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id).subscribe(e => {
            this.loading = false;
            this.canDelete = e.hasAllyDvm;
            this.form.setValue({
                apiKey: e.allydvmApiKey,
                appId: e.allydvmAppId,
                sourceId: e.allydvmSourceId
            });
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    submit(): void {
        this.formInProgress = true;
        if (this.form.valid) {
            this.allyDvmService.setInfosForEntiteJuridique(this.form.getRawValue()).subscribe({
                next: () => {
                    this.formInProgress = false;
                    this.close();
                },
                error: () => {
                    this.formInProgress = false;
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    removeAll(): void {
        this.formInProgress = true;
        this.allyDvmService.setInfosForEntiteJuridique({}).subscribe({
            next: () => {
                this.formInProgress = false;
                this.close();
            },
            error: () => {
                this.formInProgress = false;
            }
        });
    }
}

export interface AnimalDialogInterface {
    animal: Animal;
    animalService: AnimalService;
}

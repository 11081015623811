import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Utilisateur } from '../models/utilisateurs/utilisateur';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { Client, ListClients } from '../models/utilisateurs/client';

export class ClientsDataSource implements DataSource<Utilisateur> {
    private _clients = new BehaviorSubject<Client[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(private utilisateurService: UtilisateurService) {}

    loadClients(filter: string, sortDirection: string, sortField: string, pageIndex: number, pageSize: number): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.utilisateurService.getClients(filter, sortDirection, sortField, pageIndex * pageSize, pageSize).pipe(
            catchError(() => of([]))
        ).subscribe({
            next: (clients: ListClients) => {
                if (clients.data && clients.meta) {
                    const rows = [];
                    clients.data.forEach(client => rows.push(client, { detailRow: true, client }));
                    this._clients.next(rows);
                    this._totalCount.next(clients.meta.totalItems);
                    this._loadingSubject.next(false);
                }
            },
            error: () => this._loadingSubject.next(false)
        });
    }

    add(client: Client): void {
        const data = this._clients.getValue();
        data.push(client, { detailRow: true, client } as any);
        this._clients.next(data);
        this._totalCount.next(this._totalCount.getValue() + 1);
    }

    replace(client: Client): void {
        const data = this._clients.getValue();
        const index = data.findIndex(c => c.id === client.id);
        data[index] = client;
        // data[index + 1].client = client;
        this._clients.next(data);
    }

    delete(client: Client): void {
        const data = this._clients.getValue();
        data.splice(data.findIndex(c => c.id === client.id), 2);
        this._clients.next(data);
        this._totalCount.next(this._totalCount.getValue() - 1);
    }

    connect(): Observable<Client[]> {
        return this._clients.asObservable();
    }

    disconnect(): void {
        this._clients.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }
}

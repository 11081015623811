<mat-tab-group fxFlex="1 1 100%" [selectedIndex]="selectedIndex" (selectedTabChange)="onSelectedTabChanged($event)">
    <mat-tab #waiting [label]="'WAITING_ROOM.TITLE' | translate" *ngIf="(configService.getWaitingRoomEnabled() | async)">
        <app-waiting-room class="w-100-p" [showCount]="true"></app-waiting-room>
    </mat-tab>
    <mat-tab #next [label]="'CALENDAR.DATE_TODO' | translate">
        <app-rendez-vous-list-card class="w-100-p" [loadMore]="true" [count]="20" [period]="RendezVousListPeriod.TODO"></app-rendez-vous-list-card>
    </mat-tab>
    <mat-tab #previous [label]="'CALENDAR.DATE_DONE' | translate">
        <app-rendez-vous-list-card class="w-100-p" [loadMore]="false" [count]="20" [period]="RendezVousListPeriod.DONE"></app-rendez-vous-list-card>
    </mat-tab>
    <mat-tab #canceled [label]="'SHARED.CANCELED' | translate">
        <app-rendez-vous-list-card class="w-100-p" [loadMore]="false" [count]="20" [period]="RendezVousListPeriod.CANCELED"></app-rendez-vous-list-card>
    </mat-tab>
</mat-tab-group>

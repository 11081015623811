import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';

@Component({
    selector: 'app-connection-test-dialog',
    templateUrl: './connection-test-dialog.component.html',
    styleUrls: ['./connection-test-dialog.component.scss']
})
export class ConnectionTestDialogComponent {
    closable = true;

    constructor(public dialogRef: MatDialogRef<ConnectionTestDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data?: ConnectionTestDialogData) {
        if (!data?.closable) {
            this.closable = false;
        }
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface ConnectionTestDialogData {
    closable: boolean;
}

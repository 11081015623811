import { ActivatedRoute } from '@angular/router';
import { Animal } from '../../../models/animal/animal';
import { AnimalService } from 'app/services/api/animal.service';
import { Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Fichier } from '../../../models/fichier';
import { FichierService } from '../../../services/api/fichier.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { ListFichierComponent } from 'app/main/shared/list-fichier/list-fichier.component';

@Component({
    selector: 'app-animal-fichiers',
    templateUrl: './animal-fichiers.component.html',
    styleUrls: ['./animal-fichiers.component.scss'],
    animations: fuseAnimations
})
export class AnimalFichiersComponent implements OnInit, OnDestroy {
    isLoading = true;
    fichiers: Fichier[] = [];
    selectedFichiers: Fichier[] = [];
    animal: Animal;
    refreshFiles: EventEmitter<void> = new EventEmitter();

    @ViewChild('listFichier', { static: true })
    listFichierComponent: ListFichierComponent;

    private subscriptionRoute: Subscription;
    private subscriptionAnimal: Subscription;

    constructor(private animalService: AnimalService, private route: ActivatedRoute,
        private fichierService: FichierService) {}

    ngOnInit(): void {
        this.subscriptionRoute = this.route.paramMap.subscribe(params => {
            this.animal = new Animal();
            this.animal.id = Number(params.get('animalId'));

            this.subscriptionAnimal = this.animalService.getById(this.animal.id).subscribe(result => {
                this.animal = result;
            });
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionAnimal) {
            this.subscriptionAnimal.unsubscribe();
        }

        if (this.subscriptionRoute) {
            this.subscriptionRoute.unsubscribe();
        }
    }

    onFilesUpdated(fichiers: Fichier[]): void {
        this.fichiers = fichiers;
    }

    onSelectedFilesUpdated(selectedFichiers: Fichier | Fichier[]): void {
        if (Array.isArray(selectedFichiers)) {
            this.selectedFichiers = selectedFichiers;
        } else {
            this.selectedFichiers = [selectedFichiers];
        }
    }

    addFile(): void {
        this.fichierService.openFileDropDialog().subscribe((files: File[]) => {
            const reqs: Array<Observable<Fichier>> = [];
            for (const file of files) {
                reqs.push(this.animalService.uploadFile(this.animal.id, file));
            }

            forkJoin(reqs).subscribe({ complete: () => this.refreshFiles.next() });
        });
    }

    onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        this.listFichierComponent.setDateTagAtPosition(target.scrollTop);
        if (target.offsetHeight + target.scrollTop >= target.scrollHeight - 60) {
            this.listFichierComponent.loadMoreFiles();
        }
    }
}

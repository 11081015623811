import { JsonProperty } from 'json-object-mapper';
import { ReportPostInterface } from '../interfaces/report-post.interface';
import { List } from '../list';
import { TextDocument } from './text-document';
import { Template } from './template';
import { Fichier } from '../fichier';
import { RendezVous } from './rendez-vous';

export enum ReportTarget{
    Client= 'client',
    Vet = 'vet'
}

export class Report extends TextDocument {
    @JsonProperty({ type: RendezVous })
    appointment: RendezVous = null;

    @JsonProperty({ type: Template })
    template: Template = null;

    @JsonProperty()
    target: ReportTarget = null;

    @JsonProperty()
    sendingDate: Date = null;

    @JsonProperty({ type: Fichier })
    file: Fichier = null;

    public hasPost(): ReportPostInterface {
        return {
            id: this.id,
            appointmentId: this.appointment?.id,
            template: this.template?.id,
            name: this.name,
            target: this.target,
            status: this.status,
            type: this.type,
            content: this.content
        };
    }
}

export class ListReports extends List {
    @JsonProperty({ type: Report })
    data: Report[] = null;

    @JsonProperty()
    stats: any = null;
}

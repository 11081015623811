import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { Observable, Subject, Subscription } from 'rxjs';
import { EntiteJuridiqueSettingsPostInterface } from '../../../../models/interfaces/post/pro/entite-juridique-settings-post.interface';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../../environments/environment';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';
import { ConnecteurService } from '../../../../services/api/connecteur.service';
import { OneSignalService } from '../../../../services/onesignal.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
import { DialogComponent } from '../../view-utils/dialog/dialog.component';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailValidators } from 'ngx-validators';
import { emailOptionsConfig } from 'app/config';
import { Router } from '@angular/router';

@Component({
    selector: 'app-settings-form',
    templateUrl: './settings-form.component.html',
    styleUrls: ['./settings-form.component.scss'],
    animations: fuseAnimations
})
export class SettingsFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<EntiteJuridiqueSettingsPostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() disabled = false;
    @Input() paymentEnabled = false;
    @Input() currency: string;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<EntiteJuridiqueSettingsPostInterface>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<EntiteJuridique>();

    form: FormGroup;
    isNotificationEnabled = false;
    isDev = environment.hmr || environment.dev;

    showClinicCode = true;
    clinicCodeRegenerating = false;
    clinicCodeDeleting = false;

    private subscriptionEntityData: Subscription;

    constructor(
        private entiteJuridiqueService: EntiteJuridiqueService,
        private utilisateurService: UtilisateurService,
        private connecteurService: ConnecteurService,
        private oneSignalService: OneSignalService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl({ value: null, disabled: true }, [Validators.required]),
            accountSelector: new FormControl(),
            clientSignupCode: new FormControl({ value: null, disabled: true }),
            emergencyAppointmentPrice: new FormControl(null, [Validators.min(0)]),
            receiveEmailRecap: new FormControl(false),
            receiveEmailRecapAddress: new FormControl({
                value: null,
                disabled: true
            }, [Validators.required, EmailValidators.suggest(emailOptionsConfig)])
        });

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((entiteJuridiqueSettingsData: EntiteJuridiqueSettingsPostInterface) => {
            this.form.patchValue({
                id: entiteJuridiqueSettingsData.id,
                accountSelector: entiteJuridiqueSettingsData.accountSelector,
                clientSignupCode: entiteJuridiqueSettingsData.clientSignupCode,
                emergencyAppointmentPrice: entiteJuridiqueSettingsData.emergencyAppointmentPrice,
                receiveEmailRecap: entiteJuridiqueSettingsData.receiveEmailRecap,
                receiveEmailRecapAddress: entiteJuridiqueSettingsData.receiveEmailRecapAddress
            }, { emitEvent: false });

            this.showClinicCode = !EntiteJuridique.isScoldedFromOnboardingClient(entiteJuridiqueSettingsData.id);
            const recapEmail = this.form.get('receiveEmailRecapAddress');
            if (entiteJuridiqueSettingsData.receiveEmailRecap) {
                recapEmail.enable();
            } else {
                recapEmail.disable();
            }
        });

        if (this.disabled) {
            this.form.disable();
        }

        // if (!this.paymentEnabled) {
        //     this.form.get('emergencyAppointmentPrice').disable();
        // }

        this.isNotificationEnabled = Notification.permission === 'granted';

        this.oneSignalService.areNotificationsEnabled.subscribe(status => {
            this.isNotificationEnabled = status;
        });

        if (this.isDev) {
            this.form.get('receiveEmailRecap').valueChanges.subscribe((val: boolean) => {
                const emailRecap = this.form.get('receiveEmailRecapAddress');
                if (val) {
                    emailRecap.enable();
                } else {
                    emailRecap.disable();
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.subscriptionEntityData?.unsubscribe();
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save();
            }
        }
    }

    enableNotifications(): void {
        this.oneSignalService.subscribe();
    }

    private save() {
        this.formInProgress$.next(true);
        this.entiteJuridiqueService.updateEntiteJuridiqueSettings(this.form.getRawValue()).subscribe({
            next: entiteJuridique => {
                this.form.markAsPristine();
                this.formInProgress$.next(false);
                this.entityReceived$.next(entiteJuridique);
            },
            error: () => this.formInProgress$.next(false)
        });
    }

    regenerateCodeClinic(): void {
        const clientSignupCode = this.form.get('clientSignupCode').value;

        const dialog = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant(clientSignupCode ? 'SETTINGS.ONBOARDING_CLIENT.REGENERATE_DIALOG.TITLE' : 'SETTINGS.ONBOARDING_CLIENT.GENERATE_DIALOG.TITLE'),
                content: this.translateService.instant(clientSignupCode ? 'SETTINGS.ONBOARDING_CLIENT.REGENERATE_DIALOG.CONTENT' : 'SETTINGS.ONBOARDING_CLIENT.GENERATE_DIALOG.CONTENT'),
                action: true,
                cancel: this.translateService.instant('SHARED.CANCEL'),
                ok: this.translateService.instant(clientSignupCode ? 'SETTINGS.ONBOARDING_CLIENT.REGENERATE_DIALOG.OK' : 'SETTINGS.ONBOARDING_CLIENT.GENERATE_DIALOG.OK')
            },
            disableClose: true
        });

        dialog.afterClosed().subscribe(res => {
            if (res) {
                this.clinicCodeRegenerating = true;
                this.entiteJuridiqueService.regenerateClientSignupCode(this.form.get('id').value).subscribe({
                    next: (ej: EntiteJuridique) => {
                        this.form.get('clientSignupCode').setValue(ej.clientSignupCode);

                        this.clinicCodeRegenerating = false;
                    },
                    error: () => {
                        this.clinicCodeRegenerating = false;
                    }
                });
            }
        });
    }

    deleteCodeClinic(): void {
        const dialog = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SETTINGS.ONBOARDING_CLIENT.DELETE_DIALOG.TITLE'),
                content: this.translateService.instant('SETTINGS.ONBOARDING_CLIENT.DELETE_DIALOG.CONTENT'),
                action: true,
                cancel: this.translateService.instant('SHARED.CANCEL'),
                ok: this.translateService.instant('SETTINGS.ONBOARDING_CLIENT.DELETE_DIALOG.OK')
            },
            disableClose: true
        });

        dialog.afterClosed().subscribe(res => {
            if (res) {
                this.clinicCodeDeleting = true;
                this.entiteJuridiqueService.deleteClientSignupCode(this.form.get('id').value).subscribe({
                    next: (ej: EntiteJuridique) => {
                        this.form.get('clientSignupCode').setValue(ej.clientSignupCode);

                        this.clinicCodeDeleting = false;
                    },
                    error: () => {
                        this.clinicCodeDeleting = false;
                    }
                });
            }
        });
    }

    get signUpLink(): Observable<string> {
        return this.utilisateurService.utilisateurConnected.pipe(
            map(u => environment.signUpClientUrl + u.entiteJuridique.clientSignupCode)
        );
    }

    copyLinkToClipboard(): void {
        this.signUpLink.subscribe(link => {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = link;
            document.body.append(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            selBox.remove();

            this.snackbar.open(this.translateService.instant('CLIENT.SIGNUP_LINK.SNACKBAR_MESSAGE'), null, { duration: 3000 });
        });
    }

    goToClinics(): void {
        void this.router.navigate(['clinics']);
    }
}

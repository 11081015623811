<div class="page-layout simple fullwidth p-16">
    <div class="center">
        <div class="header secondary-text"
                fxLayout="column" fxLayoutAlign="start center"
                fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between start">
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <button mat-stroked-button routerLink="/" *ngIf="deactivatedAccount">
                    <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK' | translate }}
                </button>
                <mat-icon class="logo-icon">receipt</mat-icon>
                <span class="logo-text h1">{{ 'INVOICES.TITLE' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row"
                            fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="search" matInput #filter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <!-- CONTENT CARD -->
        <div class="content-card">
            <mat-table class="entites-geographique-table" #table [dataSource]="dataSource" [class.hidden]="(dataSource.loading$ | async) || (dataSource.isEmpty() | async)">
                <ng-container cdkColumnDef="number">
                    <mat-header-cell *cdkHeaderCellDef>
                        {{ 'INVOICES.TABLE.HEADERS.NUMBER' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let invoice">
                        <div>
                            {{ invoice.localNumber ? invoice.localNumber : ('INVOICES.NUMBER_NOT_AVAILABLE' | translate) }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="date">
                    <mat-header-cell *cdkHeaderCellDef>
                        {{ 'INVOICES.TABLE.HEADERS.DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let invoice">
                        <div>
                            {{ invoice.periodEnd | dateLocale:'longDate' }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="total">
                    <mat-header-cell *cdkHeaderCellDef>
                        {{ 'INVOICES.TABLE.HEADERS.TOTAL' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let invoice">
                        <div>
                            <span *ngIf="invoice.subtotalPrice && invoice.subtotalPrice !== invoice.totalPrice">
                                <span style="text-decoration: line-through;">{{ (invoice.subtotalPrice / 100) | number:'1.0-2' }}&nbsp;€</span>&nbsp;→&nbsp;
                            </span>
                            {{ (invoice.totalPrice / 100) | number:'1.0-2' }}&nbsp;€
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="status">
                    <mat-header-cell *cdkHeaderCellDef>
                        {{ 'INVOICES.TABLE.HEADERS.STATUS' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let invoice">
                        <div>
                            <span [ngSwitch]="invoice.status">
                                <span *ngSwitchCase="'draft'" class="badge orange mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_DRAFT' | translate }}</span>
                                <span *ngSwitchCase="'open'" class="badge orange mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_OPEN' | translate }}</span>
                                <span *ngSwitchCase="'paid'" class="badge green mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_PAID' | translate }}</span>
                                <span *ngSwitchCase="'void'" class="badge red mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_VOID' | translate }}</span>
                                <span *ngSwitchCase="'uncollectible'" class="badge red mr-4">{{ 'SUBSCRIPTION.INVOICES_STATUS_UNCOLLECTIBLE' | translate }}</span>
                            </span>
                            <span *ngIf="invoice?.discount?.coupon" class="badge">{{ 'SUBSCRIPTION.INVOICES_COUPON' | translate }}</span>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="action">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let invoice">
                        <div fxLayout="row" fxLayoutAlign="end center" class="w-100-p">
                            <a *ngIf="invoice.status == 'paid' || invoice.status == 'open'" (click)="downloadInvoice(invoice)" class="download-invoice">{{ 'SUBSCRIPTION.DOWNLOAD' | translate }}</a>
                        </div>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource.loading$ | async; else loaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="dataSource.isEmpty() | async" class="empty-state" fxLayout="column"
                     fxLayoutAlign="center center" fxLayoutGap="10px">

                    <img src="/assets/images/empty-state-file.svg"/>

                    <span class="app-title my-16" *fuseIfOnDom>{{ 'INVOICES.ANY' | translate }}</span>

                    <span class="app-message">{{ 'INVOICES.ANY_SPEECH' | translate }}</span>
                </div>
            </ng-template>

            <mat-paginator #paginator [fxHide]="dataSource.isEmpty() | async"
                        [length]="dataSource?.totalCount$ | async"
                        [pageIndex]="0"
                        [pageSize]="20"
                        [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <span class="title dialog-title">{{ 'VISIO.DIALOG_CHARGE.TITLE' | translate }}</span>
                <mat-spinner *ngIf="loading" mode="indeterminate" [diameter]="20" class="mr-8"></mat-spinner>
            </div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="column" fxLayoutAlign="start center">
        <img src="/assets/images/empty-state-card.svg"/>
        <p>{{ 'VISIO.DIALOG_CHARGE.SPEECH' | translate }}</p>
        <div class="text-big">{{ 'VISIO.DIALOG_CHARGE.RDV_PRICE' | translate }}</div>
        <div class="text-bigger">{{ data.prix | currencyLocale:data.currency }}</div>
        <div [ngSwitch]="state" class="mt-16">
            <div *ngSwitchCase="'sendingSignal'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-spinner mode="indeterminate" [diameter]="20" class="mr-8"></mat-spinner>
                    <span>{{ 'VISIO.DIALOG_CHARGE.SENDING_SIGNAL' | translate }}</span>
                </div>
            </div>
            <div *ngSwitchCase="'signalSent'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-spinner mode="indeterminate" [diameter]="20" class="mr-8"></mat-spinner>
                    <span>{{ 'VISIO.DIALOG_CHARGE.SIGNAL_SENT' | translate }}</span>
                </div>
            </div>
            <div *ngSwitchCase="'clientCallback'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <mat-spinner mode="indeterminate" [diameter]="20" class="mr-8"></mat-spinner>
                    <span>{{ 'VISIO.DIALOG_CHARGE.CLIENT_CALLBACK' | translate }}</span>
                </div>
            </div>
            <div *ngSwitchCase="'apiChecked'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>{{ 'VISIO.DIALOG_CHARGE.API_CHECKED' | translate }}</span>
                </div>
            </div>
            <div *ngSwitchCase="'stillUnpaid'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>{{ 'VISIO.DIALOG_CHARGE.STILL_UNPAID' | translate }}</span>
                </div>
            </div>
            <div *ngSwitchCase="'error'">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <span>{{ 'VISIO.DIALOG_CHARGE.ERROR' | translate }}</span>
                </div>
            </div>
        </div>

        <div *ngIf="state != 'apiChecked'" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" fxFlex="1 1 auto" class="mt-24">
          <div fxLayout="row" fxLayoutAlign="start center">
              <button mat-button color="warn"
                      (click)="close()"
                      [disabled]="state != 'askingVet' && state != 'stillUnpaid' && state != 'error'">
              <mat-icon>cancel</mat-icon> {{ 'SHARED.CANCEL' | translate }}
            </button>
          </div>

            <button mat-raised-button color="accent"
                    (click)="onAskCharge.emit()"
            [disabled]="state != 'askingVet' && state != 'stillUnpaid' && state != 'error'">
                <mat-icon>payment</mat-icon>
                {{ (state == 'askingVet' ? 'VISIO.DIALOG_CHARGE.ASK_CLIENT_TO_PAY_BUTTON' : 'VISIO.DIALOG_CHARGE.RETRY_BUTTON') | translate }}
          </button>
        </div>
        <div *ngIf="state == 'apiChecked'" fxLayout="row wrap" fxLayoutAlign="center center" fxFlex="1 1 auto"
             class="mt-24">
            <button mat-raised-button color="accent"
            (click)="close()">
            {{ 'VISIO.DIALOG_CHARGE.GO_TO_VISIO_BUTTON' | translate }}
          </button>
        </div>
    </div>
</div>

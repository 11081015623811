<div *ngIf="client.animaux?.length === 0" class="text-center primary-fg h2 m-8">
    {{ 'PET.ANY' | translate }}
</div>

<div class="animaux" *ngIf="client.animaux?.length > 0" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <app-profile-tile *ngFor="let animal of client.animaux" [id]="animal.id" [title]="animal.nom" class="cursor-pointer"
                      [entity]="animal" [actions]="actions" (click)="openAnimal(animal)">
        {{ animal?.espece?.nom }}
        <span *ngIf="animal?.race?.nom">&middot; {{ animal.race.nom }}</span>
        <span *ngIf="animal?.sexe">&middot; {{ ('PET_DESC.' + animal.sexeString) | translate }}</span>
        <app-connector-icon class="ml-4" [entity]="animal"></app-connector-icon>
    </app-profile-tile>
    <div class="add-button" fxLayout="row" fxLayoutAlign="center center" (click)="openAnimal()"
         [matTooltip]="'PET.NEW' | translate">
        <mat-icon>add</mat-icon>
    </div>
</div>

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ConfigService } from 'app/services/config.service';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { DevicePermission, VisioService, VisioStatut } from '../../../services/visio.service';
import { ChatService } from '../../../services/api/chat.service';
import { FuseSidebarService } from '../../../../@fuse/components/sidebar/sidebar.service';
import { Chat } from '../../../models/animal/chat';
import { filter, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    VisioChargeStatut,
    VisioPaymentChargeDialogComponent,
    VisioPaymentChargeDialogData
} from './visio-payment-charge-dialog/visio-payment-charge-dialog.component';
import { OpentokService } from 'app/services/opentok.service';
import { DialogComponent } from '../../shared/view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { WaitingRoomService } from '../../../services/api/waiting-room.service';
import { TimeDurationPipe } from '../../../pipes/time-duration.pipe';
import { Location } from '@angular/common';

import { NoteService } from '../../../services/api/note.service';
import { Note } from '../../../models/rendez-vous/note';
import { Utils } from 'app/utils';

@Component({
    selector: 'app-visio',
    templateUrl: './visio.component.html',
    styleUrls: ['./visio.component.scss'],
    providers: [VisioService, OpentokService]
})
export class VisioComponent implements OnInit, OnDestroy {
    rdvId: number;
    rendezVous: RendezVous;

    chatLoading = true;
    chatSelected: number = null;
    chatObjectSelected: Chat = null;
    chats: Chat[] = null;

    audio: any = null;

    loading = true;
    // generateInProgress: boolean;
    // rapport: Fichier;
    askRefresh = false;
    note: Note = new Note();

    private wasSideBarFolded = false;
    private chatsSubscription: Subscription;
    private statutSubscription: Subscription;
    private rendezVousSubscription: Subscription;
    private subcriptions: Subscription[] = [];
    private isChargeModalOpen = false;

    constructor(
        public dialog: MatDialog,
        public visioService: VisioService,
        public config: ConfigService,
        private chatService: ChatService,
        private snackbar: MatSnackBar,
        private rendezVousService: RendezVousService,
        private fuseSidebarService: FuseSidebarService,
        private router: Router,
        private route: ActivatedRoute,
        private translateService: TranslateService,
        private waitingRoomService: WaitingRoomService,
        private location: Location,
        private noteService: NoteService
    ) {}

    ngOnInit(): void {
        setTimeout(() => {
            const sideBar = this.fuseSidebarService.getSidebar('navbar');
            this.wasSideBarFolded = sideBar.folded;
            sideBar.fold();
        }, 0);

        this.route.params.subscribe(params => {
            this.rdvId = Number(params.rdvId);

            this.noteService.getNoteByRendezVousId(this.rdvId).subscribe(
                note => {
                    if (note) {
                        this.note = note;
                        this.note.isModified = false;
                    }
                }
            );

            this.rendezVousSubscription = this.rendezVousService.getById(this.rdvId).subscribe({
                next: (rendezVous: RendezVous) => {
                    if (rendezVous) {
                        this.rendezVous = rendezVous;
                        this.note.rendezVous = this.rendezVous;
                        this.loading = false;
                        this.visioService.setCurrentRendezVous(this.rendezVous);
                        this.statutSubscription = this.visioService.currentStatut.subscribe((statut: VisioStatut) => {
                            if (statut === VisioStatut.ongoing) {
                                if (this.rendezVous.mustPay) {
                                    this.makeCharge();
                                }
                            } else if (statut === VisioStatut.finished) {
                                this.rendezVousService.getById(this.visioService.currentRendezVousObject.id).subscribe((r: RendezVous) => {
                                    if (r.mustCapture) {
                                        return this.rendezVousService.openDialogCaptureRendezVous(r);
                                    }
                                });
                            }
                        });

                        this.chatsSubscription = this.chatService.chats.pipe(filter(c => Boolean(c))).subscribe((cs: Chat[]) => {
                            this.chats = cs
                                .filter(c => c.animal.id === this.rendezVous.animal.id)
                                .sort((a, b) => a.dateDebut < b.dateDebut ? -1 : 1);

                            if (this.chats.length > 0 && !this.chatObjectSelected) {
                                this.chatChanged(0);
                            }

                            this.chatLoading = false;
                        });
                    } else {
                        this.snackbar.open(this.translateService.instant('VISIO.NOT_FOUND'), this.translateService.instant('SHARED.OK'), { duration: 3000 });
                        void this.router.navigate(['/']);
                    }
                },
                error: () => {
                    void this.router.navigate(['/']);
                }
            });
        });

        this.subcriptions.push(
            this.visioService.permissionsStatus.subscribe((devicePermission: DevicePermission) => {
                switch (devicePermission) {
                    case DevicePermission.NoDevicesFound:
                        this.dialog.open(DialogComponent, {
                            maxWidth: '500px',
                            data: {
                                title: this.translateService.instant('VISIO.DIALOG_NO_DEVICE.TITLE'),
                                content: this.translateService.instant('VISIO.DIALOG_NO_DEVICE.CONTENT'),
                                action: true,
                                cancel: this.translateService.instant('SHARED.RETURN'),
                                ok: this.translateService.instant('SHARED.REFRESH')
                            },
                            disableClose: true
                        }).afterClosed().subscribe(res => {
                            if (res) {
                                this.askRefresh = true;
                                document.location.reload();
                            } else {
                                this.location.back();
                            }
                        });
                        break;
                    case DevicePermission.NoPermission:
                        if (!environment.hmr) { // pas de https
                            this.dialog.open(DialogComponent, {
                                maxWidth: '500px',
                                data: {
                                    title: this.translateService.instant('VISIO.DIALOG_NO_PERMISSION.TITLE'),
                                    content: this.translateService.instant('VISIO.DIALOG_NO_PERMISSION.CONTENT'),
                                    action: true,
                                    cancel: this.translateService.instant('SHARED.RETURN'),
                                    ok: this.translateService.instant('SHARED.REFRESH')
                                },
                                disableClose: true
                            }).afterClosed().subscribe(res => {
                                if (res) {
                                    this.askRefresh = true;
                                    document.location.reload();
                                } else {
                                    this.location.back();
                                }
                            });
                        }

                        break;
                    default:
                        break;
                }
            })
        );
    }

    ngOnDestroy(): void {
        if (this.audio) {
            this.audio.pause();
        }

        if (this.chatsSubscription) {
            this.chatsSubscription.unsubscribe();
        }

        if (this.statutSubscription) {
            this.statutSubscription.unsubscribe();
        }

        if (this.rendezVousSubscription) {
            this.rendezVousSubscription.unsubscribe();
        }

        if (this.subcriptions) {
            this.subcriptions.forEach((subscription: Subscription) => {
                subscription.unsubscribe();
            });
        }

        try {
            if (!this.wasSideBarFolded) {
                this.fuseSidebarService.getSidebar('navbar').unfold();
            }
        } catch {
            // ignored
        }

        this.visioService.clear();
    }

    call(): void {
        this.visioService.connectAndCall();
    }

    recall(): void {
        this.visioService.recall();
    }

    chatChanged(chatIndex: number): void {
        const chat = this.chats[chatIndex];
        if (chat && this.chatSelected !== chatIndex) {
            this.chatSelected = chatIndex;
            this.chatService.setChannelSelectedByChatId(chat.id).subscribe(() => {
                this.chatObjectSelected = chat;
            });
        }
    }

    /*
    generatePDF(note: string): void {
        if (!this.generateInProgress) {
            this.generateInProgress = true;
            this.rendezVousService.generatePDF(this.rendezVous.id, note).subscribe((pdf: Fichier) => {
                this.generateInProgress = false;
                this.rapport = pdf;
            }, () => {
                this.generateInProgress = false;
            });
        }
    }
    */

    // TODO: La génération du rapport doit mettre fin à la visio.
    // Implémenter la confirmation
    yieldReport(): void {
        if (this.note.isModified) {
            // ne devrait jamais être exécuté (bouton inactivé dans ce cas)
            this.snackbar.open('Sauvegardez vos notes.', null, { duration: 1500 });
        } else {
            void this.router.navigate(['/report', this.rdvId]);
        }
    }

    saveNotes(): void {
        this.snackbar.open('Sauvegarde en cours.', null, { duration: 1500 });
        this.noteService.saveNote(this.note.hasPost()).subscribe({
            next: data => {
                if (data) {
                    this.snackbar.open('Notes sauvegardées', this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    this.note.isModified = false;
                } else {
                    this.snackbar.open(this.translateService.instant('SHARED.ERROR'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }
            }
        });
    }

    modifyNote(): void {
        this.note.isModified = true;
    }

    isClientHere(): Observable<boolean> {
        return this.waitingRoomService.clientWaitings.pipe(
            map(cw => Boolean(cw?.find(c => c.rendezVous.id === this.rdvId)))
        );
    }

    getClientWaitingTime(): string {
        const cw = this.waitingRoomService.clientWaitings.value.find(c => c.rendezVous.id === this.rdvId);
        if (cw?.rendezVous?.waitingRoomEnterDate) {
            const date = cw.rendezVous.waitingRoomEnterDate;
            let diff = Math.abs((Number(date) - Date.now()) / 1000);
            if (diff < 60) {
                diff = 60;
            }

            return new TimeDurationPipe(this.translateService).transform(diff, false);
        }

        return this.translateService.instant('SHARED.UNKNOWN_DURATION') as string;
    }

    getElapsedTime(date: Date): string {
        return Utils.getElapsedTime(date, this.translateService);
    }

    hangOut(): void {
        if (this.visioService.currentStatutObject === 'loading') {
            return;
        }

        if (this.visioService.currentStatutObject === 'ringing') {
            this.visioService.hangout();
        } else {
            const dialog = this.dialog.open(DialogComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('VISIO.FINISH_SESSION'),
                    content: this.translateService.instant('VISIO.ASK_FINISH_CONV'),
                    action: true,
                    cancel: this.translateService.instant('SHARED.CANCEL'),
                    ok: this.translateService.instant('VISIO.FINISH')
                },
                disableClose: true
            });

            dialog.afterClosed().subscribe(res => {
                if (res) {
                    this.visioService.disconnect();
                }
            });
        }
    }

    private makeCharge(): void {
        if (this.isChargeModalOpen) {
            return;
        }

        this.isChargeModalOpen = true;
        const data: VisioPaymentChargeDialogData = {
            rdvId: this.rendezVous.id,
            prix: this.rendezVous.prix,
            currency: this.rendezVous.currency
        };
        const dialogRef = this.dialog.open(VisioPaymentChargeDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '30vw',
            disableClose: true
        });

        dialogRef.componentInstance.onAskCharge.subscribe(() => {
            dialogRef.componentInstance.state = VisioChargeStatut.sendingSignal;
            this.visioService.currentSessionObject.signal(
                {
                    type: 'payment'
                },
                error => {
                    if (error) {
                        dialogRef.componentInstance.state = VisioChargeStatut.error;
                    } else {
                        dialogRef.componentInstance.state = VisioChargeStatut.signalSent;
                        this.visioService.currentSessionObject.on('signal:paymentCallback' as any, () => {
                            this.visioService.currentSessionObject.off('signal:paymentCallback' as any);
                            dialogRef.componentInstance.state = VisioChargeStatut.clientCallback;
                            this.rendezVousService.getById(this.rendezVous.id).subscribe((rendezVous: RendezVous) => {
                                if (rendezVous) {
                                    this.visioService.setCurrentRendezVous(rendezVous);
                                    if (rendezVous.mustPay) {
                                        dialogRef.componentInstance.state = VisioChargeStatut.stillUnpaid;
                                    } else {
                                        dialogRef.componentInstance.state = VisioChargeStatut.apiChecked;
                                    }
                                } else {
                                    dialogRef.componentInstance.state = VisioChargeStatut.error;
                                }
                            });
                        });
                    }
                }
            );
        });

        dialogRef.afterClosed().subscribe(() => {
            this.isChargeModalOpen = false;
        });
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: Event): void {
        if (!this.askRefresh) {
            $event.returnValue = true;
        }
    }
}

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MediProdMedia, MediProdService } from '../../../../services/api/mediprod.service';
import { debounceTime, map, skip, take } from 'rxjs/operators';
import { Observable, Subscription, zip, BehaviorSubject } from 'rxjs';
import { Utils } from 'app/utils';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DialogComponent } from '../dialog/dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'environments/environment';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-visiocare-dialog',
    templateUrl: './visiocare-dialog.component.html',
    styleUrls: ['./visiocare-dialog.component.scss']
})
export class VisiocareDialogComponent implements OnInit, OnDestroy {
    loading$ = new BehaviorSubject<boolean>(true);
    loadingMedia$ = new BehaviorSubject<boolean>(false);
    error$ = new BehaviorSubject<boolean>(false);

    items = new BehaviorSubject<MediProdMedia[]>([]);

    selectedKeywords: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    filteredKeywords: Observable<string[]>;
    allKeywords: string[] = [];
    keywordsCtrl = new FormControl();
    @ViewChild('itemsContainer') itemsContainer: ElementRef;
    @ViewChild('keywordsInput') keywordsInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    private subscriptions: Subscription[] = [];

    get shopUrl(): string {
        const locale = this.utilisateurService.utilisateurConnectedValue.simpleLocale;
        const userId = `${this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id}`;
        let lang = 'fr/';
        if (locale !== 'fr') {
            lang = '';
        }

        return environment.mediprod.shopUrl + lang + 'linkyvet/store?id=' + userId;
    }

    constructor(
        public dialogRef: MatDialogRef<VisiocareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VisiocareDialogComponentData,
        private mediProdService: MediProdService,
        private dialog: MatDialog,
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private sanitizer: DomSanitizer,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.filteredKeywords = this.keywordsCtrl.valueChanges.pipe(
            map((keyword: string | null) => this._filter(keyword))
        );

        this.mediProdService.signin.pipe(take(1)).subscribe({
            next: () => {
                zip(this.mediProdService.keywords, this.mediProdService.search())
                    .pipe(take(1))
                    .subscribe({
                        next: ([keywords, rawMedias]) => {
                            this.allKeywords = [...keywords];
                            this.items.next(this.organizeItems(rawMedias as any));
                            this.loading$.next(false);
                        },
                        error: () => {
                            this.error$.next(true);
                        }
                    });
            },
            error: () => {
                this.error$.next(true);
            }
        });

        this.selectedKeywords
            .pipe(
                skip(1),
                debounceTime(500)
            )
            .subscribe(keywords => {
                this.loadingMedia$.next(true);
                this.mediProdService.search(keywords).pipe(take(1)).subscribe({
                    next: rawMedias => {
                        this.items.next(this.organizeItems(rawMedias as any));
                        this.loadingMedia$.next(false);

                        const itemsContainerNativeElement = this.itemsContainer.nativeElement;
                        if (itemsContainerNativeElement) {
                            itemsContainerNativeElement.scrollTop = 0;
                        }
                    },
                    error: () => {
                        this.loadingMedia$.next(false);
                    }
                });
            });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }

    keywordRemove(keyword: string): void {
        const selectedKeywords = this.selectedKeywords.value;
        const index = selectedKeywords.indexOf(keyword);

        if (index >= 0) {
            selectedKeywords.splice(index, 1);
            this.selectedKeywords.next(selectedKeywords);
        }
    }

    keywordSelected(event: MatAutocompleteSelectedEvent): void {
        const selectedKeywords = this.selectedKeywords.value;
        const keyword = event.option.viewValue;
        const index = selectedKeywords.indexOf(keyword);
        if (index === -1) {
            selectedKeywords.push(keyword);
            this.selectedKeywords.next(selectedKeywords);
            this.keywordsInput.nativeElement.value = '';
            this.keywordsCtrl.setValue(null);
        }
    }

    cancel(): void {
        this.dialogRef.close();
    }

    getIcon(item: MediProdMedia): string {
        switch (item.media_type) {
            case 'A3D':
            case 'ECH':
            case 'FLM':
                return 'movie_creation';
            case 'IMG':
            case 'RAD':
            case 'SCN':
                return 'photo';
            case 'FS':
                return 'text_snippet';
            default:
                return 'insert_drive_file';
        }
    }

    canSee(item: MediProdMedia): boolean {
        return this.mediProdService.currentUser?.demo ?? true ? item.free : true;
    }

    canSend(): boolean {
        return !(this.mediProdService.currentUser?.demo ?? true);
    }

    seeItem(item: MediProdMedia): void {
        this.mediProdService.mediaPlayer(item).pipe(take(1)).subscribe(data => {
            this.dialog.open(DialogComponent, {
                data: {
                    title: item.name,
                    content: this.sanitizer.bypassSecurityTrustHtml(data),
                    cancelButton: true,
                    ok: this.data?.for === 'visio' ? this.translateService.instant('VISIOCARE.SEND_VISIO') : this.translateService.instant('VISIOCARE.SEND_CHAT')
                }
            }).afterClosed().subscribe(send => {
                if (send) {
                    this.sendItem(item);
                }
            });
        });
    }

    sendItem(item: MediProdMedia): void {
        let type: string;
        switch (item.media_type) {
            case 'A3D':
            case 'ECH':
            case 'FLM':
                type = 'video';
                break;
            case 'IMG':
            case 'RAD':
            case 'SCN':
                type = 'image';
                break;
            case 'FS':
                type = 'document';
                break;
            default:
                type = 'other';
        }

        if (this.data?.for === 'visio') {
            if (type !== 'video' && type !== 'image') {
                throw new Error('Unsupported type');
            }

            this.mediProdService.mediaLinkRaw(item)
                .pipe(
                    take(1),
                    map(link => link.replace(/^http:\/\//i, 'https://'))
                )
                .subscribe(link => {
                    this.dialogRef.close({
                        link,
                        type
                    });
                });
        } else {
            this.mediProdService.mediaLink(item)
                .pipe(
                    take(1),
                    map(link => link.replace(/^http:\/\//i, 'https://'))
                )
                .subscribe(link => {
                    this.dialogRef.close({
                        link,
                        type
                    });
                });
        }
    }

    private _filter(value: string): string[] {
        const filterValue = Utils.removeDiacritics(value?.toLowerCase());
        return this.allKeywords.filter(k => Utils.removeDiacritics(k.toLowerCase()).startsWith(filterValue) && !this.selectedKeywords.value.includes(k));
    }

    private organizeItems(items: MediProdMedia[]): MediProdMedia[] {
        for (let index = items.length - 1; index >= 0; index--) {
            if (items[index]?.data?.length > 0) {
                this.organizeItems(items[index]?.data as any);
            }

            if (this.data?.for === 'visio' && items[index].type === 'media' && !['A3D', 'ECH', 'FLM', 'IMG', 'RAD', 'SCN'].includes(items[index]?.media_type?.toUpperCase())) {
                items.splice(index, 1);
                continue;
            }

            if (items[index]?.data?.length === 0) {
                items.splice(index, 1);
                continue;
            }

            if (items[index]?.data?.length === 1 && items[index].data[0].type !== 'media') {
                const newName = items[index].name + ' > ' + items[index].data[0].name;
                items[index] = items[index].data[0] as MediProdMedia;
                items[index].name = newName;
            }

            if (items[index]?.data?.length > 0) {
                // Un jour on va me gronder pour ca
                (items[index] as any).count = this.countItems(items[index].data as any);
            }
        }

        return items;
    }

    public get totalCountItems(): Observable<number> {
        return this.items.pipe(
            map(items => {
                return this.countItems(items);
            })
        );
    }

    countItems(items: MediProdMedia[]): number {
        return (items?.filter(i => i?.type === 'media')?.length ?? 0) + items?.map(is => is?.data ? this.countItems(is.data as any) : 0)?.reduce((sum, current) => sum + current, 0);
    }
}

export interface VisiocareDialogComponentData {
    for: 'visio' | 'chat';
}

<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-20">
    <h1 mat-dialog-title class="m-0">{{ 'UTILISATEUR.VETERINARY_REASSIGN_DIALOG.TITLE' | translate }}</h1>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>{{ 'UTILISATEUR.VETERINARY_REASSIGN_DIALOG.HELP' | translate }}</p>

  <app-input-veterinaire [control]="newUserControl"></app-input-veterinaire>
</div>

<div mat-dialog-actions fxLayoutAlign="flex-end center">
    <button mat-button [mat-dialog-close]="false">
        {{ 'SHARED.CANCEL' | translate }}
    </button>

    <button mat-button autofocus color="primary" [disabled]="newUserControl.invalid" (click)="closeOk()">
        {{ 'UTILISATEUR.VETERINARY_REASSIGN_DIALOG.OK' | translate }}
    </button>
</div>


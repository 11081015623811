import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../services/config.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RendezVousListPeriod } from 'app/main/shared/rendez-vous-list-card/rendez-vous-list-card.component';

@Component({
    selector: 'app-prochains-rendez-vous',
    templateUrl: './prochains-rendez-vous.component.html',
    styleUrls: ['./prochains-rendez-vous.component.scss']
})
export class ProchainsRendezVousComponent {
    @Input() selectedIndex = 0;

    RendezVousListPeriod = RendezVousListPeriod;

    private waitingRoomEnabled = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public configService: ConfigService
    ) {
        this.configService.getWaitingRoomEnabled().subscribe(waitingRoomEnabled => {
            this.waitingRoomEnabled = waitingRoomEnabled;

            this.route.fragment.subscribe(fragment => {
                if (this.waitingRoomEnabled) {
                    switch (fragment) {
                        case 'waiting':
                            this.selectedIndex = 0;
                            break;
                        case 'next':
                            this.selectedIndex = 1;
                            break;
                        case 'previous':
                            this.selectedIndex = 2;
                            break;
                        case 'canceled':
                            this.selectedIndex = 3;
                            break;
                        default:
                            void this.router.navigate(['/appointments'], { fragment: 'waiting' });
                            break;
                    }
                } else {
                    switch (fragment) {
                        case 'next':
                            this.selectedIndex = 0;
                            break;
                        case 'previous':
                            this.selectedIndex = 1;
                            break;
                        case 'canceled':
                            this.selectedIndex = 2;
                            break;
                        default:
                            void this.router.navigate(['/appointments'], { fragment: 'next' });
                            break;
                    }
                }
            });
        });
    }

    onSelectedTabChanged(event: MatTabChangeEvent): void {
        if (this.waitingRoomEnabled) {
            switch (event.index) {
                case 0:
                    void this.router.navigate(['/appointments'], { fragment: 'waiting' });
                    break;
                case 1:
                    void this.router.navigate(['/appointments'], { fragment: 'next' });
                    break;
                case 2:
                    void this.router.navigate(['/appointments'], { fragment: 'previous' });
                    break;
                case 3:
                    void this.router.navigate(['/appointments'], { fragment: 'canceled' });
                    break;
                default:
                    break;
            }
        } else {
            switch (event.index) {
                case 0:
                    void this.router.navigate(['/appointments'], { fragment: 'next' });
                    break;
                case 1:
                    void this.router.navigate(['/appointments'], { fragment: 'previous' });
                    break;
                case 2:
                    void this.router.navigate(['/appointments'], { fragment: 'canceled' });
                    break;
                default:
                    break;
            }
        }
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'CHAT.DIALOG_DELAYED_MESSAGES.TITLE' | translate }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="isLoading">
            <app-loading></app-loading>
        </div>
        <div *ngIf="!isLoading">
            <mat-list *ngIf="delayedMessages.length > 0; else empty">
                <div mat-list-item *ngFor="let delayedMessage of delayedMessages" fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                    <div fxFlex="1 1" [matTooltip]="delayedMessage.message.length > 20 ? delayedMessage.message : null">{{ delayedMessage.message | truncate:20:'...':true }}</div>
                    <div class="time">{{ delayedMessage.date | dateLocale:(((delayedMessage.date | dateIsCurrentYear) ? 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_YEAR_WITHOUT_SECOND' : 'CUSTOM_DATE_FORMATTER.MEDIUM_DATETIME_WITH_DAY_WITHOUT_SECOND') | translate) }}</div>
                    <mat-icon *ngIf="delayedMessage.attachment">attach_file</mat-icon>
                    <button mat-icon-button color="accent" (click)="updateDelayedMessage(delayedMessage)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="deleteDelayedMessage(delayedMessage)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </mat-list>
            <ng-template #empty>
                <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                    <img src="/assets/images/empty-state-chat.svg"/>
                    <span class="app-message my-16">{{ 'CHAT.DIALOG_DELAYED_MESSAGES.EMPTY_STATE_MESSAGE' | translate }}</span>
                </div>
            </ng-template>
        </div>
    </div>
</div>

<div fxLayout="column">
    <div class="step-header">
        <img class="icon" src=" ../../../../assets/images/icons/onboarding-icon-mail.svg" alt="Mail Icon" fxFlex="0 0"/>
        <h1 [innerHTML]="'ONBOARDING_PRO.STEPS.WELCOME.TITLE' | translate"></h1>
        <p [innerHTML]="'ONBOARDING_PRO.STEPS.WELCOME.SUBTITLE' | translate:{ mail: mail }"></p>

        <button type="button" mat-stroked-button class="back-button" (click)="reSendMail()" [disabled]="reSendMailDisabled">
            {{ 'ONBOARDING_PRO.RESEND_MAIL' | translate }}
        </button>
    </div>
</div>

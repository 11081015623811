<mat-tab-group fxFlex="1 1 100%" [selectedIndex]="selectedIndex" (selectedTabChange)="onSelectedTabChanged($event)" animationDuration="0ms">
    <mat-tab #published>
        <ng-template mat-tab-label>
            <mat-icon>public</mat-icon>
            <span class="ml-8">{{ 'NEWS.TYPE.PUBLISHED' | translate }}</span>
        </ng-template>
    </mat-tab>
    <mat-tab #planned>
        <ng-template mat-tab-label>
            <mat-icon>access_time</mat-icon>
            <span class="ml-8">{{ 'NEWS.TYPE.PLANNED' | translate }}</span>
        </ng-template>
    </mat-tab>
    <mat-tab #draft>
        <ng-template mat-tab-label>
            <mat-icon>insert_drive_file</mat-icon>
            <span class="ml-8">{{ 'NEWS.TYPE.DRAFT' | translate }}</span>
        </ng-template>
    </mat-tab>
    <mat-tab #archived>
        <ng-template mat-tab-label>
            <mat-icon>archive</mat-icon>
            <span class="ml-8">{{ 'NEWS.TYPE.ARCHIVED' | translate }}</span>
        </ng-template>
    </mat-tab>
    <mat-tab #segment>
        <ng-template mat-tab-label>
            <mat-icon>segment</mat-icon>
            <span class="ml-8">{{ 'NEWS_SEGMENTS.TITLE' | translate}}</span>
        </ng-template>
    </mat-tab>
</mat-tab-group>

<div class="page-layout simple fullwidth p-16" [style.display]="this.selectedIndex >= 4 ? 'none' : 'block'">
    <div class="center">
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between start">
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">feed</mat-icon>
                <span class="logo-text h1">{{ 'NAV.NEWS' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="search" matInput #newsFilter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <button mat-raised-button class="mt-12 mb-8" color="primary" (click)="showAddOrEditNewsModal()">
                        <mat-icon>add</mat-icon> {{ 'NEWS.NEW' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="content-card">
            <mat-table
                class="content-table"
                #newsTable
                #newsTableSort="matSort"
                matSort
                [dataSource]="newsDataSource"
                [trackBy]="trackByFn"
                [class.hidden]="(newsDataSource.loading$ | async) || (newsDataSource.isEmpty() | async)">
                <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="content-table-header">{{ 'NEWS.TABLE.NAME' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="cursor-pointer" (click)="showAddOrEditNewsModal(news)">
                            <p class="text-truncate" style="color:#28A8DD;font-weight:500;">{{ news.name }}</p>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="createdAt">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-date">
                        <span class="content-table-header">{{ 'NEWS.TABLE.CREATED_AT' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-date">
                        <p class="text-truncate">{{ news.createdAt | dateLocale:'short' }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="nbClients">
                    <mat-header-cell *cdkHeaderCellDef class="col-nb">
                        <mat-icon [matTooltip]="'NEWS.TABLE.NB_CLIENTS' | translate">supervisor_account</mat-icon>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-nb">
                        <p class="text-truncate">{{ news.nbClientsSegments }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="publishedDate">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-date">
                        <span class="content-table-header">{{ ( this.selectedIndex === 1 ? 'NEWS.TABLE.WILL_BE_PUBLISHED_AT' : 'NEWS.TABLE.PUBLISHED_AT') | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-date">
                        <p class="text-truncate"><span *ngIf="this.selectedIndex === 1 && news.publishedDate">&rarr; </span>{{ news.publishedDate ? (news.publishedDate | dateLocale:'short') : '-' }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="archivedDate">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-date">
                        <span class="content-table-header">{{ 'NEWS.TABLE.ARCHIVED_AT' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-date">
                        <p class="text-truncate">{{ news.archivedDate | dateLocale:'short' }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="views">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-nb">
                        <mat-icon [matTooltip]="'NEWS.TABLE.NB_VIEW' | translate">visibility</mat-icon>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-nb">
                        <p class="text-truncate">{{ news.nbView }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="clicks">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-nb">
                        <mat-icon [matTooltip]="'NEWS.TABLE.NB_CLICK' | translate">touch_app</mat-icon>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let news" class="col-nb">
                        <p class="text-truncate">{{ news.nbClick }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="actions">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let news">
                        <p>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="showAddOrEditNewsModal(news)">
                                    <mat-icon>info</mat-icon>
                                    <span>{{ 'SHARED.DETAILS' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="showDuplicateConfirmation(news)">
                                    <mat-icon>add</mat-icon>
                                    <span>{{ 'NEWS.ACTIONS.DUPLICATE' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="showArchiveConfirmation(news)" *ngIf="this.selectedIndex === 0">
                                    <mat-icon>archive</mat-icon>
                                    <span>{{ 'NEWS.ACTIONS.ARCHIVE' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="showDeleteConfirmation(news)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{ 'NEWS.ACTIONS.DELETE' | translate }}</span>
                                </button>
                            </mat-menu>
                            <button mat-button color="secondary-text" [matMenuTriggerFor]="actionMenu"
                                [matTooltip]="'SHARED.ACTIONS' | translate" class="more-button">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </p>
                    </mat-cell>
                </ng-container>
                <mat-header-row *cdkHeaderRowDef="newsDisplayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: newsDisplayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="newsDataSource.loading$ | async; else loaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="newsDataSource.isEmpty() | async" class="empty-state" fxLayout="column"
                    fxLayoutAlign="center center" fxLayoutGap="10px">
                    <img src="/assets/images/empty-state-client.svg" />
                    <span class="app-title my-16" *fuseIfOnDom>{{ 'NEWS.NONE' | translate }}</span>
                    <button mat-raised-button color="primary" (click)="showAddOrEditNewsModal()">
                        <mat-icon>add</mat-icon>
                        {{ 'NEWS.NEW' | translate }}
                    </button>
                </div>
            </ng-template>

            <mat-paginator
                #newsPaginator
                [fxHide]="newsDataSource.isEmpty() | async"
                [length]="newsDataSource?.totalCount$ | async"
                [pageIndex]="0"
                [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>
        </div>
    </div>
</div>

<div class="page-layout simple fullwidth p-16" [style.display]="this.selectedIndex < 4 ? 'none' : 'block'">
    <div class="center">
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between start">
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">segment</mat-icon>
                <span class="logo-text h1">{{ 'NEWS_SEGMENTS.TITLE' | translate }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                        <label for="searchSegment" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="searchSegment" matInput #newsSegmentFilter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <button mat-raised-button class="mt-12 mb-8" color="primary" (click)="showAddOrEditSegmentModal()">
                        <mat-icon>add</mat-icon> {{ 'NEWS_SEGMENTS.NEW' | translate }}
                    </button>
                </div>
            </div>
        </div>
        <div class="content-card">
            <mat-table
                class="content-table"
                #newsSegmentTable
                #newsSegmentSort="matSort"
                matSort
                [dataSource]="newsSegmentsDataSource"
                [trackBy]="trackByFn"
                [class.hidden]="(newsSegmentsDataSource.loading$ | async) || (newsSegmentsDataSource.isEmpty() | async)">
                <ng-container cdkColumnDef="name">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="content-table-header">{{ 'NEWS_SEGMENTS.TABLE.NAME' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let segment">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="cursor-pointer" (click)="showAddOrEditSegmentModal(segment)">
                            <p class="text-truncate" style="color:#28A8DD;font-weight:500;">{{ segment.name }}</p>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="nbFilters">
                    <mat-header-cell *cdkHeaderCellDef>
                        <span class="content-table-header">{{ 'NEWS_SEGMENTS.TABLE.NB_FILTERS' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let segment">
                        <p class="text-truncate">{{ segment.nbFilters }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="nbNews">
                    <mat-header-cell *cdkHeaderCellDef>
                        <span class="content-table-header">{{ 'NEWS_SEGMENTS.TABLE.NB_NEWS' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let segment">
                        <p class="text-truncate">{{ segment.nbNews }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="nbClients">
                    <mat-header-cell *cdkHeaderCellDef>
                        <span class="content-table-header">{{ 'NEWS_SEGMENTS.TABLE.NB_CLIENTS' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let segment">
                        <p class="text-truncate">{{ segment.nbClients }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="updatedAt">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="col-date">
                        <span class="content-table-header">{{ 'NEWS_SEGMENTS.TABLE.UPDATED_AT' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let segment" class="col-date">
                        <p class="text-truncate">{{ segment.updatedAt | dateLocale:'short' }}</p>
                    </mat-cell>
                </ng-container>
                <ng-container cdkColumnDef="actions">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let segment">
                        <p>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="showAddOrEditSegmentModal(segment)">
                                    <mat-icon>info</mat-icon>
                                    <span>{{ 'SHARED.DETAILS' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="showDuplicateSegmentConfirmation(segment)">
                                    <mat-icon>add</mat-icon>
                                    <span>{{ 'NEWS_SEGMENTS.ACTIONS.DUPLICATE' | translate }}</span>
                                </button>
                                <button mat-menu-item (click)="showDeleteSegmentConfirmation(segment)">
                                    <mat-icon>delete</mat-icon>
                                    <span>{{ 'NEWS_SEGMENTS.ACTIONS.DELETE' | translate }}</span>
                                </button>
                            </mat-menu>
                            <button mat-button color="secondary-text" [matMenuTriggerFor]="actionMenu"
                                [matTooltip]="'SHARED.ACTIONS' | translate" class="more-button">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </p>
                    </mat-cell>
                </ng-container>
                <mat-header-row *cdkHeaderRowDef="newsSegmentDisplayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: newsSegmentDisplayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="newsSegmentsDataSource.loading$ | async; else segmentLoaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <ng-template #segmentLoaded>
                <div *ngIf="newsSegmentsDataSource.isEmpty() | async" class="empty-state" fxLayout="column"
                    fxLayoutAlign="center center" fxLayoutGap="10px">
                    <img src="/assets/images/empty-state-client.svg" />
                    <span class="app-title my-16" *fuseIfOnDom>{{ 'NEWS_SEGMENTS.NONE' | translate }}</span>
                    <button mat-raised-button color="primary" (click)="showAddOrEditSegmentModal()">
                        <mat-icon>add</mat-icon>
                        {{ 'NEWS_SEGMENTS.NEW' | translate }}
                    </button>
                </div>
            </ng-template>

            <mat-paginator
                #newsSegmentPaginator
                [fxHide]="newsSegmentsDataSource.isEmpty() | async"
                [length]="newsSegmentsDataSource?.totalCount$ | async"
                [pageIndex]="0"
                [pageSize]="20"
                [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>
        </div>
    </div>
</div>

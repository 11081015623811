import { Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-table-card',
    templateUrl: './table-card.component.html',
    styleUrls: ['./table-card.component.scss'],
    animations: [fuseAnimations]
})
export class TableCardComponent {
    @Input() titleLabel: string;
    @Input() detail?: string;
    @Input() datas: any[];
    @Input() columns: string[];
    @Input() columnsName: string[];
    @Input() columnsIcon: string[] = [];
    @Input('loading') isLoading?: boolean;
    @ContentChild('rowActionsTemplate') rowActionsTemplate;

    @Input() action?: string;

    @Input() icon = 'open_in_new';
    @Input() color = 'light-green-700';

    @Input() tooltipFn: (data: any, translateService: TranslateService) => string;
    @Output('onActionClick') actionClicked$ = new EventEmitter<null>();

    constructor(public translateService: TranslateService) {}
}

<div fxLayout="column">
    <div class="step-header" fxLayout="column">
        <img class="icon" src=" ../../../../assets/images/icons/onboarding-icon-mail.svg" alt="Mail Icon" fxFlex="0 0"/>
        <h1 [innerHTML]="'ONBOARDING_CLIENT.STEPS.CLINIC.TITLE' | translate"></h1>
        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.CLINIC.SUBTITLE' | translate"></p>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'ONBOARDING_CLIENT.STEPS.CLINIC.CODE_PLACEHOLDER' | translate }}</mat-label>
            <input matInput [placeholder]="'ONBOARDING_CLIENT.STEPS.CLINIC.CODE_PLACEHOLDER' | translate" [formControl]="control | asFormControl" type="text" required>
            <mat-error *ngIf="control.hasError('required')">
                {{ 'ONBOARDING_CLIENT.STEPS.CLINIC.CODE_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>

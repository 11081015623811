import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ResetPassComponent } from './reset-password.component';
import { ViewSharedModule } from '../shared/view-shared.module';
import { SharedModule } from '../../shared.module';

const routes = [
    {
        path: 'reset-password',
        component: ResetPassComponent
    }
];

@NgModule({
    declarations: [
        ResetPassComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        ViewSharedModule
    ]
})
export class ResetPassModule {}

<form class="w-100-p" fxLayout="column" (submit)="submit()">
    <p>{{ 'CGU.MUST_CHANGE' | translate }}</p>

    <div class="iframe-container">
        <iframe type="text/html" width="100%" height="300" [src]="url | sanitizeHtml" class="mb-16 cgu" (load)="iframeHasLoaded()"></iframe>

        <div *ngIf="!iframeLoaded" class="loader-container">
            <app-loading [onlySpinner]="true"></app-loading>
        </div>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center">
        <button type="submit" mat-raised-button color="accent"
            [disabled]="(formInProgress$ | async) || !iframeLoaded">{{ 'CGU.ACCEPT_CGU' | translate }}</button>
    </div>
</form>

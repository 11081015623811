<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span
                class="title dialog-title">{{ 'CHAT.DIALOG_PROGRAM_SCENARIO.TITLE' | translate }} {{ scenario.title }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">

        <div *ngIf="!isStartDateApplied" class="message-box info mb-16">
            <strong>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.START_DATE_HINT_L1' | translate }}</strong><br />
            {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.START_DATE_HINT_L2' | translate }}
        </div>

        <form [formGroup]="startDateFG" fxLayout="column" fxLayoutGap="16px" (ngSubmit)="applyStartDate(startDateFG)">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">

                <mat-form-field appearance="outline" fxFlex="25%">
                    <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.START_DATE_LABEL' | translate }}</mat-label>
                    <input matInput [owlDateTimeTrigger]="datePicker" [owlDateTime]="datePicker"
                           [placeholder]="'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.START_DATE_LABEL' | translate" (click)="datePicker.open()"
                           [owlDateTimeFilter]="startDateFilter"
                           required (focus)="datePicker.open()" formControlName="startDate">
                    <mat-icon matSuffix [owlDateTimeTrigger]="datePicker">today</mat-icon>
                    <owl-date-time #datePicker [stepMinute]="5" [firstDayOfWeek]="null | firstDayWeek"
                                   [hour12Timer]="null | twelveHour"></owl-date-time>
                    <mat-error *ngIf="startDateFG.get('startDate').hasError('required')">
                        {{ 'SHARED.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>

                <button type="submit" mat-raised-button color="primary"
                        [disabled]="!startDateFG.valid">
                    <mat-icon>sync</mat-icon>
                    {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.APPLY_START_DATE' | translate }}
                </button>
            </div>
        </form>

        <mat-divider></mat-divider>

        <div *ngFor="let messageForm of form.controls">
            <app-chat-scenario-program-message [form]="messageForm | asFormGroup" class="m-16"></app-chat-scenario-program-message>
            <mat-divider></mat-divider>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" class="mt-16">
            <button type="button" (click)="save()" mat-raised-button color="accent"
                    [disabled]="formInProgress || !isStartDateApplied || !startDateFG.valid">
                <mat-icon>save</mat-icon>
                {{ 'CHAT.SEND_LATER' | translate }}
            </button>
            <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="formInProgress">
            </mat-spinner>
        </div>
    </div>
</div>

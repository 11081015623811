import { JsonProperty } from 'json-object-mapper';
import { DateMapper } from '../../object-mapper/date-mapper';
import { RendezVous } from '../rendez-vous/rendez-vous';

export class Payout {
    @JsonProperty()
    id: string = null;

    @JsonProperty()
    amount: number = null;

    @JsonProperty()
    status: 'paid' | 'pending' | 'in_transit' | 'canceled' | 'failed' = null;

    @JsonProperty()
    'failure_code': 'account_closed' | 'account_frozen' | 'bank_account_restricted' | 'bank_ownership_changed' | 'could_not_process' | 'debit_not_authorized' | 'declined' | 'insufficient_funds' | 'invalid_account_number' | 'incorrect_account_holder_name' | 'invalid_currency' | 'no_account' | 'unsupported_card' = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    created: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    'arrival_date': Date = null;

    @JsonProperty()
    account: any = null;

    @JsonProperty()
    currency: string = null;

    @JsonProperty()
    transactions: any[] = null;

    @JsonProperty({ type: RendezVous })
    rendezVous: RendezVous[] = null;

    public get failureCodeText(): string {
        return ('PAYOUTS.CODE_FAILURE.' + this.failure_code).toUpperCase();
    }
}

import { TestComponent } from './test.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { NoAuthGuard } from '../../../guards/no-auth.guard';

const routes = [
    {
        path: 'test',
        component: TestComponent,
        canActivate: [NoAuthGuard]
    }
];

@NgModule({
    declarations: [
        TestComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule
    ]
})

export class TestModule {}

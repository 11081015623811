import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Chat } from '../../../../models/animal/chat';
import { DelayedMessageService } from '../../../../services/api/delayed-message.service';
import { DelayedMessage } from '../../../../models/delayed-message';

@Component({
    selector: 'app-chat-delayed-messages-dialog',
    templateUrl: './chat-delayed-messages-dialog.component.html',
    styleUrls: ['./chat-delayed-messages-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ChatDelayedMessagesDialogComponent implements OnInit, OnDestroy {
    chat: Chat;
    isLoading = true;
    delayedMessages: DelayedMessage[] = [];

    private subscriptions: Subscription[] = [];

    constructor(
        public dialogRef: MatDialogRef<ChatDelayedMessagesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ChatDelayedMessagesDialogInterface,
        private delayedMessageService: DelayedMessageService,
        public dialog: MatDialog
    ) {
        this.chat = data.chat;
    }

    private refresh(): void {
        this.isLoading = true;
        this.delayedMessageService.getAllByChatId(this.chat.id).subscribe((delayedMessages: DelayedMessage[]) => {
            this.delayedMessages = delayedMessages.sort((first, second) => first.date.getTime() - second.date.getTime());
            this.isLoading = false;
        });
    }

    ngOnInit(): void {
        this.refresh();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((s: Subscription) => {
            s.unsubscribe();
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    updateDelayedMessage(delayedMessage: DelayedMessage): void {
        this.delayedMessageService.openDelayedMessageDialog(true, delayedMessage).afterClosed().subscribe(() => {
            this.refresh();
        });
    }

    deleteDelayedMessage(delayedMessage: DelayedMessage): void {
        this.delayedMessageService.delete(delayedMessage.id).subscribe(() => {
            this.chat.delayedMessagesCount--;
            this.refresh();
        });
    }
}

export interface ChatDelayedMessagesDialogInterface {
    chat: Chat;
}

<div class="mat-headline">{{ 'REPORTS.EDITORS.REPORT.MAIN_TITLE_INTRO' | translate }}
    <span *ngIf="report.target === reportTarget.Client">{{ 'REPORTS.EDITORS.REPORT.CLIENT' | translate | lowercase }} </span>
    <span *ngIf="report.target === reportTarget.Vet">{{ 'REPORTS.EDITORS.REPORT.COLLEAGUE' | translate | lowercase }} </span>
</div>
<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center stretch" fxLayoutAlign.gt-xs="space-between end" fxLayoutGap="8px" class="my-12">
    <div fxFlex fxFlex.gt-xs="40%" fxLayout="row" fxLayoutAlign="center stretch" fxLayoutAlign.gt-xs="start end" fxLayoutGap="8px">
        <button mat-raised-button color="primary" fxFlex="calc(50%-8px)" (click)="generatePDF()" [disabled]="report.status===reportStatus.Start">
            <mat-icon>cloud_download</mat-icon>
            {{ 'REPORTS.EDITORS.REPORT.PDF_BUTTON' | translate }}
        </button>
        <button mat-raised-button color="primary" fxFlex="calc(50%-8px)" (click)="send()" [disabled]="report.status===reportStatus.Start || disabledEmailSendingButton" *ngIf="report.target == reportTarget.Client">
            <mat-icon>forward_to_inbox</mat-icon>
            {{ 'REPORTS.EDITORS.REPORT.EMAIL_BUTTON' | translate }}
        </button>
    </div>
    <div fxFlex fxFlex.gt-xs="60%" fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center stretch" fxLayoutAlign.gt-xs="end end" fxLayoutGap="8px">
        <mat-form-field appearance="legacy" class="mat-form-field-for-report" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(66%-8px)">
            <mat-label>{{ 'REPORTS.EDITORS.REPORT.NAME_LABEL' | translate }}</mat-label>
            <input #nameInput matInput [(ngModel)]="report.name">
        </mat-form-field>
        <button mat-raised-button color="primary" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(34%-8px)" (click)="saveReport()" [disabled]="report.status!==reportStatus.Modified">
            <mat-icon>save</mat-icon>
            {{ 'REPORTS.EDITORS.REPORT.SAVE_BUTTON' | translate }}
        </button>
    </div>

</div>


<div *ngIf="!report.template && (report.status === reportStatus.Start)" class="message-box info mb-12">{{ 'REPORTS.EDITORS.REPORT.START_INSTRUCTION' | translate }}</div>
<div *ngIf="report.template && (report.status === reportStatus.Start)" class="message-box warning mb-12">{{ 'REPORTS.EDITORS.REPORT.RECORDING_REMINDER' | translate }}</div>
<div *ngIf="report.status === reportStatus.Modified" class="message-box warning mb-12">{{ 'REPORTS.EDITORS.REPORT.UPDATE_FEEDBACK' | translate }} <span *ngIf="report.dateLastRecorded"> ({{ 'REPORTS.EDITORS.REPORT.UPDATE_LAST' | translate | lowercase }} : {{ report.dateLastRecorded | date:"dd/MM/yy"}} {{ report.dateLastRecorded | date:"HH:mm:ss"}})</span>.</div>
<div *ngIf="report.status === reportStatus.UpToDate" class="message-box success mb-12">{{ 'REPORTS.EDITORS.REPORT.RECORDING_FEEDBACK' | translate }}</div>

<editor
    apiKey="p35ts2mlnis3iddvqpc4mt8cxrranyu2a6rngrkqa8wuisqn"
    outputFormat="html"
    [init]="tinyMCEInit"
    [disabled] = "false"
    [inline] = "false"
    [(ngModel)]="report.content"
    (onChange)="updateReport()"
></editor>

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { EntiteGeographique } from 'app/models/pro/entite-geographique';
import { EntiteGeographiquePostInterface } from 'app/models/interfaces/post/pro/entite-geographique-post.interface';
import { filter } from 'rxjs/operators';
import { EntiteGeographiqueService } from '../../../../services/api/entite-geographique.service';

@Component({
    selector: 'app-entite-geographique-dialog',
    templateUrl: './entite-geographique-dialog.component.html',
    styleUrls: ['./entite-geographique-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class EntiteGeographiqueDialogComponent implements OnInit, OnDestroy {
    entiteGeographiqueData$ = new BehaviorSubject<EntiteGeographiquePostInterface>(null);

    entiteGeographique$: BehaviorSubject<EntiteGeographique>;
    entiteGeographique: EntiteGeographique;
    loading$ = new BehaviorSubject<boolean>(false);

    hasChanged = false;
    disabled = false;

    private subscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<EntiteGeographiqueDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: EntiteGeographiqueDialogInterface
    ) {
        this.entiteGeographique$ = new BehaviorSubject(data.entiteGeographique);
        this.entiteGeographique$.pipe(
            filter(e => Boolean(e))
        ).subscribe((e: EntiteGeographique) => {
            this.entiteGeographique = e;
            this.entiteGeographiqueData$.next(e.hasPost());
        });

        this.disabled = data.disabled;
    }

    ngOnInit(): void {
        if (this.entiteGeographique?.id) {
            this.loading$.next(true);
            this.subscription = this.data.entiteGeographiqueService.getEntiteGeographiqueById(this.entiteGeographique.id).subscribe({
                next: (entiteGeographique: EntiteGeographique) => {
                    this.entiteGeographique$.next(entiteGeographique);
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close(this.entiteGeographique)
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.hasChanged ? this.entiteGeographique$.value : false);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}

export interface EntiteGeographiqueDialogInterface {
    entiteGeographique: EntiteGeographique;
    entiteGeographiqueService: EntiteGeographiqueService;
    disabled: boolean;
}

import { Fichier } from '../fichier';
import { JsonProperty } from 'json-object-mapper';
import { Client } from '../utilisateurs/client';
import { List } from '../list';
import { AnimalPostInterface } from '../interfaces/post/animal/animal-post.interface';
import { Entity } from '../entity';
import { Chat } from './chat';
import { DateMapper } from '../../object-mapper/date-mapper';
import { Espece } from './espece';
import { Race } from './race';
import { ConnectorIdInterface } from '../interfaces/connector-id.interface';

export class Animal extends Entity implements ConnectorIdInterface {
    @JsonProperty()
    nom: string = null;

    @JsonProperty({ type: Espece })
    espece: Espece = null;

    @JsonProperty({ type: Race })
    race?: Race = null;

    @JsonProperty()
    sexe?: string = null;

    @JsonProperty()
    sterilise?: boolean = null;

    // @JsonProperty({ type: Chat })
    chats?: Chat[] = null;
    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateNaissance?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateDeces?: Date = null;

    @JsonProperty({ type: Client })
    proprietaire?: Client = null;

    @JsonProperty({ type: Fichier })
    photo?: Fichier = null;

    @JsonProperty()
    connectorId?: string = null;

    public static emoji(animal: AnimalPostInterface | Animal): string {
        if (animal?.espece) {
            let espece = animal.espece;
            if (Object.prototype.hasOwnProperty.call(espece, 'id')) {
                espece = (espece as {id: number}).id;
            }

            switch (espece) {
                case 4: // chien
                    return '🐶';
                case 2: // chat
                    return '🐱';
                case 3: // cheval
                    return '🐴';
                case 5: // lapin
                    return '🐰';
                case 6: // cochon
                    return '🐷';
                case 7: // poule
                    return '🐔';
                case 8: // vache
                    return '🐮';
                case 9: // hamster
                    return '🐹';
                case 10: // serpent
                    return '🐍';
                case 11: // souris
                    return '🐭';
                case 12: // canard
                    return '🦆';
                case 13: // araignée
                    return '🕷';
                case 14: // mouton
                    return '🐑';
                case 15: // oiseau
                    return '🦜';
                default: // autre
                    return '🦄';
            }
        }

        return '🦄';
    }

    public hasPost(): AnimalPostInterface {
        return {
            id: this.id,
            nom: this.nom,
            espece: this.espece ? this.espece.id : null,
            race: this.race ? this.race.id : null,
            dateNaissance: this.dateNaissance,
            dateDeces: this.dateDeces,
            sexe: this.sexe,
            sterilise: this.sterilise,
            proprietaire: this.proprietaire ? this.proprietaire.id : null,
            photo: this.photo ? this.photo.url : null,
            connectorId: this.connectorId
        };
    }

    public get emoji(): string {
        return Animal.emoji(this);
    }

    public get sexeString(): string {
        switch (this.sexe) {
            case 'm':
                return 'MALE';
            case 'f':
                return 'FEMALE';
            default:
                return 'OTHER';
        }
    }
}

export class ListAnimaux extends List {
    @JsonProperty({ type: Animal })
    data: Animal[] = null;
}

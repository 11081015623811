<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">

    <div fxLayout="row" fxLayoutAlign="start center">
        <app-color-picker fxFlex="0 0 auto"
            [color]="form.get('color').value"
            (event)="form.get('color').setValue($event)"
            style="padding-bottom: 1.34375em; margin: .25em 8px .25em 0;">
        </app-color-picker>
        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{'CHAT.TITLE' | translate }}</mat-label>
            <input matInput formControlName="nom" [placeholder]="'CHAT.TITLE' | translate" required>
            <mat-error *ngIf="form.get('nom').hasError('required')">
                {{'FORM.NAME_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div class="mb-12" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div id="hospitvet" fxFlex="calc(30%-8px)" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <mat-checkbox formControlName="hospitvet" (change)="toggleHospitvetShortcut()"></mat-checkbox>
            <img src="/assets/images/hospitvet.png" alt="Hospitvet"/>
        </div>
        <mat-form-field appearance="outline" fxFlex="70%">
            <mat-label>{{'DATE.CATEGORY_CHAT' | translate }}</mat-label>
            <mat-select [placeholder]="'DATE.CATEGORY_CHAT' | translate" formControlName="categorie" (selectionChange)="updateHospitvet()">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="categoriesFilter"
                        [placeholderLabel]="'DATE.CATEGORY_SEARCH' | translate"
                        [noEntriesFoundLabel]="'DATE.CATEGORY_ANY_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let categorie of filteredCategoriesOptions$ | async" [value]="categorie.id">
                    {{ categorie.value }}
                </mat-option>
            </mat-select>
            <!--
            <mat-error *ngIf="form.get('categorie').hasError('required')">
                {{'DATE.CATEGORY_REQUIRED' | translate }}
            </mat-error>
            -->
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="8px">
        <mat-form-field fxFlex="1 1 auto" appearance="outline">
            <mat-label>{{'CHAT.OPEN' | translate }}</mat-label>
            <input matInput [matDatepicker]="dateDebutPicker" [placeholder]="'CHAT.DATE_OPEN' | translate"
                   formControlName="dateDebut" readonly
                   (focus)="dateDebutPicker.open()" (click)="dateDebutPicker.open()" required>
            <mat-datepicker-toggle matSuffix [for]="dateDebutPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateDebutPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="1 1 auto" appearance="outline">
            <mat-label>{{'CHAT.CLOSE' | translate }}</mat-label>
            <input matInput [matDatepicker]="dateFinPicker" [placeholder]="'CHAT.DATE_CLOSE' | translate"
                   formControlName="dateFin"
                   (focus)="dateFinPicker.open()" (click)="dateFinPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="dateFinPicker"></mat-datepicker-toggle>
            <!--            <button matSuffix mat-icon-button type="button" [matTooltip]="'SHARED.DELETE' | translate"-->
            <!--                    (click)="form.get('dateFin').setValue(null)">-->
            <!--                <mat-icon>close</mat-icon>-->
            <!--            </button>-->
            <mat-datepicker #dateFinPicker></mat-datepicker>
            <mat-error *ngIf="form.get('dateFin').hasError('dateFinAvantDateDebut')">
                {{'CHAT.DATE_ERROR' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <app-input-veterinaire [control]="form.get('veterinaire')"></app-input-veterinaire>

    <app-input-animal [control]="form.get('animalId')"></app-input-animal>

    <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="end center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <button mat-button color="warn" (click)="remove()" type="button" *ngIf="form.get('id').value != null"
                [disabled]="(formInProgress$ | async) || (formInProgressDelete$ | async)">
                <mat-icon>delete</mat-icon> {{ 'SHARED.DELETE' | translate }}
            </button>
        </div>
        <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async) || (formInProgressDelete$ | async)">
            <mat-icon>save</mat-icon> {{ 'FORM.SAVE' | translate }}
        </button>
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="(formInProgress$ | async) || (formInProgressDelete$ | async)"></mat-spinner>
    </div>

</form>

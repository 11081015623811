import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Inject, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-visio-payment-charge-dialog',
    templateUrl: './visio-payment-charge-dialog.component.html',
    styleUrls: ['./visio-payment-charge-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class VisioPaymentChargeDialogComponent {
    state: VisioChargeStatut = VisioChargeStatut.askingVet;
    loading = false;

    onAskCharge = new EventEmitter();

    constructor(
        public dialogRef: MatDialogRef<VisioPaymentChargeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: VisioPaymentChargeDialogData
    ) {}

    close(): void {
        this.dialogRef.close();
    }
}

export interface VisioPaymentChargeDialogData {
    rdvId: number;
    prix: number;
    currency: string;
}

export enum VisioChargeStatut {
    askingVet = 'askingVet',
    sendingSignal = 'sendingSignal',
    signalSent = 'signalSent',
    clientCallback = 'clientCallback',
    apiChecked = 'apiChecked',
    stillUnpaid = 'stillUnpaid',
    error = 'error'
}

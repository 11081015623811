<div class="w-100-p" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
    <app-input-pays [isForPhoneCountryCode]="true" [control]="countryControl" [required]="required" fxFlex="0 0 160px"></app-input-pays>
    <mat-form-field appearance="outline" fxFlex="1 1 auto">
        <mat-label>{{ label ? label : placeholder }}</mat-label>
        <input matInput [placeholder]="placeholder" [formControl]="phoneNationalControl" name="phoneNumber"
               [required]="required"/>
        <mat-error class="warn-fg" *ngIf="control.hasError('required')">
            {{ 'FORM.PHONE_REQUIRED' | translate }}
        </mat-error>
        <mat-hint class="warn-fg" *ngIf="control.hasError('number')">
            {{ 'FORM.PHONE_WRONG_NUMBER' | translate }}
        </mat-hint>
    </mat-form-field>
</div>

<fuse-widget class="widget">
    <div class="fuse-widget-front mat-white-bg">
        <div class="pl-16 pr-8 py-16 h-52" fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ titleLabel }}</span>
            <button mat-icon-button color="accent" (click)="actionClicked$.next(null)"
                    *ngIf="action !== undefined && action !== null">
                <mat-icon [matTooltip]="action">{{ icon }}</mat-icon>
            </button>
        </div>

        <div class="pt-8 pb-16 text-center">
            <span class="{{ color }}-fg font-size-72 line-height-72">
                <!-- {{ !loading ? (stat > 0 ? stat : ('SHARED.ANY' | translate)) : '...'}} -->
                {{ !loading ? stat : '...'}}
            </span>
            <span class="{{ color }}-fg font-size-32 text-bold" *ngIf="unit != null && stat != null && stat > 0">
                {{ unit }}
            </span>
        </div>

        <div class="pb-16" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
             *ngIf="detail != null && detail != null && stat > 0">
            <div class="font-size-14">
                {{ detail }}
            </div>
        </div>
    </div>
</fuse-widget>

import { CalendarEvent, CalendarEventTitleFormatter } from 'angular-calendar';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { TranslateService } from '@ngx-translate/core';

export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
    constructor(private translateService: TranslateService) {
        super();
    }

    month(event: CalendarEvent): string {
        return this.getTitle(event);
    }

    monthTooltip(event: CalendarEvent): string {
        return this.getTooltip(event, '\n');
    }

    week(event: CalendarEvent): string {
        return this.getTitle(event);
    }

    weekTooltip(event: CalendarEvent): string {
        return this.getTooltip(event);
    }

    day(event: CalendarEvent): string {
        return this.getTitle(event);
    }

    dayTooltip(event: CalendarEvent): string {
        return this.getTooltip(event);
    }

    private getTitle(event: CalendarEvent): string {
        return event.title;
    }

    private getTooltip(event: CalendarEvent, breakLine = '<br />'): string {
        const rendezVous = event.meta as RendezVous;
        const joiner = ' : ';

        const nature: string = [
            this.translateService.instant('RENDEZ_VOUS.NATURE') as string,
            rendezVous.nature
        ].join(joiner);

        const categorie: string = rendezVous.categorie ? [
            this.translateService.instant('DATE.CATEGORY') as string,
            rendezVous.categorie.value
        ].join(joiner) : null;

        const veterinaire: string = rendezVous.veterinaire ? [
            this.translateService.instant('VETO.VETO') as string,
            rendezVous.veterinaire.fullName
        ].join(joiner) : null;

        const animal: string = [
            this.translateService.instant('RENDEZ_VOUS.ANIMAL') as string,
            rendezVous.animal.nom + '/' + rendezVous.animal.espece.nom
        ].join(joiner);

        const status = this.translateService.instant(`RENDEZ_VOUS.STATUS.${rendezVous.correctStatut}`) as string;

        return [nature, categorie, veterinaire, animal, status].map(a => a).join(breakLine);
    }
}

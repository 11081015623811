import { EntiteGeographique } from './entite-geographique';
import { JsonProperty } from 'json-object-mapper';
import { Veterinaire } from '../utilisateurs/veterinaire';
import { Entity } from '../entity';
import { EntiteJuridiquePostInterface } from '../interfaces/post/pro/entite-juridique-post.interface';
import { DateMapper } from '../../object-mapper/date-mapper';
import { Animal } from '../animal/animal';
import { EntiteJuridiqueSettingsPostInterface } from '../interfaces/post/pro/entite-juridique-settings-post.interface';
import { Owner } from './stripe/owner';
import { ExternalAccount } from './stripe/external-account';
import { EntiteJuridiqueSettingsConnectorPostInterface } from '../interfaces/post/pro/entite-juridique-settings-connector-post.interface';

export class ChatShortcut {
    @JsonProperty()
    shortcut: string = null;

    @JsonProperty()
    category: string = null;

    @JsonProperty()
    content: string = null;
}

export class ChatScenarioMessage {
    @JsonProperty()
    message: string = null;

    @JsonProperty()
    nbDaysAfter: number = null;

    @JsonProperty()
    numberDaysToOpenIfClosed: number = null;

    date: Date; // Used in a component
}

export class ChatScenario {
    @JsonProperty()
    title: string = null;

    @JsonProperty({ type: ChatScenarioMessage })
    messages: ChatScenarioMessage[] = null;
}

export class EntiteJuridique extends Entity {
    @JsonProperty()
    nom: string = null;

    @JsonProperty()
    mail?: string = null;

    @JsonProperty()
    telephone?: string = null;

    @JsonProperty()
    adresse?: string = null;

    @JsonProperty()
    codePostal?: string = null;

    @JsonProperty()
    region?: string = null;

    @JsonProperty()
    ville?: string = null;

    @JsonProperty()
    pays?: string = null;

    @JsonProperty({ type: EntiteGeographique })
    entitesGeographiques?: EntiteGeographique[] = null;

    @JsonProperty({ type: Veterinaire })
    proprietaire?: Veterinaire = null;

    @JsonProperty({ type: Veterinaire })
    veterinaires?: Veterinaire[] = null;

    @JsonProperty()
    adresseFacturation?: string = null;

    @JsonProperty()
    codePostalFacturation?: string = null;

    @JsonProperty()
    villeFacturation?: string = null;

    @JsonProperty()
    regionFacturation?: string = null;

    @JsonProperty()
    paysFacturation?: string = null;

    @JsonProperty()
    siret?: string = null;

    @JsonProperty()
    nbLicences: number = null;

    @JsonProperty()
    nbGigaStockage: number = null;

    @JsonProperty()
    nbSecondesForfait: number = null;

    @JsonProperty()
    cgv: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateAnniversaire: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    periodEndDateMonthly: Date = null;

    @JsonProperty()
    statut?: string = null;

    @JsonProperty()
    currency: string = null;

    @JsonProperty()
    paymentEnabled: boolean = null;

    @JsonProperty()
    accountSelector: boolean = null;

    @JsonProperty()
    emergencyAppointmentPrice: number = null;

    @JsonProperty()
    receiveEmailRecap: boolean = null;

    @JsonProperty()
    receiveEmailRecapAddress: string = null;

    @JsonProperty()
    partenaire: string = null;

    @JsonProperty()
    vetSoft: string = null;

    @JsonProperty()
    vetSoftApiKeyToken: string = null;

    @JsonProperty({ type: ChatShortcut })
    chatShortcuts: ChatShortcut[] = null;

    @JsonProperty({ type: ChatScenario })
    chatScenarios: ChatScenario[] = null;

    @JsonProperty()
    fileSpaceUsed: number = null;

    @JsonProperty()
    callSecondsUsed: number = null;

    @JsonProperty()
    obfuscateVeterinaries: boolean = null;

    @JsonProperty()
    clientSignupCode: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    cancelSubscriptionDate: Date = null;

    @JsonProperty()
    isSubscriptionManaged: boolean = null;

    @JsonProperty()
    isDisabled: boolean = null;

    @JsonProperty()
    currentAppointmentPricePayed: number = null;

    @JsonProperty()
    vat?: string = null;

    @JsonProperty()
    appointmentVatPercent?: number = null;

    @JsonProperty()
    appointmentVatEnabled?: boolean = null;

    @JsonProperty()
    chatListColors: string[] = null;

    @JsonProperty()
    hasAllyDvm: boolean = null;

    @JsonProperty()
    allydvmApiKey: string = null;

    @JsonProperty()
    allydvmAppId: number = null;

    @JsonProperty()
    allydvmSourceId: number = null;

    @JsonProperty()
    hasVisioCare: boolean = null;

    @JsonProperty()
    newsAllowed: boolean = null;

    isFull = false;

    /**
     * Return if the entiteJuridique can access to the onboarding client or not
     * @param {EntiteJuridique | number} entiteJuridique entiteJuridique object or entiteJuridique id
     * @returns {boolean} boolean
     */
    public static isScoldedFromOnboardingClient(entiteJuridique: EntiteJuridique | number): boolean {
        const scoldedClinics = [88, 269];

        try {
            let entiteJuridiqueId = entiteJuridique;
            if (entiteJuridique instanceof EntiteJuridique) {
                entiteJuridiqueId = entiteJuridique.id;
            }

            return scoldedClinics.includes(Number(entiteJuridiqueId));
        } catch {
            return false;
        }
    }

    public hasPost(): EntiteJuridiquePostInterface {
        return {
            id: this.id,
            nom: this.nom,
            mail: this.mail,
            telephone: this.telephone,
            adresse: this.adresse,
            ville: this.ville,
            region: this.region,
            codePostal: this.codePostal,
            pays: this.pays,
            adresseFacturation: this.adresseFacturation,
            villeFacturation: this.villeFacturation,
            regionFacturation: this.regionFacturation,
            codePostalFacturation: this.codePostalFacturation,
            paysFacturation: this.paysFacturation,
            // cgv: this.cgv,
            // currency: this.currency,
            siret: this.siret,
            statut: this.statut,
            proprietaire: this.proprietaire ? this.proprietaire.id : null,
            vat: this.vat,
            appointmentVatPercent: this.appointmentVatPercent,
            appointmentVatEnabled: this.appointmentVatEnabled
        };
    }

    public configHasPost(): EntiteJuridiqueSettingsPostInterface {
        return {
            id: this.id,
            accountSelector: this.accountSelector,
            clientSignupCode: this.clientSignupCode,
            emergencyAppointmentPrice: this.emergencyAppointmentPrice,
            receiveEmailRecap: this.receiveEmailRecap,
            receiveEmailRecapAddress: this.receiveEmailRecapAddress,
            chatShortcuts: this.chatShortcuts,
            chatScenarios: this.chatScenarios,
            obfuscateVeterinaries: this.obfuscateVeterinaries,
            chatListColors: this.chatListColors
        };
    }

    public configHasConnector(): EntiteJuridiqueSettingsConnectorPostInterface {
        return {
            id: this.id,
            vetSoft: this.vetSoft
        };
    }

    public get nbGigaStockageComputed(): number {
        /*a.nbGigaStockageComputed = +new FileSizePipe(this.translateService)
            .transform(a.nbGigaStockage * 1024, 0, 'MB', 'MB')
            .slice(0, -3);*/
        return this.nbGigaStockage * 1000;
    }

    public get fileSpaceUsedComputed(): number {
        /*a.fileSpaceUsed = +new FileSizePipe(this.translateService)
            .transform(a.fileSpaceUsed, 0, 'B', 'MB')
            .slice(0, -3);*/
        return Math.round(this.fileSpaceUsed / 1024 / 1024);
    }
}

export class StatsFiles {
    @JsonProperty({ type: Animal })
    animal: Animal = null;

    @JsonProperty()
    taille: number = null;

    @JsonProperty()
    contentType: string = null;
}

export class EntiteJuridiqueStatsFiles {
    @JsonProperty()
    nbGigaStockage: number = null;

    @JsonProperty({ type: StatsFiles })
    stats: StatsFiles[] = null;
}

export class EntiteJuridiqueStripe {
    @JsonProperty()
    type: string = null;

    @JsonProperty({ type: EntiteJuridique })
    entiteJuridique: EntiteJuridique = null;

    @JsonProperty({ type: Owner })
    owner: Owner = null;

    @JsonProperty({ type: ExternalAccount })
    externalAccount: ExternalAccount = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateDueLimit: Date = null;
}

export class EntiteJuridiqueConnectorStats {
    @JsonProperty()
    apiKey: string = null;

    @JsonProperty()
    clients: number = null;

    @JsonProperty()
    clientsLinked: number = null;

    @JsonProperty()
    vets: number = null;

    @JsonProperty()
    vetsLinked: number = null;

    @JsonProperty()
    animals: number = null;

    @JsonProperty()
    animalsLinked: number = null;
}

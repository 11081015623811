import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import heic2any from 'heic2any';
import {
    createDefaultImageReader,
    createDefaultImageWriter,
    PinturaDefaultImageWriterResult,
    PinturaEditorOptions,
    plugin_annotate,
    markup_editor_defaults,
    plugin_crop,
    setPlugins,
    createDefaultShapePreprocessor,
    blobToFile
} from 'pintura';
import { Subscription } from 'rxjs';
import { FichierService } from '../../../../services/api/fichier.service';
import { ConfigService } from '../../../../services/config.service';
import { Utils } from '../../../../utils';

@Component({
    selector: 'app-edit-photo-dialog',
    templateUrl: './edit-photo-dialog.component.html',
    styleUrls: ['./edit-photo-dialog.component.scss']
})
export class EditPhotoDialogComponent implements OnDestroy {
    file: string | File | Blob;

    width = window.innerWidth - 64;
    height = window.innerHeight - 250;

    options: PinturaEditorOptions = {
        imageReader: createDefaultImageReader({
            preprocessImageFile: async file => {
                if (!file.type.includes('heic')) {
                    return file;
                }

                const blob = await heic2any({
                    blob: file,
                    toType: 'image/jpeg',
                    quality: 0.9
                }) as Blob;

                return blobToFile(blob, 'image.jpg');
            }
        }),
        imageWriter: createDefaultImageWriter(),
        locale: Utils.getPinturaLocale(this.translateService),
        ...markup_editor_defaults
    };

    private subscription?: Subscription;

    constructor(
        public dialogRef: MatDialogRef<EditPhotoDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: EditPhotoDialogData,
        private config: ConfigService,
        public translateService: TranslateService, private fichierService: FichierService
    ) {
        setPlugins(plugin_crop, plugin_annotate);
        this.setPinturaCustomOptions();
        this.downloadFile(data.url);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    close(): void {
        this.dialogRef.close(null);
    }

    onSave(result: PinturaDefaultImageWriterResult): void {
        const blob = result.dest;
        if (blob && blob instanceof File) {
            const file: File = new File([blob], 'image.jpeg', { type: 'image/jpg', lastModified: Date.now() });
            if (file) {
                this.subscription = this.fichierService.compressAndResize(file).subscribe(compressed => this.dialogRef.close(compressed));
            } else {
                console.error('[Edit Photo Dialog] Erreur lors de la génération du fichier', file);
            }
        }
    }

    private setPinturaCustomOptions(): void {
        this.options.cropSelectPresetOptions = [
            [undefined, this.translateService.instant('PINTURA.SHAPE.PRESET.CUSTOM')],
            [1, this.translateService.instant('PINTURA.SHAPE.PRESET.SQUARE')],
            [16 / 9, this.translateService.instant('PINTURA.SHAPE.PRESET.LANDSCAPE')],
            [3 / 4, this.translateService.instant('PINTURA.SHAPE.PRESET.PORTRAIT')]
        ];
        this.options.shapePreprocessor = createDefaultShapePreprocessor();
    }

    private downloadFile(url: string, retryWithCorsByPass = false) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', (retryWithCorsByPass ? this.config.baseUrl + 'api/pass_through?url=' : '') + url);
        xhr.responseType = 'blob';

        xhr.addEventListener('load', res => {
            try {
                this.file = (res.target as any).response;
            } catch {
                if (!retryWithCorsByPass) {
                    this.downloadFile(url, true);
                }
            }
        });

        xhr.addEventListener('error', () => {
            if (retryWithCorsByPass) {
                this.close();
            } else {
                this.downloadFile(url, true);
            }
        });

        xhr.send();
    }
}

export interface EditPhotoDialogData {
    url: string;
    saveBtnText?: string;
}

<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'FORM.TIMEZONE' | translate }}</mat-label>
    <mat-select [placeholder]="'FORM.TIMEZONE' | translate" [formControl]="control | asFormControl" [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="timezoneFilter"
                [placeholderLabel]="'FORM.TIMEZONE' | translate"
                [noEntriesFoundLabel]="'FORM.NO_RESULT' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let timezone of filteredTimezoneOptions$ | async" [value]="timezone">
            {{ timezone | replace:'_':' ' }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
        {{ 'FORM.TIMEZONE_REQUIRED' | translate }}
    </mat-error>
</mat-form-field>

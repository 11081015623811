import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmailDataInterface } from '../../../../../models/interfaces/email-data.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-email-building-dialog',
  templateUrl: './email-building-dialog.component.html',
  styleUrls: ['./email-building-dialog.component.scss']
})
export class EmailBuildingDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<EmailBuildingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { emailData: EmailDataInterface },
        private readonly formBuilder: FormBuilder
    ) {}

    form: FormGroup;

    ngOnInit(): void {
        this.setForm();
    }

    private setForm(): void {
        this.form = this.formBuilder.group({
            content: this.formBuilder.control(this.data.emailData.content, [Validators.required])
        });
    }

    sendEmailData(): void {
        if (!this.form.valid) {
            return;
        }

        this.data.emailData.content = this.form.get('content').value;
        this.dialogRef.close(this.data.emailData);
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientWaiting, WaitingRoomService } from '../../../../services/api/waiting-room.service';
import { Utils } from 'app/utils';

@Component({
    selector: 'app-waiting-room',
    templateUrl: './waiting-room.component.html',
    styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent {
    @Input() showCount = false;
    @Input() card = false;
    @Output() rendezVousLoaded = new EventEmitter();

    constructor(
        private router: Router,
        private translateService: TranslateService,
        public waitingRoomService: WaitingRoomService
    ) {}

    loadVisio(rendezVous: RendezVous): void {
        void this.router.navigate(['/visio', rendezVous.id]);
        this.rendezVousLoaded.emit();
    }

    getElapsedTime(date: Date): string {
        return Utils.getElapsedTime(date, this.translateService);
    }

    getTestResult(clientWaiting: ClientWaiting): string {
        switch (clientWaiting.testResult) {
            case 0:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_0') as string;
            case 1:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_1') as string;
            case 2:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_2') as string;
            case 3:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_3') as string;
            default:
                return this.translateService.instant('WAITING_ROOM.SIGNAL_STRENGTH_UNKNOWN') as string;
        }
    }

    getTestResultColor(clientWaiting: ClientWaiting): string {
        switch (clientWaiting.testResult) {
            case 0:
                return 'red';
            case 1:
                return 'red';
            case 2:
                return 'orange';
            case 3:
                return 'green';
            default:
                return 'inherit';
        }
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Subject, Subscription } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { EntiteJuridiquePostInterface } from '../../../../models/interfaces/post/pro/entite-juridique-post.interface';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';
import { phoneNumberValidator } from '../inputs/input-phone/input-phone.component';

@Component({
    selector: 'app-entite-juridique-form',
    templateUrl: './entite-juridique-form.component.html',
    styleUrls: ['./entite-juridique-form.component.scss'],
    animations: fuseAnimations
})
export class EntiteJuridiqueFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<EntiteJuridiquePostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() disabled = false;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<EntiteJuridiquePostInterface>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<EntiteJuridique>();

    form: FormGroup;

    private subscriptionEntityData: Subscription;

    constructor(private entiteJuridiqueService: EntiteJuridiqueService) {
        this.form = new FormGroup({
            id: new FormControl(null),
            nom: new FormControl(null, [Validators.required]),
            mail: new FormControl(null, [Validators.required]),
            telephone: new FormControl(null, [Validators.required, phoneNumberValidator]),
            adresse: new FormControl(null, [Validators.required]),
            codePostal: new FormControl(null, [Validators.required]),
            ville: new FormControl(null, [Validators.required]),
            region: new FormControl(),
            pays: new FormControl(null, [Validators.required]),
            adresseFacturation: new FormControl(null, [Validators.required]),
            codePostalFacturation: new FormControl(null, [Validators.required]),
            villeFacturation: new FormControl(null, [Validators.required]),
            regionFacturation: new FormControl(),
            paysFacturation: new FormControl(null, [Validators.required]),
            statut: new FormControl(null),
            siret: new FormControl(null),
            ownerId: new FormControl(null, [Validators.required]),
            // cgv: new FormControl(null, []),
            // currency: new FormControl(null, [Validators.required]),
            vat: new FormControl(null, []),
            appointmentVatEnabled: new FormControl(null, []),
            appointmentVatPercent: new FormControl(null, [])
        });

        this.form.get('id').disable();

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.form.get('appointmentVatEnabled').valueChanges.subscribe(() => {
            this.computeValidators();
        });
    }

    ngOnInit(): void {
        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe(entiteJuridiqueData => {
            this.form.patchValue({
                id: entiteJuridiqueData.id,
                nom: entiteJuridiqueData.nom,
                mail: entiteJuridiqueData.mail,
                telephone: entiteJuridiqueData.telephone,
                adresse: entiteJuridiqueData.adresse,
                codePostal: entiteJuridiqueData.codePostal,
                ville: entiteJuridiqueData.ville,
                region: entiteJuridiqueData.region,
                pays: entiteJuridiqueData.pays,
                adresseFacturation: entiteJuridiqueData.adresseFacturation,
                codePostalFacturation: entiteJuridiqueData.codePostalFacturation,
                villeFacturation: entiteJuridiqueData.villeFacturation,
                regionFacturation: entiteJuridiqueData.regionFacturation,
                paysFacturation: entiteJuridiqueData.paysFacturation,
                statut: entiteJuridiqueData.statut,
                siret: entiteJuridiqueData.siret,
                ownerId: entiteJuridiqueData.proprietaire,
                // cgv: entiteJuridiqueData.cgv,
                // currency: entiteJuridiqueData.currency,
                vat: entiteJuridiqueData.vat,
                appointmentVatEnabled: entiteJuridiqueData.appointmentVatEnabled,
                appointmentVatPercent: entiteJuridiqueData.appointmentVatPercent
            }, { emitEvent: false });

            this.computeValidators();
        });

        if (this.disabled) {
            this.form.disable();
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save(this.form.getRawValue());
            }
        }
    }

    private save(data: EntiteJuridiquePostInterface) {
        this.formInProgress$.next(true);
        if (data.id) {
            this.entiteJuridiqueService.updateEntiteJuridique(data).subscribe({
                next: entiteJuridique => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(entiteJuridique);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }

    private computeValidators(): void {
        if (this.form.get('appointmentVatEnabled').value) {
            this.form.get('appointmentVatPercent').enable();
            this.form.get('appointmentVatPercent').clearValidators();
            this.form.get('appointmentVatPercent').setErrors(null);
            this.form.get('appointmentVatPercent').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.form.get('appointmentVatPercent').updateValueAndValidity();
        } else {
            this.form.get('appointmentVatPercent').setValidators(null);
            this.form.get('appointmentVatPercent').clearValidators();
            this.form.get('appointmentVatPercent').setErrors(null);
            this.form.get('appointmentVatPercent').disable();
        }
    }
}

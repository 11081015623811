<div class="page-layout simple fullwidth w-100-p">
    <div class="content">
        <div class="header pt-24 pl-24 pr-24" fxLayout="row" fxLayoutAlign="space-between start">
            <div class="mat-display-1 mb-0">{{ greeting | translate }} {{ user?.fullName }}</div>

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" fxHide.xs class="clinique">
                <mat-icon>location_on</mat-icon>
                <span>{{ user?.entiteGeographique?.nom }}</span>
            </div>
        </div>

        <div class="p-24">
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around start" fxLayoutGap="8px">
                <div id="left" class="w-100-p" fxLayout="column" fxLayoutGap="8px">
                    <div fxLayout="row" fxLayoutGap="8px" fxFill>
                        <app-stats-card [titleLabel]="'MESSAGE.NOT_READ' | translate"
                                        [loading]="(totalUnread$ | async) == null"
                                        [stat]="totalUnread$ | async"
                                        [action]="'MESSAGE.SEE' | translate"
                                        [statValueMeaning]="statValueMeaning.ZeroIsGood"
                                        (onActionClick)="goToMessages()"></app-stats-card>

                        <app-stats-card [titleLabel]="'CHAT.NB_CHAT_OPEN' | translate"
                                        [loading]="(totalChatOpen$ | async) == null"
                                        [stat]="totalChatOpen$ | async"
                                        [action]="'MESSAGE.SEE' | translate"
                                        [statValueMeaning]="statValueMeaning.ZeroIsBad"
                                        (onActionClick)="goToMessages()"></app-stats-card>
                    </div>

                    <div id="hospitvet" fxFill>
                        <div class="text-center mb-8 mt-8">
                            <img src="/assets/images/hospitvet.png" alt="Hospitvet"/>
                        </div>

                        <div fxLayout="row" fxLayoutGap="8px">
                            <app-stats-card [titleLabel]="'MESSAGE.NOT_READ' | translate"
                                            [loading]="(totalUnreadHospivet$ | async) == null"
                                            [stat]="totalUnreadHospivet$ | async"
                                            [action]="'MESSAGE.SEE' | translate"
                                            [statValueMeaning]="statValueMeaning.ZeroIsGood"
                                            (onActionClick)="goToMessagesHospitvet()"></app-stats-card>

                            <app-stats-card [titleLabel]="'CHAT.NB_CHAT_OPEN' | translate"
                                            [loading]="(totalChatOpenHospivet$ | async) == null"
                                            [stat]="totalChatOpenHospivet$ | async"
                                            [action]="'MESSAGE.SEE' | translate"
                                            [statValueMeaning]="statValueMeaning.ZeroIsBad"
                                            (onActionClick)="goToMessagesHospitvet()"></app-stats-card>
                        </div>
                    </div>

                    <app-table-card
                        fxFill
                        [titleLabel]="'NEWS.DASHBOARD.TITLE' | translate"
                        [loading]="publishedNews === undefined"
                        [columnsName]="[('NEWS.TABLE.NAME' | translate), ('NEWS.TABLE.PUBLISHED_AT' | translate), ('NEWS.TABLE.NB_CLIENTS' | translate), ('NEWS.TABLE.NB_VIEW' | translate), ('NEWS.TABLE.NB_CLICK' | translate)]"
                        [columnsIcon]="[null, null, 'supervisor_account', 'visibility', 'touch_app']"
                        [columns]="['name', 'date', 'nbClients', 'nbViews', 'nbClicks']"
                        [datas]="publishedNews"
                        [action]="'NEWS.DASHBOARD.SEE' | translate"
                        (onActionClick)="goToNews()">
                    </app-table-card>
                </div>

                <div id="right" class="w-100-p" fxLayout="column" fxLayoutGap="8px">
                    <app-rendez-vous-list-card class="fuse-card dash-card" [period]="RendezVousListPeriod.TODAY"></app-rendez-vous-list-card>
                    <app-table-card
                        *ngIf="user?.isProprietaire"
                        [titleLabel]="'LOGIN.VETO_CONNECTED' | translate"
                        [detail]="('SHARED.TOTAL' | translate) + ' : ' + veterinairesConnected?.length + ' / ' + entiteJuridique?.nbLicences + ' ' + ('SHARED.LICENSES' | translate)"
                        [loading]="veterinairesConnected == null"
                        [columns]="entiteJuridique?.entitesGeographiques?.length === 1 ? ['fullNameLite', 'clientTokenUsedIcon'] : ['dashboardNameWithClinic', 'clientTokenUsedIcon']"
                        [columnsName]="[('FORM.USER' | translate), ('SHARED.PLATFORM' | translate)]"
                        [datas]="veterinairesConnected"
                        [tooltipFn]="vetConnectedTooltipFn">
                        <ng-template #rowActionsTemplate let-data="data">
                            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
                                <button mat-button [disabled]="!!forceDisconnectingInProgress || user?.id === data?.id"
                                        color="warn"
                                        (click)="disconnectUser(data)">{{ 'LOGIN.FORCE_LOG_OUT' | translate }}
                                </button>
                                <mat-spinner [diameter]="20" mode="indeterminate"
                                            *ngIf="forceDisconnectingInProgress === data.id"></mat-spinner>
                            </div>
                        </ng-template>
                    </app-table-card>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">rate_review</mat-icon>
                <span class="logo-text h1">{{ 'SETTINGS.MESSAGES.TITLE' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <app-settings-chat-form *ngIf="!(loading$ | async) && entiteJuridique?.id; else loading"
                               [disabled]="!isOwner"
                               [entityData]="entiteJuridiqueData$"
                               (entityReceived)="entiteJuridique$.next($event)"></app-settings-chat-form>

            <ng-template #loading>
                <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                    <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                    <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>


import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { EntiteGeographiqueService } from '../../../services/api/entite-geographique.service';
import { EntiteGeographique } from '../../../models/pro/entite-geographique';
import { EntitesGeographiqueDataSource } from 'app/datasources/entites-geographiques.data-source';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-entites-geographique',
    templateUrl: './entites-geographique.component.html',
    styleUrls: ['./entites-geographique.component.scss'],
    animations: fuseAnimations
})
export class EntitesGeographiqueComponent implements OnInit, AfterViewInit, OnDestroy {
    dataSource?: EntitesGeographiqueDataSource;
    displayedColumns = ['nom', 'mail', 'telephone', 'ville', 'codePostal', 'actions'];
    clientSignupEnabled = false;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('filter') filter: ElementRef;
    @ViewChild(MatSort) sort: MatSort;

    isOwner: boolean;
    utilisateurConnectedId: number;

    private utilisateurConnectedSubscription: Subscription;

    constructor(
        public utilisateurService: UtilisateurService,
        private dialog: MatDialog,
        private entiteGeographiqueService: EntiteGeographiqueService,
        private translateService: TranslateService,
        private readonly snackbar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.utilisateurConnectedSubscription = this.utilisateurService.utilisateurConnected.subscribe(u => {
            this.isOwner = u.isProprietaire;
            this.utilisateurConnectedId = u.id;
            this.clientSignupEnabled = !!u.entiteJuridique.clientSignupCode;
        });

        this.dataSource = new EntitesGeographiqueDataSource(this.entiteGeographiqueService);
        this.dataSource.loadEntitesGeographique(null, 'asc', 'nom', 0, 20);
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }

        if (this.utilisateurConnectedSubscription) {
            this.utilisateurConnectedSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadEntiteGeographiques();
        });

        this.paginator.page.subscribe((event: PageEvent) => {
            if (event.previousPageIndex === event.pageIndex) {
                this.paginator.pageIndex = 0;
            }

            this.loadEntiteGeographiques();
        });

        fromEvent(this.filter.nativeElement, 'keyup').pipe(
            distinctUntilChanged(),
            debounceTime(150)
        ).subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadEntiteGeographiques();
        });
    }

    loadEntiteGeographiques(): void {
        this.dataSource.loadEntitesGeographique(
            this.filter.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    openEntiteGeographique(entiteGeographique: EntiteGeographique = null): void {
        const isNew = entiteGeographique === null;

        if (isNew && this.dataSource.totalCount >= 1) {
            this.openContactFormMoreModal();
            return;
        }

        if (!entiteGeographique) {
            entiteGeographique = new EntiteGeographique();
            entiteGeographique.pays = this.utilisateurService.utilisateurConnectedValue.entiteJuridique.pays;
            entiteGeographique.region = this.utilisateurService.utilisateurConnectedValue.entiteJuridique.region;
            entiteGeographique.openingTime = this.utilisateurService.utilisateurConnectedValue.entiteGeographique.openingTime;
        }

        this.entiteGeographiqueService.openDialogEntiteGeographique(entiteGeographique, !this.isOwner)
            .afterClosed().subscribe((result: EntiteGeographique) => {
                if (result) {
                    if (isNew) {
                        this.dataSource.add(result);
                    } else {
                        this.dataSource.replace(result);
                    }
                } else if (result === null) {
                    this.dataSource.delete(entiteGeographique);
                }
        });
    }

    copySignupCode(entite: EntiteGeographique): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = entite.clientSignupCode;
        document.body.append(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        selBox.remove();

        this.snackbar.open(this.translateService.instant('CLIENT.SIGNUP_LINK.SNACKBAR_MESSAGE'), null, { duration: 3000 });
    }

    private openContactFormMoreModal() {
        this.dialog.open(DialogComponent, {
            width: '450px',
            data: {
                title: this.translateService.instant('ENTITE_GEO_MORE_DIALOG.TITLE'),
                content: this.translateService.instant('ENTITE_GEO_MORE_DIALOG.CONTENT'),
                ok: this.translateService.instant('ENTITE_GEO_MORE_DIALOG.OK')
            },
            disableClose: true
        });
    }
}

import { Entity } from '../entity';
import { JsonProperty } from 'json-object-mapper';

export enum DocumentStatus {
    Start ='start',
    Modified = 'modified',
    UpToDate = 'uptodate'
}

export enum DocumentType {
    TeleconsultationReport = 'report',
    Other = 'other'
}

export class TextDocument extends Entity {
    @JsonProperty()
    name: string = null;

    @JsonProperty()
    type: DocumentType = DocumentType.TeleconsultationReport;

    @JsonProperty()
    content: string = null;

    @JsonProperty()
    status: DocumentStatus = DocumentStatus.Start;

    @JsonProperty({ type: Date })
    dateCreated: Date = null;

    @JsonProperty({ type: Date })
    dateLastRecorded: Date = null;
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title" *ngIf="shortcut === null; else shortcutTitle">{{ 'SETTINGS.MESSAGES.SHORTCUTS.ADD' | translate }}</span>
            <ng-template #shortcutTitle>
                <span class="title dialog-title">{{ 'SETTINGS.MESSAGES.SHORTCUTS.EDIT' | translate }} <mat-chip>/{{ shortcut.shortcut }}</mat-chip></span>
            </ng-template>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="pl-24 pr-24 pb-24 m-0">
        <form [formGroup]="form" (submit)="submit()">
            <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
                <div class="mt-8" fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="10px">
                    <mat-form-field fxFlex="1 1 100%" appearance="outline">
                        <mat-label>{{ 'SETTINGS.MESSAGES.SHORTCUTS.GROUP' | translate }}</mat-label>
                        <input matInput formControlName="category" [matAutocomplete]="autoCategory" [placeholder]="'SHARED.OTHER' | translate" />
                        <mat-autocomplete #autoCategory="matAutocomplete">
                            <mat-option *ngFor="let category of filteredCategories" [value]="category">
                                {{ category }}
                            </mat-option>
                            <mat-option [value]="'SHARED.OTHER' | translate">{{ 'SHARED.OTHER' | translate }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field fxFlex="1 1 100%" appearance="outline">
                        <mat-label>{{ 'SETTINGS.MESSAGES.SHORTCUTS.SHORTCUT' | translate }}</mat-label>
                        <input matInput required formControlName="shortcut" #shortcutInput
                               [maxLength]="maxLengthShorcut"/>
                        <mat-hint align="end">{{ shortcutInput.value.length }} / {{ maxLengthShorcut }}</mat-hint>
                        <mat-error *ngIf="form.get('shortcut').hasError('required')">
                            {{ 'SETTINGS.MESSAGES.SHORTCUTS.REQUIRED' | translate }}
                        </mat-error>
                        <!-- Not used because input attribute -->
                        <mat-error *ngIf="form.get('shortcut').hasError('maxlength')">
                            {{ 'SETTINGS.MESSAGES.SHORTCUTS.MAX_LENGTH' | translate }}
                        </mat-error>
                        <!-- Not used because setter -->
                        <mat-error *ngIf="form.get('shortcut').hasError('pattern')">
                            {{ 'SETTINGS.MESSAGES.SHORTCUTS.PATTERN' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <app-input-variable-message class="w-100-p"
                                                [control]="form.get('content') | asFormControl"></app-input-variable-message>
                </div>

                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" fxFlex="1 1 auto">
                    <button type="submit" mat-raised-button color="accent" [disabled]="form.invalid">
                        <mat-icon>save</mat-icon>
                        {{ 'FORM.SAVE' | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>

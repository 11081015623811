import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { AppComponent } from './app.component';

import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './services/api/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { RendezVousService } from './services/api/rendez-vous.service';
import { ConfigService } from './services/config.service';
import { HttpTokenAndErrorInterceptor } from './services/http-token-interceptor.service';
import { UtilisateurService } from './services/api/utilisateur.service';
import { EntiteJuridiqueService } from './services/api/entite-juridique.service';
import { AnimalService } from './services/api/animal.service';
import { MatPaginatorIntlTranslated } from './config/mat-paginator-intl-translated';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from './layout/layout.module';
import { ChatService } from './services/api/chat.service';
import { ChatResolver } from './resolver/chat.resolver';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { EntiteGeographiqueService } from './services/api/entite-geographique.service';
import {
    CalendarDateFormatter,
    CalendarEventTitleFormatter,
    CalendarModule,
    DateAdapter as DateAdapterCalendarModule
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ChatIdResolver } from './resolver/chat-id.resolver';
import { DateTimeAdapter, OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { OwlDatetimePickerIntlTranslated } from './config/owl-datetime-picker-intl-translated';
import { RoutesModule } from './routes.module';
import { CustomEventTitleFormatter } from './main/content/calendrier/calendar-config/custom-event-title-formatter';
import { CalendarDateFormatterTranslated } from './main/content/calendrier/calendar-config/calendar-date-formatter-translated';
import { fuseConfig } from './config';
import { CommonModule } from '@angular/common';
import { DateAdapterTranslated } from './config/date-adapter-translated.service';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { TimezoneService } from './services/timezone.service';
import { AdminGuard } from './guards/admin.guard';
import { StripeService } from './services/api/stripe.service';
import { NoAuthGuard } from './guards/no-auth.guard';
import { LinkPreviewService } from './services/link-preview.service';
import { GlobalErrorHandler } from './global-error-handler.service';
import { ChatAuthGuard } from './guards/chat-auth.guard';
import { OneSignalService } from './services/onesignal.service';
import { FichiersAnimalDialogModule } from './main/shared/fichiers-animal-dialog/fichiers-animal-dialog.module';
import { ConnecteurService } from './services/api/connecteur.service';
import { StorageModule } from '@ngx-pwa/local-storage';
import { UserPreferenciesService } from './services/user-preferencies.service';
import { environment } from '../environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

import { init, configureScope } from '@sentry/browser';
import { Utils } from './utils';
import { BugService } from './services/api/bug.service';
import { DelayedMessageService } from './services/api/delayed-message.service';
import { TodoService } from './services/api/todo.service';
import { OnboardingService } from './services/api/onboarding.service';
import { IncompatibleBrowserGuard } from './guards/incompatible-browser.guard';
import { take } from 'rxjs/operators';
import { RouterModule } from '@angular/router';

import '@angular/common/locales/global/de';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/en-US-POSIX';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/fr-CA';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/nl';
import '@angular/common/locales/global/pt';
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/hu';

import { PayoutResolver } from './resolver/payout.resolver';
import { WebSocketService } from './services/api/websocket.service';
import { TranslateHttpLoaderFallback } from './config/translate-http-loader-fallback';
import { Veterinaire } from './models/utilisateurs/veterinaire';
import { LanguageConfig } from './models/interfaces/api/conf/conf.interface';
import { AllyDvmService } from './services/api/ally-dvm.service';
import { ConnectionServiceModule, ConnectionServiceOptionsToken } from 'ngx-connection-service';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { initializeAuth, provideAuth } from '@angular/fire/auth';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

init({
    environment: Utils.getEnv(),
    attachStacktrace: true,
    enabled: environment.production,
    dsn: environment.sentryDsn,
    release: `univet-web@${environment.version}`,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    beforeSend(event, hint) {
        const testOne = event.exception?.values[0]?.value?.startsWith('Non-Error exception captured');
        const testTwo = typeof hint?.originalException === 'string' && hint?.originalException?.startsWith('Non-Error exception captured');
        const testThree = Array.isArray(hint?.originalException) && ((hint?.originalException as any).message as string)?.startsWith('Non-Error exception captured');
        const isNonErrorException = testOne || testTwo || testThree;

        if (isNonErrorException) {
            // We want to ignore those kind of errors
            return null;
        }

        return event;
    }
});

configureScope(scope => {
    scope.setTag('session', BugService.session);
});

export function createTranslateLoader(http: HttpClient): TranslateHttpLoaderFallback {
    return new TranslateHttpLoaderFallback(http);
}

export function initLang(
    configService: ConfigService,
    translateService: TranslateService,
    utilisateurService: UtilisateurService
) {
    return async (): Promise<void> => {
        return new Promise(resolve => {
            configService.getLanguagesConfig().pipe(take(1)).subscribe(languages => {
                utilisateurService.utilisateurConnected.subscribe(user => {
                    if (user.locale && translateService.currentLang !== user.locale) {
                        computeLanguage(configService, translateService, languages, user);
                    }
                });

                if (!utilisateurService.isConnected) {
                    computeLanguage(configService, translateService, languages);
                }

                resolve();
            });
        });
    };
}

function computeLanguage(configService: ConfigService,
    translateService: TranslateService,
    languages: LanguageConfig[],
    utilisateur: Veterinaire = null) {
    const userLocale = utilisateur ? utilisateur.locale : navigator.language;
    const userLocaleSimple = userLocale?.slice(0, 2);

    const simpleLanguages = languages.map(l => l.iso.slice(0, 2));
    const langExactSimple = simpleLanguages.find(l => l === userLocaleSimple);

    const langExact = languages.find(l => l.iso === userLocale);
    const langFile = langExact ? langExact.file ?? langExact.iso : langExactSimple ?? 'en';

    document.documentElement.setAttribute('lang', langFile.replace('_', '-'));

    translateService.use(langFile).subscribe(() => {
        configService.forceRefresh();
    });
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),

        StorageModule.forRoot({
            IDBNoWrap: true
        }),

        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalytics),
        NgxGoogleAnalyticsRouterModule,
        ConnectionServiceModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        LayoutModule,

        CalendarModule.forRoot({
            provide: DateAdapterCalendarModule,
            useFactory: adapterFactory
        }),
        LazyLoadImageModule,

        RoutesModule,
        RouterModule,
        FichiersAnimalDialogModule,

        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => initializeAuth(getApp()))
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initLang,
            deps: [ConfigService, TranslateService, UtilisateurService],
            multi: true
        },
        { provide: LOCALE_ID, useValue: navigator.language },

        {
            provide: DateAdapter,
            deps: [MAT_DATE_LOCALE, Platform, TranslateService, UtilisateurService, DateTimeAdapter],
            useFactory: (matDateLocale: string, platform: Platform, translateService: TranslateService, utilisateurService: UtilisateurService, dateTimeAdapter: DateTimeAdapter<any>) => new DateAdapterTranslated(matDateLocale, platform, translateService, utilisateurService, dateTimeAdapter)
        },

        {
            provide: CalendarDateFormatter,
            deps: [DateAdapterCalendarModule, TranslateService],
            useFactory: (dateAdapter: DateAdapterCalendarModule, translateService: TranslateService) => new CalendarDateFormatterTranslated(dateAdapter, translateService)
        },

        {
            provide: MatPaginatorIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new MatPaginatorIntlTranslated(translateService)
        },

        {
            provide: OwlDateTimeIntl,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new OwlDatetimePickerIntlTranslated(translateService)
        },

        {
            provide: CalendarEventTitleFormatter,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => new CustomEventTitleFormatter(translateService)
        },

        { provide: HTTP_INTERCEPTORS, useClass: HttpTokenAndErrorInterceptor, multi: true },

        { provide: ErrorHandler, useClass: GlobalErrorHandler },

        { provide: ConnectionServiceOptionsToken, useValue: { enableHeartbeat: false } },

        CookieService,

        LinkPreviewService,
        ConfigService,
        AuthService,
        UtilisateurService,
        EntiteJuridiqueService,
        ConnecteurService,
        EntiteGeographiqueService,
        RendezVousService,
        AnimalService,
        ChatService,
        TimezoneService,
        StripeService,
        OneSignalService,
        UserPreferenciesService,
        DelayedMessageService,
        TodoService,
        OnboardingService,
        WebSocketService,
        AllyDvmService,

        AuthGuard,
        ChatAuthGuard,
        AdminGuard,
        NoAuthGuard,
        IncompatibleBrowserGuard,

        ChatResolver,
        ChatIdResolver,

        PayoutResolver
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}

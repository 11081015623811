import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { StatsCardComponent } from './stats-card/stats-card.component';
import { FuseWidgetModule } from '../../../../@fuse/components';
import { RendezVousListCardModule } from '../../shared/rendez-vous-list-card/rendez-vous-list-card.module';
import { SharedModule } from '../../../shared.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GaugeCardComponent } from './gauge-card/gauge-card.component';
import { TableCardComponent } from './table-card/table-card.component';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { AppsProCardComponent } from './apps-pro-card/apps-pro-card.component';
import { LinkAppsProModule } from 'app/main/shared/link-apps-pro/link-apps-pro.module';

const routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        DashboardComponent,
        StatsCardComponent,
        GaugeCardComponent,
        TableCardComponent,
        AppsProCardComponent
    ],
    exports: [
        AppsProCardComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseWidgetModule,
        NgxChartsModule,

        SharedModule,
        ViewSharedModule,

        RendezVousListCardModule,
        LinkAppsProModule
    ]
})

export class DashboardModule {}

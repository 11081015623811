import { FuseNavigationItem } from '@fuse/types';

export const navigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    translate: 'NAV.HOME',
    type: 'item',
    icon: 'home',
    url: '/dashboard'
  },
  {
    id: 'services',
    translate: 'NAV.SERVICES',
    type: 'group',
    children: [
      {
        id: 'chat',
        translate: 'NAV.CHAT',
        type: 'item',
        icon: 'chat',
        url: '/chat'
      },
      {
        id: 'appointments',
        translate: 'NAV.PROCHAINS_RDV',
        type: 'item',
        icon: 'videocam',
        url: '/appointments'
      },
      {
        id: 'calendar',
        translate: 'NAV.CALENDRIER',
        type: 'item',
        icon: 'today',
        url: '/calendar'
      },
      {
        id: 'news',
        translate: 'NAV.NEWS',
        type: 'item',
        icon: 'feed',
        url: '/news'
      }
    ]
  },
  {
    id: 'clients',
    translate: 'NAV.CLIENTS_HEADING',
    type: 'group',
    children: [
      {
        id: 'clients',
        translate: 'NAV.CLIENTS',
        type: 'item',
        icon: 'pets',
        url: '/customers'
      }
    ]
  },
  {
    id: 'users',
    translate: 'NAV.USERS_HEADING',
    type: 'group',
    children: [
      {
        id: 'my-account',
        translate: 'NAV.COMPTE',
        type: 'item',
        icon: 'person',
        url: '/account'
      },
      {
        id: 'veterinarians',
        translate: 'NAV.VETERINAIRES',
        type: 'item',
        icon: 'group',
        url: '/veterinarians'
      }
    ]
  },
  {
    id: 'management',
    translate: 'NAV.MANAGEMENT_HEADING',
    type: 'group',
    children: [
      {
        id: 'payments',
        translate: 'NAV.PAIEMENTS',
        type: 'item',
        icon: 'payment',
        url: '/payments'
      },
      {
        id: 'disk-space',
        translate: 'NAV.ESPACE_DISQUE',
        type: 'item',
        icon: 'storage',
        url: '/disk-space'
      }
    ]
  },
  {
    id: 'settings-container',
    translate: 'NAV.SETTINGS',
    type: 'group',
    children: [
      {
        id: 'chat-settings',
        translate: 'NAV.CHAT',
        type: 'item',
        icon: 'rate_review',
        url: '/settings/chats'
      },
      {
        id: 'legal-information',
        translate: 'NAV.ENTITE_JURIDIQUE',
        type: 'item',
        icon: 'account_balance',
        url: '/legal-information'
      },
      {
        id: 'clinics',
        translate: 'NAV.ENTITES_GEOGRAPHIQUE',
        type: 'item',
        icon: 'location_on',
        url: '/clinics'
      },
      {
        id: 'advanced-settings',
        translate: 'NAV.SETTINGS_GLOBAL',
        type: 'collapsable',
        icon: 'build',
        children: [
          {
            id: 'bank-information',
            translate: 'NAV.INFO_BANCAIRE',
            type: 'item',
            icon: 'credit_card',
            url: '/bank-information'
          },
          {
            id: 'connect',
            translate: 'NAV.CONNECTOR',
            type: 'item',
            icon: 'import_export',
            url: '/connect'
          },
          {
            id: 'other',
            translate: 'NAV.OTHER',
            type: 'item',
            icon: 'settings',
            url: '/settings/general'
          }
        ]
      }
    ]
  }
];

import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ClientPostInterface } from '../../../../../models/interfaces/post/utilisateurs/client-post.interface';

@Component({
    selector: 'app-client-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ClientFormComponent implements OnInit, OnDestroy {
    @Input('utilisateurData') utilisateurData$: Observable<ClientPostInterface>;
    @Input() form: FormGroup;
    @Input() disabled = false;

    private subscriptionUserData: Subscription;

    ngOnInit(): void {
        this.subscriptionUserData = this.utilisateurData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((utilisateurData: ClientPostInterface) => {
            this.form.patchValue({
                entiteGeographique: utilisateurData.entiteGeographique
            }, { emitEvent: false });
        });

        if (this.disabled) {
            this.form.disable();
        }
    }

    ngOnDestroy(): void {
        this.subscriptionUserData?.unsubscribe();
    }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { fuseAnimations } from '@fuse/animations';
import { Chat } from 'app/models/animal/chat';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { ChatService } from '../../../../services/api/chat.service';
import { Animal } from '../../../../models/animal/animal';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-chat-start',
    templateUrl: './chat-start.component.html',
    styleUrls: ['./chat-start.component.scss'],
    animations: fuseAnimations
})
export class ChatStartComponent {
    @Input() visio = false;
    @Input() animal: Animal;

    @Output('newChatCreated') newChatCreated$ = new EventEmitter<Chat>();
    hasChats$: Observable<boolean>;

    constructor(private utilisateurService: UtilisateurService, private chatService: ChatService,
        private router: Router) {
        this.hasChats$ = this.chatService.chats.pipe(map(c => c ? c.length > 0 : false));
    }

    addChat(): void {
        const chat = new Chat();
        chat.dateDebut = new Date();
        chat.animal = this.animal;
        chat.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.chatService.openDialogChat(chat).afterClosed().subscribe(result => {
            if (result) {
                this.newChatCreated$.next(result);
                if (!this.visio) {
                    void this.router.navigate(['/chat', result.id], { replaceUrl: true });
                }
            }
        });
    }
}

import { JsonProperty } from 'json-object-mapper';
import { Animal } from '../animal/animal';
import { TypeUtilisateurEnum, Utilisateur } from './utilisateur';
import { List } from '../list';
import { ClientPostInterface } from '../interfaces/post/utilisateurs/client-post.interface';
import { DateMapper } from '../../object-mapper/date-mapper';
import { EntiteJuridique } from '../pro/entite-juridique';
import { EntiteGeographique } from '../pro/entite-geographique';

export class Client extends Utilisateur {
    @JsonProperty({ type: EntiteGeographique })
    entiteGeographique: EntiteGeographique = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateInscription: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateProchainRendezVous?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateDernierRendezVous?: Date = null;

    @JsonProperty({ type: Animal })
    animaux?: Animal[] = null;

    @JsonProperty({ type: EntiteJuridique })
    entiteJuridique?: EntiteJuridique = null;

    @JsonProperty()
    appInstalled: boolean = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    firstConnection: Date = null;

    @JsonProperty()
    cgvMustConfirmed: boolean = null;

    constructor() {
        super();
        this.typeUtilisateur = TypeUtilisateurEnum.client;
    }

    public hasPost(): ClientPostInterface {
        return super.hasPost();
    }
}

export class ListClients extends List {
    @JsonProperty({ type: Client })
    data: Client[] = null;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserNotSupportedComponent } from './browser-not-supported.component';
import { SharedModule } from '../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'not-supported',
        component: BrowserNotSupportedComponent,
        canActivate: [IncompatibleBrowserGuard]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule
    ],
    declarations: [BrowserNotSupportedComponent]
})

export class BrowserNotSupportedModule {}

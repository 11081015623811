<div fxLayout="row wrap" fxLayoutAlign="start start" class="w-100-p">

    <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="w-100-p">
        <mat-form-field appearance="outline" fxFlex="85%">
            <mat-label>{{ 'SHARED.SEARCH' | translate }}</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'SHARED.SEARCH' | translate }}" [value]="selectedMessage" #search>
        </mat-form-field>
        <button type="button" mat-raised-button color="accent" class="mb-20" style="height:60px;" fxFlex="10%"
                [disabled]="search.value?.trim() == ''" (click)="search.value='';filter('')">
            <mat-icon>search_off</mat-icon>
        </button>

        <div *ngIf="datasource.filteredData.length === 0" class="message-box warning mb-16" fxFlex="100%">
            {{ 'SETTINGS.MESSAGES.SHORTCUTS.NONE' | translate }}
        </div>

    </div>

    <div class="mat-elevation-z1" fxLayout="column wrap" fxFlexAlign="start end" fxFlex="100%">

        <mat-table [dataSource]="datasource" matSort fxFlex="100%">

            <ng-container matColumnDef="select">
                <mat-header-cell fxFlex="10%" *matHeaderCellDef>&nbsp;</mat-header-cell>
                <mat-cell fxFlex="10%" *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                                  (change)="updateSelection(row, $event)"
                                  [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
                <mat-header-cell fxFlex="15%" *matHeaderCellDef mat-sort-header>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.GROUP_NAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="15%">
                    {{ (row.category ? row.category : ('SHARED.OTHER' | translate)) | capitalize }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="shortcut">
                <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="20%">
                    <mat-chip-list>
                        <mat-chip>/{{ row.shortcut }}</mat-chip>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="content">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.CONTENT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <p class="truncated" [innerHTML]="row.content | beautifyShortcuts"></p>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['select', 'category', 'shortcut', 'content']"></mat-header-row>

            <mat-row *matRowDef="let row; columns: ['select', 'category', 'shortcut', 'content'];"></mat-row>

        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>

    </div>

</div>

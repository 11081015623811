<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <h2>{{ 'SHARED.GENERAL' | translate }}</h2>

        <mat-form-field appearance="outline" fxFlex="1 1 100%">
            <mat-label>{{ 'FORM.NAME' | translate }}</mat-label>
            <input matInput formControlName="nom" [placeholder]="'FORM.NAME' | translate" required>
            <mat-error *ngIf="form.get('nom').hasError('required')">
                {{ 'FORM.NAME_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutGap="20px">
            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                <mat-label>{{ 'FORM.MAIL' | translate }}</mat-label>
                <input matInput formControlName="mail" [placeholder]="'FORM.MAIL' | translate" required>
                <mat-error *ngIf="form.get('mail').hasError('required')">
                    {{ 'FORM.MAIL_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>

            <app-input-phone fxFlex="1 1 auto" [control]="form.get('telephone')"
                             [required]="true"></app-input-phone>
        </div>

        <h2>{{ 'FORM.ADDRESS' | translate }}</h2>

        <app-input-adresse [controlAdresse]="form.get('adresse')"
                           [controlCodePostal]="form.get('codePostal')"
                           [controlVille]="form.get('ville')"
                           [controlRegion]="form.get('region')"
                           [controlPays]="form.get('pays')"
                           [required]="true" fxFlex="1 1 100%"></app-input-adresse>

        <h2>{{ 'FORM.BILLING_ADDRESS' | translate }}</h2>

        <app-input-adresse [controlAdresse]="form.get('adresseFacturation')"
                           [controlCodePostal]="form.get('codePostalFacturation')"
                           [controlVille]="form.get('villeFacturation')"
                           [controlRegion]="form.get('regionFacturation')"
                           [controlPays]="form.get('paysFacturation')"
                           [required]="true" fxFlex="1 1 100%"></app-input-adresse>

        <h2 *ngIf="inputVat.show">{{ 'FORM.SOCIETY' | translate }}</h2>

        <div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutGap="20px" [class.hidden]="!inputVat.show">
            <!--            <mat-form-field appearance="outline" fxFlex="1 0 auto">-->
            <!--                <mat-label>{{ 'FORM.SOCIETY_SIRET' | translate }}</mat-label>-->
            <!--                <input matInput formControlName="siret" [placeholder]="'FORM.SOCIETY_SIRET' | translate" required>-->
            <!--                <mat-error *ngIf="form.get('siret').hasError('required')">-->
            <!--                    {{ 'FORM.SOCIETY_SIRET_REQUIRED' | translate }}-->
            <!--                </mat-error>-->
            <!--            </mat-form-field>-->

            <!--            <mat-form-field appearance="outline" fxFlex="1 0 auto" class="w-200">-->
            <!--                <mat-label>{{ 'FORM.SOCIETY_STATUS' | translate }}</mat-label>-->
            <!--                <mat-select formControlName="statut" [placeholder]="'FORM.SOCIETY_STATUS' | translate" required>-->
            <!--                    <mat-option value="SARL">Société à responsabilité limitée</mat-option>-->
            <!--                    <mat-option value="SELARL">Société d'exercice libéral</mat-option>-->
            <!--                    <mat-option value="SELAS">Société d’exercice libéral par action simplifiée</mat-option>-->
            <!--                    <mat-option value="SAS">Société par actions simplifiées</mat-option>-->
            <!--                    <mat-option value="SASU">Société par actions simplifiées unipersonnelle</mat-option>-->
            <!--                    <mat-option value="SA">Société anonyme</mat-option>-->
            <!--                    <mat-option value="SNC">Société en nom collectif</mat-option>-->
            <!--                    <mat-option value="SCM">Société civile de moyens</mat-option>-->
            <!--                    <mat-option value="SCP">Société civiles professionnelles</mat-option>-->
            <!--                    <mat-option value="SCEA">Société civile d’exploitation agricole</mat-option>-->
            <!--                    <mat-option value="EARL">Exploitation Agricole à Responsabilité Limitée</mat-option>-->
            <!--                    <mat-option value="GAEC">Groupement agricole d'exploitation en commun</mat-option>-->
            <!--                </mat-select>-->
            <!--                <mat-error *ngIf="form.get('statut').hasError('required')">-->
            <!--                    {{ 'FORM.SOCIETY_STATUS_REQUIRED' | translate }}-->
            <!--                </mat-error>-->
            <!--            </mat-form-field>-->

            <app-input-vat fxFlex="1 0 auto" class="w-200" [controlVat]="form.get('vat')"
                           [country]="form.get('paysFacturation').value" [required]="false"
                           [showValidateButton]="false" #inputVat></app-input-vat>

            <!--            <app-input-currency [onlyOne]="true" [required]="true" [control]="form.get('currency')" fxFlex="1 0 auto" [country]="form.get('paysFacturation').value"></app-input-currency>-->
        </div>

        <h2>{{ 'FORM.OWNER.TITLE' | translate }}</h2>

        <div fxLayout="column" fxFlex="1 1 100%" fxLayoutGap="20px">
          <app-input-veterinaire [control]="form.get('ownerId')"></app-input-veterinaire>
        </div>

        <h2>{{ 'FORM.PRICE_VAT.TITLE' | translate }}</h2>

        <div fxLayout="column" fxFlex="1 1 100%" fxLayoutGap="20px">
            <mat-slide-toggle formControlName="appointmentVatEnabled">
                {{ 'FORM.PRICE_VAT.ENABLED' | translate }}
            </mat-slide-toggle>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'FORM.PRICE_VAT.PERCENT' | translate }}</mat-label>
                <span matSuffix>%</span>
                <input matInput placeholder="20" formControlName="appointmentVatPercent" name="appointmentVatPercent"
                       min="0" max="100" type="number"/>
                <mat-error class="warn-fg" *ngIf="form.get('appointmentVatPercent').hasError('required')">
                    {{ 'SHARED.REQUIRED' | translate }}
                </mat-error>
                <mat-error class="warn-fg" *ngIf="form.get('appointmentVatPercent').hasError('min')">
                    {{ 'SHARED.PERCENT_INVALID' | translate }}
                </mat-error>
                <mat-error class="warn-fg" *ngIf="form.get('appointmentVatPercent').hasError('max')">
                    {{ 'SHARED.PERCENT_INVALID' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- <mat-expansion-panel class="mb-16" *ngIf="form.enabled">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{ 'SHARED.TERMS_SALES' | translate }}
                <b class="warn-fg">&nbsp;{{ (form.get('cgv').hasError('required') ? ('SHARED.REQUIRED' | translate | lowercase) : '') }}</b>
            </mat-panel-title>
            <mat-panel-description>
                {{ 'SHARED.CLIC_TO_MODIFY' | translate }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <app-ngx-editor [placeholder]="('SHARED.FREE_ZONE_FOR_TERMS_SALES' | translate)" [spellcheck]="true"
                        formControlName="cgv" [toolbar]="toolbarWysiwyg" minHeight="300px"></app-ngx-editor>
    </mat-expansion-panel> -->

    <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
        <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
            <mat-icon>save</mat-icon>
            {{ 'FORM.SAVE' | translate }}
        </button>
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="(formInProgress$ | async)"></mat-spinner>
    </div>
</form>

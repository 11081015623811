import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { ConnectorComponent } from './connector.component';
import { SharedModule } from '../../../shared.module';
import { AdminGuard } from '../../../guards/admin.guard';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { ConnectorDetailComponent } from './connector-detail/connector-detail.component';
import { ConnectorImportComponent } from './connector-import/connector-import.component';
import { ConnectorGuard } from '../../../guards/connector.guard';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { AllyDvmDialogComponent } from './allydvm-dialog/allydvm-dialog.component';

const routes = [
    {
        path: 'connect',
        component: ConnectorComponent,
        canActivate: [AdminGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'connect/import',
        component: ConnectorImportComponent,
        canActivate: [ConnectorGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        ConnectorComponent,
        ConnectorDetailComponent,
        ConnectorImportComponent,
        AllyDvmDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule,
        NgxCsvParserModule
    ]
})

export class ConnectorModule {}

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-input-adresse',
    templateUrl: './input-adresse.component.html',
    styleUrls: ['./input-adresse.component.scss']
})
export class InputAdresseComponent {
    @Input() controlAdresse: AbstractControl;
    @Input() controlCodePostal: AbstractControl;
    @Input() controlRegion: AbstractControl;
    @Input() controlVille: AbstractControl;
    @Input() controlPays: AbstractControl;
    @Input() required: boolean;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Connecteur } from 'app/models/interfaces/post/pro/connecteur.interface';
import { EntiteJuridique } from 'app/models/pro/entite-juridique';
import { ConfigService } from 'app/services/config.service';
import { MatDialog } from '@angular/material/dialog';
import { AllyDvmDialogComponent } from './allydvm-dialog/allydvm-dialog.component';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-settings',
    templateUrl: './connector.component.html',
    styleUrls: ['./connector.component.scss'],
    animations: fuseAnimations
})
export class ConnectorComponent implements OnInit, OnDestroy {
    connecteurs$: Observable<Connecteur[]>;
    visibleConnecteur = new BehaviorSubject<Connecteur>(null);

    entiteJuridiqueSubscription: Subscription;
    connecteursSubscription: Subscription;
    statsSubscription: Subscription;

    connecteurEnabled = false;
    allydvmEnabled = false;
    apiKey: string;

    constructor(
        private entiteJuridiqueService: EntiteJuridiqueService,
        private configService: ConfigService,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.connecteurs$ = this.configService.getConnectors();
        this.connecteursSubscription = this.connecteurs$.subscribe(connecteurs => {
            this.entiteJuridiqueSubscription = this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.subscribe(entiteJuridique => {
                this.apiKey = entiteJuridique.vetSoftApiKeyToken;
                this.connecteurEnabled = entiteJuridique.vetSoft !== null;
                this.allydvmEnabled = entiteJuridique.isSubscriptionManaged || environment.hmr || environment.dev || environment.demo;
                this.setVisibleConnecteur(entiteJuridique.vetSoft ? connecteurs.find(connecteur => connecteur.id === entiteJuridique.vetSoft) : null);
            });
        });
    }

    ngOnDestroy(): void {
        this.entiteJuridiqueSubscription?.unsubscribe();
        this.connecteursSubscription?.unsubscribe();
        this.statsSubscription?.unsubscribe();
    }

    setVisibleConnecteur(connecteur: Connecteur): void {
        this.visibleConnecteur.next(connecteur);
    }

    refresh(entiteJuridique: EntiteJuridique): void {
        this.entiteJuridiqueService.updateEntiteJuridiqueForUtilisateurConnected(entiteJuridique);
    }

    openAllyDvmDialog(): void {
        this.dialog.open(AllyDvmDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '450px',
            disableClose: true
        });
    }
}

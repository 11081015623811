import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { Subject, Subscription } from 'rxjs';
import { EntiteJuridiqueSettingsPostInterface } from '../../../../models/interfaces/post/pro/entite-juridique-settings-post.interface';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChatScenario, ChatShortcut, EntiteJuridique } from '../../../../models/pro/entite-juridique';
import { environment } from '../../../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { ChatService } from '../../../../services/api/chat.service';
import { ConnecteurService } from '../../../../services/api/connecteur.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';
import {
    ShortcutDialogComponent,
    ShortcutDialogInterface
} from '../../form-dialog/shortcut-dialog/shortcut-dialog.component';
import { DialogComponent } from '../../view-utils/dialog/dialog.component';
import {
    ChatScenarioDialogComponent,
    ChatScenarioDialogInterface
} from '../../../content/settings/chat-scenario-dialog/chat-scenario-dialog.component';

@Component({
  selector: 'app-settings-chat-form',
  templateUrl: './settings-chat-form.component.html',
  styleUrls: ['./settings-chat-form.component.scss'],
  animations: fuseAnimations
})
export class SettingsChatFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<EntiteJuridiqueSettingsPostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() disabled = false;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<EntiteJuridiqueSettingsPostInterface>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<EntiteJuridique>();

    @ViewChild('colorPicker', { read: ElementRef }) private colorPicker: ElementRef;

    form: FormGroup;

    isDev = environment.hmr || environment.dev;
    shortcuts: ChatShortcut[] = [];
    chatShortcutsDataSource = new MatTableDataSource<ChatShortcut>(null);
    chatScenariosDataSource = new MatTableDataSource<ChatScenario>(null);

    selectedColor?: string = null;

    private subscriptionEntityData: Subscription;

    @HostListener('document:click', ['$event'])
    clickout(event: MouseEvent): void {
        if (!this.colorPicker?.nativeElement?.contains(event.target) &&
            !(event.target as Element).classList.contains('color')) {
            this.selectedColor = null;
        }
    }

    constructor(
        private entiteJuridiqueService: EntiteJuridiqueService,
        private utilisateurService: UtilisateurService,
        private chatService: ChatService,
        private connecteurService: ConnecteurService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            id: new FormControl({ value: null, disabled: true }, [Validators.required]),
            chatShortcuts: new FormControl(this.chatShortcutsDataSource.data),
            chatScenarios: new FormControl(this.chatScenariosDataSource.data),
            obfuscateVeterinaries: new FormControl(),
            chatListColors: new FormControl([]),
            appointmentType: new FormControl(0, [Validators.required]),
            appointmentTypeValue: new FormControl({ value: null, disabled: true }, [Validators.required])
        });

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.form.get('appointmentType').valueChanges.subscribe(value => {
            const appointmentTypeValue = this.form.get('appointmentTypeValue');
            appointmentTypeValue.setValue(null);
            if (value === 2) {
                appointmentTypeValue.enable();
            } else {
                appointmentTypeValue.disable();
            }
        });

        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((entiteJuridiqueSettingsData: EntiteJuridiqueSettingsPostInterface) => {
            this.form.patchValue({
                id: entiteJuridiqueSettingsData.id,
                chatShortcuts: entiteJuridiqueSettingsData.chatShortcuts,
                chatScenarios: entiteJuridiqueSettingsData.chatScenarios,
                obfuscateVeterinaries: entiteJuridiqueSettingsData.obfuscateVeterinaries,
                chatListColors: entiteJuridiqueSettingsData.chatListColors
            }, { emitEvent: false });

            this.shortcuts = entiteJuridiqueSettingsData.chatShortcuts;
            this.chatShortcutsDataSource.data = this.shortcuts;
            this.chatScenariosDataSource.data = entiteJuridiqueSettingsData.chatScenarios;
        });

        if (this.disabled) {
            this.form.disable();
        }
    }

    ngOnDestroy(): void {
        this.subscriptionEntityData?.unsubscribe();
    }

    submit(): void {
        if (this.form.valid) {
            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save();
            }
        }
    }

    addOrEditShortcut(shortcut?: ChatShortcut): void {
        const data: ShortcutDialogInterface = {
            chatShortcut: shortcut
        };
        this.dialog.open(ShortcutDialogComponent, {
            data: shortcut !== null && shortcut !== undefined ? data : null,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        }).afterClosed().subscribe((formValue?: ChatShortcut) => {
            if (formValue !== null && formValue !== undefined) {
                const shortcuts: ChatShortcut[] = this.chatShortcutsDataSource.data ? this.chatShortcutsDataSource.data : [];
                const index = shortcuts.findIndex(short => short.shortcut === formValue.shortcut);
                if (index === -1) {
                    shortcuts.push(formValue);
                } else {
                    shortcuts[index] = formValue;
                }

                this.form.get('chatShortcuts').setValue(shortcuts);
                this.chatShortcutsDataSource.data = shortcuts;
                this.save();
            }
        });
    }

    removeShortcut(shortcut: ChatShortcut): void {
        const dialog = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.DELETE_DIALOG.TITLE'),
                content: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.DELETE_DIALOG.CONTENT'),
                action: true,
                cancel: this.translateService.instant('SHARED.CANCEL'),
                ok: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.DELETE_DIALOG.OK')
            },
            disableClose: true
        });
        dialog.afterClosed().subscribe(res => {
            if (res) {
                let shortcuts: ChatShortcut[] = this.chatShortcutsDataSource.data ? this.chatShortcutsDataSource.data : [];
                shortcuts = shortcuts.filter(shorts => shortcut.shortcut !== shorts.shortcut);
                this.form.get('chatShortcuts').setValue(shortcuts);
                this.chatShortcutsDataSource.data = shortcuts;
                this.save();
            }
        });
    }

    resetShortcuts(): void {
        const dialog = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.RESTORE_DEFAULT.TITLE'),
                content: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.RESTORE_DEFAULT.CONTENT'),
                action: true,
                cancel: this.translateService.instant('SHARED.CANCEL'),
                ok: this.translateService.instant('SETTINGS.MESSAGES.SHORTCUTS.RESTORE_DEFAULT.OK')
            },
            disableClose: true
        });

        dialog.afterClosed().subscribe(res => {
            if (res) {
                this.entiteJuridiqueService.getChatDefaultShortcuts(this.form.get('id').value)
                    .subscribe(shortcuts => {
                        this.form.get('chatShortcuts').setValue(shortcuts);
                        this.chatShortcutsDataSource.data = shortcuts;
                        this.save();
                    });
            }
        });
    }

    addOrEditScenario(scenario: ChatScenario = null, index = 0): void {
        const data: ChatScenarioDialogInterface = {
            scenario: scenario ?? new ChatScenario(),
            shortcuts: this.shortcuts
        };
        this.dialog.open(ChatScenarioDialogComponent, {
            data: data,
            panelClass: 'no-padding-dialog',
            width: '800px',
            disableClose: true
        }).afterClosed().subscribe((formValue?: ChatScenario) => {
            if (formValue) {
                const scenarios: ChatScenario[] = this.chatScenariosDataSource.data ? this.chatScenariosDataSource.data : [];
                if (scenario) {
                    scenarios[index] = formValue;
                } else {
                    scenarios.push(formValue);
                }

                this.form.get('chatScenarios').setValue(scenarios);
                this.chatScenariosDataSource.data = scenarios;
                this.save();
            }
        });
    }

    removeScenario(index: number): void {
        const dialog = this.dialog.open(DialogComponent, {
            width: '350px',
            data: {
                title: this.translateService.instant('SETTINGS.MESSAGES.SCENARIOS.DELETE_DIALOG.TITLE'),
                content: this.translateService.instant('SETTINGS.MESSAGES.SCENARIOS.DELETE_DIALOG.CONTENT'),
                action: true,
                cancel: this.translateService.instant('SHARED.CANCEL'),
                ok: this.translateService.instant('SETTINGS.MESSAGES.SCENARIOS.DELETE_DIALOG.OK')
            },
            disableClose: true
        });
        dialog.afterClosed().subscribe(res => {
            if (res) {
                const scenarios: ChatScenario[] = this.chatScenariosDataSource.data;
                scenarios.splice(index, 1);
                this.form.get('chatScenarios').setValue(scenarios);
                this.chatScenariosDataSource.data = scenarios;
                this.save();
            }
        });
    }

    private save() {
        this.selectedColor = null;
        this.formInProgress$.next(true);
        this.entiteJuridiqueService.updateEntiteJuridiqueSettings(this.form.getRawValue()).subscribe({
            next: entiteJuridique => {
                this.form.markAsPristine();
                this.formInProgress$.next(false);
                this.entityReceived$.next(entiteJuridique);
                this.chatService.getChatsApi(true).subscribe();
            },
            error: () => this.formInProgress$.next(false)
        });
    }

    showColorPicker(color: string): void {
        this.selectedColor = color;
    }

    resetChatColors(): void {
        this.selectedColor = null;
        this.form.patchValue({
            chatListColors: null
        });
        this.save();
    }

    updateChatColor(newColor: string, oldColor: string): void {
        const currentValues: string[] = this.form.get('chatListColors').value ?? [];
        const index = currentValues.indexOf(oldColor);
        if (index !== -1) {
            this.selectedColor = newColor;
            currentValues[index] = newColor;
            this.form.patchValue({
                chatListColors: currentValues
            });
        }
    }
}

import { Component, Input } from '@angular/core';
import { Fichier } from '../../../../models/fichier';
import { Utilisateur } from '../../../../models/utilisateurs/utilisateur';
import { UtilisateurPostInterface } from '../../../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { Animal } from '../../../../models/animal/animal';
import { AnimalPostInterface } from '../../../../models/interfaces/post/animal/animal-post.interface';
import { ActionsMenuInterface } from '../actions-menu/actions-menu.component';

@Component({
    selector: 'app-profile-tile',
    templateUrl: './profile-tile.component.html',
    styleUrls: ['./profile-tile.component.scss']
})
export class ProfileTileComponent {
    @Input() id: number;
    @Input() title: string;
    @Input() photo: Fichier;
    @Input() actions: ActionsMenuInterface[];
    @Input() entity: Utilisateur | UtilisateurPostInterface | Animal | AnimalPostInterface;
}

<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">
    <div *ngIf="entityLinked" class="mb-16 text-bold warn-fg">
        {{ 'CONNECTOR.ENTITY_LINKED' | translate }}
    </div>

    <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>{{ 'DATE.SUBJECT' | translate }}</mat-label>
        <input matInput formControlName="nature" [placeholder]="'DATE.SUBJECT' | translate" required>
        <mat-error *ngIf="form.get('nature').hasError('required')">
            {{ 'DATE.SUBJECT_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="1 0 1">
        <mat-label>{{ 'DATE.CATEGORY' | translate }}</mat-label>
        <mat-select [placeholder]="'DATE.CATEGORY' | translate" formControlName="categorie" required>
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="categoriesFilter"
                    [placeholderLabel]="'DATE.CATEGORY_SEARCH' | translate"
                    [noEntriesFoundLabel]="'DATE.CATEGORY_ANY_FOUND' | translate">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let categorie of filteredCategoriesOptions$ | async" [value]="categorie.id">
                {{ categorie.value }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('categorie').hasError('required')">
            {{ 'DATE.CATEGORY_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="8px">
        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>{{ 'DATE.DATE' | translate }}</mat-label>
            <input matInput [owlDateTimeTrigger]="datePicker" [owlDateTime]="datePicker"
                   [placeholder]="'DATE.DATE' | translate" readonly (click)="datePicker.open()"
                   formControlName="date" required (focus)="datePicker.open()" [min]="minDate">
            <mat-icon matSuffix [owlDateTimeTrigger]="datePicker">today</mat-icon>
            <owl-date-time #datePicker [stepMinute]="5" [firstDayOfWeek]="null | firstDayWeek"
                           [hour12Timer]="null | twelveHour"></owl-date-time>
            <mat-error *ngIf="form.get('date').hasError('required')">
                {{ 'DATE.DATE_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>{{ 'DATE.DURATION_EXPECTED' | translate }}</mat-label>
            <input [placeholder]="'DATE.DURATION_EXPECTED' | translate" matInput formControlName="dureePrevue" required
                   min="1" type="number" step="1" style="text-align: right;">
            <span matSuffix>{{ 'TIME.MIN' | translate }}</span>
            <mat-error *ngIf="form.get('dureePrevue').hasError('required')">
                {{ 'DATE.DURATION_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxFlex="1 0 auto" fxLayout="column" fxLayout.gt-xs="row" fxLayoutGap="8px">
        <mat-button-toggle-group formControlName="isFree" fxFlex="0 0 auto">
            <mat-button-toggle [value]="true">{{ 'SHARED.FREE' | translate }}</mat-button-toggle>
            <mat-button-toggle [disabled]="!paymentEnabled"
                               [value]="false">{{ 'SHARED.NOT_FREE' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>

        <mat-form-field appearance="outline" fxFlex="50" *ngIf="usePriceHt">
            <mat-label>{{ 'SHARED.PRICE_HT' | translate }}</mat-label>
            <input [placeholder]="'SHARED.PRICE_HT' | translate" matInput formControlName="priceWithoutVat"
                   currencyMask [options]="getCurrencyInputOptions()">
            <mat-error *ngIf="form.get('priceWithoutVat').hasError('min')">
                {{ 'FORM.PRICE.PRICE_MIN' | translate:{min: form.get('priceWithoutVat').getError('min')} }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="50">
            <mat-label>{{ 'SHARED.PRICE' | translate }}</mat-label>
            <input [placeholder]="'SHARED.PRICE' | translate" matInput formControlName="prix"
                   currencyMask [options]="getCurrencyInputOptions()" required>
            <mat-hint *ngIf="!paymentEnabled">{{ 'PAYMENTS.DISABLED_SPEECH' | translate }}</mat-hint>
            <mat-hint class="text-bold"
                      *ngIf="pricePayed > 0">{{ 'RENDEZ_VOUS.IS_CAPTURED' | translate:{date: (datePayed | dateLocale:'shortDate')} }}
                &middot; {{ pricePayed | currencyLocale:form.get('currency').value }}</mat-hint>
            <mat-error *ngIf="form.get('prix').hasError('required')">
                {{ 'FORM.PRICE.PRICE_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('prix').hasError('min')">
                {{ 'FORM.PRICE.PRICE_MIN' | translate:{min: form.get('prix').getError('min')} }}
            </mat-error>
        </mat-form-field>

        <app-input-currency fxFlex="50" [country]="country" [control]="form.get('currency')"></app-input-currency>
    </div>

    <app-input-animal [control]="form.get('animalId')"></app-input-animal>

    <app-input-veterinaire [control]="form.get('veterinaire')"></app-input-veterinaire>

    <mat-form-field appearance="outline" class="w-100-p">
        <mat-label>{{ 'DATE.COMMENTS_ONLY_YOU' | translate }}</mat-label>
        <textarea matInput formControlName="commentaire" [placeholder]="'DATE.COMMENTS_ONLY_YOU' | translate"
                  mat-maxlength="250" max-rows="4">
        </textarea>
    </mat-form-field>

    <app-input-connector *ngIf="form.get('id').value && (connecteurService.isConnecteurFeatureEnabled('APPOINTMENT') | async)" [control]="form.get('connectorId')"></app-input-connector>

    <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutAlign="space-between center">
        <button *ngIf="form.get('id').value && mustCapture" mat-raised-button color="primary" (click)="capture()"
                type="button">
            <mat-icon>payment</mat-icon>
            {{ 'SHARED.DIALOG_CAPTURE.CAPTURE' | translate }}
        </button>

        <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="(form.enabled || canBeRemoved) && !entityLinked"
             fxLayoutGap="8px" fxFlex="1 1 auto">
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-button color="warn" (click)="remove()" type="button"
                        [disabled]="(formInProgress$ | async) || (formInProgressDelete$ | async)" *ngIf="canBeRemoved">
                    <mat-icon>delete</mat-icon>
                    {{ 'SHARED.DELETE' | translate }}
                </button>
            </div>

            <button type="submit" mat-raised-button color="accent" *ngIf="form.enabled"
                    [disabled]="(formInProgress$ | async) || (formInProgressDelete$ | async)">
                <mat-icon>save</mat-icon>
                {{ 'FORM.SAVE' | translate }}
            </button>
            <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                         *ngIf="(formInProgress$ | async) || (formInProgressDelete$ | async)"></mat-spinner>
        </div>
    </div>
</form>

import NetworkTest from 'opentok-network-test-js';
import { NgZone, ChangeDetectorRef, OnDestroy, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OpentokService } from '../../../services/opentok.service';
import { DevicePermission, VisioService } from '../../../services/visio.service';
import { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import { environment } from '../../../../environments/environment';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
    selector: 'app-connection-test',
    templateUrl: './connection-test.component.html',
    styleUrls: ['./connection-test.component.scss'],
    providers: [VisioService, OpentokService]
})
export class ConnectionTestComponent implements OnDestroy {
    name: string;
    phone: string;

    DevicePermission = DevicePermission;

    isTesting = false;
    testProgress = 0;
    testMode: ProgressBarMode = 'indeterminate';
    testingTime = 20_000;
    testStatus = '';
    testRemainingTime = undefined;

    isResult: boolean = undefined;
    resultString: string = undefined;

    resultSpeedVideo: number = undefined;
    resultSpeedAudio: number = undefined;
    resultPacketLostVideo: number = undefined;
    resultPacketLostAudio: number = undefined;

    private currentOtNetworkTest: NetworkTest = undefined;

    constructor(
        private ngZone: NgZone,
        private router: Router,
        private route: ActivatedRoute,
        private ref: ChangeDetectorRef,
        private opentokService: OpentokService,
        public visioService: VisioService
    ) {}

    ngOnDestroy(): void {
        this.stopTest();
    }

    launchTest(): void {
        this.setProgress(0, undefined, false);
        this.setResult(
            true,
            undefined,
            'TEST.CONNECTING'
        );

        const existingPublisher = this.visioService.currentPublisherObject;
        if (existingPublisher) {
            existingPublisher.destroy();
        }

        this.visioService.getTestRoom().subscribe({
            next: room => {
                this.visioService.onTestStart.emit();
                const otNetworkTest = new NetworkTest(OT as any, {
                    apiKey: room.apiKey,
                    sessionId: room.idSession,
                    token: room.token
                }, {
                    audioOnly: false,
                    timeout: this.testingTime
                });

                this.currentOtNetworkTest = otNetworkTest;

                let count = 0;
                otNetworkTest.testConnectivity().then(() => {
                    this.setResult(
                        true,
                        undefined,
                        'TEST.TESTING'
                    );
                    otNetworkTest.testQuality(_unused => {
                        count++;
                        const timePassed = count * 1000;
                        const progression = timePassed / this.testingTime * 100;
                        this.setProgress(progression, (this.testingTime - timePassed) / 1000, true);
                    }).then((results: QualityTestResults) => {
                        if (environment.dev || environment.hmr) {
                            console.log('Test result', results);
                        }

                        if (!results.video.supported && !results.audio.supported) {
                            this.setResult(
                                false,
                                false,
                                'TEST.DONE',
                                'TEST.RESULT.VIDEO_AND_AUDIO_NOT_SUPPORTED',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        } else if (!results.video.supported) {
                            this.setResult(
                                false,
                                false,
                                'TEST.DONE',
                                'TEST.RESULT.VIDEO_NOT_SUPPORTED',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        } else if (!results.audio.supported) {
                            this.setResult(
                                false,
                                false,
                                'TEST.DONE',
                                'TEST.RESULT.AUDIO_NOT_SUPPORTED',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        } else if (results.video.reason) {
                            this.setResult(
                                false,
                                false,
                                'TEST.DONE',
                                'TEST.RESULT.NOT_ENOUGH',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        } else if (results.audio.reason) {
                            this.setResult(
                                false,
                                false,
                                'TEST.DONE',
                                'TEST.RESULT.AUDIO_ONLY',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        } else {
                            this.setResult(
                                false,
                                true,
                                'TEST.DONE',
                                'TEST.RESULT.OK',
                                results.video.bitrate,
                                results.audio.bitrate,
                                results.video.packetLossRatio,
                                results.audio.packetLossRatio
                            );
                        }

                        this.setProgress(0, undefined, false);
                        this.currentOtNetworkTest = undefined;
                        this.visioService.setCurrentPublisher(null);
                        this.visioService.onTestEnd.emit();
                    }).catch(err => {
                        console.error(err);
                        this.setResult(false, false, 'TEST.DONE', 'TEST.RESULT.CONNECTIVITY_ERROR');
                        this.setProgress(0, undefined, false);
                        this.currentOtNetworkTest = undefined;
                        this.visioService.setCurrentPublisher(null);
                        this.visioService.onTestEnd.emit();
                    });
                }).catch(err => {
                    console.error(err);
                    this.setResult(false, false, 'TEST.DONE', 'TEST.RESULT.CONNECTIVITY_ERROR');
                    this.setProgress(0, undefined, false);
                    this.currentOtNetworkTest = undefined;
                    this.visioService.setCurrentPublisher(null);
                    this.visioService.onTestEnd.emit();
                });
            },
            error: err => {
                console.error(err);
                this.setResult(false, false, 'TEST.DONE', 'TEST.RESULT.CONNECTIVITY_ERROR');
                this.setProgress(0, undefined, false);
                this.currentOtNetworkTest = undefined;
                this.visioService.setCurrentPublisher(null);
                this.visioService.onTestEnd.emit();
            }
        });
    }

    stopTest(): void {
        if (this.currentOtNetworkTest) {
            this.currentOtNetworkTest.stop();
        }
    }

    private setResult(
        isTesting: boolean,
        isResult: boolean,
        status: string,
        text?: string,
        resultSpeedVideo?: number,
        resultSpeedAudio?: number,
        resultPacketLostVideo?: number,
        resultPacketLostAudio?: number
    ) {
        this.ngZone.run(() => {
            this.isTesting = isTesting;
            this.isResult = isResult;
            this.testStatus = status;
            this.resultString = text;
            this.resultSpeedVideo = resultSpeedVideo;
            this.resultSpeedAudio = resultSpeedAudio;
            this.resultPacketLostVideo = resultPacketLostVideo;
            this.resultPacketLostAudio = resultPacketLostAudio;
        });
    }

    private setProgress(
        progression: number,
        remainingTime: number,
        determinate: boolean
    ) {
        this.ngZone.run(() => {
            this.testProgress = progression;
            this.testRemainingTime = remainingTime;
            this.testMode = determinate ? 'determinate' : 'indeterminate';
        });
    }
}

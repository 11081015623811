<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">
    <mat-accordion class="my-16" [multi]="true">


        <!-- ANONYMAT-->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>fingerprint</mat-icon>
                    <span>{{ 'SETTINGS.MESSAGES.OBFUSCATE.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="end end">
                <mat-slide-toggle formControlName="obfuscateVeterinaries" fxFlex="0 0 0">
                    {{ 'SETTINGS.MESSAGES.OBFUSCATE.HINT' | translate }}
                </mat-slide-toggle>
                <p [innerHTML]="'SETTINGS.MESSAGES.OBFUSCATE.DESCRIPTION' | translate" fxFlex="1 1 auto"
                   class="description"></p>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
                <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
                    <mat-icon>save</mat-icon>
                    {{ 'FORM.SAVE' | translate }}
                </button>
                <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                             *ngIf="(formInProgress$ | async)"></mat-spinner>
            </div>
        </mat-expansion-panel>


        <!-- COULEURS-->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>palette</mat-icon>
                    <span>{{ 'SETTINGS.MESSAGES.COLORS.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="start end">
                <p [innerHTML]="'SETTINGS.MESSAGES.COLORS.DESCRIPTION' | translate" fxFlex="1 1 auto" class="description"></p>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="10px" [style.marginBottom]="'16px'">
                <div class="color"
                      *ngFor="let color of form.get('chatListColors').value"
                      [style.backgroundColor]="color"
                      [matTooltip]="'SETTINGS.MESSAGES.COLORS.EDIT' | translate"
                      (click)="showColorPicker(color)">
                </div>
                <color-chrome
                    #colorPicker
                    [color]="selectedColor"
                    [disableAlpha]="true"
                    *ngIf="selectedColor !== null"
                    (onChangeComplete)="updateChatColor($event.color.hex, selectedColor)">
                </color-chrome>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="10px" *ngIf="form.enabled">
                <button type="button" mat-raised-button color="primary" [disabled]="(formInProgress$ | async)"
                        (click)="resetChatColors()">
                    <mat-icon>refresh</mat-icon>
                    {{ 'FORM.RESET' | translate }}
                </button>
                <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
                    <mat-icon>save</mat-icon>
                    {{ 'FORM.SAVE' | translate }}
                </button>
                <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                             *ngIf="(formInProgress$ | async)"></mat-spinner>
            </div>
        </mat-expansion-panel>



        <!-- MESSAGES TYPES-->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>comment</mat-icon>
                    <span>{{ 'SETTINGS.MESSAGES.SHORTCUTS.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="start start">
                <p [innerHTML]="'SETTINGS.MESSAGES.SHORTCUTS.OVERVIEW' | translate" fxFlex="1 1 auto" class="description-left"></p>
            </div>

            <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="10px">

                <app-settings-shortcut-message
                    [chatShortcutsDataSource]="chatShortcutsDataSource"
                    (addOrEditShortcut)="addOrEditShortcut($event)"
                    (removeShortcut)="removeShortcut($event)">
                </app-settings-shortcut-message>

                <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
                    <button type="button" mat-button color="warn" (click)="resetShortcuts()">
                        <mat-icon>refresh</mat-icon>
                        {{ 'SETTINGS.MESSAGES.SHORTCUTS.RESET' | translate }}
                    </button>
                    <button type="button" mat-raised-button color="primary" (click)="addOrEditShortcut()">
                        <mat-icon>add</mat-icon>
                        {{ 'SETTINGS.MESSAGES.SHORTCUTS.ADD' | translate }}
                    </button>
                    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
                        <button type="submit" mat-raised-button color="accent"
                                [disabled]="(formInProgress$ | async)">
                            <mat-icon>save</mat-icon>
                            {{ 'FORM.SAVE' | translate }}
                        </button>
                        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                                     *ngIf="(formInProgress$ | async)"></mat-spinner>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>


        <!-- SCENARIOS-->
        <mat-expansion-panel [expanded]="true">

            <mat-expansion-panel-header>
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-icon>engineering</mat-icon>
                    <span>{{ 'SETTINGS.MESSAGES.SCENARIOS.TITLE' | translate }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div fxLayout="column" fxLayoutAlign="start start">
                <p [innerHTML]="'SETTINGS.MESSAGES.SCENARIOS.DESCRIPTION' | translate" fxFlex="1 1 auto" class="description-left"></p>
            </div>

            <div fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="10px">
                <mat-table [dataSource]="chatScenariosDataSource"
                           class="mat-elevation-z1" style="width: 100%">
                    <ng-container matColumnDef="title">
                        <mat-header-cell fxFlex="70%" *matHeaderCellDef>
                            {{ 'SETTINGS.MESSAGES.SCENARIOS.TITLE' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row" fxFlex="70%">
                            {{ row.title | capitalize }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="nbMessages">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'SETTINGS.MESSAGES.SCENARIOS.NB_MESSAGES' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row">{{ row.messages.length }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell fxFlex="12%" *matHeaderCellDef>
                            {{ 'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.LABEL' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let row; let index = index" fxFlex="12%">
                            <button type="button" mat-icon-button (click)="addOrEditScenario(row, index)"
                                    [matTooltip]="'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.EDIT' | translate">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button type="button" mat-icon-button color="warn" (click)="removeScenario(index)"
                                    [matTooltip]="'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.DELETE' | translate">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="['title', 'nbMessages', 'actions']"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: ['title', 'nbMessages', 'actions'];"></mat-row>
                </mat-table>
                <div fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="10px">
                    <button type="button" mat-raised-button color="primary" (click)="addOrEditScenario()">
                        <mat-icon>add</mat-icon>
                        {{ 'SETTINGS.MESSAGES.SCENARIOS.ACTIONS.ADD' | translate }}
                    </button>
                    <div fxLayout="row" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
                        <button type="submit" mat-raised-button color="accent"
                                [disabled]="(formInProgress$ | async)">
                            <mat-icon>save</mat-icon>
                            {{ 'FORM.SAVE' | translate }}
                        </button>
                        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                                     *ngIf="(formInProgress$ | async)"></mat-spinner>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>

    </mat-accordion>
</form>


import { JsonProperty } from 'json-object-mapper';
import { ExternalAccountPostInterface } from '../../interfaces/post/pro/stripe/external-account-post.interface';

export class ExternalAccount {
    @JsonProperty()
    accountNumber: string = null;

    @JsonProperty()
    routingNumber: string = null;

    public hasPost(): ExternalAccountPostInterface {
        return {
            accountNumber: this.accountNumber,
            routingNumber: this.routingNumber
        };
    }
}


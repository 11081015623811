import { ApplicationRef, NgModuleRef } from '@angular/core';
import { createNewHosts } from '@angularclass/hmr';

export const hmrBootstrap = (module: { hot: any }, bootstrap: () => Promise<NgModuleRef<any>>): void => {
    let ngModule: NgModuleRef<any>;
    module.hot.accept();
    void bootstrap().then(mod => {
        ngModule = mod;
    });
    module.hot.dispose(() => {
        const appRef: ApplicationRef = ngModule.injector.get(ApplicationRef);
        const elements = appRef.components.map(c => c.location.nativeElement as HTMLElement);
        const makeVisible = createNewHosts(elements);
        ngModule.destroy();
        makeVisible();
    });
};

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecordingOptions, TemplatePostInterface } from '../../../../../../models/interfaces/template-post.interface';
import { TranslateService } from '@ngx-translate/core';

export interface DialogData {
    template: TemplatePostInterface;
}

@Component({
  selector: 'app-template-recording-dialog',
  templateUrl: './template-recording-dialog.component.html',
  styleUrls: ['./template-recording-dialog.component.scss']
})
export class TemplateRecordingDialogComponent {
    warningMsg: string;

    constructor(
        public dialogRef: MatDialogRef<TemplateRecordingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private translateService: TranslateService) {}

    recordingOption = RecordingOptions;

    canBeRecorded(): boolean {
        if (!this.data.template.name || this.data.template.name.trim() === '') {
            this.warningMsg = this.translateService.instant('REPORTS.DIALOGS.TEMPLATE.NAME_ERROR_MISSING');
            return false;
        }

        if (this.data.template.isDefault && this.data.template.recordingOption === RecordingOptions.ORIGINAL) {
            this.warningMsg = this.translateService.instant('REPORTS.DIALOGS.TEMPLATE.RECORDING_OPTION_UPDATE_ERROR_DEFAULT');
            return false;
        }

        return true;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

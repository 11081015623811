import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RendezVous, ViewStatusRendezVousEnum } from '../../../../models/rendez-vous/rendez-vous';
import { ConnecteurService } from '../../../../services/api/connecteur.service';
import { WaitingRoomService } from '../../../../services/api/waiting-room.service';
import { BehaviorSubject, combineLatest, Observable, Subscription, timer } from 'rxjs';
import { Utils } from 'app/utils';
import { TranslateService } from '@ngx-translate/core';
import { RendezVousListPeriod } from '../rendez-vous-list-card.component';
import { WebSocketService } from '../../../../services/api/websocket.service';

@Component({
    selector: 'app-rendez-vous-tile',
    templateUrl: './rendez-vous-tile.component.html',
    styleUrls: ['./rendez-vous-tile.component.scss']
})
export class RendezVousTileComponent implements OnDestroy {
    @Input('rendezVous') rendezVous: RendezVous;
    @Input('period') period: RendezVousListPeriod;
    @Output('loadVisio') onLoadVisio = new EventEmitter<RendezVous>();
    @Output('editRendezVous') onEditRendezVous = new EventEmitter<RendezVous>();
    @Output('capture') onCapture = new EventEmitter<RendezVous>();
    @Output('editClient') onEditClient = new EventEmitter<RendezVous>();
    @Output('editAnimal') onEditAnimal = new EventEmitter<RendezVous>();

    statusText = new BehaviorSubject<string>('');
    statusColor = new BehaviorSubject<string>(null);
    buttonStatus = new BehaviorSubject<'edit' | 'join' | 'capture'>(null);

    visioInProgress: boolean;
    private autoRefresh$: Observable<number>;
    private subscriptions: Subscription[] = [];

    constructor(
        public connecteurService: ConnecteurService,
        public waitingRoomService: WaitingRoomService, private websocketService: WebSocketService,
        private translateService: TranslateService
    ) {
        this.autoRefresh$ = timer(0, 5 * 1000);
        this.subscriptions.push(
            combineLatest([this.autoRefresh$, this.waitingRoomService.clientWaitings]).subscribe(([_, clientWaitings]) => {
                if (!this.rendezVous) {
                    return;
                }

                switch (this.rendezVous.getViewStatut(clientWaitings)) {
                    case ViewStatusRendezVousEnum.waiting_room:
                        const cw = clientWaitings.find(cw => cw.rendezVous.id === this.rendezVous.id);
                        this.statusText.next(`${this.translateService.instant('RENDEZ_VOUS.STATUS.IN_WAITING_ROOM') as string} (${Utils.getElapsedTime(cw.rendezVous.waitingRoomEnterDate, this.translateService)})`);
                        this.statusColor.next('primary-fg');
                        this.buttonStatus.next('join');
                        break;
                    case ViewStatusRendezVousEnum.not_done:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.STATUS.canceled'));
                        this.statusColor.next(null);
                        this.buttonStatus.next(null);
                        break;
                    case ViewStatusRendezVousEnum.not_captured:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.IS_NOT_CAPTURED'));
                        this.statusColor.next('red-fg');
                        this.buttonStatus.next('capture');
                        break;
                    case ViewStatusRendezVousEnum.done:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.STATUS.ended'));
                        this.statusColor.next('blue-fg');
                        this.buttonStatus.next(null);
                        break;
                    case ViewStatusRendezVousEnum.ongoing:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.STATUS.in_progress'));
                        this.statusColor.next('primary-fg');
                        this.buttonStatus.next('join');

                        break;
                    case ViewStatusRendezVousEnum.late:
                        this.statusText.next(`${this.translateService.instant('RENDEZ_VOUS.IS_LATE') as string} (${Utils.getElapsedTime(this.rendezVous.date, this.translateService)})`);
                        this.statusColor.next('primary-fg');
                        this.buttonStatus.next('join');

                        break;
                    case ViewStatusRendezVousEnum.confirmed:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.STATUS.ready'));
                        this.statusColor.next('green-fg');
                        this.buttonStatus.next('join');

                        break;
                    case ViewStatusRendezVousEnum.not_confirmed:
                        this.statusText.next(this.translateService.instant('RENDEZ_VOUS.STATUS.waiting_for_client_confirmed'));
                        this.statusColor.next('red-fg');
                        this.buttonStatus.next('edit');
                        break;
                    default:
                        this.statusText.next('');
                        this.statusColor.next(null);
                        this.buttonStatus.next(null);
                        break;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s?.unsubscribe());
    }

    loadVisio(rendezVous: RendezVous): void {
        this.visioInProgress = true;
        this.onLoadVisio.emit(rendezVous);
    }

    editRendezVous(rendezVous: RendezVous = null): void {
        this.onEditRendezVous.emit(rendezVous);
    }

    capture(rendezVous: RendezVous): void {
        this.onCapture.emit(rendezVous);
    }

    editClient(rendezVous: RendezVous): void {
        this.onEditClient.emit(rendezVous);
    }

    editAnimal(rendezVous: RendezVous): void {
        this.onEditAnimal.emit(rendezVous);
    }
}

import { JsonProperty } from 'json-object-mapper';
import { DateMapper } from '../../object-mapper/date-mapper';
import { Entity } from '../entity';

export class HistoriqueVisio extends Entity {
    @JsonProperty()
    openTokTokenVeterinaire: string = null;

    @JsonProperty()
    openTokIdSession: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateDebut: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    dateFin?: Date = null;
}

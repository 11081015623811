<div id="chat" class="page-layout simple fullwidth">

    <!-- PAGE -->
    <div class="content">

        <mat-sidenav-container>

            <!-- LEFT SIDENAV -->
            <mat-sidenav class="sidenav"
                position="start"
                opened="true"
                mode="side"
                fuseMatSidenavHelper="chat-left-sidenav"
                matIsLockedOpen="gt-sm">

                <div class="sidenav-content">
                    <app-chat-list></app-chat-list>
                </div>

            </mat-sidenav>
            <!-- / LEFT SIDENAV -->

            <!-- CONTENT -->
            <app-chat-start *ngIf="!(selectedConversation$ | async); else viewTemplate"></app-chat-start>

            <ng-template #viewTemplate>
                <router-outlet></router-outlet>
            </ng-template>
            <!-- / CONTENT -->

        </mat-sidenav-container>

    </div>
    <!-- / PAGE -->

</div>

import { filter, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthGuard } from './auth.guard';

@Injectable({ providedIn: 'root' })
export class ManagedGuard extends AuthGuard {
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return super.canActivate(next, state).pipe(
            filter(value => value),
            switchMap(() => this.utilisateurService.utilisateurConnected),
            map(user => {
                if (user.entiteJuridique.isSubscriptionManaged) {
                    void this.router.navigate(['/dashboard']);
                }

                return !user.entiteJuridique.isSubscriptionManaged;
            })
        );
    }
}

<div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutGap="20px">
    <mat-form-field appearance="outline" fxFlex="1 1 100%" class="mr-0">
        <mat-label>{{ 'FORM.ADDRESS' | translate }}</mat-label>
        <input #address matInput [formControl]="controlAdresse | asFormControl" [placeholder]="'FORM.ADDRESS' | translate" [required]="required">
        <mat-error *ngIf="controlAdresse.hasError('required')">
            {{ 'FORM.ADDRESS_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="1 0 0">
        <mat-label>{{ 'FORM.CP' | translate }}</mat-label>
        <input matInput [formControl]="controlCodePostal | asFormControl" [placeholder]="'FORM.CP' | translate" [required]="required">
        <mat-error *ngIf="controlCodePostal.hasError('required')">
            {{ 'FORM.CP_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="1 0 0">
        <mat-label>{{ 'FORM.CITY' | translate }}</mat-label>
        <input matInput [formControl]="controlVille | asFormControl" [placeholder]="'FORM.CITY' | translate" [required]="required">
        <mat-error *ngIf="controlVille.hasError('required')">
            {{ 'FORM.CITY_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <app-input-pays [control]="controlPays" fxFlex="1 0 0" [required]="required"></app-input-pays>

    <app-input-region [control]="controlRegion" [controlCountry]="controlPays" fxFlex="1 0 0"></app-input-region>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="mb-20">
    <h1 mat-dialog-title class="m-0">{{ data.title }}</h1>
    <button *ngIf="data.cancelButton" mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content [innerHTML]="data.content"></div>

<div class="iframe-container" *ngIf="data.urlIframe">
    <iframe type="text/html" width="100%" height="500" [src]="data.urlIframe | sanitizeHtml" class="mb-16"
            (load)="iframeHasLoaded()"></iframe>

    <div *ngIf="!iframeLoaded" class="loader-container">
        <app-loading [onlySpinner]="true"></app-loading>
    </div>
</div>

<div mat-dialog-actions fxLayoutAlign="flex-end center">
    <button mat-button [mat-dialog-close]="false" *ngIf="data.action">
        {{ data.cancel ? data.cancel : ('SHARED.CANCEL' | translate) }}
    </button>

    <button mat-button autofocus [mat-dialog-close]="true" color="primary" *ngIf="!data.hideOk">
        {{ data.ok ? data.ok : ('SHARED.OK' | translate) }}
    </button>
</div>

import { RendezVous } from '../models/rendez-vous/rendez-vous';
import { EventEmitter, Injectable } from '@angular/core';
import { Publisher, PublisherProperties, Session, Stream, Subscriber } from '@opentok/client';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { RendezVousService } from './api/rendez-vous.service';
import { OpentokService } from './opentok.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { distinct, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { WaitingRoomService } from './api/waiting-room.service';

export enum DevicePermission {
    Undefined,
    NoDevicesFound,
    NoPermission,
    Ok
}

@Injectable({
    providedIn: 'root'
})
export class VisioService {
    private _callTimeout = 40 * 1000;
    private _currentStatus: BehaviorSubject<VisioStatut> = new BehaviorSubject<VisioStatut>(VisioStatut.idle);
    private _currentRendezVous: BehaviorSubject<RendezVous> = new BehaviorSubject<RendezVous>(null);
    private _currentSession: BehaviorSubject<Session> = new BehaviorSubject<Session>(null);
    private _currentPublisher: BehaviorSubject<Publisher> = new BehaviorSubject<Publisher>(null);
    private _currentPublisherFile: BehaviorSubject<Publisher> = new BehaviorSubject<Publisher>(null);
    private _currentSubscribers: BehaviorSubject<Subscriber[]> = new BehaviorSubject<Subscriber[]>([]);
    private _currentStreams: BehaviorSubject<Stream[]> = new BehaviorSubject<Stream[]>([]);
    private _streamingFile: BehaviorSubject<StreamingFile> = new BehaviorSubject<StreamingFile>(null);

    private connectionCount = 0;
    private times: Time[] = [];

    public permissionsStatus: BehaviorSubject<DevicePermission> = new BehaviorSubject<DevicePermission>(DevicePermission.Undefined);
    public onTestStart = new EventEmitter<any>();
    public onTestEnd = new EventEmitter<any>();

    constructor(
        private translateService: TranslateService,
        private rendezVousService: RendezVousService,
        private opentokService: OpentokService,
        private snackbar: MatSnackBar,
        private http: HttpClient,
        private config: ConfigService,
        private waitingRoomService: WaitingRoomService
    ) {}

    public setCurrentRendezVous(rendezVous: RendezVous): void {
        this._currentRendezVous.next(rendezVous);
    }

    public connectAndCall(): void {
        if (this._currentRendezVous.value) {
            this._currentSession.next(null);
            this._currentStreams.next(null);
            this._currentSubscribers.next(null);
            this._currentStatus.next(VisioStatut.loading);

            const rdvId = this._currentRendezVous.value.id;
            if (this.waitingRoomService.clientWaitings.value.some(c => c.rendezVous.id === rdvId)) {
                this.rendezVousService.joinCall(rdvId).subscribe({
                    next: result => this.callCallback(result.apiKey, result.idSession, result.token, true),
                    error: err => {
                        console.error(err);
                        this._currentSession.next(null);
                        this.disconnect(false);
                        this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                        this._currentStatus.next(VisioStatut.errored);
                    }
                });
            } else {
                this.rendezVousService.makeCall(rdvId).subscribe({
                    next: result => this.callCallback(result.opentokApiKey, result.visio.openTokIdSession, result.visio.openTokTokenVeterinaire),
                    error: err => {
                        console.error(err);
                        this._currentSession.next(null);
                        this.disconnect(false);
                        this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                        this._currentStatus.next(VisioStatut.errored);
                    }
                });
            }
        }
    }

    public recall(): void {
        if (this._currentRendezVous.value) {
            this._currentStatus.next(VisioStatut.loading);

            const rdvId = this._currentRendezVous.value.id;
            if (this.waitingRoomService.clientWaitings.value.some(c => c.rendezVous.id === rdvId)) {
                this.rendezVousService.joinCall(rdvId).subscribe({
                    next: result => this.callCallback(result.apiKey, result.idSession, result.token, true),
                    error: err => {
                        console.error(err);
                        this._currentSession.next(null);
                        this.disconnect(false);
                        this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                        this._currentStatus.next(VisioStatut.errored);
                    }
                });
            } else {
                this.rendezVousService.makeCall(rdvId).subscribe({
                    next: result => this.callCallback(result.opentokApiKey, result.visio.openTokIdSession, result.visio.openTokTokenVeterinaire),
                    error: err => {
                        console.error(err);
                        this._currentSession.next(null);
                        this.disconnect(false);
                        this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                        this._currentStatus.next(VisioStatut.errored);
                    }
                });
            }
        }
    }

    public callCallback(apikey: string, sessionId: string, token: string, direct = false): void {
        if (direct) {
            this._currentStatus.next(VisioStatut.ongoing);
        } else {
            this._currentStatus.next(VisioStatut.ringing);
        }

        const timeout = setTimeout(() => {
            if (this._currentStatus.value === VisioStatut.ringing) {
                this._currentStatus.next(VisioStatut.timeout);
            }
        }, this._callTimeout);

        if (!this.currentSessionObject) {
            this.opentokService.apiKey = apikey;
            this.opentokService.sessionId = sessionId;
            this.opentokService.sessionToken = token;

            this.opentokService.initSession()
                .then((session: Session) => {
                    this._currentSession.next(session);

                    this._currentSession.value.on('streamCreated', event => {
                        clearTimeout(timeout);
                        this.addCurrenStreams(event.stream);
                        this._currentStatus.next(VisioStatut.ongoing);
                    });

                    this._currentSession.value.on('streamDestroyed', event => {
                        this.removeCurrenStreams(event.stream);
                        if (this._currentStreams.value.length <= 0) {
                            try {
                                if (this._currentPublisherFile.value) {
                                    this.stopPublishFile();
                                }
                            } catch (err) {
                                console.error(err);
                            }

                            this._currentStatus.next(VisioStatut.clientHangOut);
                        }
                    });

                    this.opentokService.connect()
                        .catch(err => {
                            clearTimeout(timeout);
                            console.error(err);
                            this._currentSession.next(null);
                            this.disconnect(false);
                            this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                            this._currentStatus.next(VisioStatut.errored);
                        });
                })
                .catch(err => {
                    clearTimeout(timeout);
                    console.error(err);
                    this._currentSession.next(null);
                    this.disconnect(false);
                    this.snackbar.open(this.translateService.instant('VISIO.ERROR_SESSION_CONNEXION'), this.translateService.instant('SHARED.OK'), { duration: 4000 });
                    this._currentStatus.next(VisioStatut.errored);
                });
        }
    }

    public hangout(): void {
        this.connectionCount = 0;
        if (this.times.length > 0 && !this.times[this.times.length - 1].stop) {
            this.times[this.times.length - 1].stop = Date.now();
        }

        if (this._currentSession.value) {
            this._currentSession.value.off('streamCreated', null);
            this._currentSession.value.off('streamDestroyed', null);
            this._currentSession.value.off('connectionCreated', null);
            this._currentSession.value.off('connectionDestroyed', null);
            if (this._currentPublisher.value) {
                this._currentSession.value.unpublish(this._currentPublisher.value);
            }

            try {
                if (this._currentPublisherFile.value) {
                    this._currentSession.value.unpublish(this._currentPublisherFile.value);
                    this._currentPublisherFile.next(null);
                    this._streamingFile.next(null);
                }
            } catch {
                // ignored
            }

            this._currentSession.value.disconnect();
        }

        if (this._currentRendezVous.value?.id) {
            this.http.get(this.config.baseUrl + 'api/rendez_vous/' + this._currentRendezVous.value.id.toString() + '/call_stop').subscribe();
        }

        this._currentSession.next(null);
        this._currentStreams.next(null);
        this._currentSubscribers.next(null);
        this._currentStatus.next(VisioStatut.idle);
    }

    public disconnect(updateVisioStatut = true): void {
        this.connectionCount = 0;
        if (this.times.length > 0 && !this.times[this.times.length - 1].stop) {
            this.times[this.times.length - 1].stop = Date.now();
        }

        if (this._currentSession.value) {
            this._currentSession.value.off('streamCreated', null);
            this._currentSession.value.off('streamDestroyed', null);
            if (this._currentPublisher.value) {
                this._currentSession.value.unpublish(this._currentPublisher.value);
            }

            try {
                if (this._currentPublisherFile.value) {
                    this._currentSession.value.unpublish(this._currentPublisherFile.value);
                    this._currentPublisherFile.next(null);
                    this._streamingFile.next(null);
                }
            } catch {
                // ignored
            }

            if (this._currentStreams.value) {
                this._currentStreams.value.forEach(element => {
                    try {
                        this._currentSession.value.forceDisconnect(element.connection, null);
                    } catch {
                        // ignored
                    }
                });
            }

            if (this._currentRendezVous.value?.id) {
                this.http.get(this.config.baseUrl + 'api/rendez_vous/' + this._currentRendezVous.value.id.toString() + '/call_end').subscribe();
            }
        }

        this._currentSession.next(null);
        this._currentStreams.next(null);
        this._currentSubscribers.next(null);
        if (updateVisioStatut) {
            this._currentStatus.next(VisioStatut.finished);
        }
    }

    public startRecording(): Observable<void> {
        if (this._currentSession.value && this._currentRendezVous.value && this._currentRendezVous.value.id) {
            return this.http.get<void>(this.config.baseUrl + 'api/rendez_vous/' + this._currentRendezVous.value.id.toString() + '/recording_start');
        }
    }

    public stopRecording(): Observable<void> {
        if (this._currentSession.value && this._currentRendezVous.value && this._currentRendezVous.value.id) {
            return this.http.get<void>(this.config.baseUrl + 'api/rendez_vous/' + this._currentRendezVous.value.id.toString() + '/recording_stop');
        }
    }

    public get canRecord(): Observable<boolean> {
        return this.currentStatut.pipe(map(s =>
            s === VisioStatut.ongoing ||
            s === VisioStatut.clientHangOut
        ));
    }

    public clear(): void {
        this.disconnect(false);
        this._currentRendezVous.next(null);
        this._currentStatus.next(VisioStatut.idle);
    }

    public get currentStatut(): Observable<VisioStatut> {
        return this._currentStatus.pipe(distinct());
    }

    public get currentStatutObject(): VisioStatut {
        return this._currentStatus.value;
    }

    public get currentRendezVous(): Observable<RendezVous> {
        return this._currentRendezVous.asObservable();
    }

    public get currentRendezVousObject(): RendezVous {
        return this._currentRendezVous.value;
    }

    public get currentSession(): BehaviorSubject<Session> {
        return this._currentSession;
    }

    public get currentSessionObject(): Session {
        return this._currentSession.value;
    }

    public get currentPublisher(): Observable<Publisher> {
        return this._currentPublisher.asObservable();
    }

    public get currentPublisherObject(): Publisher {
        return this._currentPublisher.value;
    }

    public get currentPublisherFile(): Observable<Publisher> {
        return this._currentPublisherFile.asObservable();
    }

    public get currentPublisherFileObject(): Publisher {
        return this._currentPublisherFile.value;
    }

    public get currentStreams(): BehaviorSubject<Stream[]> {
        return this._currentStreams;
    }

    public get currentSubscribers(): BehaviorSubject<Subscriber[]> {
        return this._currentSubscribers;
    }

    public get isStreamingFile(): Observable<boolean> {
        return this._streamingFile.pipe(map(sf => Boolean(sf)));
    }

    public get currentStreamingFile(): Observable<StreamingFile> {
        return this._streamingFile.asObservable();
    }

    public get currentStreamingFileObject(): StreamingFile {
        return this._streamingFile.value;
    }

    public get currentStreamingFileUrl(): Observable<string> {
        return this._streamingFile.pipe(map(sf => sf?.url));
    }

    public get currentStreamingFileUrlObject(): string {
        return this._streamingFile.value?.url;
    }

    public setCurrentPublisher(publisher: Publisher): void {
        if (this._currentPublisher.value) {
            this._currentPublisher.value.destroy();
        }

        this._currentPublisher.next(publisher);
    }

    public addCurrenSubscribers(subscriber: Subscriber): void {
        let newVal = this._currentSubscribers.getValue();
        if (!newVal) {
            newVal = [];
        }

        newVal.push(subscriber);
        this._currentSubscribers.next(newVal);

        subscriber.on('connected' as any, () => {
            this.connectionCount++;

            if (this.connectionCount === 1) {
                this.times.push(
                    {
                        start: Date.now()
                    } as Time
                );
            }
        });

        subscriber.on('disconnected' as any, () => {
            this.connectionCount--;

            if (this.connectionCount <= 0 && this.times.length > 0) {
                this.times[this.times.length - 1].stop = Date.now();
            }
        });

        subscriber.on('destroyed' as any, () => {
            this.connectionCount--;

            if (this.connectionCount <= 0 && this.times.length > 0) {
                this.times[this.times.length - 1].stop = Date.now();
            }
        });
    }

    public removeCurrenSubscribers(subscriber: Subscriber): void {
        let newVal = this._currentSubscribers.getValue();
        if (!newVal) {
            newVal = [];
        }

        const idx = newVal.indexOf(subscriber);
        if (idx > -1) {
            newVal.splice(idx, 1);
            this._currentSubscribers.next(newVal);
        }

        subscriber.off('connected', null);
        subscriber.off('disconnected' as any, null);
        subscriber.off('destroyed' as any, null);
    }

    private addCurrenStreams(stream: Stream) {
        let newVal = this._currentStreams.getValue();
        if (!newVal) {
            newVal = [];
        }

        newVal.push(stream);
        this._currentStreams.next(newVal);
    }

    private removeCurrenStreams(stream: Stream) {
        let newVal = this._currentStreams.getValue();
        if (!newVal) {
            newVal = [];
        }

        const idx = newVal.indexOf(stream);
        if (idx > -1) {
            newVal.splice(idx, 1);
            this._currentStreams.next(newVal);
        }
    }

    get calculatedTime(): number {
        return this.times
            .map((time: Time) => {
                return (time.stop ? time.stop : Date.now()) - time.start;
            })
            .reduce((sum, current) => sum + current, 0);
    }

    initPublisher(nativeElement: string | HTMLElement, options: PublisherProperties): Publisher {
        const publisher = this.opentokService.initPublisher(nativeElement, options, err => {
            if (err && err.name === 'OT_NO_DEVICES_FOUND' && this.permissionsStatus.value !== DevicePermission.NoDevicesFound) {
                    this.permissionsStatus.next(DevicePermission.NoDevicesFound);
                }
        });

        publisher.on('accessAllowed', () => {
            if (this.permissionsStatus.value !== DevicePermission.Ok) {
                this.permissionsStatus.next(DevicePermission.Ok);
            }
        });

        publisher.on('accessDenied', () => {
            if (this.permissionsStatus.value !== DevicePermission.NoPermission) {
                this.permissionsStatus.next(DevicePermission.NoPermission);
            }
        });

        // publisher.on('audioLevelUpdated', (event) => {
        //     this.onPublisherAudioLevelUpdatedEmitter.emit(event);
        // });

        // publisher.on('videoDimensionsChanged', (event) => {
        //     this.onPublisherVideoDimensionChangedEmitter.emit(event);
        // });

        this.setCurrentPublisher(publisher);

        return publisher;
    }

    getTestRoom(): Observable<any> {
        return this.http.get(this.config.baseUrl + 'api/test_visio_key');
    }

    startPublishFile(type: 'video' | 'image', url: string): void {
        this._streamingFile.next({ type, url });
    }

    onPublishVideoReady(video: HTMLMediaElement | any): void {
        if (!video.captureStream) {
            alert('This browser does not support VideoElement.captureStream(). You must use Google Chrome.');
            this._streamingFile.next(null);
            return;
        }

        const session = this._currentSession.value;
        if (!session) {
            return;
        }

        const publisher = this._currentPublisher.value;
        if (publisher) {
            session.unpublish(publisher);
        }

        const stream = video.captureStream();
        let publisherFile: Publisher;
        const publish = () => {
            const videoTracks = stream.getVideoTracks();
            if (!publisherFile && videoTracks.length > 0) {
                stream.removeEventListener('addtrack', publish);
                const publisherContainer = document.createElement('div');
                publisherFile = this.opentokService.initPublisher(publisherContainer, {
                    videoSource: videoTracks[0],
                    publishVideo: true,
                    publishAudio: Boolean(publisher.stream?.hasAudio),
                    name: 'video'
                }, err => {
                    if (err) {
                        alert(err.message);
                    } else {
                        video.play();
                    }
                });

                publisherFile.on('destroyed', () => {
                    video.pause();
                });

                session.publish(publisherFile, err => {
                    if (err) {
                        console.error(err.message);
                    }
                });
                this._currentPublisherFile.next(publisherFile);
            }
        };

        stream.addEventListener('addtrack', publish);
        publish();
    }

    onPublishCanvasReady(canvas: HTMLCanvasElement | any): void {
        if (!canvas.captureStream) {
            alert('This browser does not support CanvasElement.captureStream(). You must use Google Chrome.');
            this._streamingFile.next(null);
            return;
        }

        const session = this._currentSession.value;
        if (!session) {
            return;
        }

        const publisher = this._currentPublisher.value;
        if (publisher) {
            session.unpublish(publisher);
        }

        const stream = canvas.captureStream(30);
        let publisherFile: Publisher;
        const publish = () => {
            const canvasTracks = stream.getVideoTracks();
            if (!publisherFile && canvasTracks.length > 0) {
                stream.removeEventListener('addtrack', publish);
                const publisherContainer = document.createElement('div');
                publisherFile = this.opentokService.initPublisher(publisherContainer, {
                    videoSource: canvasTracks[0],
                    publishVideo: true,
                    publishAudio: Boolean(publisher.stream?.hasAudio),
                    name: 'video'
                }, err => {
                    if (err) {
                        alert(err.message);
                    }
                });

                session.publish(publisherFile, err => {
                    if (err) {
                        console.error(err.message);
                    }
                });
                this._currentPublisherFile.next(publisherFile);
            }
        };

        stream.addEventListener('addtrack', publish);
        publish();
    }

    stopPublishFile(): void {
        const session = this._currentSession.value;
        if (!session) {
            return;
        }

        if (this._currentPublisherFile.value) {
            session.unpublish(this._currentPublisherFile.value);
        }

        this._currentPublisherFile.next(null);

        if (this._currentPublisher.value) {
            session.publish(this._currentPublisher.value, err => {
                if (err) {
                    console.error(err.message);
                }
            });
        }

        this._streamingFile.next(null);
    }
}

export enum VisioStatut {
    idle = 'idle',
    loading = 'loading',
    ringing = 'ringing',
    timeout = 'timeout',
    ongoing = 'ongoing',
    clientHangOut = 'clientHangOut',
    finished = 'finished',
    errored = 'errored'
}

export interface Time {
    start: number;
    stop?: number;
}

export interface StreamingFile {
    type: 'video' | 'image';
    url: string;
}

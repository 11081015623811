import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Categorie } from '../../../../../models/categorie';
import { Veterinaire } from '../../../../../models/utilisateurs/veterinaire';
import { Client } from 'app/models/utilisateurs/client';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CategorieInterface } from '../../../../../models/interfaces/categorie.interface';
import { BehaviorSubject, Subscription, zip } from 'rxjs';
import { ConfigService } from '../../../../../services/config.service';
import { UtilisateurService } from '../../../../../services/api/utilisateur.service';
import { debounceTime, map, take } from 'rxjs/operators';
import { Utils } from 'app/utils';
import { UserPreferenciesService } from '../../../../../services/user-preferencies.service';
import { UserPreferenciesInterface } from 'app/models/interfaces/user-preferencies.interface';
import { MatSelect } from '@angular/material/select';
import { EntiteJuridiqueService } from '../../../../../services/api/entite-juridique.service';

@Component({
    selector: 'app-chat-list-menu-filter',
    templateUrl: './chat-list-menu-filter.component.html',
    styleUrls: ['./chat-list-menu-filter.component.scss']
})
export class ChatListMenuFilterComponent implements OnInit, OnDestroy {
    loading$ = new BehaviorSubject<boolean>(true);
    @Output('filterChanged') filterChanged = new EventEmitter<ChatFilter>();
    @Output('hasFilter') hasFilter = new EventEmitter<boolean>();

    form: FormGroup;
    separatorKeysCodes: number[] = [ENTER, COMMA];

    private subscriptions: Subscription[] = [];

    public categoriesFilter: FormControl = new FormControl();
    categories: CategorieInterface[] = [];
    filteredCategoriesOptions$: BehaviorSubject<CategorieInterface[]> = new BehaviorSubject<CategorieInterface[]>(null);

    colors: string[] = [];

    public veterinariesFilter: FormControl = new FormControl();
    veterinaries: Veterinaire[] = [];
    filteredVeterinariesOptions$: BehaviorSubject<Veterinaire[]> = new BehaviorSubject<Veterinaire[]>(null);

    public clientsFilter: FormControl = new FormControl();
    clients: Client[] = [];
    filteredClientsOptions$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>(null);

    categoryMatSelectSearch = false;
    veterinaryMatSelectSearch = false;
    clientMatSelectSearch = false;

    isBookmarkFilterApplied: boolean;

    constructor(
        private configService: ConfigService,
        private utilisateurService: UtilisateurService,
        private userPreferenciesService: UserPreferenciesService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {}

    ngOnInit(): void {
        this.userPreferenciesService.getPreferencies().subscribe({
            next: (prefs: UserPreferenciesInterface) => {
                this.onPrefReady(prefs);
            },
            error: () => {
                this.onPrefReady(null);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private onPrefReady(prefs: UserPreferenciesInterface): void {
        zip(
            this.configService.getCategories(),
            this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.pipe(
                map(ej => ej.chatListColors)
            ),
            this.utilisateurService.getVeterinaires(null, 'asc', 'fullName', null, 20, true, prefs?.chatsFilter?.veterinaries ?? []),
            this.utilisateurService.getClients(null, 'asc', 'fullName', null, 20, true, prefs?.chatsFilter?.clients ?? [])
        ).pipe(take(1)).subscribe(([categories, colors, veterinaries, clients]) => {
            this.categories = categories;
            this.filteredCategoriesOptions$.next(this.categories.filter((elem, index, self) => index === self.findIndex(e => e.id === elem.id)));
            colors.unshift('none');
            this.colors = colors;
            this.filteredVeterinariesOptions$.next(veterinaries.data.filter((elem, index, self) => index === self.findIndex(e => e.id === elem.id)));
            this.filteredClientsOptions$.next(clients.data.filter((elem, index, self) => index === self.findIndex(e => e.id === elem.id)));

            this.form = new FormGroup({
                status: new FormControl('open', []),
                categories: new FormControl([], []),
                colors: new FormControl([], []),
                veterinaries: new FormControl([this.utilisateurService.utilisateurConnectedValue], []),
                clients: new FormControl([], []),
                bookmarked: new FormControl(['false'], [])
            });

            if (prefs.chatsFilter) {
                setTimeout(() => {
                    this.form.patchValue({
                        status: prefs.chatsFilter?.status ?? 'open',
                        categories: prefs.chatsFilter?.categories?.map(a => categories.find(b => a === b.id))?.filter(a => !!a) ?? [],
                        colors: prefs.chatsFilter?.colors?.map(a => colors.find(b => a === b))?.filter(a => !!a) ?? [],
                        veterinaries: prefs.chatsFilter?.veterinaries?.map(a => veterinaries.data.find(b => a === b.id))?.filter(a => !!a) ?? [],
                        clients: prefs.chatsFilter?.clients?.map(a => clients.data.find(b => a === b.id))?.filter(a => !!a) ?? [],
                        bookmarked: prefs.chatsFilter?.isBookmarkedApplied ? 'true' : 'false'
                    });
                }, 0);
            }

            this.setForm();

            this.loading$.next(false);
        });
    }

    private setForm(): void {
        const categoriesSubscription = this.categoriesFilter.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.categoryMatSelectSearch = true;

            const filteredCategoriesOptions = this.categoriesFilter.value ? this.categories.filter(espece => Utils.removeDiacritics(espece.value.toLowerCase()).includes(Utils.removeDiacritics(this.categoriesFilter.value.toLowerCase()))) : this.categories;

            this.filteredCategoriesOptions$.next(filteredCategoriesOptions);

            this.categoryMatSelectSearch = false;
        });

        if (categoriesSubscription) {
            this.subscriptions.push(categoriesSubscription);
        }

        const veterinaireSubscription = this.veterinariesFilter.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.veterinaryMatSelectSearch = true;
            this.utilisateurService.getVeterinaires(value, 'asc', 'fullName', null, 20, true).subscribe(veterinaires => {
                this.filteredVeterinariesOptions$.next(veterinaires.data);
                this.veterinaryMatSelectSearch = false;
            });
        });

        if (veterinaireSubscription) {
            this.subscriptions.push(veterinaireSubscription);
        }

        const clientsSubscription = this.clientsFilter.valueChanges.pipe(debounceTime(500)).subscribe(value => {
            this.clientMatSelectSearch = true;
            this.utilisateurService.getClients(value, 'asc', 'fullName', null, 20, true).subscribe(clients => {
                this.filteredClientsOptions$.next(clients.data);
                this.clientMatSelectSearch = false;
            });
        });

        if (clientsSubscription) {
            this.subscriptions.push(clientsSubscription);
        }

        const formSubscription = this.form.valueChanges.subscribe(values => {
            const chatFilter: ChatFilter = {
                status: values.status,
                categories: values.categories?.map((c: CategorieInterface) => c.id) ?? [],
                colors: values.colors?.map((c: string) => c) ?? [],
                veterinaries: values.veterinaries?.map((v: Veterinaire) => v.id) ?? [],
                clients: values.clients?.map((c: Client) => c.id) ?? [],
                isBookmarkedApplied: values.bookmarked === 'true'
            };
            this.userPreferenciesService.setPreferenciesValue('chatsFilter', chatFilter).pipe(take(1)).subscribe();
            this.filterChanged.emit(chatFilter);
            this.hasFilter.emit(chatFilter.status === 'close' || chatFilter.status === 'futur' || chatFilter.categories.length > 0 || chatFilter.colors.length > 0 || chatFilter.veterinaries.length > 0 || chatFilter.clients.length > 0 || chatFilter.isBookmarkedApplied);
        });

        if (formSubscription) {
            this.subscriptions.push(formSubscription);
        }
    }

    addCategory(input: MatSelect, value: Categorie | any): void {
        if (value === 'all') {
            this.form.get('categories').setValue([]);
        } else {
            const newValues = this.form.get('categories').value;
            const index = newValues.findIndex(v => v.id === value.id);
            if (index === -1) {
                newValues.push(value);
                this.form.get('categories').setValue(newValues);
            }
        }

        if (input) {
            input.value = '';
        }
    }

    removeCategory(value: Categorie): void {
        const newValues = this.form.get('categories').value;
        const index = newValues.indexOf(value);

        if (index >= 0) {
            newValues.splice(index, 1);
            this.form.get('categories').setValue(newValues);
        }
    }

    addColor(input: MatSelect, value: string | any): void {
        if (value === 'all') {
            this.form.get('colors').setValue([]);
        } else {
            const newValues = this.form.get('colors').value;
            const index = newValues.indexOf(value);
            if (index === -1) {
                newValues.push(value);
                this.form.get('colors').setValue(newValues);
            }
        }

        if (input) {
            input.value = '';
        }
    }

    removeColor(value: string): void {
        const newValues = this.form.get('colors').value;
        const index = newValues.indexOf(value);

        if (index >= 0) {
            newValues.splice(index, 1);
            this.form.get('colors').setValue(newValues);
        }
    }

    addVeterinary(input: MatSelect, value: Veterinaire | any): void {
        if (value === 'all') {
            this.form.get('veterinaries').setValue([]);
        } else if (value === 'mine') {
            this.addVeterinary(input, this.utilisateurService.utilisateurConnectedValue);
        } else {
            const newValues = this.form.get('veterinaries').value;
            const index = newValues.findIndex(v => v.id === value.id);
            if (index === -1) {
                newValues.push(value);
                this.form.get('veterinaries').setValue(newValues);
            }
        }

        if (input) {
            input.value = '';
        }
    }

    removeVeterinary(value: Veterinaire): void {
        const newValues = this.form.get('veterinaries').value;
        const index = newValues.indexOf(value);

        if (index >= 0) {
            newValues.splice(index, 1);
            this.form.get('veterinaries').setValue(newValues);
        }
    }

    addClient(input: MatSelect, value: Client | any): void {
        if (value === 'all') {
            this.form.get('clients').setValue([]);
        } else {
            const newValues = this.form.get('clients').value;
            const index = newValues.findIndex(v => v.id === value.id);
            if (index === -1) {
                newValues.push(value);
                this.form.get('clients').setValue(newValues);
            }
        }

        if (input) {
            input.value = '';
        }
    }

    removeClient(value: Client): void {
        const newValues = this.form.get('clients').value;
        const index = newValues.indexOf(value);

        if (index >= 0) {
            newValues.splice(index, 1);
            this.form.get('clients').setValue(newValues);
        }
    }

    clear(): void {
        this.form.patchValue({
            status: 'open',
            categories: [],
            colors: [],
            veterinaries: [],
            clients: [],
            bookmarked: 'false'
        });
    }

    isMe(veterinaire: Veterinaire): boolean {
        return this.utilisateurService.utilisateurConnectedValue?.id === veterinaire.id;
    }
}

export interface ChatFilter {
    status: 'open' | 'close' | 'futur';
    categories: number[];
    colors: string[];
    veterinaries: number[];
    clients: number[];
    isBookmarkedApplied: boolean;
}

import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { AvatarComponent } from './avatar/avatar.component';
import { ProfileTileComponent } from './profile-tile/profile-tile.component';
import { DialogComponent } from './dialog/dialog.component';
import { SharedModule } from '../../../shared.module';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { DocumentSelectorDialogComponent } from './document-selector-dialog/document-selector-dialog.component';
import { LoadingComponent } from './loading/loading.component';
import { ChatStartComponent } from 'app/main/content/chat/chat-start/chat-start.component';
import { NgxFileDropModule } from 'ngx-file-drop-temp';
import { EditPhotoDialogComponent } from './edit-photo-dialog/edit-photo-dialog.component';
import { ColorPickerComponent } from './color-picker/color-picker.component';
import { VisioParameterComponent } from './visio-parameter/visio-parameter.component';
import { AngularPinturaModule } from 'angular-pintura';
import { ConnectorIconComponent } from './connector-icon/connector-icon.component';
import { WaitingRoomButtonComponent } from './waiting-room-button/waiting-room-button.component';
import { WaitingRoomComponent } from './waiting-room/waiting-room.component';
import { VisiocareDialogComponent } from './visiocare-dialog/visiocare-dialog.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationDialogComponent } from './notification-dialog/notification-dialog.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@NgModule({
    declarations: [
        ActionsMenuComponent,
        AvatarComponent,
        ChatStartComponent,
        ColorPickerComponent,
        ConnectorIconComponent,
        DialogComponent,
        DocumentSelectorDialogComponent,
        EditPhotoDialogComponent,
        LoadingComponent,
        NotificationDialogComponent,
        NotificationsComponent,
        ProfileTileComponent,
        VisiocareDialogComponent,
        VisioParameterComponent,
        WaitingRoomButtonComponent,
        WaitingRoomComponent
    ],
    imports: [
        FuseSharedModule,
        SharedModule,
        NgxFileDropModule,
        AngularPinturaModule,
        LazyLoadImageModule
    ],
    exports: [
        ActionsMenuComponent,
        AvatarComponent,
        ChatStartComponent,
        ColorPickerComponent,
        ConnectorIconComponent,
        LoadingComponent,
        NotificationDialogComponent,
        NotificationsComponent,
        ProfileTileComponent,
        VisioParameterComponent,
        WaitingRoomButtonComponent,
        WaitingRoomComponent
    ]
})
export class ViewUtilsSharedModule {}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { EditorModule } from '@tinymce/tinymce-angular';

import { ReportComponent } from './report.component';
import { ReportEditorComponent } from './editors/report/report-editor.component';
import { ReportDialogComponent } from './dialogs/report-dialog.component';
import { TemplateEditorComponent } from './editors/template/template-editor.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { TemplateRecordingDialogComponent } from './dialogs/templateRecording/template-recording-dialog/template-recording-dialog.component';
import { EmailBuildingDialogComponent } from './dialogs/email-building-dialog/email-building-dialog.component';
import { HistoryComponent } from './history/history.component';

const routes = [
    {
        path: 'history/:id',
        component: HistoryComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'historique/:id',
        redirectTo: '/history/:id',
        pathMatch: 'full'
    },
    {
        path: 'report/:id',
        component: ReportComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard],
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'rapport/:id',
        redirectTo: '/report/:id',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        ReportComponent,
        ReportEditorComponent,
        ReportDialogComponent,
        TemplateEditorComponent,
        TemplateRecordingDialogComponent,
        EmailBuildingDialogComponent,
        HistoryComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        SharedModule,
        ViewSharedModule,
        EditorModule
    ],
    exports: [
        ReportComponent
    ]
})

export class ReportModule {}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, forkJoin, Observable, of, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { ConnecteurService } from '../../../../../services/api/connecteur.service';
import { DataEntityLinkPossible, DataEntityLinkPossibleStorage } from '../../../../../models/data-entity-link-possible';
import { AllyDvmService } from '../../../../../services/api/ally-dvm.service';
import { ClientPostInterface } from '../../../../../models/interfaces/post/utilisateurs/client-post.interface';
import { AnimalPostInterface } from '../../../../../models/interfaces/post/animal/animal-post.interface';

@Component({
    selector: 'app-input-import',
    templateUrl: './input-import.component.html',
    styleUrls: ['./input-import.component.scss']
})
export class InputImportComponent implements OnInit {
    @Input() type: string;
    @Input() placeholder: string;

    @Input() id?: string;

    @Output('selected') selected$ = new EventEmitter<DataEntityLinkPossible>();

    control: FormControl = new FormControl();
    filterControl: FormControl = new FormControl();
    filteredOptions$: BehaviorSubject<DataEntityLinkPossible[]> = new BehaviorSubject<DataEntityLinkPossible[]>(null);
    loading: boolean;

    private subscription: Subscription;

    constructor(private connecteurService: ConnecteurService, private allyDvmService: AllyDvmService) {}

    ngOnInit(): void {
        if (this.id) {
            this.search();
        }

        this.filterControl.valueChanges
            .pipe(
                filter(filter => {
                    if (filter?.length >= 3) {
                        this.loading = true;
                        return true;
                    }

                    this.loading = false;
                    return false;
                }),
                debounceTime(3000)
            )
            .subscribe(() => {
                this.search();
            });
    }

    emitChoosen(): void {
        this.selected$.next(this.control.value);
    }

    private getCsvDatas(filter: string): Observable<DataEntityLinkPossibleStorage> {
        switch (this.type) {
            case 'clients':
                return this.connecteurService.searchInCsvDatas(this.type, filter);

            case 'animalsClients':
                if (this.id) {
                    return this.connecteurService.searchInCsvDatasById(this.type, this.id, 'idClient');
                }

                return this.connecteurService.searchInCsvDatas(this.type, filter);

            default:
                return of(null);
        }
    }

    private getAllyDvmDatas(filter: string): Observable<Array<ClientPostInterface | AnimalPostInterface>> {
        switch (this.type) {
            case 'clients':
                return this.allyDvmService.searchClients(filter);

            case 'animalsClients':
                if (this.id) {
                    return this.allyDvmService.getAnimauxClient(this.id);
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return of([]);

            default:
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return of([]);
        }
    }

    private search() {
        const filter = this.filterControl.value ? this.filterControl.value.toLowerCase() : '';

        if (this.id || filter?.length >= 3) {
            this.loading = true;

            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            this.subscription = forkJoin({
                csv: this.getCsvDatas(filter),
                allydvm: this.getAllyDvmDatas(filter)
            }).subscribe({
                next: datas => {
                    this.loading = false;

                    let dataOk = [];

                    if (datas.csv?.datas?.length > 0) {
                        dataOk = [...dataOk, ...datas.csv.datas.slice(0, 20)];
                    }

                    if (datas.allydvm?.length > 0) {
                        dataOk = [...dataOk, ...datas.allydvm.map(d => new DataEntityLinkPossible(d))];
                    }

                    this.filteredOptions$.next(dataOk);
                },
                error: () => {
                    this.loading = false;
                }
            });
        } else {
            this.loading = false;
            this.filteredOptions$.next(this.control.value ? [this.control.value] : []);
        }
    }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
    Connecteur,
    ConnecteurFeature,
    ConnecteurFeatureInterface,
    ConnecteurFeatureSupport,
    ConnecteurFeatureType
} from '../../../../models/interfaces/post/pro/connecteur.interface';
import { ConnecteurService } from 'app/services/api/connecteur.service';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EntiteJuridique } from 'app/models/pro/entite-juridique';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'app/main/shared/view-utils/dialog/dialog.component';

@Component({
    selector: 'app-connector-detail',
    templateUrl: './connector-detail.component.html',
    styleUrls: ['./connector-detail.component.scss']
})
export class ConnectorDetailComponent implements OnInit, OnDestroy {
    @Input() enabled = false;
    @Input() connecteur: Connecteur;
    @Input() apiKey: string;

    @Output('returnToList') returnToListEvent = new EventEmitter<void>();
    @Output('needsRefresh') needsRefresh = new EventEmitter<EntiteJuridique>();

    features: ConnecteurFeature[];

    loading = false;
    private subscriptions: Subscription[] = [];

    constructor(
        private readonly snackbar: MatSnackBar,
        private readonly translateService: TranslateService,
        private readonly entiteJuridiqueService: EntiteJuridiqueService,
        private readonly connecteurService: ConnecteurService,
        private readonly dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.features = this.featuresFromInterface(this.connecteur.features);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public get langs(): string {
        return this.connecteur.countries.join(', ');
    }

    returnToList(): void {
        this.returnToListEvent.emit(null);
    }

    enableConnector(): void {
        this.loading = true;
        this.snackbar.open(this.translateService.instant('CONNECTOR.ACTIVATION.IN_PROGRESS'));
        const connecteurSubscription = this.connecteurService.enableConnecteurForEntiteJuridique(
            this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id,
            this.connecteur.id
        ).subscribe({
            next: entiteJuridique => {
                this.loading = false;
                this.snackbar.open(this.translateService.instant('CONNECTOR.ACTIVATION.SUCCEEDED'), null, { duration: 3000 });
                this.needsRefresh.emit(entiteJuridique);
            },
            error: error => {
                this.loading = false;
                console.error(error);
                const snack = this.snackbar.open(
                    this.translateService.instant('CONNECTOR.ACTIVATION.FAILED'),
                    this.translateService.instant('CONNECTOR.ACTIVATION.RETRY'),
                    { duration: 3000 }
                );
                const afterDismissedSubscription = snack.afterDismissed().subscribe(action => {
                    if (action.dismissedByAction) {
                        this.enableConnector();
                    }
                });
                this.subscriptions.push(afterDismissedSubscription);
            }
        });
        this.subscriptions.push(connecteurSubscription);
    }

    confirmDisableConnector(): void {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('CONNECTOR.DIALOG_CONFIRM.TITLE'),
                content: this.translateService.instant('CONNECTOR.DIALOG_CONFIRM.CONTENT', { connecteur: this.connecteur.name }),
                ok: this.translateService.instant('CONNECTOR.DIALOG_CONFIRM.CONFIRM'),
                cancel: this.translateService.instant('CONNECTOR.DIALOG_CONFIRM.CANCEL'),
                action: true
            }
        });

        dialog.afterClosed().subscribe(result => {
            if (result) {
                this.disableConnector();
            }
        });
    }

    private disableConnector(): void {
        this.loading = true;
        this.snackbar.open(this.translateService.instant('CONNECTOR.DEACTIVATION.IN_PROGRESS'));
        const connecteurSubscription = this.connecteurService.disableConnecteurForEntiteJuridique(
            this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id
        ).subscribe({
            next: entiteJuridique => {
                this.loading = false;
                this.snackbar.open(this.translateService.instant('CONNECTOR.DEACTIVATION.SUCCEEDED'), null, { duration: 3000 });
                this.needsRefresh.emit(entiteJuridique);
            },
            error: error => {
                this.loading = false;
                console.error(error);
                const snack = this.snackbar.open(
                    this.translateService.instant('CONNECTOR.DEACTIVATION.FAILED'),
                    this.translateService.instant('CONNECTOR.DEACTIVATION.RETRY'),
                    { duration: 3000 }
                );
                const afterDismissedSubscription = snack.afterDismissed().subscribe(action => {
                    if (action.dismissedByAction) {
                        this.enableConnector();
                    }
                });
                this.subscriptions.push(afterDismissedSubscription);
            }
        });
        this.subscriptions.push(connecteurSubscription);
    }

    openLink(): void {
        window.open(this.connecteur.link, '_blank');
    }

    private featuresFromInterface(features: ConnecteurFeatureInterface[]): ConnecteurFeature[] {
        const allFeatures: ConnecteurFeature[] = [];
        features.forEach(featureInterface => {
            const featureArray = featureInterface.toString().toLowerCase().split('_');
            const featureSupport = ConnecteurFeatureSupport[featureArray.pop()];
            const featureType = ConnecteurFeatureType[featureArray.join('_')];
            const featureIndex = allFeatures.findIndex(c => c.connecteurType === featureType);
            if (featureIndex !== -1 && allFeatures[featureIndex]) {
                allFeatures[featureIndex].support.push(featureSupport);
            } else {
                allFeatures.push({
                    connecteurType: featureType,
                    support: [featureSupport]
                });
            }
        });
        return allFeatures;
    }
}

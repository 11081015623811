import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../services/api/auth.service';
import { EmailSuggestion, EmailValidators, Suggestion } from 'ngx-validators';
import { emailOptionsConfig } from 'app/config';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-forgot-pass',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    animations: fuseAnimations
})
export class ForgotPassComponent implements OnInit {
    form: FormGroup;
    email: string;
    emailSuggestion?: Suggestion;

    private emailSuggestionExecutor = new EmailSuggestion();
    private emailSubscription?: Subscription;

    constructor(private fuseConfigService: FuseConfigService, private formBuilder: FormBuilder, private authService: AuthService) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, EmailValidators.normal]]
        });

        this.emailSubscription = this.form.get('email').valueChanges.subscribe(value => {
            this.emailSuggestion = this.emailSuggestionExecutor.suggest(value, emailOptionsConfig)?.suggestion;
        });
    }

    ngOnDestroy(): void {
        this.emailSubscription?.unsubscribe();
    }

    sendResetPassMail(): void {
        if (this.form.valid) {
            const email = this.form.get('email').value;
            this.email = email;
            this.authService.forgotPasswordForgottenSendMail(email).subscribe();
        }
    }
}

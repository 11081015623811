import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { AuthService } from '../../../services/api/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ReportBugDialogComponent } from 'app/main/shared/report-bug-dialog/report-bug-dialog.component';
import { environment } from '../../../../environments/environment';
import { ListVeterinaires, Veterinaire } from 'app/models/utilisateurs/veterinaire';
import { DialogComponent, DialogDataInterface } from '../../../main/shared/view-utils/dialog/dialog.component';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { ConnectionTestDialogComponent } from '../../../main/shared/connection-test/connection-test-dialog.component';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from '../../../services/config.service';
import { UserPreferenciesService } from '../../../services/user-preferencies.service';
import { EntiteGeographique } from '../../../models/pro/entite-geographique';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit {
    utilisateur: Veterinaire;
    lastAccountSelector: boolean;
    clock: Date = new Date();
    env: string;
    version: string;

    bannerMessage = null;
    cancelSubscriptionDate: Date = null;
    needsToEnablePayment = false;
    needsToRegisterOrdreVeto: string = null;

    isAdmin = false;
    isManaged = false;

    supportUrl = environment.supportUrl;

    constructor(
        public dialog: MatDialog,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private auth: AuthService,
        public utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private router: Router,
        public configService: ConfigService,
        public userPreferenciesService: UserPreferenciesService
    ) {
        interval(60_000).subscribe(() => {
            this.clock = new Date();
        });

        if (!environment.production) {
            if (environment.hmr) {
                this.env = 'Dev local (' + environment.apiUrl + ')';
            } else if (environment.dev) {
                this.env = 'Dev';
            } else if (environment.preproduction) {
                this.env = 'Preprod';
            } else if (environment.demo) {
                this.env = 'Démo';
            }
        }
    }

    ngOnInit(): void {
        this.utilisateurService.utilisateurConnected.subscribe((user: Veterinaire) => {
            this.utilisateur = user;

            this.setBannerMessage(user.locale);
            this.isAdmin = user.isProprietaire || environment.demo || environment.hmr;
            this.isManaged = user.entiteJuridique.isSubscriptionManaged;

            if (this.needsToRegisterOrdreVeto === null) {
                this.userPreferenciesService.isTrue(this.userPreferenciesService.USER_DISMISS_WARNING_ORDRE).subscribe(isDismissed => {
                    if (!isDismissed || user.entiteJuridique?.pays === 'IT') {
                        this.needsToRegisterOrdreVeto = user.entiteJuridique?.pays;

                        setTimeout(_ => {
                            this.needsToRegisterOrdreVeto = null;
                        }, 2 * 60 * 1000); // 2 minutes
                    }
                });
            }

            if (user.entiteJuridique.isDisabled) {
                return;
            }

            this.getVeterinaires(user.entiteJuridique);
        });

        this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.subscribe(entiteJuridique => {
            if (entiteJuridique.isDisabled) {
                return;
            }

            this.cancelSubscriptionDate = entiteJuridique.cancelSubscriptionDate;
            this.needsToEnablePayment = !entiteJuridique.paymentEnabled;

            this.getVeterinaires(entiteJuridique);
        });
    }

    private setBannerMessage(locale: string): void {
        let bannerObservable = this.configService.getBannerMessageEn();
        if (locale.toLowerCase().startsWith('fr')) {
            bannerObservable = this.configService.getBannerMessageFr();
        }

        bannerObservable
        .pipe(
            take(1)
        )
        .subscribe(bannerMessage => {
            this.bannerMessage = bannerMessage;
        });
    }

    private getVeterinaires(entiteJuridique: EntiteJuridique): void {
        if (entiteJuridique.accountSelector !== this.lastAccountSelector) {
            if (entiteJuridique.accountSelector) {
                this.utilisateurService.getVeterinaires(
                    null,
                    'desc',
                    'lastConnection',
                    null,
                    0,
                    true
                ).subscribe((list: ListVeterinaires) => {
                    this.utilisateurService.veterinaires$.next(list.data.sort((a, b) => a.nom < b.nom ? -1 : 1));
                    this.lastAccountSelector = entiteJuridique.accountSelector;
                });
            } else {
                this.lastAccountSelector = entiteJuridique.accountSelector;
                this.utilisateurService.veterinaires$.next([]);
            }
        }
    }

    reportBug(): void {
        this.dialog.open(ReportBugDialogComponent, {
            minWidth: '40vw',
            panelClass: 'no-padding-dialog',
            disableClose: true
        });
    }

    openTestView(): void {
        this.dialog.open(ConnectionTestDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '95vw',
            maxWidth: '1024px',
            minHeight: '60vh',
            disableClose: true,
            data: {
                closable: true
            }
        });
    }

    logout(): void {
        const data: DialogDataInterface = {
            title: this._translateService.instant('SHARED.DIALOG_LOGOUT.TITLE') as string,
            content: this._translateService.instant('SHARED.DIALOG_LOGOUT.CONTENT') as string,
            ok: this._translateService.instant('SHARED.YES') as string,
            cancel: this._translateService.instant('SHARED.NO') as string,
            action: true
        };
        this.dialog.open(DialogComponent, {
            data
        }).afterClosed().subscribe(result => {
            if (result) {
                this.auth.logout();
            }
        });
    }

    canSwitchAccount(userToConnect: Veterinaire): boolean {
        return userToConnect.id !== this.utilisateur.id && (!userToConnect.isProprietaire || this.utilisateur.isProprietaire);
    }

    loginAs(userToConnect: Veterinaire): void {
        if (this.canSwitchAccount(userToConnect)) {
            const data: DialogDataInterface = {
                title: this._translateService.instant('SHARED.DIALOG_CONNECT_AS.TITLE') as string,
                content: this._translateService.instant('SHARED.DIALOG_CONNECT_AS.CONTENT',
                    {
                        user1: this.utilisateurService.utilisateurConnectedValue.fullNameLite,
                        user2: userToConnect.fullNameLite
                    }
                ) as string,
                ok: this._translateService.instant('SHARED.YES') as string,
                cancel: this._translateService.instant('SHARED.NO') as string,
                action: true
            };
            this.dialog.open(DialogComponent, {
                data
            }).afterClosed().subscribe(res => {
                if (res) {
                    this.auth.switchUser(userToConnect.id).subscribe();
                }
            });
        }
    }

    loginTo(entiteGeographique: EntiteGeographique): void {
        this.auth.switchEntiteGeographique(entiteGeographique.id).subscribe();
    }

    hideWarningNotice(): void {
        this.bannerMessage = null;
    }

    hideCancelNotice(): void {
        this.cancelSubscriptionDate = null;
    }

    goToBankInfos(): void {
        void this.router.navigate(['bank-information']);
    }

    /**
     * Toggle sidebar opened status
     */
    toggleSidebarOpened(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleOpen();
    }

    /**
     * Toggle sidebar folded status
     */
    toggleSidebarFolded(): void {
        this._fuseSidebarService.getSidebar('navbar').toggleFold();
    }
}

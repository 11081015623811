import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { EntiteGeographique, ListEntitesGeographiques } from '../models/pro/entite-geographique';
import { EntiteGeographiqueService } from '../services/api/entite-geographique.service';

export class EntitesGeographiqueDataSource implements DataSource<EntiteGeographique> {
    private _entitesGeographique = new BehaviorSubject<EntiteGeographique[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();
    public totalCount = 0;

    private subscription: Subscription;
    private _totalCountSubscription: Subscription;

    constructor(private entiteGeographiqueService: EntiteGeographiqueService) {
        this._totalCountSubscription = this._totalCount.subscribe(total => {
            this.totalCount = total;
        });
    }

    loadEntitesGeographique(filter: string, sortDirection: string, sortField: string, pageIndex: number, pageSize: number): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.entiteGeographiqueService.getEntitesGeographiques(filter, sortDirection, sortField, pageIndex * pageSize, pageSize).pipe(
            catchError(() => of([])),
            finalize(() => this._loadingSubject.next(false))
        ).subscribe((entitesGeographique: ListEntitesGeographiques) => {
            if (entitesGeographique.data && entitesGeographique.meta) {
                this._entitesGeographique.next(entitesGeographique.data);
                this._totalCount.next(entitesGeographique.meta.totalItems);
            }
        });
    }

    add(entiteGeographique: EntiteGeographique): void {
        const data = this._entitesGeographique.getValue();
        data.push(entiteGeographique);
        this._entitesGeographique.next(data);
        this._totalCount.next(this._totalCount.getValue() + 1);
    }

    replace(entiteGeographique: EntiteGeographique): void {
        const data = this._entitesGeographique.getValue();
        const index = data.findIndex(c => c.id === entiteGeographique.id);
        data[index] = entiteGeographique;
        this._entitesGeographique.next(data);
    }

    delete(entiteGeographique: EntiteGeographique): void {
        const data = this._entitesGeographique.getValue();
        data.splice(data.findIndex(c => c.id === entiteGeographique.id), 1);
        this._entitesGeographique.next(data);
        this._totalCount.next(this._totalCount.getValue() - 1);
    }

    connect(): Observable<EntiteGeographique[]> {
        return this._entitesGeographique.asObservable();
    }

    disconnect(): void {
        this._entitesGeographique.complete();
        this._loadingSubject.complete();
        this._totalCountSubscription.unsubscribe();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }
}

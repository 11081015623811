<div fxLayout="column">
    <div class="step-header">
        <img class="icon" src="../../../../assets/images/icons/onboarding-icon-mail.svg" alt="Clinic Icon"fxFlex="0 0" />
        <h1 [innerHTML]="'ONBOARDING_CLIENT.STEPS.OTP.TITLE' | translate"></h1>
        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.OTP.SUBTITLE' | translate:{ phone: (phone | phone) }"></p>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center">
        <p [innerHTML]="'ONBOARDING_CLIENT.STEPS.OTP.SPEECH' | translate" class="p-32"></p>

        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ONBOARDING_CLIENT.STEPS.OTP.OTP_PLACEHOLDER' | translate }}</mat-label>
            <input matInput placeholder="{{ 'ONBOARDING_CLIENT.STEPS.OTP.OTP_PLACEHOLDER' | translate }}"
                [(ngModel)]="otpValue" type="text" required>
            <mat-error *ngIf="!otpValue">
                {{ 'ONBOARDING_CLIENT.STEPS.OTP.OTP_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <button *ngIf="!resendSmsEnabled" (click)="validateOtp()" mat-raised-button color="accent" [disabled]="otpLoading || !otpValue" class="mt-16">
            <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                <div>
                    {{ 'ONBOARDING_CLIENT.STEPS.OTP.VALIDATE_OTP' | translate }}
                </div>
                <mat-icon *ngIf="!otpLoading" matSuffix class="ml-8">check</mat-icon>
                <mat-spinner *ngIf="otpLoading" matSuffix [diameter]="24" class="ml-8"></mat-spinner>
            </div>
        </button>

        <button *ngIf="resendSmsEnabled" (click)="resendSms()" mat-stroked-button color="accent" class="mt-16">
            <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                <div>
                    {{ 'ONBOARDING_CLIENT.STEPS.OTP.RESEND_SMS' | translate }}
                </div>
            </div>
        </button>
    </div>
</div>

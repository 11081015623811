<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">

    <mat-form-field appearance="outline" fxFlex="1 1 100%">
        <mat-label>{{ 'FORM.NAME' | translate }}</mat-label>
        <input matInput formControlName="nom" [placeholder]="'FORM.NAME' | translate" required>
        <mat-error *ngIf="form.get('nom').hasError('required')">
            {{ 'FORM.NAME_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'FORM.MAIL' | translate }}</mat-label>
            <input matInput formControlName="mail" [placeholder]="'FORM.MAIL' | translate" required>
            <mat-error *ngIf="form.get('mail').hasError('required')">
                {{ 'FORM.MAIL_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <app-input-phone fxFlex="1 1 auto" [control]="form.get('telephone')" [disabled]="disabled"
                         [required]="true"></app-input-phone>
    </div>

    <app-input-adresse
            [controlAdresse]="form.get('adresse')"
            [controlCodePostal]="form.get('codePostal')"
            [controlVille]="form.get('ville')"
            [controlRegion]="form.get('region')"
            [controlPays]="form.get('pays')"
            [required]="true" class="w-100-p">
    </app-input-adresse>

    <h4>{{ 'FORM.OPENING_TIMES' | translate }}</h4>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px" style="margin-bottom: 32px;">
        <div formArrayName="openingTime" *ngFor="let order of orderOpeningTimeDays" class="w-100-p">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" [formGroupName]="'' + order">
                <div class="w-100">
                  <h5>{{ 'CALENDAR.DAYS.' + order | translate }}</h5>
                </div>

                <div class="w-120">
                  <mat-slide-toggle formControlName="enabled">
                    {{ (openingTimeDays.get('' + order).get('enabled').value ? 'CHAT_FILTER.STATUS_OPEN' : 'CHAT_FILTER.STATUS_CLOSE') | translate }}
                  </mat-slide-toggle>
                </div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" [style.opacity]="openingTimeDays.get('' + order).get('enabled').value ? 1 :  0">
                  <div class="mt-12">{{ 'DATE_TIME_PICKER.RANGE_FROM' | translate }}</div>
                  <mat-form-field appearance="outline" style="max-width: 100px;" class="no-padding">
                    <mat-label>{{ 'SHARED.BEGINNING' | translate }}</mat-label>
                    <input matInput type="time" formControlName="start" min="00:00">
                    <mat-error *ngIf="openingTimeDays.get('' + order).get('start').hasError('required')">
                        {{ 'SHARED.REQUIRED' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <div class="mt-12">{{ 'DATE_TIME_PICKER.RANGE_TO' | translate }}</div>
                  <mat-form-field appearance="outline" style="max-width: 100px;" class="no-padding">
                      <mat-label>{{ 'SHARED.END' | translate }}</mat-label>
                      <input matInput type="time" formControlName="end" max="23:59">
                      <mat-error *ngIf="openingTimeDays.get('' + order).get('end').hasError('required')">
                          {{ 'SHARED.REQUIRED' | translate }}
                      </mat-error>
                  </mat-form-field>
                </div>

                <div class="mt-12" [style.opacity]="openingTimeDays.get('' + order).get('enabled').value ? 1 :  0">&</div>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" [style.opacity]="openingTimeDays.get('' + order).get('enabled').value ? 1 :  0">
                  <div class="mt-12">{{ 'DATE_TIME_PICKER.RANGE_FROM' | translate }}</div>
                  <mat-form-field appearance="outline" style="max-width: 100px;" class="no-padding">
                    <mat-label>{{ 'SHARED.BEGINNING' | translate }}</mat-label>
                    <input matInput type="time" formControlName="start2" min="00:00">
                  </mat-form-field>
                  <div class="mt-12">{{ 'DATE_TIME_PICKER.RANGE_TO' | translate }}</div>
                  <mat-form-field appearance="outline" style="max-width: 100px;" class="no-padding">
                      <mat-label>{{ 'SHARED.END' | translate }}</mat-label>
                      <input matInput type="time" formControlName="end2" max="23:59">
                  </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <h4 class="subtitle">{{ 'SETTINGS.MESSAGES.AFK.TITLE' | translate }}</h4>
    <p style="text-align: right; color: #818181;">{{ 'SETTINGS.MESSAGES.AFK.DESCRIPTION' | translate }}</p>
    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <mat-form-field appearance="outline" class="text-area">
            <mat-label>{{ 'SETTINGS.MESSAGES.AFK.TITLE' | translate }}</mat-label>
            <textarea matInput formControlName="chatAwayMessage" #chatAwayMessage maxlength="512" rows="3"></textarea>
            <mat-hint align="end">{{ chatAwayMessage.value.length }} / {{ maxLengthChatAwayMessage }}</mat-hint>
            <mat-error *ngIf="form.get('chatAwayMessage').hasError('required')">
                {{ 'SETTINGS.MESSAGES.AFK.REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="form.get('chatAwayMessage').hasError('maxLength')">
                {{ 'SETTINGS.MESSAGES.AFK.MAX_LENGTH' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">

        <div fxFlex="1 1 auto">
            <h4 class="subtitle">{{ 'ENTITE_GEO.FORM.LOGO' | translate }}</h4>

            <div class="photo-wrapper" (click)="openImageDialog('logo')">
                <div class="photo-edit-overlay" *ngIf="this.form.get('logo')?.value || this.currentImage['logo']">
                    <mat-icon>edit</mat-icon>
                </div>
                <div class="photo-edit hover-activated"
                     *ngIf="this.form.get('logo')?.value || this.currentImage['logo']">
                    <img
                        [src]="this.base64Image['logo'] ? this.base64Image['logo'] : (this.currentImage['logo']?.url | filePath)"
                        class="photo-actuelle"/>
                </div>
                <div class="photo-add" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                     *ngIf="!this.form.get('logo')?.value && !this.currentImage['logo']">
                    <mat-icon>add</mat-icon>
                </div>
                <div class="photo-delete hover-activated"
                     *ngIf="this.form.get('logo')?.value || this.currentImage['logo']"
                     (click)="deleteImage($event, 'logo')">
                    <button mat-mini-fab color="primary">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div fxFlex="1 1 auto">
            <h4 class="subtitle">{{ 'ENTITE_GEO.FORM.COVER_IMAGE' | translate }}</h4>

            <div class="photo-wrapper" (click)="openImageDialog('imageCouverture')">
                <div class="photo-edit-overlay"
                     *ngIf="this.form.get('imageCouverture')?.value || this.currentImage['imageCouverture']">
                    <mat-icon>edit</mat-icon>
                </div>
                <div class="photo-edit hover-activated"
                     *ngIf="this.form.get('imageCouverture')?.value || this.currentImage['logo']">
                    <img
                        [src]="this.base64Image['imageCouverture'] ? this.base64Image['imageCouverture'] : (this.currentImage['imageCouverture']?.url | filePath)"
                        class="photo-actuelle"/>
                </div>
                <div class="photo-add" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                     *ngIf="!this.form.get('imageCouverture')?.value && !this.currentImage['imageCouverture']">
                    <mat-icon>add</mat-icon>
                    {{ 'ENTITE_GEO.FORM.COVER_IMAGE' | translate}}
                </div>
                <div class="photo-delete hover-activated"
                     *ngIf="this.form.get('imageCouverture')?.value || this.currentImage['imageCouverture']"
                     (click)="deleteImage($event, 'imageCouverture')">
                    <button mat-mini-fab color="primary">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <app-input-phone fxFlex="1 1 auto" [control]="form.get('telephoneUrgence')" [disabled]="disabled"
                         [label]="'ENTITE_GEO.FORM.EMERGENCY_PHONE' | translate"></app-input-phone>

        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_APPOINTMENT' | translate }}</mat-label>
            <input matInput formControlName="lienPriseRendezVous"
                   [placeholder]="'ENTITE_GEO.FORM.LINK_APPOINTMENT' | translate" type="url">
            <mat-error *ngIf="form.get('lienPriseRendezVous')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_SHOP' | translate }}</mat-label>
            <input matInput formControlName="lienBoutique" [placeholder]="'ENTITE_GEO.FORM.LINK_SHOP' | translate"
                   type="url">
            <mat-error *ngIf="form.get('lienBoutique')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_WEBSITE' | translate }}</mat-label>
            <input matInput formControlName="lienSiteWeb" [placeholder]="'ENTITE_GEO.FORM.LINK_WEBSITE' | translate"
                   type="url">
            <mat-error *ngIf="form.get('lienSiteWeb')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxFlex="1 1 100%" fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_FACEBOOK' | translate }}</mat-label>
            <input matInput formControlName="lienFacebook" [placeholder]="'ENTITE_GEO.FORM.LINK_FACEBOOK' | translate"
                   type="url">
            <mat-error *ngIf="form.get('lienFacebook')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_TWITTER' | translate }}</mat-label>
            <input matInput formControlName="lienTwitter" [placeholder]="'ENTITE_GEO.FORM.LINK_TWITTER' | translate"
                   type="url">
            <mat-error *ngIf="form.get('lienTwitter')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 1 auto">
            <mat-label>{{ 'ENTITE_GEO.FORM.LINK_INSTAGRAM' | translate }}</mat-label>
            <input matInput formControlName="lienInstagram" [placeholder]="'ENTITE_GEO.FORM.LINK_INSTAGRAM' | translate"
                   type="url">
            <mat-error *ngIf="form.get('lienInstagram')?.hasError('invalidUrl')">
                {{ 'FORM.URL_WRONG_FORMAT' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="end center" *ngIf="form.enabled" fxLayoutGap="8px">
        <button *ngIf="!disabled && canBeDeleted" type="button" mat-flat-button color="warn"
                [disabled]="formInProgress$ | async" (click)="delete()">
            <mat-icon>delete</mat-icon>
            {{ 'SHARED.DELETE' | translate }}
        </button>
        <button type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
            <mat-icon>save</mat-icon>
            {{ 'FORM.SAVE' | translate }}
        </button>

        <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="formInProgress$ | async"></mat-spinner>
    </div>
</form>

<div class="page-layout simple fullwidth p-16" (scroll)="onScroll($event)">
    <div class="center">

        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16" *fuseIfOnDom>pets</mat-icon>
                <span class="logo-text h1"
                    *fuseIfOnDom>{{ isLoading ? ('FILES.LOADING_FILES' | translate) : ('FILES.LINK_TO' | translate) + ' ' + animal?.nom }}</span>
            </div>

            <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
                <div>e
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field appearance="outline" floatLabel="never" fxFlex="1 0 auto">
<mat-label>Rechercher</mat-label>							<input id="search" matInput #filter placeholder="Rechercher">
                        </mat-form-field>
                    </div>
                </div>
            </div> -->

        </div>

        <div class="content-card" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div class="empty-state" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px"
                 *ngIf="!isLoading && (fichiers | values).length == 0;">
                <img src="/assets/images/empty-state-file.svg"/>

                <span class="app-title my-16" *fuseIfOnDom>{{ 'FILES.LINK_ANY' | translate }} {{ animal?.nom }}</span>

                <span class="app-message" *fuseIfOnDom
                      [innerHTML]="'FILES.SHARED_IN_CONV' | translate:{ animal: animal?.nom }"></span>

                <button mat-raised-button color="accent" (click)="addFile()">
                    <mat-icon>add</mat-icon>
                    {{ 'SHARED.ADD_FILE' | translate }}
                </button>
            </div>

            <app-list-fichier #listFichier
                              [input]="animal"
                              (fichiersChanged)="onFilesUpdated($event)"
                              (selectedFichiersChanged)="onSelectedFilesUpdated($event)"
                              (loadingStateChanged)="isLoading = $event"
                              [refresh]="refreshFiles"
                              fxFlex="0 0 100%"
                              [hidden]="!isLoading && (fichiers | values).length == 0"></app-list-fichier>
        </div>
    </div>
</div>

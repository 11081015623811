import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { SharedModule } from '../../../shared.module';
import { EntitesGeographiqueComponent } from './entites-geographique.component';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'clinics',
        component: EntitesGeographiqueComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'cliniques',
        redirectTo: '/clinics',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        EntitesGeographiqueComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        SharedModule,
        ViewSharedModule
    ],
    exports: [
        EntitesGeographiqueComponent
    ]
})

export class EntitesGeographiqueModule {}

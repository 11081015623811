<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center" [style.height]="rendezVous?.id ? '100px' : ''">
            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px" class="w-100-p">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                    <span class="title dialog-title">{{ rendezVous?.id ? ('CALENDAR.DATE' | translate) + ' : ' + rendezVous?.nature + ' ' + ('DATE.THE' | translate) + ' ' + (rendezVous?.date | dateLocale:'fullDate') + ' ' + ('DATE.AT' | translate) + ' ' + (rendezVous?.date | dateLocale:'shortTime') : ('CALENDAR.DATE_NEW' | translate) }}</span>
                    <button mat-icon-button (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" class="w-100-p" *ngIf="rendezVous?.id">
                    <mat-icon
                        class="mr-8">{{ rendezVous.isFinished || rendezVous.clientConfirme ? 'check' : 'warning'}}</mat-icon>
                    {{ ('RENDEZ_VOUS.STATUS.' + rendezVous.correctStatut) | translate }}
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <app-rendez-vous-form [entityData]="rendezVousData$"
                                  [pricePayed]="rendezVous?.pricePayed"
                                  [datePayed]="rendezVous.paymentCurrentStateDate"
                                  (entityReceived)="rendezVous$.next($event); hasChanged = true; close();"
                                  (entityDeleted)="close(true)" (entityCalled)="call()"
                                  (entityCapture)="capture()"
                                  [canBeFullEdited]="rendezVous?.canBeFullEdited"
                                  [canBeRemoved]="rendezVous?.canBeDeleted"
                                  [mustCapture]="rendezVous?.mustCapture"
                                  [canCall]="!rendezVous?.isFinished"></app-rendez-vous-form>
        </ng-template>
    </div>
</div>

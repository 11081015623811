import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

export enum StatValueMeaning{
    ZeroIsGood,
    ZeroIsBad,
    ItDoesNotMatter
}

@Component({
    selector: 'app-stats-card',
    templateUrl: './stats-card.component.html',
    styleUrls: ['./stats-card.component.scss'],
    animations: [fuseAnimations]
})
export class StatsCardComponent implements OnChanges {
    @Input() titleLabel: string;
    @Input() stat: number;
    @Input() unit?: string;
    @Input() detail?: string;
    @Input() action?: string;
    @Input() loading?: boolean;
    @Input() statValueMeaning: StatValueMeaning = StatValueMeaning.ItDoesNotMatter;

    @Input() icon = 'open_in_new';
    @Input() color = 'white';
    @Output('onActionClick') actionClicked$ = new EventEmitter<null>();

    private getSuitableColorForStatValue(): string {
        switch (this.statValueMeaning) {
            case StatValueMeaning.ZeroIsBad:
                return this.stat === 0 ? 'warn-500' : 'light-green-700';
            case StatValueMeaning.ZeroIsGood:
                return this.stat === 0 ? 'light-green-700' : 'warn-500';
            case StatValueMeaning.ItDoesNotMatter:
            default:
                return 'white';
        }
    }

    ngOnChanges(): void {
        this.color = this.getSuitableColorForStatValue();
    }
}

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ChatService } from '../services/api/chat.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatResolver implements Resolve<any> {
    constructor(private chatService: ChatService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.chatService.setChannelSelectedByChatId(Number(route.params.chatId));
    }
}

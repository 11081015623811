import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FichierIndex, FichierSelectionMode } from '../list-fichier.component';
import { FichierService } from '../../../../services/api/fichier.service';
import { ChatService } from '../../../../services/api/chat.service';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-fichier',
    templateUrl: './fichier.component.html',
    styleUrls: ['./fichier.component.scss']
})
export class FichierComponent {
    @Input() fichierIndex: FichierIndex;
    @Input() displayAction = true;
    @Input() selectionMode: FichierSelectionMode = FichierSelectionMode.NONE;

    @Output() openFile = new EventEmitter<FichierIndex>();
    @Output() editImage = new EventEmitter<FichierIndex>();
    @Output() toggleSelect = new EventEmitter<FichierIndex>();
    @Output() downloadFile = new EventEmitter<FichierIndex>();
    @Output() removeFile = new EventEmitter<FichierIndex>();

    mimeRegex = /\w+\/(?:\w+.\w+-)?(\w+)/;

    constructor(
        private fichierService: FichierService,
        public chatService: ChatService
    ) {}

    sendTchat(fichierIndex: FichierIndex): void {
        this.fichierService.getBlobFromFichier(fichierIndex.fichier).pipe(
            switchMap(file => this.chatService.sendFileMessage(file))
        ).subscribe();
    }
}

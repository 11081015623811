import { Component, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { VisioService } from '../../../../services/visio.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-visio-video',
    templateUrl: './visio-publisher-video.component.html',
    styleUrls: ['./visio-publisher-video.component.scss']
})

export class PublisherVideoComponent implements AfterViewInit, OnDestroy {
    @ViewChild('container') container: ElementRef;
    @ViewChild('image') image: ElementRef<HTMLImageElement>;
    @ViewChild('canvas') canvas: ElementRef<HTMLCanvasElement>;
    @ViewChild('video') video: ElementRef<HTMLVideoElement>;

    private interval;
    private refreshRate = 30;

    public url: SafeUrl;
    public type: 'image' | 'video';

    constructor(
        private visioService: VisioService,
        private sanitizer: DomSanitizer
    ) {
        const sf = this.visioService.currentStreamingFileObject;
        this.url = this.sanitizer.bypassSecurityTrustUrl(sf.url);
        this.type = sf.type;
    }

    ngAfterViewInit(): void {
        if (this.type === 'image') {
            this.refreshRate = 10;

            this.image.nativeElement.addEventListener('load', () => {
                this.interval = setInterval(() => {
                    this.drawImageInCanvas(
                        this.container.nativeElement,
                        this.canvas.nativeElement,
                        this.image.nativeElement
                    );
                }, 1000 / this.refreshRate);

                this.visioService.onPublishCanvasReady(this.canvas.nativeElement);
            });
        } else if (this.type === 'video') {
            this.visioService.onPublishVideoReady(this.video.nativeElement);
        } else {
            throw new Error('Unsupported type');
        }
    }

    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    private drawImageInCanvas(container, canvas, image) {
        const cs = getComputedStyle(container);
        const canvasWidth = Number.parseInt(cs.getPropertyValue('width'), 10);
        const canvasHeight = Number.parseInt(cs.getPropertyValue('height'), 10);

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const context = canvas.getContext('2d');
        const imageAspectRatio = image.width / image.height;
        const canvasAspectRatio = canvas.width / canvas.height;
        let renderableHeight: number;
        let renderableWidth: number;
        let xStart: number;
        let yStart: number;

        if (imageAspectRatio < canvasAspectRatio) {
            renderableHeight = canvas.height;
            renderableWidth = image.width * (renderableHeight / image.height);
            xStart = (canvas.width - renderableWidth) / 2;
            yStart = 0;
        } else if (imageAspectRatio > canvasAspectRatio) {
            renderableWidth = canvas.width;
            renderableHeight = image.height * (renderableWidth / image.width);
            xStart = 0;
            yStart = (canvas.height - renderableHeight) / 2;
        } else {
            renderableHeight = canvas.height;
            renderableWidth = canvas.width;
            xStart = 0;
            yStart = 0;
        }

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, xStart, yStart, renderableWidth, renderableHeight);
    }
}

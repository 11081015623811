<fuse-progress-bar></fuse-progress-bar>

<div id="main">

    <div id="container-1" class="container">

        <fuse-sidebar name="navbar" class="navbar-fuse-sidebar"
                      [folded]="fuseConfig.layout.navbar.folded"
                      lockedOpen="gt-md"
                      [class.hidden]="fuseConfig.layout.navbar.hidden">
            <navbar class="left-navbar"></navbar>
        </fuse-sidebar>

        <div id="container-2" class="container">

            <toolbar *ngIf="!fuseConfig.layout.toolbar.hidden" class="below-fixed secondary-500-bg"></toolbar>

            <div id="container-3" class="container">
                <content></content>
            </div>
        </div>
    </div>
</div>

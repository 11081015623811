<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'CHAT.SHORTCUT_SELECTION.TITLE' | translate }}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">

        <div fxLayout="column wrap" fxLayoutAlign="start stretch" fxLayoutGap="16px">
            <app-chat-scenario-message-selector
                [chatShortcuts]="shortcuts"
                [selectedMessage]=null
                (selectShortcut)="updateSelectedShortcut($event)"
            ></app-chat-scenario-message-selector>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="w-100-p">
                <button type="button" mat-raised-button color="primary" (click)="cancel()">
                    <mat-icon>cancel</mat-icon>&nbsp;{{ 'SETTINGS.MESSAGES.SHORTCUTS.CANCEL' | translate }}
                </button>
                <button type="button" mat-raised-button color="accent" [disabled]="!selectedShortcut" (click)="sendSelectedShortcut()">
                    <mat-icon>check</mat-icon>
                    {{ 'CHAT.SHORTCUT_SELECTION.SELECT' | translate }}
                </button>
            </div>
        </div>

    </div>
</div>

<div class="color-picker">
    <div
        fxLayout="row"
        fxLayoutAlign="center center"
        class="circle"
        [class.border]="!color"
        [ngStyle]="{'background': color}"
        [matTooltip]="tooltip"
        (click)="toggleColors(); $event.stopPropagation();">
        <mat-icon *ngIf="!color">add</mat-icon>
    </div>

    <div *ngIf="show" class="selection-box" @selectionBox>
        <div class="colors">
            <div *ngIf="color" class="circle" (click)="removeColor(); $event.stopPropagation();">
                <mat-icon>remove</mat-icon>
            </div>
            <div (click)="changeColor(paint); $event.stopPropagation();" *ngFor="let paint of colors" class="circle"
                [ngStyle]="{'background': paint}"></div>
        </div>
    </div>
</div>

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ConnecteurService } from '../../../../../services/api/connecteur.service';

@Component({
    selector: 'app-input-connector',
    templateUrl: './input-connector.component.html',
    styleUrls: ['./input-connector.component.scss']
})
export class InputConnectorComponent {
    @Input() control: AbstractControl;
    @Input() required: boolean;

    constructor(public connecteurService: ConnecteurService) {}
}

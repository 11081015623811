import { Component } from '@angular/core';
import { ConnecteurService } from '../../../../services/api/connecteur.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Animal } from '../../../../models/animal/animal';
import { Veterinaire } from '../../../../models/utilisateurs/veterinaire';
import { Client } from 'app/models/utilisateurs/client';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { Utils } from '../../../../utils';
import { StepperSelectionEvent } from '@angular/cdk/stepper/stepper';
import { AnimalService } from '../../../../services/api/animal.service';
import { PhonePipe } from '../../../../pipes/phone.pipe';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entity } from '../../../../models/entity';
import { DataEntityLinkPossible } from '../../../../models/data-entity-link-possible';
import { MatStepper } from '@angular/material/stepper';

@Component({
    selector: 'app-connector-import',
    templateUrl: './connector-import.component.html',
    styleUrls: ['./connector-import.component.scss']
})
export class ConnectorImportComponent {
    csvDatasFiltered$: BehaviorSubject<DataLinkInterface[]> = new BehaviorSubject<DataLinkInterface[]>([]);

    rawCsvDatas: any[];
    csvDatas: DataLinkInterface[];
    csvDatasColumns: string[];
    csvDatasColumnsTable: string[];
    loading: boolean;

    formConfig: FormGroup;
    configCsv: FormGroup;
    filterControl: FormControl = new FormControl();

    entities: Array<Animal | Client | Veterinaire>;

    private phonePipe: PhonePipe;

    constructor(private connecteurService: ConnecteurService, private ngxCsvParser: NgxCsvParser,
                private utilisateurService: UtilisateurService, private animalService: AnimalService) {
        this.phonePipe = new PhonePipe(utilisateurService);

        this.configCsv = new FormGroup({});
        this.formConfig = new FormGroup({
            config: new FormGroup({
                type: new FormControl(null, [Validators.required]),
                file: new FormControl(null, [Validators.required])
            }),
            configCsv: this.configCsv
        });

        this.filterControl.valueChanges.pipe(
            debounceTime(150)
        ).subscribe(() => {
            const toSearch: string[] = this.filterControl.value ? this.filterControl.value.toLowerCase().split(' ') : [];

            this.csvDatasFiltered$.next([...this.csvDatas]
                .filter(d => toSearch.length > 0 ? toSearch.every(s => d.data.concatenated.includes(s)) : true));
        });
    }

    fileChangeListener(event: Event, stepper: MatStepper): void {
        const files = (event.target as HTMLInputElement).files;

        this.ngxCsvParser.parse(files[0], { header: true, delimiter: ',' }).pipe().subscribe({
            next: (result: any[]) => {
                if (result.length > 0) {
                    this.rawCsvDatas = result;
                    this.csvDatasColumns = Object.keys(result[0]);
                    this.csvDatasColumnsTable = ['fullName'];

                    this.entities = [];

                    this.configCsv = new FormGroup({
                        connectorId: new FormControl('id', [Validators.required]),
                        nom: new FormControl('nom', [Validators.required])
                    });
                    this.configCsv.setParent(this.formConfig);

                    switch (this.currentTypeChoosen) {
                        case 'veterinaries':
                            this.csvDatasColumnsTable.push('mail', 'telephonePortable');

                            this.configCsv.addControl('prenom', new FormControl('prenom', []));
                            this.configCsv.addControl('mail', new FormControl('mail', [Validators.required]));
                            this.configCsv.addControl('telephonePortable', new FormControl('telephone_portable', []));
                            break;

                        case 'clients':
                            this.csvDatasColumnsTable.push('mail', 'telephonePortable');

                            this.configCsv.addControl('prenom', new FormControl('prenom', []));
                            this.configCsv.addControl('mail', new FormControl('mail', []));
                            this.configCsv.addControl('telephonePortable', new FormControl('telephone_portable', [Validators.required]));
                            break;

                        case 'animalsClients':
                            this.csvDatasColumnsTable.push('fullNameClient', 'mailClient', 'telephonePortableClient');

                            this.configCsv.addControl('connectorIdClient', new FormControl('idClient', [Validators.required]));
                            this.configCsv.addControl('nomClient', new FormControl('nomClient', []));
                            this.configCsv.addControl('prenomClient', new FormControl('prenomClient', []));
                            this.configCsv.addControl('mailClient', new FormControl('mailClient', []));
                            this.configCsv.addControl('telephonePortableClient', new FormControl('telephonePortableClient', []));
                            break;

                        default:
                            throw new TypeError('Entity type not found');
                    }

                    this.formConfig.setControl('configCsv', this.configCsv);

                    stepper.next();

                    (event.target as HTMLInputElement).value = null;
                } else {
                    this.csvDatas = null;
                    this.csvDatasColumns = null;
                }
            },
            error: (error: NgxCSVParserError) => {
                alert(error.message);
            }
        });
    }

    computeDataForKeysDefined(): void {
        let req;
        const configCsv = this.formConfig.get('configCsv');
        this.loading = true;

        switch (this.currentTypeChoosen) {
            case 'veterinaries':
                req = this.utilisateurService.veterinaires$.asObservable();

                this.csvDatas = this.rawCsvDatas.map(d => {
                    return {
                        data: new DataEntityLinkPossible({
                            id: configCsv.get('connectorId') ? d[configCsv.get('connectorId').value] : null,
                            nom: configCsv.get('nom') ? d[configCsv.get('nom').value] : null,
                            prenom: configCsv.get('prenom') ? d[configCsv.get('prenom').value] : null,
                            mail: configCsv.get('mail') ? d[configCsv.get('mail').value] : null,
                            telephonePortable: configCsv.get('telephonePortable') ? this.phonePipe.transform(d[configCsv.get('telephonePortable').value]) : null
                        })
                    };
                });
                break;

            case 'clients':
                req = this.utilisateurService.getClients(null, null, null, null, 0, true).pipe(
                    map(c => c.data)
                );

                this.csvDatas = this.rawCsvDatas.map(d => {
                    return {
                        data: new DataEntityLinkPossible({
                            id: configCsv.get('connectorId') ? d[configCsv.get('connectorId').value] : null,
                            nom: configCsv.get('nom') ? d[configCsv.get('nom').value] : null,
                            prenom: configCsv.get('prenom') ? d[configCsv.get('prenom').value] : null,
                            mail: configCsv.get('mail') ? d[configCsv.get('mail').value] : null,
                            telephonePortable: configCsv.get('telephonePortable') ? this.phonePipe.transform(d[configCsv.get('telephonePortable').value]) : null
                        })
                    };
                });
                break;

            case 'animalsClients':
                req = this.utilisateurService.getClients(null, null, null, null, 0, true).pipe(
                    map(c => c.data)
                );

                this.csvDatas = this.rawCsvDatas.map(d => {
                    return {
                        data: new DataEntityLinkPossible({
                            id: configCsv.get('connectorId') ? d[configCsv.get('connectorId').value] : null,
                            nom: configCsv.get('nom') ? d[configCsv.get('nom').value] : null,
                            idClient: configCsv.get('connectorIdClient') ? d[configCsv.get('connectorIdClient').value] : null,
                            nomClient: configCsv.get('nomClient') ? d[configCsv.get('nomClient').value] : null,
                            prenomClient: configCsv.get('prenomClient') ? d[configCsv.get('prenomClient').value] : null,
                            mailClient: configCsv.get('mailClient') ? d[configCsv.get('mailClient').value] : null,
                            telephonePortableClient: configCsv.get('telephonePortableClient') ? this.phonePipe.transform(d[configCsv.get('telephonePortableClient').value]) : null
                        })
                    };
                });
                break;

            default:
                throw new TypeError('Entity type not found');
        }

        req.subscribe(datas => {
            this.entities = datas;

            if (this.currentTypeChoosen === 'animalsClients') {
                datas = datas.reduce((acc: Animal[], obj: Client) => {
                    obj.animaux.forEach(a => {
                        a.proprietaire = obj;
                    });
                    return [...acc, ...obj.animaux];
                }, []);
            }

            this.csvDatas.forEach(csvData => {
                csvData.percentSureLink = 0;
                csvData.control = new FormControl('');

                const entitiesPossible = datas.sort((a, b) => {
                    return this.connecteurService.compareEntityWithCsvData(csvData.data, a) < this.connecteurService.compareEntityWithCsvData(csvData.data, b) ? 1 : -1;
                }).slice(0, 10).filter(e => this.connecteurService.compareEntityWithCsvData(csvData.data, e) >= 0.25);

                const entity = entitiesPossible.length > 0 ? entitiesPossible[0] : null;
                if (entity) {
                    csvData.percentSureLink = this.connecteurService.compareEntityWithCsvData(csvData.data, entity);
                    csvData.control.setValue(entity.id);
                }
            });
            this.csvDatas = this.csvDatas.sort((a, b) => {
                if (a.percentSureLink === b.percentSureLink) {
                    return a.data.nom < b.data.nom ? -1 : 1;
                }

                return b.percentSureLink > a.percentSureLink ? 1 : -1;
            });

            this.csvDatasFiltered$.next(this.csvDatas);

            this.connecteurService.saveCsvDatas(this.csvDatas.map(d => d.data), this.currentTypeChoosen).subscribe();

            // console.log(this.entities, this.csvDatas);

            this.loading = false;
        }, () => {
            this.loading = false;
        });
    }

    get currentTypeChoosen(): string {
        return this.formConfig.get('config').get('type').value as string;
    }

    doAction(data: DataLinkInterface, idForce: string = null): Observable<any | Entity> {
        const idWatched = idForce ?? data.control.value;

        if (idWatched) {
            switch (this.currentTypeChoosen) {
                case 'veterinaries':
                    return this.utilisateurService.openDialog(this.newEditVeterinaie(this.entities.find(e => e.id === idWatched) as Veterinaire, data)).afterClosed();

                case 'clients':
                    return this.utilisateurService.openDialog(this.newEditClient(this.entities.find(e => e.id === idWatched) as Client, data)).afterClosed();

                case 'animalsClients':
                    let req: Observable<any | Entity>;

                    const id: number | string = data.control.value;
                    if (typeof id !== 'number' && id.startsWith('add')) {
                        const clientId: number = +id.replace('add-', '');

                        const animal = this.newEditAnimal(new Animal(), this.newEditClient(this.entities.find(e => e.id === clientId) as Client, data), data);

                        req = this.utilisateurService.openDialog(animal.proprietaire).afterClosed();
                    } else {
                        let animal: Animal = this.entities.reduce((acc: Animal[], obj: Client) => {
                            obj.animaux.forEach(a => {
                                a.proprietaire = obj;
                            });
                            return [...acc, ...obj.animaux];
                        }, []).find(e => e.id === id);
                        animal = this.newEditAnimal(animal, animal.proprietaire, data);

                        req = this.animalService.openDialogAnimal(animal).afterClosed();
                    }

                    return req;

                default:
                    throw new TypeError('Entity type not found');
            }
        } else {
            switch (this.currentTypeChoosen) {
                case 'veterinaries':
                    return this.utilisateurService.openDialog(this.newEditVeterinaie(new Veterinaire(), data)).afterClosed();

                case 'clients':
                    return this.utilisateurService.openDialog(this.newEditClient(new Client(), data)).afterClosed();

                case 'animalsClients':
                    const animal = this.newEditAnimal(new Animal(), this.newEditClient(new Client(), data), data);

                    return this.utilisateurService.openDialog(animal.proprietaire).afterClosed().pipe(
                        switchMap(_ => this.animalService.openDialogAnimal(animal).afterClosed())
                    );

                default:
                    throw new TypeError('Entity type not found');
            }
        }
    }

    private newEditVeterinaie(veterinaire: Veterinaire, data: DataLinkInterface): Veterinaire {
        veterinaire.entiteGeographique = this.utilisateurService.utilisateurConnectedValue.entiteGeographique;
        veterinaire.locale = this.utilisateurService.utilisateurConnectedValue.locale;
        veterinaire.timezone = this.utilisateurService.utilisateurConnectedValue.timezone;

        if (!veterinaire.nom) {
            veterinaire.nom = data.data.nom;
        }

        if (!veterinaire.prenom) {
            veterinaire.prenom = data.data.prenom;
        }

        if (!veterinaire.telephonePortable) {
            veterinaire.telephonePortable = data.data.telephonePortable;
        }

        if (!veterinaire.mail) {
            veterinaire.mail = data.data.mail;
        }

        veterinaire.connectorId = data.data.id;

        return veterinaire;
    }

    private newEditClient(client: Client, data: DataLinkInterface): Client {
        client.entiteGeographique = this.utilisateurService.utilisateurConnectedValue.entiteGeographique;
        client.locale = this.utilisateurService.utilisateurConnectedValue.locale;
        client.timezone = this.utilisateurService.utilisateurConnectedValue.timezone;

        if (!client.nom) {
            client.nom = data.data.nomClient ?? data.data.nom;
        }

        if (!client.prenom) {
            client.prenom = data.data.prenomClient ?? data.data.prenom;
        }

        if (!client.telephonePortable) {
            client.telephonePortable = data.data.telephonePortableClient ?? data.data.telephonePortable;
        }

        if (!client.mail) {
            client.mail = data.data.mailClient ?? data.data.mail;
        }

        client.connectorId = data.data.idClient ?? data.data.id;

        return client;
    }

    private newEditAnimal(animal: Animal, client: Client, data: DataLinkInterface): Animal {
        if (!animal.nom) {
            animal.nom = data.data.nom;
        }

        animal.connectorId = data.data.id;
        animal.proprietaire = client;

        return animal;
    }

    isRequiredField(control: AbstractControl): boolean {
        return Utils.isRequiredField(control);
    }

    changeStepper(event: StepperSelectionEvent): void {
        if (event.selectedIndex === 0) {
            this.formConfig.reset();
            this.entities = [];
            this.csvDatas = [];
            this.csvDatasFiltered$.next([]);
            this.csvDatasColumns = [];
            this.csvDatasColumnsTable = [];
            this.filterControl.setValue('');
        } else if (event.previouslySelectedIndex === 2 && event.selectedIndex === 1) {
            this.entities = [];
            this.csvDatas = [];
            this.csvDatasFiltered$.next([]);
            this.filterControl.setValue('');
        } else if (event.selectedIndex === 2) {
            this.computeDataForKeysDefined();
        }
    }
}

interface DataLinkInterface {
    control?: FormControl;
    data: DataEntityLinkPossible;
    percentSureLink?: number;
}

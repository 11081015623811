import { NgModule } from '@angular/core';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { ListFichierComponent } from './list-fichier.component';
import { FichierComponent } from './fichier/fichier.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';

@NgModule({
    declarations: [
        ListFichierComponent,
        FichierComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        LazyLoadImageModule,
        PhotoGalleryModule
    ],
    exports: [ListFichierComponent]
})
export class ListFichierModule {}

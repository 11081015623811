import { Component, Input, OnChanges } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AnimalService } from '../../../../services/api/animal.service';
import { Animal } from '../../../../models/animal/animal';
import { Client } from '../../../../models/utilisateurs/client';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { Router } from '@angular/router';
import { ActionsMenuInterface } from '../../../shared/view-utils/actions-menu/actions-menu.component';
import { Chat } from '../../../../models/animal/chat';
import { ChatService } from '../../../../services/api/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { ConnecteurService } from '../../../../services/api/connecteur.service';

@Component({
    selector: 'app-animaux',
    templateUrl: './animaux.component.html',
    styleUrls: ['./animaux.component.scss'],
    animations: fuseAnimations
})
export class AnimauxComponent implements OnChanges {
    @Input() client: Client;
    actions: ActionsMenuInterface[];

    constructor(private animalService: AnimalService, private utilisateurService: UtilisateurService,
        private rendezVousService: RendezVousService, private chatService: ChatService,
        private router: Router, private translateService: TranslateService,
        public connecteurService: ConnecteurService) {
        this.actions = [
            {
                title: this.translateService.instant('ANIMAL.ACTIONS.DETAIL'),
                icon: 'info',
                onClick: (_, id) => this.openAnimal(this.client.animaux.find(animal => animal.id === id))
            },
            {
                title: this.translateService.instant('ANIMAL.ACTIONS.FILES'),
                icon: 'photo',
                onClick: (_, id) => {
                    void this.router.navigate(['/animal', id, 'files']);
                }
            },
            // { // TODO: Réactiver les rapports quand ils seront prêts
            //     title: this.translateService.instant('REPORTS.ANIMALS.LIST_ITEM_REPORTS'),
            //     icon: 'assignment',
            //     onClick: (_, id) => {
            //         void this.router.navigate(['/history', id]);
            //     }
            // },
            {
                title: this.translateService.instant('ANIMAL.ACTIONS.ADD_APPOINTMENT'),
                icon: 'video_call',
                onClick: (_, id) => {
                    const animal = this.client.animaux.find(a => a.id === id);
                    if (animal) {
                        this.createRdv(animal);
                    }
                }
            },
            {
                title: this.translateService.instant('ANIMAL.ACTIONS.ADD_CHAT'),
                icon: 'chat',
                onClick: (_, id) => {
                    const animal = this.client.animaux.find(a => a.id === id);
                    if (animal) {
                        this.createTchat(animal);
                    }
                }
            },
            {
                title: this.translateService.instant('ANIMAL.ACTIONS.CREATE_EMERGENCY'),
                class: 'warn-fg',
                icon: 'video_call',
                onClick: (_, id) => {
                    this.rendezVousService.addRendezVous(this.rendezVousService.getRendezVousUrgencePost(
                        this.utilisateurService.utilisateurConnectedValue,
                        this.client.animaux.find(a => a.id === id)
                    )).subscribe(r => {
                        if (r.id) {
                            void this.router.navigate(['/visio', r.id]);
                        }
                    });
                }
            }
        ];
    }

    ngOnChanges(): void {
        if (this.client.animaux) {
            this.client.animaux = this.client.animaux.sort((a, b) => a.nom < b.nom ? -1 : 1);
        } else {
            this.client.animaux = [];
        }
    }

    openAnimal(animal: Animal = null): void {
        const isNew = animal === null;

        if (!animal) {
            animal = new Animal();
            animal.proprietaire = this.client;
        }

        this.animalService.openDialogAnimal(animal).afterClosed().subscribe((result: Animal) => {
            if (result) {
                if (isNew) {
                    this.client.animaux.push(result);
                } else if (result.id) {
                    this.client.animaux[this.client.animaux.findIndex(a => a.id === result.id)] = result;
                }

                this.client.animaux = this.client.animaux.sort((a, b) => a.nom < b.nom ? -1 : 1);
            }
        });
    }

    removeAnimal(animal: Animal): void {
        this.animalService.deleteAnimal(animal.id).subscribe(() => {
            this.client.animaux.splice(this.client.animaux.findIndex(a => a.id === animal.id), 1);
        });
    }

    createRdv(animal: Animal): void {
        const newRdv = new RendezVous();
        newRdv.client = animal.proprietaire;
        newRdv.animal = animal;
        newRdv.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.rendezVousService.openDialogRendezVous(newRdv);
    }

    createTchat(animal: Animal): void {
        const chat = new Chat();
        chat.dateDebut = new Date();
        chat.animal = animal;
        chat.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.chatService.openDialogChat(chat);
    }
}

import { Injectable } from '@angular/core';
import { Report, ReportTarget } from '../../models/rendez-vous/report';
import { Note } from '../../models/rendez-vous/note';
import { RendezVous } from '../../models/rendez-vous/rendez-vous';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { Client } from '../../models/utilisateurs/client';
import { EntiteEnTeteRapportInterface } from '../../models/interfaces/post/pro/entite-en-tete-rapport.interface';
import { Observable } from 'rxjs';
import { Animal } from '../../models/animal/animal';
import { TagEnclosedAreaManagerService, TagHandlingOption } from './tag-enclosed-area-manager.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { map } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { EmailDataInterface } from '../../models/interfaces/email-data.interface';
import { ReportPostInterface } from '../../models/interfaces/report-post.interface';
import { TranslateService } from '@ngx-translate/core';
import { Fichier } from 'app/models/fichier';
import { FichierService } from './fichier.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
    constructor(
        private TEAService: TagEnclosedAreaManagerService,
        private http: HttpClient,
        private config: ConfigService,
        private translateService: TranslateService,
        private fichierService: FichierService
    ) {}

    public getById(reportId: number): Observable<Report> {
        return this.http.get<Report>(`${this.config.baseUrl}api/report/${reportId}`).pipe(
            map(report => ObjectMapper.deserialize(Report, report))
        );
    }

    public getReportByRendezVousIdAndTarget(rdvId: number, target: ReportTarget): Observable<Report> {
        return this.http.get<Report>(`${this.config.baseUrl}api/rendez_vous/${rdvId}/report/${target}`).pipe(
            map(data => {
                if (!data) {
                    return null;
                }

                const report = ObjectMapper.deserialize(Report, data);
                if (!report.id) {
                    return null;
                }

                return report;
            })
        );
    }

    public saveReport(report: ReportPostInterface): Observable<Report> {
        const reportName = report.name?.trim();

        if (reportName === '') {
            return new Observable<Report>(obs => {
                obs.error(null);
            });
        }

        report.name = reportName;
        if (!report.id) {
            report.id = 0;
        }

        const add = this.http.post<Report>(`${this.config.baseUrl}api/report`, report);
        const update = this.http.put<Report>(`${this.config.baseUrl}api/report/${report.id}`, report);
        const addOrUpdate = report.id === 0 ? add : update;

        return addOrUpdate.pipe(
            map((report: Report) => ObjectMapper.deserialize(Report, report))
        );
    }

    public sendByEmail(data: EmailDataInterface): Observable<Report|boolean> {
        if (!data.report.id) {
            data.report.id = 0;
        }

        return this.http.post<Report>(`${this.config.baseUrl}api/report/email`, data).pipe(
            map((report: Report) => ObjectMapper.deserialize(Report, report))
        );
    }

    public getPdf(report: ReportPostInterface): void {
        if (!report.id) {
            report.id = 0;
        }

        this.http.post<Fichier>(`${this.config.baseUrl}api/report/pdf`, report).subscribe(pdfReport => {
            this.fichierService.downloadFile(pdfReport);
        });
    }

    // Remplace les balises de mise en forme par du contenu issu de la note, du rendez-vous (dont vétérinaire, entité géographique...)
    public applyTemplate(report: Report, note: Note, rdv: RendezVous): Observable<string> {
        let content = this.handleProAreas(report);
        content = content.replace('[[purpose]]', note?.purpose ?? '')
            .replace(/\[\[history]]/g, note?.history ?? '')
            .replace(/\[\[exam]]/g, note?.exam ?? '')
            .replace(/\[\[interpretation]]/g, note?.interpretation ?? '')
            .replace(/\[\[advice]]/g, note?.advice ?? '');

        if (rdv?.animal) {
            content = this.addAnimalData(content, rdv.animal);
        }

        return this.addSiteData(content, rdv).pipe(
            map(content => {
                content = this.addDateData(content, rdv);
                content = this.addClientData(content, rdv);
                content = this.addVetData(content, rdv);
                return content;
            }
        ));
    }

    // TODO: i18n. Les données telles que l'espèce et la race sont en anglais en BDD.
    // TODO: S'assurer que la convention de nommage des sexe convient.
    private addAnimalData(input: string, animal: Animal): string {
        const espece = animal.espece?.nom ?? '';
        const race = animal.race?.nom ?? '';

        let age = '';
        if (animal.dateNaissance) {
            age = this.getAgeStringFromDateOfBirth(animal.dateNaissance);
        }

        const gender = animal.sexe ?? '';
        let sex = '';
        switch (gender) {
            case 'm':
                sex = this.translateService.instant('PET_DESC.MALE');
                break;
            case 'f':
                sex = this.translateService.instant('PET_DESC.FEMALE');
                break;
            default:
                sex = '';
        }

        // TODO: Il semblerait que la MAJ du statut stérilisé/non sétrilisé dans l'appli démo soit sans effet (reste à null). A voir.
        if (animal.sterilise) {
            switch (gender) {
                case 'm':
                    sex += ' ';
                    sex += this.translateService.instant('PET_DESC.STERILIZED_MALE');
                    break;
                case 'f':
                    sex += ' ';
                    sex += this.translateService.instant('PET_DESC.STERILIZED_FEMALE');
                    break;
                default:
                    sex = this.translateService.instant('PET_DESC.STERILIZED_MALE');
            }
        }

        // TODO: i18n. les races et espèces sont en anglais par défaut.
        return input
         .replace(/\[\[animal.name]]/g, animal.nom ?? '')
         .replace(/\[\[animal.species]]/g, espece)
         .replace(/\[\[animal.breed]]/g, race)
         .replace(/\[\[animal.sex]]/g, sex)
         .replace(/\[\[animal.age]]/g, age);
    }

    private getAgeStringFromDateOfBirth(dob: Date): string {
        let ms = Date.now().valueOf() - dob.valueOf();

        const nbrMsPerDay = 24 * 3600 * 1000;
        const nbrMsPerMonth = 30 * nbrMsPerDay;
        const nbrMsPerYear = 365 * nbrMsPerDay;

        const years = Math.floor(ms / nbrMsPerYear);
        ms %= years * nbrMsPerYear;

        const months = Math.floor(ms / nbrMsPerMonth);
        ms %= months * nbrMsPerMonth;

        const days = Math.floor(ms / nbrMsPerDay);

        let ageYears = '';
        const animalAgeYears: string = this.translateService.instant('REPORTS.REPORT_SERVICE.ANIMAL.AGE.YEARS');
        if (years > 0) {
            ageYears = years > 1 ? `${years} ${animalAgeYears}` : `${years} ${animalAgeYears}`;
        }

        let ageMonths = '';
        const animalAgeMonths: string = this.translateService.instant('REPORTS.REPORT_SERVICE.ANIMAL.AGE.MONTH');
        if (months > 0) {
            ageMonths = `${months} ${animalAgeMonths}`;
        }

        let ageDays = '';
        const animalAgeDays: string = this.translateService.instant('REPORTS.REPORT_SERVICE.ANIMAL.AGE.DAYS');
        if (years === 0 && months < 4) {
            ageDays = days > 0 ? `${days} ${animalAgeDays}` : `${days} ${animalAgeDays}`;
        }

        return (ageYears + ' ' + ageMonths + ' ' + ageDays).trim();
    }

    // Permet de faire disparaître les zones de commentaires destinées au vétérinaires de la version cliente du rapport de visio
    // Tout ce qui se trouve entre [[pro et pro]] est supprimé le cas écchéant (y compris ces délimiteurs)
    // Affiche également le label indiquant si l'exemplaire est celui du client ou du confrère.
    private handleProAreas(report: Report): string {
        let strToHandle = report?.template?.content ?? '';

        if (strToHandle !== '') {
            if (report.target === ReportTarget.Client) {
                strToHandle = this.TEAService.handleTagEnclosedAreas(strToHandle, '[[pro', 'pro]]', TagHandlingOption.Remove);
                return strToHandle.replace(/\[\[target]]/g, this.translateService.instant('REPORTS.REPORT_SERVICE.TARGET.CLIENT'));
            }

            strToHandle = this.TEAService.handleTagEnclosedAreas(strToHandle, '[[pro', 'pro]]', TagHandlingOption.Preserve);
            return strToHandle.replace(/\[\[target]]/g, this.translateService.instant('REPORTS.REPORT_SERVICE.TARGET.VET'));
        }

        return strToHandle;
    }

    private getSiteData($vetId: number): Observable<EntiteEnTeteRapportInterface> {
        return this.http.get<any>(`${this.config.baseUrl}api/report/header/${$vetId.toString()}`)
            .pipe(
                //tap(data => console.log(data)),
                map(data => {
                    const site: EntiteEnTeteRapportInterface = {
                        nom: data.nom,
                        adresse: data.adresse,
                        codePostal: data.codePostal,
                        ville: data.ville,
                        pays: data.pays,
                        telephone: data.telephone,
                        mail: data.mail,
                        openingTime: data.openingTime,
                        vets: ObjectMapper.deserializeArray(Veterinaire, data.users),
                        siret: data.entiteJuridique?.siret
                    };

                    // à défaut de disposer du nom de l'EG, on utilise celui de l'EJ
                    if (!site.nom || site.nom.trim() === '') {
                        site.nom = data.entiteJuridique.nom;
                    }

                    // à défaut de disposer de l'adresse de l'EG, on utilise cellle de l'EJ
                    if (!site.adresse || site.adresse.trim() === '' || !site.ville || site.ville.trim() === '') {
                        site.adresse = data.entiteJuridique.adresse;
                        site.codePostal = data.entiteJuridique.codePostal;
                        site.ville = data.entiteJuridique.ville;
                        site.pays = data.entiteJuridique.pays;
                    }

                    return site;
                })
                //tap(data => console.log(data)),
            );
    }

    //Remplace les balises [[site.qqch]] par leur contenu respectif, relatifs à l'entité géographique ou juridique du vétérinaire associé au rdv
    private addSiteData(input: string, rdv: RendezVous): Observable<string> {
        return this.getSiteData(rdv.veterinaire.id).pipe(
            map(
            site => {
                let temp = '';

                const nom: string = site.nom.toUpperCase();
                temp = input.replace(/\[\[site.name]]/g, nom);

                const address: string = site.adresse;
                temp = temp.replace(/\[\[site.address]]/g, address);

                const cpCity: string = (site.codePostal + ' ' + site.ville).trim();
                temp = temp.replace(/\[\[site.cp.city]]/g, cpCity);

                temp = temp.replace(/\[\[site.phone]]/g, site.telephone);
                temp = temp.replace(/\[\[site.mail]]/g, site.mail);
                temp = temp.replace(/\[\[site.siret]]/g, !site.siret || site.siret.trim() === '' ? '' : 'SIRET : ' + site.siret.trim());

                // Affichage des vétérinaires de l'entité
                // TODO: Il y a qqch à factoriser entre addVetData() et ce qui suit
                let vets = '';
                if (site.vets?.length > 0) {
                    site.vets.forEach(vet => {
                        let numOrdre = '';

                        const vetCollegeNumber: string = this.translateService.instant('REPORTS.REPORT_SERVICE.VET.COLLEGE_NUMBER');
                        if (vet.numeroOrdre) {
                            numOrdre = ` (${vetCollegeNumber} : ${vet.numeroOrdre})`;
                        }

                        vets += vet.fullName + numOrdre + '<br />';
                    });
                }

                temp = temp.replace(/\[\[vets]]/g, vets);

                // Affichage des horaires d'ouverture de l'entité
                temp = temp.replace(/\[\[openingTimes]]/g, this.openingTimesStringyfier(site.openingTime));
                return temp;
            })
        );
    }

    // Affichage des informations de date, heure et durée de consultation dans [[date]]
    private addDateData(input: string, rdv: RendezVous): string {
        let date = '';
        let duree = '';

        if (rdv.date) {
            let hour = rdv.date.getHours().toString();
            hour = hour.length < 2 ? '0' + hour : hour;
            let min = rdv.date.getMinutes().toString();
            min = min.length < 2 ? '0' + min : min;
            date = rdv.date.toLocaleDateString() + ' ' + hour + ':' + min + ' ';
        }

        if (rdv.dureeTotale) {
            duree = `(${rdv.dureeTotale} min)`;
        }

        return input.replace(/\[\[date]]/g, date).replace(/\[\[duration]]/g, duree);
    }

    // Affichage des données propres au client, dans [[client]]
    private addClientData(input: string, rdv: RendezVous): string {
        const client: Client = rdv.client;
        const patch = client ? client.fullName : '';
        return input.replace(/\[\[client]]/g, patch);
    }

    // Affichage des données du vétérinaire, dans [[vet]]
    // TODO: Il y a qqch à factoriser entre addVetData() et la fin de addSiteData()
    private addVetData(input: string, rdv: RendezVous): string {
        let numOrdre = '';
        const vet: Veterinaire = rdv.veterinaire;

        const vetCollegeNumber: string = this.translateService.instant('REPORTS.REPORT_SERVICE.VET.COLLEGE_NUMBER');
        if (rdv.veterinaire.numeroOrdre) {
            numOrdre = ` (${vetCollegeNumber} : ${rdv.veterinaire.numeroOrdre})`;
        }

        const patch = vet ? vet.fullName + numOrdre : '';
        return input.replace(/\[\[vet]]/g, patch);
    }

    // Convertion de format des dates d'ouverture de l'entité pour mise à jour de [[openiingTimes]] à la fin de addSiteData()
    // TODO: N'est-il pas prévu d'indiquer les horaires de fermeture entre 12:00 et 14:00 ?
    private openingTimesStringyfier(openingTimes: number[][]): string {
        if (!openingTimes || openingTimes.length === 0) {
            return '';
        }

        let temp = '';

        const days: string[] = [
            this.translateService.instant('CALENDAR.DAYS.1'),
            this.translateService.instant('CALENDAR.DAYS.2'),
            this.translateService.instant('CALENDAR.DAYS.3'),
            this.translateService.instant('CALENDAR.DAYS.4'),
            this.translateService.instant('CALENDAR.DAYS.5'),
            this.translateService.instant('CALENDAR.DAYS.6'),
            this.translateService.instant('CALENDAR.DAYS.0')
        ];

        const openingTimesForSunday: number [] = openingTimes.shift();
        openingTimes.push(openingTimesForSunday);

        for (const [i, data] of openingTimes.entries()) {
            temp += days[i] + ' : ';

            if (data[0] && data[1]) {
                const open: string = this.minuteToHHMM(data[0]);
                const close: string = this.minuteToHHMM(data[1]);
                temp += open + ' - ' + close;
            } else {
                temp += this.translateService.instant('REPORTS.REPORT_SERVICE.OPENING_TIMES.CLOSED');
            }

            temp += '<br />';
        }

        return temp;
    }

    // code copié depuis src/app/main/shared/form/entite-geographique-form/entite-geographique-form.component.ts
    // utilisé par openingTimesStringyfier()
    // TODO: Voir s'il est possible de factoriser
    private minuteToHHMM(value: number): string {
        const hours = Math.floor(Number(value) / 60);
        const minutes = Number(value) % 60;
        return (hours < 10 ? '0' : '') + hours.toString() + ':' + (minutes < 10 ? '0' : '') + minutes.toString();
    }
}

import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/config/navigation';
import { FuseConfig, FuseNavigationItem } from '@fuse/types';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
    fuseConfig: FuseConfig;
    navigation: FuseNavigationItem[];
    private subscription: Subscription;

    constructor(
        private readonly fuseConfigService: FuseConfigService
    ) {
        this.navigation = navigation;
    }

    ngOnInit(): void {
        this.subscription = this.fuseConfigService.config.subscribe(config => {
            this.fuseConfig = config;
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { AnimalService } from '../../../../services/api/animal.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map, tap } from 'rxjs/operators';
import { ItemHistory } from '../../../../models/interfaces/item-history';
import { ReportTarget } from '../../../../models/rendez-vous/report';
import { Animal } from '../../../../models/animal/animal';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
    animalId: number;
    animal: Animal = null;

    displayedColumns: string[] = ['rdvDate', 'vetLastName', 'noteId', 'reportTarget', 'reportUpdatedAt', 'reportSendingDate'];
    dataSource: MatTableDataSource<ItemHistory>;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    isLoadingResults = true;
    reportTarget = ReportTarget;

    constructor(private route: ActivatedRoute,
                private animalService: AnimalService,
                private rdvService: RendezVousService) {}

    ngOnInit(): void {
        this.animalId = this.route.snapshot.params.id;
        this.animalService.getById(this.animalId).subscribe((animal: Animal) => {
            this.animal = animal;
        });
        this.rdvService.getByAnimal(this.animalId)
            .pipe(
                map((items: ItemHistory[]) => items.filter(item => Date.parse(item.rdvDate.date) < Date.now())),
                tap(() => {
                    this.isLoadingResults = true;
                })
            )
            .subscribe({
                next: items => {
                    this.dataSource = new MatTableDataSource<ItemHistory>(items);
                    this.dataSource.sortingDataAccessor = (item, property) => {
                        switch (property) {
                            case 'rdvDate':
                                return item.rdvDate?.date;
                            case 'reportUpdatedAt':
                                return item.reportUpdatedAt?.date;
                            case 'reportSendingDate':
                                return item.reportSendingDate?.date;
                            default:
                                return item[property] as (string | number);
                        }
                    };

                    this.dataSource.sort = this.sort;
                    this.dataSource.paginator = this.paginator;
                },
                error: _ => {
                    console.log('err');
                },
                complete: () => {
                    this.isLoadingResults = false;
                }
            });
    }
}

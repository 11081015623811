import { JsonProperty } from 'json-object-mapper';
import { EntiteJuridique } from '../pro/entite-juridique';
import { TypeUtilisateurEnum, Utilisateur } from './utilisateur';
import { List } from '../list';
import { VeterinairePostInterface } from '../interfaces/post/utilisateurs/veterinaire-post.interface';
import { Todo } from '../todo';
import { EntiteGeographique } from '../pro/entite-geographique';

export class Veterinaire extends Utilisateur {
    @JsonProperty({ type: EntiteJuridique })
    entiteJuridique?: EntiteJuridique = null;

    @JsonProperty()
    numeroOrdre: string = null;

    @JsonProperty()
    fonction: string = null;

    @JsonProperty()
    appProAllowed: boolean = null;

    @JsonProperty()
    isProprietaire: boolean = null;

    @JsonProperty()
    isWeb: boolean = null;

    @JsonProperty({ type: Todo })
    todos: Todo[] = [];

    @JsonProperty({ type: EntiteGeographique })
    entiteGeographique: EntiteGeographique = null;

    @JsonProperty({ type: EntiteGeographique })
    entitesGeographiques: EntiteGeographique[] = [];

    @JsonProperty()
    chatSettings?: ChatSettings = null;

    newsRead: number[] = null;

    public get dashboardNameWithClinic(): string {
        return `<b>${this.fullNameLite}</b><br/>${this.clinique}`;
    }

    // utilisé par le tableau dans le dashboard. Il ne prend que de l'HTML (pas de composants Angular). Donc utilisation de <i>
    public get clientTokenUsedIcon(): string {
        return '<i class="material-icons">' + (this.isWeb ? 'computer' : 'smartphone') + '</i>';
    }

    constructor() {
        super();
        this.typeUtilisateur = TypeUtilisateurEnum.veterinaire;
        this.appProAllowed = true;
    }

    public hasPost(): VeterinairePostInterface {
        const post = super.hasPost() as VeterinairePostInterface;
        post.numeroOrdre = this.numeroOrdre;
        post.fonction = this.fonction;
        post.appProAllowed = this.appProAllowed;
        return post;
    }
}

export class ListVeterinaires extends List {
    @JsonProperty({ type: Veterinaire })
    data: Veterinaire[] = null;
}

export interface ChatSettings {
    pinned: number[];
    filters: {
        opened: boolean;
        closed: boolean;
        veterinaries: number[];
    };
}

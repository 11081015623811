import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'currencyLocale'
})
export class CurrencyLocalePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: number, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string): string {
        if (Number.isNaN(Number(value))) {
            return new CurrencyPipe(this.translateService.currentLang).transform(0, currencyCode.toUpperCase(), display, digitsInfo, this.translateService.currentLang);
        }

        return new CurrencyPipe(this.translateService.currentLang).transform(value, currencyCode.toUpperCase(), display, digitsInfo, this.translateService.currentLang);
    }
}

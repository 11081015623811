import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { IdsPostInterface } from '../../models/interfaces/post/ids-post.interface';
import { UtilisateurService } from './utilisateur.service';
import { ConfigService } from '../config.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    read = new BehaviorSubject([]);

    constructor(private http: HttpClient, private config: ConfigService, private utilisateurService: UtilisateurService) {
        this.utilisateurService.utilisateurConnected.pipe(
            filter(u => !!u),
            take(1)
        ).subscribe(veterinaire => {
            this.read.next(veterinaire.newsRead ?? []);
        });
    }

    public get notifications(): Observable<LinkyvetNotification[]> {
        return this.http.get<WPPost[]>(`${this.baseUrl}posts?lang=` + this.utilisateurService.utilisateurConnectedValue.entiteJuridique.pays).pipe(
            map(posts => posts.map(post => {
                return this.wpPostToLinkyvetNotification(post);
            }))
        );
    }

    public getNotificationDetails(id: number): Observable<LinkyvetNotification> {
        return this.http.get<WPPost>(`${this.baseUrl}posts/${id}?lang=${this.utilisateurService.utilisateurConnectedValue.entiteJuridique.pays}`).pipe(
            switchMap(post => {
                const mediaObservable = !post.featured_media || post.featured_media === 0 ? of(null) : this.http.get<WPMedia>(`${this.baseUrl}media/${post.featured_media}`);

                return mediaObservable.pipe(map(media => {
                    return { post, media };
                }));
            }),
            map(postMedia => this.wpPostToLinkyvetNotification(postMedia.post, postMedia.media))
        );
    }

    public setNotificationAsRead(id: number): void {
        const read = this.read.value as number[];
        if (id && !read.includes(id)) {
            read.push(id);
            this.read.next(read);
        }

        this.addNotificationReadToVeterinaire(this.utilisateurService.utilisateurConnectedValue, [id]).subscribe();
    }

    public setAllNotifificationAsRead(ids: number[]): void {
        const read = this.read.value as number[];
        read.push(...ids);
        const set = new Set(read);
        this.read.next([...set]);

        this.addNotificationReadToVeterinaire(this.utilisateurService.utilisateurConnectedValue, ids).subscribe();
    }

    public addNotificationReadToVeterinaire(veterinaire: Veterinaire, ids: number[]): Observable<number[]> {
        return this.http.post<number[]>(this.config.baseUrl + 'api/veterinaire/' + veterinaire.id.toString() + '/news_read', {
            ids: ids
        } as IdsPostInterface);
    }

    private get baseUrl(): string {
        return `${environment.supportUrl}wp-json/wp/v2/`;
    }

    private wpPostToLinkyvetNotification(post: WPPost, media?: WPMedia): LinkyvetNotification {
        return {
            id: post.id,
            date: new Date(post.date),
            link: post.link,
            title: post.title.rendered,
            content: this.cleanExcerpt(post.excerpt.rendered),
            image: media?.source_url,
            categories: post.categories,
            featured: post.sticky
        };
    }

    private cleanExcerpt(excerpt: string): string {
        if (!excerpt) {
            return null;
        }

        let result = excerpt;
        result = result.replace('[&hellip;]', '…');
        result = result.trim();
        const doc = new DOMParser().parseFromString(result, 'text/html');
        result = doc.documentElement.textContent;
        return result;
    }
}

export interface LinkyvetNotification {
    id: number;
    date: Date;
    link: string;
    title: string;
    content: string;
    image?: string;
    categories?: number[];
    featured?: boolean;
}

export interface WPPost {
    id: number;
    date: string;
    link: string;
    title: {
        rendered: string;
    };
    content: {
        rendered: string;
    };
    excerpt: {
        rendered: string;
    };
    categories: number[];
    featured_media: number;
    sticky: boolean;
}

export interface WPMedia {
    id: number;
    date: string;
    description: {
        rendered: string;
    };
    alt_text?: string;
    media_type: string;
    mime_type: string;
    source_url: string;
}

import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {
    public currentTimezone: string;
    public timezoneChange: EventEmitter<string> = new EventEmitter();

    public use(timezone: string): void {
        this.currentTimezone = timezone;
        this.timezoneChange.next(timezone);
    }
}

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ChatShortcut } from '../../../../models/pro/entite-juridique';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-chat-message-selection-dialog',
  templateUrl: './chat-message-selection-dialog.component.html',
  styleUrls: ['./chat-message-selection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatMessageSelectionDialogComponent {
    shortcuts: ChatShortcut[];
    selectedShortcut: ChatShortcut;

    constructor(
        public dialogRef: MatDialogRef<ChatMessageSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ChatShortcut[] | null) {
        this.shortcuts = data;
    }

    cancel(): void {
        this.dialogRef.close(null);
    }

    updateSelectedShortcut(data: ChatShortcut): void {
        this.selectedShortcut = data;
    }

    sendSelectedShortcut(): void {
        if (this.selectedShortcut) {
            const result: ChatShortcut[] = [];
            result.push(this.selectedShortcut);
            this.dialogRef.close(result);
        }
    }
}

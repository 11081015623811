import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { News } from 'app/models/news/news';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsService } from 'app/services/api/news.service';
import { NewsPostInterface } from 'app/models/interfaces/post/news-post.interface';
import { FichierService } from 'app/services/api/fichier.service';
import { SelectorType } from '../../view-utils/document-selector-dialog/document-selector-dialog.component';
import { Fichier } from 'app/models/fichier';
import { NewsSegment } from 'app/models/news/news-segment';
import { NewsSegmentsService } from 'app/services/api/news-segments.service';
import { debounceTime } from 'rxjs/operators';
import { Utils } from 'app/utils';

@Component({
    selector: 'app-news-form',
    templateUrl: './news-form.component.html',
    styleUrls: ['./news-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new BehaviorSubject<News>(null);
    @Output('formSubmited') formSubmited$ = new EventEmitter<boolean>();
    isFullscreen = false;
    form: FormGroup;
    formInProgress = false;
    minDate = new Date();
    buttonColors: string[] = ['#20405d', '#28a8de', '#ef5f27'];
    currentImage?: Fichier = null;
    base64Image: string | ArrayBuffer;

    allSegments: NewsSegment[] = [];
    segmentsMatSelectSearch = false;
    filteredSegmentsOptions$: BehaviorSubject<NewsSegment[]> = new BehaviorSubject<NewsSegment[]>(null);

    private subscriptions: Subscription[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly newsService: NewsService,
        private readonly newsSegmentsService: NewsSegmentsService,
        private readonly fichierService: FichierService
    ) {}

    ngOnInit(): void {
        this.setForm();
        this.currentImage = this.entityData$?.value?.image;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    submit(): void {
        if (this.form.invalid) {
            this.form.get('segmentsSelect').markAsDirty();
            Object.keys(this.form.controls).forEach(keyControl => {
                this.form.controls[keyControl].markAsTouched();
                this.form.controls[keyControl].updateValueAndValidity();
            });
            return;
        }

        this.formInProgress = true;
        const id = this.form.get('id').value;
        const data: NewsPostInterface = {
            name: this.form.get('name')?.value,
            title: this.form.get('title')?.value,
            content: this.form.get('content')?.value,
            publishedDate: this.form.get('publishedDate')?.value,
            callToActionText: this.form.get('buttonText')?.value,
            callToActionLink: this.form.get('buttonLink')?.value,
            callToActionColor: this.form.get('buttonColor')?.value,
            imageId: this.form.get('image')?.value ? null : this.currentImage?.id,
            imageLink: this.form.get('imageLink')?.value,
            segments: this.form.get('segments')?.value?.map((segment: NewsSegment) => segment.id)
        };

        let addOrEditService = this.newsService.add(data, this.form.get('image')?.value);
        if (id) {
            data.id = id;
            addOrEditService = this.newsService.update(data, this.form.get('image')?.value);
        }

        const addOrEditSubscription = addOrEditService.subscribe({
            next: () => {
                this.formSubmited$.emit(true);
                this.formInProgress = false;
                addOrEditSubscription?.unsubscribe();
            },
            error: error => {
                console.error(error);
                this.formInProgress = false;
                addOrEditSubscription?.unsubscribe();
            }
        });
    }

    archive(): void {
        this.formInProgress = true;
        const id = this.form.get('id').value;
        const archiveSubscription = this.newsService.archive(id).subscribe({
            next: () => {
                this.formSubmited$.emit(true);
                this.formInProgress = false;
                archiveSubscription?.unsubscribe();
            },
            error: error => {
                console.error(error);
                this.formInProgress = false;
                archiveSubscription?.unsubscribe();
            }
        });
    }

    saveDraft(): void {
        if (this.form.invalid) {
            this.form.get('segmentsSelect').markAsDirty();
            Object.keys(this.form.controls).forEach(keyControl => {
                this.form.controls[keyControl].markAsTouched();
                this.form.controls[keyControl].updateValueAndValidity();
            });
            return;
        }

        const publishedDate = this.form.get('publishedDate');
        publishedDate?.setValue(null);
        publishedDate?.updateValueAndValidity();
        this.submit();
    }

    colorUpdated(newColor: string): void {
        const buttonColor = this.form.get('buttonColor');
        buttonColor?.setValue(newColor);
        buttonColor?.updateValueAndValidity();
    }

    openImageDialog(): void {
        const fichierSubscription = this.fichierService.openFileDropDialog({
            enableCropper: true,
            forceCropper: true,
            selectorType: SelectorType.IMAGE,
            selectorMultiple: false,
            settings: {
                rounded: false,
                aspectRatio: this.isFullscreen ? 9 / 16 : 4 / 3,
                maxHeight: this.isFullscreen ? 720 : 1280,
                maxWidth: this.isFullscreen ? 1280 : 960
            }
        }).subscribe((files: File[]) => {
            if (files && files.length > 0) {
                this.currentImage = null;
                this.base64Image = null;
                const imageInput = this.form.get('image');
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.addEventListener('load', () => {
                    this.base64Image = reader.result;
                });
                reader.addEventListener('error', error => {
                    console.error(error);
                });
                imageInput?.setValue(files[0]);
                imageInput?.updateValueAndValidity();
            }

            fichierSubscription?.unsubscribe();
        });
    }

    deleteImage(event?: Event): void {
        this.base64Image = null;
        this.currentImage = null;
        const imageInput = this.form.get('image');
        imageInput?.setValue(null);
        imageInput?.updateValueAndValidity();
        event?.stopPropagation();
    }

    addSegmentToInput(segment: NewsSegment | null): void {
        const input = this.form.get('segments');
        if (segment === null) {
            input?.setValue([]);
        } else {
            const selected: NewsSegment[] = input?.value ?? [];
            if (!selected.some(element => element.id === segment.id)) {
                selected.push(segment);
            }

            input?.setValue(selected);
        }

        input?.updateValueAndValidity();
    }

    removeSegmentFromInput(segment: NewsSegment): void {
        this.form.get('segmentsSelect').markAsDirty();
        const input = this.form.get('segments');
        let selected: NewsSegment[] = input?.value ?? [];
        selected = selected.filter(object => {
            return object.id !== segment.id;
        });
        input?.setValue(selected);
        input?.updateValueAndValidity();
    }

    private arrayValidator(min = 1) {
        return (c: AbstractControl): { [key: string]: any } => {
            if (c.value.length >= min) {
                return null;
            }

            return { MinLengthArray: true };
        };
    }

    private setForm(): void {
        this.form = this.formBuilder.group({
            id: this.formBuilder.control(this.entityData$?.value?.id),
            name: this.formBuilder.control(this.entityData$?.value?.name, [Validators.required]),
            title: this.formBuilder.control(this.entityData$?.value?.title, [Validators.required]),
            content: this.formBuilder.control(this.entityData$?.value?.content, []),
            publishedType: this.formBuilder.control(0, [Validators.required]),
            publishedDate: this.formBuilder.control(this.entityData$?.value?.publishedDate ?? this.minDate, []),
            buttonText: this.formBuilder.control(this.entityData$?.value?.callToActionText),
            buttonLink: this.formBuilder.control(this.entityData$?.value?.callToActionLink, [Utils.getUrlValidator()]),
            buttonColor: this.formBuilder.control(this.entityData$?.value?.callToActionColor),
            image: this.formBuilder.control(null),
            imageLink: this.formBuilder.control(this.entityData$?.value?.imageLink),

            segmentsSelect: this.formBuilder.control(null),
            segmentsFilter: this.formBuilder.control(null),
            segments: this.formBuilder.control(this.entityData$?.value?.segments ?? [], [this.arrayValidator()])
        });

        this.setSegments();
    }

    private setSegments(): void {
        const segmentSubscription = this.newsSegmentsService.getAll().subscribe(segments => {
            this.allSegments = segments.data;
            this.filteredSegmentsOptions$.next(this.allSegments);
        });

        const segmentsFilterSubscriptions = this.form.get('segmentsFilter')?.valueChanges.pipe(debounceTime(500)).subscribe((filterValue: string) => {
            this.segmentsMatSelectSearch = true;
            if (filterValue?.trim()) {
                const segmentFilterSubscription = this.newsSegmentsService.getAll(filterValue?.trim()).subscribe(segments => {
                    this.filteredSegmentsOptions$.next(segments.data);
                    this.segmentsMatSelectSearch = false;
                    segmentFilterSubscription?.unsubscribe();
                });
            } else {
                this.filteredSegmentsOptions$.next(this.allSegments);
                this.segmentsMatSelectSearch = false;
            }
        });

        const segmentsSubscription = this.form.get('segmentsSelect')?.valueChanges.subscribe((segment?: NewsSegment | -1) => {
            if (segment && segment !== -1) {
                const segmentSelectInput = this.form.get('segmentsSelect');
                segmentSelectInput?.setValue(null);
                segmentSelectInput?.updateValueAndValidity();
                this.addSegmentToInput(segment);
            } else if (segment === -1) {
                const segmentSelectInput = this.form.get('segmentsSelect');
                segmentSelectInput?.setValue(null);
                segmentSelectInput?.updateValueAndValidity();
                this.addSegmentToInput(null);
            }
        });

        if (segmentSubscription) {
            this.subscriptions.push(segmentSubscription);
        }

        if (segmentsFilterSubscriptions) {
            this.subscriptions.push(segmentsFilterSubscriptions);
        }

        if (segmentsSubscription) {
            this.subscriptions.push(segmentsSubscription);
        }
    }
}

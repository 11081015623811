import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../services/config.service';
import { LanguageConfig } from '../../../models/interfaces/api/conf/conf.interface';

@Component({
    selector: 'app-link-apps-pro',
    templateUrl: './link-apps-pro.component.html',
    styleUrls: ['./link-apps-pro.component.scss'],
    animations: [fuseAnimations]
})
export class LinkAppsProComponent implements OnInit, OnDestroy {
    locale: string;
    localeFullUnderscore: string;
    localeFullDash: string;

    languages: LanguageConfig[] = [];

    private subscriptions: Subscription[] = [];

    constructor(private translateService: TranslateService, private configService: ConfigService) {}

    ngOnInit(): void {
        const configSubscription = this.configService.getLanguagesConfig().subscribe(languages => {
            this.languages = languages;
            this.changeLanguage();
        });

        const translateSubscription = this.translateService.onLangChange.subscribe(() => {
            this.changeLanguage();
        });

        this.subscriptions.push(configSubscription, translateSubscription);
    }

    private changeLanguage(): void {
        const userLocaleSimple = this.translateService.currentLang.toLowerCase();
        const langExactSimple = this.languages.find(l => l.iso.startsWith(userLocaleSimple));

        if (langExactSimple) {
            this.locale = userLocaleSimple;
            switch (this.locale) {
                case 'fr':
                    this.localeFullDash = 'fr-fr';
                    this.localeFullUnderscore = 'fr_fr';
                    break;
                case 'en':
                    this.localeFullDash = 'en-us';
                    this.localeFullUnderscore = 'en-us';
                    break;
                default:
                    this.localeFullDash = langExactSimple.iso.toLowerCase();
                    this.localeFullUnderscore = this.localeFullDash.replace('_', '-');
            }
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
    }
}

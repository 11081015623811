<form [formGroup]="form" (ngSubmit)="submit()">

    <div fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="16px">

        <app-chat-scenario-message-selector
            [chatShortcuts]="shortcuts"
            [selectedMessage]="selectedMessage"
            (selectShortcut)="updateForm($event)"
        ></app-chat-scenario-message-selector>

        <mat-checkbox formControlName="reopenChatIfClosed" [style.marginBottom]="'8px'" class="w-100-p">
            {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.REOPEN_IF_CLOSED_PLACEHOLDER' | translate }}
        </mat-checkbox>

        <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="w-100-p">
            <mat-form-field appearance="outline" fxFlex="55%">
                <mat-label>{{ 'SETTINGS.MESSAGES.SCENARIOS.DIALOG.MESSAGE.NB_DAY_AFTER_PLACEHOLDER' | translate }}
                </mat-label>
                <input matInput formControlName="nbDaysAfter" type="number" min="1"
                       [placeholder]="'SETTINGS.MESSAGES.SCENARIOS.DIALOG.MESSAGE.NB_DAY_AFTER_PLACEHOLDER' | translate"
                       required/>
                <span matSuffix>{{ 'SHARED.DAYS' | translate }}</span>
                <mat-error *ngIf="form.get('nbDaysAfter').hasError('required')">
                    {{ 'SHARED.REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" [class.hidden]="!form.get('reopenChatIfClosed').value" fxFlex="40%">
                <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.NUMBER_DAYS_OPEN_IF_CLOSED_PLACEHOLDER' | translate }}</mat-label>
                <input matInput type="number" min="1" step="1" formControlName="numberDaysToOpenIfClosed"
                       [style.textAlign]="'right'">
                <span matSuffix>{{ 'SHARED.DAYS' | translate }}</span>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" class="w-100-p">
            <button type="button" mat-raised-button color="primary" (click)="cancel()">
                <mat-icon>cancel</mat-icon>&nbsp;{{ 'SETTINGS.MESSAGES.SHORTCUTS.CANCEL' | translate }}
            </button>
            <button type="submit" mat-raised-button color="accent"
                    [disabled]="!form.valid || nothingSelected">
                <mat-icon>save</mat-icon>
                {{ 'FORM.SAVE' | translate }}
            </button>
        </div>

    </div>

</form>

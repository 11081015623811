import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { InformationsBancairesComponent } from './informations-bancaires.component';
import { SharedModule } from '../../../shared.module';
import { AdminGuard } from '../../../guards/admin.guard';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { PayoutsComponent } from './payouts/payouts.component';

const routes = [
    {
        path: 'bank-information',
        component: InformationsBancairesComponent,
        canActivate: [AdminGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        InformationsBancairesComponent,
        PayoutsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class InformationsBancaireModule {}

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NewsService } from 'app/services/api/news.service';
import { News } from 'app/models/news/news';

@Component({
    selector: 'app-news-dialog',
    templateUrl: './news-dialog.component.html',
    styleUrls: ['./news-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NewsDialogComponent implements OnInit, OnDestroy {
    data$ = new BehaviorSubject<News>(null);
    loading$ = new BehaviorSubject<boolean>(true);

    private subscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<NewsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: NewsDialogInterface,
        private readonly newsService: NewsService
    ) {}

    ngOnInit(): void {
        if (this.data.news?.id) {
            this.subscription = this.newsService.get(this.data.news.id).subscribe({
                next: news => {
                    if (this.data.duplicate) {
                        news.id = null;
                        news.status = null;
                        news.publishedDate = null;
                        news.archivedDate = null;
                        news.createdAt = null;
                        news.nbView = null;
                        news.nbClick = null;
                    }

                    this.data$.next(news);
                    this.loading$.next(false);
                },
                error: error => {
                    console.error(error);
                    this.loading$.next(false);
                }
            });
        } else {
            this.loading$.next(false);
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    close(hasChanged = false): void {
        this.dialogRef.close(hasChanged);
    }
}

export interface NewsDialogInterface {
    news: News;
    duplicate: boolean;
}

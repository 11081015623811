import { Component, Input, ViewChild } from '@angular/core';
import { Chat } from '../../../../../models/animal/chat';
import { Client } from '../../../../../models/utilisateurs/client';
import { Animal } from '../../../../../models/animal/animal';
import { ChatService } from '../../../../../services/api/chat.service';
import { UtilisateurService } from '../../../../../services/api/utilisateur.service';
import { AnimalService } from '../../../../../services/api/animal.service';
import { ColorPickerComponent } from '../../../../shared/view-utils/color-picker/color-picker.component';
import { UserPreferenciesService } from '../../../../../services/user-preferencies.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-chat-list-tile',
    templateUrl: './chat-list-tile.component.html',
    styleUrls: ['./chat-list-tile.component.scss']
})
export class ChatListTileComponent {
    @Input() chat: Chat;
    @Input() client: Client;
    @Input() animal: Animal;
    @Input() animalIndex: number;
    @Input() active: boolean;
    @Input() isMine: boolean;

    @ViewChild('colorPicker') colorPicker: ColorPickerComponent;
    @ViewChild(MatMenuTrigger) private menuTriggers: MatMenuTrigger;

    constructor(private chatService: ChatService, private utilisateurService: UtilisateurService,
        private animalService: AnimalService, public userPreferenciesService: UserPreferenciesService) {}

    openClient(): void {
        this.utilisateurService.openDialogClient(this.client).afterClosed().subscribe((result: Client) => {
            if (result) {
                this.client = result;
            }
        });
        this.menuTriggers.closeMenu();
    }

    openAnimal(): void {
        this.animalService.openDialogAnimal(this.animal).afterClosed().subscribe((result: Animal) => {
            if (result) {
                this.animal = result;
                if (!this.client.animaux) {
                    this.client.animaux = [];
                }

                this.client.animaux[this.animalIndex] = result;
            }
        });
        this.menuTriggers.closeMenu();
    }

    onColorUpdated(color: string): void {
        const oldColor = this.chat.color;
        this.chatService.updateChatColor(this.chat.id, color).subscribe(() => {
            this.colorPicker.color = oldColor;
        });
    }

    changeStatusChat(status: boolean): void {
        this.chatService.changeStatusChat(this.chat.id, status).subscribe((chat: Chat) => {
            this.chat = chat;
        });
        this.menuTriggers.closeMenu();
    }

    isPinned(): boolean {
        return this.utilisateurService.utilisateurConnectedValue?.chatSettings?.pinned?.includes(this.chat.id) ?? false;
    }

    togglePinChat(): void {
        this.chatService.updateChatPinned(this.chat.id, !this.isPinned()).subscribe();
        this.menuTriggers.closeMenu();
    }
}

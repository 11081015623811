<h2 mat-dialog-title>{{ 'REPORTS.DIALOGS.EMAIL.MAIN_TITLE' | translate }}</h2>
<form [formGroup]="form" autocomplete="off" novalidate (ngSubmit)="sendEmailData()">
    <mat-form-field class="w-100-p" appearance="outline">
        <mat-label>{{ 'REPORTS.DIALOGS.EMAIL.CONTENT' | translate }}</mat-label>
        <textarea matInput formControlName="content" rows="7" required></textarea>
        <mat-error *ngIf="form.get('content').hasError('required')">
            {{ 'REPORTS.DIALOGS.EMAIL.CONTENT_ERROR' | translate }}
        </mat-error>
    </mat-form-field>

    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px" class="mb-8">
        <button mat-raised-button mat-dialog-close>{{ 'REPORTS.DIALOGS.EMAIL.CANCEL_BUTTON' | translate }}</button>
        <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">{{ 'REPORTS.DIALOGS.EMAIL.SUBMIT_BUTTON' | translate }}</button>
    </div>
</form>

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'app-step-clinic',
    templateUrl: './step-clinic.component.html',
    styleUrls: ['./step-clinic.component.scss']
})
export class StepClinicComponent {
    @Input() control: AbstractControl;
}

<div fxFlex fxLayout="column" fxLayoutAlign="center center">

    <img src="/assets/images/empty-state-chat.svg"/>

    <span [class.visio]="visio" class="app-title my-16">{{ ((hasChats$ | async) ? 'CHAT.NO_CHAT' : 'CHAT.NO_CHAT_AT_ALL') | translate }}</span>

    <span class="app-message">{{ ((hasChats$ | async) ? 'CHAT.SELECT_ONE' : 'CHAT.CREATE_ONE') | translate }}</span>

    <button mat-raised-button color="primary" class="btn" (click)="addChat()">
        <mat-icon>add</mat-icon>
        {{ 'CHAT.NEW' | translate }}
    </button>

    <button mat-raised-button color="primary" class="btn" fxHide.gt-md fuseMatSidenavToggler="chat-left-sidenav">
        <mat-icon>chat</mat-icon>
        {{ 'CHAT.SELECT' | translate }}
    </button>

</div>

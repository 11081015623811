import { Component, ContentChildren, Directive, ElementRef, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Todo } from 'app/models/todo';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { TodoService } from '../../../services/api/todo.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { animate, AnimationBuilder, style } from '@angular/animations';

@Component({
    selector: 'app-list-todos',
    templateUrl: './list-todos.component.html',
    styleUrls: ['./list-todos.component.scss']
})
export class ListTodosComponent implements OnInit, OnDestroy {
    @Input() type: string;

    isLoading = true;
    todos: BehaviorSubject<Todo[]> = new BehaviorSubject<Todo[]>([]);
    todoElements: BehaviorSubject<TodoElement[]> = new BehaviorSubject<TodoElement[]>([]);

    form: FormGroup;
    private subscriptions: Subscription[] = [];

    constructor(private utilisateurService: UtilisateurService, private todoService: TodoService) {
        this.form = new FormGroup({
            description: new FormControl(null, [Validators.required]),
            date: new FormControl(null, [Validators.required])
        });

        this.subscriptions.push(
            this.todos.subscribe((todos: Todo[]) => {
                const newList = todos.filter(item => {
                    if (this.type === 'mine') {
                        return Boolean(item.veterinaire);
                    } else if (this.type === 'all') {
                        return Boolean(item.entiteJuridique);
                    }

                    return true;
                })
                    .sort((a: Todo, b: Todo) => {
                        if (a.checked === b.checked) {
                            return a.date.getTime() - b.date.getTime();
                        }

                        return a.checked ? 1 : -1;
                    })
                    .map((t: Todo) => {
                        const element: TodoElement = {
                            type: 'todo',
                            element: t
                        };
                        return element;
                    });

                let indexSeparator = newList.findIndex((t: TodoElement) => t.element.checked);
                if (indexSeparator <= -1) {
                    indexSeparator = newList.length;
                }

                newList.splice(indexSeparator, 0, {
                    type: 'past-separator'
                } as TodoElement);

                if (indexSeparator === 0) {
                    // Current todo empty
                    newList.splice(0, 0, {
                        type: 'empty-state'
                    } as TodoElement);
                }

                if (newList[newList.length - 1].type === 'past-separator') {
                    // Past todo empty
                    newList.push({
                        type: 'empty-state'
                    } as TodoElement);
                }

                this.todoElements.next(newList);
            })
        );
    }

    ngOnInit(): void {
        this.loadTodos();
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((s: Subscription) => {
                s.unsubscribe();
            });
        }
    }

    private loadTodos() {
        this.todoService.getAll().subscribe((list: Todo[]) => {
            this.todos.next(list);
            this.isLoading = false;
        });
    }

    addTodo(): void {
        if (!this.form.valid) {
            return;
        }

        const data = this.form.getRawValue();

        const todo = new Todo();
        todo.description = data.description;
        todo.date = data.date;
        if (this.type === 'mine') {
            todo.veterinaire = this.utilisateurService.utilisateurConnectedValue;
        } else {
            todo.entiteJuridique = this.utilisateurService.utilisateurConnectedValue.entiteJuridique;
        }

        this.todoService.addTodo(todo.hasPost()).subscribe((addedTodo: Todo) => {
            this.form.reset();

            const newTodos = this.todos.value;
            newTodos.push(addedTodo);
            this.todos.next(newTodos);
        });
    }

    deleteTodo(todo: Todo): void {
        this.todoService.deleteTodo(todo.id).subscribe(() => {
            const newTodos = this.todos.value;
            const index = newTodos.indexOf(todo);
            if (index > -1) {
                newTodos.splice(index, 1);
                this.todos.next(newTodos);
            }
        });
    }

    onTodoCheckedChanged(todo: Todo, checked: boolean): void {
        const oldChecked = todo.checked;
        todo.checked = checked;
        this.todos.next(this.todos.value);
        this.todoService.updateTodoChecked(todo.id, checked).subscribe(() => {
            todo.checked = oldChecked;
        });
    }
}

export interface TodoElement {
    type: 'todo' | 'past-separator' | 'empty-state';
    element?: Todo;
}

@Directive({
    selector: '[transitionGroupTodo]'
})
export class TransitionGroupTodoDirective {
    @Input() id: number;
    prevPos: DOMRect;
    newPos: DOMRect;
    el: HTMLElement;
    moveCallback: () => void;

    constructor(elRef: ElementRef) {
        this.el = elRef.nativeElement;
    }
}

@Component({
    selector: '[transitionGroupTodos]',
    template: '<ng-content></ng-content>'
})
export class TransitionGroupTodosComponent {
    @Input('transitionGroupTodos') class;

    private oldItems: TransitionGroupTodoDirective[];
    @ContentChildren(TransitionGroupTodoDirective) items: QueryList<TransitionGroupTodoDirective>;

    constructor(private animationBuilder: AnimationBuilder) {}

    ngAfterContentInit(): void {
        this.oldItems = [...this.items.toArray()];
        this.oldItems.forEach(item => {
            item.prevPos = item.el.getBoundingClientRect();
            item.newPos = item.el.getBoundingClientRect();
        });

        this.items.changes.subscribe((items: QueryList<TransitionGroupTodoDirective>) => {
            items.forEach(item => {
                if (this.oldItems && this.oldItems.length > 0) {
                    const oldItem = this.oldItems.find((i: TransitionGroupTodoDirective) => Boolean(i.id) && i.id === item.id);
                    if (oldItem) {
                        item.prevPos = oldItem.newPos;
                    }
                }

                item.newPos = item.el.getBoundingClientRect();
            });

            this.oldItems = [...items.toArray()];

            items.forEach(item => TransitionGroupTodosComponent.runCallback(item));
            items.forEach((item: TransitionGroupTodoDirective) => {
                if (!item.prevPos) {
                    return;
                }

                const dy = item.prevPos.top - item.newPos.top;
                if (dy) {
                    const animation = this.animationBuilder.build([
                        style({ transform: 'translateY(' + dy.toString() + 'px)' }),
                        animate(100, style({ transform: 'translateY(0px)' }))
                    ]);

                    const player = animation.create(item.el);
                    player.play();
                }
            });
        });
    }

    private static runCallback(item: TransitionGroupTodoDirective): void {
        if (item.moveCallback) {
            item.moveCallback();
        }
    }
}

import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilisateurPostInterface } from '../../../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { TypeUtilisateurEnum, Utilisateur } from '../../../../models/utilisateurs/utilisateur';
import { ChatService } from '../../../../services/api/chat.service';
import { Client } from '../../../../models/utilisateurs/client';
import { TranslateService } from '@ngx-translate/core';
import { phoneNumberValidator } from '../inputs/input-phone/input-phone.component';
import { ConnecteurService } from '../../../../services/api/connecteur.service';
import { ConfigService } from '../../../../services/config.service';
import { EntiteGeographique } from '../../../../models/pro/entite-geographique';
import { EntiteGeographiqueService } from '../../../../services/api/entite-geographique.service';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';

@Component({
    selector: 'app-user-form',
    templateUrl: './user-form.component.html',
    styleUrls: ['./user-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new BehaviorSubject<UtilisateurPostInterface>(null);
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();
    @Input('photoDelInProgress') photoDelInProgress$ = new Subject<boolean>();
    @Input('photoInProgress') photoInProgress$ = new Subject<boolean>();

    @Input() enablePhoto = true;
    @Input() canBeDeleted = false;
    @Input() enablePhotoForm = true;
    @Input() handleForm = true;
    @Input() showAllField = true;
    @Input() disabled = false;
    @Input() country: string;
    @Input() userNeverConnected = false;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<UtilisateurPostInterface>();
    @Output('photoSubmited') photoSubmited$ = new EventEmitter<Blob>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<Utilisateur>();
    @Output('createAnimal') createAnimal$ = new EventEmitter<boolean>();

    imageEvent$ = new Subject<Blob>();
    connectorIdRequired = false;
    form: FormGroup;
    hidePassword = true;
    hidePasswordConfirm = true;
    isMe = false;
    entityLinked = false;
    phoneRefresh$ = new Subject<string>();

    entitesGeographiques$: Observable<EntiteGeographique[]>;

    private forceAddBecauseImport: boolean;
    private subscriptionEntityData: Subscription;

    constructor(
        public utilisateurService: UtilisateurService,
        private chatService: ChatService,
        public translateService: TranslateService,
        private cdRef: ChangeDetectorRef,
        public connecteurService: ConnecteurService,
        public configService: ConfigService,
        private entiteGeographiqueService: EntiteGeographiqueService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {
        this.form = new FormGroup({
            id: new FormControl(),
            civilite: new FormControl(null),
            nom: new FormControl(null, [Validators.required]),
            prenom: new FormControl(null),
            password: new FormControl(null),
            passwordConfirm: new FormControl(null),
            mail: new FormControl(null),
            telephonePortable: new FormControl(null, [phoneNumberValidator]),
            locale: new FormControl(translateService.getBrowserCultureLang().replace('-', '_')),
            timezone: new FormControl(Intl.DateTimeFormat().resolvedOptions().timeZone),
            enable: new FormControl(true),
            typeUtilisateur: new FormControl(null, [Validators.required]),
            connectorId: new FormControl(),
            entiteGeographique: new FormControl(null, [Validators.required])
        });

        this.form.get('id').disable();
        this.form.get('passwordConfirm').disable();

        this.form.get('password').valueChanges.subscribe(value => {
            const passwordControl = this.form.get('password');
            const passwordConfirmControl = this.form.get('passwordConfirm');

            if (value?.length) {
                this.form.setValidators([UserFormComponent.passwordConfirm]);
                passwordControl.setValidators(Validators.required);
                passwordConfirmControl.enable();
                passwordConfirmControl.setValidators(Validators.required);
            } else {
                this.form.clearValidators();

                if (this.form.get('id').value) {
                    passwordControl.clearValidators();
                }

                passwordConfirmControl.clearValidators();
                passwordConfirmControl.setValue(null);
                passwordConfirmControl.disable();
            }

            this.form.updateValueAndValidity({ emitEvent: false });
            passwordControl.updateValueAndValidity({ emitEvent: false });
            passwordConfirmControl.updateValueAndValidity({ emitEvent: false });
        });

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });
    }

    ngOnInit(): void {
        this.entitesGeographiques$ = this.entiteGeographiqueService.getAllEntitesGeographiques().pipe(
            map(egs => egs.sort((a, b) => a.nom < b.nom ? -1 : 1))
        );

        if (this.disabled) {
            this.form.disable();
            this.enablePhotoForm = false;
        }

        if (!this.showAllField) {
            this.form.controls.password.disable();
            this.form.controls.passwordConfirm.disable();
            // this.form.controls.mail.disable();
            this.form.controls.enable.disable();
            this.form.controls.connectorId.disable();
        }

        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe((entityData: UtilisateurPostInterface) => {
            if (entityData.id) {
                this.form.controls.id.enable();
            } else {
                this.form.controls.id.disable();
            }

            this.form.patchValue({
                id: entityData.id,
                civilite: entityData.civilite,
                nom: entityData.nom,
                prenom: entityData.prenom,
                password: null,
                passwordConfirm: null,
                mail: entityData.mail === '' ? null : entityData.mail,
                telephonePortable: entityData.telephonePortable,
                locale: entityData.locale,
                timezone: entityData.timezone,
                enable: entityData.enable,
                typeUtilisateur: entityData.typeUtilisateur,
                connectorId: entityData.connectorId,
                entiteGeographique: entityData.entiteGeographique
            }, { emitEvent: false });

            if (entityData.typeUtilisateur === TypeUtilisateurEnum.client) {
                this.form.get('telephonePortable').setValidators([Validators.required, phoneNumberValidator]);
            } else if (!entityData.id) {
                this.form.get('password').setValidators(Validators.required);
            } else {
                this.form.get('telephonePortable').clearValidators();
                this.form.get('telephonePortable').setErrors(null);
                this.form.get('password').clearValidators();
                this.form.get('password').setErrors(null);
            }

            this.isMe = this.utilisateurService.utilisateurConnectedValue.id === entityData.id;

            if (this.utilisateurService.utilisateurConnectedValue.id === entityData.id &&
                this.utilisateurService.utilisateurConnectedValue.isProprietaire &&
                this.connecteurService.isConnecteurEnabledForUtilisateurConnectedValue
            ) {
                this.connectorIdRequired = true;
                this.form.get('connectorId').setValidators(Validators.required);
            } else {
                this.connectorIdRequired = false;
                this.form.get('connectorId').clearValidators();
                this.form.get('connectorId').setErrors(null);
            }

            this.form.get('connectorId').clearValidators();

            this.entityLinked = entityData.id && Boolean(this.form.get('connectorId').value);
        });

        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid && this.handleForm) {
            this.save(this.form.getRawValue());
        } else {
            Object.keys(this.form.controls).forEach(keyControl => {
                this.form.controls[keyControl].markAsTouched();
            });
        }
    }

    delete(): void {
        if (!this.disabled && this.canBeDeleted && this.form.get('id').value) {
            this.formInProgress$.next(true);
            if (this.entityData$.value.typeUtilisateur === TypeUtilisateurEnum.client) {
                this.utilisateurService.deleteUtilisateur(this.form.get('id').value).subscribe({
                    next: () => {
                        this.entityReceived$.next(null);
                        this.formSubmited$.next(null);
                    },
                    complete: () => this.formInProgress$.next(false)
                });
            } else {
                this.utilisateurService.deleteVeterinary(this.form.get('id').value, this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id).subscribe({
                    next: () => {
                        this.entityReceived$.next(null);
                        this.formSubmited$.next(null);
                    },
                    complete: () => this.formInProgress$.next(false)
                });
            }
        }
    }

    private save(data: UtilisateurPostInterface) {
        this.formInProgress$.next(true);

        if (data.id && !this.forceAddBecauseImport) {
            this.utilisateurService.updateUtilisateur(data).subscribe({
                next: user => {
                    if (user instanceof Client) {
                        this.chatService.updateAnimalProprietaireOnChat(user);
                    }

                    this.formInProgress$.next(false);
                    this.entityReceived$.next(user);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(user.hasPost());
                    }

                    this.formSubmited$.next(data);
                },
                error: () => {
                    this.formInProgress$.next(false);
                    this.createAnimal$.next(false);
                }
            });
        } else {
            this.utilisateurService.addUtilisateur(data).subscribe({
                next: user => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(user);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(user.hasPost());
                    }

                    this.formSubmited$.next(data);
                },
                error: () => {
                    this.formInProgress$.next(false);
                    this.createAnimal$.next(false);
                }
            });
        }
    }

    submitPhoto(image: Blob): void {
        this.photoSubmited$.next(image);
        if (this.handleForm) {
            this.savePhoto(image);
        }
    }

    resendNotificationAccountCreated(): void {
        this.formInProgress$.next(true);
        this.utilisateurService.resendNotificationAccountCreated(this.form.get('id').value, this.form.get('typeUtilisateur').value)
            .subscribe({ complete: () => this.formInProgress$.next(false) });
    }

    private savePhoto(image: Blob) {
        if (image) {
            this.photoInProgress$.next(true);
            this.utilisateurService.setPhoto(this.form.get('id').value, image).subscribe({
                next: user => {
                    if (user instanceof Client) {
                        this.chatService.updateAnimalProprietaireOnChat(user);
                    }

                    this.entityReceived$.next(user);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(user.hasPost());
                    }

                    this.photoInProgress$.next(false);
                    this.imageEvent$.next(null);
                },
                error: () => this.photoInProgress$.next(false)
            });
        } else {
            this.photoDelInProgress$.next(true);
            this.utilisateurService.setPhoto(this.form.get('id').value, image).subscribe({
                next: user => {
                    if (user instanceof Client) {
                        this.chatService.updateAnimalProprietaireOnChat(user);
                    }

                    this.entityReceived$.next(user);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(user.hasPost());
                    }

                    this.photoDelInProgress$.next(false);
                    this.imageEvent$.next(null);
                },
                error: () => this.photoDelInProgress$.next(false)
            });
        }
    }

    private static passwordConfirm(control: AbstractControl) {
        const passwordControl = control.get('password');
        const passwordConfirmControl = control.get('passwordConfirm');
        if (passwordConfirmControl.value === passwordControl.value) {
            passwordControl.setErrors(null);
            passwordConfirmControl.setErrors(null);
            return null;
        }

        passwordControl.setErrors({ passwordConfirm: true });
        passwordConfirmControl.setErrors({ passwordConfirm: true });
    }
}

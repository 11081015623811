import { OnboardingProPlansComponent } from './onboarding-pro-plans.component';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { SharedModule } from '../../shared.module';
import { ViewSharedModule } from '../shared/view-shared.module';
import { FormSharedModule } from '../shared/form/form-shared.module';
import { NgModule } from '@angular/core';

const routes = [
    {
        path: 'onboarding-pro-plans',
        component: OnboardingProPlansComponent
    }
];

@NgModule({
    declarations: [
        OnboardingProPlansComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        FormSharedModule
    ]
})
export class OnboardingProPlansModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateLocale',
    pure: false
})
export class DateLocalePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: Date | number | string, pattern = 'mediumDate'): string | null {
        const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
        return datePipe.transform(value, pattern, null, this.translateService.currentLang);
    }
}

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { ClientsDataSource } from '../../../datasources/clients.data-source';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { Client } from '../../../models/utilisateurs/client';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Animal } from '../../../models/animal/animal';
import { AnimalService } from '../../../services/api/animal.service';
import { ConnecteurService } from '../../../services/api/connecteur.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { CdkRow } from '@angular/cdk/table';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
    animations: [...fuseAnimations,
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])]
})
export class ClientsComponent implements OnInit, AfterViewInit, OnDestroy {
    dataSource?: ClientsDataSource;
    displayedColumns = ['fullName', 'telephonePortable', 'nextRdv', 'lastRdv', 'lastConnection', 'nbrAnimaux', 'actions'];
    expandedElement: Client;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    isExpansionDetailRow = (_i: number, row: CdkRow): boolean => Object.prototype.hasOwnProperty.call(row, 'detailRow') as boolean;

    constructor(private utilisateurService: UtilisateurService, public connecteurService: ConnecteurService,
        private animalService: AnimalService,
        private snackbar: MatSnackBar,
        private translateService: TranslateService) {}

    ngOnInit(): void {
        this.dataSource = new ClientsDataSource(this.utilisateurService);
        this.dataSource.loadClients(null, 'asc', 'nom', 0, 20);
    }

    ngOnDestroy(): void {
        this.dataSource.disconnect();
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadClients();
        });

        this.paginator.page.subscribe((event: PageEvent) => {
            if (event.previousPageIndex === event.pageIndex) {
                this.paginator.pageIndex = 0;
            }

            this.loadClients();
        });

        fromEvent(this.filter.nativeElement, 'keyup').pipe(
            distinctUntilChanged(),
            debounceTime(150)
        ).subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadClients();
        });
    }

    loadClients(): void {
        this.dataSource.loadClients(
            this.filter.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    openClient(client: Client = null): void {
        const isNew = client === null;

        if (!client) {
            client = new Client();
            client.entiteGeographique = this.utilisateurService.utilisateurConnectedValue.entiteGeographique;
            client.locale = this.utilisateurService.utilisateurConnectedValue.locale;
            client.timezone = this.utilisateurService.utilisateurConnectedValue.timezone;
        }

        this.utilisateurService.openDialogClient(client).afterClosed().subscribe((result: Client) => {
            if (result) {
                if (isNew) {
                    this.dataSource.add(result);
                } else {
                    this.dataSource.replace(result);
                }

                if (this.expandedElement && this.expandedElement.id === result.id) {
                    this.expandedElement = result;
                }
            } else if (result === null) {
                this.dataSource.delete(client);
                this.expandedElement = null;
            }
        });
    }

    showAnimaux(client: Client): void {
        this.expandedElement = null;
        if (client.animaux.length > 0) {
            this.expandedElement = client;
        }
    }

    addAnimal(client: Client): void {
        const animal = new Animal();
        animal.proprietaire = client;

        this.animalService.openDialogAnimal(animal).afterClosed().subscribe((result: Animal) => {
            if (result) {
                client.animaux.push(result);
                this.dataSource.replace(client);
                this.expandedElement = client;
            }
        });
    }

    resendNotificationAccountCreated(client: Client): void {
        this.utilisateurService.resendNotificationAccountCreated(client.id, 'client').subscribe();
    }

    removeClient(client: Client): void {
        this.utilisateurService.deleteUtilisateur(client.id).subscribe(() => {
            if (this.expandedElement && this.expandedElement.id === client.id) {
                this.expandedElement = null;
            }

            this.dataSource.delete(client);
        });
    }

    public get signUpLink(): Observable<string> {
        return this.utilisateurService.utilisateurConnected.pipe(
            map(u => !EntiteJuridique.isScoldedFromOnboardingClient(u.entiteJuridique.id) && u.entiteJuridique.clientSignupCode ? environment.signUpClientUrl + u.entiteJuridique.clientSignupCode : null)
        );
    }

    copyLinkToClipboard(): void {
        this.signUpLink.subscribe(link => {
            const selBox = document.createElement('textarea');
            selBox.style.position = 'fixed';
            selBox.style.left = '0';
            selBox.style.top = '0';
            selBox.style.opacity = '0';
            selBox.value = link;
            document.body.append(selBox);
            selBox.focus();
            selBox.select();
            document.execCommand('copy');
            selBox.remove();

            this.snackbar.open(this.translateService.instant('CLIENT.SIGNUP_LINK.SNACKBAR_MESSAGE'), null, { duration: 3000 });
        });
    }

    trackByFn(_index: number, item: Client): number {
        return item.id;
    }
}

import { Component } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'app-browser-not-supported',
    templateUrl: './browser-not-supported.component.html',
    styleUrls: ['./browser-not-supported.component.scss']
})
export class BrowserNotSupportedComponent {
    constructor(private fuseConfigService: FuseConfigService) {
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                }
            }
        };
    }
}

<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()" autocomplete="off">
    <mat-form-field appearance="outline" fxFlex="1 0 0">
        <mat-label>{{ 'NEWS_SEGMENTS.FORM.INPUTS.NAME' | translate }}</mat-label>
        <input matInput formControlName="name" [placeholder]="'NEWS_SEGMENTS.FORM.INPUTS.NAME' | translate">
        <mat-error *ngIf="form.get('name')?.hasError('required')">
            {{ 'NEWS_SEGMENTS.FORM.ERRORS.NAME_REQUIRED' | translate }}
        </mat-error>
    </mat-form-field>

    <p class="f-bold mt-0">{{ 'NEWS_SEGMENTS.FORM.INPUTS.FILTERS' | translate}}</p>

    <mat-form-field appearance="outline" fxFlex="1 0 0" *ngIf="!(form.get('clinic').value?.length > 0)">
        <mat-label>{{ 'NEWS_SEGMENTS.FORM.INPUTS.CLINIC' | translate }}</mat-label>
        <mat-chip-list #chipListClinic>
            <mat-chip
                *ngIf="!(form.get('clinic').value?.length > 0)"
                [selectable]="false"
                [removable]="false">
                {{ 'NEWS_SEGMENTS.FORM.INPUTS.CLINIC_ALL' | translate }}
            </mat-chip>
            <mat-chip
                *ngFor="let clinic of form.get('clinic').value"
                [selectable]="true"
                [removable]="true"
                (removed)="removeFromInput(clinic, 'clinic')">
                {{ clinic?.nom }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-select #clinicSelect formControlName="clinicSelect">
                <mat-option>
                    <ngx-mat-select-search
                        formControlName="clinicFilter"
                        [placeholderLabel]="'NEWS_SEGMENTS.FORM.INPUTS.CLINIC_SEARCH' | translate"
                        [noEntriesFoundLabel]="'NEWS_SEGMENTS.FORM.ERRORS.CLINIC_EMPTY' | translate"
                        [searching]="clinicsMatSelectSearch">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!(filteredClinicsOptions$ | async)">{{ 'SHARED.LOADING' | translate }}</mat-option>
                <mat-option *ngIf="(filteredClinicsOptions$ | async) && !this.form.get('clinicFilter')?.value" [value]="-1">
                    {{ 'NEWS_SEGMENTS.FORM.INPUTS.CLINIC_ALL' | translate }}
                </mat-option>
                <mat-option *ngFor="let clinic of (filteredClinicsOptions$ | async)" [value]="clinic">
                    {{ clinic.nom  }}
                </mat-option>
            </mat-select>
        </mat-chip-list>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'NEWS_SEGMENTS.FORM.INPUTS.SPECIES' | translate }}</mat-label>
            <mat-chip-list #chipListClinic>
                <mat-chip
                    *ngIf="!(form.get('species').value?.length > 0)"
                    [selectable]="false"
                    [removable]="false">
                    {{ 'NEWS_SEGMENTS.FORM.INPUTS.SPECIES_ALL' | translate }}
                </mat-chip>
                <mat-chip
                    *ngFor="let espece of form.get('species').value"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeFromInput(espece, 'species')">
                    {{ espece?.nom }}<mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>

                <mat-select #speciesSelect formControlName="speciesSelect">
                    <mat-option>
                        <ngx-mat-select-search
                            formControlName="speciesFilter"
                            [placeholderLabel]="'NEWS_SEGMENTS.FORM.INPUTS.SPECIES_SEARCH' | translate"
                            [noEntriesFoundLabel]="'NEWS_SEGMENTS.FORM.ERRORS.SPECIES_EMPTY' | translate"
                            [searching]="speciesMatSelectSearch">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!(filteredSpeciesOptions$ | async)">{{ 'SHARED.LOADING' | translate }}</mat-option>
                    <mat-option *ngIf="(filteredSpeciesOptions$ | async) && !this.form.get('speciesFilter')?.value" [value]="-1">
                        {{ 'NEWS_SEGMENTS.FORM.INPUTS.SPECIES_ALL' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let espece of (filteredSpeciesOptions$ | async)" [value]="espece">
                        {{ espece.nom  }}
                    </mat-option>
                </mat-select>
            </mat-chip-list>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'NEWS_SEGMENTS.FORM.INPUTS.RACE' | translate }}</mat-label>
            <mat-chip-list #chipListRace>
                <mat-chip
                    *ngIf="!(form.get('race').value?.length > 0)"
                    [selectable]="false"
                    [removable]="false">
                    {{ 'NEWS_SEGMENTS.FORM.INPUTS.RACE_ALL' | translate }}
                </mat-chip>
                <mat-chip
                    *ngFor="let race of form.get('race').value"
                    [selectable]="true"
                    [removable]="true"
                    (removed)="removeFromInput(race, 'race')">
                    {{ race?.nom }}<mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>

                <mat-select #raceSelect formControlName="raceSelect">
                    <mat-option>
                        <ngx-mat-select-search
                            formControlName="raceFilter"
                            [placeholderLabel]="'NEWS_SEGMENTS.FORM.INPUTS.RACE_SEARCH' | translate"
                            [noEntriesFoundLabel]="'NEWS_SEGMENTS.FORM.ERRORS.RACE_EMPTY' | translate"
                            [searching]="racesMatSelectSearch">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!(filteredRacesOptions$ | async)">{{ 'SHARED.LOADING' | translate }}</mat-option>
                    <mat-option *ngIf="(filteredRacesOptions$ | async) && !this.form.get('raceFilter')?.value" [value]="-1">
                        {{ 'NEWS_SEGMENTS.FORM.INPUTS.RACE_ALL' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let race of (filteredRacesOptions$ | async)" [value]="race">
                        {{ race.nom  }}
                    </mat-option>
                </mat-select>
            </mat-chip-list>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px" class="mb-16">
        <div fxFlex="1 0 0">
            <p id="gender-label" class="label mt-0">{{ 'NEWS_SEGMENTS.FORM.INPUTS.GENDER' | translate }}</p>
            <mat-radio-group formControlName="gender" aria-labelledby="gender-label">
                <mat-radio-button class="mr-16" value="m">{{ 'NEWS_SEGMENTS.FORM.INPUTS.GENDER_MALE' | translate }}</mat-radio-button>
                <mat-radio-button class="mr-16" value="f">{{ 'NEWS_SEGMENTS.FORM.INPUTS.GENDER_FEMALE' | translate }}</mat-radio-button>
                <mat-radio-button [value]="null">{{ 'NEWS_SEGMENTS.FORM.INPUTS.GENDER_ALL' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
        <div fxFlex="1 0 0">
            <p id="sterilization-label" class="label mt-0">{{ 'NEWS_SEGMENTS.FORM.INPUTS.STERILIZATION' | translate }}</p>
            <mat-radio-group formControlName="sterilization" aria-labelledby="sterilization-label">
                <mat-radio-button class="mr-16" [value]="true">{{ 'NEWS_SEGMENTS.FORM.INPUTS.STERILIZATION_YES' | translate }}</mat-radio-button>
                <mat-radio-button class="mr-16" [value]="false">{{ 'NEWS_SEGMENTS.FORM.INPUTS.STERILIZATION_NO' | translate }}</mat-radio-button>
                <mat-radio-button [value]="null">{{ 'NEWS_SEGMENTS.FORM.INPUTS.STERILIZATION_ALL' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <p class="label">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE' | translate }}</p>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" fxFlex="1 0 0">
        <mat-radio-group formControlName="ageOperator" class="mb-16">
            <mat-radio-button class="mr-16" value="greater">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE_SUP' | translate }}</mat-radio-button>
            <mat-radio-button class="mr-16" value="lower">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE_INF' | translate }}</mat-radio-button>
            <mat-radio-button value="equal">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE_EQ' | translate }}</mat-radio-button>
        </mat-radio-group>
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE' | translate }}</mat-label>
            <input matInput formControlName="age" [placeholder]="'NEWS_SEGMENTS.FORM.INPUTS.AGE' | translate"
                   type="number" min="0">
            <mat-error *ngIf="this.form.get('age').hasError('min')">
                {{ 'NEWS_SEGMENTS.FORM.ERRORS.AGE_MIN' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-radio-group formControlName="ageValueType" class="mb-16">
            <mat-radio-button class="mr-16" value="month">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE_MONTH' | translate }}</mat-radio-button>
            <mat-radio-button value="year">{{ 'NEWS_SEGMENTS.FORM.INPUTS.AGE_YEAR' | translate }}</mat-radio-button>
        </mat-radio-group>
    </div>

    <div fxLayout="row" fxLayoutAlign="{{stats ? 'space-between': 'end' }} center" fxLayoutGap="8px">
        <p *ngIf="stats">{{ 'NEWS_SEGMENTS.FORM.STATS.SELECTED' | translate }} <b>{{ stats.number ? stats.number : 0 }}</b> / {{ stats.total ? stats.total : 0 }}</p>
        <button mat-raised-button type="submit" color="accent" [disabled]="formInProgress">
            <mat-icon>save</mat-icon>
            {{ 'FORM.SAVE' | translate }}
        </button>
        <mat-spinner mode="indeterminate" diameter="20" color="primary" *ngIf="formInProgress"></mat-spinner>
    </div>
</form>

import { WebsocketMessage } from './websocket-message';
import { RendezVous } from '../rendez-vous/rendez-vous';
import { JsonProperty } from 'json-object-mapper';

export class RdvWaitingRoomEnter extends WebsocketMessage<RendezVous> {
    @JsonProperty()
    type = 'rdv.waiting-room.enter';

    @JsonProperty({ type: RendezVous })
    data: RendezVous = null;
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../services/api/auth.service';
import { AuthErrorResponseInterface } from '../../models/interfaces/auth/auth-error-response.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable, Subscription } from 'rxjs';
import { BugService } from '../../services/api/bug.service';
import { EmailSuggestion, Suggestion } from 'ngx-validators';
import { emailOptionsConfig } from 'app/config';

@Component({
    selector: 'fuse-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy {
    form: FormGroup;
    error: string;
    loginInProgress: boolean;
    platform = 'android';
    locale = 'en';
    isHidden = false;
    hidePassword = true;
    isReSignIn = false;

    version: string = environment.version;
    buildVersion: string = environment.buildVersion;
    apiVersion$: Observable<string>;

    emailSuggestion?: Suggestion;

    private emailSuggestionExecutor = new EmailSuggestion();
    private subscritions: Subscription[] = [];

    @HostListener('window:popstate', ['$event'])
    onpopstate(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        window.history.back();
    }

    constructor(
        private fuseConfigService: FuseConfigService,
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private translateService: TranslateService,
        private bugService: BugService,
        private route: ActivatedRoute
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', Validators.required]
        });

        this.form.get('email').valueChanges.subscribe(value => {
            this.emailSuggestion = this.emailSuggestionExecutor.suggest(value, emailOptionsConfig)?.suggestion;
        });

        const valChangeSubscription = this.form.valueChanges.subscribe(() => {
            this.error = null;
        });

        if (navigator.platform.includes('Mac') || navigator.platform.includes('iPad')) {
            this.platform = 'iphone';
        }

        const langChangeSubscription = this.translateService.onLangChange.subscribe(() => {
            this.locale = this.translateService.currentLang.slice(0, 2).toLowerCase();
        });

        this.subscritions.push(valChangeSubscription, langChangeSubscription);

        if (environment.dev || environment.demo || environment.preproduction) {
            this.apiVersion$ = this.bugService.getLastCommit();
        }

        this.isReSignIn = !!this.route.snapshot.data?.resignin;
    }

    ngOnDestroy(): void {
        this.subscritions.forEach(s => {
            s.unsubscribe();
        });
    }

    login(): void {
        if (this.form.valid && !this.loginInProgress) {
            this.error = null;
            this.loginInProgress = true;

            const authSubscription = this.authService.obtainAccessToken(this.form.get('email').value, this.form.get('password').value).subscribe({
                next: () => {
                    this.isHidden = true;
                    this.loginInProgress = false;
                    void this.router.navigate([this.route.snapshot.queryParams.returnUrl || '/']);
                },
                error: (err: HttpErrorResponse) => {
                    this.loginInProgress = false;
                    if (err.status === 415) { // Connexion d'un client web
                        void this.router.navigate(['onboarding-client-end']);
                        return;
                    }

                    const resp: AuthErrorResponseInterface | any = err.error;
                    if (resp?.message) {
                        this.error = resp.message;
                    } else {
                        this.error = this.translateService.instant('SHARED.ERROR');
                    }
                }
            });

            this.subscritions.push(authSubscription);
        }
    }
}

import { OnboardingClientEndComponent } from './onboarding-client-end.component';
import { NoAuthGuard } from '../../guards/no-auth.guard';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { SharedModule } from '../../shared.module';
import { ViewSharedModule } from '../shared/view-shared.module';
import { NgModule } from '@angular/core';

const routes = [
    {
        path: 'onboarding-client-end',
        component: OnboardingClientEndComponent,
        canActivate: [NoAuthGuard]
    }
];

@NgModule({
    declarations: [
        OnboardingClientEndComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        SharedModule,
        ViewSharedModule
    ]
})
export class OnboardingClientEndModule {}

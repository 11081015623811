import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { Coupon, CurrenInvoiceItem, Invoice, Subscription } from '../../../models/subscription';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Veterinaire } from 'app/models/utilisateurs/veterinaire';
import { StripeSession } from 'app/models/stripe/session';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EntiteJuridique } from 'app/models/pro/entite-juridique';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { PlanDialogComponent, PlanDialogInterface } from '../../shared/form-dialog/plan-dialog/plan-dialog.component';
import { DialogComponent, DialogDataInterface } from 'app/main/shared/view-utils/dialog/dialog.component';
import { DateLocalePipe } from 'app/pipes/date-locale.pipe';
import { add, compareAsc, isBefore, isSameDay } from 'date-fns';
import { Utils } from 'app/utils';
import { StripeService } from '../../../services/api/stripe.service';

@Component({
    selector: 'app-abonnement',
    templateUrl: './abonnement.component.html',
    styleUrls: ['./abonnement.component.scss'],
    animations: fuseAnimations
})
export class AbonnementComponent implements OnInit {
    subscription = new BehaviorSubject<Subscription>(null);
    entiteJuridique = new BehaviorSubject<EntiteJuridique>(null);
    currentDate = Date.now();
    currentUser: Veterinaire = null;
    stats: AbonnementStats = null;
    isCanceled = false;

    currentIvoiceGroupedItems: InvoiceItemGroup[] = [];
    now = new Date();

    public get montly(): Observable<boolean> {
        return this.subscription.pipe(map(s => s.monthly));
    }

    public get nbLicensesBase(): Observable<number> {
        return this.subscription.pipe(map(_ => {
            return null;
        }));
    }

    public get nbLicensesSup(): Observable<number> {
        return combineLatest([this.nbLicensesTotal, this.nbLicensesBase]).pipe(map(([total, base]) => {
            if (total && base) {
                return total - base;
            }

            return null;
        }));
    }

    public get nbLicensesTotal(): Observable<number> {
        return this.entiteJuridique.pipe(map(ej => ej.nbLicences));
    }

    public get nbFreeHours(): Observable<number> {
        return this.subscription.pipe(map(s => {
            return s?.discount?.coupon?.metadata?.visioFreeHours ?? 0;
        }));
    }

    constructor(
        private entiteJuridiqueService: EntiteJuridiqueService,
        private utilisateurService: UtilisateurService,
        private translateService: TranslateService,
        private stripeService: StripeService,
        private router: Router,
        private route: ActivatedRoute,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.utilisateurService.utilisateurConnected.subscribe(u => {
            this.currentUser = u;
            this.entiteJuridiqueService.getEntiteJuridiqueById(u.entiteJuridique.id).subscribe(entiteJuridique => {
                this.entiteJuridique.next(entiteJuridique);
                this.stats = {
                    visio: entiteJuridique?.callSecondsUsed,
                    space: entiteJuridique?.fileSpaceUsed,
                    maxSpace: entiteJuridique?.nbGigaStockage,
                    payments: entiteJuridique?.currentAppointmentPricePayed,
                    paymentsCurrency: entiteJuridique.currency
                };
                this.isCanceled = entiteJuridique.cancelSubscriptionDate !== null;
            });
            this.entiteJuridiqueService.getSubscription(u.entiteJuridique).subscribe(s => {
                this.subscription.next(s);
                if (s) {
                    s.currentInvoice?.items.forEach(item => {
                        const index = this.currentIvoiceGroupedItems.findIndex(group => {
                            const startEqual = isSameDay(group.periodStart, item.periodStart);
                            const endEqual = isSameDay(group.periodEnd, item.periodEnd);
                            return startEqual && endEqual;
                        });
                        if (index === -1) {
                            this.currentIvoiceGroupedItems.push({
                                periodStart: item.periodStart,
                                periodEnd: item.periodEnd,
                                items: [item]
                            });
                        } else {
                            this.currentIvoiceGroupedItems[index].items.push(item);
                        }
                    });
                    this.currentIvoiceGroupedItems.sort((a, b) => compareAsc(a.periodStart, b.periodStart));
                }
            });
        });

        this.handleStripeQuery();
    }

    downloadInvoice(invoice: Invoice): void {
        this.entiteJuridiqueService.getInvoiceBlob(this.currentUser.entiteJuridique, invoice).subscribe({
            next: file => {
                Utils.downloadFile(file);
            },
            error: error => {
                console.error(error);
                const snack = this.snackbar.open(this.translateService.instant('SHARED.ERROR'), this.translateService.instant('SHARED.RETRY'), { duration: 3000 });
                snack.onAction().subscribe(() => {
                    this.downloadInvoice(invoice);
                });
            }
        });
    }

    updatePaymentMethod(): void {
        this.entiteJuridiqueService.updatePaymentMethod(this.currentUser?.entiteJuridique).subscribe((res: StripeSession) => {
            const idSession = res?.idSession;
            if (idSession) {
                this.stripeService.gotoStripe(idSession).catch(err => {
                    console.error(err);
                });
            }
        });
    }

    getCouponCode(coupon: Coupon): string {
        return Coupon.getCouponCode(coupon);
    }

    getDateEndCoupon(subscription: Subscription): Date {
        const dateEndCoupon = new Date(Number(subscription.startDate));
        dateEndCoupon.setMonth(dateEndCoupon.getMonth() + Number(subscription.discount.coupon.monthsDuration));
        return dateEndCoupon;
    }

    getPriceAfterEndCoupon(subscription: Subscription): number {
        return subscription.currentInvoice?.items
            // .filter(i => i?.product?.type === 'forfait.mensuel' || i?.product?.type === 'forfait.annuel' || i?.product?.type === 'monetisation.mensuel' || i?.product?.type === 'monetisation.annuel')
            .map(p => p.price)
            .reduce((sum, current) => sum + current, 0);
    }

    updateSubcription(): void {
        const data: PlanDialogInterface = {
            entiteJuridique: this.utilisateurService.utilisateurConnectedValue.entiteJuridique,
            subscription: this.subscription.value
        };
        const dialog = this.dialog.open(PlanDialogComponent, {
            data,
            width: '512px',
            disableClose: true
        });

        dialog.afterClosed().subscribe(res => {
            if (res instanceof Subscription) {
                window.location.reload();
            }
        });
    }

    showCancelSubscriptionDialog(): void {
        const datePipe = new DateLocalePipe(this.translateService);
        const cancelDate = add(Date.now(), { days: 5 });
        const monthOrYear: Duration = this.subscription.value.monthly ? { months: 1 } : { years: 1 };
        const endDate = isBefore(cancelDate, this.subscription.value.currentPeriodEnd) ? this.subscription.value.currentPeriodEnd : add(this.subscription.value.currentPeriodEnd, monthOrYear);
        const data: DialogDataInterface = {
            title: this.translateService.instant('SUBSCRIPTION.CANCEL_DIALOG.TITLE') as string,
            content: this.translateService.instant('SUBSCRIPTION.CANCEL_DIALOG.CONTENT', {
                cancelDate: datePipe.transform(cancelDate, 'longDate'),
                endDate: datePipe.transform(endDate, 'longDate')
            }) as string,
            ok: this.translateService.instant('SUBSCRIPTION.CANCEL_DIALOG.CONFIRM') as string,
            cancel: this.translateService.instant('SUBSCRIPTION.CANCEL_DIALOG.CANCEL') as string,
            action: true
        };
        this.dialog.open(DialogComponent, {
            data
        }).afterClosed().subscribe(result => {
            if (result) {
                this.cancelSubscription();
            }
        });
    }

    showUncancelSubscriptionDialog(): void {
        const data: DialogDataInterface = {
            title: this.translateService.instant('SUBSCRIPTION.UNCANCEL_DIALOG.TITLE') as string,
            content: this.translateService.instant('SUBSCRIPTION.UNCANCEL_DIALOG.CONTENT') as string,
            ok: this.translateService.instant('SUBSCRIPTION.UNCANCEL_DIALOG.UNCANCEL') as string,
            cancel: this.translateService.instant('SUBSCRIPTION.UNCANCEL_DIALOG.CANCEL') as string,
            action: true
        };
        this.dialog.open(DialogComponent, {
            data
        }).afterClosed().subscribe(result => {
            if (result) {
                this.uncancelSubscription();
            }
        });
    }

    private uncancelSubscription(): void {
        const id = this.currentUser?.entiteJuridique?.id;
        if (id && this.isCanceled) {
            this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.UNCANCEL.INPROGRESS'));
            this.entiteJuridiqueService.uncancelSubscription(id).subscribe({
                next: () => {
                    this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.UNCANCEL.SUCCESS'), null, { duration: 3000 }).afterDismissed().subscribe(() => {
                        window.location.reload();
                    });
                },
                error: () => {
                    this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.UNCANCEL.ERROR'), null, { duration: 3000 });
                }
            });
        }
    }

    private cancelSubscription(): void {
        const id = this.currentUser?.entiteJuridique?.id;
        if (id && !this.isCanceled) {
            this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.CANCEL.INPROGRESS'));
            this.entiteJuridiqueService.cancelSubscription(id).subscribe({
                next: () => {
                    this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.CANCEL.SUCCESS'), null, { duration: 3000 }).afterDismissed().subscribe(() => {
                        window.location.reload();
                    });
                },
                error: () => {
                    this.snackbar.open(this.translateService.instant('SUBSCRIPTION.SNACKBAR.CANCEL.ERROR'), null, { duration: 3000 });
                    this.showCancelSubscriptionDialog();
                }
            });
        }
    }

    private handleStripeQuery() {
        setTimeout(() => {
            const updatedString = this.translateService.instant('SUBSCRIPTION.PAYMENT.CARD_UPDATED') as string;
            const notUpdatedString = this.translateService.instant('SUBSCRIPTION.PAYMENT.CARD_NOT_UPDATED') as string;
            this.route.queryParamMap.subscribe(queryParams => {
                if (queryParams.has('session_id')) {
                    void this.router.navigate([], {
                        queryParams: { session_id: null },
                        queryParamsHandling: 'merge'
                    });
                    this.snackbar.open(updatedString, null, { duration: 3000 });
                } else if (queryParams.has('updating_failed')) {
                    void this.router.navigate([], {
                        queryParams: { updating_failed: null },
                        queryParamsHandling: 'merge'
                    });
                    this.snackbar.open(notUpdatedString, null, { duration: 3000 });
                }
            });
        }, 500);
    }
}

interface AbonnementStats {
    visio: number;
    space: number;
    maxSpace: number;
    payments: number;
    paymentsCurrency: string;
}

interface InvoiceItemGroup {
    periodStart: Date;
    periodEnd: Date;
    items: CurrenInvoiceItem[];
}

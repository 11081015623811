<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg">
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="1 1 auto">
        <mat-card>
            <mat-card-content>
                <div fxLayout="column" class="pl-24 pr-24 pb-24">
                    <div class="step-header">
                        <img class="icon" src=" ../../../../assets/images/icons/onboarding-icon-firework.svg" alt="Mail Icon" fxFlex="0 0"/>
                        <h1 [innerHTML]="'ONBOARDING_PRO_END.TITLE' | translate"></h1>
                        <p [innerHTML]="'ONBOARDING_PRO_END.SUBTITLE' | translate"></p>

                        <button mat-stroked-button (click)="redirect()">
                            {{ 'ONBOARDING_PRO_END.ACTION' | translate }}
                        </button>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>


import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Client } from '../../../../../models/utilisateurs/client';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilisateurService } from '../../../../../services/api/utilisateur.service';
import { debounceTime, filter } from 'rxjs/operators';
import { Utils } from '../../../../../utils';
import { Veterinaire } from 'app/models/utilisateurs/veterinaire';
import { Animal } from 'app/models/animal/animal';

@Component({
    selector: 'app-input-animal',
    templateUrl: './input-animal.component.html',
    styleUrls: ['./input-animal.component.scss']
})
export class InputAnimalComponent implements OnInit, OnChanges {
    @Input() control: AbstractControl;
    @Input() clients: Array<Animal | Client | Veterinaire>;
    @Input() showMailTel: boolean;
    @Input() nullValue: string;
    @Input() nullValueForAll: string;
    @Input() maxShown = 20;

    internalControl = new FormControl();

    clientsFilter: FormControl = new FormControl();
    filteredClientsOptions$: BehaviorSubject<Client[]> = new BehaviorSubject<Client[]>(null);
    loading: boolean;
    required: boolean;

    allClients: Client[] = [];

    private subscription: Subscription;

    constructor(private utilisateurService: UtilisateurService) {}

    ngOnInit(): void {
        if (this.clients?.length > 0) {
            this.allClients = this.clients as Client[];
        }

        this.internalControl.setValue(this.control.value);
        this.internalControl.disable();
        this.internalControl.valueChanges.subscribe(value => this.control.setValue(value));
        this.required = Utils.isRequiredField(this.control);
        if (this.required) {
            this.internalControl.setValidators([Validators.required]);
        }

        this.search();

        this.clientsFilter.valueChanges
            .pipe(
                filter(v => v?.length > 0),
                debounceTime(150)
            )
            .subscribe(_ => {
                this.search();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.control) {
            this.internalControl.setValue(this.control.value);

            if (!changes.control.isFirstChange()) {
                this.search();
            }
        }
    }

    private search() {
        const filter = this.clientsFilter.value ? this.clientsFilter.value.toLowerCase() : '';

        if (this.allClients.length > 0) {
            let dataOk = [...this.allClients]
                .filter(c =>
                    (this.nullValueForAll || c.animaux.length > 0) &&
                    [
                        c.nom,
                        c.prenom,
                        c.mail,
                        c.telephonePortable,
                        c.animaux.map(a => a.nom)
                    ].filter(e => !!e).join('').replace(/ /g, '').toLowerCase().includes(filter)
                ).sort((a, b) => a.nom < b.nom ? -1 : 1);

            if (this.maxShown) {
                dataOk = dataOk.slice(0, this.maxShown);
            }

            this.filteredClientsOptions$.next(dataOk);

            if (this.control.enabled) {
                this.internalControl.enable();
            }
        } else {
            this.loading = true;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            this.subscription = this.utilisateurService.getClients(filter, 'asc', 'fullName', null, this.maxShown, true, null, this.internalControl.value).subscribe(clients => {
                if (this.control.enabled) {
                    this.internalControl.enable();
                }

                this.loading = false;
                // this.allClients = clients.data.filter(a => a.animaux.length > 0);
                // this.filteredClientsOptions$.next(this.allClients);
                this.filteredClientsOptions$.next(clients.data.filter(c => this.nullValueForAll || c.animaux.length > 0));
            });
        }
    }
}

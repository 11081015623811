import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { DevicePermission, VisioService } from '../../../../services/visio.service';
import { OpentokService } from 'app/services/opentok.service';
import { Publisher, Stream, Device } from '@opentok/client';
import { MatSelectChange } from '@angular/material/select';

@Component({
    selector: 'app-visio-parameter',
    templateUrl: './visio-parameter.component.html',
    styleUrls: ['./visio-parameter.component.scss']
})
export class VisioParameterComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() visioService: VisioService;
    @Input() preview = true;
    @Input() destroyOnFinish = true;

    isTesting = false;
    private subscriptions: Subscription[] = [];

    @ViewChild('publisherDiv') publisherDiv: ElementRef;
    publisher: Publisher;
    stream: Stream;

    audioDevices: Device[] = [];
    videoDevices: Device[] = [];
    audioLevel = new BehaviorSubject<number>(0);

    selectedAudioDevice: Device = undefined;
    selectedVideoDevice: Device = undefined;

    DevicePermission = DevicePermission;

    constructor(
        private ref: ChangeDetectorRef,
        public opentokService: OpentokService
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.visioService.permissionsStatus.subscribe(permissionStatus => {
                if (permissionStatus === DevicePermission.Ok) {
                    this.opentokService.getDevices((err, devices) => {
                        if (err) {
                            return;
                        }

                        devices.forEach(device => {
                            if (device.kind === 'audioInput') {
                                this.audioDevices.push(device);
                            } else if (device.kind === 'videoInput') {
                                this.videoDevices.push(device);
                            }
                        });

                        // I supposed that the selected device is the first but cant confirm it

                        let selectedAudiodevice = this.audioDevices.find(d => d.deviceId === 'default');
                        if (!selectedAudiodevice && this.audioDevices.length > 0) {
                            selectedAudiodevice = this.audioDevices[0];
                        }

                        this.selectedAudioDevice = selectedAudiodevice;

                        // let selectedVideoDevice = this.videoDevices.find(d => d.deviceId === 'default');
                        // if (!selectedVideoDevice && this.videoDevices.length > 0) {
                        //   selectedVideoDevice = this.videoDevices[0];
                        // }
                        // this.selectedVideoDevice = selectedVideoDevice;
                    });
                }
            })
        );

        const testStartSubscription = this.visioService.onTestStart.subscribe(() => {
            this.isTesting = true;
        });

        if (testStartSubscription) {
            this.subscriptions.push(testStartSubscription);
        }

        const testEndSubscription = this.visioService.onTestEnd.subscribe(() => {
            this.isTesting = false;
            this.initPublisher();
        });

        if (testEndSubscription) {
            this.subscriptions.push(testEndSubscription);
        }
    }

    ngAfterViewInit(): void {
        this.initPublisher();
    }

    initPublisher(): void {
        this.publisher = this.visioService.currentPublisherObject ? this.visioService.currentPublisherObject : this.visioService.initPublisher(this.publisherDiv.nativeElement, {
            publishAudio: true,
            publishVideo: true,
            insertMode: 'append',
            mirror: false,
            style: {
                nameDisplayMode: 'off',
                buttonDisplayMode: 'off',
                audioLevelDisplayMode: 'off',
                archiveStatusDisplayMode: 'off',
                backgroundImageURI: 'on'
            }
        } as any);

        // Force environement facing, to change in publisher parameter if the sdk allow it one day
        this.publisher.once('videoElementCreated', () => {
            this.switchCamera();
        });

        let lastUpdate = Date.now();
        let max = 0;
        this.publisher.on('audioLevelUpdated', event => {
            if (event.audioLevel) {
                const current = Date.now();
                if (event.audioLevel > max) {
                    max = event.audioLevel;
                }

                if (current - lastUpdate > 200) {
                    this.audioLevel.next(max * 100);
                    max = 0;
                    lastUpdate = Date.now();
                    if (!(this.ref as any).destroyed) {
                        this.ref.detectChanges();
                    }
                }
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((s: Subscription) => {
                s.unsubscribe();
            });
        }

        if (this.destroyOnFinish && this.publisher) {
            this.publisher.off('audioLevelUpdated');
            this.publisher.destroy();
        }
    }

    switchCamera(): void {
        if (this.publisher) {
            void this.publisher.cycleVideo().then(device => {
                const newDevice = this.videoDevices.find(d => d.deviceId === device.deviceId);
                if (newDevice) {
                    this.selectedVideoDevice = newDevice;
                }
            });
        }
    }

    selectMicrophone(event: MatSelectChange): void {
        if (this.publisher && event.value) {
            const deviceId = event.value;
            void this.publisher.setAudioSource(deviceId).then(() => {
                const newDevice = this.audioDevices.find(d => d.deviceId === deviceId);
                if (newDevice) {
                    this.selectedAudioDevice = newDevice;
                }
            });
        }
    }

    showSelectedVideoDevice(): string {
        if (!this.selectedVideoDevice) {
            return '';
        }

        const selectedDevice = this.videoDevices.find(d => d.deviceId === this.selectedVideoDevice.deviceId);
        return selectedDevice?.label ?? '';
    }

    isSameDevices(): boolean {
        try {
            const selectedVideoDevice = this.selectedVideoDevice;
            const selectedAudioDevice = this.selectedAudioDevice;
            const firstCase = selectedVideoDevice.label.includes(selectedAudioDevice.label);
            const secondCase = selectedAudioDevice.label.includes(selectedVideoDevice.label);
            return firstCase || secondCase;
        } catch {
            return true;
        }
    }
}

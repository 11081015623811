import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import html2Canvas from 'html2canvas';
import { switchMap, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LogPostInterface } from '../../models/interfaces/post/log-post.interface';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class BugService {
    public static session = uuidv4();
    public consoleError: any[] = [];

    private bugSendedInProgress: boolean;
    private lastLogSended: LogPostInterface;

    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private deviceService: DeviceDetectorService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar
    ) {}

    public getScreenhostBase64(): Observable<string> {
        const appElement: HTMLElement = document.querySelectorAll('app')[0] as HTMLElement;
        return new Observable<string>(observer => {
            html2Canvas(appElement, {
                useCORS: true,
                svgRendering: true
            } as any).then(canvas => {
                observer.next(canvas.toDataURL('images/jpeg', 0.2));
                observer.complete();
            }).catch(() => {
                observer.next(null);
                observer.complete();
            });
        });
    }

    public sendBugReport(comment: string, sendByUser = true): Observable<void> {
        if (!this.bugSendedInProgress || sendByUser) {
            this.bugSendedInProgress = true;
            if (sendByUser) {
                this.snackbar.open(this.translateService.instant('BUG.MESSAGE'), this.translateService.instant('SHARED.OK'), { duration: 2000 });
            }

            return this.getScreenhostBase64().pipe(
                switchMap(image => {
                    const report: Report = {
                        app: 'Web' + (sendByUser ? '' : 'auto'),
                        appVersion: environment.version + ' build ' + environment.buildVersion,
                        browser: this.deviceService.browser,
                        browserVersion: this.deviceService.browser_version,
                        device: this.deviceService.device,
                        os: this.deviceService.os,
                        osVersion: this.deviceService.os_version,
                        text: comment,
                        userAgent: this.deviceService.userAgent,
                        screenshotB64: image,
                        console: this.consoleError
                    };
                    return this.http.post<void>(this.config.baseUrl + 'api/bug', report).pipe(
                        tap({
                            next: () => {
                                this.bugSendedInProgress = false;
                                this.consoleError.length = 0;
                            },
                            error: () => {
                                this.bugSendedInProgress = false;
                            }
                        })
                    );
                }));
        }

        return of<void>(null);
    }

    public sendLog(event: string, data: any = null): void {
        const dataToSend: LogPostInterface = {
            event: event,
            data: data ? JSON.stringify({
                message: data.message,
                stack: data.stack
            }) : null
        };
        if (!this.lastLogSended || dataToSend.data !== this.lastLogSended.data) {
            this.http.post(this.config.baseUrl + 'api/log', dataToSend).subscribe();
            this.lastLogSended = dataToSend;
        }
    }

    public getLastCommit(): Observable<string> {
        return of('Front :\n' + environment.lastCommit);
    }
}

interface Report {
    app: string;
    appVersion: string;
    browser: string;
    browserVersion: string;
    device: string;
    os: string;
    osVersion: string;
    text: string;
    userAgent: string;
    screenshotB64: string;
    console: any[];
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-chat-scenario-program-message',
    templateUrl: './chat-scenario-program-message.component.html',
    styleUrls: ['./chat-scenario-program-message.component.scss']
})
export class ChatScenarioProgramMessageComponent implements OnInit {
    @Input() form: FormGroup;

    ngOnInit(): void {
        const numberDaysToOpenIfClosedForm = this.form.get('numberDaysToOpenIfClosed');

        if (!numberDaysToOpenIfClosedForm.value) {
            numberDaysToOpenIfClosedForm.disable();
        }

        this.form.get('reopenChatIfClosed').valueChanges.subscribe(val => {
            if (val) {
                numberDaysToOpenIfClosedForm.enable();
            } else {
                numberDaysToOpenIfClosedForm.setValue(null);
                numberDaysToOpenIfClosedForm.disable();
            }
        });
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chat, TypeChatMessageEnum } from '../../models/animal/chat';
import { ChatMessage, FileChatMessagePayloadInterface } from '../../models/animal/chat-message';

@Pipe({
    name: 'chatMessage'
})
export class ChatMessagePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(chat: Chat): string {
        if (!chat.lastMessageDate) {
            return this.translateService.instant('CHAT.NO_MESSAGE') as string;
        }

        if (chat.lastMessageType === TypeChatMessageEnum.file && chat.lastMessageText) { // type file and last message = url of file
              return this.translateService.instant('SHARED.DOWNLOAD_FILE') as string;
        }

        return chat.lastMessageText;
    }

    transformFromSendbirdMessage(message: ChatMessage): string {
      if (!message) {
        return this.translateService.instant('CHAT.NO_MESSAGE') as string;
      }

      if (message.isFile) {
        const payload = message.payload as FileChatMessagePayloadInterface;
        if (payload?.url) {
          return this.translateService.instant('SHARED.DOWNLOAD_FILE') as string;
        }
      }

      return message.payload as string;
    }
}

<div id="calendar" class="page-layout simple fullwidth">

    <!-- HEADER -->
    <div class="header p-16 p-sm-24 accent-fg">

        <div class="header-content" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">

            <div class="logo secondary-text mb-16 mb-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon">today</mat-icon>
                <span class="logo-text">{{ 'CALENDAR.DATE_VETO' | translate }}</span>
                <button mat-icon-button color="accent" matTooltip="{{ 'SHARED.REFRESH' | translate }}" (click)="refreshRendezVous(true)"><mat-icon>refresh</mat-icon></button>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-icon-button class="arrow"
                        mwlCalendarPreviousView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="this.setSelectedDayForDate($event)"
                        (click)="refreshRendezVous()">
                    <mat-icon>chevron_left</mat-icon>
                </button>

                <div class="title">
                    {{ viewDate | calendarDate:(view + 'ViewTitle') }}
                </div>

                <button mat-icon-button class="arrow"
                        mwlCalendarNextView
                        [view]="view"
                        [(viewDate)]="viewDate"
                        (viewDateChange)="this.setSelectedDayForDate($event)"
                        (click)="refreshRendezVous()">
                    <mat-icon>chevron_right</mat-icon>
                </button>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <!--<button mat-icon-button aria-label="Search" matTooltip="Search">-->
                <!--<mat-icon>search</mat-icon>-->
                <!--</button>-->

                <button mat-icon-button
                        mwlCalendarToday
                        [(viewDate)]="viewDate"
                        (viewDateChange)="this.setSelectedDayForDate($event)"
                        (click)="refreshRendezVous()"
                        [matTooltip]="'CALENDAR.TODAY' | translate">
                    <mat-icon>today</mat-icon>
                </button>

                <button mat-icon-button (click)="view='day'; expandedMonthDay = null"
                        [matTooltip]="'CALENDAR.DAY' | translate">
                    <mat-icon>view_day</mat-icon>
                </button>

                <button mat-icon-button (click)="view='week'; expandedMonthDay = null"
                        [matTooltip]="'CALENDAR.WEEK' | translate">
                    <mat-icon>view_week</mat-icon>
                </button>

                <button mat-icon-button (click)="view='month'; expandedMonthDay = null"
                        [matTooltip]="'CALENDAR.MONTH' | translate">
                    <mat-icon>view_module</mat-icon>
                </button>

                <button mat-raised-button
                        class="add-event-button fuse-white-fg"
                        color="primary"
                        (click)="editRendezVous()">
                    <mat-icon>add</mat-icon> {{ 'CALENDAR.DATE_NEW' | translate }}
                </button>
            </div>
            <!-- / TOOLBAR -->
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content">

        <div *ngIf="loadingInProgress; else calendarTemplate" fxLayout="column" fxLayoutAlign="center center">
            <mat-spinner mode="indeterminate" diameter="40" color="accent"></mat-spinner>
            <div class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</div>
        </div>

        <ng-template #customCellTemplate let-day="day" let-locale="locale">
            <div class="cal-cell-top">
                <span class="cal-day-badge primary-bg" *ngIf="day.events.length">{{ day.events.length }} {{ 'CALENDAR.RDV' | translate }}</span>
                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>

            <div class="cal-events ng-star-inserted">
                <a *ngFor="let event of day.events; let index = index"
                   [matTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
                   [class]="'cal-event ' + event.cssClass"
                   [class.hidden]="index >= 3 && day.date != expandedMonthDay"
                   matTooltipClass="message"
                   (click)="editRendezVous(event); $event.stopPropagation();">
                    {{ event.start | dateLocale:'shortTime' }} {{ event.title | truncate:20:'...':true }}
                </a>
                <a class="see-more" (click)="$event.stopPropagation(); expandedMonthDay = day.date"
                   *ngIf="day.date != expandedMonthDay && day.events?.length > 3">
                    {{ 'SHARED.SEE_MORE' | translate }} ({{ day.events.length - 3 }})
                </a>
            </div>
        </ng-template>

        <ng-template #calendarTemplate>
            <div [ngSwitch]="view">
                <mwl-calendar-month-view
                        *ngSwitchCase="'month'"
                        [weekStartsOn]="null | firstDayWeek"
                        [viewDate]="viewDate"
                        [events]="events$ | async"
                        [refresh]="refresh$"
                        (dayClicked)="dayClicked($event.day)"
                        (eventClicked)="editRendezVous($event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)"
                        [cellTemplate]="customCellTemplate"
                        [locale]="locale">
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                        *ngSwitchCase="'week'"
                        [viewDate]="viewDate"
                        [weekStartsOn]="null | firstDayWeek"
                        [events]="events$ | async"
                        [refresh]="refresh$"
                        (eventClicked)="editRendezVous($event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)"
                        [dayStartHour]="6"
                        [dayEndHour]="20"
                        [locale]="locale">
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                        *ngSwitchCase="'day'"
                        [viewDate]="viewDate"
                        [events]="events$ | async"
                        [refresh]="refresh$"
                        (eventClicked)="editRendezVous($event.event)"
                        (eventTimesChanged)="eventTimesChanged($event)"
                        [dayStartHour]="6"
                        [dayEndHour]="20"
                        [locale]="locale">
                </mwl-calendar-day-view>
            </div>
        </ng-template>
    </div>
    <!-- / CONTENT -->
</div>

<div class="forgot-pass-container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="logo">
        <img src="/assets/images/logos/logo-vertical.svg" alt="Logo">
    </div>
    <div class="forgot-pass-card mat-elevation-z2">
        <div *ngIf="email != null">
            <h1>{{ 'PWD_FORGOTTEN.MESSAGE_TITLE' | translate }}</h1>
            <h4>{{ 'PWD_FORGOTTEN.MESSAGE' | translate }} <a href="mailto:{{ email }}">{{ email }}</a>.
                <br>{{ 'PWD_FORGOTTEN.CHANGE_PWD_LINK' | translate }}</h4>
        </div>
        <div *ngIf="email == null">
            <h1>{{ 'LOGIN.PWD_FORGOTTEN' | translate }}</h1>
            <h4>{{ 'PWD_FORGOTTEN.MAIL' | translate }}</h4>
            <div class="content">
                <form [formGroup]="form" (submit)="sendResetPassMail()">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'FORM.MAIL' | translate }}</mat-label>
                        <input matInput formControlName="email" type="email" required>
                        <mat-error *ngIf="form.get('email').hasError('required')">
                            {{ 'FORM.MAIL_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error *ngIf="!form.get('email').hasError('required') && form.get('email').hasError('normalEmailRule')">
                            {{ 'FORM.MAIL_WRONG' | translate }}
                        </mat-error>
                        <mat-hint *ngIf="emailSuggestion" class="error">
                            <span>{{ 'FORM.DID_YOU_MEAN' | translate }}</span>
                            <span
                                (click)="form.get('email').setValue(emailSuggestion.full)"
                                [style.textDecoration]="'underline'"
                                [style.cursor]="'pointer'"
                            >{{ emailSuggestion.domain }}</span>
                            <span> ?</span>
                        </mat-hint>
                    </mat-form-field>
                    <button mat-raised-button type="submit" color="primary">{{ 'SHARED.CONTINUE' | translate }}</button>
                </form>
            </div>
        </div>
    </div>
    <button mat-button [routerLink]="'/'" color="accent">{{ 'SHARED.BACK_HOME' | translate }}</button>
    <div class="forgot-pass-info">
        <h3>{{ 'PWD_FORGOTTEN.CHANGE_MAIL' | translate }}</h3>
        <p>{{ 'PWD_FORGOTTEN.CONTACT_HELP_1' | translate }} <a href="tel:+33476855360">{{ 'PWD_FORGOTTEN.CLIENT_SERVICE' | translate }}</a> {{ 'PWD_FORGOTTEN.CONTACT_HELP_2' | translate }}</p>
    </div>
</div>

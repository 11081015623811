import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
    AllConfigParentInterface,
    ConfigInterface,
    LanguageConfig,
    PaymentConfig
} from '../models/interfaces/api/conf/conf.interface';
import { CategorieInterface } from '../models/interfaces/categorie.interface';
import { EspecesRacesInterface } from '../models/interfaces/especes-races.interface';
import { PaysInterface } from '../models/interfaces/pays.interface';
import { CurrencyInterface } from '../models/interfaces/currency.interface';
import { RegionCountryInterface } from '../models/interfaces/region.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Connecteur } from 'app/models/interfaces/post/pro/connecteur.interface';
import { countries, Country } from 'countries-list';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    public baseUrl: string;
    public currentUserCountry: string;

    private _conf: BehaviorSubject<AllConfigParentInterface> = new BehaviorSubject<AllConfigParentInterface>(null);
    private _pays: BehaviorSubject<PaysInterface[]> = new BehaviorSubject<PaysInterface[]>(null);
    private _regions: BehaviorSubject<RegionCountryInterface[]> = new BehaviorSubject<RegionCountryInterface[]>(null);
    private _currency: BehaviorSubject<CurrencyInterface[]> = new BehaviorSubject<CurrencyInterface[]>(null);
    private _timezone: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    private fetchSubscription: Subscription;
    private autoRefresh$: Subscription;

    constructor(private http: HttpClient, private snackbar: MatSnackBar, private translateService: TranslateService) {
        this.baseUrl = environment.apiUrl;
        this.autoRefresh$ = interval(5 * 60 * 1000).subscribe(() => this.http.get<ConfigInterface>(this.baseUrl + 'api/config/web_pro_version').subscribe(config => {
            this.compareVersion(config.value);
        }));
    }

    public forceRefresh(): void {
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
            this.fetchSubscription = null;
        }

        this._conf.next(null);
        this.getConf();
    }

    public getConf(): Observable<AllConfigParentInterface> {
        if (!this._conf.value && !this.fetchSubscription) {
            this.fetchSubscription = this.http.get<AllConfigParentInterface>(this.baseUrl + 'api/configs').subscribe({
                next: value => this._conf.next(value),
                complete: () => {
                    this.fetchSubscription = null;
                }
            });
        }

        return this._conf.asObservable();
    }

    public getCategories(): Observable<CategorieInterface[]> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.categories)
        );
    }

    public getEspecesRaces(): Observable<EspecesRacesInterface[]> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.especesRaces)
        );
    }

    public getPaymentConfig(): Observable<PaymentConfig> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.paymentConfig)
        );
    }

    public getLanguagesConfig(): Observable<LanguageConfig[]> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.languages)
        );
    }

    public getPaysList(): Observable<PaysInterface[]> {
        if (this._pays.value) {
            return this._pays.asObservable();
        }

        this._pays.next(Object.keys(countries).map(cKey => {
            const country = countries[cKey] as Country;
            const pays: PaysInterface = {
                code: cKey,
                name: country.native,
                phoneCode: '+' + country.phone,
                flag: country.emoji
            };
            return pays;
        }).sort((a, b) => a.name < b.name ? -1 : 1));

        return this._pays.asObservable();
    }

    public getRegionsList(): Observable<RegionCountryInterface[]> {
        if (this._regions.value) {
            return this._regions.asObservable();
        }

        return this.http.get<RegionCountryInterface[]>('assets/config/regions.json').pipe(
            tap(regions => {
                this._regions.next(regions);
            })
        );
    }

    public getCurrencyList(): Observable<CurrencyInterface[]> {
        if (this._currency.value) {
            return this._currency.asObservable();
        }

        return this.http.get<CurrencyInterface[]>('assets/config/currency.json').pipe(
            tap(currencies => {
                this._currency.next(currencies.sort((a, b) => a.name < b.name ? -1 : 1));
            })
        );
    }

    public getTimezoneList(): Observable<string[]> {
        if (this._timezone.value) {
            return this._timezone.asObservable();
        }

        return this.http.get<string[]>('assets/config/timezone.json').pipe(
            tap(value => {
                this._timezone.next(value);
            })
        );
    }

    public getChatListColors(): Observable<string[]> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.chatListColors)
        );
    }

    public getBannerMessageFr(): Observable<string> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.bannerMessageFr)
        );
    }

    public getBannerMessageEn(): Observable<string> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.bannerMessageEn)
        );
    }

    public getWaitingRoomEnabled(): Observable<boolean> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => Boolean(r.configs.waitingRoomEnabled))
        );
    }

    public getConnectors(): Observable<Connecteur[]> {
        return this.getConf().pipe(
            filter(r => r !== null),
            map(r => r.configs.connectorsList?.filter(r => !r.countries || r.countries.length === 0 || r.countries.some(l => l.toLowerCase().startsWith(this.currentUserCountry))) ?? [])
        );
    }

    private compareVersion(minVersion: string) {
        const minSubs = minVersion.split('.').map(x => Number(x));
        const currentSubs = environment.version?.split('.').map(x => Number(x));
        if (minSubs.length > 0 && (currentSubs?.length ?? 0) > 0 && minSubs.length === (currentSubs?.length ?? 0)) {
            const needUpdate = this.compareSubsVersion(minSubs, currentSubs);
            if (needUpdate) {
                this.snackbar.open(this.translateService.instant('APP_VERSION.NEW'), this.translateService.instant('APP_VERSION.REFRESH'), {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 60 * 1000
                }).onAction().subscribe(() => {
                    setTimeout(() => {
                        window.location.href = '/';
                    });
                });
            }
        }
    }

    private compareSubsVersion(minSubs: number[], currentSubs: number[]): boolean {
        const minSub = minSubs.shift();
        const currentSub = currentSubs.shift();

        if (currentSub < minSub) {
            return true;
        } else if (currentSub > minSub) {
            return false;
        }

        // currentSub === minSub
        if (minSubs.length <= 0) {
            return false;
        }

        return this.compareSubsVersion(minSubs, currentSubs);
    }
}

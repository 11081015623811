import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { WaitingRoomService } from '../../../../services/api/waiting-room.service';

@Component({
    selector: 'app-waiting-room-button',
    templateUrl: './waiting-room-button.component.html',
    styleUrls: ['./waiting-room-button.component.scss'],
    animations: [
        trigger('waitingRoom', [
            transition(':enter', [
                style({ 'transform': 'scale(0.9)', 'opacity': 0, 'z-index': 99_999 }),
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({ 'transform': 'scale(1)', 'opacity': 1, 'z-index': 99_999 }))
            ]),
            transition(':leave', [
                style({ 'transform': 'scale(1)', 'opacity': 1, 'height': '*', 'z-index': 99_999 }),
                animate('0.2s cubic-bezier(.8, 0, 0.2, 1.5)',
                    style({
                        'transform': 'scale(0.9) translateY(-16px)', 'opacity': 0,
                        'height': '0px', 'z-index': 99_999
                    }))
            ])
        ])
    ]
})
export class WaitingRoomButtonComponent {
    @Input() card: boolean;

    showRoom = false;

    @HostListener('document:click', ['$event'])
    clickout(event: Event): void {
        if (!this.ref.nativeElement.contains(event.target)) {
            this.showRoom = false;
        }
    }

    constructor(
        private ref: ElementRef,
        public waitingRoomService: WaitingRoomService
    ) {}
}

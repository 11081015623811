<div class="p-12">
    <div class="p-16">

        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="h2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <span [ngSwitch]="period">
                    <span *ngSwitchCase="'FUTUR'">{{ 'CALENDAR.NEXT_DATE' | translate }}</span>
                    <span *ngSwitchCase="'PAST'">{{ 'CALENDAR.DATE_PAST' | translate }}</span>
                    <span *ngSwitchCase="'TODAY'">{{ 'CALENDAR.TODAY' | translate }}</span>
                    <span *ngSwitchCase="'DONE'">{{ 'CALENDAR.DATE_DONE' | translate }}</span>
                    <span *ngSwitchCase="'TODO'">{{ 'CALENDAR.DATE_TODO' | translate }}</span>
                    <span *ngSwitchCase="'CANCELED'">{{ 'CALENDAR.DATE_CANCELED' | translate }}</span>
                </span>
                <span *ngIf="!loading && rendezVousMeta">
                    ({{ countString }})
                </span>
                <mat-spinner mode="indeterminate" [diameter]="20" color="accent"
                             *ngIf="loadingNext || visioInProgress || loadingAuto"></mat-spinner>
                <button mat-icon-button color="accent" matTooltip="{{ 'SHARED.REFRESH' | translate }}"
                        (click)="refresh()" *ngIf="!loading && !loadingAuto">
                    <mat-icon>refresh</mat-icon>
                </button>
                <!--<span class="small">{{ 'SHARED.REFRESH_AUTO' | translate }}</span>-->
            </div>

            <button mat-raised-button color="primary" (click)="editRendezVous()"
                    *ngIf="!loading && (rendezVous$ | async)?.length > 0">
                <mat-icon>add</mat-icon>
                {{ 'CALENDAR.DATE_NEW' | translate }}
            </button>
        </div>
    </div>

    <div class="p-16 list">

        <div *ngIf="loading" fxLayout="column" fxLayoutAlign="center center" class="text-center h2 m-8">
            <mat-spinner mode="indeterminate" [diameter]="40" color="accent"></mat-spinner>
            <div class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</div>
        </div>

        <div *ngIf="!loading && (rendezVous$ | async)?.length == 0" fxLayout="column"
             fxLayoutAlign="center center" fxLayoutGap="10px">
            <img src="/assets/images/empty-state-rdv.svg"/>
            <span class="h3 mt-8">{{ 'DATE.ANY_SCHEDULED_DATE' | translate }}</span>
            <button mat-raised-button color="primary" (click)="editRendezVous()">
                <mat-icon>add</mat-icon>
                {{ 'CALENDAR.DATE_NEW' | translate }}
            </button>
        </div>

        <app-rendez-vous-tile *ngFor="let rendezVous of (rendezVous$ | async); trackBy: trackByFn"
            class="rdv mb-16"
            [rendezVous]="rendezVous"
            [period]="period"
            (loadVisio)="loadVisio($event)"
            (editRendezVous)="editRendezVous($event)"
            (capture)="capture($event)"
            (editClient)="editClient($event)"
            (editAnimal)="editAnimal($event)"></app-rendez-vous-tile>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" *ngIf="!loading">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <button color="primary" mat-button (click)="next()"
                        [disabled]="loadingNext" *ngIf="canLoadMore">{{ 'SHARED.SEE_MORE' | translate }}
                </button>
                <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="loadingNext"></mat-spinner>
            </div>

            <button mat-button routerLink="/calendrier" color="accent">
                <mat-icon>open_in_new</mat-icon>
                {{ 'CALENDAR.SEE' | translate }}
            </button>
        </div>
    </div>
</div>

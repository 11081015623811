import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ChatShortcut } from '../../../../../models/pro/entite-juridique';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-settings-shortcut-message',
    templateUrl: './settings-shortcut-message.component.html',
    styleUrls: ['./settings-shortcut-message.component.scss']
})
export class SettingsShortcutMessageComponent implements AfterViewInit {
    @Input() chatShortcutsDataSource: MatTableDataSource<ChatShortcut>;
    @Output() addOrEditShortcut: EventEmitter<ChatShortcut> = new EventEmitter<ChatShortcut>();
    @Output() removeShortcut: EventEmitter<ChatShortcut> = new EventEmitter<ChatShortcut>();
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    ngAfterViewInit(): void {
        this.chatShortcutsDataSource.paginator = this.paginator;
        this.chatShortcutsDataSource.sort = this.sort;
    }

    addOrEdit(shortcut: ChatShortcut): void {
        this.addOrEditShortcut.emit(shortcut);
    }

    remove(shortcut: ChatShortcut): void {
        this.removeShortcut.emit(shortcut);
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.chatShortcutsDataSource.filter = filterValue.trim().toLowerCase();
        this.chatShortcutsDataSource?.paginator.firstPage();
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateIsCurrentYear',
    pure: false
})
export class DateIsCurrentYearPipe implements PipeTransform {
    transform(value: Date | number): any {
        if (value instanceof Date) {
            return value.getFullYear() === new Date().getFullYear();
        } else if (typeof value === 'number') {
            return new Date(value).getFullYear() === new Date().getFullYear();
        }

        return false;
    }
}

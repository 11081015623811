import { Todo } from '../../models/todo';
import { map, tap, filter, switchMap, catchError } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { TodoPostInterface } from '../../models/interfaces/post/todo-post.interface';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TodoService {
    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public getAll(): Observable<Todo[]> {
        return this.http.get<Todo[]>(this.config.baseUrl + 'api/todos').pipe(
            map((todos: Todo[]) => {
                for (let index = 0; index < todos.length; index++) {
                    const todo = todos[index];
                    todos[index] = ObjectMapper.deserialize(Todo, todo);
                }

                return todos;
            })
        );
    }

    public getById(id: number): Observable<Todo> {
        return this.http.get<Todo>(this.config.baseUrl + 'api/todo/' + id.toString()).pipe(
            map(todo => ObjectMapper.deserialize(Todo, todo))
        );
    }

    public addTodo(todoPost: TodoPostInterface): Observable<Todo> {
        this.snackbar.open(this.translateService.instant('TODOS.ADDING'), null, { duration: 1500 });
        todoPost.id = 0;
        return this.http.post<Todo>(this.config.baseUrl + 'api/todo', todoPost).pipe(
            map(todo => ObjectMapper.deserialize(Todo, todo)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('TODOS.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public updateTodo(todoPost: TodoPostInterface): Observable<Todo> {
        this.snackbar.open(this.translateService.instant('TODOS.UPDATING'), null, { duration: 1500 });
        return this.http.put<Todo>(this.config.baseUrl + 'api/todo/' + todoPost.id.toString(), todoPost).pipe(
            map(todo => ObjectMapper.deserialize(Todo, todo)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('TODOS.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public deleteTodo(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('TODOS.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('TODOS.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('TODOS.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('TODOS.DELETING'), null, { duration: 1500 });
            return this.http.delete(this.config.baseUrl + 'api/todo/' + id.toString())
                .pipe(tap(() => {
                    this.snackbar.open(this.translateService.instant('TODOS.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
        }));
    }

    public updateTodoChecked(id: number, checked: boolean): Observable<Todo> {
        let params = new HttpParams();
        params = params.append('checked', checked ? '1' : '0');
        return this.http.patch<Todo>(this.config.baseUrl + 'api/todo/' + id.toString() + '/checked', null, {
            params: params
        }).pipe(
            map(todo => ObjectMapper.deserialize(Todo, todo)),
            catchError(err => {
                this.snackbar.open(this.translateService.instant('TODOS.ERROR_UPDATING'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                throw err;
            }));
    }
}

<div fxLayout="column" style="flex: 1">
    <mat-toolbar class="p-0 mat-elevation-z1">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            <button mat-icon-button class="toggle-sidebar-folded"
                    (click)="toggleSidebarOpened()" fxHide.gt-md>
                <mat-icon>menu</mat-icon>
            </button>

            <div fxFlex="0 0 auto" fxLayout="row" fxLayoutAlign="start center">
                <div class="w-100-p" fxLayout="row" fxLayoutAlign="start center">
                    <div class="ml-16 mr-8" fxHide.lt-sm>
                        {{ clock | dateLocale:'fullDate' }} - {{ clock | dateLocale:'H:mm' }}
                    </div>
                    <div *ngIf="env" class="dev mr-8">{{ env }}</div>
                </div>
            </div>

            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center center">
                <app-waiting-room-button
                    *ngIf="(configService.getWaitingRoomEnabled() | async) && utilisateurService.isConnected" [card]="true" class="mr-8"></app-waiting-room-button>
            </div>

            <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <button class="mr-8" mat-icon-button color="white" [matTooltip]="'BUG.SIGNAL' | translate"
                        matTooltipPosition="below" (click)="reportBug()">
                    <mat-icon>alternate_email</mat-icon>
                </button>

                <button mat-button [matMenuTriggerFor]="userMenu"
                        class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <app-avatar [entity]="utilisateur" class="mr-16"></app-avatar>
                        <span class="username mr-12" fxHide fxShow.gt-sm>{{ utilisateur?.fullName }}</span>
                        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item [routerLink]="['account']">
                        <mat-icon>person</mat-icon>
                        <span>{{ 'NAV.COMPTE' | translate }}</span>
                    </button>

                    <button mat-menu-item (click)="openTestView()">
                        <mat-icon>wifi</mat-icon>
                        <span>{{ 'NAV.CONNECTION_TEST' | translate }}</span>
                    </button>

                    <button mat-menu-item (click)="logout()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>{{ 'SHARED.SIGN_OUT' | translate }}</span>
                    </button>

                    <div *ngIf="(utilisateurService.veterinaires$ | async)?.length > 0">
                        <mat-divider></mat-divider>
                        <button mat-menu-item disabled>
                            <span>{{ 'SHARED.CONNECT_AS' | translate }}</span>
                        </button>
                    </div>
                    <div *ngFor="let entiteGeographique of utilisateur?.entitesGeographiques"
                         mat-menu-item
                         [disabled]="utilisateur.entiteGeographique?.id === entiteGeographique.id"
                         (click)="loginTo(entiteGeographique)"
                         fxLayout="row"
                         fxLayoutAlign="start center"
                         fxLayoutGap="8px">
                        <div>
                            <mat-icon>location_on</mat-icon>
                            {{ entiteGeographique.nom }}
                        </div>
                    </div>
                    <div *ngFor="let userToConnect of (utilisateurService.veterinaires$ | async)"
                         mat-menu-item
                         [disabled]="!canSwitchAccount(userToConnect)"
                         (click)="loginAs(userToConnect)"
                         fxLayout="row"
                         fxLayoutAlign="start center"
                         fxLayoutGap="8px">
                        <app-avatar [entity]="userToConnect"></app-avatar>
                        <div>
                            {{ userToConnect.fullName }}
                            <mat-icon *ngIf="userToConnect.isProprietaire"
                                      [matTooltip]="'SHARED.ADMINISTRATOR' | translate">star
                            </mat-icon>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </mat-toolbar>

    <div *ngIf="bannerMessage" class="message-banner">
        <span [innerHTML]="bannerMessage"></span>
        <a href="javascript:void(0);" class="message-banner-link"
           (click)="hideWarningNotice()">{{ 'SHARED.HIDE_BANNER' | translate }}</a>
    </div>

    <div *ngIf="!bannerMessage && cancelSubscriptionDate" class="message-banner">
        <span>{{ 'SUBSCRIPTION.CANCELLATION' | translate:{date: (cancelSubscriptionDate | date:'longDate')} }}</span>
        <a *ngIf="isAdmin && !isManaged" class="message-banner-link"
           [routerLink]="'/subscription'">{{'SUBSCRIPTION.DETAILS.UNCANCEL_SUBSCRIPTION' | translate}}</a>
        <a href="javascript:void(0);" class="message-banner-link"
           (click)="hideCancelNotice()">{{ 'SHARED.HIDE_BANNER' | translate }}</a>
    </div>

    <div *ngIf="!bannerMessage && !cancelSubscriptionDate && needsToEnablePayment" class="message-banner">
        <span>{{ 'SUBSCRIPTION.ENABLE_PAYMENT.DESCRIPTION' | translate }}</span>
        <a href="javascript:void(0);" class="message-banner-link"
           (click)="goToBankInfos()">{{ 'SUBSCRIPTION.ENABLE_PAYMENT.BUTTON' | translate }}</a>
    </div>

    <div *ngIf="!bannerMessage && !cancelSubscriptionDate && needsToRegisterOrdreVeto"
         [ngSwitch]="needsToRegisterOrdreVeto">
<!--        ; userPreferenciesService.setTrue(userPreferenciesService.USER_DISMISS_WARNING_ORDRE)-->
        <div *ngSwitchCase="'IT'" class="message-banner">
            <a target="_blank"
               (click)="needsToRegisterOrdreVeto = null"
               href="https://www.fnovi.it/node/48470"
               class="message-banner-link">Le indicazioni di Fnovi sulle attività da remoto da parte di medici
                veterinari</a>
        </div>
    </div>
</div>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { environment } from 'environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { AuthService } from 'app/services/api/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeService } from 'app/services/api/stripe.service';
import { delay } from 'rxjs/operators';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'app-informations-bancaires',
    templateUrl: './informations-bancaires.component.html',
    styleUrls: ['./informations-bancaires.component.scss'],
    animations: fuseAnimations
})
export class InformationsBancairesComponent implements OnInit, OnDestroy {
    loading$ = new BehaviorSubject<boolean>(false);
    accountLink: string;
    entiteJuridique: EntiteJuridique;
    accountType: string;

    private subscriptionStripe: Subscription;
    private subscriptionUtilisateur: Subscription;
    private subscriptionInfos: Subscription;
    private subscriptionParams: Subscription;

    constructor(
        public utilisateurService: UtilisateurService,
        private authService: AuthService,
        private stripeService: StripeService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private route: ActivatedRoute,
        private router: Router,
        private fuseConfigService: FuseConfigService
    ) {
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.isDisabled) {
            this.configureFuseLayout();
        }
    }

    ngOnInit(): void {
        this.accountLink = this.generateStripeLinkForAction(StripeAccountAction.create);
        this.subscriptionUtilisateur = this.utilisateurService.utilisateurConnected.subscribe(() => {
            this.loading$.next(true);
            this.subscriptionInfos = this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnected.subscribe({
                next: entiteJuridique => {
                    this.entiteJuridique = entiteJuridique;
                    this.subscriptionInfos?.unsubscribe();
                    if (this.entiteJuridique.paymentEnabled === null) {
                        this.accountLink = this.generateStripeLinkForAction(StripeAccountAction.create);
                        this.loading$.next(false);
                    } else {
                        this.subscriptionStripe = this.stripeService.getInfos(this.entiteJuridique.id).subscribe({
                            next: entiteJuridiqueStripe => {
                                this.accountType = entiteJuridiqueStripe.type;
                                this.accountLink = this.generateStripeLinkForAction(StripeAccountAction.see);
                                this.loading$.next(false);
                            },
                            error: () => {
                                this.loading$.next(false);
                            }
                        });
                    }
                },
                error: () => {
                    this.loading$.next(false);
                }
            });
        });
        this.subscriptionParams = this.route.queryParamMap.subscribe(queryParametersMap => {
            if (queryParametersMap.has('code') && queryParametersMap.has('state')) {
                const code = queryParametersMap.get('code');
                this.entiteJuridique.paymentEnabled = false;
                this.stripeService.createAccountFromCode(this.entiteJuridique.id, code).pipe(delay(2000)).subscribe({
                    next: entiteJuridique => {
                        this.entiteJuridique = entiteJuridique;
                        this.accountLink = this.generateStripeLinkForAction(StripeAccountAction.see);
                        void this.router.navigate([], {
                            queryParams: {
                                code: null,
                                state: null
                            },
                            queryParamsHandling: 'merge'
                        });
                    },
                    error: () => {
                        void this.router.navigate([], {
                            queryParams: {
                                code: null,
                                state: null
                            },
                            queryParamsHandling: 'merge'
                        });
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.subscriptionUtilisateur?.unsubscribe();
        this.subscriptionInfos?.unsubscribe();
        this.subscriptionParams?.unsubscribe();
        this.subscriptionStripe?.unsubscribe();
    }

    /**
     * Genère un lien pour la création ou la consulation de compte stripe
     * @param {StripeAccountAction} action Type d'action: création de compte ou visualisation (par défaut)
     */
    generateStripeLinkForAction(action: StripeAccountAction = StripeAccountAction.see): string {
        switch (action) {
            case StripeAccountAction.create:
                return this.generateCreateAccountLink();
            default:
                return this.generateSeeAccountLink();
        }
    }

    /**
     * Génère un lien de création de compte Stripe (express) avec les informations existante pré-remplie
     * @returns {string} Lien de création de compte
     */
    private generateCreateAccountLink(): string {
        const url = 'https://connect.stripe.com/express/oauth/authorize';
        const params = {
            redirect_uri: window.location.origin + '/bank-information',
            client_id: environment.stripeClientId,
            state: uuidv4(),
            stripe_user: {
                email: this.entiteJuridique?.proprietaire?.mail,
                phone_number: this.entiteJuridique?.proprietaire?.telephonePortable,
                first_name: this.entiteJuridique?.proprietaire?.prenom,
                last_name: this.entiteJuridique?.proprietaire?.nom
            }
        };
        return this.generateLinkFromParams(url, params);
    }

    /**
     * Demande à l'api un lien de visualisation du compte Stripe (Express)
     * @returns {string} Lien de visualisation de compte
     */
    private generateSeeAccountLink(): string {
        const url = this.stripeService.getSeeAccountLink(this.entiteJuridique.id);
        const params = {
            access_token: this.authService.token
        };
        return this.generateLinkFromParams(url, params);
    }

    /**
     * Génération d'un lien get avec des params
     * @param {string} baseUrl Url de base (sans `?` final)
     * @param {object} params Objet avec des params `GET` de type clé valeur
     * `string`:(`string`|`object`)
     * @returns {string} Lien avec des paramètres en `GET`
     */
    private generateLinkFromParams(baseUrl: string, params: { [key: string]: any }): string {
        const keys = Object.keys(params);
        if (keys.length > 0) {
            const paramsArray = [];
            keys.forEach(key => {
                const value = params[key];
                if (value && typeof value === 'object') {
                    const subkeys = Object.keys(value);
                    subkeys.forEach(subkey => {
                        if (value[subkey]) {
                            paramsArray.push(key.trim() + '[' + subkey.trim() + ']=' + encodeURIComponent(value[subkey].trim()));
                        }
                    });
                } else {
                    paramsArray.push(key.trim() + '=' + encodeURIComponent(value.trim()));
                }
            });
            return baseUrl + '?' + paramsArray.join('&');
        }

        return baseUrl;
    }

    private configureFuseLayout(): void {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
}

/** Différentes action (url) pour les comptes Stripe */
export enum StripeAccountAction {
    /** Création de compte */
    create = 'create',

    /** Visualisation de compte */
    see = 'see'
}

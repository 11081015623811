import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { NewsSegment, NewsSegmentList, NewsSegmentStats } from 'app/models/news/news-segment';
import { NewsSegmentPostInterface } from 'app/models/interfaces/post/news-segment-post.interface';
import { NewsSegmentDialogComponent, NewsSegmentDialogInterface } from 'app/main/shared/form-dialog/news-segment-dialog/news-segment-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class NewsSegmentsService {
    private _news: BehaviorSubject<NewsSegment[]> = new BehaviorSubject<NewsSegment[]>([]);

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public get(id: number): Observable<NewsSegment> {
        return this.http.get<NewsSegment>(this.config.baseUrl + 'api/news_segment/' + id.toString()).pipe(
            map(r => ObjectMapper.deserialize(NewsSegment, r)),
            tap(theNews => {
                const allNews = this._news.getValue();
                const theNewsIndex = allNews.findIndex(eg => eg.id === theNews.id);
                if (theNewsIndex !== -1) {
                    allNews[theNewsIndex] = theNews;
                }

                this._news.next(allNews);
            })
        );
    }

    public getAll(
        keywords: string = null,
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit: number = null,
        clinic_id: number = null
    ): Observable<NewsSegmentList> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        if (clinic_id) {
            params = params.append('clinic_id', clinic_id.toString());
        }

        return this.http.get<NewsSegmentList>(this.config.baseUrl + 'api/news_segments', {
            params: params
        }).pipe(
            map(list => ObjectMapper.deserialize(NewsSegmentList, list))
        );
    }

    public getStats(data: Partial<NewsSegmentPostInterface>): Observable<NewsSegmentStats> {
        return this.http.post<NewsSegmentStats>(this.config.baseUrl + 'api/news_segment/clients', data);
    }

    public add(data: NewsSegmentPostInterface): Observable<NewsSegment> {
        this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.ADDING'), null);
        data.id = 0;

        return this.http.post<NewsSegment>(this.config.baseUrl + 'api/news_segment', data).pipe(
            map(r => ObjectMapper.deserialize(NewsSegment, r)),
            tap(newNews => {
                const allNews = this._news.getValue();
                allNews.push(newNews);
                this._news.next(allNews);
                this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public update(data: NewsSegmentPostInterface): Observable<NewsSegment> {
        this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.UPDATING'), null);

        return this.http.put<NewsSegment>(this.config.baseUrl + 'api/news_segment/' + data.id.toString(), data).pipe(
            map(r => ObjectMapper.deserialize(NewsSegment, r)),
            tap(newNews => {
                const allNews = this._news.getValue();
                const newsIndex = allNews.findIndex(eg => eg.id === newNews.id);
                if (newsIndex !== -1) {
                    allNews[newsIndex] = newNews;
                }

                this._news.next(allNews);
                this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            })
        );
    }

    public delete(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('NEWS_SEGMENTS.API.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('NEWS_SEGMENTS.API.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('NEWS_SEGMENTS.API.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(confirmed => Boolean(confirmed)),
            switchMap(() => {
                this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.DELETING'), null);
                return this.http.delete(this.config.baseUrl + 'api/news_segment/' + id.toString())
                    .pipe(tap(() => {
                        this.snackbar.open(this.translateService.instant('NEWS_SEGMENTS.API.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    }));
            })
        );
    }

    public openAddOrEditDialog(data?: NewsSegmentDialogInterface): MatDialogRef<NewsSegmentDialogComponent> {
        return this.dialog.open(NewsSegmentDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }
}

import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReportDialogComponent } from './dialogs/report-dialog.component';
import { Observable, of } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { map } from 'rxjs/operators';
import { ReportComponent } from './report.component';
import { DocumentStatus } from '../../../models/rendez-vous/text-document';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<ReportComponent> {
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService) {}

    canDeactivate(component: ReportComponent): Observable<boolean> {
        if (component.clientReport.status !== DocumentStatus.Modified && component.vetReport.status !== DocumentStatus.Modified) {
            return of(true);
        }

        const modifiedReport: string[] = [];
        let content: string;

        if (component.clientReport.status === DocumentStatus.Modified) {
            modifiedReport.push('client');
        }

        if (component.vetReport.status === DocumentStatus.Modified) {
            modifiedReport.push('vet');
        }

        if (modifiedReport.length === 1) {
            if (modifiedReport[0] === 'client') {
                content = this.translateService.instant('REPORTS.GUARDS.REPORT_NOT_RECORDED_WARNING_CLIENT');
            } else if (modifiedReport[0] === 'vet') {
                content = this.translateService.instant('REPORTS.GUARDS.REPORT_NOT_RECORDED_WARNING_VET');
            }
        } else if (modifiedReport.length === 2) {
            content = this.translateService.instant('REPORTS.GUARDS.REPORT_NOT_RECORDED_WARNING_BOTH');
        }

        return this.dialog.open(ReportDialogComponent, {
            width: '700px',
            data: {
                title: this.translateService.instant('REPORTS.GUARDS.REPORT_NOT_RECORDED_TITLE'),
                content: content,
                confirm: this.translateService.instant('REPORTS.GUARDS.REPORT_NOT_RECORDED_CONFIRM'),
                reply: false
            },
            disableClose: true
        }).afterClosed().pipe(
            // tap(result => console.log(result)),
            map(result => !result)
        );
    }
}

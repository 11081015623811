import { Component, OnInit } from '@angular/core';
import { FuseConfigService } from '@fuse/services/config.service';
import { Veterinaire } from 'app/models/utilisateurs/veterinaire';
import { AuthService } from 'app/services/api/auth.service';
import { UtilisateurService } from 'app/services/api/utilisateur.service';

@Component({
  selector: 'app-deactivated-account',
  templateUrl: './deactivated-account.component.html',
  styleUrls: ['./deactivated-account.component.scss']
})
export class DeactivatedAccountComponent implements OnInit {
    utilisateur: Veterinaire;

    constructor(
        private readonly fuseConfigService: FuseConfigService,
        private readonly utilisateurService: UtilisateurService,
        private readonly authService: AuthService
    ) {
        this.configureFuseLayout();
    }

    ngOnInit(): void {
        this.loadUserInfos();
    }

    logout(): void {
        this.authService.logout();
    }

    private configureFuseLayout(): void {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    private loadUserInfos(): void {
        this.utilisateurService.getMe().subscribe(utilisateur => {
            this.utilisateur = utilisateur;
        });
    }
}

<div
    class="notifications-container"
    [matBadge]="(notifications.length - readIds.length).toString()"
    [matBadgeHidden]="notifications.length - readIds.length <= 0">
    <button
        mat-icon-button
        color="white"
        [matTooltip]="'NOTIFICATIONS.NEWS' | translate"
        [matTooltipPosition]="'below'"
        (click)="toggleVisibility()">
        <mat-icon>campaign</mat-icon>
    </button>

    <div @notifications class="notifications" *ngIf="isVisible">
        <mat-card>
            <div class="card-header" fxLayout="row" fxLayoutAlign="space-between center">
                <h3 class="card-title">{{ 'NOTIFICATIONS.NEWS' | translate }}</h3>
                <button mat-button color="accent" (click)="this.markAllAsRead()" [disabled]="notifications.length - readIds.length <= 0">{{ 'NOTIFICATIONS.MARK_ALL_AS_READ' | translate }}</button>
            </div>
            <div class="card-content" fxLayout="column" fxLayoutGap="16px">
                <div
                    id="notification-{{ notification.id }}"
                    class="notification"
                    *ngFor="let notification of notifications; trackBy: trackByFn"
                    (click)="openLink(notification)"
                    [class.notification-read]="readIds.includes(notification.id)">
                    <div class="notification-icon notification-cat-{{ notification.categories[0] }}"></div>
                    <div fxFlex>
                        <div class="notification-header">
                            <h4 class="notification-title">{{ notification.title }}</h4>
                            <span class="notification-date">{{ notification.date | dateLocale }}</span>
                        </div>
                        <p class="notification-content">{{ notification.content }}</p>
                    </div>
                </div>
            </div>
            <div class="card-action" fxLayout="row" fxLayoutAlign="center center">
                <a
                    href="{{ supportUrl }}news"
                    target="_blank"
                    mat-raised-button
                    color="primary">
                    {{ 'NOTIFICATIONS.ALL_NEWS' | translate }}
                </a>
            </div>
        </mat-card>
    </div>
</div>

<div *ngIf="!(loading$ | async); else loading" (click)="$event.stopPropagation()" fxLayout="column" class="px-16 py-4">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-8">
        <h3 class="m-0">{{ 'CHAT_FILTER.TITLE' | translate }}</h3>
        <button mat-icon-button (click)="clear()" [matTooltip]="'CHAT_FILTER.RESET_DESCRIPTION' | translate"><mat-icon>delete_forever</mat-icon></button>
    </div>

    <mat-form-field appearance="fill" class="no-padding mb-8 status-container">
        <mat-label>{{ 'CHAT_FILTER.STATUS' | translate }}</mat-label>
        <input matInput ngModel/>
        <mat-radio-group [formControl]="form.get('status') | asFormControl" fxLayout="column" fxLayoutGap="4px">
            <mat-radio-button value="open">{{ 'CHAT_FILTER.STATUS_OPEN' | translate }}</mat-radio-button>
            <mat-radio-button value="close">{{ 'CHAT_FILTER.STATUS_CLOSE' | translate }}</mat-radio-button>
            <mat-radio-button value="futur" [matTooltip]="'CHAT_FILTER.STATUS_FUTUR_DESCRIPTION' | translate">{{ 'CHAT_FILTER.STATUS_FUTUR' | translate }}</mat-radio-button>
        </mat-radio-group>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-padding mb-8 status-container">
        <mat-label>{{ 'CHAT.BOOKMARK.FILTER.LABEL' | translate }}</mat-label>
        <input matInput ngModel/>
        <mat-radio-group [formControl]="form.get('bookmarked') | asFormControl" fxLayout="column" fxLayoutGap="4px">
            <mat-radio-button value="true">{{ 'CHAT.BOOKMARK.FILTER.ONLY' | translate }}</mat-radio-button>
            <mat-radio-button value="false">{{ 'CHAT.BOOKMARK.FILTER.INDIFFERENT' | translate }}</mat-radio-button>
        </mat-radio-group>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-padding mb-8">
        <mat-label>{{ 'CHAT_FILTER.CATEGORIES' | translate }}</mat-label>

        <mat-chip-list #chipListCategories>
            <mat-chip
                *ngIf="!((form.get('categories').valueChanges | async)?.length > 0)"
                [selectable]="false"
                [removable]="false">
                {{ 'CHAT_FILTER.CATEGORIES_ALL' | translate }}
            </mat-chip>
            <mat-chip
                *ngFor="let category of (form.get('categories').valueChanges) | async"
                [selectable]="true"
                [removable]="true"
                (removed)="removeCategory(category)">
                {{ category.value }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-select #categorieSelect ngModel (ngModelChange)="addCategory(categorieSelect, $event)">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="categoriesFilter"
                        [placeholderLabel]="'CHAT_FILTER.CATEGORIES_SEARCH' | translate"
                        [noEntriesFoundLabel]="'CHAT_FILTER.CATEGORIES_ANY_FOUND' | translate"
                        [searching]="categoryMatSelectSearch">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!categoriesFilter.value" [value]="'all'">
                    {{ 'CHAT_FILTER.CATEGORIES_ALL' | translate }}
                </mat-option>
                <mat-option *ngFor="let categorie of filteredCategoriesOptions$ | async" [value]="categorie">
                    {{ categorie.value }}
                </mat-option>
            </mat-select>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-padding mb-8">
        <mat-label>{{ 'CHAT_FILTER.COLORS' | translate }}</mat-label>

        <mat-chip-list #chipListColors>
            <mat-chip
                *ngIf="!((form.get('colors').valueChanges | async)?.length > 0)"
                [selectable]="false"
                [removable]="false">
                {{ 'CHAT_FILTER.COLORS_ALL' | translate }}
            </mat-chip>
            <mat-chip
                *ngFor="let color of (form.get('colors').valueChanges) | async"
                [selectable]="true"
                [removable]="true"
                (removed)="removeColor(color)">
                <span class="color" [style.backgroundColor]="color"></span>
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-select #colorSelect ngModel (ngModelChange)="addColor(colorSelect, $event)">
                <mat-option *ngFor="let color of colors" [value]="color">
                    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
                        <span fxFlex class="color-selector" [style.backgroundColor]="color"></span>
                        <span fxFlex *ngIf="color==='none'">{{ 'CHAT_FILTER.COLORS_NONE' | translate }}</span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-chip-list>
    </mat-form-field>


    <mat-form-field appearance="fill" class="no-padding mb-8">
        <mat-label>{{ 'CHAT_FILTER.VETERINARIES' | translate }}</mat-label>

        <mat-chip-list #chipListVeterinaries>
            <mat-chip
                *ngIf="!((form.get('veterinaries').valueChanges | async)?.length > 0)"
                [selectable]="false"
                [removable]="false">
                {{ 'CHAT_FILTER.VETERINARIES_ALL' | translate }}
            </mat-chip>
            <mat-chip
                *ngFor="let veterinary of (form.get('veterinaries').valueChanges) | async"
                [selectable]="true"
                [removable]="true"
                (removed)="removeVeterinary(veterinary)">
                {{ isMe(veterinary) ? ('CHAT_FILTER.VETERINARIES_MINE' | translate) : veterinary.fullNameLite }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-select #veterinariesSelect ngModel (ngModelChange)="addVeterinary(veterinariesSelect, $event)">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="veterinariesFilter"
                        [placeholderLabel]="'CHAT_FILTER.VETERINARIES_SEARCH' | translate"
                        [noEntriesFoundLabel]="'CHAT_FILTER.VETERINARIES_ANY_FOUND' | translate"
                        [searching]="veterinaryMatSelectSearch">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!veterinariesFilter.value" [value]="'all'">
                    {{ 'CHAT_FILTER.VETERINARIES_ALL' | translate }}
                </mat-option>
                <mat-option *ngIf="!veterinariesFilter.value" [value]="'mine'">
                    {{ 'CHAT_FILTER.VETERINARIES_MINE' | translate }}
                </mat-option>
                <mat-option *ngFor="let veterinary of filteredVeterinariesOptions$ | async" [value]="veterinary">
                    {{ veterinary.fullNameLite }}
                </mat-option>
            </mat-select>
        </mat-chip-list>
    </mat-form-field>

    <mat-form-field appearance="fill" class="no-padding">
        <mat-label>{{ 'CHAT_FILTER.CLIENTS' | translate }}</mat-label>

        <mat-chip-list #chipListClients>
            <mat-chip
                *ngIf="!((form.get('clients').valueChanges | async)?.length > 0)"
                [selectable]="false"
                [removable]="false">
                {{ 'CHAT_FILTER.CLIENTS_ALL' | translate }}
            </mat-chip>
            <mat-chip
                *ngFor="let client of (form.get('clients').valueChanges) | async"
                [selectable]="true"
                [removable]="true"
                (removed)="removeClient(client)">
                {{ client.fullNameLite }}<mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>

            <mat-select #clientsSelect ngModel (ngModelChange)="addClient(clientsSelect, $event)">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="clientsFilter"
                        [placeholderLabel]="'CHAT_FILTER.CLIENTS_SEARCH' | translate"
                        [noEntriesFoundLabel]="'CHAT_FILTER.CLIENTS_ANY_FOUND' | translate"
                        [searching]="clientMatSelectSearch">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngIf="!clientsFilter.value"  [value]="'all'">
                    {{ 'CHAT_FILTER.CLIENTS_ALL' | translate }}
                </mat-option>
                <mat-option *ngFor="let client of filteredClientsOptions$ | async" [value]="client">
                    {{ client.fullNameLite }}
                </mat-option>
            </mat-select>
        </mat-chip-list>
    </mat-form-field>
</div>
<ng-template #loading>
    <app-loading class="p-16"></app-loading>
</ng-template>

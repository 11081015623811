<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ data$.value ? ('NEWS.EDIT' | translate) : ('NEWS.NEW' | translate) }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <app-news-form
                [entityData]="data$"
                (formSubmited)="this.close($event)">
            </app-news-form>
        </ng-template>
    </div>
</div>

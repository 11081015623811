import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Fichier } from '../../../models/fichier';
import { Animal } from '../../../models/animal/animal';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FichierService } from 'app/services/api/fichier.service';
import { Utils } from '../../../utils';
import { FilePathPipe } from 'app/pipes/file-path.pipe';
import { ConfigService } from 'app/services/config.service';
import { AnimalService } from 'app/services/api/animal.service';
import { DateLocalePipe } from 'app/pipes/date-locale.pipe';
import { TranslateService } from '@ngx-translate/core';
import {
    EditPhotoDialogComponent,
    EditPhotoDialogData
} from 'app/main/shared/view-utils/edit-photo-dialog/edit-photo-dialog.component';
import { RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { photoSwipeConfig } from 'app/config';
import { GalleryOptions } from '@twogate/ngx-photo-gallery/lib/interfaces/photoswipe';

@Component({
    selector: 'app-list-fichier',
    templateUrl: './list-fichier.component.html',
    styleUrls: ['./list-fichier.component.scss']
})
export class ListFichierComponent implements OnInit, OnDestroy {
    @Input() input: Animal | RendezVous | ListFichierIndex;
    @Input() displayAddFile = true;
    @Input() displayScrollDay = true;
    @Input() displayStat = true;
    @Input() displayAction = true;
    @Input() displayLoading = true;
    @Input() sortBy: FichierSortBy = FichierSortBy.DATE;
    @Input() groupBy: FichierGroupBy = FichierGroupBy.DATE;
    @Input() selectionMode: FichierSelectionMode = FichierSelectionMode.MULTI;
    @Input() paginationSize = 20;
    @Input() refresh: Observable<void>;

    @Output() selectedFichiersChanged = new EventEmitter<Fichier | Fichier[]>();
    @Output() fichiersChanged = new EventEmitter<Fichier[]>();
    @Output() loadingStateChanged = new EventEmitter<boolean>(true);

    isLoading = true;
    isLoadingMore = false;
    canLoadMore = false;
    isSending = false;

    listSortStrings$ = new BehaviorSubject<string[]>(['']);
    fichiers$ = new BehaviorSubject<{ [id: string]: FichierIndex[] }>({});
    selectedFiles: FichierIndex[] = [];

    stats = { AUDIO: 0, OTHER: 0, PICTURE: 0, VIDEO: 0 };
    dateTagText = '';
    dateTagPositionY = '0px';

    photoGalleryConfig: GalleryOptions;

    private flatFichiers$ = new BehaviorSubject<FichierIndex[]>([]);
    private currentOffset = 0;
    private nbCurrentFiles = 0;
    private datePipe: DateLocalePipe;
    private subscriptionScroll: Subscription;
    private subscriptionScrollEnd: Subscription;

    constructor(
        public dialog: MatDialog,
        private configService: ConfigService,
        private fichierService: FichierService,
        private animalService: AnimalService,
        private rendezVousService: RendezVousService,
        private translateService: TranslateService
    ) {
        this.dateTagText = this.translateService.instant('CALENDAR.TODAY');
        this.datePipe = new DateLocalePipe(this.translateService);
        this.flatFichiers$.subscribe(fichiers => {
            this.nbCurrentFiles += fichiers.length;
            this.fichiersChanged.emit(fichiers.map(f => f.fichier));

            this.fichiers$.next(Utils.groupBy<FichierIndex>(fichiers, el => el.groupByValue));

            const keys = Object.keys(this.fichiers$.value);
            this.listSortStrings$.next(keys.length > 0 ? keys : ['']);

            this.computeIndexes();
            this.isLoading = false;
            this.isLoadingMore = false;
            this.loadingStateChanged.emit(false);
        });
        this.photoGalleryConfig = photoSwipeConfig(this.translateService);
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.loadingStateChanged.emit(true);
        this.loadFiles();

        if (this.refresh) {
            this.refresh.subscribe(() => {
                this.isLoading = true;
                this.loadingStateChanged.emit(true);
                this.loadFiles();
            });
        }
    }

    loadMoreFiles(): void {
        if (!this.isLoading && this.canLoadMore && !this.isLoadingMore) {
            this.currentOffset += this.paginationSize;
            this.isLoadingMore = true;
            this.loadFiles();
        }
    }

    ngOnDestroy(): void {
        if (this.subscriptionScroll) {
            this.subscriptionScroll.unsubscribe();
        }

        if (this.subscriptionScrollEnd) {
            this.subscriptionScrollEnd.unsubscribe();
        }
    }

    openFile(fichier: FichierIndex): void {
        this.fichierService.openOrDownload(fichier.fichier, '.file-content', fichier.index);
    }

    addFile(fichier: Fichier): void {
        this.statsFromFile(fichier, true);
        const fileIndex = this.createFileIndexFromFile(fichier);
        const fichiers = this.flatFichiers$.value;
        fichiers.unshift(fileIndex);
        this.flatFichiers$.next(fichiers);
        this.nbCurrentFiles++;
    }

    removeFile(fichier: FichierIndex): void {
        this.fichierService.delete(fichier.fichier.id).subscribe(() => {
            this.removeFileRefresh(fichier);
        });
    }

    private removeFileRefresh(fichier: FichierIndex): void {
        const fichiers = this.flatFichiers$.value;
        const index = fichiers.findIndex(f => f.fichier.id === fichier.fichier.id);
        if (index !== -1) {
            this.statsFromFile(fichier.fichier, false);
            fichiers.splice(index, 1);
            this.flatFichiers$.next(fichiers);
            this.nbCurrentFiles--;
        }
    }

    downloadFile(fichier: FichierIndex): void {
        this.fichierService.downloadFile(fichier.fichier);
    }

    downloadFiles(): void {
        if (this.selectedFiles.length > 0) {
            this.fichierService.zip(this.selectedFiles.map(f => f.fichier.id)).subscribe();
        }
    }

    selectAllDay(sortByString: string = null): void {
        const fichiers = this.fichiers$.getValue();
        if (sortByString) {
            for (const fileIndex of fichiers[sortByString]) {
                this.select(fileIndex);
            }
        } else {
            for (const dateStringIndex of Object.keys(fichiers)) {
                for (const fileIndex of fichiers[dateStringIndex]) {
                    this.select(fileIndex);
                }
            }
        }

        this.fichiers$.next(fichiers);
    }

    editImage(fichierIndex: FichierIndex): void {
        if (this.input instanceof Animal || this.input instanceof RendezVous) {
            const fileUrl = new FilePathPipe(this.configService).transform(fichierIndex.fichier.url) as string;
            const data: EditPhotoDialogData = {
                url: fileUrl
            };
            const editPhotoModel = this.dialog.open(EditPhotoDialogComponent, {
                data,
                panelClass: 'fullscreen-dialog',
                width: '60vw',
                height: '80vh',
                disableClose: true
            }).afterClosed();

            if (this.input instanceof Animal || this.input instanceof RendezVous) {
                editPhotoModel.subscribe((result: File) => {
                    if (result) {
                        this.isSending = true;
                        let req: Observable<Fichier>;

                        if (this.input instanceof Animal) {
                            req = this.animalService.uploadFile(this.input.id, result);
                        } else if (this.input instanceof RendezVous) {
                            req = this.rendezVousService.uploadFile(this.input.id, result);
                        }

                        req.subscribe({
                            next: resultFile => this.addFile(resultFile),
                            complete: () => {
                                this.isSending = false;
                            }
                        });
                    }
                });
            }
        }
    }

    toggleSelect(file: FichierIndex): void {
        const index = this.selectedFiles.findIndex(f => f.fichier.id === file.fichier.id);
        switch (this.selectionMode) {
            case FichierSelectionMode.SINGLE_INSTANT:
            case FichierSelectionMode.SINGLE:
                this.selectedFiles.forEach(element => {
                    element.isSelected = false;
                });
                file.isSelected = true;
                this.selectedFiles = [file];
                this.selectedFichiersChanged.emit(file.fichier);
                break;
            case FichierSelectionMode.MULTI:
                if (index === -1) {
                    // Select
                    this.selectedFiles.push(file);
                    file.isSelected = true;
                } else {
                    // Deselect
                    this.selectedFiles.splice(index, 1);
                    file.isSelected = false;
                }

                this.selectedFichiersChanged.emit(this.selectedFiles.map((f: FichierIndex) => f.fichier));
                break;
            default:
                break;
        }
    }

    select(file: FichierIndex): void {
        const index = this.selectedFiles.findIndex(f => f.fichier.id === file.fichier.id);
        switch (this.selectionMode) {
            case FichierSelectionMode.SINGLE_INSTANT:
            case FichierSelectionMode.SINGLE:
                this.selectedFiles.forEach(element => {
                    element.isSelected = false;
                });
                file.isSelected = true;
                this.selectedFiles = [file];
                this.selectedFichiersChanged.emit(file.fichier);
                break;
            case FichierSelectionMode.MULTI:
                if (index === -1) {
                    this.selectedFiles.push(file);
                    file.isSelected = true;
                }

                this.selectedFichiersChanged.emit(this.selectedFiles.map((f: FichierIndex) => f.fichier));
                break;
            default:
                break;
        }
    }

    deselectAll(): void {
        while (this.selectedFiles.length > 0) {
            this.selectedFiles.pop().isSelected = false;
        }

        this.selectedFichiersChanged.emit(this.selectedFiles.map((f: FichierIndex) => f.fichier));
    }

    removeFiles(): void {
        if (this.selectedFiles.length > 0) {
            this.fichierService.deleteMassif(this.selectedFiles.map(f => f.fichier.id)).subscribe(() => {
                for (const fichierIndex of this.selectedFiles) {
                    this.removeFileRefresh(fichierIndex);
                }

                this.selectedFiles.length = 0;
            });
        }
    }

    loadFiles(): void {
        if (this.input instanceof Animal) {
            this.animalService.getFichiers(this.input.id, this.currentOffset, this.paginationSize).subscribe({
                next: result => {
                    this.stats.PICTURE = result.stats?.image ? Number(result.stats.image) : 0;
                    this.stats.AUDIO = result.stats?.audio ? Number(result.stats.audio) : 0;
                    this.stats.VIDEO = result.stats?.video ? Number(result.stats.video) : 0;
                    this.stats.OTHER = (result.meta ? Number(result.meta.totalItems) : 0) - Number(this.stats.VIDEO) - Number(this.stats.AUDIO) - Number(this.stats.PICTURE);

                    const flatNewFichierIndex = result.data.map((fichier: Fichier) => this.createFileIndexFromFile(fichier));
                    this.flatFichiers$.next([...flatNewFichierIndex, ...this.flatFichiers$.getValue()].sort((a, b) => a.sortByValue < b.sortByValue ? 1 : -1));

                    this.canLoadMore = (result.meta ? Number(result.meta.totalItems) : this.nbCurrentFiles) > this.nbCurrentFiles;
                },
                error: _ => {
                    this.isLoading = false;
                    this.isLoadingMore = false;
                    this.loadingStateChanged.emit(false);
                }
            });
        } else if (this.input instanceof RendezVous) {
            this.rendezVousService.getFichiers(this.input.id).subscribe({
                next: (result: Fichier[]) => {
                    this.flatFichiers$.next(result.map((fichier: Fichier) => this.createFileIndexFromFile(fichier)).sort((a, b) => a.sortByValue < b.sortByValue ? 1 : -1));
                },
                error: () => {
                    this.isLoading = false;
                    this.isLoadingMore = false;
                    this.loadingStateChanged.emit(false);
                }
            });
        } else {
            this.flatFichiers$.next(this.input.fichiers ? this.input.fichiers.sort((a, b) => a.sortByValue < b.sortByValue ? 1 : -1) : []);
        }
    }

    private statsFromFile(fichier: Fichier, add: boolean): void {
        if (fichier.isImage()) {
            this.stats.PICTURE += add ? 1 : -1;
        } else if (fichier.isVideo()) {
            this.stats.VIDEO += add ? 1 : -1;
        } else if (fichier.isAudio()) {
            this.stats.AUDIO += add ? 1 : -1;
        } else {
            this.stats.OTHER += add ? 1 : -1;
        }
    }

    private createFileIndexFromFile(fichier: Fichier): FichierIndex {
        let sortByValue: string | number | Date = null;
        let groupByValue: string | number = null;
        let groupByName = '';

        switch (this.sortBy) {
            case FichierSortBy.DATE:
                sortByValue = fichier.date;
                break;
            case FichierSortBy.ANIMAL:
                sortByValue = this.input instanceof Animal ? this.input.nom : '';
                break;
            case FichierSortBy.OWNER:
                sortByValue = this.input instanceof Animal && Boolean(this.input.proprietaire) ? this.input.proprietaire.fullName : '';
                break;
            case FichierSortBy.NONE:
            default:
                sortByValue = 0;
                break;
        }

        switch (this.groupBy) {
            case FichierGroupBy.DATE:
                groupByValue = this.datePipe.transform(fichier.date, 'dd MMMM yyy');
                groupByName = this.datePipe.transform(fichier.date, 'dd MMMM yyy');
                break;
            case FichierGroupBy.ANIMAL:
                groupByValue = this.input instanceof Animal ? this.input.id : -1;
                groupByName = this.input instanceof Animal ? this.input.nom : '';
                break;
            case FichierGroupBy.OWNER:
                groupByValue = this.input instanceof Animal && Boolean(this.input.proprietaire) ? this.input.proprietaire.id : -1;
                groupByName = this.input instanceof Animal && Boolean(this.input.proprietaire) ? this.input.proprietaire.fullName : '';
                break;
            case FichierGroupBy.NONE:
            default:
                groupByValue = 0;
                groupByName = '';
                break;
        }

        const fichierIndex: FichierIndex = {
            sortByValue: sortByValue,
            groupByValue: groupByValue,
            groupByName: groupByName,
            fichier: fichier
        };

        return fichierIndex;
    }

    private computeIndexes(): void {
        let index = 0;
        Object.values(this.fichiers$.getValue()).forEach((fichierIndexes: FichierIndex[]) => {
            fichierIndexes.forEach((fichierIndex: FichierIndex) => {
                if (!fichierIndex.fichier.isOther()) {
                    fichierIndex.index = index;
                    index++;
                }
            });
        });
    }

    openNewFileDialog(): void {
        if (!this.isSending && (this.input instanceof Animal || this.input instanceof RendezVous)) {
            this.fichierService.openFileDropDialog().subscribe((files: File[]) => {
                if (files && files.length > 0) {
                    let nbFiles = files.length;

                    if (this.input instanceof Animal) {
                        this.isSending = true;

                        files.forEach(file => {
                            this.animalService.uploadFile((this.input as Animal).id, file).subscribe({
                                next: result => {
                                    nbFiles--;
                                    if (nbFiles === 0) {
                                        this.isSending = false;
                                    }

                                    this.addFile(result);
                                },
                                error: () => {
                                    nbFiles--;
                                    if (nbFiles === 0) {
                                        this.isSending = false;
                                    }
                                }
                            });
                        });
                    } else if (this.input instanceof RendezVous) {
                        files.forEach(file => {
                            this.rendezVousService.uploadFile((this.input as RendezVous).id, file).subscribe({
                                next: result => {
                                    nbFiles--;
                                    if (nbFiles === 0) {
                                        this.isSending = false;
                                    }

                                    this.addFile(result);
                                },
                                error: () => {
                                    nbFiles--;
                                    if (nbFiles === 0) {
                                        this.isSending = false;
                                    }
                                }
                            });
                        });
                    }
                }
            });
        }
    }

    trackByFn(index: number): number {
        return index;
    }

    setDateTagAtPosition(position: number): void {
        const elements = document.querySelectorAll('.date-day');
        const allDates: HTMLElement[] = Array.prototype.slice.call(elements);
        for (let index = 0; index < allDates.length; index++) {
            const element = allDates[index];
            const previousElement = allDates[index - 1];
            if (position < element.offsetTop) {
                if (previousElement) {
                    this.dateTagText = previousElement.textContent.replace('check_circle_outline', '');
                } else {
                    this.dateTagText = this.translateService.instant('CALENDAR.TODAY');
                }

                break;
            }
        }

        this.dateTagPositionY = position.toString() + 'px';
    }
}

export enum FichierSelectionMode {
    SINGLE = 'SINGLE',
    SINGLE_INSTANT = 'SINGLE_INSTANT',
    MULTI = 'MULTI',
    NONE = 'NONE'
}

export enum FichierSortBy {
    DATE = 'DATE',
    ANIMAL = 'ANIMAL',
    OWNER = 'OWNER',
    NONE = 'NONE'
}

export enum FichierGroupBy {
    DATE = 'DATE',
    ANIMAL = 'ANIMAL',
    OWNER = 'OWNER',
    NONE = 'NONE'
}

export interface FichierIndex {
    fichier: Fichier;
    sortByValue: string | number | Date;
    groupByValue: string | number;
    groupByName: string;
    index?: number;
    isSelected?: boolean;
}

export interface ListFichierIndex {
    fichiers: FichierIndex[];
    totalItems: number;
}

import { Pipe, PipeTransform } from '@angular/core';
import { BytesPipe } from 'angular-pipes';
import { ByteUnit } from 'angular-pipes/math/bytes.pipe';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(input: string | number, decimal?: number, from?: ByteUnit, to?: ByteUnit): string {
        if (input) {
            const val: string = new BytesPipe().transform(input, decimal, from, to) as string;
            return val.replace('B', this.translateService.instant('SHARED.FILESIZE.B') as string);
        }

        return '0';
    }
}

<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">

    <div *ngIf="entityLinked" class="mb-16 text-bold warn-fg">
        {{ 'CONNECTOR.ENTITY_LINKED' | translate }}
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <app-photo-profil-form *ngIf="(entityData$ | async)?.id" class="mt-8 mr-16 mb-16" [entityData]="entityData$"
                               [imageEvent]="imageEvent$" [enablePhotoForm]="enablePhotoForm"
                               [photoInProgress]="photoInProgress$"
                               [photoDelInProgress]="photoDelInProgress$" (photoSubmited)="submitPhoto($event)"
                               fxFlex="0 0 0">
        </app-photo-profil-form>

        <mat-form-field appearance="outline" fxFlex="1 1 100%">
            <mat-label>{{ 'PET_DESC.NAME' | translate }}</mat-label>
            <input matInput formControlName="nom" [placeholder]="'FORM.NAME' | translate" required>
            <mat-error *ngIf="form.get('nom').hasError('required')">
                {{ 'FORM.NAME_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="20px" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start stretch" fxLayoutGap.lt-sm="0px">
        <mat-form-field appearance="outline" fxFlex="1 0 auto">
            <mat-label>{{ 'PET_DESC.SPECIES' | translate }}</mat-label>
            <mat-select [placeholder]="'PET_DESC.SPECIES' | translate" formControlName="espece" required #espece>
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="especeFilter"
                        [placeholderLabel]="'PET_DESC.SPECIES_SEARCH' | translate"
                        [noEntriesFoundLabel]="'PET_DESC.SPECIES_ANY_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let espece of filteredEspecesOptions$ | async" [value]="espece.id">
                    {{ espece.nom }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('espece').hasError('required')">
                {{ 'PET_DESC.SPECIES_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 auto">
            <mat-label>{{ 'PET_DESC.BREED' | translate }}</mat-label>
            <mat-select placeholder="{{ 'PET_DESC.BREED' | translate }}" formControlName="race">
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="raceFilter"
                        [placeholderLabel]="'PET_DESC.BREED_SEARCH' | translate"
                        [noEntriesFoundLabel]="'PET_DESC.BREED_ANY_FOUND' | translate">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let race of filteredRacesOptions$ | async" [value]="race.id">
                    {{ race.nom }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="center start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 0 auto">
            <mat-label>{{ 'PET_DESC.DATE_BIRTH' | translate }}</mat-label>
            <input [max]="now" matInput [matDatepicker]="dateNaissancePicker"
                   [placeholder]="'PET_DESC.DATE_BIRTH' | translate"
                   formControlName="dateNaissance" readonly
                   (focus)="dateNaissancePicker.open()" (click)="dateNaissancePicker.open()">
            <mat-datepicker-toggle matSuffix [for]="dateNaissancePicker"></mat-datepicker-toggle>
            <mat-datepicker #dateNaissancePicker startView="multi-year"></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 auto" *ngIf="showDateDeces">
            <mat-label>{{ 'PET_DESC.DATE_DEATH' | translate }}</mat-label>
            <input [max]="now" matInput [matDatepicker]="dateDecesPicker"
                   [placeholder]="'PET_DESC.DATE_DEATH' | translate"
                   formControlName="dateDeces"
                   (focus)="dateDecesPicker.open()" (click)="dateDecesPicker.open()">
            <mat-datepicker-toggle matSuffix [for]="dateDecesPicker"></mat-datepicker-toggle>
            <mat-datepicker #dateDecesPicker startView="multi-year"></mat-datepicker>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-evenly" fxLayoutGap="16px" class="mb-16">
        <mat-radio-group formControlName="sexe" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="8px">
            <mat-radio-button value="m">{{ 'PET_DESC.MALE' | translate }}</mat-radio-button>
            <mat-radio-button value="f">{{ 'PET_DESC.FEMALE' | translate }}</mat-radio-button>
<!--            <mat-radio-button value="n">{{ 'PET_DESC.NEUTRAL' | translate }}</mat-radio-button>-->
            <mat-radio-button [value]="null">{{ 'PET_DESC.SEX_UNDETERMINED' | translate }}</mat-radio-button>
        </mat-radio-group>

        <mat-slide-toggle formControlName="sterilise">
            {{ form.get('sexe').value == 'f' ? ('PET_DESC.STERILIZED_FEMALE' | translate) : ('PET_DESC.STERILIZED_MALE' | translate) }}
            ?
        </mat-slide-toggle>
    </div>

    <app-input-connector *ngIf="form.get('id').value && (connecteurService.isConnecteurFeatureEnabled('ANIMAL') | async)" [control]="form.get('connectorId')"></app-input-connector>

    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px" class="mr-8">
            <button *ngIf="form.get('id').value" mat-raised-button color="primary" type="button" (click)="createRdv()">
                <mat-icon>add</mat-icon>
                {{ 'CALENDAR.DATE_ADD' | translate }}
            </button>
            <button *ngIf="form.get('id').value" mat-raised-button color="primary" type="button"
                    (click)="createTchat()">
                <mat-icon>chat</mat-icon>
                {{ 'CHAT.ADD' | translate }}
            </button>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px" fxFlex="1 1 auto" *ngIf="!entityLinked">
            <button *ngIf="showSaveButton" type="submit" mat-raised-button color="accent" [disabled]="(formInProgress$ | async)">
                <mat-icon>save</mat-icon>
                {{ 'FORM.SAVE' | translate }}
            </button>
            <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                         *ngIf="formInProgress$ | async"></mat-spinner>
        </div>
    </div>
</form>

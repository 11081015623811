import { Pipe, PipeTransform } from '@angular/core';
import { CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';
import { UtilisateurService } from '../services/api/utilisateur.service';

@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    constructor(private utilisateurService: UtilisateurService) {}

    transform(value: string, defaultCountry?: CountryCode): any {
        if (!value) {
            return value;
        }

        if (!defaultCountry) {
            defaultCountry = this.utilisateurService.utilisateurConnectedValue?.entiteGeographique?.pays.toUpperCase() as CountryCode;
        }

        try {
            return parsePhoneNumberWithError(value, defaultCountry).formatNational();
        } catch {
            return value;
        }
    }
}

import { Injectable } from '@angular/core';
import { ConfigService } from '../config.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IdsPostInterface } from '../../models/interfaces/post/ids-post.interface';
import { Utils } from '../../utils';
import { Fichier } from '../../models/fichier';
import { FilePathPipe } from '../../pipes/file-path.pipe';
import Compressor from 'compressorjs';
import {
    DocumentSelectorDialogComponent,
    DocumentSelectorDialogInterface,
    SelectorType
} from '../../main/shared/view-utils/document-selector-dialog/document-selector-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class FichierService {
    public static readonly MAX_FILE_SIZE = 4;
    public static readonly MAX_WIDTH = 1440;
    public static readonly MAX_HEIGHT = 1440;

    constructor(
        private translateService: TranslateService,
        private configService: ConfigService,
        private http: HttpClient,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public openFileDropDialog(settings: DocumentSelectorDialogInterface = documentSelectorDialogDefault): Observable<File[]> {
        return this.dialog.open(DocumentSelectorDialogComponent, {
            maxHeight: '80vh',
            width: '50vw',
            panelClass: 'document-selector-dialog',
            disableClose: true,
            data: settings
        }).afterClosed() as Observable<File[]>;
    }

    public compressAndResize(image: File | Blob, options: Compressor.Options = compressorOptionsDefault): Observable<File | Blob> {
        return new Observable<Blob>(observer => {
            options.success = (compressed: Blob) => {
                if ((typeof image as any).name === 'string') {
                    const fileImage: File = image as any;
                    observer.next(new File([compressed], fileImage.name, {
                        lastModified: fileImage.lastModified,
                        type: compressed.type
                    }));
                } else {
                    observer.next(compressed);
                }

                observer.complete();
            };

            options.error = (error: Error) => {
                compressor?.abort();
                observer.error(error);
            };

            const compressor = new Compressor(image, options);
        });
    }

    public openOrDownload(fichier: Fichier, selector: string, index: number): void {
        if (fichier.isOther()) {
            this.downloadFile(fichier);
            return;
        }

        this.openFileInModal(selector, index);
    }

    public downloadFile(fichier: Fichier): void {
        const fileUrl = new FilePathPipe(this.configService).transform(fichier.url) as string;
        window.open(fileUrl, '_blank');
    }

    /**
     * Ouvre un fichier
     * Dans une modal ou un nouvel onglet suivant le type
     *
     * @param {string} selector Selecteur css des éléments html à utiliser pour les slides
     * @param {number} [index=0] L'index du fichier a ouvrir
     * @param {string} replaceUrl Permet de remplacer l'URL de l'élèment affiché
     * @param {number} width Permet de remplacer la largeur de l'image affichée
     * @param {number} height Permet de remplacer la longueur de l'image affichée
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public openFileInModal(selector: string, index = 0, replaceUrl: string = null, width: number = null, height: number = null): void {
        // TODO: Enlever cette méthode
        // console.log(selector, index, replaceUrl, width, height);
    }

    /**
     * Supprime un fichier
     *
     * @param {number} id L'id du fichier a supprimer
     * @returns {Observable<any>} 200 si ok, erreur sinon
     */
    public delete(id: number): Observable<any> {
        const dialogPhotoSwipe = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('FILES.DIALOG.DELETE.TITLE'),
                content: this.translateService.instant('FILES.DIALOG.DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('FILES.DIALOG.OK')
            },
            disableClose: true
        });

        return dialogPhotoSwipe.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('FILES.DELETING'), null);
            return this.http.delete(this.configService.baseUrl + 'api/fichier/' + id.toString())
                .pipe(tap(() => {
                    this.snackbar.open(this.translateService.instant('FILES.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
        }));
    }

    /**
     * Supprimes des fichiers
     *
     * @param {number[]} ids Les ids des fichiers a supprimer
     * @returns {Observable<any>} 200 si ok, erreur sinon
     */
    public deleteMassif(ids: number[]): Observable<any> {
        const dialogPhotoSwipe = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('FILES.DIALOG.DELETE_ALL.TITLE'),
                content: this.translateService.instant('FILES.DIALOG.DELETE_ALL.CONTENT', {
                    nb: ids.length
                }),
                action: true,
                ok: this.translateService.instant('FILES.DIALOG.OK')
            },
            disableClose: true
        });

        return dialogPhotoSwipe.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('FILES.DELETING_ALL'), null);
            const body: IdsPostInterface = {
                ids: ids
            };
            return this.http.request('DELETE', this.configService.baseUrl + 'api/fichiers', {
                body
            }).pipe(tap(() => {
                this.snackbar.open(this.translateService.instant('FILES.DELETED_ALL'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
        }));
    }

    /**
     * Zip des fichiers
     *
     * @param {number[]} ids Les ids des fichiers a zipper
     * @returns {Observable<any>} 201 si ok, erreur sinon
     */
    public zip(ids: number[]): Observable<any> {
        this.snackbar.open(this.translateService.instant('FILES.ZIPPING'), null);
        return this.http.post(this.configService.baseUrl + 'api/fichiers/zip', {
            ids: ids
        } as IdsPostInterface, {
            responseType: 'blob'
        }).pipe(tap(zip => {
            this.snackbar.open(this.translateService.instant('FILES.ZIPPED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            Utils.downloadFile(zip);
        }));
    }

    public getBlobFromFichier(fichier: Fichier): Observable<File> {
        return this.http.get(`${this.configService.baseUrl}api/pass_through?url=${this.configService.baseUrl + fichier.url}`, {
            responseType: 'blob'
        }).pipe(map(blob => {
            const file: any = blob;
            file.lastModified = fichier.date;
            file.name = fichier.nom;
            return file as File;
        }));
    }
}

const documentSelectorDialogDefault: DocumentSelectorDialogInterface = {
    enableCropper: true,
    forceCropper: false,
    selectorType: SelectorType.ANY,
    selectorMultiple: true,
    settings: {
        rounded: false
    }
};

const compressorOptionsDefault: Compressor.Options = {
    maxHeight: 1440,
    maxWidth: 1400,
    quality: 0.8
};

import { NgModule } from '@angular/core';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { UserFullNamePipe } from './user-full-name.pipe';
import { EntityEmojiPipe } from './entity-emoji.pipe';
import { EmptyFieldPipe } from './empty-field.pipe';
import { FilePathPipe } from './file-path.pipe';
import { PhonePipe } from './phone.pipe';
import { TimeDurationPipe } from './time-duration.pipe';
import { ValuesPipe } from './values.pipe';
import { ChatMessagePipe } from './chat/chat-message.pipe';
import { FileSizePipe } from './file-size.pipe';
import { TableDataSourcePipe } from './table-data-source.pipe';
import { RelativeTimePipe } from './relative-time.pipe';
import { FirstDayWeekPipe } from './first-day-week.pipe';
import { DateLocalePipe } from './date-locale.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { CurrencyLocalePipe } from './currency-locale.pipe';
import { PriceAfterTaxe } from './price-after-taxe.pipe';
import { BeautifyShortcutsPipe } from './beautify-shortcuts.pipe';
import { DateIsCurrentYearPipe } from './date-is-current-year.pipe';
import {
    NgCapitalizePipeModule,
    NgCountPipeModule,
    NgGroupByPipeModule,
    NgLastPipeModule,
    NgMatchPipeModule,
    NgReplacePipeModule,
    NgRoundPipeModule,
    NgTruncatePipeModule
} from 'angular-pipes';
import { NumberLocalePipe } from './number-locale.pipe';
import { TwelveHourPipe } from './twelve-hour.pipe';
import { AsFormControlPipe } from './as-formcontrol.pipe';
import { AsFormGroupPipe } from './as-formgroup.pipe';
import { AsFormArrayPipe } from './as-formarray.pipe';

@NgModule({
    declarations: [
        AsFormArrayPipe,
        AsFormControlPipe,
        AsFormGroupPipe,
        BeautifyShortcutsPipe,
        ChatMessagePipe,
        CurrencyLocalePipe,
        CurrencySymbolPipe,
        DateIsCurrentYearPipe,
        DateLocalePipe,
        EmptyFieldPipe,
        EntityEmojiPipe,
        FilePathPipe,
        FileSizePipe,
        FirstDayWeekPipe,
        NumberLocalePipe,
        PhonePipe,
        PriceAfterTaxe,
        RelativeTimePipe,
        SanitizeHtmlPipe,
        TableDataSourcePipe,
        TimeDurationPipe,
        TwelveHourPipe,
        UserFullNamePipe,
        ValuesPipe
    ],
    exports: [
        NgGroupByPipeModule,
        NgMatchPipeModule,
        NgReplacePipeModule,
        NgRoundPipeModule,
        NgCapitalizePipeModule,
        NgLastPipeModule,
        NgTruncatePipeModule,
        NgCountPipeModule,

        AsFormArrayPipe,
        AsFormControlPipe,
        AsFormGroupPipe,
        BeautifyShortcutsPipe,
        ChatMessagePipe,
        CurrencyLocalePipe,
        CurrencySymbolPipe,
        DateIsCurrentYearPipe,
        DateLocalePipe,
        EmptyFieldPipe,
        EntityEmojiPipe,
        FilePathPipe,
        FileSizePipe,
        FirstDayWeekPipe,
        NumberLocalePipe,
        PhonePipe,
        PriceAfterTaxe,
        RelativeTimePipe,
        SanitizeHtmlPipe,
        TableDataSourcePipe,
        TimeDurationPipe,
        TwelveHourPipe,
        UserFullNamePipe,
        ValuesPipe
    ]
})
export class PipesModule {}

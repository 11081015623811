<mat-form-field *ngIf="show" class="vat-container" appearance="outline" fxFlex="1 1 auto">
    <mat-label>{{ 'INPUTS.INPUT_VAT.LABEL' | translate }}</mat-label>
    <input matInput [placeholder]="'INPUTS.INPUT_VAT.PLACEHOLDER' | translate" [formControl]="controlVat | asFormControl" type="text" (keyup.enter)="checkVat()">
    <mat-error *ngIf="controlVat.hasError('invalidVatNumber')">
        {{ 'INPUTS.INPUT_VAT.INVALID_VAT' | translate }}
    </mat-error>

    <button *ngIf="showValidateButton && controlVat.disabled && !vatLoading" (click)="resetVat()" class="vat-button-reset" type="button" mat-icon-button>
        <mat-icon>clear</mat-icon>
    </button>

    <button *ngIf="showValidateButton && (controlVat.enabled || vatLoading)" (click)="checkVat()" class="vat-button" type="button" mat-raised-button color="accent" [disabled]="vatLoading || !controlVat.value || controlVat.disabled">
        <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
            <div>
                {{ 'INPUTS.INPUT_VAT.CHECK' | translate }}
            </div>
            <!-- <mat-icon *ngIf="!vatLoading" matSuffix class="ml-8">check</mat-icon> -->
            <mat-spinner *ngIf="vatLoading" matSuffix [diameter]="24" class="ml-8"></mat-spinner>
        </div>
    </button>
</mat-form-field>

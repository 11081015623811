import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../../../guards/auth.guard';
import { ClientsComponent } from './clients.component';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { AnimauxComponent } from './animaux/animaux.component';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'customers',
        component: ClientsComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'clients',
        redirectTo: '/customers',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        ClientsComponent,
        AnimauxComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        SharedModule,
        ViewSharedModule
    ],
    exports: [
        ClientsComponent
    ]
})

export class ClientsModule {}

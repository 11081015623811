import { AfterContentChecked, AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop-temp';
import { Utils } from '../../../../utils';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FichierService } from '../../../../services/api/fichier.service';
import { createDefaultImageReader, createDefaultImageWriter, PinturaEditorOptions, PinturaDefaultImageWriterResult, plugin_crop, setPlugins, markup_editor_defaults, createDefaultShapePreprocessor, blobToFile } from 'pintura';
import heic2any from 'heic2any';

@Component({
  selector: 'app-document-selector-dialog',
  templateUrl: 'document-selector-dialog.component.html',
  styleUrls: ['document-selector-dialog.component.scss']
})
export class DocumentSelectorDialogComponent implements AfterViewInit, AfterContentChecked {
  onContainerReady = false;
  currentCroppingFile: SelectedFile = null;
  currentFiles: SelectedFile[] = [];
  typeForText = '';

  @ViewChild('dialogContentContainer') dialogContentContainer: ElementRef;
  @ViewChild('customInput') input: any;

  pinturaOptions: PinturaEditorOptions = {
    imageReader: createDefaultImageReader({
      preprocessImageFile: async file => {
        if (!file.type.includes('heic')) {
          return file;
        }

        const blob = await heic2any({
          blob: file,
          toType: 'image/jpeg',
          quality: 0.9
        }) as Blob;

        return blobToFile(blob, 'image.jpg');
      }
    }),
    imageWriter: createDefaultImageWriter(),
    locale: Utils.getPinturaLocale(this.translateService),
    ...markup_editor_defaults
  };

  constructor(
    public dialogRef: MatDialogRef<DocumentSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DocumentSelectorDialogInterface,
    private fichierService: FichierService,
    private translateService: TranslateService
  ) {
    setPlugins(plugin_crop);
    this.setPinturaCustomOptions();
    this.typeForText = data.selectorMultiple ? 'MULTIPLE_' : '';
    switch (data.selectorType) {
      case SelectorType.IMAGE:
        this.typeForText += 'IMAGE';
        break;
      case SelectorType.VIDEO:
        this.typeForText += 'VIDEO';
        break;
      case SelectorType.AUDIO:
        this.typeForText += 'AUDIO';
        break;
      case SelectorType.ANY:
      default:
        this.typeForText += 'DOCUMENT';
        break;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onContainerReady = true;
    });
  }

  ngAfterContentChecked(): void {
    if (!this.currentCroppingFile && this.data && this.data.file) {
      this.fileChange(this.data.file);
    }
  }

  onFileDropped(files: NgxFileDropEntry[]): void {
    if (!this.data.selectorMultiple) {
      files = files.slice(0, 1);
    }

    for (const droppedFile of files) {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          if (this.data.selectorType === '*/*' || file.type.split('/')[0] === this.data.selectorType.split('/')[0]) {
            const blob = file as Blob;
            if (file.name.toLowerCase().endsWith('.heic')) {
              file = new File([blob], file.name.toLowerCase(), { lastModified: file.lastModified, type: 'image/heic' });
            } else {
              file = blobToFile(blob, file.name);
            }

            this.fileChange(file);
          }
        });
      }
    }
  }

  private fileChange(file: File) {
    let placeholder = '';
    switch (file.type.split('/')[0]) {
      case 'image':
        placeholder = 'assets/images/file-image.svg';
        break;
      case 'audio':
        placeholder = 'assets/images/file-audio.svg';
        break;
      case 'video':
        placeholder = 'assets/images/file-video.svg';
        break;
      default:
        placeholder = 'assets/images/file-document.svg';
        break;
    }

    let req: Observable<SelectedFile> = of({
      file: file,
      placeholder: placeholder
    } as SelectedFile);

    if (Utils.isImage(file.type)) {
      req = req.pipe(
        switchMap(selectedFile => {
          // Convert heic to jpeg
          if (!selectedFile.file.type.includes('heic')) {
            return of(selectedFile);
          }

          return from(heic2any({
            blob: selectedFile.file,
            toType: 'image/jpeg',
            quality: 0.9
          })).pipe(
            map(blob => {
              let file: File = selectedFile.file;
              if (blob as Blob) {
                file = blobToFile(blob as Blob, 'image.jpg');
              } else if (blob as Blob[]) {
                file = blobToFile(blob[0], 'image.jpg');
              }

              const selectedImage: SelectedFile = {
                file,
                placeholder: selectedFile.placeholder
              };

              return selectedImage;
            }),
            catchError((error: Error) => {
              console.error(error);
              return of(selectedFile);
            })
          );
        }),
        switchMap(selectedFile => {
          // Compress and resize
          return this.fichierService.compressAndResize(selectedFile.file, {
            maxWidth: FichierService.MAX_WIDTH,
            maxHeight: FichierService.MAX_HEIGHT
          }).pipe(
            map(image => {
              const selectedImage: SelectedFile = {
                file: image as File,
                placeholder: selectedFile.placeholder
              };
              return selectedImage;
            }),
            catchError((error: Error) => {
              console.error(error);
              return of(selectedFile);
            })
          );
        }),
        tap(selectedFile => {
          const myReader: FileReader = new FileReader();
          myReader.onloadend = (loadEvent: ProgressEvent<FileReader>) => {
            selectedFile.originalDataUrl = loadEvent.target.result as string;
            if (!this.data.selectorMultiple && this.data.enableCropper && this.data.forceCropper) {
              this.cropFile(selectedFile);
            }
          };

          myReader.readAsDataURL(selectedFile.file);
        })
      );
    }

    req.subscribe({
      next: selectedFile => {
        if (selectedFile.file.size > FichierService.MAX_FILE_SIZE * 1024 * 1024) {
          alert(this.translateService.instant('FILES.TOO_BIG', { max: FichierService.MAX_FILE_SIZE }));
          return;
        }

        if (!this.data.selectorMultiple) {
          this.currentFiles = [];
        }

        this.currentFiles.push(selectedFile);
      },
      error: error => {
        console.error(error);
      }
    });
  }

  removeFile(selectedFile: SelectedFile): void {
    const index = this.currentFiles.indexOf(selectedFile);
    if (index !== -1) {
      this.currentFiles.splice(index, 1);
    }

    if (this.input?.nativeElement?.files) {
      this.input.nativeElement.value = null;
    }
  }

  cropFile(selectedFile: SelectedFile): void {
    if (this.data.enableCropper) {
      this.currentCroppingFile = selectedFile;
    }
  }

  noCrop(): void {
    this.currentCroppingFile = null;
  }

  crop(result: PinturaDefaultImageWriterResult): void {
    const blob = result.dest;
    if (blob && blob instanceof File) {
      const file: File = new File([blob], 'image.jpeg', { type: 'image/jpg', lastModified: Date.now() });
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.currentCroppingFile.croppedDataUrl = reader.result as any;
        this.currentCroppingFile.file = file;
        this.currentCroppingFile = null;
      }, false);
      reader.readAsDataURL(file);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ok(): void {
    if (!this.currentCroppingFile && this.currentFiles.length > 0) {
      this.dialogRef.close(this.currentFiles.map((sf: SelectedFile) => sf.file));
    }
  }

  isValid(): boolean {
    return this.currentFiles.length > 0;
  }

  private setPinturaCustomOptions(): void {
    const settings = this.data.settings;
    if (settings.aspectRatio) {
      this.pinturaOptions.imageCropAspectRatio = settings.aspectRatio;
    } else {
      this.pinturaOptions.cropSelectPresetOptions = [
        [undefined, this.translateService.instant('PINTURA.SHAPE.PRESET.CUSTOM')],
        [1, this.translateService.instant('PINTURA.SHAPE.PRESET.SQUARE')],
        [16 / 9, this.translateService.instant('PINTURA.SHAPE.PRESET.LANDSCAPE')],
        [3 / 4, this.translateService.instant('PINTURA.SHAPE.PRESET.PORTRAIT')]
      ];
    }

    this.pinturaOptions.shapePreprocessor = createDefaultShapePreprocessor();

    if (settings.rounded) {
      // Code from Pintura example
      this.pinturaOptions.willRenderCanvas = (shapes, state) => {
        const {
          utilVisibility,
          selectionRect,
          lineColor,
          backgroundColor
        } = state;

        if (utilVisibility.crop <= 0) {
          return shapes;
        }

        const { x, y, width, height } = selectionRect;

        return {
          ...shapes,

          interfaceShapes: [
            {
              x: +x + +width * 0.5,
              y: +y + +height * 0.5,
              rx: width * 0.5,
              ry: height * 0.5,
              opacity: utilVisibility.crop,
              inverted: true,
              backgroundColor: [...backgroundColor, 0.5],
              strokeWidth: 1,
              strokeColor: [...lineColor]
            },
            ...shapes.interfaceShapes
          ]
        };
      };
    }
  }
}

export enum SelectorType {
  ANY = '*/*',
  IMAGE = 'image/*',
  VIDEO = 'video/*',
  AUDIO = 'audio/*'
}

export interface SelectedFile {
  file: File;
  placeholder: string;
  originalDataUrl?: string;
  croppedDataUrl?: string;
}

export interface DocumentSelectorDialogInterface {
  selectorType: SelectorType;
  selectorMultiple: boolean;
  enableCropper: boolean;
  forceCropper: boolean;
  title?: string;
  file?: File;
  settings?: DocumentSelectorDialogSettingsInterface;
}

export interface DocumentSelectorDialogSettingsInterface {
  rounded?: boolean;
  aspectRatio?: number;
  maxWidth?: number;
  maxHeight?: number;
}

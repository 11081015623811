<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span
                class="title dialog-title">{{ entiteGeographique?.id ? ('ENTITE_GEO_DIALOG.CLINIC' | translate) + ' : ' + entiteGeographique?.nom : ('ENTITE_GEO_DIALOG.CLINIC_NEW' | translate) }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <app-entite-geographique-form [entityData]="entiteGeographiqueData$" [disabled]="disabled"
                                          [canBeDeleted]="entiteGeographique?.canBeDeleted"
                                          (entityReceived)="entiteGeographique$.next($event); hasChanged = true; close();">
            </app-entite-geographique-form>
        </ng-template>
    </div>
</div>

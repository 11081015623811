import { ChangeDetectorRef, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject } from 'rxjs';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { Utilisateur } from '../../../../models/utilisateurs/utilisateur';

@Component({
    selector: 'app-user-force-update-dialog',
    templateUrl: './user-force-update-dialog.component.html',
    styleUrls: ['./user-force-update-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class UserForceUpdateDialogComponent {
    utilisateur: Utilisateur;
    loading$ = new BehaviorSubject<boolean>(true);

    cguValid = false;
    changePasswordValid = false;

    constructor(
        public dialogRef: MatDialogRef<UserForceUpdateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: UserForceUpdateDialogInterface,
        private cdRef: ChangeDetectorRef
    ) {
        this.utilisateur = data.utilisateurService.utilisateurConnectedValue;
        this.cguValid = !this.utilisateur.cguMustConfirm;
        this.changePasswordValid = !this.utilisateur.passMustChange;
        this.loading$.next(false);
    }

    close(): void {
        this.dialogRef.close();
    }

    onCguStatusChanged(event: boolean, stepper: MatStepper): void {
        this.cguValid = Boolean(event);
        this.cdRef.detectChanges();

        if (this.cguValid && this.changePasswordValid) {
            this.close();
        }

        if (this.cguValid) {
            stepper.next();
            this.cdRef.detectChanges();
        }
    }

    onPasswordStatusChanged(event: boolean, stepper: MatStepper): void {
        this.changePasswordValid = Boolean(event);
        this.cdRef.detectChanges();

        if (this.cguValid && this.changePasswordValid) {
            this.close();
        }

        if (this.changePasswordValid) {
            stepper.next();
            this.cdRef.detectChanges();
        }
    }
}

export interface UserForceUpdateDialogInterface {
    utilisateur: Utilisateur;
    utilisateurService: UtilisateurService;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChatShortcut } from '../../../../../models/pro/entite-juridique';

@Component({
    selector: 'app-chat-scenario-message',
    templateUrl: './chat-scenario-message.component.html',
    styleUrls: ['./chat-scenario-message.component.scss']
})
export class ChatScenarioMessageComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() public shortcuts: ChatShortcut[];
    @Output() addOrUpdate = new EventEmitter<FormGroup>();
    @Output() cancelProcess = new EventEmitter();

    selectedMessage?: string;
    nothingSelected: boolean;

    ngOnInit(): void {
        this.selectedMessage = this.form.get('message').value;
        const numberDaysToOpenIfClosedForm = this.form.get('numberDaysToOpenIfClosed');

        if (!numberDaysToOpenIfClosedForm.value) {
            numberDaysToOpenIfClosedForm.disable();
        }

        this.form.get('reopenChatIfClosed').valueChanges.subscribe(val => {
            if (val) {
                numberDaysToOpenIfClosedForm.enable();
                // default value suggested when reopening the chat
                numberDaysToOpenIfClosedForm.setValue(7);
            } else {
                numberDaysToOpenIfClosedForm.setValue(null);
                numberDaysToOpenIfClosedForm.disable();
            }
        });
    }

    submit(): void {
        this.addOrUpdate.emit(this.form);
    }

    cancel(): void {
        this.cancelProcess.emit();
    }

    updateForm(shortcut: ChatShortcut): void {
        this.nothingSelected = shortcut === null;
        this.form.get('message').setValue(shortcut?.shortcut ?? '');
        this.form.get('nbDaysAfter').markAsTouched();
        this.form.updateValueAndValidity();
    }
}

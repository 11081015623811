import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Fichier } from '../../../models/fichier';
import { Animal } from '../../../models/animal/animal';
import { RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { Observable, Subscription } from 'rxjs';
import { FichierSelectionMode } from '../list-fichier/list-fichier.component';

@Component({
    selector: 'app-fichiers-animal-dialog',
    templateUrl: './fichiers-animal-dialog.component.html',
    styleUrls: ['./fichiers-animal-dialog.component.scss']
})
export class FichiersAnimalDialogComponent implements OnInit, OnDestroy {
    FichierSelectionMode = FichierSelectionMode;

    private reqSubscription: Subscription;

    constructor(public dialogRef: MatDialogRef<FichiersAnimalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FichiersAnimalDialogInterface) {}

    ngOnInit(): void {
        if (this.data.reqFichiers !== null) {
            this.reqSubscription = this.data.reqFichiers.subscribe({
                next: fichiers => {
                    this.data.fichiers = fichiers;
                },
                error: _ => {
                    this.close();
                }
            });
        }
    }

    ngOnDestroy(): void {
        if (this.reqSubscription) {
            this.reqSubscription.unsubscribe();
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    onFichierSelected(result: Fichier | Fichier[]): void {
        if (result instanceof Fichier) {
            this.dialogRef.close(result);
        }
    }
}

export interface FichiersAnimalDialogInterface {
    fichiers?: Fichier[];
    reqFichiers?: Observable<Fichier[]>;
    animal?: Animal;
    rendezVous?: RendezVous;
}

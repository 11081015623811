<div>
    <div *ngIf="preview" class="publisher-container" fxLayout="column" fxLayoutAlign="center center">
        <div class="publisher" #publisherDiv></div>
        <div *ngIf="isTesting" class="test">{{ 'PARAMETER.CAMERA_NOT_AVAILABLE_DURING_TEST' | translate }}</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="16px" class="mt-16">
        <div>
            <h5 class="m-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>videocam</mat-icon>&nbsp;{{ 'PARAMETER.VIDEO' | translate }}
            </h5>
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="1 1" appearance="outline" class="no-padding">
                    <mat-label>{{ 'PARAMETER.SELECTED_VIDEO_DEVICE' |translate }}</mat-label>
                    <input matInput placeholder="{{ 'PARAMETER.SELECTED_AUDIO_DEVICE' |translate }}"
                        [value]="showSelectedVideoDevice()" readonly
                        [disabled]="(visioService.permissionsStatus | async) === DevicePermission.NoPermission || (visioService.permissionsStatus | async) === DevicePermission.NoDevicesFound || isTesting">
                    <button matSuffix mat-icon-button color="primary" aria-label="Switch camera"
                        (click)="switchCamera()"
                        [disabled]="(visioService.permissionsStatus | async) === DevicePermission.NoPermission || (visioService.permissionsStatus | async) === DevicePermission.NoDevicesFound || isTesting">
                        <mat-icon>switch_camera</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

        <div>
            <h5 class="m-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>mic</mat-icon>&nbsp;{{ 'PARAMETER.AUDIO' | translate }}
            </h5>
            <mat-progress-bar class="mb-8" mode="determinate" color="primary" [value]="audioLevel | async"
                [style.opacity]="isTesting ? 0.25 : 1.0"></mat-progress-bar>
            <div fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field fxFlex="1 1" appearance="outline" class="no-padding">
                    <mat-label>{{ 'PARAMETER.SELECTED_AUDIO_DEVICE' |translate }}</mat-label>
                    <mat-select [(ngModel)]="selectedAudioDevice" (selectionChange)="selectMicrophone($event)"
                        [disabled]="(visioService.permissionsStatus | async) === DevicePermission.NoPermission  || (visioService.permissionsStatus | async) === DevicePermission.NoDevicesFound || isTesting">
                        <mat-option *ngFor="let device of audioDevices" [value]="device">
                            {{ device.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="!isSameDevices()" class="warning" fxLayout="row" fxLayoutAlign="space-around center">
            <mat-icon class="ml-16 mr-16" fxFlex="0 0 auto">warning</mat-icon>
            <p fxFlex="1 1 100%" [innerHTML]="'PARAMETER.DIFFERENT_DEVICES_SELECTED' | translate"></p>
        </div>
    </div>
</div>

import { DataSource } from '@angular/cdk/collections';
import { Invoice } from '../models/subscription';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { EntiteJuridiqueService } from '../services/api/entite-juridique.service';
import { map } from 'rxjs/operators';

export class InvoicesDataSource implements DataSource<Invoice> {
    private _originalInvoices = new BehaviorSubject<Invoice[]>([]);
    private _invoices = new BehaviorSubject<Invoice[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);
    private _filter: string = null;

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService) {}

    load(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.utilisateurService.utilisateurConnected.subscribe({
            next: u => {
                this.entiteJuridiqueService.getInvoices(u.entiteJuridique).subscribe((invoices: Invoice[]) => {
                    if (invoices) {
                        const rows = (this._filter ? invoices.filter(i => i.localNumber?.includes(this._filter)) : invoices)
                            .sort((a, b) => Number(b.periodEnd) - Number(a.periodEnd));
                        this._originalInvoices.next(rows);
                        this._invoices.next(rows);
                        this._totalCount.next(rows.length);
                        this._loadingSubject.next(false);
                    }
                });
            },
            error: () => this._loadingSubject.next(false)
        });
    }

    setFilter(f?: string): void {
        this._filter = f;

        const rows = this._filter ? this._originalInvoices.value.filter(i => i.localNumber?.includes(this._filter)) : this._originalInvoices.value;
        this._invoices.next(rows);
        this._totalCount.next(rows.length);
        this._loadingSubject.next(false);
    }

    add(invoice: Invoice): void {
        const data = this._invoices.getValue();
        data.push(invoice);
        this._invoices.next(data);
        this._totalCount.next(this._totalCount.getValue() + 1);
    }

    replace(invoice: Invoice): void {
        const data = this._invoices.getValue();
        const index = data.findIndex(i => i.id === invoice.id);
        data[index] = invoice;
        this._invoices.next(data);
    }

    delete(invoice: Invoice): void {
        const data = this._invoices.getValue();
        data.splice(data.findIndex(i => i.id === invoice.id), 1);
        this._invoices.next(data);
        this._totalCount.next(this._totalCount.getValue() - 1);
    }

    connect(): Observable<Invoice[]> {
        return this._invoices.asObservable();
    }

    disconnect(): void {
        this._invoices.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InterceptorSkipHandler } from './http-token-interceptor.service';
import { ConfigService } from './config.service';
import { getMetadata, UrlMetadataInterface } from 'page-metadata-parser';

@Injectable()
export class LinkPreviewService {
    constructor(private http: HttpClient, private config: ConfigService) {}

    public getLinkPreview(url: string): Observable<UrlMetadataInterface> {
        let headers = new HttpHeaders();
        headers = headers.set(InterceptorSkipHandler, '');

        return this.http.get(url, { headers, responseType: 'text' }).pipe(
            catchError(() => this.http.get(this.config.baseUrl + 'api/pass_through?url=' + url, { headers, responseType: 'text' })),
            catchError(() => of(null)),
            map((html: string) => {
                if (html) {
                    const doc = document.implementation.createHTMLDocument('');
                    doc.documentElement.innerHTML = html;
                    const metadata = getMetadata(doc, url);
                    // console.log(metadata);
                    return metadata;
                }

                return null;
            })
        );
    }
}

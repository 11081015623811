import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { detect } from 'detect-browser';

@Injectable({ providedIn: 'root' })

export class IncompatibleBrowserGuard implements CanActivate {
    private readonly path = 'not-supported';

    constructor(protected router: Router) {}

    canActivate(next: ActivatedRouteSnapshot): boolean {
        if (this.isBrowserCompatible()) {
            if (next.routeConfig.path === this.path) {
                void this.router.navigate(['/']);
                return false;
            }

            return true;
        }

        if (next.routeConfig.path === this.path) {
            return true;
        }

        void this.router.navigate(['/' + this.path]);
        return false;
    }

    private isBrowserCompatible(): boolean {
        const browser = detect();
        let isCompatible = false;
        switch (browser?.name) {
            case 'chrome':
            case 'firefox':
            case 'safari':
            case 'ios':
                isCompatible = true;
                break;
            default:
                break;
        }

        return isCompatible;
    }
}

import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { VisioComponent } from './visio.component';
import { Observable, of } from 'rxjs';
import { DialogComponent } from '../../shared/view-utils/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';

@Injectable({ providedIn: 'root' })

export class VisioCanDeactivate implements CanDeactivate<VisioComponent> {
    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {}

    canDeactivate(
        component: VisioComponent,
        _currentRoute: ActivatedRouteSnapshot,
        _currentState: RouterStateSnapshot,
        _nextState: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const session = component.visioService.currentSessionObject;
        if (session) {
            const dialog = this.dialog.open(DialogComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('VISIO.FINISH_SESSION'),
                    content: this.translateService.instant('VISIO.ASK_FINISH_CONV'),
                    action: true,
                    cancel: this.translateService.instant('SHARED.CANCEL'),
                    ok: this.translateService.instant('SHARED.OK')
                },
                disableClose: true
            });

            return dialog.afterClosed().pipe(
                map(result => Boolean(result)),
                switchMap(result => {
                    if (!result) {
                        return of(false);
                    }

                    return this.askForOrdre();
                })
            );
        }

        return true;
    }

    private askForOrdre(): Observable<boolean> {
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.paysFacturation !== 'FR') {
            return of(true);
        }

        return this.dialog.open(DialogComponent, {
            width: '860px',
            data: {
                title: 'Enregistrer votre acte auprès du conseil de l\'ordre',
                content: '<p>Le mot de passe est : <b>azerty</b></p>',
                urlIframe: 'https://docs.google.com/forms/d/e/1FAIpQLSfTipy4u5tkKIGaiKZh8E8tbU77LuivuWk98p-4_H9d7Y5wmg/viewform',
                action: true,
                ok: this.translateService.instant('VISIO.FINISH')
            },
            disableClose: true
        }).afterClosed().pipe(map(_ => true));
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { shortcutGroups, Shortcut } from '../models/shortcuts';

@Pipe({
    name: 'beautifyShortcuts'
})
export class BeautifyShortcutsPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(value: string): string {
        const regex = /({{)[^{]+(}})/g;
        const matches = value.match(regex);
        if (matches) {
            matches.forEach((match: string) => {
                const matchKey = match.replace(/{|}/g, '').trim();
                let s: Shortcut = null;
                for (const shortcutGroup of shortcutGroups) {
                    for (const shortcut of shortcutGroup.shortcuts) {
                        if (shortcut.key === matchKey) {
                            s = shortcut;
                            break;
                        }
                    }

                    if (s) {
                        break;
                    }
                }

                value = value.replace(match, `<span class="shortcut-chip">${s?.name ? this.translateService.instant(s.name) as string : matchKey}</span>`);
            });
        }

        return value;
    }
}

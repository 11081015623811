import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { RendezVousWebPostInterface } from '../../../../models/interfaces/post/rendez-vous-web-post.interface';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { Animal } from '../../../../models/animal/animal';

@Component({
    selector: 'app-rendez-vous-web-form',
    templateUrl: './rendez-vous-web-form.component.html',
    styleUrls: ['./rendez-vous-web-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RendezVousWebFormComponent implements OnInit {
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();
    @Input('telephonePortable') telephonePortable: string;
    @Input('animal') animal: Animal;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<RendezVousWebPostInterface>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<RendezVous>();

    form: FormGroup;

    constructor(private rendezVousService: RendezVousService, private utiliserService: UtilisateurService) {
        this.form = new FormGroup({
            telephonePortable: new FormControl(null, [Validators.required])
        });

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });
    }

    ngOnInit(): void {
        this.form.get('telephonePortable').setValue(this.telephonePortable);
    }

    submit(): void {
        if (this.form.valid) {
            this.save();
        }
    }

    private save() {
        const data = this.rendezVousService.getRendezVousUrgencePost(this.utiliserService.utilisateurConnectedValue, this.animal) as RendezVousWebPostInterface;
        data.telephonePortable = this.form.get('telephonePortable').value;
        this.formInProgress$.next(true);
        this.rendezVousService.addRendezVousWeb(data).subscribe({
            next: rendezVous => {
                this.formInProgress$.next(false);
                this.entityReceived$.next(rendezVous);
                this.formSubmited$.next(data);
            },
            error: () => {
                this.formInProgress$.next(false);
            }
        });
    }
}

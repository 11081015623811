import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSliderChange } from '@angular/material/slider';
import { Coupon, Subscription } from '../../../../models/subscription';
import { EntiteJuridiqueService } from '../../../../services/api/entite-juridique.service';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';
import { StripeCoupon } from '../../../../models/stripe/coupon';
import { OnboardingService } from '../../../../services/api/onboarding.service';
import { AuthService } from '../../../../services/api/auth.service';

@Component({
    selector: 'app-plan-form',
    templateUrl: './plan-form.component.html',
    styleUrls: ['./plan-form.component.scss']
})
export class PlanFormComponent implements OnInit {
    @Input() entiteJuridique: EntiteJuridique;
    @Input() subscription: Subscription;

    @Output() formDone = new EventEmitter<Subscription>();

    form: FormGroup;
    formLoading = false;

    priceMonthly = 79;
    priceYearly = 948;
    priceAdditionalLicenseMonthly = 9;
    priceAdditionalLicenseYearly = 9;
    priceOptionPaymentMonthly = 0;
    priceOptionPaymentYearly = 0;
    priceVat = 1.2;
    nbLicences = 2;
    nbLicencesBase = 2;

    couponLoading = false;
    private _stripeCoupon: StripeCoupon;

    public get stripeCoupon(): StripeCoupon {
        return this._stripeCoupon;
    }

    constructor(
        private translateService: TranslateService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private onboardingService: OnboardingService,
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.nbLicencesBase = 2;
        this.nbLicences = this.entiteJuridique.nbLicences;

        this.form = new FormGroup({
            numberOfLicenses: new FormControl(this.nbLicences, [Validators.min(this.nbLicencesBase), Validators.max(50), Validators.required]),
            coupon: new FormControl(this.subscription?.discount?.coupon ? Coupon.getCouponCode(this.subscription.discount.coupon) : null, []),
            options: new FormGroup({
                payment: new FormControl(true) // TODO: Supprimer l'option car incluse dans le forfait
            })
        });

        if (this.subscription?.discount?.coupon) {
            this.form.get('coupon').disable();
            this._stripeCoupon = this.subscription.discount.coupon as StripeCoupon;
        }

        this.formatLicenses = this.formatLicenses.bind(this);
    }

    submit(): void {
        if (!this.form.valid) {
            return;
        }

        this.formLoading = true;

        const raw = this.form.getRawValue();
        raw.options = Object.entries(raw.options).filter(([_key, value]) => value === true).map(([key, _value]) => key);

        this.entiteJuridiqueService.updateSubscription(this.entiteJuridique.id, raw).subscribe({
            next: (subscription: Subscription) => {
                this.formLoading = false;
                this.formDone.emit(subscription);
            },
            error: () => {
                this.formLoading = false;
            }
        });
    }

    formatLicenses(value: number): string {
        const tradKey = 'ONBOARDING_PRO_PLANS.LICENSES.NAME';
        let trad: string = this.translateService?.instant('ONBOARDING_PRO_PLANS.LICENSES.NAME');
        if (!trad || trad === tradKey) {
            trad = 'licences';
        }

        return Math.round(value).toString() + '\u00A0' + trad;
    }

    onMatSliderInput(event: MatSliderChange): void {
        this.form.get('numberOfLicenses').setValue(event.value);
    }

    close(): void {
        this.formDone.emit(null);
    }

    resetCoupon(): void {
        this._stripeCoupon = null;
        const options = Object.keys((this.form.get('options') as FormGroup).controls);
        options.forEach(optionsKey => {
            this.form.get(['options', optionsKey]).enable();
        });
        this.form.get('coupon').disable();
        this.form.get('coupon').setValue(null);
        this.form.get('coupon').enable();
    }

    checkCoupon(): void {
        const coupon = this.form.get('coupon').value;
        if (!coupon || coupon && coupon.trim() === '') {
            return;
        }

        this.form.get('coupon').disable();
        this.couponLoading = true;
        this.onboardingService.checkCoupon(coupon, this.authService.token).subscribe({
            next: (stripeCoupon: StripeCoupon) => {
                if (!stripeCoupon) {
                    this._stripeCoupon = null;
                    this.form.get('coupon').enable();
                } else {
                    this._stripeCoupon = stripeCoupon;
                }

                this.couponLoading = false;

                this.setCouponForOptions();
            },
            error: () => {
                this.form.get('coupon').enable();
                this.couponLoading = false;
            }
        });
    }

    private setCouponForOptions() {
        const coupon = this.stripeCoupon;
        if (coupon) {
            if (coupon.metadata?.optionsIncluded) {
                try {
                    const optionsIncluded: string[] = JSON.parse(coupon.metadata.optionsIncluded);
                    if (optionsIncluded && optionsIncluded.length > 0) {
                        optionsIncluded.forEach(optionKey => {
                            const option = this.form.get(['options', optionKey]);
                            option.setValue(true);
                            option.disable();
                        });
                    }
                } catch {
                    // ignored
                }
            }

            if (coupon.metadata?.licences) {
                this.nbLicences = Number.parseInt(coupon.metadata.licences, 10) + this.nbLicencesBase;
            } else {
                this.nbLicences = this.nbLicencesBase;
            }

            this.form.get('numberOfLicenses').setValue(this.nbLicences);
        }
    }

    public get pricePerPaymentAfterCoupon(): number {
        let price = this.pricePerPayment;
        const coupon = this.stripeCoupon;
        if (coupon) {
            price -= (coupon.amountOff ?? 0) / 100;
            price *= 1 - (coupon.percentOff ?? 0);
        }

        if (price < 0) {
            price = 0;
        }

        return price;
    }

    public get pricePerMonth(): number {
        const data: PlanFormValue = this.form.getRawValue();
        let pricePerMonth: number = null;
        if (this.subscription.monthly) {
            pricePerMonth = this.priceMonthly +
                (data.numberOfLicenses - 3) * this.priceAdditionalLicenseMonthly +
                (data.options.payment ? this.priceOptionPaymentMonthly : 0);
        } else {
            pricePerMonth = this.priceYearly +
                (data.numberOfLicenses - 3) * this.priceAdditionalLicenseYearly +
                (data.options.payment ? this.priceAdditionalLicenseYearly : 0);
        }

        return pricePerMonth;
    }

    public get pricePerPayment(): number {
        // return this.pricePerMonth * (this.subscription.monthly ? 1 : 12);
        return this.pricePerMonth;
    }

    public get pricePerPaymentBefore(): number {
        const nbLicencesSup = this.nbLicences - this.nbLicencesBase;
        const paymentAuthorized = true; // TODO: Supprimer l'option car incluse dans le forfait
        let pricePerPaymentBefore: number = null;

        if (this.subscription.monthly) {
            pricePerPaymentBefore = this.priceMonthly +
                nbLicencesSup * this.priceAdditionalLicenseMonthly +
                (paymentAuthorized ? this.priceOptionPaymentMonthly : 0);
        } else {
            pricePerPaymentBefore = this.priceYearly +
                nbLicencesSup * this.priceAdditionalLicenseYearly +
                (paymentAuthorized ? this.priceAdditionalLicenseYearly : 0);
        }

        return pricePerPaymentBefore;
    }
}

interface PlanFormValue {
    numberOfLicenses: number;
    coupon: string;
    options: {
        payment: boolean;
    };
}

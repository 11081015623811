import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilisateurService } from '../../../../../services/api/utilisateur.service';
import { Veterinaire } from '../../../../../models/utilisateurs/veterinaire';
import { debounceTime, filter } from 'rxjs/operators';
import { Utils } from '../../../../../utils';

@Component({
    selector: 'app-input-veterinaire',
    templateUrl: './input-veterinaire.component.html',
    styleUrls: ['./input-veterinaire.component.scss']
})
export class InputVeterinaireComponent implements OnInit, OnChanges {
    @Input() control: AbstractControl;
    @Input() showMailTel: boolean;
    @Input() nullValue: string;

    veterinairesFilter: FormControl = new FormControl();

    filteredVeterinairesOptions$: BehaviorSubject<Veterinaire[]> = new BehaviorSubject<Veterinaire[]>(null);
    loading: boolean;
    required: boolean;

    allVeterinaires: Veterinaire[] = [];

    private wasEnabled: boolean;
    private subscription: Subscription;

    constructor(private utilisateurService: UtilisateurService) {
        this.allVeterinaires = this.utilisateurService.veterinaires$.value;
    }

    ngOnInit(): void {
        this.wasEnabled = this.control.enabled;
        this.control.disable();
        this.required = Utils.isRequiredField(this.control);

        this.search();

        this.veterinairesFilter.valueChanges
            .pipe(
                filter(v => v?.length > 0),
                debounceTime(150)
            )
            .subscribe(() => {
                this.search();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes?.control?.isFirstChange()) {
            this.search();
        }
    }

    private search() {
        const filter = this.veterinairesFilter.value ? this.veterinairesFilter.value.toLowerCase() : '';

        if (this.allVeterinaires.length > 0) {
            this.filteredVeterinairesOptions$.next([...this.allVeterinaires]
                .filter(c => [
                        c.nom,
                        c.prenom,
                        c.mail,
                        c.telephonePortable
                    ].filter(e => !!e).join('').replace(/ /g, '').toLowerCase().includes(filter)
                ).sort((a, b) => a.nom < b.nom ? -1 : 1)
            );

            if (this.wasEnabled) {
                this.control.enable();
            }
        } else {
            this.loading = true;
            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            this.subscription = this.utilisateurService.getVeterinaires(null, 'asc', 'fullName', null, null, true).subscribe(veterinaires => {
                if (this.wasEnabled) {
                    this.control.enable();
                }

                this.loading = false;
                this.allVeterinaires = veterinaires.data;
                this.filteredVeterinairesOptions$.next(this.allVeterinaires);
            });
        }
    }
}

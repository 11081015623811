<form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()">

    <app-input-phone [control]="form.get('telephonePortable')" [required]="true" class="w-100-p"
                     [placeholder]="'FORM.PHONE_MOBILE' | translate"></app-input-phone>

    <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button type="submit" mat-raised-button color="accent" [disabled]="formInProgress$ | async">
            <mat-icon>save</mat-icon>
            {{ 'SHARED.SAVE_SEND' | translate }}
        </button>
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                     *ngIf="formInProgress$ | async"></mat-spinner>
    </div>
</form>

import { Component, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Observable } from 'rxjs';
import { ChatService } from '../../../services/api/chat.service';
import { map } from 'rxjs/operators';
import { Chat } from '../../../models/animal/chat';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ChatComponent {
    selectedConversation$: Observable<Chat>;
    hasChats$: Observable<boolean>;

    constructor(private chatService: ChatService) {
        this.selectedConversation$ = this.chatService.chatSelected;
        this.hasChats$ = this.chatService.chats.pipe(map(c => c ? c.length > 0 : false));
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { Observable, Subscription } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations';
import { ChatService } from '../../../services/api/chat.service';
import { Veterinaire } from '../../../models/utilisateurs/veterinaire';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { DialogComponent } from '../../shared/view-utils/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DateLocalePipe } from '../../../pipes/date-locale.pipe';
import { parseUserAgent } from 'detect-browser';
import { UserPreferenciesService } from '../../../services/user-preferencies.service';
import { StatValueMeaning } from './stats-card/stats-card.component';
import { RendezVousListPeriod } from 'app/main/shared/rendez-vous-list-card/rendez-vous-list-card.component';
import { NewsService } from 'app/services/api/news.service';
import { News } from 'app/models/news/news';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: fuseAnimations
})
export class DashboardComponent implements OnInit, OnDestroy {
    RendezVousListPeriod = RendezVousListPeriod;

    user: Veterinaire;

    totalUnread$: Observable<number>;
    totalChatOpen$: Observable<number>;

    totalUnreadHospivet$: Observable<number>;
    totalChatOpenHospivet$: Observable<number>;

    entiteJuridique: EntiteJuridique;
    veterinairesConnected: Veterinaire[];

    forceDisconnectingInProgress: number;

    statValueMeaning = StatValueMeaning;

    publishedNews?: NewsViewModel[];

    private subscriptions: Subscription[] = [];

    constructor(
        private readonly router: Router,
        private readonly utilisateurService: UtilisateurService,
        private readonly chatService: ChatService,
        private readonly entiteJuridiqueService: EntiteJuridiqueService,
        private readonly dialog: MatDialog,
        private readonly translateService: TranslateService,
        private readonly userPreferenciesService: UserPreferenciesService,
        private readonly newsService: NewsService
    ) {
        this.totalUnread$ = this.chatService.totalUnread;
        this.totalChatOpen$ = this.chatService.chats.pipe(
            filter(c => Boolean(c)),
            map(c$ => c$.filter(c => c.canChat)),
            map(c => c.length)
        );

        const chatsOpen$ = this.chatService.chats.pipe(
            filter(c => Boolean(c)),
            map(c$ => c$.filter(c => c.canChat && c.categorie?.id === 27))
        );

        this.totalChatOpenHospivet$ = chatsOpen$.pipe(map(c => c.length));
        this.totalUnreadHospivet$ = chatsOpen$.pipe(
            map(c$ => c$.filter(c => !c.veterinaryHasRead)),
            map(c => c.length)
        );
    }

    ngOnInit(): void {
        const vetSubscription = this.utilisateurService.utilisateurConnected.pipe(
            switchMap(user => {
                this.user = user;
                return this.entiteJuridiqueService.getEntiteJuridiqueById(user.entiteJuridique.id);
            }),
            switchMap((entiteJuridique: EntiteJuridique) => {
                this.entiteJuridique = entiteJuridique;
                return this.entiteJuridiqueService.getVeterinairesConnectedById(entiteJuridique.id);
            })
        ).subscribe(veterinairesConnected => {
            this.veterinairesConnected = veterinairesConnected;
        });

        if (vetSubscription) {
            this.subscriptions.push(vetSubscription);
        }

        const newsSubscription = this.newsService.getAll(
            null,
            'published',
            null,
            null,
            null,
            5
        ).pipe().subscribe(news => {
            this.publishedNews = news.data.map(news => new NewsViewModel(this.translateService, news));
        });

        if (newsSubscription) {
            this.subscriptions.push(newsSubscription);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    public get greeting(): string {
        const date: number = new Date().getHours();
        if (date > 0 && date <= 12) {
            return 'TIME.MORNING';
        } else if (date > 12 && date <= 17) {
            return 'TIME.AFTERNOON';
        }

        return 'TIME.EVENING';
    }

    goToMessages(): void {
        this.userPreferenciesService.setPreferenciesValue('chatsFilter', {
            status: 'open',
            categories: [],
            veterinaries: [],
            clients: []
        }).pipe(take(1)).subscribe(_ => {
            void this.router.navigate(['/chat']);
        });
    }

    goToMessagesHospitvet(): void {
        this.userPreferenciesService.setPreferenciesValue('chatsFilter', {
            status: 'open',
            categories: [27],
            veterinaries: [],
            clients: []
        }).pipe(take(1)).subscribe(_ => {
            void this.router.navigate(['/chat']);
        });
    }

    goToEspaceDisque(): void {
        void this.router.navigate(['/espace-disque']);
    }

    goToNews(): void {
        void this.router.navigate(['/news/published']);
    }

    disconnectUser(veterinaire: Veterinaire): void {
        this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('LOGIN.FORCE_LOG_OUT'),
                content: this.translateService.instant('LOGIN.CONFIRM_LOG_OUT', { veterinaire: veterinaire.fullNameLite }),
                action: true,
                ok: this.translateService.instant('SHARED.CONFIRM')
            },
            disableClose: true
        }).afterClosed().subscribe(result => {
            if (result) {
                this.forceDisconnectingInProgress = veterinaire.id;
                this.utilisateurService.disconnect(veterinaire.id).subscribe({
                    next: () => {
                        this.forceDisconnectingInProgress = null;
                        this.veterinairesConnected.splice(this.veterinairesConnected.findIndex(v => v.id === veterinaire.id), 1);
                    },
                    error: () => {
                        this.forceDisconnectingInProgress = null;
                    }
                });
            }
        });
    }

    public vetConnectedTooltipFn(user: Veterinaire, translateService: TranslateService): string {
        let os = '';
        if (user.lastUserAgentUsed?.startsWith('web')) {
            const userAgent = parseUserAgent(user.lastUserAgentUsed.slice(user.lastUserAgentUsed.indexOf('/') + 1));
            if (userAgent?.os) {
                os = ' · ' + userAgent?.os;
            }
        } else if (user.lastUserAgentUsed) {
            os = ' · ' + user.lastUserAgentUsed.slice(0, Math.max(0, user.lastUserAgentUsed.indexOf('/')));
        }

        return translateService.instant('SHARED.LAST_CONNECTION') as string + ' : ' + new DateLocalePipe(translateService).transform(user.lastConnection, 'short') + os;
    }
}

class NewsViewModel {
    name: string;
    date: string;
    nbClients: number;
    nbViews: number;
    nbClicks: number;

    constructor(
        private readonly translateService: TranslateService,
        private readonly news: News
    ) {
        this.name = this.news.name;
        const datePipe = new DateLocalePipe(this.translateService);
        this.date = datePipe.transform(this.news.publishedDate, 'shortDate');
        this.nbClients = this.news.nbClientsSegments;
        this.nbViews = this.news.nbView;
        this.nbClicks = this.news.nbClick;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EntiteGeographiquePostInterface } from 'app/models/interfaces/post/pro/entite-geographique-post.interface';
import { EntiteJuridiquePostInterface } from '../../models/interfaces/post/pro/entite-juridique-post.interface';
import { EntiteGeographique, ListEntitesGeographiques } from 'app/models/pro/entite-geographique';

import {
    EntiteGeographiqueDialogComponent,
    EntiteGeographiqueDialogInterface
} from 'app/main/shared/form-dialog/entite-geographique-dialog/entite-geographique-dialog.component';

import { TranslateService } from '@ngx-translate/core';
import { UtilisateurService } from './utilisateur.service';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class EntiteGeographiqueService {
    private _entitesGeographiques: BehaviorSubject<EntiteGeographique[]> = new BehaviorSubject<EntiteGeographique[]>([]);

    private shouldFetch = true;

    constructor(private translateService: TranslateService, private http: HttpClient,
        private config: ConfigService, private snackbar: MatSnackBar, private dialog: MatDialog,
        private utilisateurService: UtilisateurService) {}

    public getEntiteGeographiqueById(id: number): Observable<EntiteGeographique> {
        return this.http.get<EntiteGeographique>(this.config.baseUrl + 'api/entite_geographique/' + id.toString()).pipe(
            map(r => ObjectMapper.deserialize(EntiteGeographique, r)),
            tap(entiteGeographique => {
                const entitesGeographiques = this._entitesGeographiques.getValue();
                const entiteGeographiqueIndex = entitesGeographiques.findIndex(eg => eg.id === entiteGeographique.id);
                if (entiteGeographiqueIndex !== -1) {
                    entitesGeographiques[entiteGeographiqueIndex] = entiteGeographique;
                }

                this._entitesGeographiques.next(entitesGeographiques);
                this.utilisateurService.updateEntiteGeographiqueForUtilisateurConnected(entiteGeographique);
            })
        );
    }

    public getEntitesGeographiques(keywords: string = null,
        order: string = null,
        orderField: string = null,
        offset: number = null,
        limit = null
    ): Observable<ListEntitesGeographiques> {
        let params = new HttpParams();
        if (keywords) {
            params = params.append('keywords', keywords);
        }

        if (order) {
            params = params.append('order', order);
        }

        if (orderField) {
            params = params.append('order_field', orderField);
        }

        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        return this.http.get<ListEntitesGeographiques>(this.config.baseUrl + 'api/entites_geographiques', {
            params: params
        }).pipe(
            map(egList => ObjectMapper.deserialize(ListEntitesGeographiques, egList))
        );
    }

    public getAllEntitesGeographiques(): Observable<EntiteGeographique[]> {
        if (this.shouldFetch) {
            this.shouldFetch = false;
            return this.getEntitesGeographiques().pipe(
                map(list => list.data),
                tap(data => {
                    this._entitesGeographiques.next(data);
                    this.shouldFetch = false;
                })
            );
        }

        return this._entitesGeographiques.asObservable();
    }

    public addEntiteGeographique(data: EntiteGeographiquePostInterface, logo?: File, coverImage?: File): Observable<EntiteGeographique> {
        this.snackbar.open(this.translateService.instant('ENTITE_GEO.ADDING'), null);

        const formData = new FormData();
        formData.append('data', ObjectMapper.serialize(data).toString());
        if (logo) {
            formData.append('logo', logo);
        }

        if (coverImage) {
            formData.append('imageCouverture', coverImage);
        }

        return this.http.post<EntiteJuridiquePostInterface>(this.config.baseUrl + 'api/entite_geographique', formData)
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteGeographique, r)),
                tap(entiteGeographique => {
                    const entitesGeographiques = this._entitesGeographiques.getValue();
                    entitesGeographiques.push(entiteGeographique);
                    this._entitesGeographiques.next(entitesGeographiques);
                    this.snackbar.open(this.translateService.instant('ENTITE_GEO.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
    }

    public updateEntiteGeographique(data: EntiteGeographiquePostInterface, logo?: File, coverImage?: File): Observable<EntiteGeographique> {
        this.snackbar.open(this.translateService.instant('ENTITE_GEO.UPDATING'), null);

        const formData = new FormData();
        formData.append('data', ObjectMapper.serialize(data).toString());
        if (logo) {
            formData.append('logo', logo);
        }

        if (coverImage) {
            formData.append('imageCouverture', coverImage);
        }

        return this.http.post<EntiteJuridiquePostInterface>(this.config.baseUrl + 'api/entite_geographique/' + data.id.toString(), formData)
            .pipe(
                map(r => ObjectMapper.deserialize(EntiteGeographique, r)),
                tap(entiteGeographique => {
                    const entitesGeographiques = this._entitesGeographiques.getValue();
                    const entiteGeographiqueIndex = entitesGeographiques.findIndex(eg => eg.id === entiteGeographique.id);
                    if (entiteGeographiqueIndex !== -1) {
                        entitesGeographiques[entiteGeographiqueIndex] = entiteGeographique;
                    }

                    this._entitesGeographiques.next(entitesGeographiques);
                    this.utilisateurService.updateEntiteGeographiqueForUtilisateurConnected(entiteGeographique);
                    this.snackbar.open(this.translateService.instant('ENTITE_GEO.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
    }

    public deleteEntiteGeographique(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('ENTITE_GEO.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('ENTITE_GEO.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('ENTITE_GEO.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(
            filter(confirmed => Boolean(confirmed)),
            switchMap(() => {
                this.snackbar.open(this.translateService.instant('ENTITE_GEO.DELETING'), null);
                return this.http.delete(this.config.baseUrl + 'api/entite_geographique/' + id.toString())
                    .pipe(tap(() => {
                        this.snackbar.open(this.translateService.instant('ENTITE_GEO.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                    }));
            }));
    }

    public openDialogEntiteGeographique(entiteGeographique: EntiteGeographique | any, disabled = false): MatDialogRef<EntiteGeographiqueDialogComponent> {
        const data: EntiteGeographiqueDialogInterface = {
            entiteGeographique: entiteGeographique instanceof EntiteGeographique ||
                Object.prototype.hasOwnProperty.call(entiteGeographique, 'hasPost') ? entiteGeographique :
                ObjectMapper.deserialize(EntiteGeographique, entiteGeographique),
            entiteGeographiqueService: this,
            disabled: disabled
        };
        return this.dialog.open(EntiteGeographiqueDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }
}

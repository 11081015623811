import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {
    @Input() diameter = 40;
    @Input() loadingMessage: string = null;
    @Input() onlySpinner = false;
    @Input() color = 'accent';
}

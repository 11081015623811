import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-user-accept-cgu-form',
    templateUrl: './user-accept-cgu-form.component.html',
    styleUrls: ['./user-accept-cgu-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserAcceptCGUFormComponent implements OnInit {
    @Output('formSubmited') formSubmited$ = new EventEmitter<boolean>();
    formInProgress$ = new BehaviorSubject(false);
    url: string = null;
    iframeLoaded = false;

    constructor(private utilisateurService: UtilisateurService) {}

    ngOnInit(): void {
        this.url = 'https://lnk.vet/cgu';
    }

    submit(): void {
        this.formInProgress$.next(true);
        this.utilisateurService.acceptCGUV().subscribe({
            next: () => {
                this.formSubmited$.emit(true);
                this.formInProgress$.next(false);
            },
            error: () => {
                this.formSubmited$.emit(false);
                this.formInProgress$.next(false);
            }
        });
    }

    iframeHasLoaded(): void {
        this.iframeLoaded = true;
    }
}

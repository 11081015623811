import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { StripeService } from '../services/api/stripe.service';
import { UtilisateurService } from '../services/api/utilisateur.service';

@Injectable({ providedIn: 'root' })
export class PayoutResolver implements Resolve<any> {
    constructor(
        private stripeService: StripeService,
        private utilisateurService: UtilisateurService
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const payoutId = route.queryParamMap.get('payout');
        if (payoutId) {
            return this.stripeService.getPayout(
                this.utilisateurService.utilisateurConnectedValue.entiteJuridique.id,
                payoutId
            );
        }

        return of(null);
    }
}

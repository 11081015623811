import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { fromEvent, Subscription } from 'rxjs';
import { News } from 'app/models/news/news';
import { NewsDataSource } from 'app/datasources/news.data-source';
import { NewsService } from 'app/services/api/news.service';
import { MatInput } from '@angular/material/input';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NewsSegmentsDataSource } from 'app/datasources/news-segments.data-source';
import { NewsSegmentsService } from 'app/services/api/news-segments.service';
import { NewsSegment } from 'app/models/news/news-segment';
import { UtilisateurService } from 'app/services/api/utilisateur.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() selectedIndex = 0;

    @ViewChild('newsFilter') newsFilter: ElementRef<MatInput>;
    @ViewChild('newsPaginator') newsPaginator: MatPaginator;
    @ViewChild('newsTableSort') newsSort: MatSort;

    @ViewChild('newsSegmentFilter') newsSegmentFilter: ElementRef<MatInput>;
    @ViewChild('newsSegmentPaginator') newsSegmentPaginator: MatPaginator;
    @ViewChild('newsSegmentSort') newsSegmentSort: MatSort;

    newsDataSource?: NewsDataSource;
    newsSegmentsDataSource?: NewsSegmentsDataSource;
    newsDisplayedColumns = ['name', 'createdAt', 'nbClients', 'actions'];
    newsSegmentDisplayedColumns = ['name', 'nbFilters', 'nbNews', 'nbClients', 'updatedAt', 'actions'];

    private readonly currentRoute = '/news/';
    private tabSubscriptions: Subscription[] = [];
    private subscriptions: Subscription[] = [];

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly newsService: NewsService,
        private readonly newsSegmentsService: NewsSegmentsService,
        private readonly utilisateurService: UtilisateurService
    ) {}

    ngOnInit(): void {
        this.newsDataSource = new NewsDataSource(this.newsService);
        this.newsSegmentsDataSource = new NewsSegmentsDataSource(this.newsSegmentsService);
        const routeParamsSubscription = this.route.params.subscribe(params => {
            switch (params.id) {
                case 'published':
                    this.selectedIndex = 0;
                    this.newsDisplayedColumns = ['name', 'createdAt', 'publishedDate', 'nbClients', 'views', 'clicks', 'actions'];
                    break;
                case 'planned':
                    this.selectedIndex = 1;
                    this.newsDisplayedColumns = ['name', 'createdAt', 'publishedDate', 'nbClients', 'actions'];
                    break;
                case 'draft':
                    this.selectedIndex = 2;
                    this.newsDisplayedColumns = ['name', 'createdAt', 'nbClients', 'actions'];
                    break;
                case 'archived':
                    this.selectedIndex = 3;
                    this.newsDisplayedColumns = ['name', 'createdAt', 'publishedDate', 'archivedDate', 'nbClients', 'views', 'clicks', 'actions'];
                    break;
                case 'segments':
                    this.selectedIndex = 4;
                    break;
                default:
                    void this.router.navigate([this.currentRoute + 'published']);
                    break;
            }

            const newsFilter = this.newsFilter?.nativeElement;
            const newsSegmentFilter = this.newsSegmentFilter?.nativeElement;
            if (newsFilter) {
                newsFilter.value = null;
            }

            if (newsSegmentFilter) {
                newsSegmentFilter.value = null;
            }

            this.loadData();
        });
        if (routeParamsSubscription) {
            this.subscriptions.push(routeParamsSubscription);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.tabSubscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.newsDataSource?.disconnect();
        this.newsSegmentsDataSource?.disconnect();
    }

    ngAfterViewInit(): void {
        this.setTabSubscriptions();
    }

    onSelectedTabChanged(event: MatTabChangeEvent): void {
        switch (event.index) {
            case 0:
                void this.router.navigate([this.currentRoute + 'published']);
                break;
            case 1:
                void this.router.navigate([this.currentRoute + 'planned']);
                break;
            case 2:
                void this.router.navigate([this.currentRoute + 'draft']);
                break;
            case 3:
                void this.router.navigate([this.currentRoute + 'archived']);
                break;
            case 4:
                void this.router.navigate([this.currentRoute + 'segments']);
                break;
            default:
                break;
        }

        this.tabSubscriptions.forEach(s => {
            s.unsubscribe();
        });
        this.selectedIndex = event.index;
        this.setTabSubscriptions();
        this.loadData();
    }

    // News Actions

    showAddOrEditNewsModal(news?: News, duplicate = false): void {
        const addOrEditDialogRef = this.newsService.openAddOrEditDialog({ news, duplicate });
        const dialogSubscription = addOrEditDialogRef.afterClosed().subscribe((hasChanged: boolean) => {
            if (hasChanged) {
                this.loadData();
            }
        });
        if (dialogSubscription) {
            this.subscriptions.push(dialogSubscription);
        }
    }

    showDuplicateConfirmation(news: News): void {
        this.showAddOrEditNewsModal(news, true);
    }

    showArchiveConfirmation(news: News): void {
        const archiveSubscription = this.newsService.archive(news.id).subscribe(() => {
            this.loadData();
        });

        if (archiveSubscription) {
            this.subscriptions.push(archiveSubscription);
        }
    }

    showDeleteConfirmation(news: News): void {
        const deleteSubscription = this.newsService.delete(news.id).subscribe(() => {
            this.loadData();
        });

        if (deleteSubscription) {
            this.subscriptions.push(deleteSubscription);
        }
    }

    // News Segment Actions

    showAddOrEditSegmentModal(segment?: NewsSegment, duplicate = false): void {
        const addOrEditDialogRef = this.newsSegmentsService.openAddOrEditDialog({ segment, duplicate });
        const dialogSubscription = addOrEditDialogRef.afterClosed().subscribe((hasChanged: boolean) => {
            if (hasChanged) {
                this.loadData();
            }
        });
        if (dialogSubscription) {
            this.subscriptions.push(dialogSubscription);
        }
    }

    showDuplicateSegmentConfirmation(segment: NewsSegment): void {
        this.showAddOrEditSegmentModal(segment, true);
    }

    showDeleteSegmentConfirmation(segment: NewsSegment): void {
        const deleteSubscription = this.newsSegmentsService.delete(segment.id).subscribe(() => {
            this.loadData();
        });

        if (deleteSubscription) {
            this.subscriptions.push(deleteSubscription);
        }
    }

    // Track by for row

    trackByFn(_index: number, item: News | NewsSegment): number {
        return item.id;
    }

    // Private functions

    private setTabSubscriptions(): void {
        const sort = this.selectedIndex < 4 ? this.newsSort : this.newsSegmentSort;
        const paginator = this.selectedIndex < 4 ? this.newsPaginator : this.newsSegmentPaginator;
        const filter = this.selectedIndex < 4 ? this.newsFilter : this.newsSegmentFilter;

        // Sort
        const sortSubscription = sort?.sortChange?.subscribe(() => {
            paginator.pageIndex = 0;
            this.loadData();
        });

        if (sortSubscription) {
            this.tabSubscriptions.push(sortSubscription);
        }

        // Paginator
        const paginatorSubscription = paginator?.page?.subscribe((event: PageEvent) => {
            if (event.previousPageIndex === event.pageIndex) {
                paginator.pageIndex = 0;
            }

            this.loadData();
        });

        if (paginatorSubscription) {
            this.tabSubscriptions.push(paginatorSubscription);
        }

        // Filter
        if (filter?.nativeElement) {
            const filterSubscription = fromEvent(filter.nativeElement as any, 'keyup').pipe(
                distinctUntilChanged(),
                debounceTime(150)
            ).subscribe(() => {
                paginator.pageIndex = 0;
                this.loadData();
            });

            if (filterSubscription) {
                this.tabSubscriptions.push(filterSubscription);
            }
        }
    }

    private loadData(): void {
        let status = 'published';
        switch (this.selectedIndex) {
            case 1:
                status = 'planned';
                break;
            case 2:
                status = 'draft';
                break;
            case 3:
                status = 'archived';
                break;
            case 4:
                status = 'segment';
                break;
            default:
                break;
        }

        if (status === 'segment') {
            this.newsSegmentsDataSource?.load(
                this.newsSegmentFilter?.nativeElement.value,
                this.newsSegmentSort?.direction ?? 'desc',
                this.newsSegmentSort?.active ?? 'updatedAt',
                this.newsSegmentPaginator?.pageIndex ?? 0,
                this.newsSegmentPaginator?.pageSize ?? 20,
                this.utilisateurService.utilisateurConnectedValue.entiteGeographique.id
            );
        } else {
            this.newsDataSource?.load(
                this.newsFilter?.nativeElement.value,
                status,
                this.newsSort?.direction ?? 'asc',
                this.newsSort?.active ?? 'name',
                this.newsPaginator?.pageIndex ?? 0,
                this.newsPaginator?.pageSize ?? 20
            );
        }
    }
}

import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'onboarding-client-end',
    templateUrl: './onboarding-client-end.component.html',
    styleUrls: ['./onboarding-client-end.component.scss'],
    animations: fuseAnimations
})
export class OnboardingClientEndComponent implements OnInit {
    phone: string;
    platform = 'other';

    constructor(
        private deviceService: DeviceDetectorService,
        private fuseConfigService: FuseConfigService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            if (queryParams.has('phone')) {
                this.phone = queryParams.get('phone');
                void this.router.navigate([], {
                    queryParams: {
                        phone: null
                    },
                    queryParamsHandling: 'merge'
                });
            }
        });

        if (this.deviceService.os === 'Android') {
            this.platform = 'Android';
        } else if (this.deviceService.os === 'iOS') {
            this.platform = 'iOS';
        }
    }

    redirect(): void {
        void this.router.navigate(['/']);
    }
}

import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChatShortcut } from 'app/models/pro/entite-juridique';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EntiteJuridiqueService } from 'app/services/api/entite-juridique.service';
import { Utils } from 'app/utils';

@Component({
    selector: 'app-shortcut-dialog',
    templateUrl: './shortcut-dialog.component.html',
    styleUrls: ['./shortcut-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ShortcutDialogComponent {
    maxLengthShorcut = 32;
    shortcut: ChatShortcut;
    form: FormGroup;
    filteredCategories: string[] = [];
    private allCategories: Set<string>;

    constructor(
        public dialogRef: MatDialogRef<ShortcutDialogComponent>,
        private readonly translateService: TranslateService,
        private readonly entiteJuridiqueService: EntiteJuridiqueService,
        @Inject(MAT_DIALOG_DATA) private data: ShortcutDialogInterface
    ) {}

    ngOnInit(): void {
        this.allCategories = new Set(this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.chatShortcuts.filter(c => !!c.category).map(c => c.category).sort((a, b) => a < b ? -1 : 1));
        this.filteredCategories = [...this.allCategories];
        this.shortcut = this.data !== null && this.data !== undefined ? this.data.chatShortcut : null;

        const shortcutValue = this.shortcut?.shortcut;
        const categoryValue = this.shortcut?.category ?? this.translateService.instant('SHARED.OTHER');
        const contentValue = this.shortcut?.content;

        this.form = new FormGroup({
            shortcut: new FormControl(shortcutValue, [Validators.maxLength(this.maxLengthShorcut), Validators.required]),
            category: new FormControl(categoryValue),
            content: new FormControl(contentValue, [Validators.required])
        });

        this.form.get('shortcut').valueChanges.subscribe((value: string) => {
            if (value) {
                this.form.get('shortcut').setValue(
                    value
                        .trim()
                        .replace('/', '')
                        .replace(/ /g, '')
                        .toLowerCase(),
                    { emitEvent: false }
                );
            }
        });

        this.form.get('category').valueChanges.subscribe((value: string) => {
            if (value) {
                this.filteredCategories = [...this.allCategories].filter(c => Utils.removeDiacritics(c.toLowerCase()).includes(Utils.removeDiacritics(value.toLowerCase())));
            } else {
                this.filteredCategories = [...this.allCategories];
            }
        });
    }

    close(formValue: ChatShortcut = null): void {
        this.dialogRef.close(formValue);
    }

    submit(): void {
        if (this.form.valid && this.form.value !== null) {
            const val = new ChatShortcut();
            val.shortcut = this.form.value.shortcut.trim().replace('/', '').toLowerCase();
            val.category = this.form.value.category.trim();
            val.content = this.form.value.content.trim();
            this.close(val);
        }
    }
}

export interface ShortcutDialogInterface {
    chatShortcut: ChatShortcut;
}

<div id="subscriberContainer" #subscriberContainer (click)="pointerClick($event)">
  <div id="subscriberDivContainer" [ngStyle]="videoOrientationTransform">
    <div #subscriberDiv></div>
  </div>
  <div #pointersLayer id="pointersLayer" [ngStyle]="videoOrientationTransform">
    <div id="pointerContainer">
      <app-visio-pointer [ngStyle]="{'display': pointerDisplay, 'left': pointerX + 'px', 'top': pointerY + 'px'}"></app-visio-pointer>
    </div>
  </div>
  <div id="actionsLayer">
    <div id="actionsTop"></div>
    <div id="actionsBottom">
        <button mat-mini-fab (click)="sendClearPointerSignal($event)" color="white">
            <mat-icon
                      matTooltip="{{ 'VISIO_OPTION.POINTER_DELETE' | translate }}" matTooltipPosition="below"
                      svgIcon="eraser"></mat-icon>
        </button>
        <button mat-fab (click)="sendTakePhotoSignal($event)" color="white">
            <mat-icon>camera_alt</mat-icon>
        </button>
        <button mat-mini-fab (click)="sendToggleFlashSignal($event)" color="white">
            <mat-icon>flash_on</mat-icon>
        </button>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { RendezVousListCardComponent } from './rendez-vous-list-card.component';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { RendezVousTileComponent } from './rendez-vous-tile/rendez-vous-tile.component';

@NgModule({
    declarations: [
        RendezVousListCardComponent,
        RendezVousTileComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        SharedModule,
        ViewSharedModule
    ],
    exports: [RendezVousListCardComponent]
})
export class RendezVousListCardModule {}

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChatService } from '../services/api/chat.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ChatIdResolver implements Resolve<any> {
    constructor(private chatService: ChatService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.chatService.setChannelSelectedByChatId(Number(route.params.chatId)).pipe(
            catchError((err: Error) => {
                if (Object.prototype.hasOwnProperty.call(err, 'message') && Object.prototype.hasOwnProperty.call(err, 'code')) {
                    return of(true);
                }

                return throwError(() => err);
            })
        );
    }
}

import { CalendarDateFormatter, DateAdapter, DateFormatterParams } from 'angular-calendar';
import { TranslateService } from '@ngx-translate/core';
import { DateLocalePipe } from '../../../../pipes/date-locale.pipe';

export class CalendarDateFormatterTranslated extends CalendarDateFormatter {
    constructor(dateAdapter: DateAdapter, private translateService: TranslateService) {
        super(dateAdapter);
    }

    public monthViewTitle({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'MMMM y');
    }

    public weekViewTitle({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'd MMMM y');
    }

    public weekViewColumnSubHeader({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'd MMM');
    }

    public dayViewTitle({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'fullDate');
    }

    public dayViewHour({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'HH\'h\'');
    }

    public weekViewHour({ date }: DateFormatterParams): string {
        return new DateLocalePipe(this.translateService).transform(date, 'HH\'h\'');
    }
}

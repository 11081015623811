<div fxLayout="column" fxLayoutAlign="start center" class="p-8" fxLayoutGap="16px">
    <!-- <button *ngIf="(visioService.currentStatut | async) != 'ongoing'" mat-fab color="accent" (click)="hangIn()"
            [matTooltip]="'VISIO.CALL' | translate" matTooltipPosition="above">
        <mat-icon>call</mat-icon>
    </button> -->

    <button
            *ngIf="(visioService.currentStatut | async) == 'ongoing' || (visioService.currentStatut | async) == 'ringing'"
            mat-fab color="warn" (click)="hangOut()" [matTooltip]="'VISIO.CALL_STOP' | translate"
            matTooltipPosition="above">
        <mat-icon>call_end</mat-icon>
    </button>

  <!-- <button mat-mini-fab [color]="'recall'" (click)="recall()" class="mb-32" matTooltip="Rappeler" matTooltipPosition="above">
    <mat-icon aria-label="Rappeler" svgIcon="retry_call"></mat-icon>
  </button> -->

    <button mat-mini-fab color="white" (click)="toggleSound()" [matTooltip]="'VISIO_OPTION.VOLUME' | translate"
              matTooltipPosition="above">
          <mat-icon>{{ silent ? 'volume_off' : 'volume_up' }}</mat-icon>
    </button>

    <!--<button mat-mini-fab [color]="record ? 'warn' : 'white'" [matTooltip]="'VISIO_OPTION.RECORD' | translate"-->
    <!--(click)="toggleRecord()" matTooltipPosition="above"-->
    <!--[disabled]="!(visioService.canRecord | async) || recordInProgress">-->
    <!--<mat-icon>{{ record ? 'stop' : 'fiber_manual_record' }}</mat-icon>-->
    <!--</button>-->

    <button [disabled]="(visioService.currentStatut | async) !== 'ongoing'" *ngIf="(visioService.currentRendezVous | async)?.mustPay" mat-mini-fab [matTooltip]="'VISIO_OPTION.CONFIRM' | translate"
            (click)="createCharge()" matTooltipPosition="above" color="white">
        <mat-icon>attach_money</mat-icon>
    </button>

    <button *ngIf="utilisateurService?.utilisateurConnectedValue?.entiteJuridique?.hasVisioCare && !(visioService.isStreamingFile | async)" [disabled]="(visioService.currentStatut | async) !== 'ongoing'" mat-mini-fab [matTooltip]="((visioService.isStreamingFile | async) ? 'VISIO.MEDIPROD.STOP_PUBLISH_VIDEO' : 'VISIO.MEDIPROD.START_PUBLISH_VIDEO') | translate" matTooltipPosition="above" color="white" (click)="togglePublishVideo()">
        <mat-icon svgIcon="visiocare"></mat-icon>
    </button>

    <button *ngIf="utilisateurService?.utilisateurConnectedValue?.entiteJuridique?.hasVisioCare && (visioService.isStreamingFile | async)" [disabled]="(visioService.currentStatut | async) !== 'ongoing'" mat-mini-fab [matTooltip]="((visioService.isStreamingFile | async) ? 'VISIO.MEDIPROD.STOP_PUBLISH_VIDEO' : 'VISIO.MEDIPROD.START_PUBLISH_VIDEO') | translate" matTooltipPosition="above" color="white" (click)="togglePublishVideo()">
        <mat-icon [style.color]="'red'">stop</mat-icon>
    </button>

    <button *ngIf="(visioService.isStreamingFile | async) && (chatService.chatSelected | async)" mat-mini-fab [matTooltip]="'VISIOCARE.SEND_CHAT' | translate" matTooltipPosition="above" color="white" (click)="sendLinkToChat()">
        <mat-icon>forward_to_inbox</mat-icon>
    </button>
</div>

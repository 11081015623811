<div class="publisher-container">
    <div fxLayout="row" fxLayoutAlign="center center" style="width: 100%; height: 100%;">
        <div [ngClass]="{'publishing': publishing}" #publisherDiv></div>
    </div>
    <div *ngIf="(visioService.permissionsStatus | async) === DevicePermission.NoDevicesFound || (visioService.permissionsStatus | async) === DevicePermission.NoPermission" class="publisher-message">
        <div class="mb-8">{{ ((visioService.permissionsStatus | async) === DevicePermission.NoDevicesFound ? 'VISIO.NO_DEVICES_FOUND' : 'VISIO.NO_PERMISSION') | translate }}</div>
        <button mat-stroked-button (click)="refresh()">{{ 'SHARED.REFRESH' | translate }}</button>
    </div>
    <div *ngIf="(visioService.permissionsStatus | async) === DevicePermission.Ok" class="publisher-action" fxLayout="row" fxLayoutAlign="space-between center">
        <div>
            <button mat-icon-button [matTooltip]="'VISIO.DIALOG_SETTINGS.TITLE' | translate" matTooltipPosition="below"
                (click)="openSettings()">
                <mat-icon>settings</mat-icon>
            </button>
        </div>
        <div>
            <button mat-icon-button [matTooltip]="'VISIO_OPTION.CAMERA' | translate" matTooltipPosition="below"
                (click)="toggleCamera()">
                <mat-icon>{{ cameraEnabled ? 'videocam' : 'videocam_off' }}</mat-icon>
            </button>
            <button mat-icon-button [matTooltip]="'VISIO_OPTION.MICRO' | translate" matTooltipPosition="below"
                (click)="toggleMic()">
                <mat-icon>{{ micEnabled ? 'mic' : 'mic_off' }}</mat-icon>
            </button>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';
import { Animal, ListAnimaux } from '../../models/animal/animal';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { AnimalPostInterface } from '../../models/interfaces/post/animal/animal-post.interface';
import { ObjectMapper } from 'json-object-mapper';
import {
    AnimalDialogComponent,
    AnimalDialogInterface
} from '../../main/shared/form-dialog/animal-dialog/animal-dialog.component';
import { Fichier, ListFichiers } from '../../models/fichier';
import { DialogComponent } from '../../main/shared/view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Client } from '../../models/utilisateurs/client';

@Injectable({
    providedIn: 'root'
})
export class AnimalService {
    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    public getAllForClient(id: number): Observable<ListAnimaux> {
        return this.http.get<ListAnimaux>(this.config.baseUrl + 'api/client/' + id.toString() + '/animaux').pipe(
            map(animaux => ObjectMapper.deserialize(ListAnimaux, animaux))
        );
    }

    public getById(id: number): Observable<Animal> {
        return this.http.get<Animal>(this.config.baseUrl + 'api/animal/' + id.toString()).pipe(
            map(animal => ObjectMapper.deserialize(Animal, animal)),
            tap(animal => {
                animal.proprietaire = ObjectMapper.deserialize(Client, animal.proprietaire);
            })
        );
    }

    public addAnimal(data: AnimalPostInterface): Observable<Animal> {
        this.snackbar.open(this.translateService.instant('ANIMAL.ADDING'), null, { duration: 1500 });
        data.dateNaissance = data.dateNaissance ? Math.round((data.dateNaissance as Date).getTime() / 1000) : null;
        data.dateDeces = data.dateDeces ? Math.round((data.dateDeces as Date).getTime() / 1000) : null;
        data.id = 0;

        return this.http.post<Animal>(this.config.baseUrl + 'api/animal', data)
            .pipe(
                map(animal => ObjectMapper.deserialize(Animal, animal)),
                tap(animal => {
                    animal.proprietaire = ObjectMapper.deserialize(Client, animal.proprietaire);
                }),
                tap(() => {
                    this.snackbar.open(this.translateService.instant('ANIMAL.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
    }

    public updateAnimal(data: AnimalPostInterface): Observable<Animal> {
        this.snackbar.open(this.translateService.instant('ANIMAL.UPDATING'), null, { duration: 1500 });
        data.dateNaissance = data.dateNaissance ? Math.round((data.dateNaissance as Date).getTime() / 1000) : null;
        data.dateDeces = data.dateDeces ? Math.round((data.dateDeces as Date).getTime() / 1000) : null;

        return this.http.put<Animal>(this.config.baseUrl + 'api/animal/' + data.id.toString(), data)
            .pipe(
                map(animal => ObjectMapper.deserialize(Animal, animal)),
                tap(animal => {
                    animal.proprietaire = ObjectMapper.deserialize(Client, animal.proprietaire);
                }),
                tap(() => {
                    this.snackbar.open(this.translateService.instant('ANIMAL.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
    }

    public setPhoto(id: number, image?: Blob): Observable<Animal> {
        this.snackbar.open(this.translateService.instant('ANIMAL.PHOTO_UPDATING'), null, { duration: 1500 });
        const formData: FormData = new FormData();
        if (image instanceof Blob) {
            formData.append('photo', image);
        }

        return this.http.post<Animal>(this.config.baseUrl + 'api/animal/' + id.toString() + '/photo', formData).pipe(
            map(animal => ObjectMapper.deserialize(Animal, animal)),
            tap(animal => {
                animal.proprietaire = ObjectMapper.deserialize(Client, animal.proprietaire);
            }),
            tap(() => {
                this.snackbar.open(this.translateService.instant('ANIMAL.PHOTO_UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public deleteAnimal(id: number): Observable<any> {
        const dialog = this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('ANIMAL.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('ANIMAL.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('ANIMAL.DIALOG_DELETE.OK')
            },
            disableClose: true
        });

        return dialog.afterClosed().pipe(filter(confirmed => Boolean(confirmed)), switchMap(() => {
            this.snackbar.open(this.translateService.instant('ANIMAL.DELETING'), null);
            return this.http.delete(this.config.baseUrl + 'api/animal/' + id.toString())
                .pipe(tap(() => {
                    this.snackbar.open(this.translateService.instant('ANIMAL.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
        }));
    }

    public openDialogAnimal(animal: Animal | any): MatDialogRef<AnimalDialogComponent> {
        const data: AnimalDialogInterface = {
            animal: animal instanceof Animal || Object.prototype.hasOwnProperty.call(animal, 'hasPost') ? animal : ObjectMapper.deserialize(Animal, animal),
            animalService: this
        };
        return this.dialog.open(AnimalDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public getFichiers(id: number, offset = 0, limit = 20, type: string = null): Observable<ListFichiers> {
        let params = new HttpParams();
        if (offset) {
            params = params.append('offset', offset.toString());
        }

        if (limit >= 0) {
            params = params.append('limit', (limit ? limit : 0).toString());
        }

        if (type !== null) {
            params = params.append('type', type);
        }

        return this.http.get<ListFichiers>(this.config.baseUrl + 'api/animal/' + id.toString() + '/fichiers', {
            params: params
        }).pipe(
            map(fichier => ObjectMapper.deserialize(ListFichiers, fichier))
        );
    }

    public uploadFile(id: number, file: File): Observable<Fichier> {
        if (!(file instanceof Blob)) {
            return;
        }

        this.snackbar.open(this.translateService.instant('ANIMAL.FILE_UPDATING'), null, { duration: 1500 });
        const formData: FormData = new FormData();
        formData.append('fichier', file, file.name);
        return this.http.post(this.config.baseUrl + 'api/animal/' + id.toString() + '/upload', formData).pipe(
            map(fichier => ObjectMapper.deserialize(Fichier, fichier)),
            tap(() => {
                this.snackbar.open(this.translateService.instant('ANIMAL.FILE_UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }
}

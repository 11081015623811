import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { RendezVous, ViewStatusRendezVousEnum } from '../../../models/rendez-vous/rendez-vous';
import { Router } from '@angular/router';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { ListMetaInterface } from '../../../models/interfaces/api/list-meta.interface';
import { Client } from '../../../models/utilisateurs/client';
import { Animal } from '../../../models/animal/animal';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { AnimalService } from '../../../services/api/animal.service';

@Component({
    selector: 'app-rendez-vous-list-card',
    templateUrl: './rendez-vous-list-card.component.html',
    styleUrls: ['./rendez-vous-list-card.component.scss']
})
export class RendezVousListCardComponent implements OnInit, OnDestroy {
    rendezVous$ = new BehaviorSubject<RendezVous[]>([]);
    rendezVousMeta: ListMetaInterface;
    countString = '';

    visioInProgress: boolean;
    loading: boolean;
    loadingAuto: boolean;
    loadingNext: boolean;
    canLoadMore: boolean;

    @Input() count = 20;
    @Input() loadMore = false;
    @Input() period: RendezVousListPeriod;

    private timer = 60;
    private autoRefresh$: Subscription;
    private fetchSubscription: Subscription;

    constructor(
        private rendezVousService: RendezVousService,
        private utilisateurService: UtilisateurService,
        private animalService: AnimalService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.loadRendezVous(this.count);
    }

    ngOnDestroy(): void {
        this.autoRefresh$?.unsubscribe();
        this.fetchSubscription?.unsubscribe();
    }

    loadVisio(rendezVous: RendezVous): void {
        this.visioInProgress = true;
        void this.router.navigate(['/visio', rendezVous.id]);
    }

    editRendezVous(rendezVous: RendezVous = null): void {
        if (!rendezVous) {
            rendezVous = new RendezVous();
            rendezVous.veterinaire = this.utilisateurService.utilisateurConnectedValue;
        }

        this.rendezVousService.openDialogRendezVous(rendezVous).afterClosed().subscribe(result => {
            if (result && result instanceof RendezVous) {
                this.loading = true;
                this.rendezVous$.next([]);
                this.loadRendezVous(this.count * (this.rendezVousMeta ? this.rendezVousMeta.offset : 0));
            } else if (result === 'deleted') {
                const rendezVousValue = this.rendezVous$.value;
                const index = rendezVousValue.findIndex(r => r.id === rendezVous.id);
                if (index !== -1) {
                    rendezVousValue.splice(index, 1);
                    this.rendezVous$.next(rendezVousValue);
                }
            }
        });
    }

    editClient(rendezVous: RendezVous): void {
        this.utilisateurService.openDialogClient(rendezVous.client).afterClosed().subscribe((result: Client) => {
            if (result) {
                rendezVous.client = result;
            }
        });
    }

    editAnimal(rendezVous: RendezVous): void {
        this.animalService.openDialogAnimal(rendezVous.animal).afterClosed().subscribe((result: Animal) => {
            if (result) {
                rendezVous.animal = result;
            }
        });
    }

    next(): void {
        if (this.canLoadMore) {
            this.loadingNext = true;
            this.loadRendezVous(this.count * (this.rendezVousMeta ? this.rendezVousMeta.offset + 1 : 0));
        }
    }

    capture(rendezVous: RendezVous): void {
        if (rendezVous.mustCapture) {
            this.rendezVousService.openDialogCaptureRendezVous(rendezVous).afterClosed().subscribe((updatedRendezVous: RendezVous) => {
                if (updatedRendezVous) {
                    rendezVous.mustCapture = updatedRendezVous.mustCapture;
                }
            });
        }
    }

    refresh(): void {
        this.loadingAuto = true;
        this.loadRendezVous(this.count * (this.rendezVousMeta ? this.rendezVousMeta.offset : 0));
    }

    private loadRendezVous(limit: number, offset = 0): void {
        let startDate = new Date();
        let stopDate: Date = null;
        let order = 'asc';
        let onlyNext = false;

        switch (this.period) {
            case RendezVousListPeriod.PAST:
                startDate.setDate(startDate.getDate() - 7);
                stopDate = new Date();
                stopDate.setDate(stopDate.getDate() + 1);
                order = 'desc';
                onlyNext = false;
                break;

            case RendezVousListPeriod.TODAY:
                startDate = new Date();
                startDate.setHours(0, 0, 0, 0);
                stopDate = new Date();
                stopDate.setDate(stopDate.getDate() + 1);
                stopDate.setHours(0, 0, 0, 0);
                order = 'asc';
                onlyNext = false;
                break;

            case RendezVousListPeriod.FUTUR:
                startDate = new Date();
                stopDate = null;
                order = 'asc';
                onlyNext = false;
                break;

            case RendezVousListPeriod.DONE:
                startDate = null;
                stopDate = null;
                order = 'desc';
                onlyNext = false;
                break;

            case RendezVousListPeriod.TODO:
                startDate = null;
                stopDate = null;
                order = 'asc';
                onlyNext = true;
                break;

            case RendezVousListPeriod.CANCELED:
                startDate = null;
                stopDate = new Date();
                order = 'asc';
                onlyNext = false;
                break;

            default:
                break;
        }

        this.fetchSubscription = this.rendezVousService.getAll(null, order, 'date', offset, limit, startDate, stopDate, onlyNext).subscribe({
            next: rendezVous => {
                this.loading = false;
                this.loadingNext = false;

                if (this.loadingAuto) {
                    this.rendezVous$.next([]);
                    this.loadingAuto = false;
                }

                this.rendezVous$.next([...this.rendezVous$.value, ...rendezVous.data]
                    .filter((r: RendezVous) => {
                        switch (this.period) {
                            case RendezVousListPeriod.TODAY: {
                                return !r.isFinished || r.mustCapture;
                            }

                            case RendezVousListPeriod.DONE: {
                                return r.getViewStatut() === ViewStatusRendezVousEnum.done;
                            }

                            case RendezVousListPeriod.CANCELED: {
                                return r.getViewStatut() === ViewStatusRendezVousEnum.not_done;
                            }

                            default:
                                return true;
                        }
                    })
                    .sort((a, b) => (a.date < b.date ? 1 : -1) * (this.period === RendezVousListPeriod.PAST || this.period === RendezVousListPeriod.DONE ? 1 : -1))
                );
                this.rendezVousMeta = rendezVous.meta;
                this.canLoadMore = this.loadMore && this.rendezVous$.value.length < this.rendezVousMeta.totalItems;

                this.countString = this.rendezVous$.value.length.toString() +
                    (
                        this.period !== RendezVousListPeriod.DONE &&
                        this.period !== RendezVousListPeriod.TODAY &&
                        this.period !== RendezVousListPeriod.CANCELED &&
                        this.rendezVous$.value.length !== this.rendezVousMeta.totalItems ? '/' + this.rendezVousMeta.totalItems.toString() : ''
                    );

                if (!this.autoRefresh$) {
                    this.autoRefresh$ = interval(this.timer * 1000).subscribe(_ => this.refresh());
                }
            },
            error: () => {
                this.loading = false;
                this.loadingNext = false;
                this.loadingAuto = false;
            }
        });
    }

    trackByFn(_index: number, item: RendezVous): number {
        return item.id;
    }
}

export enum RendezVousListPeriod {
    TODAY = 'TODAY',
    PAST = 'PAST',
    FUTUR = 'FUTUR',
    DONE = 'DONE',
    TODO = 'TODO',
    CANCELED = 'CANCELED'
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VisioService } from '../../../../services/visio.service';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { Fichier } from '../../../../models/fichier';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { Animal } from '../../../../models/animal/animal';
import { AnimalService } from '../../../../services/api/animal.service';
import { Subscription } from 'rxjs';
import { Session } from '@opentok/client';
import { Client } from '../../../../models/utilisateurs/client';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { ChatService } from '../../../../services/api/chat.service';
import { filter } from 'rxjs/operators';
import { ActionsMenuInterface } from '../../../shared/view-utils/actions-menu/actions-menu.component';
import { ObjectMapper } from 'json-object-mapper';
import { TranslateService } from '@ngx-translate/core';
import { ListFichierComponent, FichierGroupBy, FichierSortBy, FichierSelectionMode } from 'app/main/shared/list-fichier/list-fichier.component';

@Component({
    selector: 'app-visio-gestion',
    templateUrl: './visio-gestion.component.html',
    styleUrls: ['./visio-gestion.component.scss']
})

export class GestionComponent implements OnInit, OnDestroy {
    @ViewChild('fichier') fichierInput: ElementRef;
    @ViewChild('listFichier') listFichierComponent: ListFichierComponent;

    FichierSortBy = FichierSortBy;
    FichierGroupBy = FichierGroupBy;
    FichierSelectionMode = FichierSelectionMode;

    session: Session;
    rendezVous: RendezVous;
    images: Fichier[] = [];

    subscriptions: Subscription;
    fichiersInProgress: boolean;

    batteryLevel = '';

    clientActions: ActionsMenuInterface[] = [];
    animalActions: ActionsMenuInterface[] = [];
    rdvActions: ActionsMenuInterface[] = [];

    constructor(private visioService: VisioService,
        private rendezVousService: RendezVousService,
        private animalService: AnimalService,
        private utilisateurService: UtilisateurService,
        public chatService: ChatService,
        private translateService: TranslateService) {
        this.clientActions = [
            {
                title: this.translateService.instant('SHARED.DETAILS'),
                icon: 'info',
                onClick: _ => this.editClient()
            }
        ];
        this.animalActions = [
            {
                title: this.translateService.instant('SHARED.DETAILS'),
                icon: 'info',
                onClick: _ => this.editAnimal()
            }
        ];
        this.rdvActions = [
            { title: this.translateService.instant('CALENDAR.DATE_NEW'), icon: 'add', onClick: _ => this.newRdv() }
        ];
    }

    ngOnInit(): void {
        this.subscriptions = this.visioService.currentRendezVous.subscribe((rendezVous: RendezVous) => {
            if (rendezVous) {
                this.rendezVous = rendezVous;
            }
        });
        this.subscriptions.add(this.visioService.currentSession.pipe(filter(session => Boolean(session))).subscribe((session: Session) => {
            if (!this.session && session) {
                this.session = session;

                this.session.on('signal', event => {
                    if ((event.type as string) === 'signal:updatePhoto' && event.data) {
                            const fichier = ObjectMapper.deserialize(Fichier, JSON.parse(event.data));
                            this.images.unshift(fichier);
                            this.images = [...this.images];
                            this.listFichierComponent.addFile(fichier);
                        }
                });
            }
        }));
    }

    ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }

    editClient(): void {
        this.utilisateurService.openDialogClient(this.rendezVous.client).afterClosed().subscribe((result: Client) => {
            if (result) {
                this.rendezVous.client = result;
            }
        });
    }

    editAnimal(): void {
        this.animalService.openDialogAnimal(this.rendezVous.animal).afterClosed().subscribe((result: Animal) => {
            if (result) {
                this.rendezVous.animal = result;
            }
        });
    }

    newRdv(): void {
        const newRdv = new RendezVous();
        newRdv.client = this.rendezVous.client;
        newRdv.animal = this.rendezVous.animal;
        newRdv.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.rendezVousService.openDialogRendezVous(newRdv).afterClosed().subscribe(result => {
            if (result && result instanceof RendezVous) {
                    this.rendezVousService.createEventFromRendezVous(result);
                }
        });
    }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { FuseConfigService } from '../../../../@fuse/services/config.service';
import { BugService } from '../../../services/api/bug.service';
import { MatDialog } from '@angular/material/dialog';
import { ConnectionTestDialogComponent } from '../../shared/connection-test/connection-test-dialog.component';

@Component({
    selector: 'app-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
    error: string;
    loginInProgress: boolean;
    isHidden = false;
    hidePassword = true;

    version: string = environment.version;
    buildVersion: string = environment.buildVersion;
    apiVersion$: Observable<string>;

    @HostListener('window:popstate', ['$event'])
    onpopstate(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        window.history.back();
    }

    constructor(
        public dialog: MatDialog,
        private fuseConfigService: FuseConfigService,
        private bugService: BugService
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.dialog.open(ConnectionTestDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '95vw',
            maxWidth: '1024px',
            minHeight: '60vh',
            disableClose: true,
            data: {
                closable: false
            }
        });

        if (environment.dev || environment.demo || environment.preproduction) {
            this.apiVersion$ = this.bugService.getLastCommit();
        }
    }
}

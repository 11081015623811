import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss']
})
export class StepsComponent {
    @Input() currentStep: number;
    @Input() stepsCount: number;
    @Output() stepClick = new EventEmitter<number>();

    getCountArray(): number[] {
        const count: number[] = [];
        for (let index = 0; index < this.stepsCount; index++) {
            count.push(index);
        }

        return count;
    }

    goToStep(step: number): void {
        if (step < this.currentStep && this.currentStep + 1 < this.stepsCount) {
            this.stepClick.emit(step);
        }
    }
}

import { JsonProperty } from 'json-object-mapper';

export class WebsocketMessage<T = any> {
    @JsonProperty()
    readonly type: string = null;

    @JsonProperty()
    token?: string = null;

    @JsonProperty()
    data: T = null;
}

<div class="profile">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
      <app-avatar *ngIf="photo || entity" [url]="photo" [entity]="entity"></app-avatar>
      <div fxLayout="column" fxLayoutAlign="start start">
        <p><b>{{ title }}</b></p>
          <p fxLayout="row" fxLayoutAlign="start center">
          <ng-content></ng-content>
        </p>
      </div>
    </div>
    <app-actions-menu *ngIf="actions != null || actions?.length > 0" [id]="id" [actions]="actions"></app-actions-menu>
  </div>
</div>
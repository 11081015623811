<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'CONNECT_ALLYDVM.TITLE' | translate }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading; else formEl" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #formEl>
            <form fxLayout="column" [formGroup]="form" (submit)="submit()">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CONNECT_ALLYDVM.API_KEY' | translate }}</mat-label>
                    <input formControlName="apiKey" matInput [placeholder]="'CONNECT_ALLYDVM.API_KEY' | translate"/>
                    <mat-error *ngIf="form.get('apiKey').hasError('required')">
                        {{ 'SHARED.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CONNECT_ALLYDVM.APP_ID' | translate }}</mat-label>
                    <input formControlName="appId" matInput type="number"
                           [placeholder]="'CONNECT_ALLYDVM.APP_ID' | translate"/>
                    <mat-error *ngIf="form.get('appId').hasError('required')">
                        {{ 'SHARED.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'CONNECT_ALLYDVM.SOURCE_ID' | translate }}</mat-label>
                    <input formControlName="sourceId" matInput type="number"
                           [placeholder]="'CONNECT_ALLYDVM.SOURCE_ID' | translate"/>
                    <mat-error *ngIf="form.get('sourceId').hasError('required')">
                        {{ 'SHARED.REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>

                <div fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap="8px">
                    <button type="button" mat-flat-button color="warn" *ngIf="canDelete"
                            [disabled]="formInProgress" (click)="removeAll()">
                        <mat-icon>delete</mat-icon>
                        {{ 'SHARED.DELETE' | translate }}
                    </button>

                    <button type="submit" mat-raised-button color="accent" [disabled]="formInProgress || form.invalid">
                        <mat-icon>save</mat-icon>
                        {{ 'FORM.SAVE' | translate }}
                    </button>

                    <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                                 *ngIf="formInProgress"></mat-spinner>
                </div>
            </form>
        </ng-template>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'emptyField'
})
export class EmptyFieldPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(something?: string, override: string = null): string {
        if (something && something !== '') {
            return something;
        }

        if (override && override !== '') {
            return override;
        }

        return this.translateService.instant('SHARED.NOT_FILLED') as string;
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ chatScenarioMessage.message ? ('SETTINGS.MESSAGES.SHORTCUTS.TITLE_UPDATE' | translate) : ('SETTINGS.MESSAGES.SHORTCUTS.TITLE_NEW' | translate) }}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">

        <app-chat-scenario-message
            [shortcuts]="shortcuts"
            [form]="chatScenarioMessageForm"
            (addOrUpdate)="addOrUpdate($event)"
            (cancelProcess)="cancel()"
        ></app-chat-scenario-message>

    </div>
</div>

<div class="contact" [class.active]="active" fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="start center" class="mb-8">
        <app-avatar [entity]="animal"></app-avatar>

        <div fxLayout="column" class="w-100-p" style="overflow: hidden;">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <app-color-picker #colorPicker [color]="chat.color"
                                      (event)="onColorUpdated($event)"></app-color-picker>
                    <mat-icon *ngIf="chat.delayedMessagesCount > 0" class="ml-8"
                              [matTooltip]="'CHAT.DELAYED_MESSAGES' | translate">access_time
                    </mat-icon>
                    <div fxLayout="row wrap" fxLayoutAlign="start end">
                        <span class="text-bold font-size-18 pl-8">{{ chat.nom }}</span>
                        <span *ngIf="chat.categorie" class="chat-categorie font-size-14 pl-8">{{ chat.categorie.value }}</span>
                    </div>
                </div>

                <span class="unread-message-count" *ngIf="!chat.veterinaryHasRead">
                    1
                </span>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" class="entities-infos">
                <div class="entity-info" fxLayout="row" fxLayoutAlign="start center"><mat-icon fxFlex="0 0 auto">pets</mat-icon>&nbsp;<div fxFlex="1 1">{{ animal.nom }}</div></div>
                <div class="entity-info" fxLayout="row" fxLayoutAlign="start center"><mat-icon fxFlex="0 0 auto">person</mat-icon>&nbsp;<div fxFlex="1 1">{{ client | userFullName }}</div></div>
                <div class="entity-info" fxLayout="row" fxLayoutAlign="start center" *ngIf="!isMine">
                    <mat-icon fxFlex="0 0 auto">local_hospital</mat-icon>&nbsp;<div fxFlex="1 1">{{ chat.veterinaire.fullName }}</div>
                </div>
            </div>
        </div>
        <div fxLayout="column wrap" fxLayoutAlign="space-between center">
            <mat-icon *ngIf="chat.bookmarked" [matTooltip]="'CHAT.BOOKMARK.BOOKMARKED' | translate" color="accent">star</mat-icon>
            <button mat-icon-button [matMenuTriggerFor]="moreMenu" (click)="$event.stopPropagation();">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #moreMenu="matMenu">
                <button mat-menu-item (click)="togglePinChat(); $event.stopPropagation();">
                    <mat-icon>push_pin</mat-icon>
                    <span>{{ (isPinned() ? 'CHAT.UNPIN' : 'CHAT.PIN_MENU') | translate }}</span>
                </button>
                <button mat-menu-item (click)="openAnimal(); $event.stopPropagation();">
                    <mat-icon>pets</mat-icon>
                    <span>{{ 'PET.ANIMAL' | translate }}</span>
                </button>
                <button mat-menu-item (click)="openClient(); $event.stopPropagation();">
                    <mat-icon>person</mat-icon>
                    <span>{{ 'CLIENT.CLIENT' | translate }}</span>
                </button>
                <button type="button" mat-menu-item (click)="changeStatusChat(false); $event.stopPropagation();"
                        *ngIf="chat.canChat; else canNotChat">
                    <mat-icon>lock</mat-icon>
                    {{ 'CHAT.STATUS_DO_CLOSE' | translate }}
                </button>

                <ng-template #canNotChat>
                    <button class="open-button" type="button" mat-menu-item (click)="changeStatusChat(true)">
                        <mat-icon>lock_open</mat-icon>
                        {{ 'CHAT.STATUS_DO_OPEN' | translate }}
                    </button>
                </ng-template>
            </mat-menu>
        </div>
    </div>

    <div class="text-italic warn-fg"
         *ngIf="chat.isChatClosed">{{ 'CHAT.CLOSE_MESSAGE' | translate }}{{ chat.dateFin | dateLocale:'shortDate' }}</div>
    <div class="text-italic warn-fg"
         *ngIf="chat.isFutur">{{ 'CHAT.FUTURE_MESSAGE' | translate }}{{ chat.dateDebut | dateLocale:'shortDate' }}</div>

    <div class="mt-4 message" *ngIf="chat?.lastMessageDate" fxLayout="row wrap">
        <div fxFlex="0 0 auto" *ngIf="chat.lastMessageOwner">
            {{ isMine ? ('CHAT.YOU' | translate) : chat.lastMessageOwner | userFullName }}
            :
        </div>
        <div fxFlex="1 1 0%" class="message-preview">{{ chat | chatMessage }}</div>
        <div fxFlex="0 0 auto">({{ chat.lastMessageDate | relativeTime }})</div>
    </div>
</div>

<mat-accordion [multi]="true">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-icon>compare_arrows</mat-icon>
                <span>{{ 'PAYOUTS.TITLE' | translate }}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <mat-table class="payouts-table" #table [dataSource]="dataSource" [class.hidden]="(dataSource.loading$ | async) || (dataSource.isEmpty() | async)" matSort>
                <ng-container cdkColumnDef="amount">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        {{ 'PAYOUTS.COLUMNS.AMMOUNT' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <div>
                            {{ (element.amount / 100) | currencyLocale: element.currency }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="status">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        {{ 'PAYOUTS.COLUMNS.STATUT' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <div [ngSwitch]="element.status" class="{{ element.status }}">
                            <div *ngSwitchCase="'paid'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYOUTS.STATUS.PAID' | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'pending'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYOUTS.STATUS.PENDING' | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'in_transit'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYOUTS.STATUS.IN_TRANSIT' | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'canceled'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYOUTS.STATUS.CANCELED' | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'failed'">
                                <mat-chip-list  [matTooltip]="element.failureCodeText | translate" [matTooltipClass]="'tooltip-multiline'">
                                    <mat-chip>{{ 'PAYOUTS.STATUS.FAILED' | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="account">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        {{ 'PAYOUTS.COLUMNS.ACCOUNT' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <div>
                            {{ element.account }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="created">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        {{ 'PAYOUTS.COLUMNS.SEND_AT' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <div>
                            {{ element.created | dateLocale:'shortDate' }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="arrival_date">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        {{ 'PAYOUTS.COLUMNS.RECEIVED_AT' | translate }}
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element">
                        <div>
                            {{ element.arrival_date | dateLocale:'shortDate' }}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="action">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header class="w-120">
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let element" class="w-120" fxLayout="row" fxLayoutAlign="end center">
                        <button mat-button (click)="onDetailClick(element)">{{ 'SHARED.DETAILS' | translate }}</button>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>

            <div *ngIf="dataSource?.loading$ | async; else loaded" class="mt-8" fxLayout="column" fxLayoutAlign="center center"
                fxLayoutGap="8px">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="dataSource?.isEmpty() | async" class="primary-fg mt-8">
                    <div class="text-center m-8">
                        <p>{{ 'PAYOUTS.ANY' | translate }}</p>
                    </div>
                </div>
                <p [innerHTML]="'PAYOUTS.OLD_PAYOUT_SPEECH' | translate" class="text-center text-small grey-fg"></p>
            </ng-template>
        </div>
    </mat-expansion-panel>
</mat-accordion>

import { Pipe, PipeTransform } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(currencyCode: string): string {
        return getCurrencySymbol(currencyCode, 'narrow', this.translateService.currentLang);
    }
}

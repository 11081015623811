import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { TodosComponent } from './todos.component';
import { SharedModule } from '../../../shared.module';
import { ListTodosModule } from '../../shared/list-todos/list-todos.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'todos',
        component: TodosComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    }
];

@NgModule({
    declarations: [
        TodosComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule,

        ListTodosModule
    ],
    exports: []
})

export class TodosModule {}

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">assignment</mat-icon>
                <span class="logo-text h1">{{ 'REPORTS.REPORT.MAIN_TITLE' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->


        <!-- MAIN -->

        <!-- CONTENT CARD -->
        <mat-card class="mb-12" >

            <mat-card-header>
                <mat-card-title *ngIf="rendezVous">{{ 'REPORTS.REPORT.APPOINTMENT_SUBTITLE' | translate }} {{ rendezVous.date | date:'dd/MM/yyyy'}}</mat-card-title>
                <mat-card-subtitle *ngIf="rendezVous?.client && rendezVous?.animal">{{ rendezVous.client.civilite }}&nbsp;{{ rendezVous.client.nom | uppercase }}&nbsp;{{ rendezVous.client.prenom | titlecase }} | {{ rendezVous.animal.nom | titlecase }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="pl-12">

                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="mb-12">
                    <mat-button-toggle-group #group="matButtonToggleGroup" [(ngModel)]="displayedContent">
                        <mat-button-toggle #template_toggle_button [value]="contentType.Template" aria-label="Modèle"
                           [ngClass]="{'primary': template_toggle_button.checked, 'basic': !template_toggle_button.checked }"
                           *ngIf="clientReport.dateLastRecorded === null && vetReport.dateLastRecorded === null"
                        >
                            {{ 'REPORTS.REPORT.TOGGLE_BUTTON_TEMPLATE' | translate }}
                        </mat-button-toggle>
                        <mat-button-toggle #client_toggle_button [value]="contentType.Client" aria-label="Rapport client"
                           [ngClass]="{'primary': client_toggle_button.checked, 'basic': !client_toggle_button.checked }">
                            {{ 'REPORTS.REPORT.TOGGLE_BUTTON_REPORT_CLIENT' | translate }}
                        </mat-button-toggle>
                        <mat-button-toggle #vet_toggle_button [value]="contentType.Vet" aria-label="Rapport confrère"
                           [ngClass]="{'primary': vet_toggle_button.checked, 'basic': !vet_toggle_button.checked }">
                            {{ 'REPORTS.REPORT.TOGGLE_BUTTON_REPORT_COLLEAGUE' | translate }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>

                    <button mat-icon-button color="accent">
                        <a href="https://www.univet-veterinaire.com/contact/" target="_blank"><mat-icon>help_outline</mat-icon></a>
                    </button>
                </div>
                <div [ngStyle]="{'display' : (displayedContent==contentType.Template)?'block':'none'}">
                    <app-template-editor *ngIf="templates"
                         [templates]="templates"
                         (deleteTemplate)="deleteTemplate($event)"
                         (saveTemplate)="saveTemplate($event)">
                    </app-template-editor>
                </div>
                <div [ngStyle]="{'display' : (displayedContent==contentType.Client)?'block':'none'}">
                    <app-report-editor *ngIf="clientReport && note && rendezVous"
                        [report]="clientReport"
                        [note]="note"
                        [rdv]="rendezVous"
                        [disabledEmailSendingButton]="disabledEmailSendingButton"
                        (save)="saveReport($event)"
                        (sendEmail)="sendEmail($event)">
                    </app-report-editor>
                </div>
                <div [ngStyle]="{'display' : (displayedContent==contentType.Vet)?'block':'none'}">
                    <app-report-editor *ngIf="vetReport && note && rendezVous"
                        [report]="vetReport"
                        [note]="note"
                        [rdv]="rendezVous"
                        [disabledEmailSendingButton]="disabledEmailSendingButton"
                        (save)="saveReport($event)"
                        (sendEmail)="sendEmail($event)">
                    </app-report-editor>
                </div>


                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="mt-12">
                    <button mat-raised-button color="primary" (click)="quit()">
                        {{ 'REPORTS.REPORT.BUTTON_QUIT' | translate }}
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                </div>

            </mat-card-content>

        </mat-card>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->
</div>

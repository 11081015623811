import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConnecteurService } from 'app/services/api/connecteur.service';
import { Animal } from '../../../../models/animal/animal';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { Utilisateur } from '../../../../models/utilisateurs/utilisateur';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Veterinaire } from '../../../../models/utilisateurs/veterinaire';
import { Connecteur } from '../../../../models/interfaces/post/pro/connecteur.interface';

@Component({
    selector: 'app-connector-icon',
    templateUrl: './connector-icon.component.html',
    styleUrls: ['./connector-icon.component.scss']
})
export class ConnectorIconComponent implements OnInit, OnDestroy {
    visibleConnecteur = new BehaviorSubject<Connecteur>(null);

    @Input() entity: Animal | RendezVous | Utilisateur;

    private subscriptions: Subscription[] = [];

    constructor(public connecteurService: ConnecteurService) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.connecteurService.currentConnecteurForUtilisateurConnected.subscribe(connecteur => {
                this.visibleConnecteur.next(connecteur);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public get show(): Observable<boolean> {
        let feature = null;
        if (this.entity instanceof Animal) {
            feature = 'ANIMAL';
        } else if (this.entity instanceof RendezVous) {
            feature = 'APPOINTMENT';
        } else if (this.entity instanceof Utilisateur) {
            feature = this.entity instanceof Veterinaire ? 'VETERINARIAN' : 'CLIENT';
        }

        return combineLatest([
            this.connecteurService.isConnecteurEnabledForUtilisateurConnected,
            feature ? this.connecteurService.isConnecteurFeatureEnabled(feature) : of(true)
        ]).pipe(map(([a, b]) => {
            return a && b;
        }));
    }
}

import { Component, OnInit, Input } from '@angular/core';
import { EntiteJuridique } from '../../../../models/pro/entite-juridique';
import { PayoutDataSource } from './payout.data-source';
import { StripeService } from '../../../../services/api/stripe.service';
import { Payout } from '../../../../models/stripe/payout';
import { Router } from '@angular/router';

@Component({
    selector: 'app-payouts',
    templateUrl: './payouts.component.html',
    styleUrls: ['./payouts.component.scss']
})
export class PayoutsComponent implements OnInit {
    @Input() entiteJuridique: EntiteJuridique;

    displayedColumns: string[] = ['amount', 'status', 'account', 'created', 'arrival_date', 'action'];
    dataSource?: PayoutDataSource;

    constructor(
        private stripeService: StripeService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.dataSource = new PayoutDataSource(this.stripeService);
        this.dataSource.loadAll(this.entiteJuridique);
    }

    onDetailClick(payout: Payout): void {
        void this.router.navigate(['/payments'], { queryParams: { payout: payout.id } });
    }
}

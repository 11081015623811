import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ObjectMapper } from 'json-object-mapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntiteJuridique, EntiteJuridiqueStripe } from '../../models/pro/entite-juridique';
import { EntiteJuridiqueService } from './entite-juridique.service';
import { Payout } from '../../models/stripe/payout';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StripeService {
    constructor(
        private translateService: TranslateService, private http: HttpClient,
        private config: ConfigService, private snackbar: MatSnackBar,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {}

    public getInfos(entiteJuridiqueId: number): Observable<EntiteJuridiqueStripe> {
        return this.http.get<EntiteJuridiqueStripe>(this.config.baseUrl + 'api/entite_juridique/' + entiteJuridiqueId.toString() + '/stripe')
            .pipe(
                map(stripeInfos => {
                    const data = ObjectMapper.deserialize(EntiteJuridiqueStripe, stripeInfos);
                    data.entiteJuridique = this.entiteJuridiqueService.updateEntiteJuridiqueForUtilisateurConnected(data.entiteJuridique);
                    return data;
                })
            );
    }

    public getSeeAccountLink(entiteJuridiqueId: number): string {
        return `${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/stripe/goto`;
    }

    public createAccountFromCode(entiteJuridiqueId: number, code: string): Observable<EntiteJuridique> {
        return this.http.post<EntiteJuridique>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/stripe/create`, {
            code
        });
    }

    public getPayout(entiteJuridiqueId: number, payoutId: string): Observable<Payout> {
        return this.http.get<Payout>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/payout/${payoutId}`).pipe(
            map(payout => {
                return ObjectMapper.deserialize(Payout, payout);
            })
        );
    }

    public getPayouts(entiteJuridiqueId: number): Observable<Payout[]> {
        return this.http.get<Payout[]>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/payouts`).pipe(
            map(payouts => {
                for (let index = 0; index < payouts.length; index++) {
                    payouts[index] = ObjectMapper.deserialize(Payout, payouts[index]);
                }

                return payouts;
            })
        );
    }

    public async gotoStripe(sessionId: string): Promise<any> {
        const stripe = await loadStripe(environment.stripePublicKey);
        return stripe.redirectToCheckout({
            sessionId
        });
    }
}

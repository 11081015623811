import { Injectable } from '@angular/core';
import SendBird, {
    AdminMessage,
    BaseChannel,
    BaseMessageInstance,
    ChannelHandler,
    FileMessage,
    GroupChannel,
    GroupChannelListQuery,
    PreviousMessageListQuery,
    SendBirdError,
    SendBirdInstance,
    User,
    UserMessage
} from 'sendbird';

import { BehaviorSubject, Observable, Observer, of } from 'rxjs';
import { UtilisateurService } from './utilisateur.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FuseNavigationService } from '../../../@fuse/components/navigation/navigation.service';
import { ObjectMapper } from 'json-object-mapper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import {
    ChatDialogComponent,
    ChatDialogInterface
} from '../../main/shared/form-dialog/chat-dialog/chat-dialog.component';
import { Chat, TypeChatMessageEnum } from '../../models/animal/chat';
import { ChatPostInterface } from '../../models/interfaces/post/animal/chat-post.interface';
import { environment } from '../../../environments/environment';
import { Animal } from '../../models/animal/animal';
import { Client } from '../../models/utilisateurs/client';
import { TranslateService } from '@ngx-translate/core';
import { FichierService } from './fichier.service';
import { UserPreferenciesService } from '../user-preferencies.service';
import { UtilisateurPostInterface } from '../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Veterinaire } from '../../models/utilisateurs/veterinaire';
import { Fichier } from '../../models/fichier';
import { ChatMessage, FileChatMessagePayloadInterface } from '../../models/animal/chat-message';
import { TypeUtilisateurEnum, Utilisateur } from '../../models/utilisateurs/utilisateur';
import { Utils } from '../../utils';
import { ChatShortcut } from 'app/models/pro/entite-juridique';
import { ChatMessagePipe } from '../../pipes/chat/chat-message.pipe';

declare let Tinycon: any;

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private readonly sb: SendBirdInstance;
    private readonly channelHandler: ChannelHandler;
    private readonly widthThumbnail = 250;

    public _chats$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>([]);
    public _chatsBookmarksUpdate$: BehaviorSubject<Chat[]> = new BehaviorSubject<Chat[]>([]);

    private _typing$: BehaviorSubject<UtilisateurPostInterface | Veterinaire> = new BehaviorSubject(null);
    private _newMessage$: BehaviorSubject<ChatMessage> = new BehaviorSubject(null);
    private _userConnected$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private _chatSelected$: BehaviorSubject<Chat> = new BehaviorSubject<Chat>(null);
    private _channelSelectedMessage$: BehaviorSubject<ChatMessage[]> = new BehaviorSubject<ChatMessage[]>([]);
    private _totalUnread$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    private _chatMessagePipe: ChatMessagePipe;

    private shouldFetchChannels = true;
    private shouldFetchApi = true;

    constructor(
        private translateService: TranslateService,
        private fuseNavigationService: FuseNavigationService,
        private utilisateurService: UtilisateurService,
        private fichierService: FichierService,
        private domSanitizer: DomSanitizer,
        private snackbar: MatSnackBar,
        private router: Router,
        private config: ConfigService,
        private http: HttpClient,
        private dialog: MatDialog,
        private userPreferenciesService: UserPreferenciesService,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this._chatMessagePipe = new ChatMessagePipe(this.translateService);

        this.chats.subscribe(_ => this.computeTotalUnread());

        this.sb = new SendBird({ appId: environment.sendbirdAppId });

        this.channelHandler = new this.sb.ChannelHandler();

        this.channelHandler.onMessageReceived = (channel: BaseChannel, message: BaseMessageInstance) => {
            const chat = this.updateChannel(channel as GroupChannel);
            this.addOrUpdateChatSelectedOneMessage(this.createOurChatMessage(message, chat), chat);
        };

        this.channelHandler.onMessageUpdated = (channel: BaseChannel, message: BaseMessageInstance) => {
            const chat = this.updateChannel(channel as GroupChannel);
            this.addOrUpdateChatSelectedOneMessage(this.createOurChatMessage(message, chat), chat);
        };

        this.channelHandler.onChannelDeleted = (channelUrl: string) => {
            this.removeChannel(channelUrl);
        };

        this.channelHandler.onChannelChanged = (channel: GroupChannel) => {
            this.updateChannel(channel);
        };

        this.channelHandler.onReadReceiptUpdated = (channel: GroupChannel) => {
            const chat = this.updateChannel(channel);
            this.addOrUpdateChatSelectedOneMessage(this.createOurChatMessage(channel.lastMessage, chat), chat);
        };

        this.channelHandler.onTypingStatusUpdated = (channel: GroupChannel) => {
            if (this.isChannelSelectedByChannel(channel)) {
                const members = channel.getTypingMembers();
                if (members?.length) {
                    this._typing$.next(this.getUserFromSendbirdUser(members[0]));
                } else {
                    this._typing$.next(null);
                }
            }
        };

        this.sb.addChannelHandler('channelHandler', this.channelHandler);

        this.totalUnread.subscribe(unread => {
            this.fuseNavigationService.updateNavigationItem('chat', {
                badge: null
            });
            Tinycon.setBubble();

            setTimeout(() => {
              if (unread > 0) {
                  this.fuseNavigationService.updateNavigationItem('chat', {
                      badge: {
                          title: unread.toString(),
                          bg: '#28a8de',
                          fg: '#ffffff'
                      }
                  });
                  Tinycon.setBubble(unread);
              } else {
                  this.fuseNavigationService.updateNavigationItem('chat', {
                      badge: null
                  });
                  Tinycon.setBubble();
              }
            }, 0);
        });
    }

    public getChatsWithChannels(force = false, status: 'open' | 'close' | 'futur' = 'open'): Observable<Chat[]> {
        return this.getChatsApi(force, status).pipe(
            switchMap(chats => this.getChannels(chats, force)),
            map(() => this._chats$.value)
        );
    }

    public getChatsApi(force = false, status: 'open' | 'close' | 'futur' = 'open', keywords: string = null): Observable<Chat[]> {
        if (force || this.shouldFetchApi) {
            return this.userPreferenciesService.getPreferencies().pipe(
                switchMap(() => {
                    this.shouldFetchApi = false;

                    let params = new HttpParams();
                    params = params.append('who', 'all');
                    params = params.append('status', status);
                    if (keywords) {
                      params = params.append('keywords', keywords);
                    }

                    return this.http.get<Chat[]>(this.config.baseUrl + 'api/chats', {
                        params: params
                    });
                }),
                map(chats => ObjectMapper.deserializeArray(Chat, chats)),
                tap(chats => this._chats$.next(chats))
            );
        }

        return of(this._chats$.value);
    }

    public connect(): Observable<User> {
        return new Observable<User>(observer => {
            if (this.utilisateurService.isConnected) {
                if (this.isConnected) {
                    observer.next(this._userConnected$.value);
                    observer.complete();
                    this.shouldFetchChannels = false;
                } else {
                    void this.sb.connect(this.utilisateurService.utilisateurConnectedValue.id.toString(), (user: User, error: SendBirdError) => {
                        if (error) {
                            this.shouldFetchChannels = false;
                            observer.error(error);
                            alert(this.translateService.instant('CHAT.CONNEXION_ERROR', { message: error.message }));
                        } else {
                            this.shouldFetchChannels = true;
                            this._userConnected$.next(user);
                            observer.next(user);
                            observer.complete();
                        }
                    });
                }
            } else {
                observer.error(this.translateService.instant('CHAT.NO_USER_CONNECTED'));
            }
        });
    }

    public getChannels(chats: Chat[], forceFetch = false): Observable<Chat[]> {
        return new Observable<Chat[]>(observer => {
            if (this.isConnected) {
                if (this.shouldFetchChannels || forceFetch) {
                    const channelListQuery: GroupChannelListQuery = this.sb.GroupChannel.createMyGroupChannelListQuery();
                    channelListQuery.includeEmpty = true;
                    channelListQuery.limit = 100;
                    channelListQuery.order = 'latest_last_message';

                    if (!channelListQuery.hasNext) {
                        observer.next([]);
                        observer.complete();
                    }

                    void channelListQuery.next((channelList: GroupChannel[], errorChannel: SendBirdError) => this.loopGetChannels(channelListQuery, observer, chats, channelList, errorChannel));
                } else {
                    observer.next(chats);
                    observer.complete();
                }
            } else {
                observer.next([]);
                observer.complete();
            }
        }).pipe(catchError(err => {
            this.snackbar.open(this.translateService.instant('CHAT.CONNEXION_ERROR', { message: err ? err.message : '' }), this.translateService.instant('SHARED.OK'), { duration: 5000 });
            throw err;
        }));
    }

    private loopGetChannels(channelListQuery: GroupChannelListQuery, observer: Observer<Chat[]>, chats: Chat[], channelList: GroupChannel[], errorChannel: SendBirdError) {
        if (errorChannel) {
            observer.error(errorChannel);
        } else if (channelList && channelList.length > 0) {
            channelList.forEach(c => {
                const chat = chats.find(chatBdd => chatBdd.sendbirdUrl === c.url);
                if (chat) {
                    this.setChannelForChat(c, chat);
                }
            });
        }

        this.shouldFetchChannels = false;
        observer.next(chats);
        observer.complete(); // pour ne pas bloquer l'utilisateur
        if (channelListQuery.hasNext) {
            void channelListQuery.next((channelList2: GroupChannel[], errorChannel2: SendBirdError) => this.loopGetChannels(channelListQuery, observer, chats, channelList2, errorChannel2));
        }
    }

    public connectAndGetChannel(): Observable<any> {
        return this.connect().pipe(
            switchMap(() => this.getChatsApi())
        );
    }

    public sendMessage(messageToSend: string): Observable<UserMessage> {
        return this.connect().pipe(switchMap(_ => {
            return new Observable<UserMessage>(observer => {
                if (this.hasSelectedChat) {
                    this.stopTyping();
                    const messageTmp = this.createTextMessage(messageToSend, this._chatSelected$.value);
                    this.addOrUpdateChatSelectedOneMessage(messageTmp, this._chatSelected$.value);

                    this._chatSelected$.value.channel.sendUserMessage(messageToSend, (message: UserMessage, error: SendBirdError) => {
                        if (error) {
                            observer.error(error);
                            alert(this.translateService.instant('CHAT.ERROR_SENDING_MESSAGE', { message: error.message }));
                        } else {
                            const ourMessage = this.createOurChatMessage(message, this._chatSelected$.value, {});
                            messageTmp.id = ourMessage.id;
                            this.addOrUpdateChatSelectedOneMessage(ourMessage, this._chatSelected$.value, messageTmp.id);
                            if (environment.production) {
                                this.googleAnalyticsService.gtag('event', 'message', {
                                    type: 'text'
                                });
                            }

                            observer.next(message);
                            observer.complete();
                        }
                    });
                } else {
                    observer.error(this.translateService.instant('CHAT.NO_CONVERSATION_SELECTED'));
                }
            });
        }));
    }

    public isCurrentChatOpened(): boolean {
        const chat = this.getChatForChannelSelected();
        return chat ? !chat.isChatClosed : false;
    }

    public sendFileMessage(file: File, shouldSave = false): Observable<ChatMessage> {
        return this.connect().pipe(switchMap(_ => {
            return new Observable<ChatMessage>(observer => {
                if (this.hasSelectedChat) {
                    this.stopTyping();
                    const messageTmp = this.createFileMessage(file, this._chatSelected$.value);
                    this.addOrUpdateChatSelectedOneMessage(messageTmp, this._chatSelected$.value);

                    const fileUrl = URL.createObjectURL(file);

                    if (file.type.includes('image')) {
                        const img = new Image();
                        img.addEventListener('error', err => {
                            observer.error(err);
                            alert(this.translateService.instant('CHAT.ERROR_GENERATING_IMAGE_MESSAGE'));
                        });

                        img.addEventListener('load', () => {
                            const ratio = img.width / img.height;
                            this.fichierService.compressAndResize(file, {
                                maxWidth: this.widthThumbnail,
                                maxHeight: this.widthThumbnail / ratio
                            }).subscribe((fileResized: File) => {
                                this.convertBlobToBase64(fileResized).subscribe((base64: string) => {
                                    this.sendFileMessageExec(file, {
                                        image: base64.slice(Math.max(0, base64.indexOf(',') + 1)),
                                        size: [img.width, img.height],
                                        shouldSave: shouldSave,
                                        type: 'image'
                                    }, messageTmp).subscribe(message => {
                                        observer.next(message);
                                        observer.complete();
                                    });
                                });
                            });
                        });

                        img.src = fileUrl;
                    } else if (file.type.includes('video')) {
                        const canvas: any = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        const video: any = document.createElement('video');
                        video.addEventListener('error', err => {
                            observer.error(err);
                            alert(this.translateService.instant('CHAT.ERROR_GENERATING_VIDEO_MESSAGE'));
                        });

                        video.addEventListener('loadedmetadata', () => {
                            video.width = this.widthThumbnail;
                            video.height = this.widthThumbnail / (video.videoWidth / video.videoHeight);
                        });

                        video.addEventListener('canplay', () => {
                            canvas.width = video.width;
                            canvas.height = video.height;
                            context.drawImage(video, 0, 0, canvas.width, canvas.height);
                            canvas.toBlob(thumbnail => {
                                this.convertBlobToBase64(thumbnail).subscribe((base64: string) => {
                                    this.sendFileMessageExec(file, {
                                        image: base64.slice(Math.max(0, base64.indexOf(',') + 1)),
                                        size: [video.videoWidth, video.videoHeight],
                                        shouldSave: shouldSave,
                                        type: 'video'
                                    }, messageTmp).subscribe(message => {
                                        observer.next(message);
                                        observer.complete();
                                    });
                                });
                            }, 'image/jpeg');
                        });

                        video.src = fileUrl;
                    } else if (file.type.includes('audio')) {
                        const audio = new Audio();
                        audio.addEventListener('error', err => {
                            observer.error(err);
                            alert(this.translateService.instant('CHAT.ERROR_GENERATING_AUDIO_MESSAGE'));
                        });

                        audio.addEventListener('loadedmetadata', () => {
                            this.sendFileMessageExec(file, {
                                duration: audio.duration,
                                shouldSave: shouldSave,
                                type: 'audio'
                            }, messageTmp).subscribe(message => {
                                observer.next(message);
                                observer.complete();
                            });
                        });

                        audio.src = fileUrl;
                    } else if (file.type) {
                        this.sendFileMessageExec(file, {
                            shouldSave: shouldSave,
                            type: 'other'
                        }, messageTmp).subscribe(message => {
                            observer.next(message);
                            observer.complete();
                        });
                    }
                } else {
                    observer.error(this.translateService.instant('CHAT.NO_CONVERSATION_SELECTED'));
                }
            });
        }));
    }

    private sendFileMessageExec(file: File, data: any, ourMessageTmp: ChatMessage): Observable<ChatMessage> {
        return new Observable((observer: Observer<ChatMessage>) => {
            const fileMessageParams = new this.sb.FileMessageParams();
            fileMessageParams.file = file;
            fileMessageParams.data = JSON.stringify(data);
            this._chatSelected$.value.channel.sendFileMessage(fileMessageParams, (messageSendbird: FileMessage, error: SendBirdError) => {
                if (error) {
                    observer.error(error);
                    alert(this.translateService.instant('CHAT.ERROR_SENDING_MESSAGE', { message: error.message }));
                } else {
                    const ourTrueMessage = this.createOurChatMessage(messageSendbird, this._chatSelected$.value, {});
                    ourMessageTmp.id = ourTrueMessage.id;
                    this.addOrUpdateChatSelectedOneMessage(ourTrueMessage, this._chatSelected$.value, ourMessageTmp.id);
                    if (environment.production) {
                        this.googleAnalyticsService.gtag('event', 'message', {
                            type: data.mimeType
                        });
                    }

                    observer.next(ourTrueMessage);
                    observer.complete();
                }
            });
        });
    }

    public startTyping(): void {
        if (this.hasSelectedChat) {
            this._chatSelected$.value.channel.startTyping();
        }
    }

    public stopTyping(): void {
        if (this.hasSelectedChat) {
            this._chatSelected$.value.channel.endTyping();
        }
    }

    public get isConnected(): boolean {
        return Boolean(this._userConnected$.value);
    }

    public get typing(): Observable<UtilisateurPostInterface | Veterinaire> {
        return this._typing$.asObservable();
    }

    public get newMessage(): Observable<ChatMessage> {
        return this._newMessage$.asObservable();
    }

    public get chats(): Observable<Chat[]> {
        return this._chats$.asObservable();
    }

    public get chatsBookmarksUpdate(): Observable<Chat[]> {
        return this._chatsBookmarksUpdate$.asObservable();
    }

    public get totalUnread(): Observable<number> {
        return this._totalUnread$.asObservable();
    }

    public get chatSelected(): Observable<Chat> {
        return this._chatSelected$.asObservable();
    }

    public get hasSelectedChat(): boolean {
        return Boolean(this._chatSelected$.value) && Boolean(this._chatSelected$.value.sendbirdUrl);
    }

    public setChatSelected(chat: Chat): void {
        if (chat?.channel) {
            this._chatSelected$.next(chat);

            const unreadMembers = chat.channel.getReadStatus(true) as { [key: number]: { user: User; last_seen_at: number }};

            const messageListQuery: PreviousMessageListQuery = chat.channel.createPreviousMessageListQuery();

            void messageListQuery.load(30, true, 0, (messages: BaseMessageInstance[], error: SendBirdError) => {
                if (error) {
                    console.error(error);
                    alert(this.translateService.instant('CHAT.ERROR_FETCHING_MESSAGES', { message: error.message }));
                } else {
                    void chat.channel.markAsRead();
                    this._channelSelectedMessage$.next(messages.map(c => this.createOurChatMessage(c, chat, unreadMembers)));
                }
            });
        } else {
            this._channelSelectedMessage$.next([]);
            this._chatSelected$.next(null);
            this.stopTyping();
        }
    }

    public setChannelSelectedByChatId(chatId: number): Observable<Chat> {
        return new Observable<Chat>(observer => {
            const chat = this._chats$.value.find(c => c.id === chatId);
            if (chat) {
                if (chat.channel) {
                    observer.next(chat);
                    observer.complete();
                    this.setChatSelected(chat);
                } else {
                    this.getChannels([chat], true)
                        .subscribe({
                            error: error => observer.error(error),
                            complete: () => {
                                if (chat.channel) {
                                    observer.next(chat);
                                    observer.complete();
                                    this.setChatSelected(chat);
                                }
                            }
                        });
                }
            }
        });
    }

    public getMoreMessageOnChannelSelected(channel: GroupChannel): Observable<ChatMessage[]> {
        return new Observable((observer: Observer<ChatMessage[]>) => {
            if (this.isChannelSelectedByChannel(channel)) {
                const messageListParams = new this.sb.MessageListParams();
                messageListParams.isInclusive = false;
                messageListParams.prevResultSize = 30;
                messageListParams.reverse = true;

                const unreadMembers = channel.getReadStatus(true) as { [key: number]: { user: User; last_seen_at: number }};

                void channel.getMessagesByMessageId(
                    this._channelSelectedMessage$.value[0].id,
                    messageListParams,
                    (messages: BaseMessageInstance[], error: SendBirdError) => {
                        const chat = this._chatSelected$.value;

                        if (error) {
                            alert(this.translateService.instant('CHAT.ERROR_FETCHING_PREVIOUS_MESSAGES', { message: error.message }));
                            observer.error(error);
                        } else {
                            let ourMessages = messages.map(c => this.createOurChatMessage(c, chat, unreadMembers));
                            observer.next(ourMessages);

                            ourMessages = [...ourMessages, ...this._channelSelectedMessage$.value];
                            this._channelSelectedMessage$.next(ourMessages);

                            observer.complete();
                        }
                    });
            } else {
                observer.next([]);
                observer.complete();
            }
        });
    }

    public get channelSelectedMessage(): Observable<ChatMessage[]> {
        return this._channelSelectedMessage$.asObservable().pipe(
            map(m => m ? m.sort((a, b) => a.date < b.date ? -1 : 1) : [])
        );
    }

    public addOrUpdateChatSelectedOneMessage(message: ChatMessage, chat: Chat, messageIdToReplace: number = null): void {
        if (chat) {
            if (message.isDelayed) {
                try {
                    chat.delayedMessagesCount--;

                    const chats = this._chats$.value;
                    const index = chats.findIndex(c => c.id === chat.id);
                    if (index !== -1) {
                        chats[index] = chat;
                        this._chats$.next(chats);
                    }
                } catch {
                    // ignored
                }
            }

            if (this.isChannelSelectedByChat(chat)) {
                const messages = this._channelSelectedMessage$.value;
                if (messageIdToReplace) {
                    const messageIndex = messages.findIndex(m => m.id === messageIdToReplace);
                    if (messageIndex !== -1) {
                        messages[messageIndex] = message;
                    }
                } else {
                    const messageIndex = messages.findIndex(m => m.id === message.id);
                    if (messageIndex === -1) {
                        messages.unshift(message);
                    } else {
                        messages[messageIndex] = message;
                    }
                }

                this._channelSelectedMessage$.next(messages);
            } else if (message.isClient) {
                this.snackbar.open(this.translateService.instant('CHAT.NEW_MESSAGE'), this.translateService.instant('SHARED.SEE'), {
                    horizontalPosition: 'right',
                    verticalPosition: 'top',
                    duration: 4000
                }).onAction().subscribe(() => {
                    void this.router.navigate(['chat', chat.id]);
                });

                this.computeTotalUnread();
            }

            this._newMessage$.next(message);
        }
    }

    public getChatForChannel(channel: GroupChannel | BaseChannel): Chat {
        if (!channel) {
            return null;
        }

        return this._chats$.value.find(chat => channel.url === chat.sendbirdUrl);
    }

    public getChatForChannelByUrl(url: string): Chat {
        return this._chats$.value.find(chat => url === chat.sendbirdUrl);
    }

    public getChatForChannelSelected(): Chat {
        return this.hasSelectedChat ? this._chats$.value.find(chat => this._chatSelected$.value.sendbirdUrl === chat.sendbirdUrl) : null;
    }

    public isMyMessage(message: ChatMessage): boolean {
        return this.isMessageSentByUserId(message, this.utilisateurId);
    }

    public isMessageSentByUserId(message: ChatMessage, userId: number): boolean {
        return message.sender?.id === userId;
    }

    public getFileTypeMessage(message: ChatMessage): string {
        const payload = message.payload as FileChatMessagePayloadInterface;

        if (payload.mimeType.includes('application')) {
            return payload.mimeType.slice(Math.max(0, payload.mimeType.indexOf('/') + 1));
        }

        return payload.mimeType.slice(0, Math.max(0, payload.mimeType.indexOf('/')));
    }

    public createOurChatMessage(c: BaseMessageInstance | UserMessage | FileMessage | AdminMessage, chat: Chat, unreadMembers: { [key: number]: { user: User; last_seen_at: number }} = null): ChatMessage {
        let data: any | null;
        try {
            data = JSON.parse(c.data);
        } catch {
            // ignored
        }

        const message = new ChatMessage();

        message.messageOriginal = c;
        message.id = c.messageId;
        message.chat = chat;
        message.date = new Date(c.createdAt);
        message.isAdmin = c.messageType === 'admin';
        message.isFile = c.messageType === 'file';
        message.isDelayed = data?.delayedMessage ?? false;

        switch (c.messageType) {
            case 'admin': {
                const adminC = c as AdminMessage;
                message.isAdmin = true;
                message.payload = adminC.message;
                break;
            }

            case 'user': {
                const userC = c as UserMessage;
                message.sender = this.getUserFromSendbirdUser(userC.sender);
                message.payload = userC.message;
                break;
            }

            case 'file': {
                const fileC = c as FileMessage;
                message.sender = this.getUserFromSendbirdUser(fileC.sender);
                message.payload = {
                    mimeType: fileC.type,
                    name: fileC.name,
                    url: fileC.url,
                    size: fileC.size,

                    image: data?.image,
                    dimension: data?.size,
                    shouldSave: data?.shouldSave
                };

                if (message.payload?.image) {
                    message.payload.thumbnailUrl = this.domSanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + message.payload.image);
                } else if (message.payload.url) {
                    message.payload.thumbnailUrl = message.payload.url;
                }

                break;
            }

            case 'base': {
              break;
            }

            default: {
              break;
            }
        }

        if (message.sender) {
            message.isMyMessage = message.sender.id === this.utilisateurId;
            message.isClient = message.sender.typeUtilisateur === TypeUtilisateurEnum.client;

            if (!unreadMembers) {
                unreadMembers = chat.channel.getReadStatus(true) as { [key: number]: { user: User; last_seen_at: number }};
            }

            if (unreadMembers) {
                message.isRead = unreadMembers[chat.animal.proprietaire.id]?.last_seen_at >= c.createdAt;
            }
        }

        return message;
    }

    private setChannelForChat(channel: GroupChannel, chat: Chat): void {
        try {
            if (channel.data) {
                channel.data = JSON.parse(channel.data);
            }
        } catch {
            // ignored
        }

        chat.channel = channel;
        if (channel.lastMessage) {
            chat.lastMessage = this.createOurChatMessage(channel.lastMessage, chat, channel.getReadStatus(true) as { [key: number]: { user: User; last_seen_at: number }});

            if (chat.lastMessage.sender) {
              chat.lastMessageOwner = chat.lastMessage.sender as Utilisateur;
              chat.veterinaryHasRead = chat.lastMessage.isMyMessage;
            }

            chat.lastMessageDate = chat.lastMessage.date;
            chat.lastMessageType = chat.lastMessage.messageOriginal.messageType as TypeChatMessageEnum;
            chat.lastMessageText = this._chatMessagePipe.transformFromSendbirdMessage(chat.lastMessage);
        }

        this.computeUnreadMessageForChat(chat);
        this.computeTotalUnread();
    }

    private get utilisateurId(): number {
        if (this.utilisateurService.utilisateurConnectedValue) {
            return this.utilisateurService.utilisateurConnectedValue.id;
        }

        if (this._userConnected$.value) {
            return +this._userConnected$.value.userId;
        }

        throw new Error('User not connected');
    }

    private convertBlobToBase64(file: Blob): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            const reader = new FileReader();
            reader.addEventListener('error', err => {
                observer.error(err);
            });

            reader.addEventListener('load', () => {
                observer.next(reader.result as string);
                observer.complete();
            });

            reader.readAsDataURL(file);
        });
    }

    public isChannelSelectedByChannel(channel: BaseChannel): boolean {
        return !!channel && this.hasSelectedChat && channel.url === this._chatSelected$.value.sendbirdUrl;
    }

    public isChannelSelectedByChat(chat: Chat): boolean {
        return this.hasSelectedChat && chat.sendbirdUrl === this._chatSelected$.value.sendbirdUrl;
    }

    public addChat(data: ChatPostInterface): Observable<Chat> {
        this.snackbar.open(this.translateService.instant('CHAT.ADDING'), null, { duration: 1500 });
        data.dateDebut = data.dateDebut ? Math.round((data.dateDebut as Date).getTime() / 1000) : null;
        if (data.dateFin) {
            data.dateFin = data.dateFin ? Math.round((data.dateFin as Date).getTime() / 1000) : null;
        }

        data.id = 0;

        //bookmark = false dans tous les cas pour un nouveau chat
        data.bookmarked = false;

        return this.http.post<Chat>(this.config.baseUrl + 'api/chat', data)
            .pipe(
                map(chat => ObjectMapper.deserialize(Chat, chat)),
                tap(chat => {
                    const chats = this._chats$.value;
                    chats.push(chat);
                    this._chats$.next(chats);
                    this.snackbar.open(this.translateService.instant('CHAT.ADDED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                })
            );
    }

    public updateAnimalOnChat(animal: Animal): void {
        for (const chat of this._chats$.value.filter(c => c.animal.id === animal.id)) {
            chat.animal = animal;
        }
    }

    public updateAnimalProprietaireOnChat(client: Client): void {
        for (const chat of this._chats$.value.filter(c => c.animal.proprietaire.id === client.id)) {
            chat.animal.proprietaire = client;
        }
    }

    public updateChat(data: ChatPostInterface): Observable<Chat> {
        this.snackbar.open(this.translateService.instant('CHAT.UPDATING'), null, { duration: 1500 });
        data.dateDebut = data.dateDebut ? Math.round((data.dateDebut as Date).getTime() / 1000) : null;
        if (data.dateFin) {
            data.dateFin = data.dateFin ? Math.round((data.dateFin as Date).getTime() / 1000) : null;
        }

        return this.http.put<Chat>(this.config.baseUrl + 'api/chat/' + data.id.toString(), data)
            .pipe(
                map(chat => ObjectMapper.deserialize(Chat, chat)),
                tap(chat => {
                    const chats = this._chats$.value;
                    const index = chats.findIndex(c => c.id === data.id);
                    if (index !== -1) {
                        this.setChannelForChat(chats[index].channel, chat);
                        chats[index] = chat;
                        this._chats$.next(chats);
                    }

                    this.snackbar.open(this.translateService.instant('CHAT.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
                }));
    }

    public deleteChat(id: number): Observable<any> {
        this.snackbar.open(this.translateService.instant('CHAT.DELETING'), null);
        return this.http.delete(this.config.baseUrl + 'api/chat/' + id.toString())
            .pipe(tap(() => {
                const chats = this._chats$.value;
                const index = chats.findIndex(c => c.id === id);
                if (index !== -1) {
                    const chat = chats[index];
                    if (this.isChannelSelectedByChat(chat)) {
                        this.setChatSelected(null);
                    }

                    chats.splice(index, 1);
                    this._chats$.next(chats);
                }

                this.snackbar.open(this.translateService.instant('CHAT.DELETED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public changeStatusChat(id: number, status: boolean): Observable<any> {
        this.snackbar.open(status ? this.translateService.instant('CHAT.OPENING') : this.translateService.instant('CHAT.CLOSING'), null);
        let params = new HttpParams();
        params = params.append('status', status.toString());
        return this.http.patch(this.config.baseUrl + 'api/chat/' + id.toString() + '/status', null, {
            params: params
        }).pipe(
            map(chat => ObjectMapper.deserialize(Chat, chat)),
            tap(chat => {
                const chats = this._chats$.value;
                const index = chats.findIndex(c => c.id === id);
                if (index !== -1) {
                    this.setChannelForChat(chats[index].channel, chat);
                    chats[index] = chat;
                    this._chats$.next(chats);
                }

                this.snackbar.open(status ? this.translateService.instant('CHAT.OPENED') : this.translateService.instant('CHAT.CLOSED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public changeBookmarkChat(id: number, bookmarked: boolean): Observable<any> {
        this.snackbar.open(bookmarked ? this.translateService.instant('CHAT.BOOKMARK.ADDING') : this.translateService.instant('CHAT.BOOKMARK.REMOVING'), null);
        let params = new HttpParams();
        params = params.append('bookmark', bookmarked.toString());
        return this.http.patch(this.config.baseUrl + 'api/chat/' + id.toString() + '/bookmark', null, {
            params: params
        }).pipe(
            map(chat => ObjectMapper.deserialize(Chat, chat)),
            tap(chat => {
                const chats = this._chats$.value;
                const index = chats.findIndex(c => c.id === id);
                if (index !== -1) {
                    this.setChannelForChat(chats[index].channel, chat);
                    chats[index] = chat;
                    this._chatsBookmarksUpdate$.next(chats);
                }

                this.snackbar.open(bookmarked ? this.translateService.instant('CHAT.BOOKMARK.ADDED') : this.translateService.instant('CHAT.BOOKMARK.REMOVED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public openDialogChat(chat: Chat | any): MatDialogRef<ChatDialogComponent> {
        const data: ChatDialogInterface = {
            chat: chat instanceof Chat || Object.prototype.hasOwnProperty.call(chat, 'hasPost') ? chat : ObjectMapper.deserialize(Chat, chat)
        };
        return this.dialog.open(ChatDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '40vw',
            disableClose: true
        });
    }

    public computeUnreadMessageForChat(chat: Chat): number {
        if (!chat.channel) {
            return 0;
        }

        chat.unreadMessage = chat.channel.unreadMessageCount;
        chat.veterinaryHasRead = chat.unreadMessage === 0;

        return chat.unreadMessage;
    }

    public getUserFromSendbirdUser(user: User): UtilisateurPostInterface | Veterinaire {
        if (!user) {
            return null;
        }

        // Test si véto car on a les véto en mémoire
        const userBdd = this.utilisateurService.veterinaires$.value.find(u => u.id === Number(user.userId));
        return userBdd ? userBdd : this.getSendbirdUserAsUtilisateurInterface(user);
    }

    private getSendbirdUserAsUtilisateurInterface(user: User): UtilisateurPostInterface {
        if (!user) {
            return null;
        }

        const metaData: any = user.metaData;

        return {
            id: Number.parseInt(user.userId, 10),
            nom: metaData.nom ? metaData.nom : user.nickname,
            prenom: metaData.prenom,
            photo: user.profileUrl,
            civilite: metaData.civilite,
            typeUtilisateur: metaData.typeUtilisateur ? metaData.typeUtilisateur : 'user'
        };
    }

    public updateChatColor(id: number, color: string): Observable<Chat> {
        this.snackbar.open(this.translateService.instant('CHAT.UPDATING'), null, { duration: 1500 });
        let params = new HttpParams();
        if (color) {
            params = params.append('color', color);
        }

        return this.http.patch<Chat>(this.config.baseUrl + 'api/chat/' + id.toString() + '/color', null, {
            params: params
        }).pipe(
            map(chat => ObjectMapper.deserialize(Chat, chat)),
            tap(chat => {
                const chats = this._chats$.value;
                const index = chats.findIndex(c => c.id === id);
                if (index !== -1) {
                    this.setChannelForChat(chats[index].channel, chat);
                    chats[index] = chat;
                    this._chats$.next(chats);
                }

                this.snackbar.open(this.translateService.instant('CHAT.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public generatePDF(id: number): Observable<Fichier> {
        this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.RAPPORT_GENERATING'));
        return this.http.get<Fichier>(this.config.baseUrl + 'api/chat/' + id.toString() + '/pdf').pipe(
            map(fichier => ObjectMapper.deserialize(Fichier, fichier)),
            tap(pdf => {
                this.snackbar.open(this.translateService.instant('RENDEZ_VOUS.RAPPORT_GENERATED'), this.translateService.instant('SHARED.SEE'), { duration: 1500 }).onAction().subscribe(_ => {
                    this.fichierService.downloadFile(pdf);
                });
            })
        );
    }

    public updateChatPinned(id: number, pin: boolean): Observable<Veterinaire> {
        this.snackbar.open(this.translateService.instant('CHAT.UPDATING'), null, { duration: 1500 });
        let params = new HttpParams();
        params = params.append('pin', pin ? 'true' : 'false');

        return this.http.patch<Veterinaire>(this.config.baseUrl + 'api/veterinaire/chat/' + id.toString() + '/pin', null, {
            params: params
        }).pipe(
            map(v => ObjectMapper.deserialize(Veterinaire, v)),
            tap(v => {
                const newUser = this.utilisateurService.utilisateurConnectedValue;
                newUser.chatSettings = v.chatSettings;
                this.utilisateurService.utilisateurConnectedValue = newUser;
                this._chats$.next(this._chats$.value);

                this.snackbar.open(this.translateService.instant('CHAT.UPDATED'), this.translateService.instant('SHARED.OK'), { duration: 1500 });
            }));
    }

    public getChatShortcuts(id: number): Observable<ChatShortcut[]> {
        return this.http.get<Chat>(`${this.config.baseUrl}api/chat/${id}`).pipe(map(c => c.shortcuts));
    }

    private updateChannel(channel: GroupChannel): Chat {
        const chat = this.getChatForChannel(channel);

        if (chat) {
            this.setChannelForChat(channel, chat);

            if (this.isChannelSelectedByChat(chat)) {
                this._chatSelected$.next(chat);
            }

            this.computeTotalUnread();

            return chat;
        }

        return null;
    }

    private removeChannel(channelUrl: string) {
        const chat = this.getChatForChannelByUrl(channelUrl);

        if (chat) {
            chat.channel = null;
        }

        this._chats$.next(this._chats$.value);
    }

    private createTextMessage(text: string, chat: Chat): ChatMessage {
        const message = new ChatMessage();
        message.payload = text;
        message.date = new Date();
        message.chat = chat;
        message.sender = this.getUserFromSendbirdUser(this._userConnected$.value);
        message.isMyMessage = true;
        return message;
    }

    private createFileMessage(file: File, chat: Chat): ChatMessage {
        const message = new ChatMessage();
        const url = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));

        message.payload = {
            name: file.name,
            size: file.size,
            mimeType: file.type,
            url: url,
            thumbnailUrl: Utils.isImage(file.type) ? url : null
        };

        message.isFile = true;
        message.date = new Date();
        message.chat = chat;
        message.sender = this.getUserFromSendbirdUser(this._userConnected$.value);
        message.isMyMessage = true;

        return message;
    }

    private computeTotalUnread(): number {
        const total = this._chats$.value
            .map(c => c.veterinaryHasRead ? 0 : 1)
            .reduce((previousValue, currentValue) => currentValue + previousValue, 0);
        this._totalUnread$.next(total);
        return total;
    }
}

import { Injectable } from '@angular/core';
import { Session, initSession, initPublisher, Publisher, PublisherProperties, OTError, getDevices, Device } from '@opentok/client';

@Injectable({
    providedIn: 'root'
})
export class OpentokService {
    apiKey: string;
    sessionId: string;
    sessionToken: string;

    session: Session;

    async initSession(): Promise<Session> {
        if (this.apiKey && this.sessionId && this.sessionToken) {
            this.session = initSession(this.apiKey, this.sessionId);
            return Promise.resolve(this.session);
        }
    }

    initPublisher(targetElement?: string | HTMLElement, properties?: PublisherProperties, callback?: (error?: OTError) => void): Publisher {
        return initPublisher(targetElement, properties, callback);
    }

    async connect(): Promise<Session> {
        return new Promise((resolve, reject) => {
            this.session.connect(this.sessionToken, err => {
                if (err) {
                    reject(err);
                } else {
                    resolve(this.session);
                }
            });
        });
    }

    getDevices(callback: (error?: OTError, devices?: Device[]) => void): void {
        return getDevices(callback);
    }
}

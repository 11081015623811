import { FuseConfig } from '@fuse/types';
import { TranslateService } from '@ngx-translate/core';
import { GalleryOptions } from '@twogate/ngx-photo-gallery/lib/interfaces/photoswipe';
import { EmailOptions } from 'ngx-validators';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    customScrollbars: true,
    layout: {
        navbar: {
            folded: false,
            hidden: false
        },
        toolbar: {
            hidden: false
        }
    }
};

export const emailOptionsConfig: EmailOptions = {
    domains: [
        'aim.com',
        'aol.com',
        'att.net',
        'bellsouth.net',
        'btinternet.com',
        'charter.net',
        'comcast.net',
        'cox.net',
        'earthlink.net',
        'free.fr',
        'gmail.com',
        'gvmi.com',
        'google.com',
        'googlemail.com',
        'groupe-curious.com',
        'icloud.com',
        'mac.com',
        'me.com',
        'msn.com',
        'optonline.net',
        'optusnet.com.au',
        'orange.fr',
        'qq.com',
        'rocketmail.com',
        'rogers.com',
        'sbcglobal.net',
        'sfr.fr',
        'shaw.ca',
        'sky.com',
        'sympatico.ca',
        'telus.net',
        'verizon.net',
        'web.de',
        'xtra.co.nz',
        'yahoo.com',
        'ymail.com'
    ],
    secondLevelDomains: ['yahoo', 'hotmail', 'gmail', 'live', 'outlook'],
    topLevelDomains: [
        'at',
        'be',
        'biz',
        'ca',
        'ch',
        'co.il',
        'co.jp',
        'co.nz',
        'co.uk',
        'com.au',
        'com.tw',
        'com',
        'cz',
        'de',
        'dk',
        'edu',
        'es',
        'eu',
        'fr',
        'gov',
        'gr',
        'hk',
        'hu',
        'ie',
        'in',
        'info',
        'it',
        'jp',
        'kr',
        'mil',
        'net.au',
        'net',
        'nl',
        'no',
        'org',
        'ru',
        'se',
        'sg',
        'uk',
        'us',
        'vet'
    ]
};

export function photoSwipeConfig(translateService: TranslateService): GalleryOptions {
    return {
        bgOpacity: 0.8,
        counterEl: false,
        shareButtons: [
            {
                id: 'download',
                label: translateService.instant('SHARED.DOWNLOAD'),
                url: '{{raw_image_url}}',
                download: true
            }
        ]
    };
}

<fuse-widget class="widget">
    <div class="fuse-widget-front mat-white-bg">
        <div class="pl-16 pr-8 pt-16 pb-8 h-52" fxLayout="row" fxLayoutAlign="space-between center">
            <span>{{ titleLabel }}</span>
            <button mat-icon-button color="accent" (click)="actionClicked$.next(null)"
                    *ngIf="action !== undefined && action !== null">
                <mat-icon [matTooltip]="action">{{ icon }}</mat-icon>
            </button>
        </div>

        <div class="p-8" fxLayout="row" fxLayoutAlign="center baseline" fxLayoutGap="10px">
            <div *ngIf="!isLoading; else loading" class="w-100-p">
                <table class="simple" *ngIf="datas && datas.length; else nothing">
                    <thead>
                    <tr>
                        <th *ngFor="let header of columnsName; let i=index">
                            <ng-container *ngIf="columnsIcon[i]; else normalTitle">
                                <mat-icon [matTooltip]="header">{{ columnsIcon[i] }}</mat-icon>
                            </ng-container>
                            <ng-template #normalTitle>{{ header | capitalize }}</ng-template>
                        </th>
                        <th *ngIf="rowActionsTemplate"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of datas" [matTooltip]="tooltipFn ? tooltipFn(data, translateService) : null">
                        <td *ngFor="let column of columns" [innerHTML]="data[column]"></td>
                        <td *ngIf="rowActionsTemplate">
                            <ng-container *ngTemplateOutlet="rowActionsTemplate; context:{data: data}"></ng-container>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <ng-template #nothing>
                    <div class="p-8 text-center">{{ 'SHARED.NOTHING_TO_DISPLAY' | translate }}</div>
                </ng-template>

                <div *ngIf="detail" class="p-8">
                    {{ detail }}
                </div>
            </div>

            <ng-template #loading>
                <app-loading class="mb-8" [onlySpinner]="true"></app-loading>
            </ng-template>
        </div>
    </div>
</fuse-widget>

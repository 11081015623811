import { PaiementsComponent } from './paiements.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../shared.module';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { AuthGuard } from '../../../guards/auth.guard';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { PayoutResolver } from '../../../resolver/payout.resolver';

const routes = [
    {
        path: 'payments',
        component: PaiementsComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard],
        resolve: {
            payout: PayoutResolver
        }
    }
];

@NgModule({
    declarations: [
        PaiementsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class PaiementsModule {}

<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>

    <h3>{{ data.content }}</h3>

    <h3>{{ data.confirm }}</h3>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px" class="pb-20">
    <button class="mat-raised-button mat-primary" [mat-dialog-close]="true">{{ 'REPORTS.DIALOGS.REPORT.SUBMIT_BUTTON' | translate }}</button>
    <button class="mat-raised-button" [mat-dialog-close]="false">{{ 'REPORTS.DIALOGS.REPORT.CANCEL_BUTTON' | translate }}</button>
</mat-dialog-actions>

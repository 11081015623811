import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../services/api/auth.service';

@Component({
    selector: 'fuse-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    animations: fuseAnimations
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService
    ) {}

    ngOnInit(): void {
        this.authService.logout();
    }
}

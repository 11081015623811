import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { OnboardingService } from 'app/services/api/onboarding.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-step-welcome',
    templateUrl: './step-welcome.component.html',
    styleUrls: ['./step-welcome.component.scss']
})
export class StepWelcomeComponent implements OnInit, OnDestroy {
    @Input() mail: string;
    reSendMailDisabled = true;
    private resendMailSubscription: Subscription;

    constructor(private readonly onboardingService: OnboardingService) {}

    ngOnInit(): void {
        setTimeout(() => {
            this.reSendMailDisabled = false;
        }, 5000);
    }

    ngOnDestroy(): void {
    this.resendMailSubscription?.unsubscribe();
    }

    reSendMail(): void {
        this.reSendMailDisabled = true;
        this.resendMailSubscription = this.onboardingService.resendMail(this.mail).subscribe();
        setTimeout(() => {
            this.reSendMailDisabled = false;
        }, 5000);
    }
}

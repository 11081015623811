<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
             fxLayoutAlign.gt-xs="space-between center">
            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                <button mat-stroked-button routerLink="/subscription/invoices" *ngIf="deactivatedAccount | async">
                    <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK' | translate }}
                </button>
                <mat-icon class="logo-icon mr-16">payment</mat-icon>
                <span class="logo-text h1">{{ !payout ? ('PAYMENTS.TITLE' | translate) : ('PAYMENTS.TITLE_PAYOUT' | translate:{ date: (payout.created | dateLocale), amount: ((payout.amount / 100) | currencyLocale: payout.currency) }) }}</span>
            </div>

            <!-- / APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="displayPayments">
                <div *ngIf="!payout" class="search-input-wrapper" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                    <input [matDatepicker]="datePicker" style="height: 0; width: 0; opacity: 0;"/>
                    <button mat-flat-button (click)="datePicker.open()"
                            [matTooltip]="'DATE_TIME_PICKER.SWITCH_TO_MULTI_YEAR_VIEW' | translate">
                        {{ viewDate | dateLocale:'MMMM yyyy' }}
                    </button>
                    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                    <mat-datepicker #datePicker startView="year" [startAt]="viewDate"
                                    (yearSelected)="chosenYearHandler($event)"
                                    (monthSelected)="chosenMonthHandler($event); datePicker.close()"></mat-datepicker>
                </div>
                <div class="search-input-wrapper" fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
                    <label for="search" class="mr-8">
                        <mat-icon class="secondary-text">search</mat-icon>
                    </label>
                    <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                        <input id="search" matInput #filter [placeholder]="'SHARED.SEARCH' | translate">
                    </mat-form-field>
                </div>
            </div>

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table *ngIf="displayPayments; else paymentsDisabled" class="paiements-table" #table
                       [dataSource]="dataSource" matSort [trackBy]="trackByFn"
                       [class.hidden]="dataSource?.loading$ | async">

                <ng-container cdkColumnDef="nature">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'RENDEZ_VOUS.RDV' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <button mat-button
                                [disabled]="deactivatedAccount | async"
                                color="accent"
                                class="text-truncate"
                        matTooltip="{{ 'SHARED.SEE_INFORMATIONS' | translate }}"
                        (click)="showRdvDetail(rendezVous)">#{{ rendezVous.id }} &middot; {{ rendezVous.nature }}</button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef
                                     class="text-bold">{{ 'SHARED.TOTAL' | translate }}</mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="veterinaire">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'VETO.VETO' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <app-avatar [entity]="rendezVous.veterinaire"></app-avatar>
                        <button mat-button color="accent" class="text-truncate"
                                [disabled]="deactivatedAccount | async"
                                matTooltip="{{ 'SHARED.SEE_INFORMATIONS' | translate }}"
                                (click)="showUserDetails(rendezVous.veterinaire, 'veterinaire')">{{ rendezVous.veterinaire | userFullName:true }}</button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="client">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'RENDEZ_VOUS.CLIENT' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <app-avatar [entity]="rendezVous.client"></app-avatar>
                        <button mat-button color="accent" class="text-truncate"
                                [disabled]="deactivatedAccount | async"
                                matTooltip="{{ 'SHARED.SEE_INFORMATIONS' | translate }}"
                                (click)="showUserDetails(rendezVous.client, 'client')">{{ rendezVous.client | userFullName:true }}</button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="animal">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'RENDEZ_VOUS.ANIMAL' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <app-avatar [entity]="rendezVous.animal"></app-avatar>
                        <button mat-button color="accent" class="text-truncate"
                                [disabled]="deactivatedAccount | async"
                                matTooltip="{{ 'SHARED.SEE_INFORMATIONS' | translate }}"
                                (click)="showAnimalDetail(rendezVous.animal)">{{ rendezVous.animal?.nom }}</button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="date">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'RENDEZ_VOUS.DATE' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <p class="text-truncate">{{ rendezVous.date | dateLocale }}</p>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="status">
                    <mat-header-cell *cdkHeaderCellDef>
                        <span class="header-rendezVous">{{ 'RENDEZ_VOUS.STATUS.TITLE' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <div [ngSwitch]="rendezVous.paymentStatus" class="{{ rendezVous.paymentStatus }}">
                            <div *ngSwitchCase="'waiting_payment'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYMENTS.STATUS.'+rendezVous.paymentStatus | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'payed'">
                                <mat-chip-list>
                                    <mat-chip class="clickable" (click)="capture(rendezVous)" [matTooltip]="'RENDEZ_VOUS.CAPTURABLE_UNTIL' | translate:{ date: (rendezVous.captureUntilDate | dateLocale:'shortDate')}">
                                        {{ 'SHARED.DIALOG_CAPTURE.TO_CAPTURE' | translate }}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'canceled'">
                                <mat-chip-list>
                                    <mat-chip>{{ 'PAYMENTS.STATUS.'+rendezVous.paymentStatus | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'captured'">
                                <mat-chip-list>
                                    <mat-chip [matTooltip]="'RENDEZ_VOUS.IS_CAPTURED' | translate:{ date: (rendezVous.paymentCurrentStateDate | dateLocale:'shortDate') }">{{ 'PAYMENTS.STATUS.'+rendezVous.paymentStatus | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                            <div *ngSwitchCase="'timeout'">
                                <mat-chip-list>
                                    <mat-chip [matTooltip]="'RENDEZ_VOUS.CAPTURABLE_UNTIL' | translate:{ date: (rendezVous.captureUntilDate | dateLocale:'shortDate')}">{{ 'PAYMENTS.STATUS.'+rendezVous.paymentStatus | translate }}</mat-chip>
                                </mat-chip-list>
                            </div>
                        </div>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="price">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'SHARED.PRICE' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <p *ngIf="(rendezVous.paymentStatus == 'captured' && rendezVous.pricePayed != rendezVous.prix) || rendezVous.paymentStatus == 'canceled'; else otherPrice">
                            <span class="striketrough">{{ rendezVous.prix > 0 ? (rendezVous.prix | currencyLocale:rendezVous.currency) : (0 | currencyLocale:rendezVous.currency) }}</span>
                            &rarr;
                            <span>{{ rendezVous.pricePayed > 0 ? (rendezVous.pricePayed | currencyLocale:rendezVous.currency) : (0 | currencyLocale:rendezVous.currency) }}</span>
                        </p>
                        <ng-template #otherPrice>
                            <p>
                                <span>{{ rendezVous.prix | currencyLocale:rendezVous.currency }}</span>
                            </p>
                        </ng-template>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold">
                        {{ getTotalPrice() | async | currencyLocale:(currencyEntiteJuridique | async) }}
                    </mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="priceTotal">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span class="header-rendezVous">{{ 'SHARED.TOTAL' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <p class="text-truncate"
                           *ngIf="rendezVous.paymentStatus == 'captured' || rendezVous.paymentStatus == 'canceled'">
                            {{ rendezVous.pricePayed > 0 ? (rendezVous.pricePayed | priceAfterTaxe | async | round:2 | currencyLocale:rendezVous.currency) : (0 | currencyLocale:rendezVous.currency) }}
                            <span *ngIf="rendezVous.pricePayed > 0" class="taxe" [matTooltip]="'PAYMENTS.TAXE' |  translate">(-{{ rendezVous.pricePayed | priceAfterTaxe:true | async | round:2 | currencyLocale:rendezVous.currency }})</span>
                        </p>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold">
                        {{ getTotalWithTax() | async | currencyLocale:(currencyEntiteJuridique | async) }}
                    </mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="available">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header>
                        <span>{{ 'PAYMENTS.AVAILABLE_ON' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <span *ngIf="rendezVous.paymentAvailableOn">{{ rendezVous.paymentAvailableOn | dateLocale }}</span>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <ng-container cdkColumnDef="receipt">
                    <mat-header-cell *cdkHeaderCellDef>
                        <span>{{ 'SHARED.RECEIPT' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let rendezVous">
                        <button *ngIf="rendezVous.paymentStatus == 'captured'" mat-icon-button color="accent"
                                matTooltip="{{ 'SHARED.OPEN_RECEIPT' | translate }}" (click)="openReceipt(rendezVous)">
                            <mat-icon>open_in_new</mat-icon>
                        </button>
                    </mat-cell>
                    <mat-footer-cell *matFooterCellDef class="text-bold"></mat-footer-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *cdkRowDef="let row; columns: displayedColumns;"></mat-row>
                <mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"
                                [ngClass]="{'hide':(dataSource?.isEmpty() | async)}"></mat-footer-row>
            </mat-table>

            <div *ngIf="dataSource?.loading$ | async; else loaded" class="mt-8"
                 fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="dataSource?.isEmpty() | async" class="primary-fg mt-8">
                    <div class="text-center m-8">
                        <p>{{ 'PAYMENTS.ANY' | translate }}</p>
                    </div>
                </div>
            </ng-template>

            <ng-template #paymentsDisabled>
                <div fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="center center">
                    <img src="/assets/images/empty-state-card.svg" class="mb-8"/>
                    <p>{{ 'PAYMENTS.DISABLED_SPEECH' | translate }}</p>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

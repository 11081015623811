<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <button mat-stroked-button class="back-button" (click)="onBackHomeClicked()">
            <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK_HOME' | translate }}
        </button>
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg" alt="Univet">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg" alt="Univet">
            </div>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxFlex="1 1 auto">
        <mat-card>
            <mat-card-content>
                <div class="step-header">
                    <h1>{{ 'ONBOARDING_CLIENT.REGISTER' | translate }}</h1>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                    <a target="_blank"
                        style="display: inline-block; overflow: hidden; border-radius: 10px; width: 200px; height: 66px;"
                        href="https://apps.apple.com/fr/app/univet/id1434308403?itsct=apps_box_badge&amp;itscg=30200">
                        <img alt="Download on the App Store"
                            style="border-radius: 10px; width: 200px; height: 66px;"
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/fr-fr?size=200x66&amp;releaseDate=1536278400&h=10fc216bd347df56c7d6c3d79f3e7788"/>
                    </a>

                    <a target="_blank"
                        style="width: 240px"
                        href='https://play.google.com/store/apps/details?id=com.univet.app&hl=fr&gl=FR'>
                        <img alt="Download on Google Play"
                            src='https://play.google.com/intl/fr_FR/badges/static/images/badges/fr_badge_web_generic.png'/>
                    </a>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>

import { Entity } from './entity';
import { JsonProperty } from 'json-object-mapper';
import { DateMapper } from 'app/object-mapper/date-mapper';
import { EntiteJuridique } from './pro/entite-juridique';
import { Veterinaire } from './utilisateurs/veterinaire';
import { TodoPostInterface } from './interfaces/post/todo-post.interface';

export class Todo extends Entity {
    @JsonProperty()
    description: string = null;

    @JsonProperty()
    checked = false;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    date: Date = null;

    @JsonProperty({ type: EntiteJuridique })
    entiteJuridique?: EntiteJuridique = null;

    @JsonProperty({ type: Veterinaire })
    veterinaire?: Veterinaire = null;

    public hasPost(): TodoPostInterface {
        return {
            id: this.id,
            description: this.description,
            checked: this.checked,
            date: this.date,
            entiteJuridique: this.entiteJuridique ? this.entiteJuridique.id : null,
            veterinaire: this.veterinaire ? this.veterinaire.id : null
        };
    }
}

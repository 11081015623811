<div class="page-layout simple fullwidth">
    <div class="visio-container" fxLayout="row">
        <app-visio-gestion></app-visio-gestion>
        <div class="visio-subscriber-container" fxFlex="1 0 1" fxLayout="row" fxLayoutAlign="flex-end stretch">
            <div *ngIf="loading" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                <app-loading color="primary"></app-loading>
            </div>
            <div *ngIf="!loading" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center stretch">
                <div class="timer-container"
                    *ngIf="(visioService.currentStreams | async)?.length > 0 || visioService.calculatedTime > 0">
                    <app-visio-timer></app-visio-timer>
                </div>
                <div *ngIf="!loading"
                    [ngSwitch]="visioService.currentStatut | async" fxFlex="1 1" fxLayout="column">
                    <div *ngSwitchCase="'idle'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <div *ngIf="isClientHere() | async; else noClient" fxLayout="column" fxLayoutAlign="center center" class="text-card mb-32">
                            <p [innerHTML]="'VISIO.CLIENT_JOIN' | translate:{ clientName: rendezVous.client.fullNameLite, time: getElapsedTime(rendezVous.waitingRoomEnterDate) }"></p>
                            <button mat-raised-button color="primary" class="text-uppercase" (click)="call()">
                                <mat-icon class="mr-8">videocam</mat-icon>
                                {{ 'VISIO.JOIN_CLIENT' | translate }}
                            </button>
                        </div>

                        <ng-template #noClient>
                            <div fxLayout="column" fxLayoutAlign="center center" class="text-card mb-32">
                                <p [innerHTML]="'VISIO.WARNING_BEFORE' | translate:{ clientName: rendezVous.client.fullNameLite, clientPhone: (rendezVous?.client?.telephonePortable | phone) }"></p>
                                <button mat-raised-button color="primary" class="text-uppercase" (click)="call()">
                                    <mat-icon class="mr-8">videocam</mat-icon>
                                    {{ 'VISIO.LAUNCH' | translate }}
                                </button>
                            </div>
                        </ng-template>
                    </div>
                    <div *ngSwitchCase="'loading'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <app-loading></app-loading>
                    </div>
                    <div *ngSwitchCase="'clientHangOut'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <i class="Phone is-hangout"></i>
                        <p class="info-text">{{ 'VISIO.CLIENT_HANG_UP' | translate }}</p>
                        <!-- <button mat-raised-button (click)="recall()">{{ 'VISIO.CALL_RE' | translate }}</button> -->
                        <button mat-raised-button (click)="hangOut()" color="warn" class="mt-8">{{ 'VISIO.FINISH_SESSION' | translate }}</button>
                    </div>
                    <div *ngSwitchCase="'finished'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <i class="Phone is-hangout"></i>
                        <p class="info-text">{{ 'VISIO.CALL_ENDED' | translate }}</p>
                    </div>
                    <div *ngSwitchCase="'timeout'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <i class="Phone is-not-animating"></i>
                        <p class="info-text">{{ 'VISIO.CLIENT_NOT_PICK_UP' | translate }}</p>
                        <button mat-raised-button (click)="recall()">{{ 'VISIO.CALL_RE' | translate }}</button>
                    </div>
                    <div *ngSwitchCase="'ringing'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <i class="Phone is-animating"></i>
                        <p class="info-text">{{ 'VISIO.CALL_IN_PROGRESS' | translate }}</p>
                    </div>
                    <div *ngSwitchCase="'errored'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon class="warn-icon">warning</mat-icon>
                        <p class="info-text">{{ 'VISIO.ERROR_SESSION_CONNEXION' | translate }}</p>
                        <button mat-raised-button (click)="recall()">{{ 'SHARED.RETRY' | translate }}</button>
                    </div>

                    <div *ngSwitchCase="'ongoing'" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center stretch">
                        <div *ngIf="!(visioService.isStreamingFile | async)" fxFlex="1 1" fxLayout="column" fxLayoutAlign="center stretch">
                            <app-visio-subscriber fxFlex="1 1" *ngFor="let stream of (visioService.currentStreams | async)"
                            [stream]="stream"></app-visio-subscriber>
                        </div>

                        <app-visio-video *ngIf="visioService.isStreamingFile | async" fxFlex="1 1"></app-visio-video>
                    </div>
                </div>
            </div>
            <app-visio-action fxFlex="0 0 auto" *ngIf="!loading"></app-visio-action>
        </div>
        <div class="visio-right-container" fxLayout="column">
            <div class="visio-publisher-container">
                <app-visio-publisher></app-visio-publisher>
            </div>
            <mat-tab-group fxFlex="1 1" [dynamicHeight]="true">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="mr-8">chat</mat-icon>
                        {{ 'VISIO.TCHATS' | translate }}
                    </ng-template>
                    <div fxLayout="column nowrap" fxLayoutAlign="start stretch" style="width: 100%; height: 100%;"
                        *ngIf="chatSelected !== null; else chatNotReady">
                        <mat-form-field fxFlex="0 1 auto">
                            <mat-select [ngModel]="chatSelected" (selectionChange)="chatChanged($event.value)">
                                <mat-option *ngFor="let chat of chats; let i=index" [value]="i">
                                    {{ chat.nom + ' - ' + chat.categorie.value }}
                                    {{ !chat.canChat ? '- ' + ('VISIO.CLOSE' | translate) : '' }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <app-chat-view *ngIf="chatObjectSelected" fxFlex="1 1 auto" [visio]="true"></app-chat-view>
                    </div>
                    <ng-template #chatNotReady>
                        <div *ngIf="chatLoading; else noChat">
                            <div fxLayout="column" fxLayoutAlign="center center" class="w-100-p m-8">
                                <app-loading [diameter]="20"></app-loading>
                            </div>
                        </div>
                        <ng-template #noChat>
                            <div fxFlex fxLayout="column" fxLayoutAlign="center center">
                                <app-chat-start [visio]="true" [animal]="rendezVous?.animal"></app-chat-start>
                            </div>
                        </ng-template>
                    </ng-template>
                </mat-tab>

                <!-- TODO: Isoler le code dans un composant Angular -->
                <mat-tab>
                    <ng-template mat-tab-label>
                        <mat-icon class="mr-8">edit</mat-icon>
                        {{ 'VISIO.NOTES' | translate }}
                    </ng-template>
                    <div fxLayout="column" class="notes">

                        <mat-accordion multi="false">

                            <mat-expansion-panel expanded="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'REPORTS.VISIO.PURPOSE' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'VISIO.NOTES' | translate }}</mat-label>
                                    <textarea matInput [(ngModel)]="note.purpose" [placeholder]="'REPORTS.VISIO.PLACEHOLDER' | translate" (change)="modifyNote()"></textarea>
                                </mat-form-field>

                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'REPORTS.VISIO.HISTORY' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'VISIO.NOTES' | translate }}</mat-label>
                                    <textarea matInput [(ngModel)]="note.history" [placeholder]="'REPORTS.VISIO.PLACEHOLDER' | translate" (change)="modifyNote()"></textarea>
                                </mat-form-field>

                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'REPORTS.VISIO.EXAMINATION' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'VISIO.NOTES' | translate }}</mat-label>
                                    <textarea matInput [(ngModel)]="note.exam" [placeholder]="'REPORTS.VISIO.PLACEHOLDER' | translate" (change)="modifyNote()"></textarea>
                                </mat-form-field>

                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'REPORTS.VISIO.INTERPRETATION' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'VISIO.NOTES' | translate }}</mat-label>
                                    <textarea matInput [(ngModel)]="note.interpretation" [placeholder]="'REPORTS.VISIO.PLACEHOLDER' | translate" (change)="modifyNote()"></textarea>
                                </mat-form-field>

                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        {{ 'REPORTS.VISIO.ADVICE' | translate }}
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'VISIO.NOTES' | translate }}</mat-label>
                                    <textarea matInput [(ngModel)]="note.advice" [placeholder]="'REPORTS.VISIO.PLACEHOLDER' | translate" (change)="modifyNote()"></textarea>
                                </mat-form-field>

                            </mat-expansion-panel>

                        </mat-accordion>

                        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="w-100-p note-controls">

                            <button mat-raised-button color="primary" (click)="saveNotes()">
                                <mat-icon>save</mat-icon>
                                {{ 'REPORTS.VISIO.BUTTON_SAVE' | translate }}
                            </button>

                            <button mat-raised-button color="primary" [disabled]="note.isModified" (click)="yieldReport()">
                                <mat-icon>assignment</mat-icon>
                                {{ 'REPORTS.VISIO.BUTTON_REPORT' | translate }}
                            </button>

                        </div>

                    </div>
                </mat-tab>

            </mat-tab-group>
        </div>
    </div>
</div>

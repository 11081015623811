import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/api/auth.service';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { ChatService } from '../services/api/chat.service';
import { EntiteGeographiqueService } from '../services/api/entite-geographique.service';
import { ConfigService } from '../services/config.service';
import { AuthGuard } from './auth.guard';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })

export class ChatAuthGuard extends AuthGuard {
    constructor(router: Router,
        authService: AuthService,
        utilisateurService: UtilisateurService,
        chatService: ChatService,
        entiteGeographiqueService: EntiteGeographiqueService,
        configService: ConfigService,
        translateService: TranslateService
    ) {
        super(
            router,
            authService,
            utilisateurService,
            chatService,
            entiteGeographiqueService,
            configService,
            translateService
        );
        this.withChat = true;
    }
}

<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'FORM.REGION' | translate }}</mat-label>
    <mat-select
        [placeholder]="'FORM.REGION' | translate"
        [formControl]="control | asFormControl"
        [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="regionsFilter"
                [placeholderLabel]="'FORM.REGION' | translate"
                [noEntriesFoundLabel]="'FORM.NO_RESULT' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let region of filteredRegionsOptions$ | async" [value]="region.shortCode">
            {{ region.name }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
        {{ 'FORM.REGION_REQUIRED' | translate }}
    </mat-error>
</mat-form-field>

import { Animal } from '../animal/animal';
import { HistoriqueVisio } from './historique-visio';
import { JsonProperty } from 'json-object-mapper';
import { Client } from '../utilisateurs/client';
import { Veterinaire } from '../utilisateurs/veterinaire';
import { List } from '../list';
import { Entity } from '../entity';
import { StatusRendezVousMapper } from '../../object-mapper/status-rendez-vous-mapper';
import { DateMapper } from '../../object-mapper/date-mapper';
import { RendezVousPostInterface } from '../interfaces/post/rendez-vous-post.interface';
import { Categorie } from '../categorie';
import { StatusPaymentRendezVousMapper } from '../../object-mapper/status-payment-rendez-vous-mapper';
import { ConnectorIdInterface } from '../interfaces/connector-id.interface';
import { ClientWaiting } from '../../services/api/waiting-room.service';

export enum StatusRendezVousEnum {
    'waiting_for_client_confirmed' = 'waiting_for_client_confirmed',
    ready = 'ready',
    'in_progress' = 'in_progress',
    'ended_must_pay' = 'ended_must_pay',
    ended = 'ended',
    canceled = 'canceled'
}

export enum StatusPaymentRendezVousEnum {
    'waiting_confirm' = 'waiting_confirm',
    'waiting_payment' = 'waiting_payment',
    payed = 'payed',
    captured = 'captured',
    canceled = 'canceled',
    free = 'free',
    timeout = 'timeout'
}

export enum ViewStatusRendezVousEnum {
    'not_confirmed' = 'not_confirmed',
    'confirmed' = 'confirmed',
    'late' = 'late',
    'waiting_room' = 'waiting_room',
    'ongoing' = 'ongoing',
    'not_done' = 'not_done',
    'done' = 'done',
    'not_captured' = 'not_captured'
}

export class RendezVous extends Entity implements ConnectorIdInterface {
    public static readonly DURATION_BEFORE_AVAILABLE = 10; // minutes
    public static readonly DURATION_AFTER_AVAILABLE = 10; // minutes

    @JsonProperty({ type: StatusRendezVousEnum, deserializer: StatusRendezVousMapper, serializer: StatusRendezVousMapper })
    statut?: StatusRendezVousEnum = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    clientConfirme: Date = null;

    @JsonProperty({ type: HistoriqueVisio })
    historiqueVisios: HistoriqueVisio[] = null;

    @JsonProperty({ type: Client })
    client: Client = null;

    @JsonProperty({ type: Veterinaire })
    veterinaire: Veterinaire = null;

    @JsonProperty({ type: Animal })
    animal: Animal = null;

    @JsonProperty()
    nature: string = null;

    @JsonProperty({ type: Categorie })
    categorie: Categorie = null;

    @JsonProperty()
    dureePrevue: number = null;

    @JsonProperty()
    dureeTotale: number = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    date: Date = null;

    @JsonProperty()
    commentaire?: string = null;

    @JsonProperty({
        type: StatusPaymentRendezVousEnum,
        deserializer: StatusPaymentRendezVousMapper,
        serializer: StatusPaymentRendezVousMapper
    })
    paymentStatus?: StatusPaymentRendezVousEnum = null;

    @JsonProperty()
    prix?: number = null;

    @JsonProperty()
    priceWithoutVat?: number = null;

    @JsonProperty()
    pricePayed?: number = null;

    @JsonProperty()
    currency?: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    payed?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    captured?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    waitingRoomEnterDate?: Date = null;

    @JsonProperty()
    mustPay?: boolean = null;

    @JsonProperty()
    mustCapture?: boolean = null;

    @JsonProperty()
    mustConfirm?: boolean = null;

    @JsonProperty()
    hasStarted?: boolean = null;

    @JsonProperty()
    isFinished?: boolean = null;

    @JsonProperty()
    canBeFullEdited?: boolean = null;

    @JsonProperty()
    canBeDeleted?: boolean = null;

    @JsonProperty()
    sourceLinkyvet?: boolean = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    captureUntilDate?: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    paymentAvailableOn?: Date = null;

    @JsonProperty()
    stripeChargeId?: string = null;

    @JsonProperty()
    connectorId?: string = null;

    constructor() {
        super();

        this.date = new Date();
        this.date.setHours(this.date.getHours() + 1);
        this.date.setMinutes(0);
        this.date.setSeconds(0);
        this.dureePrevue = 10;

        this.mustConfirm = true;
        this.mustPay = false;
        this.mustCapture = false;
        this.canBeFullEdited = true;
        this.canBeDeleted = false;
        this.hasStarted = false;
        this.isFinished = false;
    }

    public hasPost(): RendezVousPostInterface {
        return {
            id: this.id,
            nature: this.nature,
            categorie: this.categorie ? this.categorie.id : null,
            dureePrevue: this.dureePrevue,
            date: this.date,
            commentaire: this.commentaire,
            prix: this.priceHtIfPossible,
            currency: this.currency,
            animalId: this.animal ? this.animal.id : null,
            veterinaire: this.veterinaire ? this.veterinaire.id : null,
            clientConfirme: this.clientConfirme,
            connectorId: this.connectorId
        };
    }

    public get priceHtIfPossible(): number {
        if (this.priceWithoutVat) {
            return this.priceWithoutVat;
        }

        return this.prix;
    }

    public get finalPrice(): number {
        if (this.prix) {
            return this.pricePayed ? this.pricePayed : this.prix;
        }

        return 0;
    }

    public get paymentCurrentStateDate(): Date {
        return this.captured ? this.captured : this.payed;
    }

    public get duree(): number {
        return this.dureeTotale ? this.dureeTotale : this.dureePrevue;
    }

    public get correctStatut(): string {
        if (!this.clientConfirme && this.statut === StatusRendezVousEnum.ready) {
            return StatusRendezVousEnum.waiting_for_client_confirmed;
        }

        return this.statut;
    }

    public getViewStatut(clientWaitings: ClientWaiting[] = []): ViewStatusRendezVousEnum {
        const cw = clientWaitings.find(cw => cw.rendezVous.id === this.id);
        if (cw) {
            return ViewStatusRendezVousEnum.waiting_room;
        } else if (this.isFinished || new Date() > new Date(this.date.getTime() + RendezVous.DURATION_AFTER_AVAILABLE * 60 * 1000)) {
            if (!this.clientConfirme || this.statut === StatusRendezVousEnum.canceled) {
                return ViewStatusRendezVousEnum.not_done;
            } else if (this.mustCapture) {
                return ViewStatusRendezVousEnum.not_captured;
            }

            return ViewStatusRendezVousEnum.done;
        } else if (this.statut === StatusRendezVousEnum.in_progress) {
            return ViewStatusRendezVousEnum.ongoing;
        } else if (this.clientConfirme) {
            if (this.date < new Date()) {
                return ViewStatusRendezVousEnum.late;
            }

            return ViewStatusRendezVousEnum.confirmed;
        }

        return ViewStatusRendezVousEnum.not_confirmed;
    }

    public search(q: string): boolean {
        return [
            this.id,
            this.categorie?.value,
            this.captured,
            this.commentaire,
            this.nature,
            this.date,
            this.client?.fullNameLite,
            this.veterinaire?.fullNameLite,
            this.animal?.nom,
            this.statut,
            this.clientConfirme,
            this.prix,
            this.payed
        ]
            .filter(x => Boolean(x))
            .join(' ')
            .includes(q ?? '');
    }
}

export class ListRendezVous extends List {
    @JsonProperty({ type: RendezVous })
    data: RendezVous[] = null;
}


import { NgModule } from '@angular/core';
import { FuseSharedModule } from '../../../@fuse/shared.module';
import { SharedModule } from '../../shared.module';
import { ViewUtilsSharedModule } from './view-utils/view-utils-shared.module';
import { FormSharedModule } from './form/form-shared.module';
import { FormDialogSharedModule } from './form-dialog/form-dialog-shared.module';

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,

        ViewUtilsSharedModule,
        FormSharedModule,
        FormDialogSharedModule
    ],
    exports: [
        ViewUtilsSharedModule,
        FormSharedModule,
        FormDialogSharedModule
    ]
})
export class ViewSharedModule {}

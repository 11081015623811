import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ViewSharedModule } from '../shared/view-shared.module';
import { SharedModule } from '../../shared.module';
import { NoAuthGuard } from '../../guards/no-auth.guard';
import { OnboardingProComponent } from './onboarding-pro.component';
import { StepsComponent } from './steps/steps.component';
import { StepClinicComponent } from './step-clinic/step-clinic.component';
import { StepUserComponent } from './step-user/step-user.component';
import { StepWelcomeComponent } from './step-welcome/step-welcome.component';
import { FormSharedModule } from '../shared/form/form-shared.module';

const routes = [
    {
        path: 'onboarding-pro',
        component: OnboardingProComponent,
        canActivate: [NoAuthGuard]
    }
];

@NgModule({
    declarations: [
        OnboardingProComponent,
        StepsComponent,
        StepClinicComponent,
        StepUserComponent,
        StepWelcomeComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        FormSharedModule
    ]
})
export class OnboardingProModule {}

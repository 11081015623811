<div class="files" [class.selected]="fichierIndex.isSelected">
    <div class="file" *ngIf="fichierIndex.fichier.isImage()">
        <img class="file-content"
            [lazyLoad]="(fichierIndex.fichier.url | filePath).toString()"
            [alt]="fichierIndex.fichier.nom" width="200" height="200"
            [title]="fichierIndex.fichier.date | dateLocale:'medium'">
    </div>
    <div class="file" *ngIf="fichierIndex.fichier.isVideo()">
        <video class="file-content"
            [src]="fichierIndex.fichier.url | filePath"
            [title]="fichierIndex.fichier.date | dateLocale:'medium'"></video>
    </div>
    <div class="file" *ngIf="fichierIndex.fichier.isAudio()">
        <audio class="file-content"
            [src]="fichierIndex.fichier.url | filePath"
            [title]="fichierIndex.fichier.date | dateLocale:'medium'" style="display: none" preload="none"></audio>
        <img lazyLoad="/assets/images/files/file_audio.png" width="150" height="200">
    </div>
    <div class="file" *ngIf="fichierIndex.fichier.isOther()">
        <img lazyLoad="/assets/images/files/file.png" height="200">
    </div>
    <div class="file-overlay" *ngIf="selectionMode == 'SINGLE_INSTANT'" (click)="toggleSelect.emit(fichierIndex)">
        <div class="file-icon">
            <mat-icon>check</mat-icon>
        </div>
        <p *ngIf="fichierIndex.fichier.isOther()">
            {{ fichierIndex.fichier.contentType | match:mimeRegex:null | last | capitalize }}
            ({{ fichierIndex.fichier.taille | fileSize }})</p>
    </div>
    <div class="file-overlay" *ngIf="selectionMode != 'SINGLE_INSTANT' && fichierIndex.fichier.isOther()" (click)="openFile.emit(fichierIndex)">
        <div class="file-icon">
            <mat-icon>{{ fichierIndex.fichier.iconForType }}</mat-icon>
        </div>
        <p *ngIf="fichierIndex.fichier.isOther()">
            {{ fichierIndex.fichier.contentType | match:mimeRegex:null | last | capitalize }}
            ({{ fichierIndex.fichier.taille | fileSize }})</p>
    </div>
    <div class="file-overlay" *ngIf="selectionMode != 'SINGLE_INSTANT' && !fichierIndex.fichier.isOther()" [photoGallery]="(fichierIndex.fichier.url | filePath).toString()" (click)="openFile.emit(fichierIndex)">
        <div class="file-icon">
            <mat-icon>{{ fichierIndex.fichier.iconForType }}</mat-icon>
        </div>
        <p *ngIf="fichierIndex.fichier.isOther()">
            {{ fichierIndex.fichier.contentType | match:mimeRegex:null | last | capitalize }}
            ({{ fichierIndex.fichier.taille | fileSize }})</p>
    </div>
    <mat-menu #actionMenu="matMenu">
        <button mat-menu-item (click)="sendTchat(fichierIndex)"
                *ngIf="chatService.isCurrentChatOpened()">
            <mat-icon>message</mat-icon>
            <span>{{ 'SHARED.SEND_TO_CHAT' | translate }}</span>
        </button>
        <button *ngIf="fichierIndex.fichier.isImage()" mat-menu-item (click)="editImage.emit(fichierIndex)">
            <mat-icon>edit</mat-icon>
            <span>{{ 'SHARED.EDIT' | translate }}</span>
        </button>
        <button mat-menu-item (click)="downloadFile.emit(fichierIndex)">
            <mat-icon>save_alt</mat-icon>
            <span>{{ 'SHARED.DOWNLOAD' | translate }}</span>
        </button>
        <button mat-menu-item (click)="removeFile.emit(fichierIndex)">
            <mat-icon>delete</mat-icon>
            <span>{{ 'SHARED.DELETE' | translate }}</span>
        </button>
    </mat-menu>
    <button *ngIf="selectionMode != 'NONE'" mat-icon-button color="secondary-text" class="select-button"
        [matTooltip]="selectionMode == 'SINGLE_INSTANT' ? ('SHARED.ZOOM' | translate) : ('SHARED.SELECT' | translate)" (click)="selectionMode == 'SINGLE_INSTANT' ? openFile.emit(fichierIndex) : toggleSelect.emit(fichierIndex)">
        <mat-icon>{{ selectionMode == 'SINGLE_INSTANT' ? fichierIndex.fichier.iconForType : 'check_circle'}}</mat-icon>
    </button>
    <button mat-icon-button color="secondary-text" class="more-button" [matMenuTriggerFor]="actionMenu"
        matTooltip="{{ 'SHARED.ACTIONS' | translate }}" *ngIf="displayAction">
        <mat-icon>more_vert</mat-icon>
    </button>
    <!-- <mat-icon class="source" matTooltip="Source : {{ fichierIndex.fichier.type | capitalize}}">{{
        fichierIndex.fichier.type == 'visio' ? 'videocam' : 'chat_bubble' }}
    </mat-icon> -->
    <div class="shadow shadow-top-left" *ngIf="fichierIndex.fichier.isImage() || fichierIndex.fichier.isVideo()"></div>
    <div class="shadow shadow-top-right" *ngIf="fichierIndex.fichier.isImage() || fichierIndex.fichier.isVideo()"></div>
    <!-- <div class="shadow shadow-bottom"
        *ngIf="fichierIndex.fichier.isImage() || fichierIndex.fichier.isVideo()"></div> -->
</div>

<div class="container">
    <div *ngIf="rendezVous?.client">
        <div class="title">{{ 'CLIENT.CLIENT' | translate }}</div>
        <app-profile-tile [title]="rendezVous.client | userFullName" [entity]="rendezVous?.client"
            [actions]="clientActions" class="cursor-pointer" [matTooltip]="'SHARED.SEE_INFORMATIONS' | translate"
            (click)="editClient()">
            <div fxLayout="column" fxLayoutAlign="start start">
                <div>{{ rendezVous?.client.telephonePortable | phone }}</div>
            </div>
        </app-profile-tile>
    </div>

    <div *ngIf="rendezVous?.animal">
        <div class="title">{{ 'PET.ANIMAL' | translate }}</div>
        <app-profile-tile [title]="rendezVous?.animal?.nom" [entity]="rendezVous?.animal" [actions]="animalActions"
            class="cursor-pointer" [matTooltip]="'SHARED.SEE_INFORMATIONS' | translate" (click)="editAnimal()">
            {{ rendezVous?.animal?.espece?.nom }}
            {{ rendezVous?.animal?.race?.nom ? '&middot; ' + rendezVous?.animal?.race?.nom : '' }}
            <br />
            {{ rendezVous?.animal?.dateNaissance | relativeTime }}
        </app-profile-tile>
    </div>

    <div *ngIf="rendezVous">
        <div class="title">{{ 'CALENDAR.DATE' | translate }}</div>
        <app-profile-tile [actions]="rdvActions">
            <p class="m-0">
                <strong>{{ 'DATE.DATE' | translate }} : </strong> {{ rendezVous.date | dateLocale:'short' }} <br/>
                <strong>{{ 'DATE.DURATION_EXPECTED' | translate }} : </strong> {{ rendezVous.dureePrevue }}
                {{ 'SHARED.MINUTES' | translate }} <br/>
                <strong>{{ 'DATE.SUBJECT' | translate }} : </strong> {{ rendezVous.nature }} <br/>
                <span *ngIf="rendezVous.categorie"><strong>{{ 'DATE.CATEGORY' | translate }} : </strong>
                    {{ rendezVous.categorie.value }} <br/></span>
                <strong>{{ 'SHARED.PRICE' | translate }} : </strong>
                {{ rendezVous.finalPrice | currencyLocale:rendezVous.currency }}<br/>
                <span *ngIf="rendezVous.commentaire"><br/><strong>{{ 'DATE.COMMENTS_ONLY_YOU' | translate }} :
                    </strong> {{ rendezVous.commentaire }} <br/></span>
            </p>
        </app-profile-tile>
    </div>

    <div *ngIf="rendezVous">
        <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
            {{ 'FILES.LINKS_RDV' | translate }}
            <mat-spinner *ngIf="fichiersInProgress" mode="indeterminate" [diameter]="20"></mat-spinner>
        </div>
        <app-list-fichier #listFichier [input]="rendezVous" [displayStat]="false" [displayScrollDay]="false"
            [displayLoading]="false" [sortBy]="FichierSortBy.NONE" [groupBy]="FichierGroupBy.NONE" [selectionMode]="FichierSelectionMode.NONE"
            (loadingStateChanged)="fichiersInProgress = $event">
        </app-list-fichier>
    </div>
</div>

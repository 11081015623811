import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from 'ngx-connection-service';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

import { navigation } from 'app/config/navigation';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { FuseConfig, FuseNavigationItem } from '@fuse/types';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    fuseConfig: FuseConfig;
    navigation: FuseNavigationItem[];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private _platform: Platform,
        private _connectionService: ConnectionService,
        private _snackbar: MatSnackBar,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer
    ) {
        matIconRegistry.addSvgIcon(
            'eraser',
            domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/eraser.svg')
        );

        matIconRegistry.addSvgIcon(
            'retry_call',
            domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/retry_call.svg')
        );

        matIconRegistry.addSvgIcon(
            'visiocare',
            domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/visiocare/logo_mini.svg')
        );

        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add is-mobile class to the body if the platform is mobile
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
    }

    ngOnInit(): void {
        // Subscribe to config changes
        this._fuseConfigService.config.subscribe((config: FuseConfig) => {
            this.fuseConfig = config;
        });

        let hasShowOffline = false;
        this._connectionService.monitor().subscribe(online => {
            if (!online.hasNetworkConnection) {
                this._snackbar.open(this._translateService.instant('SHARED.OFFLINE'), this._translateService.instant('SHARED.OK'), { duration: -1 });
                hasShowOffline = true;
            } else if (hasShowOffline && online.hasNetworkConnection) {
                this._snackbar.open(this._translateService.instant('SHARED.ONLINE'), this._translateService.instant('SHARED.OK'), { duration: 2000 });
                hasShowOffline = false;
            }
        });
    }
}

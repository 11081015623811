import { EntiteJuridique } from './entite-juridique';
import { JsonProperty } from 'json-object-mapper';
import { List } from '../list';
import { Veterinaire } from '../utilisateurs/veterinaire';
import { Entity } from '../entity';
import { EntiteGeographiquePostInterface } from '../interfaces/post/pro/entite-geographique-post.interface';
import { Todo } from '../todo';
import { Fichier } from '../fichier';

export class EntiteGeographique extends Entity {
    @JsonProperty()
    nom: string = null;

    @JsonProperty()
    mail?: string = null;

    @JsonProperty()
    telephone?: string = null;

    @JsonProperty()
    adresse?: string = null;

    @JsonProperty()
    codePostal?: string = null;

    @JsonProperty()
    ville?: string = null;

    @JsonProperty()
    region?: string = null;

    @JsonProperty()
    pays?: string = null;

    @JsonProperty({ type: EntiteJuridique })
    entiteJuridique?: EntiteJuridique = null;

    @JsonProperty({ type: Veterinaire })
    veterinaires?: Veterinaire[] = null;

    @JsonProperty()
    openingTime?: number[][] = null;

    @JsonProperty()
    chatAwayMessage: string = null;

    @JsonProperty()
    canBeDeleted: boolean = null;

    @JsonProperty({ type: Todo })
    todos: Todo[] = [];

    @JsonProperty({ type: Fichier })
    logo?: Fichier = null;

    @JsonProperty({ type: Fichier })
    imageCouverture?: Fichier = null;

    @JsonProperty()
    telephoneUrgence?: string = null;

    @JsonProperty()
    lienPriseRendezVous?: string = null;

    @JsonProperty()
    lienBoutique?: string = null;

    @JsonProperty()
    lienSiteWeb?: string = null;

    @JsonProperty()
    lienFacebook?: string = null;

    @JsonProperty()
    lienTwitter?: string = null;

    @JsonProperty()
    lienInstagram?: string = null;

    @JsonProperty()
    clientSignupCode: string = null;

    public hasPost(): EntiteGeographiquePostInterface {
        return {
            id: this.id,
            nom: this.nom,
            mail: this.mail,
            telephone: this.telephone,
            adresse: this.adresse,
            ville: this.ville,
            region: this.region,
            codePostal: this.codePostal,
            pays: this.pays,
            entiteJuridique: this.entiteJuridique ? this.entiteJuridique.id : null,
            openingTime: this.openingTime,
            chatAwayMessage: this.chatAwayMessage,
            logoId: this.logo?.id,
            imageCouvertureId: this.imageCouverture?.id,
            telephoneUrgence: this.telephoneUrgence,
            lienPriseRendezVous: this.lienPriseRendezVous,
            lienBoutique: this.lienBoutique,
            lienSiteWeb: this.lienSiteWeb,
            lienFacebook: this.lienFacebook,
            lienTwitter: this.lienTwitter,
            lienInstagram: this.lienInstagram,

            logo: this.logo,
            imageCouverture: this.imageCouverture
        };
    }
}

export class ListEntitesGeographiques extends List {
    @JsonProperty({ type: EntiteGeographique })
    data: EntiteGeographique[] = null;
}

import { Injectable } from '@angular/core';

interface TagEnclosedArea{
    start: number;
    end: number;
}

interface StringFragment{
    type: TagRelatedFragmentType;
    content: string;
}

enum TagRelatedFragmentType{
    Inner,
    Outer,
    Tag
}

export enum TagHandlingOption{
    Remove,
    Preserve
}

@Injectable({
  providedIn: 'root'
})
export class TagEnclosedAreaManagerService {
    // Scanne le chaîne passée en paramètre, à la recherche des zones délimitées (Tag Enclosed Area - TEA) par les balises ouvrantes et fermantes passées.
    // Selon l'option choisie, renvoie la chaîne avec ou sans le contenu des TEA.
    // Dans tous les cas, les balises sont éliminées de la chaîne initiale.
    // ! Requiert que les 2 balises comportent le même nbr de caractères.
    public handleTagEnclosedAreas(stringWithTags: string, openingTag: string, closingTag: string, option: TagHandlingOption): string {
        const OTs = this.getTagsInText(stringWithTags, openingTag);
        const CTs = this.getTagsInText(stringWithTags, closingTag);
        const TEAs: TagEnclosedArea[] = this.getMatchingPairsOfTags(OTs, CTs, openingTag.length);
        const TEANbr = TEAs.length;

        if (TEANbr > 0) {
            let TEAIndex = 0;
            let SFs: StringFragment[] = [];

            for (const TEA of TEAs) {
                let before = '';
                if (TEAIndex === 0) {
                    before = stringWithTags.slice(0, TEA.start);
                }

                const OT = stringWithTags.slice(TEA.start, TEA.start + openingTag.length);
                const between = stringWithTags.slice(TEA.start + openingTag.length, TEA.end);
                const CT = stringWithTags.slice(TEA.end, TEA.end + closingTag.length);

                let after = '';
                after = TEAIndex < TEANbr - 1 ? stringWithTags.slice(TEA.end + closingTag.length, TEAs[TEAIndex + 1].start) : stringWithTags.slice(TEA.end + closingTag.length);

                const fragmentsToAdd = [
                    { type: TagRelatedFragmentType.Outer, content: before },
                    { type: TagRelatedFragmentType.Tag, content: OT },
                    { type: TagRelatedFragmentType.Inner, content: between },
                    { type: TagRelatedFragmentType.Tag, content: CT },
                    { type: TagRelatedFragmentType.Outer, content: after }
                ];
                SFs = SFs.concat(fragmentsToAdd);
                TEAIndex++;
            }

            if (option === TagHandlingOption.Remove) {
                return SFs.filter(SF => SF.type === TagRelatedFragmentType.Outer).map(SF => SF.content).join('');
            }

            return SFs.filter(SF => SF.type !== TagRelatedFragmentType.Tag).map(SF => SF.content).join('');
        }

        return stringWithTags;
    }

    // Effectue une analyse conjointe d'une série de positions de balises ouvrantes et fermantes dans une chaîne.
    // Retourne la liste des couple balise ouvrante / balise fermante qui sont pertinents, sous forme d'un object
    // contenant la position du 1er caractère de chaque balise du couple.
    // Algorithme générique pouvant servir à d'autres balises, pour peu qu'elles soient de même longueur.
    private getMatchingPairsOfTags(openingTagPositions: number [], closingTagPositions: number[], tagLength: number): TagEnclosedArea[] {
        const result: TagEnclosedArea[] = [];

        let CTOffset = 0;

        for (const i of openingTagPositions.keys()) {
            const OTPosition = openingTagPositions[i];
            const CTPositionsToScan = closingTagPositions.slice(i + CTOffset);

            for (const j of CTPositionsToScan.keys()) {
                const CTPosition = CTPositionsToScan[j];

                if (CTPosition >= OTPosition + tagLength) {
                    if (i < openingTagPositions.length && CTPosition >= openingTagPositions[i + 1] + tagLength) {
                        CTOffset--;
                        break;
                    }

                    result.push({ start: OTPosition, end: CTPosition });
                    break;
                } else {
                    CTOffset++;
                }
            }
        }

        return result;
    }

    // Retourne un tableau contenant la liste des index du 1er caractère d'une sous-chaîne dans une chaîne
    // Genre d'indexOf() qui ne se limite pas à la 1ère occurance de la sous-chaîne dans la chaîne.
    // Algorithme générique pouvant servir à d'autres balises.
    private getTagsInText(text: string, tag: string): number[] {
        let lastIndex = 0;
        const positions: number[] = [];
        const wholeString = text;

        while (wholeString.includes(tag, lastIndex)) {
            lastIndex = wholeString.indexOf(tag, lastIndex);
            if (lastIndex === -1) {
                break;
            }

            positions.push(lastIndex);
            if (lastIndex > wholeString.length - 2 * tag.length) {
                break;
            }

            lastIndex += tag.length;
        }

        return positions;
    }

    // TODO: Convertir en test unitaire utlérieurement
    // N'a pour seule vocation de de valider l'algorithme de remplacement des TEA
    // A conserver car l'algo est complexe, bien que ce soit du code mort
    private testRemovingProAreas() {
        const testA = 'NORMAL__ABC[[pro***pro]]DEFGHI[[pro***pro]]JKLMNO[[pro***pro]]';
        const testB = 'CASN°1__ABC[[pro***pro]]DEFGHI[[pro***pro]]JKLMNOpro]]PQRpro]]';
        const testC = 'CASN°2__ABC[[proDEF[[proIJKLMN[[pro***pro]]OPQRST[[pro***pro]]';
        const testD = 'CASN°3__ABCpro]]DEFpro]]IJKLMNpro]]OPQpro]]RSTUVWpro]]XYZpro]]';
        const testE = 'CASN°3__ABC[[proDEF[[proIJKLMN[[proOPQ[[proRSTUVW[[proXYZ[[pro';
        const testF = 'CASN°5__ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZAB';
        const testG = 'CASN°6__ABC[[prDEFGpro]]IJKLMN[[pro***pro]]OPQRST[[pro***pro]]';
        const testH = 'CASN°7__ABC[[proDEFGpr]]HIJKLM[[pro***pro]]OPQRST[[pro***pro]]';
        const testI = 'CASN°8__ABC[[proDEF[[proIJKLMN[[pro***pro]]OPQRSTpro]]UVWpro]]';
        const testJ = 'CASN°9__ABCpro]]DEFpro]]GHIJKL[[pro***pro]]MNOPQR[[proSTU[[pro';

        const tests: string[] = [testA, testB, testC, testD, testE, testF, testG, testH, testI, testJ];

        for (const test of tests) {
            console.log(`input: ${test}`);
            const processedString = this.handleTagEnclosedAreas(test, '[[pro', 'pro]]', TagHandlingOption.Remove);
            console.log(`output: ${processedString}`);
            console.log('\n');
        }
    }

    // TODO: Convertir en test unitaire utlérieurement
    // N'a pour seule vocation de de valider l'algorithme de screening des zones [[pro---pro]]
    // A conserver car l'algo est complexe, bien que ce soit du code mort
    private testProAreasScreening(): void {
        const test1 = 'NORMAL---[[pro---pro]]------[[pro---pro]]------[[pro---pro]]';
        const test2 = 'CASN°1---[[pro---pro]]------[[pro---pro]]------pro]]---pro]]';
        const test3 = 'CASN°2---[[pro---[[pro------[[pro---pro]]------[[pro---pro]]';
        const test4 = 'CASN°3---pro]]---pro]]------pro]]---pro]]------pro]]---pro]]';
        const test5 = 'CASN°4---[[pro---[[pro------[[pro---[[pro------[[pro---[[pro';
        const test6 = 'CASN°5------------------------------------------------------';
        const test7 = 'CASN°6---[[pr----pro]]------[[pro---pro]]------[[pro---pro]]';
        const test8 = 'CASN°7---[[pro----pr]]------[[pro---pro]]------[[pro---pro]]';
        const test9 = 'CASN°8---[[pro---[[pro------[[pro---pro]]------pro]]---pro]]';
        const test10 = 'CASN°9---pro]]---pro]]------[[pro---pro]]------[[pro---[[pro';

        const tests: string[] = [test1, test2, test3, test4, test5, test6, test7, test8, test9, test10];

        for (const test of tests) {
            console.log('\n=======================');
            console.log(test);
            const OTs = this.getTagsInText(test, '[[pro');
            const CTs = this.getTagsInText(test, 'pro]]');
            console.log(this.getMatchingPairsOfTags(OTs, CTs, 5));
            console.log('=======================\n');
        }
    }
}

<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px" class="container">
    <div class="photo-wrapper" (click)="openImageSelector()" *ngIf="!photo">
        <div class="photo-edit-overlay" *ngIf="enablePhotoForm && (entityData$ | async)?.photo">
            <mat-icon>edit</mat-icon>
        </div>
        <div [class]="'photo-edit' + (enablePhotoForm ? ' hover-activated' : '')" *ngIf="(entityData$ | async)?.photo">
            <img [lazyLoad]="((entityData$ | async)?.photo | filePath).toString()" class="photo-actuelle"/>
        </div>
        <div class="photo-add" *ngIf="enablePhotoForm && !(entityData$ | async)?.photo">
            <mat-icon>add</mat-icon>
        </div>
        <div [class]="'photo-delete' + (enablePhotoForm ? ' hover-activated' : '')"
             *ngIf="enablePhotoForm && !(photoInProgress$ | async) && !(photoDelInProgress$ | async) && (entityData$ | async)?.photo && !photo"
             (click)="setImage(null); $event.stopPropagation();">
            <button mat-mini-fab color="primary">
              <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
        <mat-spinner mode="indeterminate" [diameter]="20" color="primary"
                     *ngIf="(photoInProgress$ | async) || (photoDelInProgress$ | async)"></mat-spinner>
    </div>
</div>

<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header secondary-text"
             fxLayout="column" fxLayoutAlign="start center"
             fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between start">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">pets</mat-icon>
                <span class="logo-text h1">{{ 'SHARED.CLIENT_PET' | translate }}</span>
            </div>
            <!-- / APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                <div>
                    <div class="search-input-wrapper mx-24 m-md-0" fxFlex="1 0 auto" fxLayout="row"
                            fxLayoutAlign="start center">
                        <label for="search" class="mr-8">
                            <mat-icon class="secondary-text">search</mat-icon>
                        </label>
                        <mat-form-field floatLabel="never" fxFlex="1 0 auto">
                            <input id="search" matInput #filter [placeholder]="'SHARED.SEARCH' | translate">
                        </mat-form-field>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <button mat-raised-button class="mt-12 mb-8" color="primary" (click)="openClient()">
                        <mat-icon>add</mat-icon> {{ 'CLIENT.NEW' | translate }}
                    </button>

                    <!-- <p *ngIf="signUpLink | async" class="link-container m-0 text-center" [matTooltip]="'CLIENT.SIGNUP_LINK.TOOLTIP' | translate" [matTooltipClass]="'tooltip-multiline'">
                        {{ 'CLIENT.SIGNUP_LINK.LABEL' | translate }}&nbsp;<mat-icon>help</mat-icon><br />
                        <span class="link blue-fg" (click)="copyLinkToClipboard()">{{ signUpLink | async }}</span>
                    </p> -->
                </div>
            </div>
        </div>

        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <mat-table
                class="clients-table"
                #table
                [dataSource]="dataSource" matSort
                [trackBy]="trackByFn"
                [class.hidden]="(dataSource.loading$ | async) || (dataSource.isEmpty() | async)">
                <ng-container cdkColumnDef="fullName">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                        class="header-client">{{ 'FORM.FIRST_NAME' | translate }} {{ 'FORM.NAME' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
                             class="cursor-pointer" (click)="openClient(client)">
                            <app-avatar [entity]="client"></app-avatar>
                            <mat-icon class="ml-4" [color]="client.lastConnection ? 'accent' : 'primary'"
                                      [matTooltip]="client.lastConnection ? ('SHARED.APP_INSTALL' | translate) + ' : ' + (client.firstConnection | dateLocale:'short') : ('SHARED.APP_UNINSTALL' | translate)">
                                {{ client.lastConnection ? 'smartphone' : 'phonelink_erase' }}
                            </mat-icon>
                            <app-connector-icon [entity]="client"></app-connector-icon>
                            <p class="text-truncate" style="color:#28A8DD;font-weight:500;">{{ client.fullNameLite }}</p>
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="telephonePortable">
                    <mat-header-cell *cdkHeaderCellDef><span
                            class="header-client">{{ 'FORM.PHONE_MOBILE' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <p class="text-truncate">{{ client.telephonePortable | phone | emptyField }}</p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="nextRdv">
                    <mat-header-cell *cdkHeaderCellDef><span
                            class="header-client">{{ 'CALENDAR.NEXT_DATE' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <mat-icon *ngIf="!client.dateProchainRendezVous; else prochainRDV">clear</mat-icon>
                        <ng-template #prochainRDV>
                            <p class="text-truncate">
                                {{ client.dateProchainRendezVous | dateLocale:'medium' }}
                            </p>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="lastRdv">
                    <mat-header-cell *cdkHeaderCellDef><span class="header-client">{{ 'CALENDAR.LAST_DATE' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <mat-icon *ngIf="!client.dateDernierRendezVous; else dernierRDV">clear</mat-icon>
                        <ng-template #dernierRDV>
                            <p class="text-truncate" *ngIf="client.dateDernierRendezVous">
                                {{ client.dateDernierRendezVous | dateLocale:'mediumDate' }}
                            </p>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="entiteGeographique">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span class="header-client">{{ 'ENTITE_GEO_DIALOG.CLINIC' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <p class="text-truncate">
                            {{ client.entiteGeographique?.nom | emptyField }}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="lastConnection">
                    <mat-header-cell *cdkHeaderCellDef mat-sort-header><span
                            class="header-client">{{ 'SHARED.LAST_CONNECTION' | translate }}</span></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <mat-icon *ngIf="!client.lastConnection; else lastConnecion">clear</mat-icon>
                        <ng-template #lastConnecion>
                            <p class="text-truncate">{{ client.lastConnection | dateLocale:'medium' }}</p>
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="nbrAnimaux">
                    <mat-header-cell *cdkHeaderCellDef fxLayout="row" fxLayoutAlign="center center">
                        <span class="header-client">{{ 'PET.ANIMALS' | translate }}</span>
                    </mat-header-cell>
                    <mat-cell *cdkCellDef="let client" fxLayout="row" fxLayoutAlign="center center">
                        <p>
                            <button mat-button color="accent" (click)="showAnimaux(client)" [disabled]="expandedElement == client"
                                    *ngIf="client.animaux?.length > 0; else emptyAnimals">
                                {{ 'PET.SEE' | translate }} ({{ client.animaux | count }})
                            </button>
                            <ng-template #emptyAnimals>
                                <button mat-raised-button color="primary" class="white-fg" (click)="addAnimal(client)">
                                    <mat-icon>add</mat-icon> {{ 'PET.NEW' | translate }}
                                </button>
                            </ng-template>
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="actions">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let client">
                        <p>
                            <mat-menu #actionMenu="matMenu">
                                <button mat-menu-item (click)="openClient(client)">
                                    <mat-icon>info</mat-icon>
                                    <span>{{ 'SHARED.DETAILS' | translate }}</span>
                                </button>
                                <button *ngIf="!client.lastConnection" mat-menu-item (click)="resendNotificationAccountCreated(client)">
                                    <mat-icon>sms_failed</mat-icon>
                                    <span>{{ 'CLIENT.RESEND_NOTIFICATION_ACCOUNT_CREATED.ACTION' | translate }}</span>
                                </button>
                                <!--<button mat-menu-item (click)="removeClient(client)">-->
                                <!--<mat-icon>delete</mat-icon>-->
                                <!--<span>Supprimer</span>-->
                                <!--</button>-->
                            </mat-menu>
                            <button mat-button color="secondary-text"
                                    [matMenuTriggerFor]="actionMenu"
                                    [matTooltip]="'SHARED.ACTIONS' | translate"
                                    class="more-button">
                                <mat-icon>more_horiz</mat-icon>
                            </button>
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container cdkColumnDef="expandedAnimaux">
                    <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
                    <mat-cell *cdkCellDef="let expandedDetailRow">
                        <app-animaux
                                class="w-100-p"
                                [client]="expandedDetailRow.client">
                        </app-animaux>
                    </mat-cell>
                </ng-container>

                <mat-header-row *cdkHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *cdkRowDef="let row; columns: displayedColumns;" class="expandable"
                         [class.expanded]="row == expandedElement"></mat-row>

                <mat-row *cdkRowDef="let row; columns: ['expandedAnimaux']; when: isExpansionDetailRow;"
                         class="collapsable"></mat-row>

            </mat-table>

            <div *ngIf="dataSource.loading$ | async; else loaded" fxLayout="column" fxLayoutAlign="center center">
                <app-loading></app-loading>
            </div>

            <ng-template #loaded>
                <div *ngIf="dataSource.isEmpty() | async" class="empty-state" fxLayout="column"
                     fxLayoutAlign="center center" fxLayoutGap="10px">

                    <img src="/assets/images/empty-state-client.svg"/>

                    <span class="app-title my-16" *fuseIfOnDom>{{ 'CLIENT.ANY' | translate }}</span>

                    <button mat-raised-button color="primary" (click)="openClient()">
                        <mat-icon>add</mat-icon>
                        {{ 'CLIENT.ADD' | translate }}
                    </button>
                </div>
            </ng-template>

            <mat-paginator #paginator [fxHide]="dataSource.isEmpty() | async"
                           [length]="dataSource?.totalCount$ | async"
                           [pageIndex]="0"
                           [pageSize]="20"
                           [pageSizeOptions]="[5, 10, 20, 50, 100]">
            </mat-paginator>

        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

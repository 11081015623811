import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseNavigationModule } from '../../../../@fuse/components';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared.module';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports: [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,

        FuseSharedModule,
        FuseNavigationModule,
        RouterModule,
        SharedModule
    ],
    exports: [
        NavbarComponent
    ]
})
export class NavbarModule {}

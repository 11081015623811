import { NgModule } from '@angular/core';
import { AnimalFichiersComponent } from './animal-fichiers.component';
import { AuthGuard } from 'app/guards/auth.guard';
import { RouterModule } from '@angular/router';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { SharedModule } from '../../../shared.module';
import { ListFichierModule } from 'app/main/shared/list-fichier/list-fichier.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes = [
    {
        path: 'animal/:animalId/files',
        component: AnimalFichiersComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'animal/:animalId',
        redirectTo: '/animal/:animalId/files',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),

        SharedModule,
        ViewSharedModule,
        ListFichierModule
    ],
    declarations: [AnimalFichiersComponent],
    exports: [AnimalFichiersComponent]
})
export class AnimalFichiersModule {}

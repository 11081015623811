<form [formGroup]="form" fxLayout="column" fxLayoutGap="16px">

    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="16px">
        <mat-form-field appearance="outline" fxFlex="25%">
            <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.DATE_PLACEHOLDER' | translate }}</mat-label>
            <input matInput [owlDateTimeTrigger]="datePicker" [owlDateTime]="datePicker"
                   [placeholder]="'DATE.DATE' | translate" readonly (click)="datePicker.open()"
                   required (focus)="datePicker.open()" formControlName="date">
            <mat-icon matSuffix [owlDateTimeTrigger]="datePicker">today</mat-icon>
            <owl-date-time #datePicker [stepMinute]="5" [firstDayOfWeek]="null | firstDayWeek"
                           [hour12Timer]="null | twelveHour"></owl-date-time>
            <mat-error *ngIf="form.get('date').hasError('required')">
                {{ 'SHARED.REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <div fxLayout="column" fxLayoutAlign="start start" fxFlex="calc(75%-16px)">
            <mat-form-field appearance="outline" class="w-100-p">
                <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_PLACEHOLDER' | translate }}
                </mat-label>
                <textarea matInput formControlName="message"
                          [placeholder]="'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_PLACEHOLDER' | translate"
                          required rows="4">
                            </textarea>
                <mat-error *ngIf="form.get('message').hasError('required')">
                    {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.MESSAGE_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <div fxLayout="row wrap" fxLayoutAlign="space-between top" class="w-100-p">
                <mat-checkbox formControlName="reopenChatIfClosed" class="mb-16">
                    {{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.REOPEN_IF_CLOSED_PLACEHOLDER' | translate }}
                </mat-checkbox>
                <mat-form-field appearance="outline" [class.hidden]="!form.get('reopenChatIfClosed').value">
                    <mat-label>{{ 'CHAT.DIALOG_UPDATE_DELAYED_MESSAGE.NUMBER_DAYS_OPEN_IF_CLOSED_PLACEHOLDER' | translate }}</mat-label>
                    <input matInput type="number" min="1" step="1" formControlName="numberDaysToOpenIfClosed"
                           [style.textAlign]="'right'">
                    <span matSuffix>{{ 'SHARED.DAYS' | translate }}</span>
                </mat-form-field>
            </div>
        </div>
    </div>

</form>

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '../config.service';
import { WebsocketMessage } from '../../models/websockets/websocket-message';
import { filter, map, tap } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { RdvWaitingRoomEnter } from '../../models/websockets/rdv-waiting-room-enter';
import { RdvWaitingRoomList } from '../../models/websockets/rdv-waiting-room-list';
import { RdvWaitingRoomExit } from '../../models/websockets/rdv-waiting-room-exit';
import { RdvTestVisio } from 'app/models/websockets/rdv-test-visio';
import { AuthService } from './auth.service';
import { Connection } from '../../models/websockets/connection';
import { environment } from '../../../environments/environment';
import { EntiteJuridiqueService } from './entite-juridique.service';

@Injectable({
    providedIn: 'root'
})
export class WebSocketService implements OnDestroy {
    private static PING_INTERVAL = 20_000;
    private static CONNECTION_RETRY_TIMEOUT = 5000;

    private _url: string;
    private _ws: WebSocket;
    private _messages: Subject<MessageEvent> = new Subject<MessageEvent>();
    private _pingInterval;
    private _reconnectionTimeout;
    private _lastConnection: Date;
    private _connected = false;

    public isConnected(): boolean {
        return this._connected && !!this._ws && !this._reconnectionTimeout;
    }

    public get messages(): Observable<WebsocketMessage> {
        return this._messages.pipe(
            filter(wsm => wsm.data !== 'pong'),
            map(wsm => this.parse(wsm)),
            tap(data => {
                if (environment.dev || environment.hmr) {
                    console.log(data);
                }
            })
        );
    }

    constructor(
        private config: ConfigService,
        private authService: AuthService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {
        this.config.getWaitingRoomEnabled().subscribe(waitingRoomEnabled => {
            if (waitingRoomEnabled && !entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.isDisabled) {
                this._url = this.config.baseUrl.replace(/^(http(s?):\/\/|\.)/, 'wss://').replace(/\/$/, '') + '/ws?access_token=' + this.authService.token;
                // this._url = 'ws://app.linkyvet.local:8080';
                this.connect();
            }
        });
    }

    ngOnDestroy(): void {
        if (this._reconnectionTimeout) {
            clearTimeout(this._reconnectionTimeout);
            this._reconnectionTimeout = null;
        }

        if (this._pingInterval) {
            clearInterval(this._pingInterval);
            this._pingInterval = null;
        }

        this._ws?.close(1000, null);
        this._ws = null;
    }

    private connect() {
        if (this._ws) {
            console.log('Websocket already connected, reconnection');
            this._ws.close();
            this._ws = null;
        }

        this._ws = new WebSocket(this._url);

        this._ws.addEventListener('open', this.onOpen);
        this._ws.addEventListener('error', this.onError);
        this._ws.addEventListener('close', this.onClose);
    }

    private onMessage = this._messages.next.bind(this._messages);

    private onOpen = () => {
        this._connected = true;
        if (this._reconnectionTimeout) {
            clearTimeout(this._reconnectionTimeout);
            this._reconnectionTimeout = null;
        }

        this._lastConnection = new Date();
        if (environment.dev || environment.hmr) {
            console.log('Connected to websocket !');
        }

        this._ws.addEventListener('message', this.onMessage);

        this.send(new Connection(this.authService.token));

        this._pingInterval = setInterval(() => {
            if (this._ws && this._ws.readyState === WebSocket.OPEN) {
                this._ws.send('ping');
            }
        }, WebSocketService.PING_INTERVAL);
    };

    private onError = (evt: any) => {
        console.error('Error from websocket :', evt);
        this.error(evt);
    };

    private onClose = (evt: any) => {
        this.error(evt);
    };

    private error(evt: any): void {
        this._connected = false;

        if (environment.dev || environment.hmr) {
            console.log('Disconnected from websocket !', evt, Date.now() - this._lastConnection?.getTime());
        }

        if (this._ws) {
            this._ws.removeEventListener('message', this.onMessage);
            this._ws.removeEventListener('open', this.onOpen);
            this._ws.removeEventListener('error', this.onError);
            this._ws.removeEventListener('close', this.onClose);
            if (this._ws.readyState === WebSocket.OPEN) {
                this._ws?.close();
            }
        }

        this._ws = null;

        if (this._reconnectionTimeout) {
            clearTimeout(this._reconnectionTimeout);
            this._reconnectionTimeout = null;
        }

        if (this._pingInterval) {
            clearInterval(this._pingInterval);
            this._pingInterval = null;
        }

        this._reconnectionTimeout = setTimeout(() => {
            this.connect();
        }, WebSocketService.CONNECTION_RETRY_TIMEOUT);
    }

    private send(data: WebsocketMessage) {
        if (this._ws && this._ws.readyState === WebSocket.OPEN) {
            this._ws.send(ObjectMapper.serialize(data).toString());
        }
    }

    private parse(message: MessageEvent): WebsocketMessage {
        const wsm = JSON.parse(message.data);
        switch (wsm?.type) {
            case 'rdv.waiting-room.list':
                return ObjectMapper.deserialize(RdvWaitingRoomList, wsm);
            case 'rdv.waiting-room.enter':
                return ObjectMapper.deserialize(RdvWaitingRoomEnter, wsm);
            case 'rdv.waiting-room.exit':
                return ObjectMapper.deserialize(RdvWaitingRoomExit, wsm);
            case 'rdv.test-visio':
                return ObjectMapper.deserialize(RdvTestVisio, wsm);
            default:
                return ObjectMapper.deserialize(WebsocketMessage, wsm);
        }
    }
}

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { RendezVous } from '../../../../../models/rendez-vous/rendez-vous';
import { TemplateService } from '../../../../../services/api/template.service';
import { Note } from '../../../../../models/rendez-vous/note';
import { ReportService } from '../../../../../services/api/report.service';
import { Template } from '../../../../../models/rendez-vous/template';
import { Report, ReportTarget } from '../../../../../models/rendez-vous/report';
import { Observable, Subscription } from 'rxjs';
import { DocumentStatus } from '../../../../../models/rendez-vous/text-document';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../../../../services/config.service';
import { Fichier } from '../../../../../models/fichier';
import {
    FichiersAnimalDialogComponent,
    FichiersAnimalDialogInterface
} from '../../../../shared/fichiers-animal-dialog/fichiers-animal-dialog.component';
import { map } from 'rxjs/operators';
import { AnimalService } from '../../../../../services/api/animal.service';
import { ReportPostInterface } from '../../../../../models/interfaces/report-post.interface';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-report-editor',
    templateUrl: './report-editor.component.html',
    styleUrls: ['./report-editor.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ReportEditorComponent implements OnInit, OnDestroy {
    @Input()
    report: Report;

    @Input()
    rdv: RendezVous;

    @Input()
    note: Note;

    @Input()
    disabledEmailSendingButton: boolean;

    @Output()
    save: EventEmitter<Report>;

    @Output()
    sendEmail: EventEmitter<ReportPostInterface>;

    @ViewChild('nameInput', { static: false }) nameInput: ElementRef;

    reportTarget = ReportTarget;
    reportStatus = DocumentStatus;

    tinyMCEInit: any;

    private templateSelectionUpdateNotifierSubscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private dialog: MatDialog,
        private templateService: TemplateService,
        private reportService: ReportService,
        private animalService: AnimalService,
        private configService: ConfigService,
        private snackbar: MatSnackBar
    ) {
        this.save = new EventEmitter<Report>();
        this.sendEmail = new EventEmitter<ReportPostInterface>();
    }

    ngOnInit(): void {
        this.templateSelectionUpdateNotifierSubscription = this.templateService.templateSelectionUpdateNotifier.subscribe(template => {
            this.applyTemplate(template);
        });

        this.tinyMCEInit = {
            height: 700,
            menubar: false,
            branding: false,
            elementpath: false,
            resize: false,
            plugins: [
                'advlist autolink lists link image charmap print',
                'preview anchor searchreplace visualblocks code',
                'fullscreen insertdatetime media table paste'
            ],
            toolbar: 'undo redo | copy cut paste | formatselect | bold italic underline | fontselect fontsizeselect forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | myCustomImagePickerButton editimage | link table charmap',
            toolbar_mode: 'sliding',
            // TODO: i18n
            language: 'fr_FR',
            language_url: '../../../../../assets/i18n/tinyMCE/fr_FR.js',
            content_css: '../../../../../assets/report_styles/default-template-style.css',
            mobile: {
                toolbar_mode: 'sliding'
            },

            // see how to add custom buttons in TinyMCE editor at https://www.tiny.cloud/docs/ui-components/toolbarbuttons/
            setup: editor => {
                editor.ui.registry.addButton('myCustomImagePickerButton', {
                    icon: 'gallery',
                    // TODO: i18n
                    tooltip: 'Insérer une image',
                    onAction: () => {
                        this.openFichiersModal().subscribe(fichier => {
                            if (fichier?.isImage()) {
                                const baseUrl: string = this.configService.baseUrl;
                                const imageTag = `<img src="${baseUrl + fichier.url}" alt="${fichier.description}" width="300px"/>`;
                                editor.insertContent(imageTag);
                            }
                        });
                    }
                });
            }
        };
    }

    ngOnDestroy(): void {
        this.templateSelectionUpdateNotifierSubscription.unsubscribe();
    }

    applyTemplate(template: Template): void {
        this.report.template = template;
        this.reportService.applyTemplate(this.report, this.note, this.rdv).subscribe(
            content => {
                this.report.content = content;
                this.report.status = DocumentStatus.Modified;
            }
        );
    }

    saveReport(): void {
        const reportName = this.report.name?.trim();
        if (this.report.name === null || reportName === '') {
            this.snackbar.open(this.translateService.instant('REPORTS.EDITORS.REPORT.NAME_REMINDER'), null, { duration: 2000 });
            this.nameInput.nativeElement.focus();
            return;
        }

        this.save.emit(this.report);
    }

    updateReport(): void {
        this.report.status = DocumentStatus.Modified;
    }

    generatePDF(): void {
        const reportName = this.report.name?.trim();
        if (this.report.name === null || reportName === '') {
            this.snackbar.open(this.translateService.instant('REPORTS.EDITORS.REPORT.NAME_REMINDER'), null, { duration: 2000 });
            this.nameInput.nativeElement.focus();
            return;
        }

        this.reportService.getPdf(this.report.hasPost());
    }

    send(): void {
        const reportName = this.report.name?.trim();
        if (this.report.name === null || reportName === '') {
            this.snackbar.open(this.translateService.instant('REPORTS.EDITORS.REPORT.NAME_REMINDER'), null, { duration: 2000 });
            this.nameInput.nativeElement.focus();
            return;
        }

        this.sendEmail.emit(this.report.hasPost());
    }

    // Même file picker que pour les Chat
    openFichiersModal(): Observable<Fichier> {
        const data: FichiersAnimalDialogInterface = {
            reqFichiers: this.animalService.getFichiers(this.rdv.animal.id).pipe(map(f => f.data)),
            animal: this.rdv.animal
        };
        return this.dialog.open(FichiersAnimalDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '60vw',
            minHeight: '60vh',
            disableClose: true,
            data
        }).afterClosed().pipe(map((fichier: Fichier) => fichier));
    }
}

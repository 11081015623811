import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'timeDuration'
})
export class TimeDurationPipe implements PipeTransform {
    constructor(private translateService: TranslateService) {}

    transform(seconds: number, withSecond = false): string {
        if (Number.isNaN(seconds)) {
            return '';
        }

        const min: number = Number.parseInt(`${Math.floor(seconds / 60)}`.padStart(2, '0'), 10);
        let sec = 0;
        if (withSecond) {
            sec = seconds % 60;
        }

        return [
            min,
            (this.translateService.instant('SHARED.MINUTE') as string).slice(0, 3),
            withSecond && sec > 0 ? `${sec} ${(this.translateService.instant('SHARED.SECOND') as string).slice(0, 1)}` : null
        ].map(a => a).join(' ');
    }
}

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { Veterinaire } from '../../../../models/utilisateurs/veterinaire';
import { VeterinairePostInterface } from '../../../../models/interfaces/post/utilisateurs/veterinaire-post.interface';
import { Utilisateur } from 'app/models/utilisateurs/utilisateur';

@Component({
    selector: 'app-veterinaire-dialog',
    templateUrl: './veterinaire-dialog.component.html',
    styleUrls: ['./veterinaire-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VeterinaireDialogComponent implements OnInit, OnDestroy {
    userData$ = new BehaviorSubject<VeterinairePostInterface>(null);

    veterinaire$: BehaviorSubject<Utilisateur>;
    veterinaire: Veterinaire;
    loading$ = new BehaviorSubject<boolean>(false);

    disabled = true;

    hasChanged = false;

    private subscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<VeterinaireDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: VeterinaireDialogInterface
    ) {
        this.veterinaire$ = new BehaviorSubject(data.veterinaire);
        this.veterinaire$.pipe(
            filter(u => Boolean(u))
        ).subscribe((veterinaire: Veterinaire) => {
            this.veterinaire = veterinaire;
            this.userData$.next(veterinaire.hasPost());
        });

        this.disabled = data.disabled;
    }

    ngOnInit(): void {
        if (this.veterinaire?.id) {
            this.loading$.next(true);
            this.subscription = this.data.utilisateurService.getById(this.veterinaire.id).subscribe({
                next: (veterinaire: Veterinaire) => {
                    this.veterinaire$.next(veterinaire);
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close(this.veterinaire)
            });
        }
    }

    close(): void {
        this.dialogRef.close(this.hasChanged ? this.veterinaire$.value : false);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}

export interface VeterinaireDialogInterface {
    veterinaire: Veterinaire;
    utilisateurService: UtilisateurService;
    disabled: boolean;
}

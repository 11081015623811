import { CacheKey, Deserializer, Serializer } from 'json-object-mapper';
import { StatusRendezVousEnum } from '../models/rendez-vous/rendez-vous';

@CacheKey('StatusRendezVousMapper')
export class StatusRendezVousMapper implements Deserializer, Serializer {
    deserialize = (value: string): StatusRendezVousEnum => {
        return StatusRendezVousEnum[value] as StatusRendezVousEnum;
    };

    serialize = (value: StatusRendezVousEnum): string => {
        const obj = Object.entries(StatusRendezVousEnum).find(o => o[1] === value)[0];
        return obj?.length ? obj[0] : null;
    };
}

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LinkyvetNotification } from 'app/services/api/notifications.service';

@Component({
    selector: 'app-notification-dialog',
    templateUrl: 'notification-dialog.component.html',
    styleUrls: ['notification-dialog.component.scss']
})
export class NotificationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<NotificationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: NotificationDialogData
    ) {}

    openNotification(): void {
        this.closeModal();
        window.open(this.data.notification.link, '_blank');
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}

export interface NotificationDialogData {
    notification: LinkyvetNotification;
}

import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OnboardingService } from '../../../../../services/api/onboarding.service';

@Component({
    selector: 'app-input-vat',
    templateUrl: './input-vat.component.html',
    styleUrls: ['./input-vat.component.scss']
})
export class InputVatComponent {
    @Input() controlVat: AbstractControl;
    @Input() country: string;
    @Input() required = false;
    @Input() showValidateButton = true;

    show = false;
    vatLoading = false;

    private shownCountry = [
        'DE',
        'AT',
        'BE',
        'BG',
        'CY',
        'HR',
        'DK',
        'ES',
        'EE',
        'FI',
        'GR',
        'HU',
        'IE',
        'IT',
        'LV',
        'LT',
        'LU',
        'MT',
        'NL',
        'PL',
        'PT',
        'CZ',
        'RO',
        'GB',
        'SK',
        'SI',
        'SE'
    ];

    constructor(private onboardingService: OnboardingService) {
        this.show = this.shownCountry.includes(this.country);
    }

    resetVat(): void {
        this.controlVat.setValue(null);
        this.controlVat.enable();
    }

    checkVat(): void {
        const value = this.controlVat.value;
        if (!value || value && value.trim() === '') {
            return;
        }

        this.controlVat.disable();
        this.vatLoading = true;

        this.onboardingService.checkVatNumber(value, this.country).subscribe({
            next: res => {
                if (res) {
                    this.controlVat.setValue(value);
                    this.vatLoading = false;
                } else {
                    this.controlVat.setValue(null);
                    this.vatLoading = false;
                    this.controlVat.enable();
                }
            },
            error: () => {
                this.vatLoading = false;
                this.controlVat.enable();
            }
        });
    }
}

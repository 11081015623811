<div class="mat-headline">{{ 'REPORTS.EDITORS.TEMPLATE.MAIN_TITLE' | translate }}</div>
<div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center stretch" fxLayoutAlign.gt-xs="space-between end" fxLayoutGap="8px" class="my-12">
    <mat-form-field appearance="outline" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(40%-8px)" class="mat-form-field-for-template">
        <mat-label>{{ 'REPORTS.EDITORS.TEMPLATE.TEMPLATE_LABEL' | translate }}</mat-label>
        <mat-select #template (selectionChange)="updateSelectedTemplate(template.value)" [value]="selectedTemplate.id" >
            <mat-option *ngFor="let t of templates" [value]="t.id">
                {{t.name}}
                <span *ngIf="t.isDefault">({{ 'REPORTS.EDITORS.TEMPLATE.DEFAULT_TAG' | translate | lowercase }})</span>
                <span *ngIf="!t.isDefault">({{ 'REPORTS.EDITORS.TEMPLATE.CUSTOM_TAG' | translate | lowercase }})</span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(20%-8px)"  [disabled]="youSeeWhatIsApplied()" (click)="applyTemplate()">
        <mat-icon>autorenew</mat-icon>
        {{ 'REPORTS.EDITORS.TEMPLATE.APPLY_BUTTON' |translate }}
    </button>
    <button mat-raised-button color="primary" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(20%-8px)"  [disabled]="this.selectedTemplate.isDefault" (click)="delete()">
        <mat-icon>delete</mat-icon>
        {{ 'REPORTS.EDITORS.TEMPLATE.DELETE_BUTTON' |translate }}
    </button>
    <button mat-raised-button color="primary" fxFlex="calc(100%-8px)" fxFlex.gt-xs="calc(20%-8px)"  [disabled]="this.selectedTemplate.status !== this.templateStatus.Modified" (click)="save()">
        <mat-icon>save</mat-icon>
        {{ 'REPORTS.EDITORS.TEMPLATE.SAVE_BUTTON' |translate }}
    </button>
</div>

<div *ngIf="isTemplateHasBeenChosen">
    <!--TODO Affichage du statut du template : est-il modifié ? enregistré ?-->
    <div *ngIf="youSeeWhatIsApplied()" class="message-box success mb-12">{{ 'REPORTS.EDITORS.TEMPLATE.CURRENT_PROMPT' | translate }}</div>
    <div *ngIf="!youSeeWhatIsApplied()" class="message-box error mb-12">
        {{ 'REPORTS.EDITORS.TEMPLATE.APPLY_NEW_INFO' | translate }}
        <u>{{ 'REPORTS.EDITORS.TEMPLATE.APPLY_NEW_WARNING' | translate }}</u>
    </div>
</div>

<editor
    apiKey="p35ts2mlnis3iddvqpc4mt8cxrranyu2a6rngrkqa8wuisqn"
    outputFormat="html"
    [init]="tinyMCEInit"
    [disabled] = "false"
    [inline] = "false"
    [(ngModel)]="selectedTemplate.content"
    (onChange)="templateModified()"
></editor>

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { AuthGuard } from '../../../guards/auth.guard';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { AbonnementComponent } from './abonnement.component';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { ManagedGuard } from 'app/guards/managed.guard';

const routes = [
    {
        path: 'subscription',
        component: AbonnementComponent,
        canActivate: [AuthGuard, ManagedGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'abonnement',
        redirectTo: '/subscription',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        AbonnementComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,

        SharedModule,
        ViewSharedModule
    ]
})

export class AbonnementModule {}

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NewsSegment } from 'app/models/news/news-segment';
import { NewsSegmentsService } from 'app/services/api/news-segments.service';

@Component({
    selector: 'app-news-segment-dialog',
    templateUrl: './news-segment-dialog.component.html',
    styleUrls: ['./news-segment-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class NewsSegmentDialogComponent implements OnInit, OnDestroy {
    data$ = new BehaviorSubject<NewsSegment>(null);
    loading$ = new BehaviorSubject<boolean>(true);

    private subscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<NewsSegmentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: NewsSegmentDialogInterface,
        private readonly newsSegmentsService: NewsSegmentsService
    ) {}

    ngOnInit(): void {
        if (this.data.segment?.id) {
            this.subscription = this.newsSegmentsService.get(this.data.segment.id).subscribe({
                next: segment => {
                    if (this.data.duplicate) {
                        segment.id = null;
                    }

                    this.data$.next(segment);
                    this.loading$.next(false);
                },
                error: error => {
                    console.error(error);
                    this.loading$.next(false);
                }
            });
        } else {
            this.loading$.next(false);
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    close(hasChanged = false): void {
        this.dialogRef.close(hasChanged);
    }
}

export interface NewsSegmentDialogInterface {
    segment: NewsSegment;
    duplicate: boolean;
}

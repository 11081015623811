import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { InputPhoneComponent } from '../../shared/form/inputs/input-phone/input-phone.component';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
    selector: 'app-step-user',
    templateUrl: './step-user.component.html',
    styleUrls: ['./step-user.component.scss']
})
export class StepUserComponent implements OnInit, OnDestroy {
    pwdLevel: number = null;
    hidePassword = true;

    @Input() form: FormGroup;
    @Input() entiteJuridique: BehaviorSubject<EntiteJuridique>;
    @ViewChild('inputPhone', { static: true }) inputPhone: InputPhoneComponent;

    private subcriptions: Subscription[] = [];

    ngOnInit(): void {
        this.form.get('password').setValidators([Validators.required, this.gradePasswordValidator()]);
        this.subcriptions.push(
            this.form.get('password').valueChanges.subscribe(pwd => {
                this.pwdLevel = this.gradePassword(pwd);
            })
        );
    }

    ngOnDestroy(): void {
        if (this.subcriptions) {
            this.subcriptions.forEach((subscription: Subscription) => {
                subscription.unsubscribe();
            });
        }
    }

    private gradePasswordValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const valid = this.gradePassword(control.value) > 30;
            return valid ? null : { notStrongEnough: { value: control.value } };
        };
    }

    private gradePassword(pwd: string): number {
        let score = 0;
        if (!pwd) {
            return score;
        }

        // award every unique letter until 5 repetitions
        //  var letters = new Object();
        const letters: { [key: string]: number } = {};
        [...pwd].forEach(letter => {
            const letterScore = (letters[letter] || 0) + 1;
            letters[letter] = letterScore;
            score += 5 / letterScore;
        });

        // bonus points for mixing it up
        const variations = {
            digits: /\d/.test(pwd),
            lower: /[a-z]/.test(pwd),
            upper: /[A-Z]/.test(pwd),
            nonWords: /\W/.test(pwd)
        };

        let variationCount = 0;
        Object.keys(variations).forEach(check => {
            variationCount += variations[check] === true ? 1 : 0;
        });
        score += (variationCount - 1) * 10;

        return Number.parseInt(score.toString(), 10);
    }
}

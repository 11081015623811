<mat-form-field appearance="outline" fxFlex="1 0 0"
                *ngIf="(connecteurService.isConnecteurEnabledForUtilisateurConnected | async)">
    <mat-label>{{ 'FORM.CONNECTOR_ID' | translate:{connecteur: (connecteurService.currentConnecteurForUtilisateurConnected | async)?.name} }}</mat-label>
    <input matInput [formControl]="control | asFormControl"
           [placeholder]="'FORM.CONNECTOR_ID' | translate:{ connecteur: (connecteurService.currentConnecteurForUtilisateurConnected | async)?.name }"
           [required]="required"/>
    <mat-hint class="warn-fg"
              *ngIf="(connecteurService.isConnecteurEnabledForUtilisateurConnected | async) && !control.value">
        {{ (required ? 'FORM.FIELD_REQUIRED' : 'FORM.NOT_LINKED') | translate:{connecteur: (connecteurService.currentConnecteurForUtilisateurConnected | async)?.name} }}
    </mat-hint>
</mat-form-field>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChatScenarioMessage, ChatShortcut } from '../../../../../models/pro/entite-juridique';
import { EntiteJuridiqueService } from '../../../../../services/api/entite-juridique.service';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioMessageDialogComponent } from '../scenario-message-dialog/scenario-message-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-scenari-datatable',
  templateUrl: './scenari-datatable.component.html',
  styleUrls: ['./scenari-datatable.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
        ])
    ]
})
export class ScenariDatatableComponent implements OnInit, OnChanges {
    dataSource = new MatTableDataSource<ChatScenarioMessageItem>();
    columnsToDisplay = ['nbDaysAfter', 'message', 'numberDaysToOpenIfClosed', 'actions'];
    expandedChatScenarioMessage: ChatScenarioMessage | null;
    previewShortcut: ChatShortcut;
    previewShortcutSelected: ChatShortcut;

    @Input() messages: ChatScenarioMessage[];
    @Input() shortcuts: ChatShortcut[];
    @Output() updateMessage = new EventEmitter<ChatScenarioMessage[]>();
    @Output() removeMessage = new EventEmitter<ChatScenarioMessage[]>();
    @Output() cancel = new EventEmitter();

    constructor(private entiteJuridiqueService: EntiteJuridiqueService, public dialog: MatDialog) {}

    ngOnInit(): void {
        this.sortMessagesByDelay(this.messages);
        this.dataSource.data = this.getData(this.messages);
    }

    private getData(data: ChatScenarioMessage[]): ChatScenarioMessageItem[] {
        const temp: ChatScenarioMessageItem[] = [];
        data.forEach((m: ChatScenarioMessage) => {
            const index = temp.length;
            temp.push({
                index: index,
                chatScenarioMessage: m
            });
        });
        return temp;
    }

    sortMessagesByDelay(messages: ChatScenarioMessage[]): void {
        messages.sort((a: ChatScenarioMessage, b: ChatScenarioMessage) => {
            if (a.nbDaysAfter === b.nbDaysAfter) {
                return 0;
            }

            return a.nbDaysAfter < b.nbDaysAfter ? -1 : 1;
        });
    }

    refreshPreviewSelected(shortcutName: string): void {
        this.previewShortcutSelected = this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.chatShortcuts.find(c => c.shortcut === shortcutName);
    }

    editMessage(chatScenarioMessage: ChatScenarioMessage, index: number): void {
        this.dialog.open(ScenarioMessageDialogComponent, {
            data: {
                message: chatScenarioMessage,
                shortcuts: this.shortcuts
            },
            panelClass: 'no-padding-dialog',
            width: '800px',
            disableClose: true
        }).afterClosed().subscribe((m: ChatScenarioMessage) => {
            if (m === null) {
                this.cancel.emit();
            } else {
                this.updateMessage.emit(this.dataSource.data.map(elmt => elmt.index === index ? m : elmt.chatScenarioMessage));
            }
        });
    }

    remove(index: number): void {
        this.removeMessage.emit(this.dataSource.data
            .filter(elmt => elmt.index !== index)
            .map(elmt => elmt.chatScenarioMessage));
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.sortMessagesByDelay(this.messages);
        this.dataSource.data = this.getData(changes.messages.currentValue);
    }
}

export interface ChatScenarioMessageItem{
    index: number;
    chatScenarioMessage: ChatScenarioMessage;
}

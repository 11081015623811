import { Component, OnDestroy, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { fuseAnimations } from '../../../@fuse/animations';
import { FuseConfigService } from '../../../@fuse/services/config.service';
import { MatStepper } from '@angular/material/stepper';
import { FormControl, FormGroup, Validators, FormArray, AbstractControl, ValidatorFn } from '@angular/forms';
import { OnboardingService, OnboardingClientSession, OnboardingClientStartOtpResult, OnboardingClientStartOtp, OnboardingClientValidateOtp, OnboardingClientValidateOtpResult } from '../../services/api/onboarding.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { add } from 'date-fns';
import { environment } from 'environments/environment';
import { Subscription, Observable, of, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { tap, catchError, map, mergeMap } from 'rxjs/operators';
import { StepUserComponent } from './step-user/step-user.component';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth, RecaptchaVerifier } from '@angular/fire/auth';

@Component({
    selector: 'onboarding-client',
    templateUrl: './onboarding-client.component.html',
    styleUrls: ['./onboarding-client.component.scss'],
    animations: fuseAnimations
})
export class OnboardingClientComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('stepper', { static: true }) stepper: MatStepper;
    @ViewChild('stepUser', { static: true }) stepUser: StepUserComponent;

    isLoading = true;
    entiteJuridique: BehaviorSubject<EntiteJuridique> = new BehaviorSubject<EntiteJuridique>(null);

    public get location(): string {
        try {
            return this.entiteJuridique.value.entitesGeographiques.map(eg => {
                return eg.ville + ' ' + eg.codePostal + ' ' + eg.pays;
            })
                .filter((elem, index, self) => {
                    return index === self.indexOf(elem);
                })
                .join('<br/>');
        } catch {
            return '';
        }
    }

    form: FormGroup;
    formLoading = false;
    skipClinic = false;

    private formSubscription: Subscription;
    private readonly cookieName = 'onboarding_form_client';

    recaptchaToken = null;
    private recaptchaVerifier: RecaptchaVerifier;
    private sessionInfo;

    constructor(
        private route: ActivatedRoute,
        private fuseConfigService: FuseConfigService,
        private cookie: CookieService,
        private onboardingService: OnboardingService,
        private router: Router,
        private afAuth: Auth,
        private ngZone: NgZone,
        private translateService: TranslateService,
        private snackbar: MatSnackBar
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this.form = new FormGroup({
            codeClinique: new FormControl(null, [Validators.required]),
            user: new FormGroup({
                civilite: new FormControl(null, []),
                prenom: new FormControl(null, []),
                nom: new FormControl(null, [Validators.required]),
                mail: new FormControl(null, [Validators.required]),
                telephone: new FormControl(null, [Validators.required]),
                password: new FormControl(null, [Validators.required])
            }),
            animals: new FormArray([], [this.minLengthFormArray(1), this.maxLengthFormArray(10)])
        });
    }

    ngOnInit(): void {
        this.retrieveFormData();

        this.formSubscription = this.form.valueChanges.subscribe(() => {
            this.saveFormData();
        });

        this.route.paramMap.subscribe((params: ParamMap) => {
            const clinic = params.get('clinic');
            if (clinic) {
                this.retrieveClinic(clinic).subscribe(res => {
                    if (res) {
                        this.skipClinic = true;
                    }

                    this.isLoading = false;
                });
            } else {
                this.isLoading = false;
            }
        });
    }

    ngAfterViewInit(): void {
        // this.resetRecaptcha();
    }

    ngOnDestroy(): void {
        this.formSubscription?.unsubscribe();
    }

    onBackHomeClicked(): void {
        void this.router.navigate(['/']);
    }

    onBackClicked(): void {
        if (this.stepper.selectedIndex > 0) {
            if (this.stepper.selectedIndex === (this.skipClinic ? 2 : 3)) {
                this.resetRecaptcha();
            }

            this.stepper.previous();
        }
    }

    onNextClicked(): void {
        if (this.stepper.selectedIndex === (this.skipClinic ? 1 : 2)) {
            return;
        }

        if (!this.skipClinic && this.stepper.selectedIndex === 0) {
            this.retrieveClinic(this.form.get('codeClinique').value).subscribe(res => {
                if (res) {
                    this.stepper.next();
                }
            });
        } else {
            this.stepper.next();
        }
    }

    goToStep(step: number): void {
        this.stepper.selectedIndex = step;
    }

    private retrieveFormData(): void {
        if (!this.cookie.check(this.cookieName)) {
            return;
        }

        const data = JSON.parse(this.cookie.get(this.cookieName));
        if (!data) {
            return;
        }

        setTimeout(() => {
            this.form.patchValue(data);
            if (data.user.telephone) {
                this.stepUser.inputPhone.setNewValue(data.user.telephone);
            }
        });
    }

    private saveFormData(): void {
        const formData = this.form.getRawValue();
        delete formData.codeClinique;
        delete formData.user.password;
        const formDataString = JSON.stringify(formData);
        this.cookie.set(this.cookieName, formDataString, add(Date.now(), { weeks: 1 }), null, null, environment.production, 'Lax');
    }

    resendSms(): Observable<void> {
        return new Observable(observer => {
            this.resetRecaptcha();
            setTimeout(() => {
                const elem = document.querySelector('#sign-in-button');
                const clickEvent = new Event('click');
                elem.dispatchEvent(clickEvent);

                observer.next();
                observer.complete();
            });
        });
    }

    private resetRecaptcha() {
        if (this.recaptchaVerifier) {
            this.recaptchaToken = null;
            this.sessionInfo = null;
        } else {
            this.afAuth.languageCode = this.translateService.currentLang;

            this.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
                size: 'invisible',
                callback: (recaptcha: string) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    this.recaptchaToken = recaptcha;
                    this.ngZone.run(() => {
                        this.startOtp();
                    });
                }
            }, this.afAuth);

            void this.recaptchaVerifier.render();
        }
    }

    private startOtp() {
        if (!this.form.valid || !this.recaptchaToken) {
            return;
        }

        const phoneNumber = this.form.get(['user', 'telephone']).value;
        this.onboardingService.checkClientPhone(phoneNumber).subscribe({
            next: exist => {
                if (exist) {
                    this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.PHONE_ALREADY_USED'), null, { duration: 3000 });
                    this.resetRecaptcha();
                    this.formLoading = false;
                } else {
                    const data: OnboardingClientStartOtp = {
                        phoneNumber,
                        recaptchaToken: this.recaptchaToken
                    };
                    this.formLoading = true;
                    this.onboardingService.startOtp(data).subscribe({
                        next: (res: OnboardingClientStartOtpResult) => {
                            this.sessionInfo = res.sessionInfo;
                            this.formLoading = false;
                            this.stepper.next();
                        },
                        error: () => {
                            this.resetRecaptcha();
                            this.formLoading = false;
                        }
                    });
                }
            },
            error: () => {
                this.resetRecaptcha();
                this.formLoading = false;
            }
        });
    }

    validateOtp(code: string): Observable<OnboardingClientValidateOtpResult> {
        if (!this.sessionInfo || !code) {
            return;
        }

        const data: OnboardingClientValidateOtp = {
            sessionInfo: this.sessionInfo,
            code
        };
        return this.onboardingService.validateOtp(data).pipe(
            mergeMap((res: OnboardingClientValidateOtpResult) => {
                if (res?.code === 'GRANTED') {
                    return this.sendForm().pipe(
                        map(() => res),
                        catchError(() => of({ code: 'ERROR' } as OnboardingClientValidateOtpResult))
                    );
                }

                return of(res);
            }),
            catchError(() => of({ code: 'ERROR' } as OnboardingClientValidateOtpResult))
        );
    }

    private sendForm(): Observable<void | Error> {
        if (!this.form.valid) {
            return;
        }

        const formData = this.form.value as OnboardingClientSession;
        if (!formData) {
            return;
        }

        this.formLoading = true;
        return this.onboardingService.registerNewClient(formData).pipe(
            tap(() => {
                this.cookie.delete(this.cookieName);
                void this.router.navigate(['onboarding-client-end'], {
                    queryParams: {
                        phone: formData.user.telephone
                    }
                });
            }),
            catchError((err: Error) => {
                this.formLoading = false;
                return of(err);
            })
        );
    }

    public get buttonContinueEnabled(): boolean {
        return this.stepper.selected?.completed ?? false;
    }

    private minLengthFormArray(length: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const valid = (control as FormArray).controls.length >= length;
            return valid ? null : { minLength: { value: control.value } };
        };
    }

    private maxLengthFormArray(length: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const valid = (control as FormArray).controls.length <= length;
            return valid ? null : { maxLength: { value: control.value } };
        };
    }

    private retrieveClinic(codeClinique: string): Observable<boolean> {
        this.formLoading = true;
        return this.onboardingService.checkClinic(codeClinique).pipe(
            tap((ej: EntiteJuridique) => {
                if (ej) {
                    this.entiteJuridique.next(ej);
                    this.form.get('codeClinique').setValue(codeClinique);
                    this.formLoading = false;
                } else {
                    this.form.get('codeClinique').enable();
                    this.formLoading = false;
                }
            }),
            map(ej => Boolean(ej)),
            catchError(() => {
                this.form.get('codeClinique').enable();
                this.formLoading = false;
                return of(false);
            })
        );
    }
}

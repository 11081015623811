<div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="16px">
    <form [formGroup]="form" class="w-100-p" fxLayout="column" (submit)="submit()" autocomplete="off">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
            <mat-form-field appearance="outline" fxFlex="1 0 0">
                <mat-label>{{ 'NEWS.FORM.INPUTS.NAME' | translate }}</mat-label>
                <input matInput formControlName="name" [placeholder]="'NEWS.FORM.INPUTS.NAME' | translate"
                    [readonly]="this.entityData$?.value?.status === 'archived'">
                <mat-error *ngIf="form.get('name')?.hasError('required')">
                    {{ 'NEWS.FORM.ERRORS.NAME_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="1 0 0">
                <mat-label>{{ 'NEWS.FORM.INPUTS.TITLE' | translate }}</mat-label>
                <input matInput formControlName="title" [placeholder]="'NEWS.FORM.INPUTS.TITLE' | translate"
                    [readonly]="this.entityData$?.value?.status === 'archived'">
                <mat-error *ngIf="form.get('title')?.hasError('required')">
                    {{ 'NEWS.FORM.ERRORS.TITLE_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <label class="mb-8">{{ 'NEWS.FORM.INPUTS.TYPES' | translate }}</label>
        <mat-button-toggle-group class="type-selection mb-16" [(ngModel)]="isFullscreen" [ngModelOptions]="{standalone: true}" (change)="deleteImage()">
          <mat-button-toggle [value]="false">{{ 'NEWS.FORM.INPUTS.TYPE_IMAGE_PLUS_TEXT' | translate }}</mat-button-toggle>
          <mat-button-toggle [value]="true">{{ 'NEWS.FORM.INPUTS.TYPE_FULLSCREEN' | translate }}</mat-button-toggle>
        </mat-button-toggle-group>

        <div class="photo-wrapper" [class.disabled]="this.entityData$?.value?.status === 'archived'"
            (click)="openImageDialog()">
            <div class="photo-edit-overlay"
                *ngIf="this.entityData$?.value?.status !== 'archived' && (this.form.get('image')?.value || this.currentImage)">
                <mat-icon>edit</mat-icon>
            </div>
            <div class="photo-edit hover-activated" *ngIf="this.form.get('image')?.value || this.currentImage">
                <img [src]="this.base64Image ? this.base64Image : (this.currentImage?.url ?? (isFullscreen ? 'assets/images/news-no-image-preview-9-16.png' : 'assets/images/news-no-image-preview-16-9.png'))"
                    class="photo-actuelle" />
            </div>
            <div class="photo-add" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                *ngIf="!this.form.get('image')?.value && !this.currentImage">
                <mat-icon *ngIf="this.entityData$?.value?.status !== 'archived'">add</mat-icon>
                <div>{{ (this.entityData$?.value?.status === 'archived' ? 'NEWS.FORM.ERRORS.EMPTY_IMAGE' :'NEWS.FORM.INPUTS.IMAGE') | translate}}</div>
            </div>
            <div class="photo-delete hover-activated"
                *ngIf="this.entityData$?.value?.status !== 'archived' && (this.form.get('image')?.value || this.currentImage)"
                (click)="deleteImage($event)">
                <button mat-mini-fab color="primary">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>

        <mat-form-field appearance="outline" fxFlex="1 0 0" *ngIf="this.form.get('image')?.value || this.currentImage">
            <mat-label>{{ 'NEWS.FORM.INPUTS.IMAGE_LINK' | translate }}</mat-label>
            <input matInput formControlName="imageLink" [placeholder]="'NEWS.FORM.INPUTS.IMAGE_LINK' | translate"
                [readonly]="this.entityData$?.value?.status === 'archived'">
        </mat-form-field>

        <mat-form-field *ngIf="!isFullscreen" appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'NEWS.FORM.INPUTS.CONTENT' | translate }}</mat-label>
            <textarea matInput rows="10" formControlName="content" [placeholder]="'NEWS.FORM.INPUTS.CONTENT' | translate"
                [readonly]="this.entityData$?.value?.status === 'archived'"></textarea>
            <mat-error *ngIf="form.get('content')?.hasError('required')">
                {{ 'NEWS.FORM.ERRORS.CONTENT_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <div *ngIf="!isFullscreen" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="16px">
            <div fxFlex="1 0 0" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="16px">
                <app-color-picker [class.disabled]="this.entityData$?.value?.status === 'archived'"
                    [tooltip]="'NEWS.FORM.INPUTS.BUTTON_COLOR' | translate" [color]="this.form.get('buttonColor')?.value"
                    [colors]="buttonColors" (event)="colorUpdated($event)">
                </app-color-picker>
                <mat-form-field appearance="outline" fxFlex="1 0 0">
                    <mat-label>{{ 'NEWS.FORM.INPUTS.BUTTON_TEXT' | translate }}</mat-label>
                    <input matInput formControlName="buttonText" [placeholder]="'NEWS.FORM.INPUTS.BUTTON_TEXT' | translate"
                        [readonly]="this.entityData$?.value?.status === 'archived'">
                </mat-form-field>
            </div>
            <mat-form-field appearance="outline" fxFlex="1 0 0">
                <mat-label>{{ 'NEWS.FORM.INPUTS.BUTTON_LINK' | translate }}</mat-label>
                <input matInput formControlName="buttonLink" [placeholder]="'NEWS.FORM.INPUTS.BUTTON_LINK' | translate"
                    [readonly]="this.entityData$?.value?.status === 'archived'">
                <mat-error *ngIf="form.get('buttonLink')?.hasError('invalidUrl')">
                    {{ 'FORM.URL_WRONG_FORMAT' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field appearance="outline"
            [class.mat-form-field-invalid]="this.form.get('segmentsSelect').dirty && this.form.get('segments').hasError('MinLengthArray')"
            fxFlex="1 0 0">
            <mat-label>{{ 'NEWS.FORM.INPUTS.SEGMENTS' | translate }}</mat-label>
            <mat-chip-list>
                <mat-chip *ngIf="!(form.get('segments')?.value?.length > 0)" [selectable]="false" [removable]="false">
                    {{ 'NEWS.FORM.INPUTS.SEGMENTS_EMPTY_SELECT' | translate }}
                </mat-chip>

                <mat-chip *ngFor="let segment of form.get('segments')?.value" [selectable]="true" [removable]="true"
                    (removed)="removeSegmentFromInput(segment)">
                    {{ segment?.name }}<mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>

                <mat-select formControlName="segmentsSelect">
                    <mat-option>
                        <ngx-mat-select-search formControlName="segmentsFilter"
                            [placeholderLabel]="'NEWS.FORM.INPUTS.SEGMENTS_SEARCH' | translate"
                            [noEntriesFoundLabel]="'NEWS.FORM.ERRORS.SEGMENTS_EMPTY' | translate"
                            [searching]="segmentsMatSelectSearch">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngIf="!(filteredSegmentsOptions$ | async)">{{ 'SHARED.LOADING' | translate }}</mat-option>
                    <mat-option *ngFor="let segment of (filteredSegmentsOptions$ | async)" [value]="segment">
                        {{ segment.name }}
                    </mat-option>
                </mat-select>
            </mat-chip-list>
            <mat-hint class="mat-error"
                *ngIf="this.form.get('segmentsSelect').dirty && this.form.get('segments').hasError('MinLengthArray')">
                {{ 'NEWS.FORM.ERRORS.SEGMENTS_REQUIRED' | translate }}
            </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0" class="mt-16"
            [class.disabled]="this.entityData$?.value?.status === 'published' || this.entityData$?.value?.status === 'archived'">
            <mat-label>{{ 'NEWS.FORM.INPUTS.PUBLISHED_DATE' | translate }}</mat-label>
            <input matInput formControlName="publishedDate" [placeholder]="'NEWS.FORM.INPUTS.PUBLISHED_DATE' | translate"
                [owlDateTimeTrigger]="datePicker" [owlDateTime]="datePicker"
                [min]="this.entityData$?.value?.status === 'published' ? null : minDate"
                [readonly]="this.entityData$?.value?.status === 'published' || this.entityData$?.value?.status === 'archived'"
                (focus)="datePicker.open()" (click)="datePicker.open()">
            <mat-icon matSuffix [owlDateTimeTrigger]="datePicker"></mat-icon>
            <owl-date-time #datePicker [stepMinute]="5" [firstDayOfWeek]="null | firstDayWeek"
                [hour12Timer]="null | twelveHour">
            </owl-date-time>
            <mat-error *ngIf="form.get('publishedDate')?.hasError('required')">
                {{ 'NEWS.FORM.ERRORS.DATE_REQUIRED' | translate }}
            </mat-error>
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
            <button mat-raised-button type="button" color="primary"
                *ngIf="!this.entityData$?.value?.id || this.entityData$?.value?.status === 'draft'" (click)="saveDraft()"
                [disabled]="formInProgress">
                <mat-icon>save</mat-icon>
                {{ 'NEWS.ACTIONS.SAVE' | translate }}
            </button>
            <button mat-raised-button type="button" color="primary" *ngIf="this.entityData$?.value?.status === 'published'"
                (click)="archive()" [disabled]="formInProgress">
                <mat-icon>archive</mat-icon>
                {{ 'NEWS.ACTIONS.ARCHIVE' | translate }}
            </button>
            <button mat-raised-button type="submit" color="accent"
                [disabled]="formInProgress || this.entityData$?.value?.status === 'archived'">
                <mat-icon>{{ this.entityData$?.value?.id && this.entityData$?.value?.status !== 'draft' ? 'save' : 'send' }}
                </mat-icon>
                {{ (this.entityData$?.value?.id && this.entityData$?.value?.status !== 'draft' ? 'FORM.SAVE' :
                'NEWS.ACTIONS.PUBLISH') | translate }}
            </button>
            <mat-spinner mode="indeterminate" diameter="20" color="primary" *ngIf="formInProgress"></mat-spinner>
        </div>
    </form>
    <div class="preview">
        <img src="/assets/images/preview/preview_phone.png" alt="Google Pixel 5" class="preview-background">
        <div class="preview-content">
            <img class="preview-image" src="/assets/images/preview/preview_top.png">
            <ng-container *ngIf="!isFullscreen; else otherStyle">
                <div class="preview-container">
                  <img class="preview-image" [src]="this.base64Image ? this.base64Image : (this.currentImage?.url ?? 'assets/images/news-no-image-preview-16-9.png')">
                  <div class="preview-text">
                    <p class="preview-title" *ngIf="this.form.get('title')?.value">{{ this.form.get('title')?.value }}</p>
                    <p class="preview-description" *ngIf="this.form.get('content')?.value">{{ this.form.get('content')?.value }}</p>
                    <p class="preview-button" [style.backgroundColor]="this.form.get('buttonColor')?.value"
                        *ngIf="this.form.get('buttonText')?.value">{{ this.form.get('buttonText')?.value }}</p>
                  </div>
                </div>
            </ng-container>
            <ng-template #otherStyle>
                <div class="preview-big-container">
                  <img class="preview-image-big" [src]="this.base64Image ? this.base64Image : (this.currentImage?.url ?? 'assets/images/news-no-image-preview-9-16.png')">
                  <p class="preview-title-big" *ngIf="this.form.get('title')?.value">{{ this.form.get('title')?.value }}</p>
                </div>
            </ng-template>
        </div>
    </div>
</div>

import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Observable } from 'rxjs';
import { Template } from '../../models/rendez-vous/template';
import { map } from 'rxjs/operators';
import { ObjectMapper } from 'json-object-mapper';
import { RecordingOptions, TemplatePostInterface } from '../../models/interfaces/template-post.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplateService {
    public templateSelectionUpdateNotifier: EventEmitter<Template>;
    public templateListUpdateNotifier: EventEmitter<Template>;

    constructor(
        private translateService: TranslateService,
        private http: HttpClient,
        private config: ConfigService
    ) {
        this.templateSelectionUpdateNotifier = new EventEmitter<Template>();
        this.templateListUpdateNotifier = new EventEmitter<Template>();
    }

    // Api

    public getTemplatesByEntiteJuridiqueId(EntiteJuridiqueId: number): Observable<Template[]> {
        return this.http.get<Template[]>(`${this.config.baseUrl}api/entite_juridique/${EntiteJuridiqueId}/templates`).pipe(
            map(templates => this.sortTemplatesByName(ObjectMapper.deserializeArray(Template, templates)))
        );
    }

    public saveTemplate(template: TemplatePostInterface, entiteJuridiqueId: number): Observable<Template[]> {
        const add = this.http.post<Template[]>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/template`, template);
        const update = this.http.put<Template[]>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/template/${template.id}`, template);

        const $result: Observable<Template[]> = template.recordingOption === RecordingOptions.COPY ? add : update;

        return $result.pipe(
            map((response: Template[]) => {
                if (!response) {
                    throw new Error(this.translateService.instant('REPORTS.TEMPLATE_SERVICE.RECORDING_FEEDBACK_FAILURE'));
                }

                return this.sortTemplatesByName(ObjectMapper.deserializeArray(Template, response));
            })
        );
    }

    public deleteTemplate(templateId: number, entiteJuridiqueId: number): Observable<Template[]> {
        return this.http.delete<Template[]>(`${this.config.baseUrl}api/entite_juridique/${entiteJuridiqueId}/template/${templateId}`)
            .pipe(
                map(templates => this.sortTemplatesByName(ObjectMapper.deserializeArray(Template, templates)))
            );
    }

    // Local

    /**
     * Renvoie les template mis à jour ou créés en dernier dans la liste de templates reçue
     * @param templates Liste des templates
     */
    public notifyTemplateListUpdate(templates: Template[]): void {
        const templatesSortedByUpdateDateDesc = [...templates];
        templatesSortedByUpdateDateDesc.sort((a, b) => {
            if (a.dateLastRecorded === b.dateLastRecorded) {
                return 0;
            } else if (a.dateLastRecorded > b.dateLastRecorded) {
                return -1;
            }

            return 1;
        });
        this.templateListUpdateNotifier.emit(templatesSortedByUpdateDateDesc[0]);
    }

    public changeAppliedTemplate(templateToApply: Template): void {
        this.templateSelectionUpdateNotifier.emit(templateToApply);
    }

    private sortTemplatesByName(templates: Template[]): Template[] {
        return templates.sort((a, b) => {
            if (a.name.toLowerCase() === b.name.toLowerCase()) {
                return 0;
            } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }

            return 1;
        });
    }
}

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { fuseAnimations } from '@fuse/animations';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';

import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { MatDialog } from '@angular/material/dialog';
import { AnimalService } from '../../../../services/api/animal.service';
import { Router } from '@angular/router';
import { ChatService } from '../../../../services/api/chat.service';
import { debounceTime } from 'rxjs/operators';
import { Chat } from '../../../../models/animal/chat';
import { fromEvent, merge, Subscription } from 'rxjs';
import { Utils } from '../../../../utils';
import { UserPreferenciesService } from '../../../../services/user-preferencies.service';
import { ChatFilter } from './chat-list-menu-filter/chat-list-menu-filter.component';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferenciesInterface } from 'app/models/interfaces/user-preferencies.interface';

@Component({
    selector: 'app-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss'],
    animations: fuseAnimations
})
export class ChatListComponent implements OnInit, OnDestroy {
    chats: Chat[] = [];
    chatGroup = ChatGroup;

    chatsRefined: ChatViewInterface[] = [];

    nbChatOpened: number;

    isRefreshing: boolean;
    chatSelected: Chat;
    hasFilter: boolean;

    @ViewChild('filter', { static: true }) filterElement: ElementRef;

    private sortSubscription = new Subscription();
    private chatSubscription = new Subscription();

    private searchValue: string;
    private chatFilter: ChatFilter;

    constructor(
        public chatService: ChatService, private fuseMatSidenavService: FuseMatSidenavHelperService,
        public media: MediaObserver, private dialog: MatDialog,
        private utilisateurService: UtilisateurService, private animalService: AnimalService,
        private router: Router, public userPreferenciesService: UserPreferenciesService,
        private translateService: TranslateService
    ) {
        this.userPreferenciesService.getPreferencies().subscribe((prefs: UserPreferenciesInterface) => {
            this.chatFilter = prefs?.chatsFilter;
            this.refreshChats();
        });
    }

    ngOnInit(): void {
        this.sortSubscription.add(this.chatService.chats.subscribe(chats => {
            this.chats = chats;
            this.isRefreshing = false;
        }));

        this.sortSubscription.add(this.chatService.chatSelected.subscribe(chat => {
            this.chatSelected = chat;
        }));

        this.sortSubscription.add(merge(
            this.chatService.chats,
            this.chatService.chatSelected,
            this.chatService.newMessage,
            this.chatService.totalUnread
        ).subscribe(() => {
            this.updateChats();
        }));

        this.sortSubscription.add(this.chatService.chatsBookmarksUpdate.subscribe(() => this.updateChats(true)));

        fromEvent(this.filterElement.nativeElement, 'keyup').pipe(
            debounceTime(150)
        ).subscribe(() => {
            this.searchValue = this.filterElement.nativeElement.value;
            this.refreshChats();
        });
    }

    private refreshChats(): void {
      this.chatSubscription?.unsubscribe();
      this.chatSubscription = this.chatService.getChatsApi(true, this.chatFilter.status, this.searchValue?.trim()?.toLowerCase()).subscribe(() => {
        this.updateChats();
      });
    }

    ngOnDestroy(): void {
        this.chatSubscription?.unsubscribe();
        this.sortSubscription.unsubscribe();
    }

    selectConversation(chatId: number): void {
        void this.router.navigate(['chat', chatId]);

        if (!this.media.isActive('gt-md')) {
            const sidenav = this.fuseMatSidenavService.getSidenav('chat-left-sidenav');
            void sidenav?.toggle();
        }
    }

    addChat(): void {
        const chat = new Chat();
        chat.dateDebut = new Date();
        chat.veterinaire = this.utilisateurService.utilisateurConnectedValue;

        this.chatService.openDialogChat(chat).afterClosed().subscribe(result => {
            if (result) {
                void this.router.navigate(['/chat', result.id], { replaceUrl: true });
            }
        });
    }

    updateChats(dateSortingDisabled = false): void {
        // const chatsAfterSearch = this.filterSearch(this.chats);
        // const chatsAfterFilters = this.filterChatFilter(chatsAfterSearch);
        const chatsAfterFilters = this.filterChatFilter(this.chats);
        this.doSortChat(chatsAfterFilters, dateSortingDisabled);
    }

    private filterSearch(chats: Chat[]): Chat[] {
        if (!this.searchValue || this.searchValue?.trim() === '') {
            return chats;
        }

        const search = Utils.removeDiacritics(this.searchValue?.trim().toLowerCase());
        return chats.filter(c => {
            const content: string[] = [
                c.nom,
                c.categorie.value,
                c.color,
                c.animal.nom,
                c.animal.proprietaire ? c.animal.proprietaire.fullNameLite : '',
                c.veterinaire.fullNameLite
            ];
            return content.filter(c => c !== undefined && c?.trim() !== '').some(c => Utils.removeDiacritics(c?.trim().toLowerCase()).includes(search));
        });
    }

    private filterChatFilter(chats: Chat[]): Chat[] {
        return chats.filter(c => {
            const cf = this.chatFilter;

            switch (cf?.status) {
                case 'close':
                    if (!c.isChatClosed) {
                        return false;
                    }

                    break;
                case 'futur':
                    if (!c.isFutur) {
                        return false;
                    }

                    break;
                case 'open':
                default:
                    if (!c.canChat) {
                        return false;
                    }

                    break;
            }

            if (cf?.isBookmarkedApplied && !c.bookmarked) {
                return false;
            }

            if (cf?.categories?.length > 0 && !cf.categories.includes(c.categorie.id)) {
                return false;
            }

            if (cf?.colors?.length > 0 && (c.color && !cf.colors.includes(c.color) || !c.color && !cf.colors.includes('none'))) {
                    return false;
                }

            if (cf?.veterinaries?.length > 0 && !cf.veterinaries.includes(c.veterinaire.id)) {
                return false;
            }

            if (cf?.clients?.length > 0 && !cf.clients.includes(c.animal.proprietaire.id)) {
                return false;
            }

            return true;
        });
    }

    private doSortChat(chats: Chat[], dateSortingDisabled = false) {
        this.chatsRefined = [];
        this.nbChatOpened = 0;

        const chatsUnread = chats
            .filter((c: Chat) => !c.veterinaryHasRead)
            .sort((a: Chat, b: Chat) => (a.lastMessageDate?.getTime() ?? 0) < (b.lastMessageDate?.getTime() ?? 0) ? 1 : -1);
        if (chatsUnread.length > 0) {
            this.chatsRefined.push({
                type: 'unread'
            } as ChatViewInterface);
            this.chatsRefined = [...this.chatsRefined, ...chatsUnread.map(c => {
                return {
                    type: 'chat',
                    group: 'unread',
                    chat: c
                };
            })];
        }

        const chatsPinned = chats
            .filter((c: Chat) => this.utilisateurService.utilisateurConnectedValue?.chatSettings?.pinned?.includes(c.id) ?? false)
            .sort((a: Chat, b: Chat) => (a.lastMessageDate?.getTime() ?? 0) < (b.lastMessageDate?.getTime() ?? 0) ? 1 : -1);
        if (chatsPinned.length > 0) {
            this.chatsRefined.push({
                type: 'pinned'
            } as ChatViewInterface);
            this.chatsRefined = [...this.chatsRefined, ...chatsPinned.map(c => {
                return {
                    type: 'chat',
                    group: 'pinned',
                    chat: c
                };
            })];
        }

        if (chats.length > 0) {
            this.chatsRefined.push({
                title: this.translateService.instant('SHARED.TCHATS'),
                type: 'category'
            } as ChatViewInterface);

            chats = chats.filter((c: Chat) => {
                const isUnread = c.unreadMessage > 0;
                const isPinned = this.utilisateurService.utilisateurConnectedValue?.chatSettings?.pinned?.includes(c.id) ?? false;
                return !(isUnread || isPinned);
            });

            if (!dateSortingDisabled) {
                chats = chats.sort((a, b) => (a.lastMessageDate?.getTime() ?? 0) < (b.lastMessageDate?.getTime() ?? 0) ? 1 : -1);
            }

            chats.forEach((c: Chat) => {
                    this.chatsRefined.push({
                        type: 'chat',
                        chat: c
                    });
                });
        }
    }

    trackByFn(index: number, item: Chat): number {
        return item.id;
    }

    trackByFnCat(index: number, item: ChatViewInterface): string {
        return item.title;
    }

    onChatFilterChanged(chatFilter: ChatFilter): void {
        if (this.chatFilter.status !== chatFilter.status) {
            this.chatFilter = chatFilter;
            const chatSubscription = this.chatService.getChatsApi(true, chatFilter.status).subscribe(() => {
                chatSubscription?.unsubscribe();
                this.updateChats();
            });
            return;
        }

        this.chatFilter = chatFilter;
        this.updateChats();
    }

    getChatNbrByGroup(group: ChatGroup): number {
        if (group === ChatGroup.CATEGORY) {
            return this.chatsRefined.filter((c: ChatViewInterface) => c.type === 'chat' && !c.group).length;
        }

        return this.chatsRefined.filter((c: ChatViewInterface) => c.group === group).length;
    }
}

enum ChatGroup {
    CATEGORY = 'category',
    CLOSE = 'close',
    FUTUR = 'futur',
    PINNED = 'pinned',
    UNREAD= 'unread'
}

interface ChatViewInterface {
    title?: string;
    type: string;
    group?: string;
    chat?: Chat;
}

<div fxLayout="row" class="rdv mb-16">
    <div class="rdv-date p-8 cursor-pointer" [matTooltip]="rendezVous.commentaire"
         (click)="editRendezVous(rendezVous)">
        <div class="rdv-date-day">{{ rendezVous.date | dateLocale:'dd' }}</div>
        <div class="rdv-date-month">{{ rendezVous.date | dateLocale:'MMM' }}</div>
    </div>
    <div class="rdv-content p-8 w-100-p">
        <div fxLayout="row" fxLayoutAlign="space-between top" fxLayoutGap="16px">
            <div class="font-size-18" fxLayout="row wrap">
                <b class="cursor-pointer" [matTooltip]="rendezVous.commentaire"
                   (click)="editRendezVous(rendezVous)">
                    {{ rendezVous.date | dateLocale:'shortTime' }}
                    &middot; {{ rendezVous.nature }}
                    &middot; {{ rendezVous.categorie?.value }}
                </b>
                <b *ngIf="rendezVous.finalPrice > 0">
                    &middot;
                    <span>{{ 'RENDEZ_VOUS.PRIX' | translate }}&nbsp;{{ rendezVous.finalPrice | currencyLocale:rendezVous.currency }}</span>
                </b>
                <div [ngSwitch]="rendezVous.paymentStatus">
                    <b *ngSwitchCase="'waiting_payment'">
                        &middot; <span class="red-fg">{{ 'RENDEZ_VOUS.IS_NOT_PAYED' | translate }}</span>
                    </b>
                    <b *ngSwitchCase="'payed'"
                       matTooltip="{{ 'RENDEZ_VOUS.CAPTURABLE_UNTIL' | translate:{ date: (rendezVous.captureUntilDate | dateLocale:'shortDate')} }}">
                        &middot; <span
                        class="primary-fg">{{ (rendezVous.hasStarted ? 'RENDEZ_VOUS.IS_NOT_CAPTURED' : 'RENDEZ_VOUS.IS_NOT_STARTED') | translate }}</span>
                    </b>
                    <b *ngSwitchCase="'canceled'">
                        &middot; <span class="accent-fg">{{ 'RENDEZ_VOUS.CANCELED_CAPTURE' | translate }}</span>
                    </b>
                    <b *ngSwitchCase="'captured'">
                        &middot; <span
                        class="green-fg">{{ 'RENDEZ_VOUS.IS_CAPTURED' | translate:{date: (rendezVous.paymentCurrentStateDate | dateLocale:'shortDate')} }}</span>
                    </b>
                    <b *ngSwitchCase="'timeout'"
                       matTooltip="{{ 'RENDEZ_VOUS.CAPTURABLE_UNTIL' | translate:{ date: (rendezVous.captureUntilDate | dateLocale:'shortDate')} }}">
                        &middot; <span class="red-fg">{{ 'RENDEZ_VOUS.CAPTURE_TIMEOUT' | translate}}</span>
                    </b>
                </div>
            </div>
            <div fxLayout="row" [ngClass]="['font-size-18', 'text-bold', (statusColor | async) ? (statusColor | async) : '']">
                {{ statusText | async }}
            </div>
        </div>


        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxLayout="row wrap" fxLayoutGap="8px">
                <app-profile-tile [title]="rendezVous.animal.nom" [entity]="rendezVous.animal"
                                  class="cursor-pointer" [matTooltip]="'SHARED.SEE_INFORMATIONS' | translate"
                                  (click)="editAnimal(rendezVous)">
                    {{ rendezVous.animal.espece.nom + ' ' + (rendezVous.animal.race != null ? ' &middot; ' +
                    rendezVous.animal.race.nom : '') }}
                </app-profile-tile>

                <app-profile-tile [title]="rendezVous.client | userFullName:true" [entity]="rendezVous.client"
                                  class="cursor-pointer" [matTooltip]="'SHARED.SEE_INFORMATIONS' | translate"
                                  (click)="editClient(rendezVous)">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                        <span>{{ rendezVous.client.telephonePortable | phone }}</span>
                        <mat-icon class="ml-4" [color]="rendezVous.client.lastConnection ? 'accent' : 'primary'"
                                  [matTooltip]="rendezVous.client.lastConnection ? ('SHARED.APP_INSTALL' | translate) : ('SHARED.APP_UNINSTALL' | translate)">
                            {{ rendezVous.client.lastConnection ? 'smartphone' : 'phonelink_erase' }}
                        </mat-icon>
                    </div>
                </app-profile-tile>
            </div>

            <div fxLayout="row wrap" fxLayoutAlign="end center" [ngSwitch]="buttonStatus | async">
                <div *ngSwitchCase="'edit'">
                    <button mat-button color="primary" (click)="editRendezVous(rendezVous)">
                        <mat-icon>edit</mat-icon>
                        {{ 'CHAT.CHANGE' | translate }}
                    </button>
                </div>
                <div *ngSwitchCase="'join'">
                    <button mat-button color="accent" (click)="loadVisio(rendezVous)">
                        <mat-icon>videocam</mat-icon>
                        {{ 'VISIO.JOIN' | translate }}
                    </button>
                </div>
                <div *ngSwitchCase="'capture'">
                    <button mat-button color="accent" (click)="capture(rendezVous)">
                        <mat-icon>payment</mat-icon>
                        {{ 'SHARED.DIALOG_CAPTURE.CAPTURE' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

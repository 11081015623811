<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ animal?.id ? ('PET.ANIMAL' | translate) + ' : ' + animal?.nom : ('PET.NEW' | translate) }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <app-animal-form [entityData]="animalData$" (photoSubmited)="hasChanged = true"
                             (entityReceived)="animal$.next($event); hasChanged = true; close();"
                             (createRdv)="createRdv()" (createTchat)="createTchat()"></app-animal-form>
        </ng-template>
    </div>
</div>

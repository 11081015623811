import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ChatPostInterface } from '../../../../models/interfaces/post/animal/chat-post.interface';
import { Chat } from '../../../../models/animal/chat';
import { ChatService } from '../../../../services/api/chat.service';
import { EntiteGeographique } from '../../../../models/pro/entite-geographique';
import { EntiteGeographiqueService } from '../../../../services/api/entite-geographique.service';
import { ConfigService } from '../../../../services/config.service';
import { CategorieInterface } from '../../../../models/interfaces/categorie.interface';
import { DialogComponent } from '../../view-utils/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-chat-form',
    templateUrl: './chat-form.component.html',
    styleUrls: ['./chat-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<ChatPostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();
    @Input('formInProgressDelete') formInProgressDelete$ = new Subject<boolean>();

    @Input() handleForm = true;
    @Input() redirectAfterAdd = true;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<ChatPostInterface>();
    @Output('formDeleted') formDeleted$ = new EventEmitter<boolean>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<Chat>();
    @Output('entityDeleted') entityDeleted$ = new EventEmitter<boolean>();

    form: FormGroup;
    now: Date = new Date();

    entitesGeographiques$: Observable<EntiteGeographique[]>;

    public categoriesFilter: FormControl = new FormControl();
    categories: CategorieInterface[] = [];
    filteredCategoriesOptions$: BehaviorSubject<CategorieInterface[]> = new BehaviorSubject<CategorieInterface[]>(null);

    private subscriptionEntityData: Subscription;
    private subscriptionCategories: Subscription;

    private readonly HOSPITVET_CATEGORIE_NUMBER: number = 27;

    constructor(
        private configService: ConfigService,
        private chatService: ChatService, private dialog: MatDialog, private translateService: TranslateService,
        private entiteGeographiqueService: EntiteGeographiqueService) {
        this.form = new FormGroup({
            id: new FormControl(),
            nom: new FormControl(null, [Validators.required]),
            hospitvet: new FormControl(true),
            categorie: new FormControl(null),
            dateDebut: new FormControl(null, [Validators.required]),
            dateFin: new FormControl(null),
            animalId: new FormControl(null, [Validators.required]),
            veterinaire: new FormControl(null, [Validators.required]),
            color: new FormControl(null),
            bookmarked: new FormControl(false)
        }, [dateFinAvantDateDebut]);

        this.form.get('id').disable();

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.categoriesFilter.valueChanges
            .subscribe(() => {
                const filteredCategoriesOptions = this.categoriesFilter.value ? this.categories.filter(espece => espece.value.toLowerCase().includes(this.categoriesFilter.value.toLowerCase())) : this.categories;

                this.filteredCategoriesOptions$.next(filteredCategoriesOptions);
            });
    }

    ngOnInit(): void {
        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe(chatData => {
            this.form.patchValue({
                id: chatData.id,
                nom: chatData.nom,
                hospitvet: chatData.id ? chatData.categorie === this.HOSPITVET_CATEGORIE_NUMBER : true,
                categorie: chatData.categorie,
                dateDebut: chatData.dateDebut ? chatData.dateDebut : this.now,
                dateFin: chatData.dateFin,
                animalId: chatData.animalId,
                veterinaire: chatData.veterinaire,
                color: chatData.color,
                bookmarked: chatData.id ? chatData.bookmarked : false
            }, { emitEvent: false });

            if (this.form.get('hospitvet').value) {
                this.form.get('categorie').setValue(this.HOSPITVET_CATEGORIE_NUMBER);
                this.form.get('categorie').disable();
            } else {
                this.form.get('categorie').enable();
            }

            if (chatData.id) {
                this.form.get('animalId').disable();
                this.form.get('veterinaire').disable();
            }
        });

        this.entitesGeographiques$ = this.entiteGeographiqueService.getAllEntitesGeographiques().pipe(
            map(egs => egs.sort((a, b) => a.nom < b.nom ? -1 : 1))
        );

        this.subscriptionCategories = this.configService.getCategories().subscribe(categories => {
            this.categories = categories;
            this.filteredCategoriesOptions$.next(this.categories);
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }

        if (this.subscriptionCategories) {
            this.subscriptionCategories.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid) {
            // Si aucune discipline (catégorie) n'a été choisie, on en impose la discipline par défaut (other/25).
            if (!this.form.controls.categorie.value) {
                this.form.controls.categorie.setValue(25);
            }

            this.formSubmited$.next(this.form.getRawValue());
            if (this.handleForm) {
                this.save(this.form.getRawValue());
            }
        }
    }

    private save(data: ChatPostInterface) {
        this.formInProgress$.next(true);

        if (data.id) {
            this.chatService.updateChat(data).subscribe({
                next: animal => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(animal);
                },
                error: () => this.formInProgress$.next(false)
            });
        } else {
            this.chatService.addChat(data).subscribe({
                next: animal => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(animal);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }

    remove(): void {
        this.formDeleted$.next(true);
        if (this.handleForm) {
            this.removeRendezVous();
        }
    }

    private removeRendezVous() {
        this.dialog.open(DialogComponent, {
            data: {
                title: this.translateService.instant('CHAT.DIALOG_DELETE.TITLE'),
                content: this.translateService.instant('CHAT.DIALOG_DELETE.CONTENT'),
                action: true,
                ok: this.translateService.instant('CHAT.DIALOG_DELETE.OK')
            },
            disableClose: true
        }).afterClosed().subscribe(val => {
            if (val) {
                this.formInProgressDelete$.next(true);
                this.chatService.deleteChat(this.form.get('id').value).subscribe({
                    next: () => {
                        this.formInProgressDelete$.next(false);
                        this.entityDeleted$.next(true);
                    },
                    error: () => this.formInProgressDelete$.next(false)
                });
            }
        }
        );
    }

    toggleHospitvetShortcut(): void {
        if (this.form.controls.hospitvet.value) {
            this.form.controls.categorie.setValue(this.HOSPITVET_CATEGORIE_NUMBER);
            this.form.controls.categorie.disable();
        } else {
            this.form.controls.categorie.setValue(null);
            this.form.controls.categorie.enable();
        }
    }

    updateHospitvet(): void {
        if (this.form.controls.categorie.value === this.HOSPITVET_CATEGORIE_NUMBER) {
            this.form.controls.hospitvet.setValue(true);
            this.form.controls.categorie.disable();
        }
    }
}

function dateFinAvantDateDebut(control: AbstractControl) {
    const dateDebut = control.get('dateDebut');
    const dateFin = control.get('dateFin');
    if (dateFin.value && dateFin.value < dateDebut.value) {
        dateFin.setErrors({ dateFinAvantDateDebut: true });
    } else {
        dateFin.setErrors(null);
        return null;
    }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/api/auth.service';
import { UtilisateurService } from '../services/api/utilisateur.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class NoAuthGuard implements CanActivate {
    private allowedUrl: string[] = [
        '/',
        '/onboarding-pro-plans',
        '/onboarding-pro-end',
        '/deactivated-account'
    ];

    constructor(
        protected router: Router,
        protected authService: AuthService,
        private utilisateurService: UtilisateurService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (state.url.startsWith('/login')) { // autrement ça rentre en conflit avec l'onboarding
            this.authService.saveTokenFromRouteIfPossible(state.root);
        }

        if (this.authService.isAuthenticated) {
            return this.utilisateurService.getMe().pipe(
                map(u => {
                    if (u.entiteJuridique.isDisabled && this.allowedUrl.some(url => state.url.startsWith(url))) {
                        return true;
                    }

                    void this.router.navigate(['/']);
                    return false;
                })
            );
        }

        return true;
    }
}

<div class="container" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div class="logo">
        <img src="/assets/images/logos/logo-vertical.svg" alt="Logo">
    </div>
    <div class="card mat-elevation-z2" *ngIf="utilisateur; else loading">
        <h1>{{ 'TIME.MORNING' | translate }} {{ utilisateur.fullName }},</h1>
        <p>{{ 'DEACTIVATED_ACCOUNT.DESCRIPTION' | translate }} {{ utilisateur.entiteJuridique.cancelSubscriptionDate | dateLocale:'fullDate' }}.</p>
        <div class="actions" fxLayout="column" fxLayoutGap="10px">
            <button mat-raised-button color="primary" [routerLink]="'/onboarding-pro-plans'">{{ 'DEACTIVATED_ACCOUNT.ACTIONS.ENABLE' | translate }}</button>
            <button mat-button [routerLink]="'/subscription/invoices'">{{ 'DEACTIVATED_ACCOUNT.ACTIONS.INVOICE' | translate }}</button>
            <button mat-button [routerLink]="'/bank-information'" *ngIf="utilisateur.entiteJuridique.paymentEnabled !== null">{{ 'DEACTIVATED_ACCOUNT.ACTIONS.PAYOUT' | translate }}</button>
        </div>
    </div>
    <ng-template #loading>
        <div class="card mat-elevation-z2">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                <span>{{ 'SHARED.LOADING' | translate }}</span>
                <mat-spinner mode="indeterminate" diameter="20"></mat-spinner>
            </div>
        </div>
    </ng-template>
    <button mat-button (click)="logout()" color="accent"><mat-icon>exit_to_app</mat-icon> {{ 'SHARED.SIGN_OUT' | translate }}</button>
</div>

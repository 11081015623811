import { NgModule } from '@angular/core';
import { ViewSharedModule } from 'app/main/shared/view-shared.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from '../../../shared.module';
import { RouterModule } from '@angular/router';
import { ListFichierModule } from '../list-fichier/list-fichier.module';
import { ConnectionTestDialogComponent } from './connection-test-dialog.component';
import { ConnectionTestComponent } from './connection-test.component';

@NgModule({
    declarations: [
        ConnectionTestDialogComponent,
        ConnectionTestComponent
    ],
    imports: [
        RouterModule,
        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        ListFichierModule
    ],
    exports: [ConnectionTestDialogComponent]
})
export class ConnectionTestDialogModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from 'app/services/config.service';
import { SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'filePath'
})

export class FilePathPipe implements PipeTransform {
    constructor(private configService: ConfigService) {}

    transform(uri: null | string | SafeResourceUrl, base = true, fallback = 'assets/images/no-image.jpg'): null | string | SafeResourceUrl {
        if (!uri) {
            return fallback;
        }

        if (typeof uri === 'string') {
            return uri && uri !== '' ? (base ? this.configService.baseUrl : '') + uri : fallback;
        }

        return uri;
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CalendarModule } from 'angular-calendar';

import { CalendrierComponent } from './calendrier.component';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { AuthGuard } from '../../../guards/auth.guard';
import { SharedModule } from '../../../shared.module';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';

const routes: Routes = [
    {
        path: 'calendar',
        component: CalendrierComponent,
        canActivate: [AuthGuard, IncompatibleBrowserGuard]
    },
    {
        path: 'calendrier',
        redirectTo: '/calendar',
        pathMatch: 'full'
    }
];

@NgModule({
    declarations: [
        CalendrierComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CalendarModule,

        SharedModule,
        ViewSharedModule
    ]
})
export class CalendrierModule {}

import { Component, Input, Host } from '@angular/core';
import { OnboardingClientValidateOtpResult } from '../../../services/api/onboarding.service';
import { OnboardingClientComponent } from '../onboarding-client.component';

@Component({
    selector: 'app-step-otp',
    templateUrl: './step-otp.component.html',
    styleUrls: ['./step-otp.component.scss']
})
export class StepOtpComponent {
    otpLoading = false;
    otpValue = '';
    @Input() phone: string;

    resendSmsEnabled = false;

    constructor(
        @Host() private parent: OnboardingClientComponent
    ) {}

    validateOtp(): void {
        if (!this.otpValue) {
            return;
        }

        this.otpLoading = true;
        this.parent.validateOtp(this.otpValue).subscribe({
            next: (res: OnboardingClientValidateOtpResult) => {
                if (res?.code === 'SESSION_EXPIRED') {
                    this.resendSmsEnabled = true;
                }

                if (res?.code !== 'GRANTED') {
                    this.otpLoading = false;
                }
            },
            error: () => {
                this.otpLoading = false;
            }
        });
    }

    resendSms(): void {
        this.parent.resendSms().subscribe({
            next: () => {
                this.resendSmsEnabled = false;
            },
            error: () => {
                this.resendSmsEnabled = false;
            }
        });
    }
}

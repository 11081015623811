import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormArray, FormControl, Validators } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
    selector: 'app-step-animals',
    templateUrl: './step-animals.component.html',
    styleUrls: ['./step-animals.component.scss']
})
export class StepAnimalsComponent implements OnInit {
    @Input() form: FormArray;
    @ViewChild('tabGroup', { static: true }) tabGroup: MatTabGroup;

    ngOnInit(): void {
        if (this.controls.length === 0) {
            this.addAnimal();
        }
    }

    public get controls(): AbstractControl[] {
        return this.form.controls;
    }

    onFormChanged(form: AbstractControl, index: number): void {
        this.form.controls[index].patchValue(form.value);
    }

    getAnimalName(index: number): string {
        try {
            return this.form.controls[index].get('nom').value as string;
        } catch {
            return null;
        }
    }

    addAnimal(): void {
        this.form.push(new FormGroup({
            nom: new FormControl(null, [Validators.required]),
            espece: new FormControl(null, [Validators.required]),
            race: new FormControl(null),
            sexe: new FormControl(null),
            sterilise: new FormControl(null),
            dateNaissance: new FormControl(null)
        })
        );

        this.tabGroup.selectedIndex = this.form.controls.length - 1;
    }

    removeAnimal(index: number): void {
        this.form.removeAt(index);
    }
}

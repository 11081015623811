<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'RENDEZ_VOUS.DIALOG_WEB.TITLE' | translate }}</span>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <app-rendez-vous-web-form (entityReceived)="rendezVous$.next($event); close();"
                                      [telephonePortable]="data.client?.telephonePortable"
                                      [animal]="data.animal">
            </app-rendez-vous-web-form>
        </ng-template>
    </div>
</div>

<div fxLayout="column" [formGroup]="form">
    <div class="step-header">
        <h1 [innerHTML]="'ONBOARDING_CLIENT.STEPS.USER.TITLE' | translate"></h1>
        <p *ngIf="entiteJuridique | async; let ej" [innerHTML]="'ONBOARDING_CLIENT.STEPS.USER.SUBTITLE' | translate"></p>
    </div>

    <div fxLayout="row wrap">
        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="center start">
            <img class="icon mb-16" src="../../../../assets/images/icons/onboarding-icon-user.svg" alt="User Icon" fxFlex="0 0"/>
        </div>
        <div fxFlex="1 1 auto" fxLayout="row wrap" fxLayoutGap="16px">
            <mat-form-field appearance="outline" fxFlex="1 0 100px">
                <mat-label>{{ 'FORM.CIVILITY' | translate }}</mat-label>
                <mat-select [placeholder]="'FORM.CIVILITY' | translate" formControlName="civilite" required>
                    <mat-option [value]="'CIVILITY.MR' | translate">{{ 'CIVILITY.MR' | translate }}</mat-option>
                    <mat-option [value]="'CIVILITY.MRS' | translate">{{ 'CIVILITY.MRS' | translate }}</mat-option>
                    <mat-option [value]="'CIVILITY.MISS' | translate">{{ 'CIVILITY.MISS' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('civilite').hasError('required')">
                    {{ 'ONBOARDING_CLIENT.STEPS.USER.CIVILITY_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                <mat-label>{{ 'ONBOARDING_CLIENT.STEPS.USER.FIRSTNAME_PLACEHOLDER' | translate }}</mat-label>
                <input matInput [placeholder]="'ONBOARDING_CLIENT.STEPS.USER.FIRSTNAME_PLACEHOLDER' | translate" formControlName="prenom" type="text" required>
                <mat-error *ngIf="form.get('prenom').hasError('required')">
                    {{ 'ONBOARDING_CLIENT.STEPS.USER.FIRSTNAME_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="1 1 auto">
                <mat-label>{{ 'ONBOARDING_CLIENT.STEPS.USER.NAME_PLACEHOLDER' | translate }}</mat-label>
                <input matInput [placeholder]="'ONBOARDING_CLIENT.STEPS.USER.NAME_PLACEHOLDER' | translate" formControlName="nom" type="text" required>
                <mat-error *ngIf="form.get('nom').hasError('required')">
                    {{ 'ONBOARDING_CLIENT.STEPS.USER.NAME_REQUIRED' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div fxLayout="column">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'ONBOARDING_CLIENT.STEPS.USER.MAIL_PLACEHOLDER' | translate }}</mat-label>
            <input matInput [placeholder]="'ONBOARDING_CLIENT.STEPS.USER.MAIL_PLACEHOLDER' | translate" formControlName="mail" type="mail" required>
            <mat-error *ngIf="form.get('mail').hasError('required')">
                {{ 'ONBOARDING_CLIENT.STEPS.USER.MAIL_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="!form.get('mail').hasError('required') && form.get('mail').hasError('email')">
                {{ 'ONBOARDING_CLIENT.STEPS.USER.MAIL_INVALID' | translate }}
            </mat-error>
        </mat-form-field>

        <app-input-phone #inputPhone class="w-100-p" appearance="outline"
            [placeholder]="'ONBOARDING_CLIENT.STEPS.USER.PHONE_PLACEHOLDER' | translate" [label]="'ONBOARDING_CLIENT.STEPS.USER.PHONE_LABEL' | translate" [control]="form.get('telephone')" [required]="false">
        </app-input-phone>
    </div>

    <mat-form-field appearance="outline" class="input-pwd">
        <mat-label>{{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_PLACEHOLDER' | translate }}</mat-label>
        <input matInput [placeholder]="'ONBOARDING_PRO.STEPS.USER.PASSWORD_PLACEHOLDER' | translate" formControlName="password" [type]="hidePassword ? 'password' : 'text'" required>
        <mat-icon matSuffix class="hide-password" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error *ngIf="form.get('password').hasError('required')">
            {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_REQUIRED' | translate }}
        </mat-error>
        <mat-error *ngIf="!form.get('password').hasError('required') && form.get('password').hasError('notStrongEnough')">
            {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_NOT_STRONG_ENOUGH' | translate }}
        </mat-error>
    </mat-form-field>
    <div *ngIf="pwdLevel !== null" fxLayout="row" fxLayoutAlign="start center" [style.margin-top]="form.get('password').touched && !form.get('password').valid ? '0px' : '-20px'" class="strong-container">
        <mat-progress-bar mode="determinate" [value]="pwdLevel" class="w-120" [color]="pwdLevel > 50 ? 'accent' : 'warn'"></mat-progress-bar>
        <div class="ml-8">
            <div *ngIf="pwdLevel < 20">
                {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_LEVEL.VERY_BAD' | translate }}
            </div>
            <div *ngIf="pwdLevel >= 20 && pwdLevel < 40">
                {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_LEVEL.BAD' | translate }}
            </div>
            <div *ngIf="pwdLevel >= 40 && pwdLevel < 60">
                {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_LEVEL.MEDIUM' | translate }}
            </div>
            <div *ngIf="pwdLevel >= 60 && pwdLevel < 80">
                {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_LEVEL.GOOD' | translate }}
            </div>
            <div *ngIf="pwdLevel >= 80">
                {{ 'ONBOARDING_PRO.STEPS.USER.PASSWORD_LEVEL.VERY_GOOD' | translate }}
            </div>
        </div>
    </div>
</div>

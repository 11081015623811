import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChatPostInterface } from '../../../../models/interfaces/post/animal/chat-post.interface';
import { Chat } from '../../../../models/animal/chat';

@Component({
    selector: 'app-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ChatDialogComponent {
    chatData$ = new BehaviorSubject<ChatPostInterface>(null);

    chat$: BehaviorSubject<Chat>;
    chat: Chat;
    loading$ = new BehaviorSubject<boolean>(false);

    hasChanged = false;

    constructor(
        public dialogRef: MatDialogRef<ChatDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ChatDialogInterface
    ) {
        this.chat$ = new BehaviorSubject(data.chat);
        this.chat$.pipe(
            filter(u => Boolean(u))
        ).subscribe(chat => {
            this.chat = chat;
            this.chatData$.next(chat.hasPost());
        });
    }

    close(deleted = false): void {
        if (deleted) {
            this.dialogRef.close('deleted');
        } else {
            this.dialogRef.close(this.hasChanged ? this.chat$.value : false);
        }
    }
}

export interface ChatDialogInterface {
    chat: Chat;
}

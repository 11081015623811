import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat.component';
import { ChatViewComponent } from './chat-view/chat-view.component';
import { ViewSharedModule } from '../../shared/view-shared.module';
import { ChatListComponent } from './chat-list/chat-list.component';
import { ChatAuthGuard } from '../../../guards/chat-auth.guard';
import { ChatResolver } from '../../../resolver/chat.resolver';
import { ChatListTileComponent } from './chat-list/chat-list-tile/chat-list-tile.component';
import { SharedModule } from '../../../shared.module';
import { FichiersAnimalDialogModule } from '../../shared/fichiers-animal-dialog/fichiers-animal-dialog.module';
import { AutosizeModule } from 'ngx-autosize';
import { NgxFileDropModule } from 'ngx-file-drop-temp';
import { ChatDelayedMessagesDialogComponent } from './chat-delayed-messages-dialog/chat-delayed-messages-dialog.component';
import { ChatEditDelayedMessageDialogComponent } from './chat-edit-delayed-message-dialog/chat-edit-delayed-message-dialog.component';
import { IncompatibleBrowserGuard } from 'app/guards/incompatible-browser.guard';
import { LinkyModule } from 'ngx-linky';
import { ChatListMenuFilterComponent } from './chat-list/chat-list-menu-filter/chat-list-menu-filter.component';
import { ChatScenarioProgramDialogComponent } from './chat-scenario-program-dialog/chat-scenario-program-dialog.component';
import { ChatScenarioProgramMessageComponent } from './chat-scenario-program-dialog/chat-scenario-program-message/chat-scenario-program-message.component';
import { ChatMessageSelectionDialogComponent } from './chat-message-selection-dialog/chat-message-selection-dialog.component';
import { SettingsModule } from '../settings/settings.module';
import { PhotoGalleryModule } from '@twogate/ngx-photo-gallery';

const routes: Routes = [
    {
        path: 'chat',
        component: ChatComponent,
        canActivate: [ChatAuthGuard, IncompatibleBrowserGuard],
        children: [
            {
                path: ':chatId',
                component: ChatViewComponent,
                resolve: {
                    chats: ChatResolver
                }
            }
        ]
    }
];

@NgModule({
    declarations: [
        ChatComponent,
        ChatViewComponent,
        ChatListComponent,
        ChatListTileComponent,
        ChatListMenuFilterComponent,
        ChatDelayedMessagesDialogComponent,
        ChatEditDelayedMessageDialogComponent,
        ChatScenarioProgramDialogComponent,
        ChatScenarioProgramMessageComponent,
        ChatMessageSelectionDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        SharedModule,
        ViewSharedModule,
        AutosizeModule,
        FichiersAnimalDialogModule,
        NgxFileDropModule,
        LinkyModule,
        SettingsModule,
        PhotoGalleryModule
    ],
    exports: [
        ChatViewComponent,
        ChatListComponent
    ]
})
export class ChatModule {}

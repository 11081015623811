<div [formGroup]="form">
    <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="20px">
        <mat-form-field appearance="outline" fxFlex="1 0 0">
            <mat-label>{{ 'FORM.FUNCTION' | translate }}</mat-label>
            <mat-select [placeholder]="'FORM.FUNCTION' | translate" formControlName="fonction">
                <mat-option *ngFor="let function of (('VETO.FUNCTIONS' | translate) | values:true)"
                            [value]="function.value">
                    {{ function.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="1 0 0" *ngIf="country == 'FR'">
            <mat-label>{{ 'FORM.ORDER' | translate }}</mat-label>
            <input matInput formControlName="numeroOrdre" [placeholder]="'FORM.ORDER' | translate">
        </mat-form-field>
    </div>

    <div class="w-100-p mb-16">
        <mat-slide-toggle formControlName="appProAllowed">
            {{ 'LOGIN.APP_PRO_ALLOWED_AUTHORIZED' | translate }}
        </mat-slide-toggle>
    </div>
</div>

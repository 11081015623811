import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ViewSharedModule } from '../shared/view-shared.module';
import { SharedModule } from '../../shared.module';
import { NoAuthGuard } from '../../guards/no-auth.guard';
import { OnboardingClientComponent } from './onboarding-client.component';
import { StepsComponent } from './steps/steps.component';
import { FormSharedModule } from '../shared/form/form-shared.module';
import { StepClinicComponent } from './step-clinic/step-clinic.component';
import { StepAnimalsComponent } from './step-animals/step-animals.component';
import { StepOtpComponent } from './step-otp/step-otp.component';
import { StepUserComponent } from './step-user/step-user.component';

const routes = [
    {
        path: 'onboarding-client/:clinic',
        component: OnboardingClientComponent,
        canActivate: [NoAuthGuard]
    },
    {
        path: 'onboarding-client',
        component: OnboardingClientComponent,
        canActivate: [NoAuthGuard]
    }
];

@NgModule({
    declarations: [
        OnboardingClientComponent,
        StepsComponent,
        StepClinicComponent,
        StepUserComponent,
        StepAnimalsComponent,
        StepOtpComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        FormSharedModule
    ]
})
export class OnboardingClientModule {}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisioService } from '../../../../services/visio.service';

@Component({
    selector: 'app-visio-settings-dialog',
    templateUrl: './visio-settings-dialog.component.html',
    styleUrls: ['./visio-settings-dialog.component.scss']
})

export class VisioSettingsDialogComponent {
    visioService: VisioService;

    constructor(
        public dialogRef: MatDialogRef<VisioSettingsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: VisioSettingsDialogInterface
    ) {
        this.visioService = data.visioService;
    }

    close(): void {
        this.dialogRef.close();
    }
}

export interface VisioSettingsDialogInterface {
    visioService: VisioService;
}

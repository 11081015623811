export interface Connecteur {
    id: string;
    name: string;
    description: string;
    link?: string;
    logo: ConnecteurLogo;
    countries: string[];
    minVersion?: string;
    features: ConnecteurFeatureInterface[];
    installation?: ConnecteurInstruction;
    status: ConnecteurStatus;
}

export interface ConnecteurLogo {
    light: string;
    dark?: string;
}

export enum ConnecteurFeatureInterface {
    'sync_appointment_push' = 'SYNC_APPOINTMENT_PUSH',
    'sync_appointment_pull' = 'SYNC_APPOINTMENT_PULL',
    'sync_client_push' = 'SYNC_CLIENT_PUSH',
    'sync_client_pull' = 'SYNC_CLIENT_PULL',
    'sync_animal_push' = 'SYNC_ANIMAL_PUSH',
    'sync_animal_pull' = 'SYNC_ANIMAL_PULL',
    'sync_veterinarian_push' = 'SYNC_VETERINARIAN_PUSH',
    'sync_veterinarian_pull' = 'SYNC_VETERINARIAN_PULL',
    'sync_files_push' = 'SYNC_FILES_PUSH',
    'sync_files_pull' = 'SYNC_FILES_PULL'
}

export interface ConnecteurFeature {
    connecteurType: ConnecteurFeatureType;
    support: ConnecteurFeatureSupport[];
}

export enum ConnecteurFeatureType {
    'sync_appointment' = 'SYNC_APPOINTMENT',
    'sync_client' = 'SYNC_CLIENT',
    'sync_animal' = 'SYNC_ANIMAL',
    'sync_veterinarian' = 'SYNC_VETERINARIAN',
    'sync_files' = 'SYNC_FILES'
}

export enum ConnecteurFeatureSupport {
    push,
    pull
}

export interface ConnecteurInstruction {
    instruction: string;
    link?: string;
}

export enum ConnecteurStatus {
    available = 'available',
    'coming_soon' = 'coming_soon',
    unavailable = 'unavailable'
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span class="title dialog-title">{{ 'ANIMAL_EDIT_PHOTO_DIALOG.EDITOR' | translate }}</span>
            </div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0">

        <pintura-editor
            *ngIf="file; else imageEditLoading"
            [src]="file"
            [options]="options"
            (process)="onSave($event)"
            (close)="close()">
        </pintura-editor>

        <ng-template #imageEditLoading>
            <app-loading></app-loading>
        </ng-template>
    </div>
</div>

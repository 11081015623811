import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, merge, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AnimalPostInterface } from '../../../../models/interfaces/post/animal/animal-post.interface';
import { AnimalService } from '../../../../services/api/animal.service';
import { Animal } from '../../../../models/animal/animal';
import { EspecesRacesInterface, RacesInterface } from '../../../../models/interfaces/especes-races.interface';
import { ConfigService } from '../../../../services/config.service';
import { ChatService } from '../../../../services/api/chat.service';
import { ConnecteurService } from 'app/services/api/connecteur.service';

@Component({
    selector: 'app-animal-form',
    templateUrl: './animal-form.component.html',
    styleUrls: ['./animal-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AnimalFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new Subject<AnimalPostInterface>();
    @Input('formInProgress') formInProgress$ = new Subject<boolean>();
    @Input('photoDelInProgress') photoDelInProgress$ = new Subject<boolean>();
    @Input('photoInProgress') photoInProgress$ = new Subject<boolean>();
    @Input('imageChangedEvent') imageEvent$ = new Subject<any>();

    @Input() enablePhoto = true;
    @Input() allowEditWhereasLinked = false;
    @Input() enablePhotoForm = true;
    @Input() handleForm = true;
    @Input() showSaveButton = true;
    @Input() showDateDeces = true;

    @Output('formChanged') formChanged$ = new EventEmitter<AbstractControl>();
    @Output('formSubmited') formSubmited$ = new EventEmitter<AnimalPostInterface>();
    @Output('photoSubmited') photoSubmited$ = new EventEmitter<Blob>();
    @Output('entityReceived') entityReceived$ = new EventEmitter<Animal>();
    @Output('createRdv') createRdv$ = new EventEmitter<boolean>();
    @Output('createTchat') createTchat$ = new EventEmitter<boolean>();

    form: FormGroup;
    now: Date = new Date();
    entityLinked = false;

    public especeFilter: FormControl = new FormControl();
    public raceFilter: FormControl = new FormControl();

    especesRaces: EspecesRacesInterface[] = [];
    filteredEspecesOptions$: BehaviorSubject<EspecesRacesInterface[]> = new BehaviorSubject<EspecesRacesInterface[]>(null);
    filteredRacesOptions$: BehaviorSubject<RacesInterface[]> = new BehaviorSubject<RacesInterface[]>(null);

    private subscriptionEntityData: Subscription;
    private readonly subscriptionEspeces: Subscription;

    constructor(
        private animalService: AnimalService,
        private chatService: ChatService,
        private configService: ConfigService,
        public connecteurService: ConnecteurService
    ) {
        this.form = new FormGroup({
            id: new FormControl(),
            nom: new FormControl(null, [Validators.required]),
            espece: new FormControl(null, [Validators.required]),
            race: new FormControl(null),
            sexe: new FormControl(null),
            sterilise: new FormControl(null),
            dateNaissance: new FormControl(null),
            dateDeces: new FormControl(null),
            proprietaire: new FormControl(null, [Validators.required]),
            connectorId: new FormControl()
        });

        this.form.get('id').disable();

        if (this.entityLinked) {
            this.form.disable();
        }

        this.form.valueChanges.subscribe(() => {
            this.formChanged$.next(this.form);
        });

        this.subscriptionEspeces = this.configService.getEspecesRaces().subscribe(e => {
            // On positionne le chien et chat en 1er dans la liste
            this.especesRaces = this.moveDogAndCatToTop(e);
            this.filteredEspecesOptions$.next(this.especesRaces);
            this.updateSelectRace();
        });

        this.especeFilter.valueChanges
            .subscribe(() => {
                const fileteredEspeces = this.especeFilter.value ? this.especesRaces.filter(espece => espece.nom.toLowerCase().includes(this.especeFilter.value.toLowerCase())) : this.especesRaces;

                this.filteredEspecesOptions$.next(fileteredEspeces);
            });

        merge(this.raceFilter.valueChanges, this.form.get('race').valueChanges, this.form.get('espece').valueChanges).subscribe(() => {
            this.updateSelectRace();
        });
    }

    ngOnInit(): void {
        this.subscriptionEntityData = this.entityData$.pipe(
            filter(u => Boolean(u))
        ).subscribe(animalData => {
            this.form.patchValue({
                id: animalData.id,
                nom: animalData.nom,
                espece: animalData.espece,
                race: animalData.race,
                sexe: animalData.sexe,
                sterilise: animalData.sterilise,
                dateNaissance: animalData.dateNaissance,
                dateDeces: animalData.dateDeces,
                proprietaire: animalData.proprietaire,
                connectorId: animalData.connectorId
            }, { emitEvent: false });
            this.updateSelectRace();

            if (animalData.id) {
                this.entityLinked = Boolean(this.form.get('connectorId').value);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }

        if (this.subscriptionEspeces) {
            this.subscriptionEspeces.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid && this.handleForm) {
            this.save(this.form.getRawValue());
        }
    }

    private save(data: AnimalPostInterface): void {
        this.formInProgress$.next(true);

        if (data.id) {
            this.animalService.updateAnimal(data).subscribe({
                next: animal => {
                    this.chatService.updateAnimalOnChat(animal);
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(animal);
                    this.formSubmited$.next(data);
                },
                error: () => this.formInProgress$.next(false)
            });
        } else {
            this.animalService.addAnimal(data).subscribe({
                next: animal => {
                    this.formInProgress$.next(false);
                    this.entityReceived$.next(animal);
                    this.formSubmited$.next(data);
                },
                error: () => this.formInProgress$.next(false)
            });
        }
    }

    submitPhoto(image: Blob): void {
        this.photoSubmited$.next(image);
        if (this.handleForm) {
            this.savePhoto(image);
        }
    }

    private savePhoto(image: Blob): void {
        if (image) {
            this.photoInProgress$.next(true);
            this.animalService.setPhoto(this.form.get('id').value, image).subscribe({
                next: animal => {
                    this.chatService.updateAnimalOnChat(animal);
                    this.entityReceived$.next(animal);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(animal.hasPost());
                    }

                    this.photoInProgress$.next(false);
                    this.imageEvent$.next(null);
                },
                error: () => this.photoInProgress$.next(false)
            });
        } else {
            this.photoDelInProgress$.next(true);
            this.animalService.setPhoto(this.form.get('id').value, image).subscribe({
                next: animal => {
                    this.chatService.updateAnimalOnChat(animal);
                    this.entityReceived$.next(animal);
                    if (this.entityData$ instanceof Subject) {
                        this.entityData$.next(animal.hasPost());
                    }

                    this.photoDelInProgress$.next(false);
                    this.imageEvent$.next(null);
                },
                error: () => this.photoDelInProgress$.next(false)
            });
        }
    }

    createRdv(): void {
        this.createRdv$.next(true);
    }

    createTchat(): void {
        this.createTchat$.next(true);
    }

    updateSelectRace(): void {
        const espece = this.especesRaces.find(e => e.id === this.form.get('espece').value);
        let races: RacesInterface[];
        if (espece) {
            this.form.get('race').enable({ emitEvent: false });
            races = espece.races
                .filter(race => {
                    return espece.races.includes(race);
                });
        } else {
            this.form.get('race').disable({ emitEvent: false });
            races = [];
        }

        if (this.raceFilter.value) {
            races = races.filter(race => race.nom.toLowerCase().includes(this.raceFilter.value.toLowerCase()));
        }

        this.filteredRacesOptions$.next(races);
    }

    private moveDogAndCatToTop(initialList: EspecesRacesInterface[]): EspecesRacesInterface[] {
        const dogAndCat: EspecesRacesInterface[] = initialList.filter(e => e.id === 2 || e.id === 4);
        const otherSpecies: EspecesRacesInterface[] = initialList.filter(e => e.id !== 2 && e.id !== 4);
        otherSpecies.sort((a, b) => {
            if (a.nom < b.nom) {
                return -1;
            } else if (a.nom > b.nom) {
                return 1;
            }

            return 0;
        });

        return [...dogAndCat, ...otherSpecies];
    }
}

import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NewsSegment, NewsSegmentList } from 'app/models/news/news-segment';
import { NewsSegmentsService } from 'app/services/api/news-segments.service';

export class NewsSegmentsDataSource implements DataSource<NewsSegment> {
    private _news = new BehaviorSubject<NewsSegment[]>([]);
    private _totalCount = new BehaviorSubject<number>(0);
    private _loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this._loadingSubject.asObservable();
    public totalCount$ = this._totalCount.asObservable();

    private subscription: Subscription;

    constructor(private newsSegmentsService: NewsSegmentsService) {}

    load(filter: string, sortDirection: string, sortField: string, pageIndex: number, pageSize: number, clinic_id: number): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this._loadingSubject.next(true);

        this.subscription = this.newsSegmentsService.getAll(filter, sortDirection, sortField, pageIndex * pageSize, pageSize, clinic_id).pipe(
            catchError(() => of([]))
        ).subscribe({
            next: (news: NewsSegmentList) => {
                if (news.data && news.meta) {
                    const rows = [];
                    news.data.forEach(element => rows.push(element));
                    this._news.next(rows);
                    this._totalCount.next(news.meta.totalItems);
                    this._loadingSubject.next(false);
                }
            },
            error: () => this._loadingSubject.next(false)
        });
    }

    add(news: NewsSegment): void {
        const data = this._news.getValue();
        data.push(news);
        this._news.next(data);
        this._totalCount.next(this._totalCount.getValue() + 1);
    }

    replace(news: NewsSegment): void {
        const data = this._news.getValue();
        const index = data.findIndex(c => c.id === news.id);
        data[index] = news;
        this._news.next(data);
    }

    delete(news: NewsSegment): void {
        const data = this._news.getValue();
        data.splice(data.findIndex(c => c.id === news.id), 2);
        this._news.next(data);
        this._totalCount.next(this._totalCount.getValue() - 1);
    }

    connect(): Observable<NewsSegment[]> {
        return this._news.asObservable();
    }

    disconnect(): void {
        this._news.complete();
        this._loadingSubject.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    isEmpty(): Observable<boolean> {
        return this._totalCount.pipe(
            map(nb => nb === 0)
        );
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span
                class="title dialog-title">{{ data.title ? data.title : ('FORM.IMPORT_' + typeForText + '_TITLE' | translate) }}</span>
            <button mat-icon-button (click)="cancel()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content>
        <div #dialogContentContainer class="dialog-content-container" fxLayout="column" fxLayoutAlign="center center">
            <div *ngIf="!currentCroppingFile" class="file-upload p-16" fxLayout="column" fxLayoutAlign="center center"
                fxFlex="1 1 100%">
                <ngx-file-drop (onFileDrop)="onFileDropped($event)" class="file-upload-inner"
                               fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="center center">
                    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center center" style="height: 100%;">
                        <div *ngIf="currentFiles?.length > 0" fxLayout="row wrap" fxFlex="1 1 80%" style="height: 100%;"
                            [fxLayoutAlign]="data.selectorMultiple ? 'start start' : 'center center'">
                            <div *ngFor="let currentFile of currentFiles" fxLayout="row" fxFlex="0 0 50%" class="p-8">
                                <div class="preview-container" fxLayout="column" fxLayoutAlign="center center">
                                    <div [class]="(data.selectorMultiple ? 'multiple' : '')">
                                        <img [src]="currentFile.croppedDataUrl ? currentFile.croppedDataUrl : (currentFile.originalDataUrl ? currentFile.originalDataUrl : currentFile.placeholder)"
                                            [class]="'preview' + (currentFile.originalDataUrl ? ' isImage' : '') + (currentFile.originalDataUrl && data.settings.rounded ? ' rounded' : '')" />
                                        <div *ngIf="data.enableCropper && currentFile.originalDataUrl"
                                            [class]="'preview-hover' + (currentFile.originalDataUrl && data.settings.rounded ? ' rounded' : '')"
                                            fxLayout="column" fxLayoutAlign="center center"
                                            (click)="currentFile.originalDataUrl ? cropFile(currentFile) : null;">
                                            <mat-icon>crop</mat-icon>
                                        </div>
                                        <button class="preview-delete" mat-mini-fab color="primary"
                                            (click)="removeFile(currentFile)">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </div>
                                </div>

                                <div class="ml-16" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
                                    <div [class]="'text-bigger' + (data.selectorMultiple ? ' multiple' : '')">
                                        {{ currentFile.file.name }}</div>
                                    <div [class]="'text-big' + (data.selectorMultiple ? ' multiple' : '')">
                                        {{ currentFile.file.size | fileSize }}</div>
                                </div>
                            </div>
                            <div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center center">
                                <button mat-raised-button class="mat-accent mt-16"
                                        (click)="openFileSelector()">{{ 'FORM.IMPORT_' + typeForText + '_ANOTHER' | translate }}</button>
                            </div>
                        </div>
                        <div [hidden]="currentFiles?.length > 0" fxLayout="column" fxLayoutAlign="center center" fxFlex="1 1 80%" style="height: 100%;">
                            <img src="/assets/images/empty-state-file.svg"/>
                            <p style="text-align: center;">{{ 'FORM.IMPORT_' + typeForText + '_SPEECH' | translate }}
                            </p>
                            <button mat-raised-button class="mat-accent mr-16"
                                    (click)="openFileSelector()">{{ 'FORM.IMPORT_' + typeForText | translate }}</button>
                        </div>
                    </div>
                    </ng-template>
                </ngx-file-drop>
            </div>
            <pintura-editor
                    class="cropper"
                    *ngIf="onContainerReady && currentCroppingFile"
                    [src]="currentCroppingFile.originalDataUrl"
                    [options]="pinturaOptions"
                    (process)="crop($event)"
                    (close)="noCrop()"
                    fxFlex="1 1 100%">
            </pintura-editor>
        </div>
        <div *ngIf="!currentCroppingFile" fxLayout="row" fxLayoutAlign="flex-end center" class="m-16">
            <button mat-button (click)="cancel()">
                {{ 'SHARED.CANCEL' | translate }}
            </button>

            <button mat-raised-button color="primary" (click)="ok()" [disabled]="!isValid()">
                {{ 'SHARED.OK' | translate }}
            </button>
        </div>
    </div>
</div>

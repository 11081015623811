import { Entity } from './entity';
import { JsonProperty } from 'json-object-mapper';
import { DateMapper } from '../object-mapper/date-mapper';
import { Utilisateur } from './utilisateurs/utilisateur';
import { Chat } from './animal/chat';
import { List } from './list';
import { Veterinaire } from './utilisateurs/veterinaire';
import { Fichier } from './fichier';

export class DelayedMessage extends Entity {
    @JsonProperty()
    message: string = null;

    @JsonProperty({ type: Chat })
    chat: Chat = null;

    @JsonProperty({ type: Utilisateur })
    sender: Veterinaire = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    date: Date = null;

    @JsonProperty()
    numberDaysToOpenIfClosed: number = null;

    @JsonProperty({ type: Fichier })
    attachment?: Fichier = null;
}

export class ListDelayedMessages extends List {
    @JsonProperty({ type: DelayedMessage })
    data: DelayedMessage[] = null;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { RendezVous } from '../../../models/rendez-vous/rendez-vous';
import { RendezVousService } from '../../../services/api/rendez-vous.service';
import { TemplateService } from '../../../services/api/template.service';
import { NoteService } from '../../../services/api/note.service';
import { Note } from '../../../models/rendez-vous/note';
import { ReportService } from '../../../services/api/report.service';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { Veterinaire } from '../../../models/utilisateurs/veterinaire';
import { Template } from '../../../models/rendez-vous/template';
import { Report, ReportTarget } from '../../../models/rendez-vous/report';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TemplateRecordingDialogComponent } from './dialogs/templateRecording/template-recording-dialog/template-recording-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportPostInterface } from '../../../models/interfaces/report-post.interface';
import { TranslateService } from '@ngx-translate/core';
import { EmailDataInterface } from 'app/models/interfaces/email-data.interface';
import { DateLocalePipe } from 'app/pipes/date-locale.pipe';
import { EmailBuildingDialogComponent } from './dialogs/email-building-dialog/email-building-dialog.component';

enum ContentType {
    Template,
    Client,
    Vet
}

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
    animations: fuseAnimations
})
export class ReportComponent implements OnInit, OnDestroy {
    /** le véto actuellement connecté */
    vet: Veterinaire = null;

    /** l'entité juridique de ce véto */
    private entiteJuridiqueId: number = null;

    /** les types de contenus qu'il est possible d'afficher / modifier */
    contentType = ContentType;

    /** le toggle bouton sélectionné : conditionne la MEF générale */
    displayedContent: ContentType;

    /** le rapport de consult version client */
    clientReport: Report = null;

    /** le rapport de consult version véto */
    vetReport: Report = null;

    /** la liste des templates dispo pour l'entité géographique du véto */
    templates: Template[] = null;

    /** l'id du rdv de visio qui précède la rédaction du rapport, et au cours duquel les notes que l'on valorise ici ont été prises */
    rdvId: number;

    /** le rdv de visio qui précède la rédaction du rapport, et au cours duquel les notes que l'on valorise ici ont été prises */
    rendezVous: RendezVous = null;

    /** la prise de note effectuée pdt la visio */
    note: Note = null;

    disabledEmailSendingButton: boolean;

    private templateSelectionUpdateNotifierSubscription: Subscription;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private rdvService: RendezVousService,
        private templateService: TemplateService,
        private noteService: NoteService,
        private reportService: ReportService,
        private utilisateurService: UtilisateurService,
        private snackbar: MatSnackBar,
        private dialog: MatDialog,
        private translateService: TranslateService) {}

    ngOnInit(): void {
        // On récupère le vétérinaire connecté + entité juridique
        this.utilisateurService.utilisateurConnected.subscribe(veterinaire => {
            this.vet = veterinaire;
            this.entiteJuridiqueId = this.vet.entiteJuridique.id;

            // On récupère la liste des modèles à dispo pour cette entité juridique
            this.templateService.getTemplatesByEntiteJuridiqueId(this.entiteJuridiqueId).subscribe((templates: Template[]) => {
                this.templates = templates;
                this.templateService.notifyTemplateListUpdate(templates);
            });
        });

        // On récupère le rdv et l'utilise pour mettre à jour ce qui dépend de lui
        this.rdvId = +this.route.snapshot.params.id;

        // On récupère la note
        this.note = new Note();
        this.noteService.getNoteByRendezVousId(this.rdvId).subscribe(
            note => {
                if (note !== null) {
                    this.note = note;
                }
            }
        );

        // On récupère le rvd et les rapports
        this.rendezVous = new RendezVous();

        this.clientReport = new Report();
        this.clientReport.target = ReportTarget.Client;

        this.vetReport = new Report();
        this.vetReport.target = ReportTarget.Vet;

        this.rdvService.getById(this.rdvId).subscribe(rdv => {
            if (rdv !== null) {
                this.rendezVous = rdv;
                this.clientReport.appointment = rdv;
                this.vetReport.appointment = rdv;
            }

            this.reportService.getReportByRendezVousIdAndTarget(this.rdvId, ReportTarget.Client).subscribe({
                next: report => {
                    if (report !== null) {
                        this.clientReport = report;
                    }
                },
                complete: () => {
                    this.clientReport.appointment = rdv;
                    this.displayedContent = ContentType.Client;
                }
            });

            this.reportService.getReportByRendezVousIdAndTarget(this.rdvId, ReportTarget.Vet).subscribe({
                next: report => {
                    if (report !== null) {
                        this.vetReport = report;
                    }
                },
                complete: () => {
                    this.vetReport.appointment = rdv;
                }
            });
        });

        // On s'abonne au changement de template
        this.templateSelectionUpdateNotifierSubscription = this.templateService.templateSelectionUpdateNotifier.subscribe(_ => {
            this.templateUpdate();
        });

        this.disabledEmailSendingButton = false;
    }

    ngOnDestroy(): void {
        this.templateSelectionUpdateNotifierSubscription.unsubscribe();
    }

    private templateUpdate() {
        // On bascule sur le volet client quand le template appliqué change
        this.displayedContent = ContentType.Client;
    }

    quit(): void {
        void this.router.navigate(['/dashboard']);
    }

    deleteTemplate($event: number): void {
        this.templateService.deleteTemplate($event, this.entiteJuridiqueId).subscribe({
            next: templates => {
                this.snackbar.open(this.translateService.instant('REPORTS.REPORT.TEMPLATE_DELETION_FEEDBACK_SUCCESS'), null, { duration: 1500 });
                this.templates = templates;
                this.templateService.notifyTemplateListUpdate(templates);
            },
            error: _ => {
                this.snackbar.open(this.translateService.instant('REPORTS.REPORT.TEMPLATE_DELETION_FEEDBACK_FAILURE'), null, { duration: 1500 });
            }
        });
    }

    saveTemplate($event: Template): void {
        const templateData = $event.hasPost();

        // TODO: Gestion du layout sur terminaux mobiles
        const templateRecordingDialog = this.dialog.open(TemplateRecordingDialogComponent, {
            width: '500px',
            data: {
                template: templateData
            }
        });

        templateRecordingDialog.afterClosed().subscribe(result => {
            if (result?.template) {
                this.templateService.saveTemplate(result.template, this.entiteJuridiqueId).subscribe({
                    next: templates => {
                        this.snackbar.open(this.translateService.instant('REPORTS.REPORT.TEMPLATE_RECORDING_FEEDBACK_SUCCESS'), null, { duration: 1500 });
                        this.templates = templates;
                        this.templateService.notifyTemplateListUpdate(templates);
                    },
                    error: _ => {
                        this.snackbar.open(this.translateService.instant('REPORTS.REPORT.TEMPLATE_RECORDING_FEEDBACK_FAILURE'), null, { duration: 1500 });
                    }
                });
            }
        });
    }

    sendEmail(report: ReportPostInterface): void {
        if (!this.rendezVous.client.mail) {
            this.snackbar.open(this.translateService.instant('REPORTS.ERROR.CLIENT_NO_MAIL'), this.translateService.instant('SHARED.OK'));
            return;
        }

        this.disabledEmailSendingButton = true;

        const datePipe = new DateLocalePipe(this.translateService);
        const date = datePipe.transform(this.rendezVous.date, 'shortDate');

        const emailData: EmailDataInterface = {
            content: this.getEmailContent(date, this.rendezVous.veterinaire.fullNameLite),
            report
        };

        const emailBuidlingDialog = this.dialog.open(EmailBuildingDialogComponent, {
            width: '700px',
            data: { emailData },
            disableClose: true
        });
        emailBuidlingDialog.afterClosed().subscribe(result => {
            if (result) {
                this.reportService.sendByEmail(result).subscribe({
                    next: report => {
                        if (report instanceof Report && report.id !== null) {
                            if (report.target === ReportTarget.Client) {
                                this.clientReport.sendingDate = report.sendingDate;
                            } else if (report.target === ReportTarget.Vet) {
                                this.vetReport.sendingDate = report.sendingDate;
                            }

                            this.snackbar.open(this.translateService.instant('REPORTS.REPORT.EMAIL_SENDING_FEEDBACK_SUCCESS'), null, { duration: 1500 });
                        } else {
                            this.snackbar.open(this.translateService.instant('REPORTS.REPORT.EMAIL_SENDING_FEEDBACK_FAILURE'), null, { duration: 1500 });
                        }
                    },
                    error: _error => {
                        this.snackbar.open(this.translateService.instant('REPORTS.REPORT.EMAIL_SENDING_FEEDBACK_FAILURE'), null, { duration: 1500 });
                    },
                    complete: () => {
                        this.disabledEmailSendingButton = false;
                    }
                });
            } else {
                this.disabledEmailSendingButton = false;
            }
        });
    }

    saveReport($event: Report): void {
        this.snackbar.open(this.translateService.instant('REPORTS.REPORT.REPORT_RECORDING_IN_PROGRESS'), null, { duration: 1500 });

        this.reportService.saveReport($event.hasPost()).subscribe({
            next: (report: Report) => {
                if (report.target === ReportTarget.Client) {
                    this.clientReport = report;
                } else if (report.target === ReportTarget.Vet) {
                    this.vetReport = report;
                }
            },
            error: _ => {
                this.snackbar.open(this.translateService.instant('REPORTS.REPORT.REPORT_RECORDING_FEEDBACK_FAILURE'), null, { duration: 1500 });
            }
        });
    }

    private getEmailContent(date: string, vet: string): string {
        const l1: string = this.translateService.instant('REPORTS.REPORT.EMAIL_DEFAULT_CONTENT_LINE1');
        const l2: string = this.translateService.instant('REPORTS.REPORT.EMAIL_DEFAULT_CONTENT_LINE2');
        const l3: string = this.translateService.instant('REPORTS.REPORT.EMAIL_DEFAULT_CONTENT_LINE3');
        return `${l1}\n\n${l2} ${date}.\n\n${l3}\n\nDr ${vet}.`;
    }
}

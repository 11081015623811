import { Component, OnInit } from '@angular/core';
import { DialogComponent } from '../../../shared/view-utils/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VisioService } from '../../../../services/visio.service';
import { Session, Subscriber } from '@opentok/client';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RendezVousService } from 'app/services/api/rendez-vous.service';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { VisiocareDialogComponent } from '../../../shared/view-utils/visiocare-dialog/visiocare-dialog.component';
import { ChatService } from '../../../../services/api/chat.service';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import {
    VisioChargeStatut,
    VisioPaymentChargeDialogComponent,
    VisioPaymentChargeDialogData
} from '../visio-payment-charge-dialog/visio-payment-charge-dialog.component';

@Component({
    selector: 'app-visio-action',
    templateUrl: './visio-action.component.html',
    styleUrls: ['./visio-action.component.scss']
})

export class ActionComponent implements OnInit {
    session: Session;
    silent = false;
    record = false;

    recordInProgress: boolean;

    constructor(
        private dialog: MatDialog,
        public utilisateurService: UtilisateurService,
        public rendezVousService: RendezVousService,
        public visioService: VisioService,
        public chatService: ChatService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.visioService.currentSession.pipe(filter(session => Boolean(session))).subscribe((session: Session) => {
            this.session = session;
        });
    }

    hangIn(): void {
        if (this.visioService.currentStatutObject === 'loading') {
            return;
        }

        if (this.visioService.currentStatutObject === 'idle' || this.visioService.currentStatutObject === 'finished') {
            this.visioService.connectAndCall();
        } else {
            this.visioService.recall();
        }
    }

    hangOut(): void {
        if (this.visioService.currentStatutObject === 'loading') {
            return;
        }

        if (this.visioService.currentStatutObject === 'ringing') {
            this.visioService.hangout();
        } else {
            const dialog = this.dialog.open(DialogComponent, {
                width: '350px',
                data: {
                    title: this.translateService.instant('VISIO.FINISH_SESSION'),
                    content: this.translateService.instant('VISIO.ASK_FINISH_CONV'),
                    action: true,
                    cancel: this.translateService.instant('SHARED.CANCEL'),
                    ok: this.translateService.instant('VISIO.FINISH')
                },
                disableClose: true
            });

            dialog.afterClosed().subscribe(res => {
                if (res) {
                    this.visioService.disconnect();
                }
            });
        }
    }

    toggleSound(): void {
        this.silent = !this.silent;

        this.visioService.currentSubscribers.subscribe((subscribers: Subscriber[]) => {
            if (subscribers) {
                subscribers.forEach((subscriber: Subscriber) => {
                    if (this.silent) {
                        subscriber.setAudioVolume(0);
                    } else {
                        subscriber.setAudioVolume(100);
                    }
                });
            }
        });
    }

    toggleRecord(): void {
        if (this.recordInProgress) {
            if (this.record) {
                this.visioService.stopRecording().subscribe(() => {
                    this.record = false;
                });
            } else {
                this.visioService.startRecording().subscribe(() => {
                    this.record = true;
                });
            }
        }
    }

    createCharge(): void {
        const rendezVous = this.visioService.currentRendezVousObject;
        const data: VisioPaymentChargeDialogData = {
            rdvId: rendezVous.id,
            prix: rendezVous.prix,
            currency: rendezVous.currency
        };
        const dialogRef = this.dialog.open(VisioPaymentChargeDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '30vw',
            disableClose: true
        });

        dialogRef.componentInstance.onAskCharge.subscribe(() => {
            dialogRef.componentInstance.state = VisioChargeStatut.sendingSignal;
            this.visioService.currentSessionObject.signal(
                {
                    type: 'payment'
                },
                error => {
                    if (error) {
                        dialogRef.componentInstance.state = VisioChargeStatut.error;
                    } else {
                        dialogRef.componentInstance.state = VisioChargeStatut.signalSent;
                        this.visioService.currentSessionObject.on('signal:paymentCallback' as any, () => {
                            this.visioService.currentSessionObject.off('signal:paymentCallback' as any);
                            dialogRef.componentInstance.state = VisioChargeStatut.clientCallback;
                            this.rendezVousService.getById(rendezVous.id).subscribe((r: RendezVous) => {
                                if (r) {
                                    this.visioService.setCurrentRendezVous(r);
                                    if (r.mustPay) {
                                        dialogRef.componentInstance.state = VisioChargeStatut.stillUnpaid;
                                    } else {
                                        dialogRef.componentInstance.state = VisioChargeStatut.apiChecked;
                                    }
                                } else {
                                    dialogRef.componentInstance.state = VisioChargeStatut.error;
                                }
                            });
                        });
                    }
                }
            );
        });
    }

    togglePublishVideo(): void {
        if (this.visioService.currentStreamingFileUrlObject) {
            this.visioService.stopPublishFile();
        } else {
            this.openMediProdModal();
        }
    }

    openMediProdModal(): void {
        this.dialog.open(VisiocareDialogComponent, {
            panelClass: 'no-padding-dialog',
            disableClose: true,
            data: {
                for: 'visio'
            },
            width: '600px',
            maxWidth: '95vw',
            height: '600px'
        }).afterClosed().subscribe(res => {
            if (res?.link && res?.type) {
                this.visioService.startPublishFile(res.type, res.link);
            }
        });
    }

    sendLinkToChat(): void {
        const link = this.visioService.currentStreamingFileObject?.url;
        if (link) {
            this.chatService.sendMessage(link).subscribe();
        }
    }
}

<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'FORM.' + (isForPhoneCountryCode ? 'PHONE_COUNTRY_CODE' : 'COUNTRY') | translate }}</mat-label>
    <mat-select
        [placeholder]="'FORM.' + (isForPhoneCountryCode ? 'PHONE_COUNTRY_CODE' : 'COUNTRY') | translate"
        [formControl]="control | asFormControl"
        [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="paysFilter"
                [placeholderLabel]="'FORM.' + (isForPhoneCountryCode ? 'PHONE_COUNTRY_CODE' : 'COUNTRY') | translate"
                [noEntriesFoundLabel]="'FORM.NO_RESULT' | translate">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let pays of filteredPaysOptions$ | async" [value]="pays.code">
            {{ pays.flag }} {{ isForPhoneCountryCode ? pays.phoneCode + ' - ' : '' }} {{ pays.name }}
        </mat-option>
    </mat-select>
    <mat-error *ngIf="control.hasError('required')">
        {{ 'FORM.' + (isForPhoneCountryCode ? 'PHONE_COUNTRY_CODE_REQUIRED' : 'COUNTRY_REQUIRED') | translate }}
    </mat-error>
</mat-form-field>

<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="8px">
    <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{ 'CONNECT_IMPORT.INPUT.LABEL' | translate }} {{ loading ? '- ' + ('SHARED.LOADING' | translate) : '' }}</mat-label>
        <mat-select [placeholder]="placeholder" [formControl]="control">
            <mat-option>
                <ngx-mat-select-search
                    [formControl]="filterControl"
                    [placeholderLabel]="'SHARED.SEARCH' | translate"
                    [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate"
                    [searching]="loading">
                </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let data of (filteredOptions$ | async)" [value]="data">
                {{ data.toString() }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <button mat-button type="button" [disabled]="!this.control.value"
            (click)="emitChoosen()">{{ 'CONNECT_IMPORT.INPUT.IMPORT_BUTTON' | translate }}</button>
</div>

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { VeterinairesDataSource } from 'app/datasources/veterinaire.data-source';
import { Veterinaire } from '../../../models/utilisateurs/veterinaire';
import { ConnecteurService } from '../../../services/api/connecteur.service';
import { Client } from '../../../models/utilisateurs/client';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';

@Component({
    selector: 'app-veterinaires',
    templateUrl: './veterinaires.component.html',
    styleUrls: ['./veterinaires.component.scss'],
    animations: fuseAnimations
})
export class VeterinairesComponent implements OnInit, AfterViewInit, OnDestroy {
    dataSource?: VeterinairesDataSource;
    displayedColumns = ['fullName', 'username', 'entiteGeographique', 'lastConnection', 'actions'];

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    isOwner: boolean;
    utilisateurConnectedId: number;

    private utilisateurConnectedSubscription: Subscription;

    constructor(public utilisateurService: UtilisateurService, public connecteurService: ConnecteurService,
                private entiteJuridiqueService: EntiteJuridiqueService) {}

    ngOnInit(): void {
        this.utilisateurConnectedSubscription = this.utilisateurService.utilisateurConnected.subscribe(u => {
            this.isOwner = u.isProprietaire;
            this.utilisateurConnectedId = u.id;
        });

        this.dataSource = new VeterinairesDataSource(this.utilisateurService);
        this.dataSource.loadVeterinaires(null, 'asc', 'nom', 0, 20);
    }

    ngOnDestroy(): void {
        if (this.dataSource) {
            this.dataSource.disconnect();
        }

        if (this.utilisateurConnectedSubscription) {
            this.utilisateurConnectedSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadVeterinaires();
        });

        this.paginator.page.subscribe((event: PageEvent) => {
            if (event.previousPageIndex === event.pageIndex) {
                this.paginator.pageIndex = 0;
            }

            this.loadVeterinaires();
        });

        fromEvent(this.filter.nativeElement, 'keyup').pipe(
            distinctUntilChanged(),
            debounceTime(150)
        ).subscribe(() => {
            this.paginator.pageIndex = 0;
            this.loadVeterinaires();
        });
    }

    loadVeterinaires(): void {
        this.dataSource.loadVeterinaires(
            this.filter.nativeElement.value,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize
        );
    }

    openVeterinaire(veterinaire: Veterinaire = null): void {
        const isNew = veterinaire === null;

        if (!veterinaire) {
            veterinaire = new Veterinaire();
            veterinaire.entiteGeographique = this.utilisateurService.utilisateurConnectedValue.entiteGeographique;
            veterinaire.locale = this.utilisateurService.utilisateurConnectedValue.locale;
            veterinaire.timezone = this.utilisateurService.utilisateurConnectedValue.timezone;
        }

        this.utilisateurService.openDialogVeterinaire(veterinaire, this.utilisateurConnectedId !== veterinaire.id && !this.isOwner)
            .afterClosed().subscribe((result: Veterinaire) => {
                if (result) {
                    if (isNew) {
                        this.dataSource.add(result);
                    } else {
                        this.dataSource.replace(result);
                    }
                } else if (result === null) {
                    this.dataSource.delete(veterinaire);
                }
            });
    }

    resendNotificationAccountCreated(client: Client): void {
        this.utilisateurService.resendNotificationAccountCreated(client.id, 'veterinaire').subscribe();
    }

    removeVeterinaire(veterinaire: Veterinaire): void {
        this.utilisateurService.deleteVeterinary(veterinaire.id, this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id).subscribe(() => {
            this.dataSource.delete(veterinaire);
        });
    }

    trackByFn(_index: number, item: Veterinaire): number {
        return item.id;
    }
}

import { CacheKey, Deserializer, Serializer } from 'json-object-mapper';

@CacheKey('DateMapper')
export class DateMapper implements Deserializer, Serializer {
    private readonly timezone = 0; // new Date().getTimezoneOffset() * 60;

    deserialize = (value: number): Date => {
        return new Date((value - this.timezone) * 1000);
    };

    serialize = (value: Date): number => {
        return value.getTime();
    };
}

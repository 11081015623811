<div class="page-layout simple fullwidth p-16">

    <!-- CENTER -->
    <div class="center centered-content">

        <!-- HEADER -->
        <div class="header secondary-text"
            fxLayout="column"
            fxLayoutAlign="center center"
            fxLayout.gt-xs="row"
            fxLayoutAlign.gt-xs="space-between center">

            <!-- APP TITLE -->
            <div class="logo my-12 m-sm-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon mr-16">settings</mat-icon>
                <span class="logo-text h1">{{ 'CONNECTOR.TITLE' | translate }}</span>
            </div>
            <!-- / APP TITLE -->

        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <p>{{ 'CONNECTOR.HELP' | translate }}</p>

            <!-- Cas 1 : Pas de connecteur actif -> Liste -->
            <div *ngIf="!(visibleConnecteur | async) && !connecteurEnabled">
                <div class="connectors" *ngIf="connecteurs$ | async as connecteurs; else loading">
                    <div class="connector" *ngFor="let connecteur of connecteurs"
                         (click)="setVisibleConnecteur(connecteur)"
                         [class.unavailable]="connecteur.status === 'unavailable'"
                         [class.coming-soon]="connecteur.status === 'coming_soon'">
                        <div class="coming-soon-badge" *ngIf="connecteur.status === 'coming_soon'">{{ 'CONNECTOR.COMING_SOON' | translate }}</div>
                        <ng-container *ngIf="connecteur.logo && connecteur.logo.light != ''; else empty">
                            <img [src]="connecteur.logo.light" alt="Logo {{ connecteur.name }}" [class.logo-light]="connecteur.logo.dark">
                            <img *ngIf="connecteur.logo.dark" [src]="connecteur.logo.dark" alt="Logo {{ connecteur.name }}" class="logo-dark">
                        </ng-container>
                        <ng-template #empty>
                            <div></div>
                        </ng-template>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <div fxFlex="1 1 auto">
                                <h3>{{ connecteur.name }}</h3>
                                <p>{{ connecteur.description }}</p>
                            </div>
                            <mat-icon *ngIf="connecteur.status === 'available'">arrow_forward</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Cas 2 : Connecteur actif -> Connecteur -->
            <app-connector-detail
                *ngIf="visibleConnecteur | async"
                [enabled]="connecteurEnabled"
                [connecteur]="visibleConnecteur | async"
                [apiKey]="apiKey"
                (returnToList)="setVisibleConnecteur(null)"
                (needsRefresh)="refresh($event)"
            ></app-connector-detail>

            <ng-template #loading>
                <div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;">
                    <mat-spinner mode="indeterminate" color="accent" [diameter]="40"></mat-spinner>
                    <span class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</span>
                </div>
            </ng-template>
        </div>
        <!-- / CONTENT CARD -->
    </div>
    <!-- / CENTER -->
</div>

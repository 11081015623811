import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { UtilisateurPostInterface } from '../../../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-user-change-password-form',
    templateUrl: './user-change-password-form.component.html',
    styleUrls: ['./user-change-password-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserChangePasswordFormComponent implements OnInit, OnDestroy {
    @Output('formSubmited') formSubmited$ = new EventEmitter<boolean>();
    formInProgress$ = new BehaviorSubject(false);

    form: FormGroup;

    private subscriptionEntityData: Subscription;

    hidePassword = true;
    hidePasswordConfirm = true;

    constructor(
        public utilisateurService: UtilisateurService,
        private cdRef: ChangeDetectorRef
    ) {
        this.form = new FormGroup({
            id: new FormControl(),
            password: new FormControl(null, [Validators.required]),
            passwordConfirm: new FormControl(null, [Validators.required])
        });

        this.form.get('id').disable();
        this.form.get('passwordConfirm').disable();

        this.form.get('password').valueChanges.subscribe(value => {
            const passwordControl = this.form.get('password');
            const passwordConfirmControl = this.form.get('passwordConfirm');

            if (value?.length) {
                this.form.setValidators([UserChangePasswordFormComponent.passwordConfirm]);
                passwordControl.setValidators([Validators.required]);
                passwordConfirmControl.enable();
                passwordConfirmControl.setValidators([Validators.required]);
            } else {
                this.form.clearValidators();
                this.form.setErrors(null);

                if (this.form.get('id').value) {
                    passwordControl.clearValidators();
                    passwordControl.setErrors(null);
                }

                passwordConfirmControl.clearValidators();
                passwordConfirmControl.setErrors(null);
                passwordConfirmControl.setValue(null);
                passwordConfirmControl.disable();
            }
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            id: this.utilisateurService.utilisateurConnectedValue.id,
            password: null,
            passwordConfirm: null
        }, { emitEvent: false });

        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        if (this.subscriptionEntityData) {
            this.subscriptionEntityData.unsubscribe();
        }
    }

    submit(): void {
        if (this.form.valid) {
            this.save(this.form.getRawValue());
        }
    }

    private save(_data: UtilisateurPostInterface): void {
        this.formInProgress$.next(true);

        const newPassword = this.form.get('password').value;

        this.utilisateurService.setPasswordUtilisateur(newPassword).subscribe({
            next: () => {
                this.formInProgress$.next(false);
                this.formSubmited$.next(true);
            },
            error: () => {
                this.formInProgress$.next(false);
                this.formSubmited$.next(true);
            }
        });
    }

    private static passwordConfirm(control: AbstractControl) {
        const passwordControl = control.get('password');
        const passwordConfirmControl = control.get('passwordConfirm');
        if (passwordConfirmControl.value === passwordControl.value) {
            passwordControl.setErrors(null);
            passwordConfirmControl.setErrors(null);
            return null;
        }

        passwordControl.setErrors({ passwordConfirm: true });
        passwordConfirmControl.setErrors({ passwordConfirm: true });
    }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ConfigService } from '../../../../../services/config.service';

@Component({
    selector: 'app-input-timezone',
    templateUrl: './input-timezone.component.html',
    styleUrls: ['./input-timezone.component.scss']
})
export class InputTimezoneComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() required: boolean;

    timezoneFilter = new FormControl();
    timezoneList: string[] = [];
    filteredTimezoneOptions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);

    private subscriptionTimezone: Subscription;

    constructor(private configService: ConfigService) {}

    ngOnInit(): void {
        this.timezoneFilter.valueChanges
            .subscribe(() => {
                const filteredPaysOptions = this.timezoneFilter.value ? this.timezoneList.filter(timezone => timezone.replace('_', ' ').toLowerCase().includes(this.timezoneFilter.value.replace('_', ' ').toLowerCase())) : this.timezoneList;

                this.filteredTimezoneOptions$.next(filteredPaysOptions);
            });

        this.subscriptionTimezone = this.configService.getTimezoneList().subscribe(value => {
            this.timezoneList = value;
            this.filteredTimezoneOptions$.next(value);
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionTimezone) {
            this.subscriptionTimezone.unsubscribe();
        }
    }
}

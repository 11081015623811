<mat-form-field appearance="outline" class="w-100-p">
    <mat-label>{{ 'PET.ANIMAL' | translate }} {{ loading ? '- ' + ('SHARED.LOADING' | translate) : '' }}</mat-label>
    <mat-select [placeholder]="'PET.ANIMAL' | translate" [formControl]="internalControl" [required]="required">
        <mat-option>
            <ngx-mat-select-search
                [formControl]="clientsFilter"
                [placeholderLabel]="'PET.CLIENT_SEARCH' | translate"
                [noEntriesFoundLabel]="'PET.CLIENT_ANY_FOUND' | translate"
                [searching]="loading">
            </ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="''" *ngIf="nullValue">{{ nullValue }}</mat-option>
        <mat-optgroup *ngFor="let client of filteredClientsOptions$ | async"
                      [label]="(client | userFullName) + (showMailTel ? (client.mail ? ' ' + client.mail : '') + (client.telephonePortable ? ' ' + client.telephonePortable : '') : '')">
            <mat-option [value]="'add-' + client.id" *ngIf="nullValueForAll">{{ nullValueForAll }}
                - {{ client.fullNameLite }}</mat-option>
            <mat-option *ngFor="let animal of client.animaux" [value]="animal.id">
                {{ animal.nom }}
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="internalControl.hasError('required')">
        {{ 'PET.ANIMAL_REQUIRED' | translate }}
    </mat-error>
</mat-form-field>

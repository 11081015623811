import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';
import { RendezVous } from '../../../../models/rendez-vous/rendez-vous';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RendezVousPostInterface } from '../../../../models/interfaces/post/rendez-vous-post.interface';
import { RendezVousService } from '../../../../services/api/rendez-vous.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-rendez-vous-dialog',
    templateUrl: './rendez-vous-dialog.component.html',
    styleUrls: ['./rendez-vous-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RendezVousDialogComponent implements OnInit, OnDestroy {
    rendezVousData$ = new BehaviorSubject<RendezVousPostInterface>(null);

    rendezVous$: BehaviorSubject<RendezVous>;
    rendezVous: RendezVous;
    loading$ = new BehaviorSubject<boolean>(false);

    event: CalendarEvent;
    hasChanged = false;

    private subscription: Subscription;

    constructor(
        private router: Router,
        public dialogRef: MatDialogRef<RendezVousDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: RendezVousDialogInterface
    ) {
        this.rendezVous$ = new BehaviorSubject(data.rendezVous);
        this.rendezVous$.pipe(
            filter(u => Boolean(u))
        ).subscribe(rendezVous => {
            this.rendezVous = rendezVous;
            this.rendezVousData$.next(rendezVous.hasPost());
        });
    }

    ngOnInit(): void {
        if (this.rendezVous?.id) {
            this.loading$.next(true);
            this.subscription = this.data.rendezVousService.getById(this.rendezVous.id).subscribe({
                next: (rdv: RendezVous) => {
                    this.rendezVous$.next(rdv);
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close(this.rendezVous)
            });
        }
    }

    close(deleted = false): void {
        if (deleted) {
            this.dialogRef.close('deleted');
        } else {
            this.dialogRef.close(this.hasChanged ? this.rendezVous$.value : false);
        }
    }

    call(): void {
        this.dialogRef.close('called');
        void this.router.navigate(['/visio', this.rendezVous.id]);
    }

    capture(): void {
        this.data.rendezVousService.openDialogCaptureRendezVous(this.rendezVous).afterClosed().subscribe((updatedRendezVous: RendezVous) => {
            if (updatedRendezVous) {
                this.rendezVous$.next(updatedRendezVous);
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}

export interface RendezVousDialogInterface {
    rendezVous: RendezVous;
    rendezVousService: RendezVousService;
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import { filter } from 'rxjs/operators';
import { EntiteJuridique } from '../../../models/pro/entite-juridique';
import { EntiteJuridiqueSettingsPostInterface } from '../../../models/interfaces/post/pro/entite-juridique-settings-post.interface';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: fuseAnimations
})
export class SettingsComponent implements OnInit, OnDestroy {
    entiteJuridiqueData$ = new BehaviorSubject<EntiteJuridiqueSettingsPostInterface>(null);
    entiteJuridique$ = new BehaviorSubject<EntiteJuridique>(null);
    entiteJuridique: EntiteJuridique;

    loading$ = new BehaviorSubject<boolean>(false);

    isOwner: boolean;

    private subscriptionUtilisateur: Subscription;
    private subscriptionEntiteJuridique: Subscription;

    constructor(
        public utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {
        this.entiteJuridique$.pipe(
            filter(u => Boolean(u))
        ).subscribe(entiteJuridique => {
            this.entiteJuridique = entiteJuridique;
            this.entiteJuridiqueData$.next(entiteJuridique.configHasPost());
        });
    }

    ngOnInit(): void {
        this.subscriptionUtilisateur = this.utilisateurService.utilisateurConnected.subscribe(u => {
            this.isOwner = u.isProprietaire;

            if (u.entiteJuridique.isFull) {
                this.entiteJuridique$.next(u.entiteJuridique);
                this.loading$.next(false);
            } else {
                this.loading$.next(true);
                this.subscriptionEntiteJuridique = this.entiteJuridiqueService.getEntiteJuridiqueById(u.entiteJuridique.id).subscribe({
                    next: d => {
                        this.entiteJuridique$.next(d);
                        this.loading$.next(false);
                    },
                    error: () => this.loading$.next(false)
                });
            }
        });
    }

    ngOnDestroy(): void {
        if (this.subscriptionUtilisateur) {
            this.subscriptionUtilisateur.unsubscribe();
        }

        if (this.subscriptionEntiteJuridique) {
            this.subscriptionEntiteJuridique.unsubscribe();
        }
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{ 'VETO.FORCE_UPDATE_DIALOG.TITLE' | translate }}</span>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
            <app-loading></app-loading>
        </div>

        <ng-template #form>
            <div [class.force-update-single]="utilisateur.passMustChange !== utilisateur.cguMustConfirm">
                <mat-horizontal-stepper linear #stepper>
                    <mat-step *ngIf="utilisateur.passMustChange" [completed]="changePasswordValid" editable="false">
                        <ng-template matStepLabel>{{ 'VETO.FORCE_UPDATE_DIALOG.MUST_UPDATE_PWD' | translate }}</ng-template>
                        <app-user-change-password-form (formSubmited)="onPasswordStatusChanged($event, stepper)"></app-user-change-password-form>
                    </mat-step>
                    <mat-step *ngIf="utilisateur.cguMustConfirm" [completed]="cguValid" editable="false">
                        <ng-template matStepLabel>{{ 'VETO.FORCE_UPDATE_DIALOG.MUST_UPDATE_CGU' | translate }}</ng-template>
                        <app-user-accept-cgu-form (formSubmited)="onCguStatusChanged($event, stepper)"></app-user-accept-cgu-form>
                    </mat-step>
                    <!-- <mat-step [completed]="changePasswordValid && cguValid" editable="false">
                        <ng-template matStepLabel>{{ 'VETO.FORCE_UPDATE_DIALOG.DONE' | translate }}</ng-template>
                        <p>
                            {{ 'VETO.FORCE_UPDATE_DIALOG.DONE_SPEECH' | translate }}
                        </p>
                        <div fxLayout="row wrap" fxLayoutAlign="end center">
                            <button mat-button (click)="close()">{{ 'SHARED.OK' | translate }}</button>
                        </div>
                    </mat-step> -->
                </mat-horizontal-stepper>
            </div>
        </ng-template>
    </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Utilisateur } from '../models/utilisateurs/utilisateur';
import { UtilisateurPostInterface } from '../models/interfaces/post/utilisateurs/utilisateur-post.interface';

@Pipe({
    name: 'userFullName'
})
export class UserFullNamePipe implements PipeTransform {
    transform(utilisateur: Utilisateur | UtilisateurPostInterface, lite = false): string {
        if (utilisateur) {
            if (utilisateur instanceof Utilisateur) {
                return lite ? utilisateur.fullNameLite : utilisateur.fullName;
            }

            return (!lite && utilisateur.civilite ? utilisateur.civilite + ' ' : '') +
                (utilisateur.prenom ? utilisateur.prenom + ' ' : '') +
                utilisateur.nom;
        }

        return '';
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DateAdapter } from '@angular/material/core';

@Pipe({
    name: 'firstDayWeek'
})
export class FirstDayWeekPipe implements PipeTransform {
    constructor(private adapter: DateAdapter<any>) {}

    transform(_notused?: string): number {
        return this.adapter.getFirstDayOfWeek();
    }
}

<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
                <span class="title dialog-title">{{ 'SHARED.DIALOG_CAPTURE.TITLE' | translate }}</span>
            </div>
            <button mat-icon-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0" fxLayout="column" fxLayoutAlign="start center">
        <img src="/assets/images/empty-state-card.svg"/>
        <p>{{ 'SHARED.DIALOG_CAPTURE.SPEECH' | translate }}</p>
        <div class="mb-16">{{ 'SHARED.DIALOG_CAPTURE.CAPTURE_PRICE_ORIGINAL' | translate }} :
            <b>{{ data.rendezVous.prix | currencyLocale:data.rendezVous.currency }}</b></div>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'SHARED.DIALOG_CAPTURE.CAPTURE_PRICE' | translate }}</mat-label>
            <input matInput [formControl]="priceControl" [options]="getCurrencyInputOptions()"
                   currencyMask [max]="data.rendezVous.prix" placeholder="{{ data.rendezVous.prix }}">
            <mat-error *ngIf="!priceControl.valid">
                {{ 'SHARED.DIALOG_CAPTURE.CAPTURE_PRICE_ERROR' | translate:{price: data.rendezVous.prix} }}
            </mat-error>
        </mat-form-field>
        <div *ngIf="showFees" class="fees">
            <div class="fee">
                <span class="title">{{ 'SHARED.DIALOG_CAPTURE.TO_CAPTURE' | translate }}</span>
                <span class="price">{{ priceControl.value | currencyLocale:data.rendezVous.currency }}</span>
            </div>
            <div class="fee">
                <span class="title">{{ 'SHARED.DIALOG_CAPTURE.LINKYVET_TAX' | translate }}</span>
                <span class="price">{{ priceControl.value | priceAfterTaxe:true | async | currencyLocale:data.rendezVous.currency }}</span>
            </div>
            <div class="fee total">
                <span class="title">{{ 'SHARED.TOTAL' | translate }}</span>
                <span class="price">{{ priceControl.value | priceAfterTaxe | async | currencyLocale:data.rendezVous.currency }}</span>
            </div>
        </div>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="8px" fxFlex="1 1 auto"
             class="w-100-p mt-24">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button mat-button color="warn"
              (click)="cancel()">
              {{ 'SHARED.DIALOG_CAPTURE.DONT_CAPTURE' | translate }}
            </button>
          </div>

          <button mat-raised-button color="accent"
            (click)="capture()"
            [disabled]="!priceControl.valid">
            {{ 'SHARED.DIALOG_CAPTURE.CAPTURE' | translate }}
          </button>

          <mat-spinner mode="indeterminate" [diameter]="20" color="primary" *ngIf="loading"></mat-spinner>
        </div>
    </div>
</div>

<div id="onboarding-container" fxLayout="column" class="p-16 w-100-p">
    <div fxLayout="row" fxLayoutAlign="center start" class="mb-64">
        <button mat-stroked-button class="back-button" (click)="onBackClicked()">
            <mat-icon matPrefix>arrow_back</mat-icon> {{ 'ONBOARDING_PRO.BACK' | translate }}
        </button>
        <div class="mt-64">
            <div id="splash-screen-logo-light" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical.svg" alt="Logo Univet">
            </div>

            <div id="splash-screen-logo-dark" class="logo">
                <img width="128" src="/assets/images/logos/logo-vertical-dark.svg" alt="Logo Univet">
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center start" fxFlex="1 1 auto">
        <mat-card *ngIf="!isValidUser" fxLayout="row" fxLayoutAlign="center center" class="p-32">
            <mat-spinner color="accent"></mat-spinner>
        </mat-card>

        <form *ngIf="isValidUser" [formGroup]="form" (submit)="submit()" (keydown.enter)="$event.preventDefault()">
            <mat-card>
                <mat-card-content>
                    <div fxLayout="column" class="pl-24 pr-24">
                        <div class="step-header">
                            <h1 [innerHTML]="'ONBOARDING_PRO_PLANS.TITLE' | translate:{ 'clinic': cliniqueName }"></h1>
                            <p [innerHTML]="'ONBOARDING_PRO_PLANS.SUBTITLE' | translate"></p>

                            <p *ngIf="messageToUser" [innerHTML]="messageToUser | translate" class="text-bold mb-32 message-to-user"></p>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between start" class="mb-8" fxLayoutGap="8px">
                            <mat-button-toggle-group formControlName="monthly" class="mt-4 h-60">
                                <mat-button-toggle [value]="true" fxLayout="row">
                                    {{ 'ONBOARDING_PRO_PLANS.MONTHLY' | translate }}<br>
                                    <span class="toggle-price">{{priceMonthly}} €{{ 'ONBOARDING_PRO_PLANS.PER_MONTH' | translate }}</span>
                                </mat-button-toggle>
                            </mat-button-toggle-group>

                            <mat-form-field class="coupon-container" appearance="outline" fxFlex="1 1 auto">
                                <mat-label>{{ 'ONBOARDING_PRO_PLANS.COUPON.TITLE' | translate }}</mat-label>
                                <input matInput [placeholder]="'ONBOARDING_PRO_PLANS.COUPON.PLACEHOLDER' | translate" formControlName="coupon" type="text" (keyup.enter)="checkCoupon()">

                                <button *ngIf="!!stripeCouponRaw" (click)="resetCoupon()" class="coupon-button-reset" type="button" mat-icon-button>
                                    <mat-icon>clear</mat-icon>
                                </button>

                                <button *ngIf="!stripeCouponRaw" (click)="checkCoupon()" class="coupon-button" type="button" mat-raised-button color="accent" [disabled]="couponLoading || !form.get('coupon').value">
                                    <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                                        <div>
                                            {{ 'ONBOARDING_PRO_PLANS.COUPON.USE' | translate }}
                                        </div>
                                        <mat-icon *ngIf="!couponLoading" matSuffix class="ml-8">confirmation_number</mat-icon>
                                        <mat-spinner *ngIf="couponLoading" matSuffix [diameter]="24" class="ml-8"></mat-spinner>
                                    </div>
                                </button>
                            </mat-form-field>
                        </div>

                        <p class="coupon-desc" *ngIf="stripeCoupon?.metadata?.description">{{ stripeCoupon?.metadata?.description }}</p>

                        <div fxLayout="row" fxLayoutAlign="space-around start">
                            <div fxLayout="column" fxLayoutAlign="start center" class="plan-detail">
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.MONTHLY' | translate"></p>
                                <p>{{priceMonthly}} €<span class="small">{{ 'ONBOARDING_PRO_PLANS.PER_MONTH' | translate }}</span></p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="start center" class="plan-detail">
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.TCHAT' | translate"></p>
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.TCHAT_CONTENT' | translate"></p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="start center" class="plan-detail">
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.VISIO' | translate"></p>
                                <p *ngIf="stripeCoupon?.metadata?.visioFreeHours > 0">
                                    {{stripeCoupon?.metadata?.visioFreeHours}}{{ 'ONBOARDING_PRO_PLANS.VISIO_FREE' | translate }}</p>
                                <p class="visio-content">
                                    <span *ngIf="stripeCoupon?.metadata?.visioFreeHours > 0; else visioContent" [innerHTML]="'ONBOARDING_PRO_PLANS.VISIO_THEN' | translate:{ content: ('ONBOARDING_PRO_PLANS.VISIO_CONTENT' | translate)}"></span>
                                    <ng-template #visioContent>
                                        <span [innerHTML]="'ONBOARDING_PRO_PLANS.VISIO_CONTENT' | translate"></span>
                                    </ng-template>
                                </p>
                            </div>
                            <div fxLayout="column" fxLayoutAlign="start center" class="plan-detail">
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.STOCKAGE' | translate"></p>
                                <p [innerHTML]="'ONBOARDING_PRO_PLANS.STOCKAGE_CONTENT' | translate"></p>
                            </div>
                        </div>

                        <h3 class="option-title">{{ 'ONBOARDING_PRO_PLANS.LICENSES.TITLE' | translate:{nbLicences: nbLicences} }}</h3>
                        <p class="option-description">
                            {{ 'ONBOARDING_PRO_PLANS.LICENSES.DESCRIPTION_MONTHLY' | translate:{ price: priceAdditionalLicenseMonthly } }}
                            <span class="free"
                                  *ngIf="this.stripeCoupon?.metadata?.licences">{{ 'ONBOARDING_PRO_PLANS.LICENSES.FREE' | translate:{
                                nbInit: 2,
                                nb: this.stripeCoupon.metadata.licences
                            } }}</span>
                        </p>

                        <div fxLayout="column" class="pl-24 pr-24 mb-32 mt-32">
                            <mat-slider
                                formControlName="numberOfLicenses"
                                class="ml-16 mr-16"
                                [displayWith]="formatLicenses"
                                [min]="nbLicences"
                                max="50"
                                (input)="onMatSliderInput($event)">
                            </mat-slider>
                            <div fxLayout="row" fxLayoutAlign="space-between start">
                                <div>{{ 'ONBOARDING_PRO_PLANS.LICENSES.MIN' | translate }}</div>
                                <div>{{ 'ONBOARDING_PRO_PLANS.LICENSES.MAX' | translate }}</div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-divider></mat-divider>
                <mat-card-actions fxLayout="column">

                    <div fxLayout="row" fxLayoutAlign="center center" class="mt-16">
                        <div *ngIf="pricePerPaymentAfterCoupon < pricePerPayment" fxLayout="row" fxLayoutAlign="center end" class="mr-8">
                            <span class="price-before-coupon">{{ pricePerPayment | numberLocale }}€</span>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center end">
                            <span
                                class="price">{{ pricePerPaymentAfterCoupon === 0 ? 0 : (pricePerPaymentAfterCoupon | numberLocale) }}
                                €</span>
                            <span class="price-detail">{{ (form.get('monthly').value ? 'ONBOARDING_PRO_PLANS.PER_MONTH' : 'ONBOARDING_PRO_PLANS.PER_YEAR') | translate }}</span>
                        </div>
                    </div>

                    <div *ngIf="!form.get('monthly').value && dateCouponEnd" fxLayout="row" fxLayoutAlign="center center" class="mt-0">
                        <span class="price-detail-year grey-500-fg">
                            {{ 'ONBOARDING_PRO_PLANS.COUPON.EXPIRES_YEAR' | translate:{ price: pricePerPayment, date: dateCouponEnd } }}
                        </span>
                    </div>

                    <div *ngIf="form.get('monthly').value && dateCouponEnd" fxLayout="row" fxLayoutAlign="center center" class="mt-0">
                        <span class="price-detail-year grey-500-fg">
                            {{ 'ONBOARDING_PRO_PLANS.COUPON.EXPIRES_MONTH' | translate:{ price: pricePerPayment, date: dateCouponEnd } }}
                        </span>
                    </div>

                    <p *ngIf="!!stripeCoupon && priceToPayNowHT == pricePerPayment" class="text-bold text-center message-to-user">
                        {{ 'ONBOARDING_PRO_PLANS.COUPON.WITHOUT_EFFECT' | translate }}
                    </p>
                    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-16">
                        <div class="conditions">
                            <mat-checkbox formControlName="conditionsAccepted">
                                <span
                                    [innerHTML]="'ONBOARDING_PRO_PLANS.CGU_CGV' | translate:{linkCGUV: linkCGUV}"></span>
                            </mat-checkbox>

                            <mat-checkbox *ngIf="stripeCoupon" formControlName="specialConditionsAccepted">
                                <span
                                    [innerHTML]="'ONBOARDING_PRO_PLANS.SPECIAL_CONDITION' | translate"></span>
                            </mat-checkbox>

                            <p class="coupon-desc" *ngIf="stripeCoupon?.metadata?.description">
                                {{ stripeCoupon?.metadata?.description }}
                            </p>
                            <p class="warn-fg" *ngIf="pricePerPaymentAfterCoupon < pricePerPayment"
                               [innerHTML]="'ONBOARDING_PRO_PLANS.COUPON_WARNING' | translate:{ price: pricePerPayment | numberLocale }"></p>
                        </div>
                        <div fxLayout="column" fxLayoutAlign="center center">
                            <button type="submit" mat-raised-button color="accent"
                                    [disabled]="formLoading || !form.valid">
                                <div fxLayout="row" fxLayoutAlign="center center" class="h-36">
                                    <div
                                        [innerHTML]="'ONBOARDING_PRO_PLANS.PAY' | translate:{ price: (priceToPayNowHT | numberLocale)}"></div>
                                    <mat-icon *ngIf="!formLoading" matSuffix class="ml-8">lock</mat-icon>
                                    <mat-spinner *ngIf="formLoading" matSuffix [diameter]="24"
                                                 class="ml-8"></mat-spinner>
                                </div>
                            </button>
                        </div>
                    </div>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
</div>

import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Client } from '../../../../models/utilisateurs/client';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClientPostInterface } from '../../../../models/interfaces/post/utilisateurs/client-post.interface';
import { filter } from 'rxjs/operators';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { AnimalService } from '../../../../services/api/animal.service';
import { Animal } from '../../../../models/animal/animal';
import { Utilisateur } from 'app/models/utilisateurs/utilisateur';

@Component({
    selector: 'app-client-dialog',
    templateUrl: './client-dialog.component.html',
    styleUrls: ['./client-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ClientDialogComponent implements OnInit, OnDestroy {
    userData$ = new BehaviorSubject<ClientPostInterface>(null);

    client$: BehaviorSubject<Utilisateur>;
    client: Client;
    loading$ = new BehaviorSubject<boolean>(false);

    createAnimal: boolean;

    hasChanged = false;

    private subscription: Subscription;

    constructor(
        public dialogRef: MatDialogRef<ClientDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ClientDialogInterface,
        private animalService: AnimalService
    ) {
        this.client$ = new BehaviorSubject(data.client);
        this.client$.pipe(
            filter(u => Boolean(u))
        ).subscribe((client: Client) => {
            this.client = client;
            this.userData$.next(client.hasPost());
        });
    }

    ngOnInit(): void {
        if (this.client?.id) {
            this.loading$.next(true);
            this.subscription = this.data.utilisateurService.getById(this.client.id).subscribe({
                next: (client: Client) => {
                    this.client$.next(client);
                    this.loading$.next(false);
                },
                error: () => this.dialogRef.close(this.client)
            });
        }
    }

    close(): void {
        if (this.createAnimal && this.client?.id) {
            const animal = new Animal();
            animal.proprietaire = this.client;
            this.animalService.openDialogAnimal(animal).afterClosed().subscribe(result => {
                this.dialogRef.close(result ? result : false);
            });
        } else {
            this.dialogRef.close(this.hasChanged ? this.client : false);
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}

export interface ClientDialogInterface {
    client: Client;
    utilisateurService: UtilisateurService;
}

import { DateMapper } from 'app/object-mapper/date-mapper';
import { JsonProperty } from 'json-object-mapper';
import { Entity } from '../entity';
import { Fichier } from '../fichier';
import { List } from '../list';
import { NewsSegment } from './news-segment';

export class News extends Entity {
    @JsonProperty()
    name: string = null;

    @JsonProperty()
    title: string = null;

    @JsonProperty()
    content: string = null;

    @JsonProperty()
    status: NewsStatus = null;

    @JsonProperty()
    callToActionText: string = null;

    @JsonProperty()
    callToActionLink: string = null;

    @JsonProperty()
    callToActionColor: string = null;

    @JsonProperty()
    image: Fichier = null;

    @JsonProperty()
    imageLink: string = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    publishedDate: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    archivedDate: Date = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    createdAt: Date = null;

    @JsonProperty()
    nbView: number = null;

    @JsonProperty()
    nbClick: number = null;

    @JsonProperty()
    nbClientsSegments: number = null;

    @JsonProperty({ type: NewsSegment })
    segments: NewsSegment[] = null;
}

export enum NewsStatus {
    DRAFT = 'draft',
    PLANNED = 'planned',
    PUBLISHED = 'published',
    ARCHIVED = 'archived'
}

export class NewsList extends List {
    @JsonProperty({ type: News })
    data: News[] = null;
}

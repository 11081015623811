<div class="dialog-content-wrapper">
    <mat-toolbar class="accent m-0 white-fg">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px" class="w-100-p">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100-p">
                    <span class="title dialog-title">{{ 'TEST.TITLE' | translate }}</span>

                    <button *ngIf="closable" mat-icon-button (click)="close()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 m-0">
        <app-connection-test></app-connection-test>
    </div>
</div>

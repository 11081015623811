import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AnimalPostInterface } from '../../../../models/interfaces/post/animal/animal-post.interface';
import { UtilisateurPostInterface } from '../../../../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { SelectorType } from '../../view-utils/document-selector-dialog/document-selector-dialog.component';
import { FichierService } from '../../../../services/api/fichier.service';

@Component({
    selector: 'app-photo-profil-form',
    templateUrl: './photo-profil-form.component.html',
    styleUrls: ['./photo-profil-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PhotoProfilFormComponent implements OnInit {
    @Input('entityData') entityData$ = new Subject<UtilisateurPostInterface | AnimalPostInterface>();
    @Input('photoDelInProgress') photoDelInProgress$ = new Observable<boolean>();
    @Input('photoInProgress') photoInProgress$ = new Observable<boolean>();
    @Input('imageEvent') imageEvent$;

    imageChangedEvent$ = new Subject<any>();

    @Input() enablePhotoForm = true;

    @Output('photoSubmited') photoSubmited$ = new EventEmitter<Blob>();

    photo: Blob;

    constructor(private fichierService: FichierService) {}

    ngOnInit(): void {
        if (this.imageEvent$) {
            this.imageEvent$.subscribe(value => {
                if (value) {
                    this.imageChangedEvent$.next(value);
                } else {
                    this.imageChangedEvent$.next(null);
                }
            });
        }
    }

    setImage(image?: Blob): void {
        this.photoSubmited$.next(image);
    }

    openImageSelector(): void {
        if (this.enablePhotoForm) {
            this.fichierService.openFileDropDialog({
                enableCropper: true,
                forceCropper: true,
                selectorType: SelectorType.IMAGE,
                selectorMultiple: false,
                settings: {
                    rounded: true,
                    aspectRatio: 1,
                    maxHeight: 128,
                    maxWidth: 128
                }
            }).subscribe((files: File[]) => {
                if (files && files.length > 0) {
                    this.setImage(files[0]);
                }
            });
        }
    }
}

<div id="login" fxLayout="row" fxLayoutAlign="start" [fxHide]="isHidden">

    <div id="login-intro" fxFlex fxHide fxShow.gt-sm>
        <div fxLayout="column" fxLayoutAlign="end end" fxLayoutGap="10px" class="intro-wrapper"
             *ngIf="!isReSignIn; else reSignInImage">
            <img src="/assets/images/home/home-{{platform}}-fr.png" alt="Univet App Screenshot" width="434"
                 height="500">
            <div class="title" [innerHTML]="'SHARED.TITLE' | translate"></div>
        </div>
        <ng-template #reSignInImage>
            <div class="reSignInImage">
                Réactiver votre compte Univet
            </div>
        </ng-template>
    </div>

    <div id="login-form-wrapper">

        <div id="login-form">
            <div class="logo-light text-center">
                <img src="/assets/images/logos/logo-vertical.svg" alt="Univet" width="200">
            </div>

            <div class="logo-dark text-center">
                <img src="/assets/images/logos/logo-vertical-dark.svg" alt="Univet" width="200">
            </div>

            <div class="register" *ngIf="isReSignIn" fxLayoutGap="8px"
                 fxLayout="column" fxLayoutAlign="center center">
                <span>Vous avez résilié votre compte Univet.</span>
                <b>Vous pouvez le réactiver avec l'identifiant et le mot de passe de votre <u>compte administrateur</u>.</b>
                <span>Vous retrouverez l'ensemble de vos données et paramètres.</span>
            </div>

            <form [formGroup]="form">

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LOGIN.IDENTIFIANT' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'LOGIN.IDENTIFIANT' | translate }}" formControlName="email"
                           type="email">
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        {{ 'LOGIN.IDENTIFIANT_REQUIRED' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!form.get('email').hasError('required') && form.get('email').hasError('normalEmailRule')">
                        {{ 'FORM.MAIL_WRONG' | translate }}
                    </mat-error>
                    <mat-hint *ngIf="emailSuggestion" class="error">
                        <span>{{ 'FORM.DID_YOU_MEAN' | translate }}</span>
                        <span (click)="form.get('email').setValue(emailSuggestion.full)"
                              [style.textDecoration]="'underline'"
                              [style.cursor]="'pointer'">{{ emailSuggestion.domain }}</span>
                        <span> ?</span>
                    </mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LOGIN.PWD' | translate }}</mat-label>
                    <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="{{ 'LOGIN.PWD' | translate }}" formControlName="password">
                    <mat-icon matSuffix class="hide-password" (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-error *ngIf="form.get('password').hasError('required')">
                        {{ 'LOGIN.PWD_REQUIRED' | translate }}
                    </mat-error>
                </mat-form-field>

                <button mat-raised-button color="primary" class="submit-button" [disabled]="loginInProgress"
                        (click)="login()">
                        {{ 'LOGIN.CONNEXION' | translate }}
                </button>

                <div *ngIf="loginInProgress" fxLayout="column" fxLayoutAlign="center center">
                    <app-loading [diameter]="40" [loadingMessage]="'SHARED.CONNECT' | translate"></app-loading>
                </div>

                <div *ngIf="error" class="warn-fg">
                    {{ error }}
                </div>

                <div class="remember-forgot-password" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                    <a class="forgot-password" [routerLink]="'/forgot-password'">
                        {{ 'LOGIN.PWD_FORGOTTEN' | translate }}
                    </a>
                </div>

            </form>

            <div class="version" [matTooltip]="apiVersion$ | async">v{{ version }} Build&nbsp;{{ buildVersion }}</div>
        </div>
    </div>
</div>

import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorIntlTranslated extends MatPaginatorIntl {
    private noElementLabel: string;
    private deLabel: string;

    itemsPerPageLabel: string;
    nextPageLabel: string;
    previousPageLabel: string;
    firstPageLabel: string;
    lastPageLabel: string;
    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return this.noElementLabel;
        }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.deLabel} ${length}`;
    };

    constructor(private translateService: TranslateService) {
        super();

        this.refreshTranslation();
        this.translateService.onLangChange.subscribe(() => this.refreshTranslation());
    }

    private refreshTranslation(): void {
        this.noElementLabel = this.translateService.instant('PAGINATOR.NO_ELEMENT') as string;
        this.deLabel = this.translateService.instant('PAGINATOR.DE') as string;
        this.itemsPerPageLabel = this.translateService.instant('PAGINATOR.ELEMENTS_PER_PAGE') as string;
        this.nextPageLabel = this.translateService.instant('PAGINATOR.NEXT_PAGE') as string;
        this.previousPageLabel = this.translateService.instant('PAGINATOR.PREVIOUS_PAGE') as string;
        this.firstPageLabel = this.translateService.instant('PAGINATOR.FIRST_PAGE') as string;
        this.lastPageLabel = this.translateService.instant('PAGINATOR.LAST_PAGE') as string;
    }
}

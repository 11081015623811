import { DateMapper } from 'app/object-mapper/date-mapper';
import { JsonProperty } from 'json-object-mapper';
import { Espece } from '../animal/espece';
import { Race } from '../animal/race';
import { Entity } from '../entity';
import { List } from '../list';
import { EntiteGeographique } from '../pro/entite-geographique';

export class NewsSegment extends Entity {
    @JsonProperty()
    name: string = null;

    @JsonProperty({ type: EntiteGeographique })
    entitesGeographiques?: EntiteGeographique[] = null;

    @JsonProperty({ type: Espece })
    especes?: Espece[] = null;

    @JsonProperty({ type: Race })
    races?: Race[] = null;

    @JsonProperty()
    ageOperator?: 'lower' | 'equal' | 'greater' = null;

    @JsonProperty()
    monthAged?: number = null;

    @JsonProperty()
    sex?: 'f' | 'm' = null;

    @JsonProperty()
    sterilized?: boolean = null;

    @JsonProperty({ type: Date, deserializer: DateMapper, serializer: DateMapper })
    updatedAt: Date = null;

    @JsonProperty()
    nbFilters: number = null;

    @JsonProperty()
    nbNews: number = null;

    @JsonProperty()
    nbClients: number = null;
}

export class NewsSegmentList extends List {
    @JsonProperty({ type: NewsSegment })
    data: NewsSegment[] = null;
}

export interface NewsSegmentStats {
    number: number;
    total: number;
}

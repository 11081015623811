import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { environment } from 'environments/environment';
import { UtilisateurService } from './utilisateur.service';
import { EntiteJuridiqueService } from './entite-juridique.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MediProdService {
    public currentUser?: MediProdUser;

    private get baseUrl(): string {
        return `${environment.mediprod.url}v${environment.mediprod.version}`;
    }

    constructor(
        private http: HttpClient,
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService
    ) {}

    public get signin(): Observable<MediProdUser> {
        if (this.currentUser) {
            return of(this.currentUser);
        }

        const userRequest: MediProdUserRequest = {
            user_id: `${this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.id}`,
            station_id: `${this.utilisateurService.utilisateurConnectedValue.id}`,
            partner: environment.mediprod.partnerName,
            language: this.utilisateurService.utilisateurConnectedValue.simpleLocale?.toUpperCase() ?? 'EN'
        };
        return this.http.post<MediProdUser>(`${this.baseUrl}/signin`, userRequest).pipe(map((u: MediProdUser) => {
            this.currentUser = u;
            return u;
        }), catchError((error: HttpErrorResponse) => {
            console.error(error);
            return this.http.post<MediProdUser>(`${this.baseUrl}/signup`, userRequest).pipe(map((u: MediProdUser) => {
                this.currentUser = u;
                return u;
            }));
        }));
    }

    public get refresh(): Observable<MediProdUser> {
        if (!this.currentUser) {
            return throwError(() => new Error('No user to refresh'));
        }

        return this.http.get(`${this.baseUrl}/refresh`, {
            headers: {
                Authorization: `Bearer ${this.currentUser.token}`
            }
        }).pipe(map((res: {status: string; token: string}) => {
            this.currentUser.token = res.token;
            return this.currentUser;
        }));
    }

    public get keywords(): Observable<string[]> {
        return this.http.post<MediProdKeywordsRequest>(`${this.baseUrl}/keywords`, {
            language: this.utilisateurService.utilisateurConnectedValue.simpleLocale?.toUpperCase() ?? 'EN'
        })
            .pipe(
                map(res => res.result)
            );
    }

    public search(keywords: string[] = []): Observable<MediProdSearchResult[]> {
        if (!this.currentUser) {
            return throwError(() => new Error('No user found. You need to be signed in to access this route'));
        }

        return this.http.post<MediProdSearchResultWrapper>(`${this.baseUrl}/search`, {
            keywords: keywords.join(',')
        }, {
            headers: {
                Authorization: `Bearer ${this.currentUser.token}`
            }
        }).pipe(map(r => r.result));
    }

    public mediaLink(media: MediProdMedia): Observable<string> {
        if (!this.currentUser) {
            return throwError(() => new Error('No user found. You need to be signed in to access this route'));
        }

        const request: MediProdMediaRequest = {
            medias: [media],
            sender_email: this.utilisateurService.utilisateurConnectedValue.mail ?? '',
            receiver_email: ''
        };
        return this.http.post<MediProdMediaResult>(`${this.baseUrl}/link/media`, request, {
            headers: {
                Authorization: `Bearer ${this.currentUser.token}`
            }
        }).pipe(map(r => r.url));
    }

    public mediaLinkRaw(media: MediProdMedia): Observable<string> {
        if (!this.currentUser) {
            return throwError(() => new Error('No user found. You need to be signed in to access this route'));
        }

        const request = {
            file_id: media.file_id,
            file_label: media.name
        };
        return this.http.post<any>(`${this.baseUrl}/media/private`, request, {
            headers: {
                Authorization: `Bearer ${this.currentUser.token}`
            }
        }).pipe(map(r => r.link as string));
    }

    public mediaPlayer(media: MediProdMedia): Observable<any> {
        if (!this.currentUser) {
            return throwError(() => new Error('No user found. You need to be signed in to access this route'));
        }

        return this.http.get(`${this.baseUrl}/playerpage/${media.file_id}`, {
            headers: {
                Accept: 'text/html',
                Authorization: `Bearer ${this.currentUser.token}`
            },
            responseType: 'text'
        });
    }
}

interface MediProdUser {
    status: string;
    token: string;
    demo: boolean;
}

interface MediProdUserRequest {
    'user_id': string;
    'station_id': string;
    partner: string;
    language: string;
}

interface MediProdKeywordsRequest {
    status: string;
    language: string;
    result: string[];
}

export interface MediProdSearchResult {
    name: string;
    type: 'animal' | 'organ' | 'folder' | 'media';
    normalizedname: string;
    data?: Array<MediProdSearchResult | MediProdMedia>;
}

interface MediProdSearchResultWrapper {
    status: string;
    keywords: string;
    result: MediProdSearchResult[];
}

export interface MediProdMedia extends MediProdSearchResult {
    'media_type': string;
    'file_id': string;
    new?: boolean;
    free?: boolean;
    loop?: boolean;
}

interface MediProdMediaRequest {
    medias: MediProdMedia[];
    'sender_email': string;
    'receiver_email': string;
}

interface MediProdMediaResult {
    status: string;
    url: string;
}

import { Pipe, PipeTransform } from '@angular/core';
import { Utilisateur } from '../models/utilisateurs/utilisateur';
import { UtilisateurPostInterface } from '../models/interfaces/post/utilisateurs/utilisateur-post.interface';
import { Animal } from '../models/animal/animal';
import { AnimalPostInterface } from '../models/interfaces/post/animal/animal-post.interface';
import { UtilisateurService } from 'app/services/api/utilisateur.service';
import * as emojiUnicode from 'emoji-unicode';

@Pipe({
    name: 'entityEmoji'
})
export class EntityEmojiPipe implements PipeTransform {
    constructor(private readonly utilisateurService: UtilisateurService) {}

    transform(entity: Utilisateur | UtilisateurPostInterface | Animal | AnimalPostInterface): string {
        if (entity) {
            if (Object.prototype.hasOwnProperty.call(entity, 'typeUtilisateur')) { // Is utilisateur or animal ?
                return this.getHtml(this.utilisateurService.emoji(entity as UtilisateurPostInterface));
            }

            if (entity instanceof Animal) {
                return this.getHtml(entity.emoji);
            }

            return this.getHtml(Animal.emoji(entity as AnimalPostInterface));
        }

        return '';
    }

    private getHtml(emojiName: string): string {
        return `<img class="emoji" src="https://openmoji.org/data/color/svg/${(emojiUnicode(emojiName) as string).replace(/ /g, '-').toUpperCase()}.svg" alt="${emojiName}" width="40" height="40" />`;
    }
}

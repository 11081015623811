import { Chat } from './chat';
import { UtilisateurPostInterface } from '../interfaces/post/utilisateurs/utilisateur-post.interface';
import { Veterinaire } from '../utilisateurs/veterinaire';
import { AdminMessage, BaseMessageInstance, FileMessage, UserMessage } from 'sendbird';
import { SafeResourceUrl } from '@angular/platform-browser';

export class ChatMessage {
    id: number;
    date: Date;
    payload: string | FileChatMessagePayloadInterface;
    isDelayed = false;
    isFile = false;
    isAdmin = false;
    isMyMessage = false;
    isClient = false;
    isRead = false;
    sender?: UtilisateurPostInterface | Veterinaire;
    chat: Chat;
    position?: number;

    messageOriginal: BaseMessageInstance | AdminMessage | UserMessage | FileMessage;

    public get isSent(): boolean {
        return Boolean(this.id);
    }
}

export interface FileChatMessagePayloadInterface {
    name: string;
    size: number;
    mimeType: string;
    url: string | SafeResourceUrl;

    image?: string;
    thumbnailUrl?: string | SafeResourceUrl;
    dimension?: number[]; // [width, height]
    shouldSave?: boolean;
}

import { saveAs } from 'file-saver';
import { environment } from '../environments/environment';
import { Observable, Observer } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TimeDurationPipe } from './pipes/time-duration.pipe';
import { TranslateService } from '@ngx-translate/core';

export class Utils {
    public static getEnv(): string {
        if (environment.hmr) {
            return 'local';
        } else if (environment.dev) {
            return 'dev';
        } else if (environment.demo) {
            return 'demo';
        } else if (environment.preproduction) {
            return 'preprod';
        }

        return 'prod';
    }

    public static groupBy<T>(array: T[], key: (el: T) => string | number): { [key: string]: T[] } {
        // eslint-disable-next-line unicorn/prefer-object-from-entries
        return array.reduce((grouped: { [key: string]: T[] }, el: T) => {
            (grouped[key(el)] = grouped[key(el)] || []).push(el);
            return grouped;
        }, {});
    }

    public static downloadFile(blob: Blob, nom: string = null, rawName: string = null): void {
        let nomOK = nom;
        if (rawName) {
            nomOK += '.' + rawName.split('.').pop();
        }

        saveAs(blob, nomOK);
    }

    public static isPdf(contentType: string): boolean {
        return contentType?.toLowerCase().startsWith('application/pdf') || contentType.toLowerCase().endsWith('pdf');
    }

    public static isImage(contentType: string): boolean {
        const lower = contentType?.toLowerCase();
        return lower?.startsWith('image/') ||
        lower?.endsWith('jpg') ||
        lower?.endsWith('jpeg') ||
        lower?.endsWith('bmp') ||
        lower?.endsWith('svg') ||
        lower?.endsWith('gif') ||
        lower?.endsWith('png');
    }

    public static isVideo(contentType: string): boolean {
        const lower = contentType?.toLowerCase();
        return lower?.startsWith('video/') ||
        lower?.endsWith('avi') ||
        lower?.endsWith('mp4') ||
        lower?.endsWith('mkv') ||
        lower?.endsWith('flv') ||
        lower?.endsWith('webm');
    }

    public static isAudio(contentType: string): boolean {
        const lower = contentType?.toLowerCase();
        return lower?.startsWith('audio/') ||
        lower?.endsWith('mp3') ||
        lower?.endsWith('wav') ||
        lower?.endsWith('aac') ||
        lower?.endsWith('amr');
    }

    public static isOther(contentType: string): boolean {
        return !this.isImage(contentType) && !this.isVideo(contentType) && !this.isAudio(contentType);
    }

    public static getIconForType(contentType: string): string {
        if (this.isImage(contentType)) {
            return 'zoom_in';
        } else if (this.isVideo(contentType) || this.isAudio(contentType)) {
            return 'play_arrow';
        }

        return 'open_in_new';
    }

    public static toDataURL(url: string): Observable<string> {
        const xhr = new XMLHttpRequest();

        return new Observable<string>((observer: Observer<string>) => {
            xhr.addEventListener('load', () => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    observer.next(reader.result as string);
                    observer.complete();
                };

                reader.addEventListener('error', err => {
                    observer.error(err);
                    observer.complete();
                });

                reader.readAsDataURL(xhr.response);
            });

            xhr.open('GET', url);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }

    public static dataURLtoFile(dataurl: string, filename: string): File {
        const arr = dataurl.split(',');
        const mime = /:(.*?);/.exec(arr[0])[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.codePointAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    public static capitalize(s: string): string {
        try {
            if (!s) {
                return s;
            }

            return s[0].toUpperCase() + s.slice(1).toLowerCase();
        } catch {
            return s;
        }
    }

    public static removeDiacritics(str: string): string {
        if (!str) {
            return '';
        }

        return str.normalize('NFD').replace(/[\u0300-\u036F]/g, '');
    }

    public static isRequiredField(control: AbstractControl): boolean {
        if (!control.validator) {
            return false;
        }

        const validators = control.validator({} as AbstractControl);
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return validators ? validators.required : false;
    }

    public static getElapsedTime(date: Date, translateService: TranslateService): string {
        let diff = Math.abs((Number(date) - Date.now()) / 1000);
        if (diff < 60) {
            diff = 60;
        }

        return new TimeDurationPipe(translateService).transform(diff, false).trim().replace(' ', '\u00A0');
    }

    public static getPinturaLocale(translateService: TranslateService): Record<string, any> {
      return {
        annotateLabel: translateService.instant('PINTURA.BUTTONS.UTILS.MARKUP'),
        cropLabel: translateService.instant('PINTURA.BUTTONS.UTILS.CROP'),

        cropLabelButtonFlipHorizontal: translateService.instant('PINTURA.CROP.FLIP_HORIZONTAL'),
        cropLabelButtonFlipVertical: translateService.instant('PINTURA.CROP.FLIP_VERTICAL'),
        cropLabelButtonRecenter: translateService.instant('PINTURA.CROP.RECENTER'),
        cropLabelButtonRotateLeft: translateService.instant('PINTURA.CROP.ROTATE_LEFT'),
        cropLabelButtonRotateRight: translateService.instant('PINTURA.CROP.ROTATE_RIGHT'),
        cropLabelCropBoundary: translateService.instant('PINTURA.CROP.CROP_BOUNDARY'),
        cropLabelCropBoundaryEdge: translateService.instant('PINTURA.CROP.EDGE_OF_IMAGE'),
        cropLabelCropBoundaryNone: translateService.instant('PINTURA.CROP.NONE'),
        cropLabelSelectPreset: translateService.instant('PINTURA.CROP.CROP_SHAPE'),
        cropLabelTabRotation: translateService.instant('PINTURA.CROP.ROTATION'),
        cropLabelTabZoom: translateService.instant('PINTURA.CROP.ZOOM'),

        labelAuto: translateService.instant('PINTURA.BUTTONS.AUTO'),
        labelButtonCancel: translateService.instant('PINTURA.BUTTONS.CANCEL'),
        labelButtonExport: translateService.instant('PINTURA.BUTTONS.CONFIRM'),
        labelButtonRedo: translateService.instant('PINTURA.BUTTONS.REDO'),
        labelButtonRevert: translateService.instant('PINTURA.BUTTONS.REVERT'),
        labelButtonUndo: translateService.instant('PINTURA.BUTTONS.UNDO'),
        labelClose: translateService.instant('PINTURA.BUTTONS.CLOSE'),
        labelDefault: translateService.instant('PINTURA.BUTTONS.DEFAULT'),
        labelEdit: translateService.instant('PINTURA.BUTTONS.EDIT'),
        labelNone: translateService.instant('PINTURA.BUTTONS.NONE'),
        labelReset: translateService.instant('PINTURA.BUTTONS.RESET'),

        labelSizeExtraLarge: translateService.instant('PINTURA.SIZE.EXTRA_LARGE'),
        labelSizeExtraSmall: translateService.instant('PINTURA.SIZE.EXTRA_SMALL'),
        labelSizeLarge: translateService.instant('PINTURA.SIZE.LARGE'),
        labelSizeMedium: translateService.instant('PINTURA.SIZE.MEDIUM'),
        labelSizeMediumLarge: translateService.instant('PINTURA.SIZE.MEDIUM_LARGE'),
        labelSizeMediumSmall: translateService.instant('PINTURA.SIZE.MEDIUM_SMALL'),
        labelSizeSmall: translateService.instant('PINTURA.SIZE.SMALL'),

        shapeLabelInputCancel: translateService.instant('PINTURA.SHAPE.INPUT.CANCEL'),
        shapeLabelInputConfirm: translateService.instant('PINTURA.SHAPE.INPUT.CONFIRM'),
        shapeLabelInputText: translateService.instant('PINTURA.SHAPE.INPUT.EDIT_TEXT'),

        shapeLabelStrokeNone: translateService.instant('PINTURA.SHAPE.STROKE_NONE'),

        shapeLabelToolArrow: translateService.instant('PINTURA.SHAPE.TOOL.ARROW'),
        shapeLabelToolEllipse: translateService.instant('PINTURA.SHAPE.TOOL.ELLIPSE'),
        shapeLabelToolEraser: translateService.instant('PINTURA.SHAPE.TOOL.ERASER'),
        shapeLabelToolLine: translateService.instant('PINTURA.SHAPE.TOOL.LINE'),
        shapeLabelToolPreset: translateService.instant('PINTURA.SHAPE.TOOL.STICKERS'),
        shapeLabelToolRectangle: translateService.instant('PINTURA.SHAPE.TOOL.RECTANGLE'),
        shapeLabelToolSharpie: translateService.instant('PINTURA.SHAPE.TOOL.SHARPIE'),
        shapeLabelToolText: translateService.instant('PINTURA.SHAPE.TOOL.TEXT'),

        shapeTitleBackgroundColor: translateService.instant('PINTURA.SHAPE.BUTTON.FILL_COLOR'),
        shapeTitleButtonDuplicate: translateService.instant('PINTURA.SHAPE.BUTTON.DUPLICATE'),
        shapeTitleButtonFlipHorizontal: translateService.instant('PINTURA.SHAPE.BUTTON.FLIP_HORIZONTAL'),
        shapeTitleButtonFlipVertical: translateService.instant('PINTURA.SHAPE.BUTTON.FLIP_VERTICAL'),
        shapeTitleButtonMoveToFront: translateService.instant('PINTURA.SHAPE.BUTTON.MOVE_TO_FRONT'),
        shapeTitleButtonRemove: translateService.instant('PINTURA.SHAPE.BUTTON.REMOVE'),

        shapeTitleColorAqua: translateService.instant('PINTURA.SHAPE.COLOR.AQUA'),
        shapeTitleColorBlack: translateService.instant('PINTURA.SHAPE.COLOR.BLACK'),
        shapeTitleColorBlue: translateService.instant('PINTURA.SHAPE.COLOR.BLUE'),
        shapeTitleColorFuchsia: translateService.instant('PINTURA.SHAPE.COLOR.FUCHSIA'),
        shapeTitleColorGray: translateService.instant('PINTURA.SHAPE.COLOR.GRAY'),
        shapeTitleColorGreen: translateService.instant('PINTURA.SHAPE.COLOR.GREEN'),
        shapeTitleColorMaroon: translateService.instant('PINTURA.SHAPE.COLOR.MAROON'),
        shapeTitleColorNavy: translateService.instant('PINTURA.SHAPE.COLOR.NAVY'),
        shapeTitleColorOlive: translateService.instant('PINTURA.SHAPE.COLOR.OLIVE'),
        shapeTitleColorOrange: translateService.instant('PINTURA.SHAPE.COLOR.ORANGE'),
        shapeTitleColorPurple: translateService.instant('PINTURA.SHAPE.COLOR.PURPLE'),
        shapeTitleColorRed: translateService.instant('PINTURA.SHAPE.COLOR.RED'),
        shapeTitleColorSilver: translateService.instant('PINTURA.SHAPE.COLOR.SILVER'),
        shapeTitleColorTeal: translateService.instant('PINTURA.SHAPE.COLOR.TEAL'),
        shapeTitleColorTransparent: translateService.instant('PINTURA.SHAPE.COLOR.TRANSPARENT'),
        shapeTitleColorWhite: translateService.instant('PINTURA.SHAPE.COLOR.WHITE'),
        shapeTitleColorYellow: translateService.instant('PINTURA.SHAPE.COLOR.YELLOW'),

        shapeTitleFontFamily: translateService.instant('PINTURA.SHAPE.FONT.FONT'),
        shapeTitleFontSize: translateService.instant('PINTURA.SHAPE.FONT.FONT_SIZE'),
        shapeTitleFontStyle: translateService.instant('PINTURA.SHAPE.FONT.FONT_STYLE.TITLE'),

        shapeLabelFontStyleBold: translateService.instant('PINTURA.SHAPE.FONT.FONT_STYLE.BOLD'),
        shapeLabelFontStyleItalic: translateService.instant('PINTURA.SHAPE.FONT.FONT_STYLE.ITALIC'),
        shapeLabelFontStyleItalicBold: translateService.instant('PINTURA.SHAPE.FONT.FONT_STYLE.ITALIC_BOLD'),
        shapeLabelFontStyleNormal: translateService.instant('PINTURA.SHAPE.FONT.FONT_STYLE.NORMAL'),

        shapeTitleLineDecorationArrow: translateService.instant('PINTURA.SHAPE.LINE.ARROW'),
        shapeTitleLineDecorationArrowSolid: translateService.instant('PINTURA.SHAPE.LINE.ARROW_SOLID'),
        shapeTitleLineDecorationBar: translateService.instant('PINTURA.SHAPE.LINE.BAR'),
        shapeTitleLineDecorationCircle: translateService.instant('PINTURA.SHAPE.LINE.CIRCLE'),
        shapeTitleLineDecorationCircleSolid: translateService.instant('PINTURA.SHAPE.LINE.CIRCLE_SOLID'),
        shapeTitleLineDecorationSquare: translateService.instant('PINTURA.SHAPE.LINE.SQUARE'),
        shapeTitleLineDecorationSquareSolid: translateService.instant('PINTURA.SHAPE.LINE.SQUARE_SOLID'),
        shapeTitleLineEnd: translateService.instant('PINTURA.SHAPE.LINE.END'),
        shapeTitleLineHeight: translateService.instant('PINTURA.SHAPE.LINE.LEADING'),
        shapeTitleLineStart: translateService.instant('PINTURA.SHAPE.LINE.START'),

        shapeTitleStrokeColor: translateService.instant('PINTURA.SHAPE.STROKE.LINE_COLOR'),
        shapeTitleStrokeWidth: translateService.instant('PINTURA.SHAPE.STROKE.LINE_WIDTH'),

        shapeTitleTextAlign: translateService.instant('PINTURA.SHAPE.TEXT.TEXT_ALIGN'),
        shapeTitleTextAlignCenter: translateService.instant('PINTURA.SHAPE.TEXT.CENTER_ALIGN'),
        shapeTitleTextAlignLeft: translateService.instant('PINTURA.SHAPE.TEXT.LEFT_ALIGN'),
        shapeTitleTextAlignRight: translateService.instant('PINTURA.SHAPE.TEXT.RIGHT_ALIGN'),
        shapeTitleTextColor: translateService.instant('PINTURA.SHAPE.TEXT.FONT_COLOR'),

        statusLabelButtonClose: translateService.instant('PINTURA.BUTTONS.CLOSE'),

        labelSupportError: (features: string[]): string => features.join(', ') + ' ' + (translateService.instant('PINTURA.STATUS.UNAVAILABLE_FEATURES') as string),
        statusLabelLoadImage: (state: { task: string; error: Error }): string => {
          if (!state || !state.task) {
            return translateService.instant('PINTURA.STATUS.AWAITING_IMAGE') as string;
          }

          if (state.error) {
            return translateService.instant('PINTURA.STATUS.LOAD_IMAGE_ERROR') as string;
          }

          if (state.task === 'blob-to-bitmap') {
            return translateService.instant('PINTURA.STATUS.CREATING_PREVIEW') as string;
          }

          return translateService.instant('PINTURA.STATUS.LOADING_IMAGE') as string;
        },
        statusLabelProcessImage: (state: { task: string; error: Error }): string | undefined => {
          if (!state || !state.task) {
            return;
          }

          if (state.task === 'store') {
            if (state.error) {
              return translateService.instant('PINTURA.STATUS.UPLOAD_IMAGE_ERROR') as string;
            }

            return translateService.instant('PINTURA.STATUS.UPLOADING_IMAGE') as string;
          }

          if (state.error) {
            return translateService.instant('PINTURA.STATUS.UPLOAD_IMAGE_ERROR') as string;
          }

          return translateService.instant('PINTURA.STATUS.PROCESSING_IMAGE') as string;
        },
        ...Utils.getPinturaIcons()
      };
    }

    public static getPinturaIcons(): Record<string, string> {
      return {
        annotateIcon: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M17.086 2.914a2.828 2.828 0 1 1 4 4l-14.5 14.5-5.5 1.5 1.5-5.5 14.5-14.5z"/></g>',
        cropIcon: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M23 17H9a2 2 0 0 1-2-2v-5m0-3V1"/><path d="M1 7h14a2 2 0 0 1 2 2v7m0 4v3"/></g>',
        cropIconButtonFlipHorizontal: '<g stroke="none" fill="currentColor"><path d="M11.93 7.007V20a1 1 0 0 1-1 1H5.78a1 1 0 0 1-.93-1.368l5.15-12.993a1 1 0 0 1 1.929.368z"/><path d="M14 7.007V20a1 1 0 0 0 1 1h5.149a1 1 0 0 0 .93-1.368l-5.15-12.993A1 1 0 0 0 14 7.007z" opacity=".6"/></g>',
        cropIconButtonFlipVertical: '<g stroke="none" fill="currentColor"><path d="M19.993 12.143H7a1 1 0 0 1-1-1V5.994a1 1 0 0 1 1.368-.93l12.993 5.15a1 1 0 0 1-.368 1.93z"/><path d="M19.993 14a1 1 0 0 1 .368 1.93L7.368 21.078A1 1 0 0 1 6 20.148V15a1 1 0 0 1 1-1h12.993z" opacity=".6"/></g>',
        cropIconButtonRecenter: '<path stroke="currentColor" fill="none" stroke-width="2" stroke-linejoin="bevel" d="M1.5 7.5v-6h6M1.5 16.5v6h6M22.5 16.5v6h-6M22.5 7.5v-6h-6"/><circle cx="12" cy="12" r="3.5" fill="currentColor" stroke="none"/>',
        cropIconButtonRotateLeft: '<g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"/><rect x="3" rx="1" height="12" width="12" y="9"/><path d="M15 5h-1a5 5 0 015 5 1 1 0 002 0 7 7 0 00-7-7h-1.374l.747-.747A1 1 0 0011.958.84L9.603 3.194a1 1 0 000 1.415l2.355 2.355a1 1 0 001.415-1.414l-.55-.55H15z"/></g>',
        cropIconButtonRotateRight: '<g stroke="none" fill="currentColor"><path fill="none" d="M-1-1h582v402H-1z"/><path d="M11.177 5H10a5 5 0 00-5 5 1 1 0 01-2 0 7 7 0 017-7h1.374l-.747-.747A1 1 0 0112.042.84l2.355 2.355a1 1 0 010 1.415l-2.355 2.354a1 1 0 01-1.415-1.414l.55-.55z"/><rect rx="1" height="12" width="12" y="9" x="9"/></g>',
        iconButtonClose: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M18 6L6 18M6 6l12 12"></path></path></g>',
        iconButtonExport: '<polyline points="20 6 9 17 4 12" fill="none" stroke="currentColor" stroke-width=".125em"></polyline>',
        iconButtonRedo: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M14 8h-4c-2.485 0-5 2-5 5s2.515 5 5 5h4"/><path fill="currentColor" d="M19 8l-4-3v6z"/></g>',
        iconButtonRevert: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M7.388 18.538a8 8 0 10-2.992-9.03"/><path fill="currentColor" d="M2.794 11.696L2.37 6.714l5.088 3.18z"/><path d="M12 8v4M12 12l4 2"/></g>',
        iconButtonUndo: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M10 8h4c2.485 0 5 2 5 5s-2.515 5-5 5h-4"/><path fill="currentColor" d="M5 8l4-3v6z"/></g>',
        iconSupportError: '<g fill="none" stroke="currentColor" stroke-width="2"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"/><g><line x1="12" y1="9" x2="12" y2="13"/><line x1="12" y1="17" x2="12.01" y2="17"/></g>',
        shapeIconButtonDuplicate: '\n    <g fill="none" fill-rule="evenodd"><path d="M15 13.994V16a2 2 0 01-2 2H8a2 2 0 01-2-2v-5a2 2 0 012-2h2.142" stroke="currentColor" stroke-width=".125em"/><path d="M15 9V8a1 1 0 00-2 0v1h-1a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1zm-4-4h6a2 2 0 012 2v6a2 2 0 01-2 2h-6a2 2 0 01-2-2V7a2 2 0 012-2z" fill="currentColor"/></g>',
        shapeIconButtonFlipHorizontal: '<g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M6 6.5h5v11H6z"/><path fill="#FFF" d="M15 6.5h3v11h-3z"/><path d="M11 4v16" fill="#fff"/></g>',
        shapeIconButtonFlipVertical: '<g stroke="currentColor" stroke-width=".125em"><rect x="7" y="8" width="11" height="5" fill="none"/><rect x="7" y="17" width="11" height="2" fill="currentColor"/><line x1="5" y1="13" x2="20" y2="13"/></g>',
        shapeIconButtonMoveToFront: '\n    <g fill="none" fill-rule="evenodd"><rect fill="currentColor" x="11" y="13" width="8" height="2" rx="1"/><rect fill="currentColor" x="9" y="17" width="10" height="2" rx="1"/><path d="M11.364 8H10a5 5 0 000 10M12 6.5L14.5 8 12 9.5z" stroke="currentColor" stroke-width=".125em" stroke-linecap="round"/></g>',
        shapeIconButtonRemove: '<g fill="none" fill-rule="evenodd"><path stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M7.5 7h9z"/><path d="M7.916 9h8.168a1 1 0 01.99 1.14l-.972 6.862a2 2 0 01-1.473 1.653c-.877.23-1.753.345-2.629.345-.876 0-1.752-.115-2.628-.345a2 2 0 01-1.473-1.653l-.973-6.862A1 1 0 017.916 9z" fill="#FFF"/><rect fill="#FFF" x="10" y="5" width="4" height="3" rx="1"/></g>',
        shapeIconButtonSelectSticker: '<g><circle fill="currentColor" cx="5" cy="6" r="1.333"/><g fill="none" stroke="currentColor" stroke-width="0.0625em" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="1" width="14" height="14" rx="2"/><path d="M15 13l-5-6-5 8"/></g></g>',
        shapeIconInputCancel: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M18 6L6 18M6 6l12 12"/></g>',
        shapeIconInputConfirm: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><polyline points="20 6 9 17 4 12"/></g>',
        shapeIconTextAlignCenter: '<g stroke-width=".125em" stroke="currentColor"><line x1="7" y1="8" x2="17" y2="8"/><line x1="5" y1="12" x2="19" y2="12"/><line x1="8" y1="16" x2="16" y2="16"/></g>',
        shapeIconTextAlignLeft: '<g stroke-width=".125em" stroke="currentColor"><line x1="5" y1="8" x2="15" y2="8"/><line x1="5" y1="12" x2="19" y2="12"/><line x1="5" y1="16" x2="14" y2="16"/></g>',
        shapeIconTextAlignRight: '<g stroke-width=".125em" stroke="currentColor"><line x1="9" y1="8" x2="19" y2="8"/><line x1="5" y1="12" x2="19" y2="12"/><line x1="11" y1="16" x2="19" y2="16"/></g>',
        shapeIconToolArrow: '<g stroke-width=".125em" stroke="currentColor" fill="none"><line x1="20" y1="3" x2="6" y2="21"/><path d="m10 5 L22 1 L21 13" fill="currentColor" stroke="none"/></g>',
        shapeIconToolEllipse: '<g stroke-width=".125em" stroke="currentColor" fill="none"><circle cx="12" cy="12" r="11"/></g>',
        shapeIconToolEraser: '<g stroke-width=".125em" stroke="currentColor" stroke-linecap="round" fill="none"><g transform="translate(3, 15) rotate(-45)"><rect x="0" y="0" width="18" height="10" rx="3"/></g><line x1="11" y1="21" x2="18" y2="21"/><line x1="20" y1="21" x2="22" y2="21"/></g>',
        shapeIconToolLine: '<g stroke-width=".125em" stroke="currentColor" fill="none"><line x1="20" y1="3" x2="6" y2="21"/></g>',
        shapeIconToolPreset: '<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M12 22c2.773 0 1.189-5.177 3-7 1.796-1.808 7-.25 7-3 0-5.523-4.477-10-10-10S2 6.477 2 12s4.477 10 10 10z"></path><path d="M20 17c-3 3-5 5-8 5"></path></g>',
        shapeIconToolRectangle: '<g stroke-width=".125em" stroke="currentColor" fill="none"><rect x="2" y="2" width="20" height="20" rx="3"/></g>',
        shapeIconToolSharpie: '<g stroke-width=".125em" stroke="currentColor" fill="none"><path d="M2.025 5c5.616-2.732 8.833-3.857 9.65-3.374C12.903 2.351.518 12.666 2.026 14 3.534 15.334 16.536.566 17.73 2.566 18.924 4.566 3.98 17.187 4.831 18c.851.813 9.848-6 11.643-6 1.087 0-2.53 5.11-2.92 7-.086.41 3.323-1.498 4.773-1 .494.17.64 2.317 1.319 3 .439.443 1.332.776 2.679 1" stroke="currentColor" stroke-width=".125em" fill="none" fill-rule="evenodd" stroke-linejoin="round"/></g>',
        shapeIconToolText: '<g stroke="none" fill="currentColor" transform="translate(6,0)"><path d="M8.14 20.085c.459 0 .901-.034 1.329-.102a8.597 8.597 0 001.015-.21v1.984c-.281.135-.695.247-1.242.336a9.328 9.328 0 01-1.477.133c-3.312 0-4.968-1.745-4.968-5.235V6.804H.344v-1.25l2.453-1.078L3.89.819h1.5v3.97h4.97v2.015H5.39v10.078c0 1.031.245 1.823.735 2.375s1.161.828 2.015.828z"/>',
        statusIconButtonClose: '<g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor" stroke-width=".125em"><path d="M18 6L6 18M6 6l12 12"></path></path></g>'
      };
    }

    public static getUrlValidator(): ValidatorFn {
        return (control: AbstractControl) => {
            const input: HTMLInputElement = document.createElement('input');
            input.type = 'url';
            input.value = control.value;
            return input.checkValidity() ? null : { invalidUrl: true };
        };
    }
}

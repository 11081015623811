export class DataEntityLinkPossible {
    id: string;
    nom: string;
    prenom?: string;
    mail?: string;
    telephonePortable?: string;

    idClient?: string;
    nomClient?: string;
    prenomClient?: string;
    mailClient?: string;
    telephonePortableClient?: string;

    constructor(data: any) {
        for (const key of Object.keys(data)) {
            this[key] = data[key];
        }
    }

    public toString(): string {
        return [
            this.nom,
            this.prenom,
            this.nomClient,
            this.prenomClient,
            this.mail,
            this.mailClient,
            this.telephonePortable,
            this.telephonePortableClient
        ].filter(e => !!e).join(' ');
    }

    get fullName(): string {
        return this.nom + (this.prenom ? ' ' + this.prenom : '');
    }

    get fullNameClient(): string {
        return this.nomClient + (this.prenomClient ? ' ' + this.prenomClient : '');
    }

    get concatenated(): string {
        return this.toString().replace(/ /g, '').toLowerCase();
    }
}

export interface DataEntityLinkPossibleStorage {
    datas: DataEntityLinkPossible[];
    date?: Date;
}

import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { fuseAnimations } from '../../../../@fuse/animations';
import { Invoice } from '../../../models/subscription';
import { EntiteJuridiqueService } from '../../../services/api/entite-juridique.service';
import { UtilisateurService } from '../../../services/api/utilisateur.service';
import { fromEvent } from 'rxjs';
import { InvoicesDataSource } from '../../../datasources/invoices.data-source';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Utils } from 'app/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'app-factures',
    templateUrl: './factures.component.html',
    styleUrls: ['./factures.component.scss'],
    animations: fuseAnimations
})
export class FacturesComponent implements AfterViewInit {
    dataSource: InvoicesDataSource;
    displayedColumns = ['number', 'date', 'total', 'status', 'action'];
    deactivatedAccount = false;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('filter', { static: true }) filter: ElementRef;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private utilisateurService: UtilisateurService,
        private entiteJuridiqueService: EntiteJuridiqueService,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        private fuseConfigService: FuseConfigService
    ) {
        this.dataSource = new InvoicesDataSource(this.utilisateurService, this.entiteJuridiqueService);
        this.dataSource.load();
        if (this.entiteJuridiqueService.entiteJuridiqueForUtilisateurConnectedValue.isDisabled) {
            this.configureFuseLayout();
            this.deactivatedAccount = true;
        }
    }

    ngAfterViewInit(): void {
        fromEvent(this.filter.nativeElement, 'keyup').pipe(
            distinctUntilChanged(),
            debounceTime(150)
        ).subscribe(() => {
            this.paginator.pageIndex = 0;
            this.dataSource.setFilter(this.filter.nativeElement.value);
        });
    }

    downloadInvoice(invoice: Invoice): void {
        this.entiteJuridiqueService.getInvoiceBlob(this.utilisateurService.utilisateurConnectedValue.entiteJuridique, invoice).subscribe({
            next: file => {
                Utils.downloadFile(file);
            },
            error: error => {
                console.error(error);
                const snack = this.snackbar.open(this.translateService.instant('SHARED.ERROR'), this.translateService.instant('SHARED.RETRY'), { duration: 3000 });
                snack.onAction().subscribe(() => {
                    this.downloadInvoice(invoice);
                });
            }
        });
    }

    private configureFuseLayout(): void {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }
}

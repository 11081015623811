import { JsonProperty } from 'json-object-mapper';
import { List } from '../list';
import { RecordingOptions, TemplatePostInterface } from '../interfaces/template-post.interface';
import { TextDocument } from './text-document';

export class Template extends TextDocument {
    @JsonProperty()
    isDefault: boolean;

    hasBeenUpdatedSinceLastTimeApplied: boolean;

    constructor() {
        super();
        this.isDefault = false;
        this.hasBeenUpdatedSinceLastTimeApplied = false;
    }

    public hasPost(): TemplatePostInterface {
        return {
            id: this.id,
            name: this.name,
            isDefault: this.isDefault,
            content: this.content,
            status: this.status,
            type: this.type,
            recordingOption: RecordingOptions.COPY
        };
    }
}

export class ListTemplates extends List {
    @JsonProperty({ type: Template })
    data: Template[] = null;

    @JsonProperty()
    stats: any = null;
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../config.service';
import { StripeCoupon } from 'app/models/stripe/coupon';
import { map, tap, catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EntiteJuridique } from '../../models/pro/entite-juridique';
import { ObjectMapper } from 'json-object-mapper';

@Injectable({
    providedIn: 'root'
})
export class OnboardingService {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
        private snackbar: MatSnackBar,
        private translateService: TranslateService
    ) {}

    /**
     * Enregistre le nouvel utilisateur et lui envoi un mail
     * @param {OnboardingSession} session Session d'inscrption
     * @returns {Observable<void>} Subscription
     */
    registerNewUser(session: OnboardingSession): Observable<void> {
        return this.http.post<void>(`${this.config.baseUrl}api/signup/pro`, session);
    }

    /**
     * Enregistre le choix de formule du nouvel utilisateur
     * @param {OnboardingPlans} plans
     * @param {string} accessToken
     * @returns {Observable<void>} Subscription
     */
    sendPlans(plans: OnboardingPlans, accessToken: string): Observable<OnboardingPlansResponse> {
        return this.http.post<OnboardingPlansResponse>(
            `${this.config.baseUrl}api/signup/pro/plans`,
            plans,
            {
                headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
            }
        ).pipe(
            map(res => res)
        );
    }

    /**
     * Renvoi le mail d'inscription
     * @param {string} mail Mail utilisé pour l'inscription
     * @returns {Observable<void>} Subscription
     */
    resendMail(mail: string): Observable<void> {
        return this.http.post<void>(`${this.config.baseUrl}api/signup/pro/send-mail-confirmation`, {
            mail
        });
    }

    /**
     * Verifie les informations d'un coupon
     * @param {string} coupon
     * @param {string} accessToken
     */
    checkCoupon(coupon: string, accessToken: string): Observable<StripeCoupon> {
        return this.http.get<StripeCoupon>(`${this.config.baseUrl}api/signup/pro/coupon/${coupon}`,
            {
                headers: new HttpHeaders().set('Authorization', 'Bearer ' + accessToken)
            })
            .pipe(
                map(r => {
                    if (Array.isArray(r)) {
                        return null;
                    }
    
                    return r;
                }),
                tap(res => {
                    if (!res) {
                        this.snackbar.open(this.translateService.instant('ONBOARDING_PRO_PLANS.COUPON.INVALID'), null, { duration: 3000 });
                    }
                })
            );
    }

    /**
     * Vérifie qu'un code clinique existe
     * @param {string} codeClinique code de la clinique
     * @returns {Observable<EntiteJuridique>} Subscription
     */
    checkClinic(codeClinique: string): Observable<EntiteJuridique> {
        return this.http.get<EntiteJuridique>(`${this.config.baseUrl}api/signup/client/clinic/${codeClinique}`).pipe(
            map(r => ObjectMapper.deserialize(EntiteJuridique, r)),
            catchError(err => {
                if (err?.status === 404) {
                    return of<EntiteJuridique>(null);
                }

                throw err;
            }),
            tap(res => {
                if (!res) {
                    this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.BAD_CLINIC_CODE'), null, { duration: 3000 });
                }
            })
        );
    }

    /**
     * Vérifie qu'un numéro de téléphone client existe
     * @param {string} phone téléphone du client
     * @returns {Observable<boolean>} Subscription
     */
    checkClientPhone(phone: string): Observable<boolean> {
        const body = {
            mail: null,
            telephonePortable: phone
        };
        return this.http.post<boolean>(`${this.config.baseUrl}api/signup/check-exist`, body);
    }

    /**
     * Vérifie qu'un numéro de TVA est correct
     * @param {string} vat Numéro de TVA
     * @param {string} country Pays de l'EJ
     * @returns {Observable<boolean>} Subscription
     */
    checkVatNumber(vat: string, country: string): Observable<boolean> {
        const body = {
            vat,
            country
        };
        return this.http.post<boolean>(`${this.config.baseUrl}api/signup/check-vat`, body)
            .pipe(
                catchError(err => {
                    this.snackbar.open(this.translateService.instant('ONBOARDING_PRO.VAT_NUMBER.ERROR'), null, { duration: 4000 });
                    throw err;
                })
            );
    }

    /**
     * Enregistre le nouveau client
     * @param {OnboardingClientSession} session Session d'inscrption de client
     * @returns {Observable<void>} Subscription
     */
    registerNewClient(session: OnboardingClientSession): Observable<void> {
        return this.http.post<void>(`${this.config.baseUrl}api/signup/client`, session);
    }

    /**
     * Start client OTP
     * @param {OnboardingClientStartOtp} data Infos pour l'OTP
     * @returns {Observable<OnboardingClientStartOtpResult>} Subscription
     */
    startOtp(data: OnboardingClientStartOtp): Observable<OnboardingClientStartOtpResult> {
        return this.http.post<OnboardingClientStartOtpResult>(`${this.config.baseUrl}api/firebase/sendVerificationCode`, data).pipe(
            catchError(err => {
                if (err?.error?.error?.message === 'TOO_MANY_ATTEMPTS_TRY_LATER') {
                    this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.OTP_TOO_MANY_ATTEMPTS_TRY_LATER'), null, { duration: 3000 });
                }

                throw err;
            })
        );
    }

    /**
     * Validate client OTP
     * @param {OnboardingClientValidateOtp} data Infos pour l'OTP
     * @returns {Observable<void>} Subscription
     */
    validateOtp(data: OnboardingClientValidateOtp): Observable<OnboardingClientValidateOtpResult> {
        return this.http.post<void>(`${this.config.baseUrl}api/firebase/verifyPhoneNumber`, data).pipe(
            map(() => {
                const res: OnboardingClientValidateOtpResult = { code: 'GRANTED' };
                return res;
            }),
            catchError(err => {
                if (err?.status === 400) {
                    switch (err?.error?.error?.message) {
                        case 'INVALID_CODE':
                            this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.OTP_INVALID_CODE'), null, { duration: 3000 });
                            return of({ code: 'INVALID_CODE' } as OnboardingClientValidateOtpResult);
                        case 'SESSION_EXPIRED':
                            this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.OTP_SESSION_EXPIRED'), null, { duration: 3000 });
                            return of({ code: 'SESSION_EXPIRED' } as OnboardingClientValidateOtpResult);
                        default:
                            this.snackbar.open(this.translateService.instant('ONBOARDING_CLIENT.OTP_UNKNOWN_ERROR'), null, { duration: 3000 });
                            return of({ code: 'ERROR' } as OnboardingClientValidateOtpResult);
                    }
                }

                throw err;
            })
        );
    }
}

export interface OnboardingClientSession {
    codeClinique: string;
    user: {
        civilite: string;
        prenom: string;
        nom: string;
        mail: string;
        telephone: string;
    };
    animals: OnboardingClientAnimal[];
}

export interface OnboardingClientAnimal {
    nom: string;
    espece: number;
    race: number;
    sexe: string;
    sterilise: boolean;
    dateNaissance: Date;
}

export interface OnboardingClientStartOtp {
    phoneNumber: string;
    recaptchaToken: string;
}

export interface OnboardingClientStartOtpResult {
    sessionInfo: string;
}

export interface OnboardingClientValidateOtp {
    sessionInfo: string;
    code: string;
}

export interface OnboardingClientValidateOtpResult {
    code: 'GRANTED' | 'INVALID_CODE' | 'SESSION_EXPIRED' | 'ERROR';
}

/** Session d'inscription */
export interface OnboardingSession {
    /** Clinique */
    entiteJuridique: OnboardingEntiteJuridique;
    /** Véterinaire */
    veterinaire: OnboardingVeterinaire;
}

export interface OnboardingEntiteJuridique {
    /** Nom */
    nom: string;
    /** Numéro de voie + Nom de voie */
    adresse: string;
    /** Code Postal */
    codePostal: string;
    /** Ville */
    ville: string;
    /** Pays */
    pays: string;
    /** Téléphone */
    telephone: string;
    /** Email (identique au veterinaire) */
    mail: string;
    groupement: string;
    vat?: string;
}

export interface OnboardingVeterinaire {
    /** Civilite */
    civilite: string;
    /** Prenom */
    prenom: string;
    /** Nom */
    nom: string;
    /** Email */
    mail: string;
    /** Téléphone portable */
    telephone: string;
    /** Mot de passe */
    password: string;
    /** Langue format iso (fr_FR) */
    locale: string;
    /** Timezone (Europe/Paris) */
    timezone: string;
}

export interface OnboardingPlans {
    /** Mensuel ou non */
    monthly: boolean;
    /** Nombre de licences */
    numberOfLicenses: number;
    /** Coupon de reduction */
    coupon: string;
}

export interface OnboardingPlansResponse {
    /** Id de session de paiement */
    idSession: string;
}

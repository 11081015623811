<div class="p-12" fxFlex="grow">
    <div class="p-16">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="h2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <span [ngSwitch]="type">
                    <span *ngSwitchCase="'mine'">{{ 'TODOS.TITLE.MINE' | translate }}</span>
                    <span *ngSwitchCase="'all'">{{ 'TODOS.TITLE.ALL' | translate }}</span>
                </span>
                <span *ngIf="!isLoading && (todos | async).length > 0">({{ (todos | async).length }})</span>
                <mat-spinner *ngIf="isLoading" mode="indeterminate" color="accent" diameter="20"></mat-spinner>
            </div>
        </div>
    </div>
    <div class="p-16 list">
        <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center" class="text-center h2 m-8">
            <mat-spinner mode="indeterminate" [diameter]="40" color="accent"></mat-spinner>
            <div class="h4 mt-16">{{ 'SHARED.LOADING' | translate }}</div>
        </div>
        <div *ngIf="!isLoading">
            <form fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px" [formGroup]="form"
                (submit)="addTodo()" class="todo-form">
                <mat-form-field appearance="outline" class="no-padding">
                    <mat-label>{{ 'TODOS.ADD.DESCRIPTION.NAME' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'TODOS.ADD.DESCRIPTION.PLACEHOLDER' | translate }}"
                        formControlName="description">
                </mat-form-field>

                <mat-form-field appearance="outline" class="no-padding">
                    <mat-label>{{ 'TODOS.ADD.DATEPICKER.NAME' | translate }}</mat-label>
                    <input matInput [matDatepicker]="dateDebutPicker"
                        [placeholder]="'TODOS.ADD.DATEPICKER.NAME' | translate" formControlName="date" readonly
                        (focus)="dateDebutPicker.open()" (click)="dateDebutPicker.open()" required>
                    <mat-datepicker-toggle matSuffix [for]="dateDebutPicker"></mat-datepicker-toggle>
                    <mat-datepicker #dateDebutPicker></mat-datepicker>
                </mat-form-field>

                <button type="submit" mat-raised-button color="primary">
                    <mat-icon>add</mat-icon>{{ 'TODOS.ADD.BUTTON' | translate }}
                </button>
            </form>
            <div fxLayout="column" fxLayoutGap="16px" class="mt-32" [transitionGroupTodos]="'todos-list'">
                <div *ngFor="let todoElement of todoElements | async" transitionGroupTodo
                     [id]="todoElement?.element?.id">
                    <div [ngSwitch]="todoElement.type">
                        <div *ngSwitchCase="'todo'">
                            <mat-checkbox class="todo" [checked]="todoElement.element.checked"
                                (change)="onTodoCheckedChanged(todoElement.element, $event.checked)">
                                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                                    <div class="content">
                                        <div class="description">{{ todoElement.element.description }}</div>
                                        <div class="date">{{ todoElement.element.date | dateLocale }}</div>
                                    </div>
                                    <button mat-icon-button (click)="deleteTodo(todoElement.element)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </mat-checkbox>
                        </div>
                        <div *ngSwitchCase="'past-separator'" class="past-separator">
                            {{ 'TODOS.PAST_SEPARATOR' | translate }}
                        </div>
                        <div *ngSwitchCase="'empty-state'" class="empty-state">
                            {{ 'TODOS.EMPTY_STATE' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

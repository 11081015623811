import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { ForgotPassComponent } from './forgot-password.component';
import { ViewSharedModule } from '../shared/view-shared.module';
import { SharedModule } from '../../shared.module';
import { ValidatorsModule } from 'ngx-validators';

const routes = [
    {
        path: 'forgot-password',
        component: ForgotPassComponent
    }
];

@NgModule({
    declarations: [
        ForgotPassComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        ViewSharedModule,
        ValidatorsModule
    ]
})
export class ForgotPassModule {}

<div fxLayout="row wrap" fxLayoutAlign="start start">
    <mat-form-field appearance="outline" fxFlex="100%">
        <mat-label>{{ 'SHARED.SEARCH' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'SHARED.SEARCH' | translate }}" #input>
    </mat-form-field>

    <div *ngIf="chatShortcutsDataSource.filteredData.length === 0" class="message-box warning mb-16" fxFlex="100%">
        {{ 'SETTINGS.MESSAGES.SHORTCUTS.NONE' | translate }}
    </div>

    <div class="mat-elevation-z1" fxLayout="column wrap" fxFlexAlign="start end" fxFlex="100%">

        <mat-table [dataSource]="chatShortcutsDataSource" matSort fxFlex="100%">

            <ng-container matColumnDef="category">
                <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.GROUP_NAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="20%">
                    {{ row.category ? row.category : ('SHARED.OTHER' | translate) }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="shortcut">
                <mat-header-cell fxFlex="20%" *matHeaderCellDef mat-sort-header>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="20%">
                    <mat-chip-list>
                        <mat-chip>/{{ row.shortcut }}</mat-chip>
                    </mat-chip-list>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="content">
                <mat-header-cell *matHeaderCellDef fxFlex="48%">
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.CONTENT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="48%">
                    <p class="truncated" [innerHTML]="row.content | beautifyShortcuts"></p>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell fxFlex="12%" *matHeaderCellDef>
                    {{ 'SETTINGS.MESSAGES.SHORTCUTS.ACTIONS' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let row" fxFlex="12%">
                    <button type="button" mat-icon-button (click)="addOrEdit(row)"
                            [matTooltip]="'SETTINGS.MESSAGES.SHORTCUTS.EDIT' | translate">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button type="button" mat-icon-button color="warn" (click)="remove(row)"
                            [matTooltip]="'SETTINGS.MESSAGES.SHORTCUTS.DELETE' | translate">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="['category', 'shortcut', 'content', 'actions']"></mat-header-row>

            <mat-row *matRowDef="let row; columns: ['category', 'shortcut', 'content', 'actions'];"></mat-row>

        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

    </div>

</div>

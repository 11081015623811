<h1 mat-dialog-title>{{ 'SUBSCRIPTION.UPDATE_DIALOG.TITLE' | translate }}</h1>

<div mat-dialog-content>
    <div *ngIf="loading$ | async; else form" fxLayout="column" fxLayoutAlign="center center">
        <app-loading></app-loading>
    </div>

    <ng-template #form>
        <app-plan-form [entiteJuridique]="data.entiteJuridique" [subscription]="data.subscription" (formDone)="onFormDone($event)"></app-plan-form>
    </ng-template>
</div>

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NewsSegment, NewsSegmentStats } from 'app/models/news/news-segment';
import { NewsSegmentsService } from 'app/services/api/news-segments.service';
import { NewsSegmentPostInterface } from 'app/models/interfaces/post/news-segment-post.interface';
import { EntiteGeographique } from 'app/models/pro/entite-geographique';
import { Espece } from 'app/models/animal/espece';
import { Race } from 'app/models/animal/race';
import { debounceTime, map } from 'rxjs/operators';
import { Utils } from 'app/utils';
import { ConfigService } from 'app/services/config.service';
import { EntiteGeographiqueService } from 'app/services/api/entite-geographique.service';
import { UtilisateurService } from 'app/services/api/utilisateur.service';

@Component({
    selector: 'app-news-segment-form',
    templateUrl: './news-segment-form.component.html',
    styleUrls: ['./news-segment-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NewsSegmentFormComponent implements OnInit, OnDestroy {
    @Input('entityData') entityData$ = new BehaviorSubject<NewsSegment>(null);
    @Output('formSubmited') formSubmited$ = new EventEmitter<boolean>();
    form: FormGroup;
    formInProgress = false;

    allClinics: EntiteGeographique[] = [];
    clinicsMatSelectSearch = false;
    filteredClinicsOptions$: BehaviorSubject<EntiteGeographique[]> = new BehaviorSubject<EntiteGeographique[]>(null);

    allSpecies: Espece[] = [];
    speciesMatSelectSearch = false;
    filteredSpeciesOptions$: BehaviorSubject<Espece[]> = new BehaviorSubject<Espece[]>(null);

    allRaces: Race[] = [];
    racesMatSelectSearch = false;
    filteredRacesOptions$: BehaviorSubject<Race[]> = new BehaviorSubject<Race[]>(null);

    stats?: NewsSegmentStats;

    private subscriptions: Subscription[] = [];

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly newsSegmentsService: NewsSegmentsService,
        private readonly configService: ConfigService,
        private readonly entiteGeographiqueService: EntiteGeographiqueService,
        private readonly userService: UtilisateurService
    ) {}

    ngOnInit(): void {
        this.setForm();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => {
            sub.unsubscribe();
        });
    }

    submit(): void {
        if (this.form.invalid) {
            Object.keys(this.form.controls).forEach(keyControl => {
                this.form.controls[keyControl].markAsTouched();
                this.form.controls[keyControl].updateValueAndValidity();
            });
            return;
        }

        this.formInProgress = true;

        // Mois
        let monthAged = this.form.get('age')?.value ?? 0;
        const ageValueType = this.form.get('ageValueType')?.value;
        if (ageValueType === 'year') {
            monthAged *= 12;
        }

        const data: NewsSegmentPostInterface = {
            name: this.form.get('name')?.value,
            entitesGeographiques: this.form.get('clinic')?.value?.map((eg: EntiteGeographique) => eg.id),
            especes: this.form.get('species')?.value?.map((esp: Espece) => esp.id),
            races: this.form.get('race')?.value?.map((race: Race) => race.id),
            monthAged,
            ageOperator: this.form.get('ageOperator')?.value,
            sex: this.form.get('gender')?.value,
            sterilized: this.form.get('sterilization')?.value
        };

        const id = this.form.get('id').value;
        let addOrEditService = this.newsSegmentsService.add(data);
        if (id) {
            data.id = id;
            addOrEditService = this.newsSegmentsService.update(data);
        }

        const addOrEditSubscription = addOrEditService.subscribe({
            next: () => {
                this.formSubmited$.emit(true);
                this.formInProgress = false;
                addOrEditSubscription?.unsubscribe();
            },
            error: error => {
                console.error(error);
                this.formInProgress = false;
                addOrEditSubscription?.unsubscribe();
            }
        });
    }

    addToInput(objectToAdd: EntiteGeographique | Espece | Race | null, inputName: string): void {
        const input = this.form.get(inputName);
        if (objectToAdd === null) {
            input?.setValue([]);
        } else {
            const selected: Array<EntiteGeographique | Espece | Race> = input?.value ?? [];
            if (!selected.some(element => element.id === objectToAdd.id)) {
                selected.push(objectToAdd);
            }

            input?.setValue(selected);
        }

        input?.updateValueAndValidity();
    }

    removeFromInput(objectToRemove: EntiteGeographique | Espece | Race, inputName: string): void {
        const input = this.form.get(inputName);
        let selected: Array<EntiteGeographique | Espece | Race> = input?.value ?? [];
        selected = selected.filter(object => {
            return object.id !== objectToRemove.id;
        });
        input?.setValue(selected);
        input?.updateValueAndValidity();
    }

    private setForm(): void {
        this.form = this.formBuilder.group({
            id: this.formBuilder.control(this.entityData$?.value?.id),
            name: this.formBuilder.control(this.entityData$?.value?.name, [Validators.required]),

            clinicSelect: this.formBuilder.control(null),
            speciesSelect: this.formBuilder.control(null),
            raceSelect: this.formBuilder.control(null),

            clinicFilter: this.formBuilder.control(null),
            speciesFilter: this.formBuilder.control(null),
            raceFilter: this.formBuilder.control(null),

            clinic: this.formBuilder.control(this.entityData$?.value?.entitesGeographiques ?? []),
            species: this.formBuilder.control(this.entityData$?.value?.especes ?? []),
            race: this.formBuilder.control(this.entityData$?.value?.races ?? []),

            gender: this.formBuilder.control(this.entityData$?.value?.sex),
            sterilization: this.formBuilder.control(this.entityData$?.value?.sterilized),
            ageOperator: this.formBuilder.control(this.entityData$?.value?.ageOperator),
            age: this.formBuilder.control(this.entityData$?.value?.monthAged, [Validators.min(0)]),
            ageValueType: this.formBuilder.control('month')
        });

        this.setStats();
        this.setClinics();
        this.setSpecies();
        this.setRaces();
    }

    private setRaces(): void {
        const racesSubscription = this.configService.getEspecesRaces().subscribe(especesRaces => {
            this.allRaces = especesRaces.reduce<Race[]>((prev, next) => {
                return [...prev, ...next.races.map(raceInterface => {
                    const race = new Race();
                    race.id = raceInterface.id;
                    race.nom = next.nom + ' - ' + raceInterface.nom;
                    return race;
                })];
            }, []);
            this.filteredRacesOptions$.next(this.allRaces);
        });

        const racesFilterSubscriptions = this.form.get('raceFilter')?.valueChanges.pipe(debounceTime(500)).subscribe((filterValue: string) => {
            this.racesMatSelectSearch = true;
            let filteredRacesOptions = this.allRaces;
            if (filterValue?.trim()) {
                filteredRacesOptions = this.allRaces.filter((race: Race) => {
                    const nom = Utils.removeDiacritics(race.nom.toLowerCase());
                    const filter = Utils.removeDiacritics(filterValue.trim().toLowerCase());
                    return nom.includes(filter);
                });
            }

            this.filteredRacesOptions$.next(filteredRacesOptions);
            this.racesMatSelectSearch = false;
        });

        const racesSubscriptions = this.form.get('raceSelect')?.valueChanges.subscribe((race?: Race | -1) => {
            const racesSelectInput = this.form.get('raceSelect');
            if (race && race !== -1) {
                racesSelectInput?.setValue(null);
                racesSelectInput?.updateValueAndValidity();
                this.addToInput(race, 'race');
            } else if (race === -1) {
                racesSelectInput?.setValue(null);
                racesSelectInput?.updateValueAndValidity();
                this.addToInput(null, 'race');
            }
        });

        if (racesSubscription) {
            this.subscriptions.push(racesSubscription);
        }

        if (racesFilterSubscriptions) {
            this.subscriptions.push(racesFilterSubscriptions);
        }

        if (racesSubscriptions) {
            this.subscriptions.push(racesSubscriptions);
        }
    }

    private setSpecies(): void {
        const speciesSubscription = this.configService.getEspecesRaces().subscribe(especesRaces => {
            this.allSpecies = especesRaces.map(especeRace => {
                const espece = new Espece();
                espece.id = especeRace.id;
                espece.nom = especeRace.nom;
                return espece;
            });
            this.filteredSpeciesOptions$.next(this.allSpecies);
        });

        const speciesFilterSubscriptions = this.form.get('speciesFilter')?.valueChanges.pipe(debounceTime(500)).subscribe((filterValue: string) => {
            this.speciesMatSelectSearch = true;
            let filteredSpeciesOptions = this.allSpecies;
            if (filterValue?.trim()) {
                filteredSpeciesOptions = this.allSpecies.filter((espece: Espece) => {
                    const nom = Utils.removeDiacritics(espece.nom.toLowerCase());
                    const filter = Utils.removeDiacritics(filterValue.trim().toLowerCase());
                    return nom.includes(filter);
                });
            }

            this.filteredSpeciesOptions$.next(filteredSpeciesOptions);
            this.speciesMatSelectSearch = false;
        });

        const speciesSubscriptions = this.form.get('speciesSelect')?.valueChanges.subscribe((espece?: Espece | -1) => {
            const speciesSelectInput = this.form.get('speciesSelect');
            if (espece && espece !== -1) {
                speciesSelectInput?.setValue(null);
                speciesSelectInput?.updateValueAndValidity();
                this.addToInput(espece, 'species');
            } else if (espece === -1) {
                speciesSelectInput?.setValue(null);
                speciesSelectInput?.updateValueAndValidity();
                this.addToInput(null, 'species');
            }
        });

        if (speciesSubscription) {
            this.subscriptions.push(speciesSubscription);
        }

        if (speciesFilterSubscriptions) {
            this.subscriptions.push(speciesFilterSubscriptions);
        }

        if (speciesSubscriptions) {
            this.subscriptions.push(speciesSubscriptions);
        }
    }

    private setClinics(): void {
        const meSubscription = this.userService.utilisateurConnected.subscribe(user => {
            if (user?.entiteGeographique) {
                this.addToInput(user.entiteGeographique, 'clinic');
            }
        });

        if (meSubscription) {
            this.subscriptions.push(meSubscription);
        }

        const egSubscription = this.entiteGeographiqueService.getAllEntitesGeographiques().pipe(
            map(egs => egs.sort((a, b) => a.nom < b.nom ? -1 : 1))
        ).subscribe(entiteGeographiques => {
            this.allClinics = entiteGeographiques;
            this.filteredClinicsOptions$.next(this.allClinics);
        });

        const clinicFilterSubscriptions = this.form.get('clinicFilter')?.valueChanges.pipe(debounceTime(500)).subscribe((filterValue: string) => {
            this.clinicsMatSelectSearch = true;
            let filteredClinicsOptions = this.allClinics;
            if (filterValue?.trim()) {
                filteredClinicsOptions = this.allClinics.filter((clinic: EntiteGeographique) => {
                    const nom = Utils.removeDiacritics(clinic.nom.toLowerCase());
                    const filter = Utils.removeDiacritics(filterValue.trim().toLowerCase());
                    return nom.includes(filter);
                });
            }

            this.filteredClinicsOptions$.next(filteredClinicsOptions);
            this.clinicsMatSelectSearch = false;
        });

        const clinicSubscriptions = this.form.get('clinicSelect')?.valueChanges.subscribe((clinic?: EntiteGeographique | -1) => {
            if (clinic && clinic !== -1) {
                const clinicSelectInput = this.form.get('clinicSelect');
                clinicSelectInput?.setValue(null);
                clinicSelectInput?.updateValueAndValidity();
                this.addToInput(clinic, 'clinic');
            } else if (clinic === -1) {
                const clinicSelectInput = this.form.get('clinicSelect');
                clinicSelectInput?.setValue(null);
                clinicSelectInput?.updateValueAndValidity();
                this.addToInput(null, 'clinic');
            }
        });

        if (egSubscription) {
            this.subscriptions.push(egSubscription);
        }

        if (clinicFilterSubscriptions) {
            this.subscriptions.push(clinicFilterSubscriptions);
        }

        if (clinicSubscriptions) {
            this.subscriptions.push(clinicSubscriptions);
        }
    }

    private setStats(): void {
        this.performStatsRequest();

        const formValueChangesSubscription = this.form.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.performStatsRequest();
        });

        if (formValueChangesSubscription) {
            this.subscriptions.push(formValueChangesSubscription);
        }
    }

    private performStatsRequest(): void {
        let monthAged = this.form.get('age')?.value ?? 0;
        const ageValueType = this.form.get('ageValueType')?.value;
        if (ageValueType === 'year') {
            monthAged *= 12;
        }

        const statsSubscription = this.newsSegmentsService.getStats({
            entitesGeographiques: this.form.get('clinic')?.value?.map((eg: EntiteGeographique) => eg.id),
            especes: this.form.get('species')?.value?.map((esp: Espece) => esp.id),
            races: this.form.get('race')?.value?.map((race: Race) => race.id),
            monthAged,
            ageOperator: this.form.get('ageOperator')?.value,
            sex: this.form.get('gender')?.value,
            sterilized: this.form.get('sterilization')?.value
        }).subscribe(stats => {
            this.stats = stats;
            statsSubscription?.unsubscribe();
        });
    }
}

import { Injectable } from '@angular/core';
import { UtilisateurService } from './api/utilisateur.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'environments/environment';
import { OneSignal } from 'onesignal-ngx';

@Injectable()
export class OneSignalService {
    isInit = new BehaviorSubject<boolean>(false);
    areNotificationsEnabled = new BehaviorSubject<boolean>(false);
    private userId: number;

    constructor(
        private readonly utilisateurService: UtilisateurService,
        private readonly oneSignal: OneSignal
    ) {
        this.utilisateurService.utilisateurConnected.subscribe(user => {
            this.userId = user.id;
        });

        this.oneSignal.init({
            appId: environment.oneSignalAppId,
            autoRegister: true,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
                enable: false
            },
            welcomeNotification: {
                disable: true
            }
        }).then(() => {
            this.isInit.next(true);
        }).catch(error => {
            console.error('Error while initializing OneSignal', error);
        });

        this.oneSignal.User.PushSubscription.addEventListener('change', event => {
            if (event.current.token) {
                console.log('User is subscribed to notifications');
                this.areNotificationsEnabled.next(true);
            } else {
                console.log('User is not subscribed to notifications');
                this.areNotificationsEnabled.next(false);
            }
        });
    }

    subscribe(): void {
        if (!this.isInit.value) {
            console.log('OneSignal is not initialized');
            return;
        }

        const isSupported = this.oneSignal.Notifications.isPushSupported();

        if (!isSupported) {
            console.log('Push notifications are not supported');
            return;
        }

        console.log('Requesting permission for push notifications');
        this.oneSignal.Notifications.requestPermission()
        .then(() => {
            console.log('Notification permission sent, Login to OneSignal');
        }).catch(e => {
            console.error('Error while subscribing to OneSignal', e);
        });
    }
}

import { BehaviorSubject, fromEvent, interval, Subscription } from 'rxjs';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChatService } from '../../../../services/api/chat.service';
import { UtilisateurService } from '../../../../services/api/utilisateur.service';
import { Animal } from '../../../../models/animal/animal';
import { filter, map, switchMap, take, timeInterval } from 'rxjs/operators';
import { Veterinaire } from '../../../../models/utilisateurs/veterinaire';
import { Client } from '../../../../models/utilisateurs/client';
import { DomSanitizer } from '@angular/platform-browser';
import { Chat } from '../../../../models/animal/chat';
import { TypeUtilisateurEnum } from '../../../../models/utilisateurs/utilisateur';
import { FichierService } from '../../../../services/api/fichier.service';
import { LinkPreviewService } from '../../../../services/link-preview.service';
import {
    EditPhotoDialogComponent,
    EditPhotoDialogData
} from '../../../shared/view-utils/edit-photo-dialog/edit-photo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ChatScenario, ChatShortcut } from 'app/models/pro/entite-juridique';
import {
    FichiersAnimalDialogComponent,
    FichiersAnimalDialogInterface
} from '../../../shared/fichiers-animal-dialog/fichiers-animal-dialog.component';
import { AnimalService } from '../../../../services/api/animal.service';
import { Fichier } from 'app/models/fichier';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop-temp';
import { Utils } from 'app/utils';
import { DelayedMessageService } from '../../../../services/api/delayed-message.service';
import {
    ChatDelayedMessagesDialogComponent,
    ChatDelayedMessagesDialogInterface
} from '../chat-delayed-messages-dialog/chat-delayed-messages-dialog.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { VisiocareDialogComponent } from '../../../shared/view-utils/visiocare-dialog/visiocare-dialog.component';
import { HtmlTag, UrlMatch } from 'autolinker';
import { ChatMessage, FileChatMessagePayloadInterface } from '../../../../models/animal/chat-message';
import { DelayedMessage } from '../../../../models/delayed-message';
import { addDays } from 'date-fns';
import {
    ChatScenarioProgramDialogComponent,
    ChatScenarioProgramDialogInterface
} from '../chat-scenario-program-dialog/chat-scenario-program-dialog.component';
import { ChatMessageSelectionDialogComponent } from '../chat-message-selection-dialog/chat-message-selection-dialog.component';
import { photoSwipeConfig } from 'app/config';
import { GalleryOptions } from '@twogate/ngx-photo-gallery/lib/interfaces/photoswipe';
import { AvatarSize } from 'app/main/shared/view-utils/avatar/avatar.component';

let linkPreviewServiceChatViewComponent: LinkPreviewService;

@Component({
    selector: 'app-chat-view',
    templateUrl: './chat-view.component.html',
    styleUrls: ['./chat-view.component.scss']
})
export class ChatViewComponent implements OnInit, AfterViewInit, OnDestroy {
    AvatarSize = AvatarSize;

    wrappedMessages$: BehaviorSubject<Array<ChatMessage | DateSeparator>> = new BehaviorSubject<Array<ChatMessage | DateSeparator>>(null);
    chat: Chat;
    animal: Animal;
    client: Client;
    veterinaire: Veterinaire;
    veterinaires: Veterinaire[];

    shortcuts: ChatShortcut[] = [];
    groupedShortcuts: Map<string, ChatShortcut[]> = new Map<string, ChatShortcut[]>();
    groupedShortcutsKeys: string[] = [];
    filteredShortcuts: ChatShortcut[] = [];
    scenarios: ChatScenario[] = [];

    replyInput: HTMLTextAreaElement;
    loading = true;
    isSending = false;
    smallSendButton = false;

    photoGalleryConfig: GalleryOptions;

    @ViewChild('replyInput') replyInputField: ElementRef<HTMLTextAreaElement>;
    @ViewChild('replyForm') replyForm: NgForm;
    @ViewChild('container') container: ElementRef<HTMLElement>;
    @ViewChild('chatcontent') chatContent: ElementRef<HTMLElement>;

    @Input() visio = false;

    private loadMoreSubscription: Subscription;
    private messageSubscription: Subscription;
    private chatSelectedSubscription: Subscription;
    private userSelectedSubscription: Subscription;

    private scrollToBottomOffset = 0;
    private canLoadMore = false;

    constructor(
        private _elRef: ElementRef,
        public dialog: MatDialog,
        public chatService: ChatService,
        public utilisateurService: UtilisateurService,
        private fichierService: FichierService,
        private translateService: TranslateService,
        private sanitizer: DomSanitizer,
        public animalService: AnimalService,
        private linkPreviewService: LinkPreviewService,
        private delayedMessageService: DelayedMessageService
    ) {
        linkPreviewServiceChatViewComponent = this.linkPreviewService;
        this.photoGalleryConfig = photoSwipeConfig(this.translateService);
    }

    ngOnInit(): void {
        this.chatService.channelSelectedMessage.subscribe((messages: ChatMessage[]) => {
            const groupedMessages = Utils.groupBy(messages, message => {
                const dateObj = new Date(message.date);
                dateObj.setUTCHours(12);
                dateObj.setUTCMinutes(0);
                dateObj.setUTCSeconds(0);
                dateObj.setUTCMilliseconds(0);
                return dateObj.getTime();
            });

            const finalWrappedMessages = [];
            Object.keys(groupedMessages).sort((a, b) => Number(a) - Number(b)).forEach(key => {
                if (Object.prototype.hasOwnProperty.call(groupedMessages, key)) {
                    finalWrappedMessages.push({
                        id: Number(key),
                        dateSeparator: new Date(Number(key)),
                        isMyMessage: false
                    } as DateSeparator);
                    groupedMessages[key].sort((a, b) => a.date.getTime() - b.date.getTime())
                        .forEach(m => {
                            finalWrappedMessages.push(m);
                        });
                }
            });

            finalWrappedMessages.forEach((mw, index) => {
                if (mw instanceof ChatMessage) {
                    (mw as any).position = this.getMessagePosition(index, finalWrappedMessages);
                }
            });

            return this.wrappedMessages$.next(finalWrappedMessages);
        });

        this.messageSubscription = this.wrappedMessages$.subscribe(_ => {
            setTimeout(() => {
                this.loading = false;
                if (this.chat?.channel) {
                    this.scrollToBottom();

                    setTimeout(() => {
                        this.canLoadMore = true;
                        if (this.loadMoreSubscription) {
                            this.loadMoreSubscription.unsubscribe();
                        }

                        if (this.chat?.channel) {
                            void this.chat.channel.markAsRead();
                        }
                    }, 1500);
                }
            }, 500);
        });

        this.chatSelectedSubscription = this.chatService.chatSelected.pipe(filter(c => Boolean(c?.channel)))
            .subscribe(chat => {
                this.loading = !this.chat?.channel || chat.channel.url !== this.chat.channel.url;

                if (this.loading) {
                    this.readyToReply();
                }

                this.chat = chat;
                this.setGroupedShortcutFromChat(chat);

                this.animal = this.chat.animal;
                this.client = this.animal ? this.animal.proprietaire : null;
                if (this.client) {
                    this.client.typeUtilisateur = TypeUtilisateurEnum.client;
                }
            });

        this.userSelectedSubscription = this.utilisateurService.utilisateurConnected.subscribe(user => {
            this.veterinaire = user;
            this.scenarios = user.entiteJuridique.chatScenarios;
        });
    }

    ngAfterViewInit(): void {
        if (this.replyInputField) {
            this.replyInput = this.replyInputField.nativeElement;

            let subs: Subscription;
            fromEvent(this.replyInput, 'keyup')
                .subscribe(() => {
                    if (subs) {
                        subs.unsubscribe();
                        subs = null;
                    } else {
                        this.chatService.startTyping();
                    }

                    subs = interval(2000).pipe(timeInterval(), take(1)).subscribe(() => {
                        this.chatService.stopTyping();
                        subs.unsubscribe();
                        subs = null;
                    });
                });
            this.readyToReply();
        }

        const containerResizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                const cr = entry.contentRect;
                const width = cr.width;

                this.smallSendButton = width <= 680;
            }
        });
        containerResizeObserver.observe(this.container.nativeElement);
    }

    ngOnDestroy(): void {
        if (this.loadMoreSubscription) {
            this.loadMoreSubscription.unsubscribe();
        }

        this.chatService.setChatSelected(null);
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }

        if (this.chatSelectedSubscription) {
            this.chatSelectedSubscription.unsubscribe();
        }

        if (this.userSelectedSubscription) {
            this.userSelectedSubscription.unsubscribe();
        }
    }

    readyToReply(): void {
        setTimeout(() => {
            if (this.chat?.canChat && this.replyForm) {
                this.replyForm.reset();
                this.focusReplyInput();
                setTimeout(() => this.scrollToBottom(), 1000);
                this.replyForm.form?.get('message')?.valueChanges.subscribe(value => this.filterShortcuts(value));
            }
        }); // when possible
    }

    focusReplyInput(): void {
        setTimeout(() => {
            if (this.replyInput) {
                this.replyInput.focus();
            }
        });
    }

    scrollToBottom(): void {
        const container = this.chatContent.nativeElement;
        container.scrollTop = container.scrollHeight - this.scrollToBottomOffset;
    }

    loadMoreMessage(event: Event): void {
        const target = event.target as HTMLElement;
        const reachedTop = target.scrollTop <= 10;
        if (this.canLoadMore && reachedTop) {
            this.canLoadMore = false;
            this.scrollToBottomOffset = target.scrollHeight;
            this.loading = true;
            if (this.loadMoreSubscription) {
                this.loadMoreSubscription.unsubscribe();
            }

            this.loadMoreSubscription = this.chatService.getMoreMessageOnChannelSelected(this.chat.channel).subscribe({
                complete: () => {
                    this.loading = false;
                }
            });
        }
    }

    reply(): void {
        if (
            this.replyForm?.form?.value &&
            !this.isSending &&
            this.replyForm?.form?.value?.message.trim().length
        ) {
            this.isSending = true;
            this.chatService.sendMessage(this.replyForm.form.value.message.trim()).subscribe({
                next: () => this.readyToReply(),
                complete: () => {
                    this.isSending = false;
                }
            });
        }
    }

    openImage(message: ChatMessage): void {
        const payload = message.payload as FileChatMessagePayloadInterface;

        if (payload?.url && message?.id) {
            try {
                this.fichierService.openFileInModal('.image-' + message.id.toString(), 0, payload.url as string, payload.dimension[0], payload.dimension[1]);
            } catch {
                this.fichierService.openFileInModal('.image-' + message.id.toString(), 0, payload.url as string, 720, 1280);
            }
        }
    }

    openChat(): void {
        this.chatService.openDialogChat(this.chat).afterClosed().subscribe((result: Chat) => {
            if (result) {
                this.chat = result;
            }
        });
    }

    changeStatusChat(status: boolean): void {
        this.chatService.changeStatusChat(this.chat.id, status).subscribe((chat: Chat) => {
            this.chat = chat;
        });
    }

    addBookmark(): void {
        this.chatService.changeBookmarkChat(this.chat.id, true).subscribe((chat: Chat) => {
            this.chat = chat;
        });
    }

    removeBookmark(): void {
        this.chatService.changeBookmarkChat(this.chat.id, false).subscribe((chat: Chat) => {
            this.chat = chat;
        });
    }

    editImage(message: ChatMessage): void {
        const payload = message.payload as FileChatMessagePayloadInterface;

        if (payload?.image) {
            const data: EditPhotoDialogData = {
                url: payload.url as string,
                saveBtnText: this.translateService.instant('SHARED.SAVE_SEND')
            };
            this.dialog.open(EditPhotoDialogComponent, {
                data,
                panelClass: 'fullscreen-dialog',
                width: '60vw',
                height: '80vh',
                disableClose: true
            }).afterClosed().subscribe((result: File) => {
                if (result) {
                    this.chatService.sendFileMessage(result, true).subscribe();
                }
            });
        }
    }

    openMessageModal(): void {
        const data: ChatShortcut[] = this.shortcuts;
        this.dialog.open(ChatMessageSelectionDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '60vw',
            disableClose: true
        }).afterClosed().subscribe((data: ChatShortcut[]) => {
            if (data?.length === 1) {
                this.replyForm.form.get('message').setValue(data[0]?.content);
            }
        });
    }

    filterShortcuts(filterStr?: string): void {
        if (this.shortcuts) {
            this.filteredShortcuts = this.shortcuts.filter((chat: ChatShortcut) => {
                if (filterStr !== null && filterStr.trim() !== '') {
                    const shortcutClean = '/' + chat.shortcut.toLowerCase().trim();
                    const filterStrClean = filterStr.toLowerCase().trim();
                    return shortcutClean.startsWith(filterStrClean);
                }

                return false;
            });
        }
    }

    openFichiersModal(): void {
        const data: FichiersAnimalDialogInterface = {
            reqFichiers: this.animalService.getFichiers(this.chat.animal.id).pipe(map(f => f.data)),
            animal: this.chat.animal
        };
        this.dialog.open(FichiersAnimalDialogComponent, {
            panelClass: 'no-padding-dialog',
            width: '60vw',
            minHeight: '60vh',
            disableClose: true,
            data
        }).afterClosed().subscribe(result => {
            if (result instanceof Fichier) {
                this.fichierService.getBlobFromFichier(result).pipe(
                    switchMap(file => this.chatService.sendFileMessage(file))
                ).subscribe();
            }
        });
    }

    openMediProdModal(): void {
        this.dialog.open(VisiocareDialogComponent, {
            panelClass: 'no-padding-dialog',
            disableClose: true,
            data: {
                for: 'chat'
            },
            width: '600px',
            maxWidth: '95vw',
            height: '600px'
        }).afterClosed().subscribe(res => {
            if (res?.link) {
                this.isSending = true;
                this.chatService.sendMessage(res.link).subscribe({
                    next: () => this.readyToReply(),
                    complete: () => {
                        this.isSending = false;
                    }
                });
            }
        });
    }

    onFileDropped(files: NgxFileDropEntry[]): void {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.chatService.sendFileMessage(file, true).subscribe();
                });
            }
        }
    }

    generatePDF(): void {
        this.chatService.generatePDF(this.chat.id).subscribe();
    }

    private getMessagePosition(position: number, wrappedMessages: Array<ChatMessage | DateSeparator>): MessagePosition {
        const groupId = this.getMessageGroupId(position, wrappedMessages);
        const groupIdTop = this.getMessageGroupId(position + 1, wrappedMessages);
        const groupIdBottom = this.getMessageGroupId(position - 1, wrappedMessages);

        if (groupIdTop !== groupId && groupIdBottom !== groupId || groupId === '') {
            return MessagePosition.SOLO;
        } else if (groupIdTop !== groupId) {
            return MessagePosition.BOTTOM;
        } else if (groupIdBottom !== groupId) {
            return MessagePosition.TOP;
        }

        return MessagePosition.MIDDLE;
    }

    private getMessageGroupId(position: number, wrappedMessages: Array<ChatMessage | DateSeparator>): string {
        try {
            const message = wrappedMessages[position] as ChatMessage;
            const date = new Date((message as any).date);
            const h = date.getHours();
            const d = date.getDate();
            const m = date.getMonth() + 1;
            const y = date.getFullYear();
            return message.sender.id.toString() + '-' +
            y.toString() + '-' +
            (m <= 9 ? '0' + m.toString() : m.toString()) + '-' +
            (d <= 9 ? '0' + d.toString() : d.toString()) + '-' +
            (h <= 9 ? '0' + h.toString() : h.toString());
        } catch {
            return '';
        }
    }

    sendMessageLater(): void {
        if (
            this.replyForm?.form?.value &&
            this.replyForm?.form?.value?.message.trim().length
        ) {
            const data = new DelayedMessage();
            data.message = this.replyForm.form.value.message.trim();
            data.chat = this.chat;
            data.sender = this.utilisateurService.utilisateurConnectedValue;
            data.date = addDays(new Date(), 1);
            data.numberDaysToOpenIfClosed = 3;

            this.delayedMessageService.openDelayedMessageDialog(false, data).afterClosed().subscribe(result => {
                if (result) {
                    this.readyToReply();
                }
            });
        }
    }

    seeDelayedMessages(): void {
        const data: ChatDelayedMessagesDialogInterface = {
            chat: this.chat
        };
        this.dialog.open(ChatDelayedMessagesDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            minWidth: '550px',
            maxWidth: '620px',
            disableClose: true
        });
    }

    replaceFnLinky(match: UrlMatch): string {
        let metadata = '';
        if (match.getType() === 'url') {
            const rand = Math.round(Math.random() * 10_000);
            metadata = '<div class="link-preview-' + rand.toString() + '"></div>';
            linkPreviewServiceChatViewComponent.getLinkPreview(match.getUrl()).subscribe(data => {
                const element = document.querySelectorAll('.link-preview-' + rand.toString());
                if (element?.length > 0 && data) {
                    let html = '<a target="_blank" href="' + match.getUrl() + '">';

                    let image: string = null;
                    if (Utils.isImage(data.image)) {
                        image = data.image;
                    } else if (Utils.isImage(data.icon)) {
                        image = data.icon;
                    }

                    if (image) {
                        html += '<img src="' + image + '" alt="' + data.title + '" />';
                    }

                    html += '<div><strong>' + data.title + (data.provider ? ' - ' + data.provider : '') + '</strong>';

                    if (data.description) {
                        html += '<br />' + data.description;
                    }

                    html += '</div></a>';
                    element[0].innerHTML = html;
                    element[0].classList.add('link-preview');
                }
            });
        }

        const tag: HtmlTag = match.buildTag();
        return tag.toAnchorString() + metadata;
    }

    trackByFn(index: number, _item: ChatMessage | DateSeparator): number {
        return index;
    }

    private setGroupedShortcutFromChat(chat: Chat) {
        const shortcutsSubscription = this.chatService.getChatShortcuts(chat.id).subscribe(shortcuts => {
            this.shortcuts = shortcuts;

            if (!this.shortcuts) {
                this.shortcuts = [];
            }

            this.groupedShortcuts = this.shortcuts.reduce((acc: Map<string, ChatShortcut[]>, current: ChatShortcut) => {
                if (acc.has(current.category)) {
                    const chats = acc.get(current.category);
                    chats.push(current);
                    acc.set(current.category, chats);
                } else {
                    acc.set(current.category, [current]);
                }

                return acc;
            }, new Map<string, ChatShortcut[]>());
            this.groupedShortcutsKeys = [...this.groupedShortcuts.keys()].sort((a, b) => (a ?? 'zzzzzz').localeCompare(b ?? 'zzzzzz'));

            shortcutsSubscription?.unsubscribe();
        });
    }

    openScenarioModal(scenario: ChatScenario): void {
        this.chat.shortcuts = this.shortcuts;
        const data: ChatScenarioProgramDialogInterface = {
            chat: this.chat,
            scenario: scenario
        };
        this.dialog.open(ChatScenarioProgramDialogComponent, {
            data,
            panelClass: 'no-padding-dialog',
            width: '60vw',
            disableClose: true
        }).afterClosed().subscribe();
    }
}

export enum MessagePosition {
    TOP = 'TOP',
    MIDDLE = 'MIDDLE',
    BOTTOM = 'BOTTOM',
    SOLO = 'SOLO'
}

export interface DateSeparator {
    id: number;
    dateSeparator: Date;
    isMyMessage: boolean;
    position: number;
}

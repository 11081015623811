import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { UserPreferenciesInterface } from '../models/interfaces/user-preferencies.interface';
import { ChatFilter } from '../main/content/chat/chat-list/chat-list-menu-filter/chat-list-menu-filter.component';

@Injectable()
export class UserPreferenciesService {
    private _userPreferencies$: BehaviorSubject<UserPreferenciesInterface> = new BehaviorSubject(null);

    private readonly USER_PREFERENCIES = 'userPreferencies';
    public readonly USER_DISMISS_WARNING_ORDRE = 'userDismissWarningOrdre';

    constructor(private storageMap: StorageMap) {
        this._userPreferencies$.subscribe(pref => this.storageMap.set(this.USER_PREFERENCIES, pref));
    }

    public getPreferencies(): Observable<UserPreferenciesInterface> {
        if (this._userPreferencies$.value) {
            return this._userPreferencies$.asObservable();
        }

        return this.storageMap.has(this.USER_PREFERENCIES).pipe(
            switchMap(hasPref => {
                if (hasPref) {
                    return this.storageMap.get(this.USER_PREFERENCIES) as Observable<UserPreferenciesInterface>;
                }

                const chatsFilter: ChatFilter = {
                    status: 'open',
                    categories: [],
                    colors: [],
                    veterinaries: [],
                    clients: [],
                    isBookmarkedApplied: false
                };

                return of({
                    chatsFilter
                }) as Observable<UserPreferenciesInterface>;
            }),
            tap((value: UserPreferenciesInterface) => {
                this._userPreferencies$.next(value);
            })
        );
    }

    public setPreferenciesValue(name: string, value: boolean | any): Observable<UserPreferenciesInterface> {
        return this.getPreferencies().pipe(
            map(pref => {
                pref[name] = value;
                return pref;
            }),
            switchMap(pref => this.storageMap.set(this.USER_PREFERENCIES, pref).pipe(map(() => pref)))
        );
    }

    public isTrue(name: string): Observable<any | boolean> {
        return this.storageMap.get(name);
    }

    public setTrue(name: string): void {
        this.storageMap.set(name, true).subscribe();
    }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/api/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-reset-pass',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    animations: fuseAnimations
})
export class ResetPassComponent implements OnInit {
    form: FormGroup;
    codeOk = null;

    identifier = null;
    code = null;

    hidePassword = true;
    hidePasswordConfirm = true;

    changeInProgress = false;

    constructor(
        private fuseConfigService: FuseConfigService,
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private snackbar: MatSnackBar,
        private translateService: TranslateService
    ) {
        // Configure the layout
        this.fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            pwd: ['', [Validators.required]],
            pwdConfirm: ['', [Validators.required, ResetPassComponent.confirmPassword]]
        });
        try {
            const token = this.route.snapshot.queryParamMap.get('token');
            const data = JSON.parse(atob(decodeURIComponent(token)));
            this.identifier = data.identifier;
            this.code = data.code;
            this.authService.forgotPasswordForgottenCheckCode(this.identifier, this.code).subscribe({
                next: () => {
                    this.codeOk = true;
                },
                error: () => {
                    this.codeOk = false;
                }
            });
        } catch {
            this.codeOk = false;
        }
    }

    sendResetPassowrd(): void {
        if (this.form.valid) {
            this.changeInProgress = true;
            this.authService.forgotPasswordForgottenChangePassword(this.identifier, this.code, this.form.get('pwd').value).subscribe({
                next: () => {
                    this.snackbar.open(this.translateService.instant('LOGIN.PWD_UPDATE'), this.translateService.instant('SHARED.OK'), { duration: 3000 });
                    void this.router.navigate(['/']);
                    this.changeInProgress = false;
                },
                error: () => {
                    this.changeInProgress = false;
                    this.snackbar.open(this.translateService.instant('SHARED.ERROR'), this.translateService.instant('SHARED.OK'), { duration: 3000 });
                }
            });
        }
    }

    private static confirmPassword(control: AbstractControl) {
        if (!control.parent || !control) {
            return;
        }

        const password = control.parent.get('pwd');
        const passwordConfirm = control.parent.get('pwdConfirm');

        if (!password || !passwordConfirm) {
            return;
        }

        if (passwordConfirm.value === null) {
            return;
        }

        if (password.value !== passwordConfirm.value) {
            return {
                passwordsNotMatch: true
            };
        }
    }
}
